import React from 'react'

const AutoInsightsSvgIcon: React.FC = () => (
  // <div style={{ marginTop: -8, marginRight: 0 }}>
  <svg
    width="24"
    height="24"
    viewBox="-2 -4 18 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11.7333L4.125 7.7L0 5.86667L4.125 4.03333L6 0L7.875 4.03333L12 5.86667L7.875 7.7L6 11.7333ZM12 13.2L11.0625 11.1833L9 10.2667L11.0625 9.35L12 7.33333L12.9375 9.35L15 10.2667L12.9375 11.1833L12 13.2Z"
      fill="currentColor"
    />
  </svg>
  // </div>
)

export default AutoInsightsSvgIcon
