import styled from 'styled-components'
import {
  lightBlueFontColor,
  lightBlueFontColorHalfOpacity,
} from '../../assets/styles/variables'

export const Container = styled.div`
  box-shadow: 0 4px 10px 0 #00000026;
  border-radius: 10px;
  padding: 10px 20px;
  color: ${lightBlueFontColor};
  font-size: 18px;
`

export const Footer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const CarouselIndex = styled.div`
  font-size: 12px;
  color: ${lightBlueFontColorHalfOpacity};
`
