import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import { Loader } from 'shared/components'
import { PhraseList } from './phrase-list'
import { Chart } from './components/Chart/chart'
import { searchKeywords } from './model'
import useResizeObserver from 'use-resize-observer'
import { isInitialFlow } from 'features/home/utils'
import _ from 'lodash'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useQuery } from 'react-query'
import { Fetcher } from 'shared/components/fetcher'
import { buttonBlue, cardBorder, panelHover } from 'assets/styles/variables'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import Superset from 'components/Superset/Superset'
import { Controls } from './components/Controls/Controls'
import { Header } from 'features/project/features/filters/Header/Header'
import YogiDivider from 'components/UI/YogiDivider'

const MAX_RESULTS = 50

type Props = {
  comparativeIndex: number
  isActive: boolean
  setActivePanel: (index: number) => void
}

const KeywordsContent: React.FC<Props> = ({
  comparativeIndex,
  isActive,
  setActivePanel,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex],
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls,
  )
  const updateKeywordsControls = useProjectStore(
    (state: ProjectState) => state.updateKeywordsControls,
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )

  const { data: feature_flags } = useFeatureFlags()

  const wrapperRef = useRef<HTMLDivElement>(null)
  const { searchTerm, windowSize, stopWords, currentPhrase } =
    keywordsControls[comparativeIndex]

  const [loading, setLoading] = useState(true)

  const { data: searchKeywordsResults, isFetching } = useQuery(
    [
      `keywords`,
      projectId,
      filterValues.values,
      filterValues.searchQuery,
      filterValues.searchCondition,
      searchTerm,
      windowSize,
      stopWords,
    ],
    async () => {
      const { data } = await searchKeywords(
        projectId,
        {
          search_term: searchTerm || '',
          window_size: windowSize,
          max_results: MAX_RESULTS,
        },
        {
          criteria: filterValues.values,
          search_terms: [],
          search_criteria: [],
          stop_words: stopWords as string[],
        },
      )
      return data
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    },
  )

  useEffect(() => {
    mixpanel.track('keywords page')
  }, [])

  // Whenever a filter is applied, opened options should be reset
  useEffect(() => {
    clearOpenedOptions(comparativeIndex)
  }, [filterValues])

  useEffect(() => {
    if (searchKeywordsResults) {
      setLoading(false)
    }
  }, [searchKeywordsResults])

  const showSelectDataset =
    feature_flags?.superset &&
    !filterValues.values.find((el) => el.field === 'proj_uuid')

  if (showSelectDataset) {
    return (
      <>
        <ContentWrapper
          comparativePanelsNumber={comparativePanelsNumber}
          isActive={isActive}
          onClick={() => setActivePanel(comparativeIndex)}
        >
          <Superset comparativeIndex={comparativeIndex} />
        </ContentWrapper>
      </>
    )
  }

  if (isInitialFlow(details?.state) || loading) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.keywords.alias}`}</LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <ContentWrapper
      comparativePanelsNumber={comparativePanelsNumber}
      isActive={isActive}
      onClick={() => setActivePanel(comparativeIndex)}
    >
      {!!searchKeywordsResults && !currentPhrase && (
        <ScrollableContainer ref={wrapperRef}>
          <Chart
            data={searchKeywordsResults.chart}
            filterValues={filterValues}
            comparativeIndex={comparativeIndex}
            onPhraseSelect={(phrase: string) => {
              mixpanel.track('keywords chart click', {
                phrase: searchTerm,
                window: Number(windowSize),
                stop_words: stopWords.join(','),
              })
              updateKeywordsControls(comparativeIndex, 'currentPhrase', phrase)
            }}
          />
        </ScrollableContainer>
      )}
      {!isFetching && currentPhrase && (
        <PhraseList
          phrase={currentPhrase}
          useKeywordsSearch={searchTerm === ''}
          showHighlights={true}
          comparativeIndex={comparativeIndex}
        />
      )}

      {isFetching && (
        <Overlay>
          <LoaderWrapper>
            <Fetcher />
          </LoaderWrapper>
        </Overlay>
      )}
    </ContentWrapper>
  )
}

const Keywords: React.FC = () => {
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls,
  )
  const removePanel = useProjectStore(
    (state: ProjectState) => state.removePanel,
  )

  const [activePanel, setActivePanel] = useState(0)

  const { ref } = useResizeObserver<HTMLDivElement>()

  const selectedIndex = comparativePanelsNumber > 1 ? activePanel : null

  useEffect(() => {
    if (activePanel >= comparativePanelsNumber) {
      setActivePanel(0)
    }
  }, [comparativePanelsNumber])

  if (keywordsControls.length < comparativePanelsNumber) {
    return <Container />
  }

  return (
    <Container ref={ref}>
      <Left>
        {_.times(comparativePanelsNumber, (index) => (
          <>
            {index > 0 && <YogiDivider style={{ marginBottom: 15 }} />}
            {comparativePanelsNumber > 1 && (
              <PanelLabel>Panel {index + 1}:</PanelLabel>
            )}
            <Controls comparativeIndex={index}></Controls>
          </>
        ))}
      </Left>
      <Right>
        <HeaderContainer>
          <Header
            // this helps stop a bug from removing an active panel at the highest index
            activePanel={
              activePanel >= comparativePanelsNumber ? 0 : activePanel
            }
          ></Header>
        </HeaderContainer>
        <Wrapper>
          {_.times(comparativePanelsNumber, (index) => (
            <Panel key={index}>
              {comparativePanelsNumber > 1 && (
                <RemovePanel
                  onClick={() => {
                    removePanel(index)
                  }}
                >
                  <CloseOutlined />
                </RemovePanel>
              )}
              <KeywordsContent
                comparativeIndex={index}
                setActivePanel={setActivePanel}
                isActive={selectedIndex === index}
              />
            </Panel>
          ))}
        </Wrapper>
      </Right>
    </Container>
  )
}

export default Keywords

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-right: 16px;
  gap: 20px;
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  min-height: calc(100% - 80px);
  max-height: calc(100% - 80px);
  height: 100%;
  width: 100%;
  margin: 0 16px 16px 8px;
`

const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  overflow: hidden;
  position: relative;

  padding: 8px;
`
const ScrollableContainer = styled.div`
  border-radius: 6px;
  max-height: 100%;
  overflow-y: scroll;
`

const ContentWrapper = styled.div<{
  comparativePanelsNumber: number
  isActive: boolean
}>`
  width: 100%;
  padding: var(--default-padding-quarter) var(--default-padding-half);
  height: 100%;
  display: flex;
  flex-direction: column;

  border: 2px solid ${cardBorder};
  border-radius: 10px;
  border-color: ${({ isActive }) => (isActive ? buttonBlue : cardBorder)};
  &:hover {
    border-color: ${({ comparativePanelsNumber, isActive }) =>
      comparativePanelsNumber > 1 && !isActive
        ? panelHover
        : isActive
          ? buttonBlue
          : cardBorder};
  }

  box-shadow: 0 4px 10px 0 #00000026;
`

const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 100px;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.4);
`
const LoaderWrapper = styled.div`
  top: 30%;
`

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const RemovePanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  cursor: pointer;
  border: 2px solid ${cardBorder};
  background: white;
  border-radius: 50%;
  z-index: 2;
  //opacity: 0.5;
  font-size: var(--font-size-md);
  transition: 0.2s ease;
  &:hover {
    background: ${cardBorder};
  }
`

const Left = styled.div`
  height: calc(100vh - 40px);
  overflow: auto;
  //padding-right: 10px;
  width: 300px;
`

const PanelLabel = styled.div`
  margin-bottom: 5px;
`

const Right = styled.div`
  height: 100%;
  width: calc(100% - 300px);
  max-width: calc(100% - 300px);
`
const HeaderContainer = styled.div`
  margin-left: 15px;
  margin-bottom: 10px;
`
