import React, { useState } from 'react'
import {
  Table,
  Input,
  InputNumber,
  Button,
  Form,
  Tooltip,
  notification,
  Typography,
} from 'antd'
import styled from 'styled-components'
import { useMutation } from 'react-query'
import { updateSource } from '../../model'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { tooltip } from '../../../../../../utils/tooltip-data'

type Props = {
  title: string
  description: string
  last_update_time: string
  item: string
  role: string
  source_uuid: string
  state: string
  hierarchy: string
  url: string
  refetch_data: any
}

interface Description {
  key: string
  field: any
  value: string | any
}

interface EditableCellProps extends React.HTMLAttributes<HTMLElement> {
  editing: boolean
  dataIndex: string
  title: any
  inputType: 'text' | 'number'
  record: Description
  index: number
  children: React.ReactNode
}

const EditableCell: React.FC<EditableCellProps> = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode = inputType === 'number' ? <InputNumber min={0} /> : <Input />
  //title in this case is the "Field"
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          rules={[
            {
              required: true,
              message: `Please Input ${title}`,
            },
          ]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  )
}

export const SourceTableAdmin: React.FC<Props> = ({
  title,
  last_update_time,
  item,
  role,
  state,
  hierarchy,
  source_uuid,
  url,
  refetch_data,
}) => {
  const formatTableData = (
    title: string,
    last_update_time: string,
    item: string,
    state: string,
    hierarchy: string,
    url: string
  ) => {
    return [
      {
        key: '1',
        field: (
          <div>
            {' '}
            Title {''}{' '}
            <Tooltip title={tooltip['source-title']}>
              <InfoCircleOutlined />
            </Tooltip>{' '}
          </div>
        ),
        value: title,
      },
      {
        key: '3',
        field: (
          <div>
            {' '}
            Last Update Time {''}{' '}
            <Tooltip title={tooltip['source-last-update']}>
              <InfoCircleOutlined />
            </Tooltip>{' '}
          </div>
        ),
        value: last_update_time,
      },
      {
        key: '4',
        field: (
          <div>
            {' '}
            State {''}{' '}
            <Tooltip title={tooltip['source-state']}>
              <InfoCircleOutlined />
            </Tooltip>{' '}
          </div>
        ),
        value: state,
      },
      {
        key: '5',
        field: (
          <div>
            {' '}
            Item {''}{' '}
            <Tooltip title={tooltip['source-item']}>
              <InfoCircleOutlined />
            </Tooltip>{' '}
          </div>
        ),
        value: item,
      },
      {
        key: '6',
        field: (
          <div>
            {' '}
            Hierarchy {''}{' '}
            <Tooltip title={tooltip['source-hierarchy']}>
              <InfoCircleOutlined />
            </Tooltip>{' '}
          </div>
        ),
        value: hierarchy,
      },
      {
        key: '7',
        field: (
          <div>
            {' '}
            Url {''}{' '}
            <Tooltip title={tooltip['source-url-page']}>
              <InfoCircleOutlined />
            </Tooltip>{' '}
          </div>
        ),
        value: (
          <Tooltip title={url}>
            <Typography.Link
              style={{ width: 350 }}
              ellipsis
              target="_blank"
              rel="noopener noreferrer"
              href={url}
            >
              {url}
            </Typography.Link>
          </Tooltip>
        ),
      },
    ]
  }

  const [form] = Form.useForm()
  const [data, setData] = useState(
    formatTableData(title, last_update_time, item, state, hierarchy, url)
  )
  const [editingKey, setEditingKey] = useState('')

  const isEditing = (record: Description) => record.key === editingKey

  const edit = (record: Description) => {
    form.setFieldsValue({ ...record })
    setEditingKey(record.key)
  }

  const cancel = () => {
    setEditingKey('')
  }

  const save = async (key: React.Key) => {
    try {
      const row = (await form.validateFields()) as Description

      if (key === '1') {
        await mutate({
          uuid: source_uuid,
          title: row.value,
          description: undefined,
        })
      } else if (key === '2') {
        await mutate({
          uuid: source_uuid,
          title: undefined,
          description: row.value,
        })
      } else {
        await mutate({
          uuid: source_uuid,
          title: undefined,
          description: undefined,
        })
      }
    } catch (errInfo) {}
  }

  const { mutate, isLoading } = useMutation(updateSource, {
    onSuccess: (data) => {
      // Query Invalidations
      setData(
        formatTableData(
          data.title,
          last_update_time,
          state,
          item,
          hierarchy,
          url
        )
      )
      setEditingKey('')
      refetch_data()

      notification['success']({
        message: 'Updated',
        description: 'Updated Successfully',
      })
    },
    onError: () => {
      notification['error']({
        message: 'Error',
        description: 'Unable to Update',
      })
    },
  })

  const columns = [
    {
      title: 'Field',
      dataIndex: 'field',
      key: 'field',
      render: (text: string) => <b>{text}</b>,
      editable: false,
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
      editable: true,
    },
    {
      title: 'Action',
      action: 'action',
      render: (_: any, record: Description) => {
        let nonEditableFields = ['4', '5', '6', '7', '8']
        if (nonEditableFields.indexOf(record.key) === -1) {
          const editable = isEditing(record)
          return editable ? (
            <div>
              <Button
                type="primary"
                loading={isLoading}
                onClick={() => save(record.key)}
              >
                Save
              </Button>
              <Button type="primary" onClick={() => cancel()}>
                Cancel
              </Button>
            </div>
          ) : (
            <div>
              <Button
                icon={<EditOutlined />}
                ghost
                type="primary"
                shape="circle"
                disabled={editingKey !== ''}
                onClick={() => edit(record)}
              />
            </div>
          )
        } else {
          return (
            <div>
              <Button icon={<EditOutlined />} disabled={true} />
            </div>
          )
        }
      },
    },
  ]

  const mergedColumns = columns.map((col) => {
    if (!col.editable) {
      return col
    }
    return {
      ...col,
      onCell: (record: Description) => ({
        record,
        inputType: 'text',
        dataIndex: col.dataIndex,
        title: col.title,
        editing: isEditing(record),
      }),
    }
  })

  return (
    <>
      <Form form={form} component={false}>
        <TableWrapper>
          <Table
            components={{
              body: {
                cell: EditableCell,
              },
            }}
            bordered
            dataSource={data}
            columns={mergedColumns}
            rowClassName="editable-row"
            size="small"
            showHeader={false}
            pagination={false}
          />
        </TableWrapper>
      </Form>
    </>
  )
}

const TableWrapper = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`
