import React, { FC, memo, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { Layout } from 'react-grid-layout'
import { ChartHandle } from '../Charts/chart'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { Header } from '../header'
import _ from 'lodash'
import { generateDashboard, saveDashboard } from '../../../model'
import { exportAllImages } from '../../../utils'
import { DashboardResponse } from '../../../types'
import { Summary } from '../../../../summary'
import mixpanel from '../../../../../../trackers/mixpanel'
import DashboardBuilderHeader from './DashboardBuilderHeader'
import DashboardViewContent from './DashboardViewContent'
import { toast } from 'react-toastify'
import { useQuery } from 'react-query'
import { Annotations } from '../Annotations/Annotations'
import { useFeatureFlags } from '../../../../../hooks/useFeatureFlags'

type Props = {
  filterValues: Filters
  setFilters: (filters: Filters) => void
  setView: (view: 'list' | 'view' | 'edit') => void
  uneditedDashboard: DashboardResponse | undefined
  selectedDashboard: DashboardResponse | undefined
  setSelectedDashboard: (
    selectedDashboard: DashboardResponse | undefined,
  ) => void
  setSharingDashboard: (sharingDashboard: DashboardResponse | undefined) => void
  isOwner?: boolean
  isPublic?: boolean
}
export const SmartDashboardView: FC<Props> = memo(function ContainerFn({
  filterValues,
  setFilters,
  uneditedDashboard,
  selectedDashboard,
  setView,
  setSelectedDashboard,
  setSharingDashboard,
  isOwner,
  isPublic,
}) {
  const [itemMap, setItemMap] = useState<{ [key: string]: any }>({})
  const [shadowMap, setShadowMap] = useState<{ [key: string]: any }>({})
  const [curLayout, setCurLayout] = useState<Layout[]>([])
  const { layout } = selectedDashboard?.dashboard.dashboard_settings ?? {}

  // TODO should this be in own project store?
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const [dashboardName, setDashboardName] = useState<string>('Smart Dashboard')
  const [showResetDashboard, setShowResetDashboard] = useState<boolean>(false)
  const chartRefs = useRef<ChartHandle[]>([])
  const [chartRefsMap, setChartRefsMap] = useState<{ [key: string]: any }>({})

  const [category, setCategory] = useState('brand')
  const [value, setValue] = useState('')
  const [themes, setThemes] = useState<string[]>([])

  const [selectedTheme, setSelectedTheme] = useState<string>('')
  const [dashboardsList, setDashboardsList] = useState<string>('')

  // TODO - move update logic to child because right now its trying to update a null dash or something

  // TODO - possible to have selectedtheme in a product_hierarchy dashboard causing cache misses
  const { data: dashboard, isLoading } = useQuery(
    ['generate-dashboard', projectId, category, value, selectedTheme],
    () => {
      if (!projectId || !value || (category === 'brand' && !selectedTheme)) {
        return Promise.resolve(undefined)
      }
      return generateDashboard(projectId, category, value, selectedTheme)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled:
        !!projectId &&
        (category !== 'brand' || (category === 'brand' && !!selectedTheme)),
    },
  )

  const { data: feature_flags } = useFeatureFlags()

  useEffect(() => {
    if (themes.length) {
      setSelectedTheme(themes[0])
    }
  }, [themes])

  useEffect(() => {
    if (dashboard && !_.isEqual(dashboard.filters, filterValues)) {
      setShowResetDashboard(true)
    } else {
      setShowResetDashboard(false)
    }
  }, [filterValues])

  // useEffect(() => {
  //   if (selectedDashboard) {
  //     setDashboard(selectedDashboard.dashboard.dashboard_settings)
  //   } else {
  //     setDashboard(undefined)
  //   }
  // }, [selectedDashboard])

  // todo can prob be condensed into above
  useEffect(() => {
    if (dashboard) {
      setFilters(dashboard?.filters)
    }
  }, [dashboard])

  const [headerHeight, setHeaderHeight] = useState(40)

  const addToRefs = (el: ChartHandle) => {
    if (el && !chartRefs.current.includes(el)) {
      chartRefs.current.push(el)
      chartRefsMap[el.index] = el
      setChartRefsMap(chartRefsMap)
    }
  }

  useEffect(() => {
    setItemMap(shadowMap)
  }, [layout])

  const updateItem = (
    index: string,
    filterValues: Filters | null,
    dashboardControls: any,
    title?: string,
  ) => {
    if (!dashboard) return
    // mixpanel.track('custom dashboard', {
    //   action: 'update item',
    //   value: selectedDashboard?.dashboard?.id,
    //   type: 'view',
    //   proj_uuid: projectId,
    // })
    const item = JSON.parse(JSON.stringify(dashboard?.items[Number(index)]))
    if (filterValues) {
      item.filterValues = filterValues
    }
    if (dashboardControls) {
      item.dashboardControls = dashboardControls
    }
    if (title) {
      item.title = title
    }
    shadowMap[`${index}`] = item
    setShadowMap(shadowMap)
  }

  const handleSaveDashboard = async () => {
    // this doesnt get used fyi
    mixpanel.track('custom dashboard', {
      action: 'save smart dashboard',
      value: value,
      theme: selectedTheme,
      proj_uuid: projectId,
    })
    // const orderedLayout = curLayout.sort((a, b) => {
    //   if (a.y !== b.y) {
    //     return a.y - b.y
    //   } else {
    //     return a.x - b.x
    //   }
    // })
    // const orderedItems = orderedLayout.map((l) => itemMap[l.i])
    // const dashboardLayout = {
    //   layout: orderedLayout,
    //   items: orderedItems,
    //   filters: filterValues,
    // }

    await saveDashboard(projectId, {
      name: `${value} - ${selectedTheme} Analysis`,
      dashboard_settings: dashboard,
      is_shared: false,
    })
    toast.success('Dashboard saved successfully')
    // setDashboard(dashboardLayout)
  }
  const [isExporting, setIsExporting] = useState<boolean>(false)

  useEffect(() => {
    if (isExporting) {
      exportAllImages(
        // @ts-ignore
        dashboard.layout,
        chartRefsMap,
        dashboard,
        projectId,
        dashboard.filters,
        defaultFilterList,
        dashboardName,
        new Date().toLocaleDateString(),
        setIsExporting,
      )
    }
  }, [isExporting])

  return (
    <Container>
      <Header
        saveDashboard={() => handleSaveDashboard()}
        exportDashboard={() => {
          mixpanel.track('custom dashboard', {
            action: 'export',
            value: selectedDashboard?.dashboard.id,
            proj_uuid: projectId,
          })
          setIsExporting(true)
        }}
        showResetDashboard={showResetDashboard}
        dashboardName={dashboardName}
        dashboard={selectedDashboard}
        setView={setView}
        setSharingDashboard={() => {
          setSharingDashboard(selectedDashboard)
        }}
        isExporting={isExporting}
        isOwner={isOwner}
        isPublic={isPublic}
        setHeaderHeight={setHeaderHeight}
        isSmart={true}
      />
      <ViewWrapper headerHeight={headerHeight}>
        <div
          style={{
            overflow: 'auto',
            height: '100%',
            // paddingTop: 12,
          }}
        >
          <div style={{ height: 110 }}>
            <div
              style={{
                position: 'absolute',
                width: '100%',
                zIndex: 101,
                overflow: 'auto',
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <DashboardBuilderHeader
                selectedDashboard={selectedDashboard}
                setSelectedDashboard={setSelectedDashboard}
                category={category}
                setCategory={setCategory}
                value={value}
                setValue={setValue}
                themes={themes}
                setThemes={setThemes}
                selectedTheme={selectedTheme}
                setSelectedTheme={setSelectedTheme}
              />
            </div>
          </div>
          <DashboardViewsContainer>
            {category === 'brand' ? (
              <>
                {themes.map((theme) => (
                  <div
                    style={{
                      height: theme !== selectedTheme ? 0 : '100%',
                      overflow: theme !== selectedTheme ? 'hidden' : 'auto',
                    }}
                  >
                    <DashboardViewContent
                      filterValues={filterValues}
                      setFilters={setFilters}
                      isPublic={!!isPublic}
                      updateItem={updateItem}
                      addToRefs={addToRefs}
                      category={category}
                      value={value ?? ''}
                      theme={theme}
                    />
                  </div>
                ))}
              </>
            ) : (
              <div
                style={{
                  height: '100%',
                  overflow: 'auto',
                }}
              >
                <DashboardViewContent
                  filterValues={filterValues}
                  setFilters={setFilters}
                  isPublic={!!isPublic}
                  updateItem={updateItem}
                  addToRefs={addToRefs}
                  category={category}
                  value={value ?? ''}
                  theme={''}
                />
              </div>
            )}
          </DashboardViewsContainer>
        </div>
      </ViewWrapper>

      {/* TODO smart dash is not ready for annotations - dashboard value here is dashboardsettings */}
      {/*{feature_flags?.annotations && (*/}
      {/*  <>*/}
      {/*    {dashboard && (*/}
      {/*      <Annotations dashboard={dashboard} isPublic={isPublic} />*/}
      {/*    )}*/}
      {/*  </>*/}
      {/*)}*/}
      <Summary isPublic={isPublic} />
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  justify-content: flex-start;
  flex-direction: row;
  max-height: calc(100vh - 92px);
  height: 100%;
  width: 100%;
`
const ViewWrapper = styled.div<{ headerHeight?: number }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: ${(props) =>
    props.headerHeight
      ? `calc(100vh - 40px - ${props.headerHeight}px)`
      : '100%'};
`
const DashboardViewsContainer = styled.div`
  height: calc(100% - 110px);
  overflow: auto;
`
