import React from 'react'
import mixpanel from '../../../../trackers/mixpanel'
import {
  initialState,
  useProjectStore,
} from '../../../projectStore/projectStore'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import { getCurrentRouteName } from '../../../utils'
import { Filters, ProjectState } from '../../../types'
import styled from 'styled-components'
import { Tooltip } from 'antd'
import { Keyword } from '../model'
import { secondaryNavy } from '../../../../../assets/styles/variables'

interface Props {
  text: string
  keywordsToHighlight: string[]
  userKeywords: string[]
  keywords: Keyword[]
  disabled?: boolean
  filters: Filters
}

const HighlightKeywords: React.FC<Props> = ({
  text,
  keywordsToHighlight,
  userKeywords,
  keywords,
  disabled,
  filters,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // Create a Set from the keywordsToHighlight for quick lookup
  const keywordsToHighlightSet = new Set(
    keywordsToHighlight.map((word) => word.toLowerCase())
  )

  // Find the keywords that are present in the keywordsToHighlightSet
  const highlightedKeywords = keywords.filter((keyword) =>
    keywordsToHighlightSet.has(keyword.keyword.toLowerCase())
  )

  const handleKeywordClick = async (keyword: string) => {
    mixpanel.track('pdp insights', {
      action: 'open keyword',
      value: keyword,
      proj_uuid: projectId,
    })
    // setShowFetcher(true)
    document.body.style.cursor = 'wait'
    const pageControls = JSON.parse(
      JSON.stringify(initialState.keywordsControls)
    )
    pageControls[0].searchTerm = keyword
    const body = {
      filters: [filters],
      pageControls,
    }
    try {
      const filterHash = await getBookmarkUrlPost(projectId, {
        page: getCurrentRouteName(window.location.pathname),
        filterString: JSON.stringify(body),
      })
      const url = `${window.location.origin}/project/${projectId}/keywords?filters=${filterHash}`
      window.open(url, '')
      // setShowFetcher(false)
      document.body.style.cursor = ''
    } catch (error) {
      // setShowFetcher(false)
      document.body.style.cursor = ''
      console.log(error)
    }
  }

  // old function, may need to reclaim something from here
  const highlightKeywords = (
    beforeText: string,
    afterText: string,
    keywords: string[]
  ) => {
    // TODO is there a way that returns JSX so we dont have to dangerouslysethtml?
    let newKeywords = keywords.filter(
      (el) => !beforeText.toLowerCase().includes(el.toLowerCase())
    )
    const regex = new RegExp(`(${newKeywords.join('|')})`, 'gi')
    // TODO need to sanitize this
    return afterText.replace(regex, '<span class="highlight">$1</span>')
  }

  // Replace the keywords in text with a span element with the provided background color
  const highlightedText = text.split(/(\W+)/).map((part) => {
    const lowercasePart = part.toLowerCase()
    const matchingKeyword = highlightedKeywords.find(
      (keyword) => keyword.keyword.toLowerCase() === lowercasePart
    )

    if (matchingKeyword) {
      return (
        <Tooltip
          title={
            <div>
              <div>Mentions: {matchingKeyword.sentences}</div>
              <div>Sentiment: {matchingKeyword.sentiment}</div>
            </div>
          }
        >
          <HighlightedSpan
            key={part}
            color={matchingKeyword.color}
            // style={{
            //   backgroundColor: matchingKeyword.color,
            //   color: 'white',
            //   padding: '0 4px',
            //   borderRadius: 4,
            //   cursor: 'pointer',
            // }}
            style={{
              borderBottom: '2px solid ' + matchingKeyword.color,
              // color: 'white',
              // padding: '0 4px',
              // borderRadius: 4,
              cursor: 'pointer',
            }}
            onClick={() => handleKeywordClick(part)}
          >
            {part}
          </HighlightedSpan>
        </Tooltip>
      )
    } else {
      const matchingUserKeyword = userKeywords.find(
        (keyword) => keyword.toLowerCase() === lowercasePart
      )
      if (matchingUserKeyword) {
        return (
          <HighlightedSpan
            key={part}
            color={secondaryNavy}
            // style={{
            //   backgroundColor: secondaryNavy,
            //   color: 'white',
            //   padding: '0 4px',
            //   borderRadius: 4,
            //   cursor: 'pointer',
            // }}
            style={{
              borderBottom: '2px solid ' + secondaryNavy,
              // color: 'white',
              // padding: '0 4px',
              // borderRadius: 4,
              cursor: 'pointer',
            }}
            onClick={() => handleKeywordClick(part)}
          >
            {part}
          </HighlightedSpan>
        )
      }
    }

    return part
  })

  if (disabled) return <p style={{ marginBottom: 0 }}>{text}</p>

  return <p style={{ marginBottom: 0 }}>{highlightedText}</p>
}

export default HighlightKeywords

const HighlightedSpan = styled.span<{ color: string }>`
  &:hover {
    box-shadow: inset 0 0 0 1000px ${({ color }) => color};
    //border-radius: 4px;
    color: white;
    //padding: 0 4px;
  }
`
