import React, { useState } from 'react'
import {
  Table,
  Button,
  Modal,
  Form,
  Tooltip,
  Input,
  Select,
  notification,
} from 'antd'
import styled from 'styled-components'
import { DeleteOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { useMutation } from 'react-query'
import { deleteSource, addCustomField } from './../../model'
import { tooltip } from '../../../../../../utils/tooltip-data'
import { YogiButton } from '../../../../../../components/UI/YogiButton'

type Props = {
  data: any[]
  role: string
  uuid: string
}

interface CustomFieldFormProps {
  visible: boolean
  onCreate: (values: any) => void
  onCancel: () => void
  isLoading: boolean
}

const CustomFieldFormModal: React.FC<CustomFieldFormProps> = ({
  visible,
  onCreate,
  onCancel,
  isLoading,
}) => {
  const [form] = Form.useForm()

  const handleOk = () => {
    // Put save logic here, add to the state of the data

    form
      .validateFields()
      .then((values) => {
        onCreate(values)
        form.resetFields()
      })
      .catch((info) => {
        notification['error']({
          message: 'Validate Failed',
          description: 'Validation Failed',
        })
      })

    // do the request, or put is loading here, so no need for setConfirmLoading
  }

  return (
    <Modal
      title="Add Custom Field"
      visible={visible}
      okText="Create"
      cancelText="Cancel"
      onCancel={onCancel}
      onOk={handleOk}
      confirmLoading={isLoading}
    >
      <Form
        form={form}
        id="custom-field"
        name="basic"
        initialValues={{ remember: true }}
      >
        <Form.Item
          label="Field"
          name="field"
          rules={[{ required: true, message: 'Please input your field!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          label="Value"
          name="value"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <Input />
        </Form.Item>

        <Form.Item
          name="type"
          label="Type"
          rules={[{ required: true, message: 'Please input your value!' }]}
        >
          <Select
            placeholder="Select a option and change input text above"
            allowClear
          >
            <Select.Option value="str">Text</Select.Option>
            <Select.Option value="int">Integer</Select.Option>
            <Select.Option value="float">Float</Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </Modal>
  )
}

export const SourceCustomFieldsAdmin: React.FC<Props> = ({ data, uuid }) => {
  // DELETE CUSTOM FIELD TABLE
  interface CustomField {
    key: string
    field: string
    value: string
  }

  const { mutate, isLoading } = useMutation(deleteSource, {
    onSuccess: () => {
      // Query Invalidationss
      notification['success']({
        message: 'Deleted',
        description: 'Deleted Successfully',
      })
    },
    onError: () => {
      notification['error']({
        message: 'Error',
        description: 'Unsuccessful Operation',
      })
    },
  })

  // TODO if creates an issue, might need mutateAsync
  const { mutate: addField, isLoading: isLoadingAdd } = useMutation(
    addCustomField,
    {
      onSuccess: () => {
        notification['success']({
          message: 'Created',
          description: 'Created Successfully',
        })
      },
      onError: () => {
        notification['error']({
          message: 'Error',
          description: 'Unsuccessful Operation',
        })
      },
    }
  )

  // now use mutation to delete  record and then update list on success -> no need to refetch?
  const deleteCustomField = async (record: CustomField) => {
    await mutate(
      {
        uuids: [uuid],
        fields: [record.field],
      },
      {
        onSuccess: (data) => {
          setData(customData.filter((data) => data.key !== record.key))
        },
      }
    )
  }

  const columns = [
    {
      title: (
        <div>
          {' '}
          Custom Field {''}{' '}
          <Tooltip title={tooltip['custom-field']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      dataIndex: 'field',
      key: 'field',
      width: '30%',
    },
    {
      title: (
        <div>
          {' '}
          Value {''}{' '}
          <Tooltip title={tooltip['custom-value']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      dataIndex: 'value',
      key: 'value',
      width: '30%',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      width: '10%',
      render: (_: any, record: CustomField) => (
        <Button
          type={'primary'}
          onClick={() => deleteCustomField(record)}
          icon={<DeleteOutlined />}
          loading={isLoading}
          danger
          style={{
            borderRadius: '8px',
            borderWidth: '2px',
          }}
        ></Button>
      ),
    },
  ]

  const table_data: CustomField[] = []
  for (const property in data) {
    const val =
      typeof data[property] === 'object'
        ? JSON.stringify(data[property])
        : data[property]
    table_data.push({
      key: property + val,
      field: property,
      value: val,
    })
  }
  const [customData, setData] = useState(table_data)
  const [visible, setVisible] = React.useState(false)

  const showModal = () => {
    setVisible(true)
  }

  const onCreate = async (values: any) => {
    await addField(
      {
        resource_uuid: uuid,
        field: values.field,
        resource: 'source',
        field_value: values.value,
        field_type: values.type,
        lookup_field: null,
        lookup_value: null,
        lookup_type: null,
        add_field: true,
      },
      {
        onSuccess: () => {
          const newData: CustomField = {
            key: values.field + values.value,
            field: values.field,
            value: values.value,
          }

          setData([...customData, newData])
          setVisible(false)
        },
      }
    )
  }

  const onCancel = () => {
    setVisible(false)
  }

  return (
    <>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={customData}
          pagination={false}
          scroll={{ y: 240 }}
          size="small"
          bordered
        ></Table>
      </TableWrapper>
      <AddCustomButton>
        <YogiButton type="primary" onClick={showModal}>
          Add Custom Field
        </YogiButton>
      </AddCustomButton>

      <CustomFieldFormModal
        visible={visible}
        onCreate={onCreate}
        onCancel={onCancel}
        isLoading={isLoadingAdd}
      ></CustomFieldFormModal>
    </>
  )
}

const AddCustomButton = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const TableWrapper = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`
