import React from 'react'
import mixpanel from '../../../../trackers/mixpanel'
import { LoadingOutlined, UnorderedListOutlined } from '@ant-design/icons'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import {
  getBadgeLabels,
  HardcodedFilterOptionsEnum,
  reducerToComputeFiltersCount,
} from '../helpers'
import {
  cardBorder,
  filterButtonFontSize,
  lightBlueFontColor,
  navDefaultTextColor,
  primaryOrange,
  secondaryButtonLightBlue,
  secondaryButtonLightOrange,
} from 'assets/styles/variables'
import { FilterPanel } from 'features/project/features/filters/FilterButton/components/FilterPanel/FilterPanel'
import { useClickOutside } from 'shared/hooks'
import styled, { keyframes } from 'styled-components'
import { Popover } from 'antd'
import { useFilterBadges } from 'features/project/hooks/useFilterBadges'

type Props = {
  filterValues: Filters
  isFilterPanelVisible: boolean
  setFilterPanelVisible: any
  filterOptionsLoading: boolean
  isSmall?: boolean
  disabled?: boolean
  comparativeIndex: number
  // these are for custom dash charts to have their own filterlists
  localOpenedOptions?: string[]
  setLocalOpenedOptions?: (openedOptions: string[]) => void
  updateLocalFilterValues?: (filterValues: Filters) => void
  localSelectedOption?: string
  setLocalSelectedOption?: (selectedOption: string) => void
  isUnlinked?: boolean
  parentFilters?: Filters
  isDashboardChart?: boolean
  isDashboardEdit?: boolean
  mergedFilters?: Filters
}
export const FilterButton: React.FC<Props> = ({
  filterValues,
  isFilterPanelVisible,
  setFilterPanelVisible,
  filterOptionsLoading,
  isSmall,
  disabled,
  comparativeIndex,
  //cdash things
  updateLocalFilterValues,
  localOpenedOptions,
  setLocalOpenedOptions,
  localSelectedOption,
  setLocalSelectedOption,
  isUnlinked,
  parentFilters,
  isDashboardChart,
  isDashboardEdit,
  mergedFilters,
}) => {
  const { isDashboard, isNetwork, isFeedback, isKeywords, isAlerts } =
    useProjectStore((state: ProjectState) => state.route)
  const countries = useProjectStore((state: ProjectState) => state.countries)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const updateGlobalSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )

  const openFilterPanel = (field: string) => {
    setFilterPanelVisible(true)
    updateGlobalSelectedOptionField(comparativeIndex || 0, field)
  }

  const { getTag } = useFilterBadges(
    filterValues,
    comparativeIndex,
    openFilterPanel,
    updateLocalFilterValues,
  )

  const panelRef = React.createRef<HTMLDivElement>()
  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.classList?.contains('filter-badge')) ||
      (e.target && e.target?.classList?.contains('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button')
    )
      return
    setFilterPanelVisible(false)
  })

  const globalAppliedFiltersCount = filterValues.values
    .filter(
      (el) =>
        !el.field.startsWith('search_query') &&
        !el.field.startsWith('search_condition') &&
        (countries?.enabled ? !el.field.startsWith('country') : true),
    )
    .map((el) => ({
      field: el.field,
      values: [...new Set(getBadgeLabels(el, defaultFilterList))],
    }))
    .reduce(reducerToComputeFiltersCount, 0)

  const leftInset = () => {
    if (isKeywords) {
      return 470
    }
    if (isFeedback) {
      return 480
    }
    return isDashboardEdit ? 430 : isDashboardChart ? 400 : 370
  }

  return (
    <Wrapper
      className={'filters-button'}
      style={{
        position: isDashboard ? 'static' : 'relative',
      }}
    >
      <Container
        disabled={disabled}
        className="filters-button"
        onClick={() => {
          setFilterPanelVisible(!isFilterPanelVisible)
          mixpanel.track('filter panel open', {
            isDashboard,
            isNetwork,
            isFeedback,
            isKeywords,
          })
        }}
      >
        <Label className={'filters-button'}>
          {isSmall ? (
            <span
              style={{ pointerEvents: 'none', fontSize: filterButtonFontSize }}
            >
              {filterOptionsLoading ? (
                <LoadingOutlined style={{ pointerEvents: 'none' }} size={10} />
              ) : (
                <UnorderedListOutlined
                  style={{
                    pointerEvents: 'none',
                    fontSize: filterButtonFontSize,
                  }}
                  size={10}
                />
              )}
            </span>
          ) : (
            <span>Filters</span>
          )}
        </Label>
        {filterValues.values.filter(
          (el) => el.field !== HardcodedFilterOptionsEnum.CREATE_TIME,
        ).length > 0 ? (
          <Popover
            content={
              <>
                {filterValues.values
                  .filter(
                    (el) => el.field !== HardcodedFilterOptionsEnum.CREATE_TIME,
                  )
                  .map(getTag)}
              </>
            }
          >
            <Count
              count={globalAppliedFiltersCount}
              className={'filters-button'}
            >
              <Number
                key={comparativeIndex}
                style={{ minWidth: 10, textAlign: 'center' }}
                className={'filters-button'}
              >
                {globalAppliedFiltersCount}
              </Number>
            </Count>
          </Popover>
        ) : (
          <Count count={globalAppliedFiltersCount} className={'filters-button'}>
            <span
              style={{ minWidth: 10, textAlign: 'center' }}
              className={'filters-button'}
            >
              {globalAppliedFiltersCount}
            </span>
          </Count>
        )}
      </Container>
      {isFilterPanelVisible && (
        <FilterPanelWrapper
          ref={panelRef}
          comparativePanelsNumber={1}
          isComparative={false}
          dragged={false}
          style={{
            top: isDashboardChart ? '60px' : '45px',
            left: isDashboardChart ? '15px' : '5px',
            maxWidth: isAlerts ? 650 : 800,
          }}
          isDashboardChart={!!isDashboardChart}
          leftInset={leftInset()}
        >
          <FilterPanel
            onClose={() => {
              setFilterPanelVisible(false)
            }}
            filterValues={filterValues}
            comparativeIndex={comparativeIndex}
            //cdash things
            updateLocalFilterValues={updateLocalFilterValues}
            localOpenedOptions={localOpenedOptions}
            setLocalOpenedOptions={setLocalOpenedOptions}
            localSelectedOption={localSelectedOption}
            setLocalSelectedOption={setLocalSelectedOption}
            isUnlinked={isUnlinked}
            parentFilters={parentFilters}
            mergedFilters={mergedFilters}
          />
        </FilterPanelWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div``

const Container = styled.div<{ disabled?: boolean }>`
  font-size: ${filterButtonFontSize}!important;
  background: white;
  border: 2px solid ${cardBorder};
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #00000026;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    border-color: ${navDefaultTextColor};
  }
`

const Label = styled.div`
  padding: 8px 14px 8px;
  color: #28364f;
  font-size: ${filterButtonFontSize}!important;
`

const Count = styled.div<{ count: number }>`
  padding: 5px 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${({ count }) =>
    count > 0 ? secondaryButtonLightBlue : secondaryButtonLightOrange};
  color: ${({ count }) => (count > 0 ? lightBlueFontColor : primaryOrange)};

  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
  font-size: ${filterButtonFontSize}!important;
`

const FilterPanelWrapper = styled.div<{
  isComparative: boolean
  comparativePanelsNumber: number
  dragged: boolean
  isDashboardChart: boolean
  leftInset: number
}>`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: 5px;
  top: 50px;
  z-index: 101;

  border-radius: 10px;
  border: 1px solid #e3e3e3;

  box-shadow: 0 4px 5px 0 #00000026;

  width: ${({ isDashboardChart, leftInset }) =>
    isDashboardChart ? 'calc(100% - 30px)' : `calc(100vw - ${leftInset}px)`};
  max-width: 800px;
  height: 500px;

  transform: ${({ dragged }) => (dragged ? 'none !important' : 'inherit')};
`

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Number = styled.div`
  display: inline-block;
  animation: ${fadeIn} 0.5s ease-out;
`
