import React from 'react'
import { Input as AntdInput } from 'antd'
import styled from 'styled-components'

type InputProps = {
  name: string
  autoComplete?: string
  placeholder?: string
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void
  value: string
}

const Input: React.FC<InputProps> = ({
  name,
  autoComplete,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <StyledInput
      name={name}
      autoComplete={autoComplete}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )
}

const StyledInput = styled(AntdInput)`
  background-color: #f1f1f1;
  padding: var(--default-padding-half);

  :focus {
    background-color: #ffffff;
  }
  :focus-within {
    background-color: #ffffff;
  }

  ::placeholder {
    font-style: italic;
  }
`

const InputPassword: React.FC<InputProps> = ({
  name,
  autoComplete,
  placeholder,
  onChange,
  value,
}) => {
  return (
    <StyledInputPassword
      name={name}
      autoComplete={autoComplete}
      placeholder={placeholder}
      onChange={onChange}
      value={value}
    />
  )
}

const StyledInputPassword = styled(AntdInput.Password)`
    background-color: #f1f1f1;
    padding: var(--default-padding-half);

    :focus {
      background-color: #ffffff;
    }
    :focus-within {
      background-color: #ffffff;
    }

    ::placeholder {
      font-style: italic;
    }

    svg {
      width: 19px;
      height: 19px;
    }

    svg:hover {
      color: #1890ff;
    }

    input {
      background-color: #f1f1f1;
      :focus {
      background-color: #ffffff;
    }
    }
`

export { Input, InputPassword }
