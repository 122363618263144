import React from 'react'
import styled from 'styled-components'
import { Typography, Progress } from 'antd'
import { blue, grey, green } from '@ant-design/colors'
import { numberFormatter } from 'utils/numberFormat'
import { NumericalChart as NumericalChartType } from '../types'

const { Title, Text } = Typography

type Props = {
  data: NumericalChartType
}

export const NumericalChart = React.memo(function ({ data }: Props) {
  let percent = 0
  const isRange = !!data.range_max
  const primaryValue = data.primary_value

  if (primaryValue) {
    percent = primaryValue / (data.secondary_value || 1)

    if (data.range_min !== undefined && data.range_max !== undefined) {
      if (data.range_min < 0) {
        if (primaryValue > 0) {
          percent = 0.5 + primaryValue / data.range_max / 2
        } else {
          percent = 0.5 - Math.abs(primaryValue) / Math.abs(data.range_min) / 2
        }
      } else {
        percent = primaryValue / (data.range_max - data.range_min)
      }
    }
  } else if (primaryValue && primaryValue < 1) {
    percent = primaryValue
  }

  percent = percent * 100

  return (
    <Wrapper>
      <Row>
        <div />
        <div>
          <Title
            level={4}
            style={{ color: percent === 1 ? green[6] : blue[7] }}
          >
            {primaryValue && numberFormatter(primaryValue)}
          </Title>
        </div>
      </Row>
      <Row>
        <Progress percent={percent} showInfo={false} />
      </Row>
      <Row>
        <Text strong style={{ color: grey[3] }}>
          {isRange
            ? numberFormatter(data.range_min as number)
            : data.secondary_title}
        </Text>
        <Text strong style={{ color: grey[3] }}>
          {isRange ? (
            numberFormatter(data.range_max as number)
          ) : (
            <>
              {numberFormatter(data.secondary_value as number)}
              {data.percent ? ` (${percent.toFixed(2)}%)` : ''}
            </>
          )}
        </Text>
      </Row>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  background: white;
  padding: var(--default-padding-half);
  width: 100%;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
`
const Row = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
