import React from 'react'
import { Table, Tooltip } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { SourceCustomFieldsAdmin } from './admin/source-custom-fields-admin'
import { tooltip } from '../../../../../utils/tooltip-data'

type Props = {
  data: []
  role: string
  uuid: string
}

export const SourceCustomFieldsNonAdmin: React.FC<Props> = ({ data }) => {
  let table_data = []
  const columns = [
    {
      title: (
        <div>
          {' '}
          Custom Field {''}{' '}
          <Tooltip title={tooltip['custom-field']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: (
        <div>
          {' '}
          Value {''}{' '}
          <Tooltip title={tooltip['custom-value']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      dataIndex: 'value',
      key: 'value',
    },
  ]
  for (const property in data) {
    const val =
      typeof data[property] === 'object'
        ? JSON.stringify(data[property])
        : data[property]
    table_data.push({
      key: property + val,
      field: property,
      value: val,
    })
  }
  return (
    <>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={table_data}
          size="small"
          bordered
        ></Table>
      </TableWrapper>
    </>
  )
}
const TableWrapper = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const SourceCustomFields: React.FC<Props> = ({ data, role, uuid }) => {
  if (role !== 'admin') {
    return (
      <SourceCustomFieldsNonAdmin
        data={data}
        uuid={uuid}
        role={role}
      ></SourceCustomFieldsNonAdmin>
    )
  } else {
    return <SourceCustomFieldsAdmin data={data} uuid={uuid} role={role} />
  }
}
