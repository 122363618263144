import React from 'react'
import { Select, SelectProps } from 'antd'

type Props = SelectProps & {
  onChange?: any
}
export const YogiSelect: React.FC<Props> = ({
  defaultValue,
  options,
  onChange,
  style,
}) => {
  return (
    <Select
      defaultValue={defaultValue ? defaultValue : options ? options[0] : ''}
      onChange={onChange}
      options={options}
      dropdownMatchSelectWidth
      style={style}
    />
  )
}
