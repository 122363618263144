import React, { useEffect } from 'react'
import { Button, Popover } from 'antd'
import { ProjectState } from 'features/project/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import mixpanel from 'features/trackers/mixpanel'
import { toast } from 'react-toastify'
import { DRIVER_EXPORT_LIMIT } from 'features/project/features/Charts/components/ExportDrawer/constants'

type Props = {
  children: React.ReactNode
  exportCsv: () => any
  exportCsvWithThemeDrivers?: () => any
}

export const ExportCsv: React.FC<Props> = ({
  children,
  exportCsv,
  exportCsvWithThemeDrivers,
}) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const isExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isExportDrawerOpen,
  )

  const [isVisible, setIsVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isExtendedLoading, setIsExtendedLoading] = React.useState(false)

  useEffect(() => {
    setIsVisible(false)
  }, [isExportDrawerOpen])

  if (!exportCsvWithThemeDrivers) {
    return <div onClick={exportCsv}>{children}</div>
  }

  return (
    <Popover
      open={isVisible}
      onOpenChange={(v: boolean) => {
        setIsVisible(v)
      }}
      trigger="click"
      placement={'bottom'}
      content={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Button
            onClick={() => {
              mixpanel.track('csv download', {
                ...route,
              })
              setIsLoading(true)
              exportCsv().then(() => {
                setIsVisible(false)
                setIsLoading(false)
              })
            }}
            loading={isLoading}
          >
            Export CSV
          </Button>
          <Button
            onClick={() => {
              mixpanel.track('csv download', {
                type: 'theme drivers',
                ...route,
              })
              setIsExtendedLoading(true)
              exportCsvWithThemeDrivers()
                .then((response: boolean) => {
                  setIsExtendedLoading(false)
                  if (!response) {
                    toast.error('Maximum 50 rows supported for Drivers Export')
                  } else {
                    setIsVisible(false)
                    setIsLoading(false)
                  }
                })
                .catch((e: any) => {
                  setIsExtendedLoading(false)
                  toast.error(
                    e?.message ?? 'Error exporting CSV with Theme Drivers',
                  )
                })
            }}
            loading={isExtendedLoading}
          >
            Export CSV with Theme Drivers (Max {DRIVER_EXPORT_LIMIT} Rows)
          </Button>
        </div>
      }
    >
      <div onClick={() => setIsVisible(!isVisible)}>{children}</div>
    </Popover>
  )
}
