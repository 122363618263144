import React from 'react'

const ChartsSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.13158 11.6667H3.55263V5.83333H5.13158V11.6667ZM8.28947 11.6667H6.71053V3.33333H8.28947V11.6667ZM11.4474 11.6667H9.86842V8.33333H11.4474V11.6667ZM13.4211 13.4167H1.57895V1.66667H13.4211V13.4167ZM13.4211 0H1.57895C0.710526 0 0 0.75 0 1.66667V13.3333C0 14.25 0.710526 15 1.57895 15H13.4211C14.2895 15 15 14.25 15 13.3333V1.66667C15 0.75 14.2895 0 13.4211 0Z"
      fill="currentColor"
    />
  </svg>
)

export default ChartsSvgIcon
