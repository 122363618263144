import { Input, Switch, Tooltip, Typography } from 'antd'
import { EditOutlined, InfoCircleOutlined } from '@ant-design/icons'
import {
  cardBorderGrey,
  lightText,
  secondaryNavy,
} from '../../../../../assets/styles/variables'
import { Link } from 'react-router-dom'
import { CHARTS_URL, DEFAULT_CHART_FONT_SIZE } from '../../../utils'
import mixpanel from '../../../../trackers/mixpanel'
import React, { useRef } from 'react'
import { UnionChart } from '../types'
import { ChartTypes, fontSizeDict } from '../utils'
import { useQuery } from 'react-query'
import { getBookmarkUrlPost } from '../../../../url-params/model'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import styled from 'styled-components'

type Props = {
  currentChart: UnionChart
  isEditing: boolean
  fontSize: string
  chartTitle: string | undefined
  setChartTitle: (chartTitle: string) => void
  dashboardControls: any
  filters: Filters
  isLink?: boolean
  comparativePanelsNumber?: number
  comparativeIndex?: number
  updateDashboardControls: (key: string, value: any) => void
}
const { Title } = Typography
const ChartTitle: React.FC<Props> = ({
  currentChart,
  isEditing,
  fontSize,
  chartTitle,
  setChartTitle,
  filters,
  dashboardControls,
  isLink,
  comparativePanelsNumber,
  comparativeIndex,
  updateDashboardControls,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)

  const alignOrderRef = useRef<HTMLButtonElement>(null)
  const alignScaleRef = useRef<HTMLButtonElement>(null)

  const { isAlignOrder, isAlignScales } = dashboardControls

  const isAlignOrderTypeChart =
    currentChart.chart_type === ChartTypes.TABLE ||
    currentChart.chart_type === ChartTypes.BAR ||
    currentChart.chart_type === ChartTypes.STACK_BAR
  // ||
  // currentChart.chart_type === ChartTypes.MATRIX
  const isAlignScalesTypeChart =
    currentChart?.chart_type === ChartTypes.STACK_BAR ||
    currentChart?.chart_type === ChartTypes.BAR ||
    currentChart?.chart_type === ChartTypes.BUBBLE ||
    currentChart?.chart_type === ChartTypes.LINE

  const { data: linkData } = useQuery(
    ['getFilterHash', projectId, filters, dashboardControls],
    () =>
      getBookmarkUrlPost(projectId, {
        page: `${CHARTS_URL}`,
        filterString: JSON.stringify({
          filters: [filters],
          pageControls: [dashboardControls],
        }),
      }),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!isLink,
    },
  )

  const ChartTitleText = () => {
    return (
      <Title
        level={5}
        style={{
          marginRight: 'auto',
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis',
          marginBottom: 0,
          fontSize: fontSizeDict[fontSize || DEFAULT_CHART_FONT_SIZE],
        }}
      >
        {chartTitle ?? currentChart.title}
        {!!currentChart.info && (
          <Tooltip title={currentChart.info}>
            <InfoCircleOutlined
              data-html2canvas-ignore={'true'}
              style={{ cursor: 'pointer', marginLeft: 5 }}
            />
          </Tooltip>
        )}
      </Title>
    )
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        gap: 10,
        maxWidth: '90%',
      }}
    >
      {isEditing ? (
        <Input
          suffix={<EditOutlined style={{ color: '#9FB8C3' }} />}
          style={{
            border: '2px solid ' + cardBorderGrey,
            borderRadius: '10px',
            minWidth: 200,
            width: 400,
            fontSize: 12,
          }}
          value={chartTitle ?? currentChart.title}
          onChange={(event) => setChartTitle(event.target.value)}
          className={'drag-cancel'}
        />
      ) : (
        <>
          {isLink && linkData ? (
            <Link
              target={'_blank'}
              to={`${CHARTS_URL}?filters=${linkData}`}
              onClick={() => {
                mixpanel.track('custom dashboard', {
                  action: 'open chart link',
                  value: currentChart.chart_id,
                })
              }}
            >
              <ChartTitleText />
            </Link>
          ) : (
            <ChartTitleText />
          )}
          {comparativePanelsNumber === 1 ? (
            <div style={{ display: 'flex', alignItems: 'center' }}>
              {(route.isCharts || route.isDashboard) ?? (
                <i style={{ color: lightText, fontSize: '12px' }}>
                  Click on chart data to view a detailed summary
                </i>
              )}
            </div>
          ) : (
            <>
              {isAlignOrderTypeChart && comparativeIndex === 0 && (
                <StyledSwitch
                  ref={alignOrderRef}
                  data-html2canvas-ignore={'true'}
                  checkedChildren="Order"
                  unCheckedChildren="Order"
                  checked={isAlignOrder}
                  onChange={() =>
                    updateDashboardControls('isAlignOrder', !isAlignOrder)
                  }
                />
              )}
              {isAlignScalesTypeChart && comparativeIndex === 0 && (
                <StyledSwitch
                  ref={alignScaleRef}
                  data-html2canvas-ignore={'true'}
                  checkedChildren="Scale"
                  unCheckedChildren="Scale"
                  checked={isAlignScales}
                  onChange={() =>
                    updateDashboardControls('isAlignScales', !isAlignScales)
                  }
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  )
}

export default ChartTitle

const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryNavy : 'rgba(0, 0, 0, 0.25)'}!important;

  .ant-switch-inner {
    font-size: 12px;
  }
`
