import React from 'react'
import styled from 'styled-components'
import { Typography } from 'antd'

type Props = { children: React.ReactNode }

export const FormTitle = ({ children }: Props) => (
  <Title>
    <Typography.Title level={3}>{children}</Typography.Title>
  </Title>
)

const Title = styled.div`
  text-align: center;
  margin-bottom: var(--default-padding);
`
