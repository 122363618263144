import React, { useEffect } from 'react'
import { Popover } from 'antd'
import { ProjectState } from 'features/project/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { SaveViewForm } from 'features/project/features/saved-vews/components/view-save-form'
import { SavedViewPayload } from 'features/project/features/saved-vews/types'
import styled from 'styled-components'
import { useSavedViews } from 'features/project/features/saved-vews/utils'

type Props = {
  children: React.ReactNode
}

export const SaveView: React.FC<Props> = ({ children }) => {
  const isExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isExportDrawerOpen,
  )

  const { saveView } = useSavedViews()

  const [isVisible, setIsVisible] = React.useState(false)

  useEffect(() => {
    setIsVisible(false)
  }, [isExportDrawerOpen])

  return (
    <Popover
      open={isVisible}
      onOpenChange={(v: boolean) => {
        setIsVisible(v)
      }}
      title={<div style={{ padding: '5px' }}>Saved Views</div>}
      trigger="click"
      style={{ padding: '0 !important' }}
      placement={'bottom'}
      content={
        <SavedViewsPopoverContent>
          <SaveViewForm
            saveFunction={async (view: SavedViewPayload) => {
              await saveView(view)
              setIsVisible(false)
              // refetch()
            }}
          />
        </SavedViewsPopoverContent>
      }
    >
      <div onClick={() => setIsVisible(!isVisible)}>{children}</div>
    </Popover>
  )
}

const SavedViewsPopoverContent = styled.div`
  width: 500px;
`
