import React, { useState } from 'react'
import { Tabs } from 'antd'
import styled from 'styled-components'
import { Filters } from '../../types'
import useResizeObserver from 'use-resize-observer'
import { emptyFilters } from '../../utils'
import PDPDetailContent from './PDPDetailContent'
import { ProductHierarchyInfo } from '../products/types'

interface FocusDetailProps {
  lens: string
  item: ProductHierarchyInfo
  setValue: (value: string | null) => void
}

const PDPDetail: React.FC<FocusDetailProps> = ({ lens, item, setValue }) => {
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>()

  const [localFilters, setLocalFilters] = useState<Filters>(emptyFilters)

  return (
    <Container>
      <Header ref={ref}>
        {/*<div*/}
        {/*  style={{*/}
        {/*    display: 'flex',*/}
        {/*    justifyContent: 'space-between',*/}
        {/*    alignItems: 'center',*/}
        {/*    // marginBottom: 10,*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <YogiButton*/}
        {/*    type="dashed"*/}
        {/*    onClick={() => setValue(null)}*/}
        {/*    icon={<LeftOutlined />}*/}
        {/*    style={{*/}
        {/*      border: 'none',*/}
        {/*      boxShadow: 'none',*/}
        {/*      color: lightText,*/}
        {/*      paddingLeft: 0,*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Back to all Products*/}
        {/*  </YogiButton>*/}
        {/*  <div></div>*/}
        {/*</div>*/}
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <div style={{ marginBottom: 10, fontSize: 14 }}>
            Amazon PDP Insights: {item.hierarchy}
          </div>
        </div>
      </Header>
      <Content headerHeight={height}>
        <PDPDetailContent item={item} lens={lens} filterValues={localFilters} />
      </Content>
    </Container>
  )
}

const Container = styled.div`
  height: calc(100% + 40px);
  max-width: calc(100% - 300px);

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 60px 20px 20px;
  margin-top: -60px;

  background: linear-gradient(
    90deg,
    rgba(98, 183, 212, 0.064) 0%,
    rgba(252, 250, 250, 0.064) 43.62%
  );
`

const Header = styled.div`
  //background: white;
  padding: var(--default-padding-quarter) var(--default-padding-half) 0px;
  //box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
`
const Content = styled.div<{ headerHeight?: number }>`
  height: calc(100% - ${(props) => props.headerHeight}px);
  overflow: auto;
  display: flex;
`
const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
`

const MainBody = styled.div`
  display: flex;
  flex-direction: row;
  max-width: 100%;
`

const LeftSection = styled.div`
  flex: 3;
  padding-right: 16px;
  border-right: 2px solid rgba(159, 183, 195, 0.15);
  max-width: 75%;
`

const RightSection = styled.div`
  flex: 1;
  padding-left: 16px;
`

const StyledTabs = styled(Tabs)`
  flex: 1;
  padding-left: 16px;

  .ant-tabs-nav {
    margin: 0;
  }
`
const LoadingWrapper = styled.div`
  height: 100%;
  max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`

export default PDPDetail
