import React from 'react'

const PDPInsightsSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="16"
    viewBox="0 0 15 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.1571 12.725C6.77693 15.7614 2.81732 13.2209 0.282656 11.6779C0.125811 11.5806 -0.140769 11.7006 0.090526 11.9663C0.934951 12.9902 3.70231 15.458 7.31452 15.458C10.9292 15.458 13.0796 13.4857 13.3486 13.1416C13.6158 12.8004 13.4271 12.6123 13.157 12.725H13.1571ZM14.949 11.7354C14.7776 11.5123 13.9071 11.4707 13.3593 11.538C12.8106 11.6034 11.987 11.9387 12.0586 12.14C12.0954 12.2154 12.1704 12.1816 12.5473 12.1477C12.9253 12.11 13.9841 11.9764 14.2047 12.2648C14.4263 12.5552 13.867 13.9386 13.7648 14.1617C13.6661 14.3848 13.8025 14.4424 13.9879 14.2938C14.1708 14.1452 14.5018 13.7606 14.724 13.2162C14.9446 12.6688 15.0792 11.9053 14.949 11.7354Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.86711 6.52914C8.86711 7.32585 8.88724 7.99027 8.48455 8.69779C8.15952 9.27309 7.64467 9.62686 7.06944 9.62686C6.28418 9.62686 5.82687 9.02858 5.82687 8.1456C5.82687 6.40254 7.38864 6.08619 8.86711 6.08619V6.52914ZM10.9294 11.5137C10.7942 11.6345 10.5986 11.6432 10.4462 11.5626C9.76738 10.9989 9.64654 10.7371 9.27265 10.1992C8.15092 11.344 7.35705 11.6863 5.90168 11.6863C4.18167 11.6863 2.84131 10.6249 2.84131 8.49936C2.84131 6.83979 3.74163 5.70939 5.02149 5.15719C6.13177 4.66816 7.68208 4.58189 8.86711 4.44675V4.18211C8.86711 3.696 8.90446 3.12076 8.61975 2.70085C8.36948 2.32405 7.89204 2.16872 7.47207 2.16872C6.69263 2.16872 5.99656 2.5685 5.82687 3.39686C5.79231 3.58098 5.65717 3.7622 5.4731 3.77081L3.48845 3.55801C3.32166 3.52054 3.1376 3.3854 3.18361 3.12937C3.64093 0.724867 5.81244 0 7.75677 0C8.75198 0 10.052 0.264641 10.8373 1.01824C11.8325 1.94725 11.7375 3.1869 11.7375 4.53587V7.72278C11.7375 8.68058 12.1345 9.10048 12.5084 9.61825C12.6407 9.80231 12.6694 10.0238 12.5026 10.1618C12.0855 10.5098 11.3435 11.157 10.9351 11.5195L10.9293 11.5137"
      fill="currentColor"
    />
    <path
      d="M13.1571 12.725C6.77693 15.7614 2.81732 13.2209 0.282656 11.6779C0.125811 11.5806 -0.140769 11.7006 0.090526 11.9663C0.934951 12.9902 3.70231 15.458 7.31452 15.458C10.9292 15.458 13.0796 13.4857 13.3486 13.1416C13.6158 12.8004 13.4271 12.6123 13.157 12.725H13.1571ZM14.949 11.7354C14.7776 11.5123 13.9071 11.4707 13.3593 11.538C12.8106 11.6034 11.987 11.9387 12.0586 12.14C12.0954 12.2154 12.1704 12.1816 12.5473 12.1477C12.9253 12.11 13.9841 11.9764 14.2047 12.2648C14.4263 12.5552 13.867 13.9386 13.7648 14.1617C13.6661 14.3848 13.8025 14.4424 13.9879 14.2938C14.1708 14.1452 14.5018 13.7606 14.724 13.2162C14.9446 12.6688 15.0792 11.9053 14.949 11.7354Z"
      fill="currentColor"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M8.86711 6.52914C8.86711 7.32585 8.88724 7.99027 8.48455 8.69779C8.15952 9.27309 7.64467 9.62686 7.06944 9.62686C6.28418 9.62686 5.82687 9.02858 5.82687 8.1456C5.82687 6.40254 7.38864 6.08619 8.86711 6.08619V6.52914ZM10.9294 11.5137C10.7942 11.6345 10.5986 11.6432 10.4462 11.5626C9.76738 10.9989 9.64654 10.7371 9.27265 10.1992C8.15092 11.344 7.35705 11.6863 5.90168 11.6863C4.18167 11.6863 2.84131 10.6249 2.84131 8.49936C2.84131 6.83979 3.74163 5.70939 5.02149 5.15719C6.13177 4.66816 7.68208 4.58189 8.86711 4.44675V4.18211C8.86711 3.696 8.90446 3.12076 8.61975 2.70085C8.36948 2.32405 7.89204 2.16872 7.47207 2.16872C6.69263 2.16872 5.99656 2.5685 5.82687 3.39686C5.79231 3.58098 5.65717 3.7622 5.4731 3.77081L3.48845 3.55801C3.32166 3.52054 3.1376 3.3854 3.18361 3.12937C3.64093 0.724867 5.81244 0 7.75677 0C8.75198 0 10.052 0.264641 10.8373 1.01824C11.8325 1.94725 11.7375 3.1869 11.7375 4.53587V7.72278C11.7375 8.68058 12.1345 9.10048 12.5084 9.61825C12.6407 9.80231 12.6694 10.0238 12.5026 10.1618C12.0855 10.5098 11.3435 11.157 10.9351 11.5195L10.9293 11.5137"
      fill="currentColor"
    />
  </svg>
)

export default PDPInsightsSvgIcon
