import styled from 'styled-components'
import { secondaryNavy } from '../../../../assets/styles/variables'

export const Container = styled.span`
  color: ${secondaryNavy};
  font-weight: 600;
  padding-right: 10px;
  font-size: 18px;
  cursor: pointer;
  white-space: nowrap;
`

export const Spacer = styled.div`
  display: inline-block;
  width: 12px;
  position: relative;
`

export const CitationBubble = styled.span`
  color: ${secondaryNavy};
  margin-left: 2px;
  margin-top: -25px;
  border-radius: 50%;
  background-color: #d7d9da;
  padding: 2px 4px;
  font-size: 12px;
  min-width: 20px;
  position: absolute;

  text-align: center;
  line-height: 1.25;
`

export const NumberLink = styled.a`
  color: ${secondaryNavy};
`
