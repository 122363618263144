import React, { useRef, useState } from 'react'
import { FilterButton } from 'features/project/features/filters/FilterButton/FilterButton'
import { SearchButton } from '../../../../filters/SearchButton/SearchButton'
import { SearchPanel } from '../../../../filters/SearchButton/components/SearchPanel/SearchPanel'
import { PanelBadges } from '../../../../filters/features/badge-panel/panel-badges'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { useClickOutside } from 'shared/hooks'
import {
  Section,
  ControlsCard,
  ButtonWrapper,
  SectionTitle,
} from '../CreateAlert.styles'
import { SearchPanelWrapper } from '../../../../filters/header-filter-panel'
import { Filters } from '../../../../../types'

interface Props {
  filterValues: Filters
  setFilterValues: (filterValues: Filters) => void
}

const AlertDefinition: React.FC<Props> = ({
  filterValues,
  setFilterValues,
}) => {
  const updateSelectedOptionField = useProjectStore(
    (state) => state.updateSelectedOptionField,
  )
  const updateOpenedOptions = useProjectStore(
    (state) => state.updateOpenedOptions,
  )
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)

  const panelRef = React.createRef<HTMLDivElement>()

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button')
    )
      return
    setFilterPanelVisible(false)
  })

  function setLocalFilterValues(filterValues: Filters) {
    setFilterValues(filterValues)
    setFilterPanelVisible(false)
    setSearchPanelVisible(false)
  }

  // this helps us position the filter-panel in the right place if the CreateAlert body has been scrolled
  const buttonWrapperRef = useRef<HTMLDivElement>(null)

  return (
    <Section style={{ marginTop: 5 }}>
      <SectionTitle>Define Alert</SectionTitle>
      <ControlsCard>
        <ButtonWrapper ref={buttonWrapperRef}>
          <FilterButton
            filterValues={filterValues}
            isFilterPanelVisible={isFilterPanelVisible}
            setFilterPanelVisible={setFilterPanelVisible}
            filterOptionsLoading={false}
            comparativeIndex={0}
            updateLocalFilterValues={setFilterValues}
          />
          <SearchButton
            filterValues={filterValues}
            comparativeIndex={0}
            updateLocalFilterValues={setFilterValues}
          />
        </ButtonWrapper>
        {isSearchPanelVisible && (
          <div style={{ position: 'relative' }}>
            <SearchPanelWrapper>
              <SearchPanel
                filterValues={filterValues}
                closePanel={() => setSearchPanelVisible(false)}
                comparativeIndex={0}
                updateLocalFilterValues={setLocalFilterValues}
              />
            </SearchPanelWrapper>
          </div>
        )}
        {!!filterValues.values.length && (
          <div style={{ marginTop: 12, marginLeft: 5 }}>
            <PanelBadges
              filterValues={filterValues}
              comparativeIndex={0}
              updateLocalFilterValues={setLocalFilterValues}
              openFilterPanel={(field) => {
                updateSelectedOptionField(0, field)
                updateOpenedOptions(0, field)
                setFilterPanelVisible(true)
              }}
              countThreshold={100}
            />
          </div>
        )}
      </ControlsCard>
    </Section>
  )
}

export default AlertDefinition
