import React from 'react'
import { UploadType } from 'utils/enums'
import { FormikType } from './new-source'
import * as sources from './sources'
import { transformSourceType } from '../utils'

export type SourceProps = {
  sourceType: string
  formik: FormikType
}

const { File, Url, Amazon, ...sourceList }: any = sources
export const customSources = Object.keys(sourceList)

export const SourceProvider = (props: SourceProps) => {
  if (!props.sourceType || props.sourceType.length === 0) return null
  const sourceType = transformSourceType(props.sourceType)
  if (customSources.includes(sourceType)) {
    const Component = sourceList[sourceType]
    return <Component {...props} />
  }
  if (props.sourceType === UploadType.File) {
    return <File {...props} />
  }
  if (props.sourceType === 'Amazon') {
    return <Amazon {...props} />
  }
  return <Url {...props} />
}
