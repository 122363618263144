import React from 'react'

const SuggestedViewsSvgIcon: React.FC = () => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11 0C4.92685 0 0 4.92685 0 11C0 17.0731 4.92685 22 11 22C17.0731 22 22 17.0731 22 11C22 4.92685 17.0731 0 11 0ZM10.4269 19.8176V12.9178H6.59118L12.1022 2.18236V9.08216H15.7946L10.4269 19.8176Z"
      fill="currentColor"
    />
  </svg>
)

export default SuggestedViewsSvgIcon
