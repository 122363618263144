import React, { useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import {
  AppliedFilterOption,
  ApplyFilterPayload,
  ApplyGroupFilterPayload,
  FilterOption,
} from 'features/project/features/filters/types'
import '../../../../../styles.css'
import { Filters } from 'features/project/types'
import SelectorHeader from './components/SelectorHeader/SelectorHeader'
import SelectorFooter from './components/SelectorFooter/SelectorFooter'
import SelectorBody from './components/SelectorBody/SelectorBody'

type Props = {
  children?: React.ReactNode
  option?: FilterOption
  appliedFilters: Array<AppliedFilterOption>
  applyFilter: (p: ApplyFilterPayload) => void
  applyGroupFilter: (p: ApplyGroupFilterPayload) => void
  applyFreeInputFilter: (p: ApplyGroupFilterPayload) => void
  applyEveryFilter: (search: string) => void
  clearFilters: (search: string) => void
  filterList: FilterOption[]
  filterListData: FilterOption[] | undefined
  parentFilters?: Filters
  isUnlinked?: boolean
  filterValues: Filters
  isLoading: boolean
}

export const FilterPanelSelector: React.FC<Props> = ({
  option: propOption,
  applyFilter,
  applyGroupFilter,
  applyFreeInputFilter,
  appliedFilters,
  clearFilters,
  applyEveryFilter,
  filterList,
  filterListData,
  parentFilters,
  isUnlinked,
  filterValues,
  isLoading,
}) => {
  const [search, setSearch] = useState('')
  const ref = useRef<any>()
  const [floatValues, setFloatValues] = useState(false)

  const option = useMemo(() => {
    return (
      (!!filterListData?.find((filter) => filter.field === propOption?.field)
        ?.filter_type &&
        filterListData?.find((filter) => filter.field === propOption?.field)) ||
      (!!filterList?.find((filter) => filter.field === propOption?.field)
        ?.filter_type &&
        filterList?.find((filter) => filter.field === propOption?.field)) ||
      propOption
    )
  }, [filterListData, filterList, propOption])

  if (!option) {
    return null
  }

  const currentAppliedFilter = appliedFilters.find(
    (filter) => filter.field === option.field,
  )

  // functions for applying filters
  // needs refactor
  const apply = (value: ApplyFilterPayload['value']) => {
    return applyFilter({ field: option.field, value: value })
  }
  const applySelect = (value: ApplyGroupFilterPayload['value']) => {
    return applyGroupFilter({ field: option.field, value: value })
  }
  const applyFreeInput = (value: ApplyGroupFilterPayload['value']) => {
    return applyFreeInputFilter({ field: option.field, value: value })
  }

  return (
    <Wrapper ref={ref}>
      <SelectorHeader
        option={option}
        search={search}
        setSearch={setSearch}
      ></SelectorHeader>
      <SelectorBody
        option={option}
        apply={apply}
        applySelect={applySelect}
        applyFreeInput={applyFreeInput}
        search={search}
        filterValues={filterValues}
        floatValues={floatValues}
        currentAppliedFilter={currentAppliedFilter}
        parentFilters={parentFilters}
        isUnlinked={isUnlinked}
        propOption={propOption}
        isLoading={isLoading}
      ></SelectorBody>
      <SelectorFooter
        option={option}
        search={search}
        currentAppliedFilter={currentAppliedFilter}
        applyEveryFilter={applyEveryFilter}
        clearFilters={clearFilters}
        floatValues={floatValues}
        setFloatValues={setFloatValues}
        isLoading={isLoading}
      ></SelectorFooter>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 1;
  flex-direction: column;
  display: flex;
  height: 100%;
`
