import { Divider, Slider } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { lightBlueFontColor } from 'assets/styles/variables'

type Props = {
  temperature: number
  setTemperature: (temperature: number) => void
}

const Temperature: React.FC<Props> = ({ temperature, setTemperature }) => {
  return (
    <Container>
      <Title>Temperature of PDP Changes:</Title>
      <Content>
        <Slider
          aria-label="Temperature"
          defaultValue={1}
          valueLabelDisplay="auto"
          step={1}
          marks={[
            {
              value: 1,
              label: 'Low',
            },
            {
              value: 2,
              label: 'Medium',
            },
            {
              value: 3,
              label: 'High',
            },
          ]}
          min={1}
          max={3}
          value={temperature}
          // @ts-ignore
          onChange={(e) => setTemperature(e.target?.value ?? 1)}
          style={{ maxWidth: 400, marginLeft: 10 }}
        />
        <Divider orientation={'vertical'} style={{ margin: '0px 20px' }} />
        <Description>
          {temperature === 1 && (
            <p>Introduces select keywords into existing copy</p>
          )}

          {temperature === 2 && (
            <p>Adds/removes feature bullets based on Yogi Theme data</p>
          )}
          {temperature === 3 && (
            <p>Rewrites entire copy based on Yogi Theme data</p>
          )}
        </Description>
      </Content>
    </Container>
  )
}

export default Temperature

const Container = styled.div`
  width: 100%;

  padding: 20px;

  background: white;
  color: ${lightBlueFontColor};
  box-shadow: 0px 4px 4px 0px #00000040;

  border-radius: 10px;
`
const Title = styled.div`
  font-size: 14px;
  font-weight: 600;
`

const Content = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 600;
`

const Description = styled.div`
  font-size: 14px;
  font-weight: 400;
`
