import React, { useEffect, useRef, useState } from 'react'
import {
  DashboardResponse,
  DashboardSettings,
  DashboardType,
} from '../../../types'
import { CloseOutlined, EditOutlined, PlusOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { useDashboardStore } from '../../../dashboardStore'
import { Tooltip } from 'antd'
import { Title } from '../../../../../../../components/Title'
import { YogiInput } from '../../../../../../../components/UI/YogiInput'
import { NewAnnotation } from './NewAnnotation'
import { AvatarWrapper } from '../../../../alerts/Components/AlertCard'
import { Annotation } from './Annotation'
import { updateDashboard } from '../../../model'
import { toast } from 'react-toastify'
import _ from 'lodash'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

type Props = {
  dashboard: DashboardResponse
  isPublic?: boolean
}

export const Annotations: React.FC<Props> = ({
  dashboard: dashboardResponse,
  isPublic,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const areAnnotationsOpen = useDashboardStore(
    (state) => state.areAnnotationsOpen
  )
  const setAreAnnotationsOpen = useDashboardStore(
    (state) => state.setAreAnnotationsOpen
  )
  const { dashboard } = dashboardResponse

  const [annotations, setAnnotations] = useState(
    dashboard.dashboard_settings.annotations || []
  )

  useEffect(() => {
    setAnnotations(dashboard.dashboard_settings.annotations || [])
  }, [dashboard])

  const ref = useRef<HTMLDivElement>(null)

  const deleteAnnotation = (id: string) => {
    setAnnotations(annotations.filter((annotation) => annotation.id !== id))

    const newSettings = _.cloneDeep(dashboard.dashboard_settings)
    newSettings.annotations = annotations.filter(
      (annotation) => annotation.id !== id
    )
    const updatedDash = updateDashboard(projectId, {
      dashboard_id: dashboard.id,
      name: dashboard.name,
      dashboard_settings: newSettings,
    })
      .then((res) => {
        // toast.success('Chart added to dashboard')
      })
      .catch((err) => {
        toast.error('Error adding comment')
        annotations.pop()
        setAnnotations(annotations)
      })
  }

  if (!dashboard) return <div></div>
  return (
    <Drawer ref={ref} isOpen={areAnnotationsOpen} isPublic={!!isPublic}>
      <Wrapper>
        <Tooltip title="Annotations" placement={'left'}>
          <Arrow onClick={() => setAreAnnotationsOpen(!areAnnotationsOpen)}>
            <EditOutlined
              style={{
                opacity: '.5',
              }}
            />
          </Arrow>
        </Tooltip>
        <Content>
          <Header>
            <Title style={{ margin: 0 }}>Annotations</Title>
            <CloseOutlined
              onClick={() => setAreAnnotationsOpen(!areAnnotationsOpen)}
              style={{
                fontSize: 20,
                cursor: 'pointer',
                marginTop: -50,
                marginRight: -25,
              }}
            />
          </Header>

          <Body>
            <Section>
              {annotations.map((annotation) => (
                <Annotation
                  annotation={annotation}
                  deleteAnnotation={deleteAnnotation}
                />
              ))}
              <NewAnnotation
                annotations={annotations}
                dashboard={dashboard}
                setAnnotations={setAnnotations}
              />
            </Section>
          </Body>
        </Content>
      </Wrapper>
    </Drawer>
  )
}
const Drawer = styled.div<{ isOpen: boolean; isPublic: boolean }>`
  position: absolute;
  z-index: ${(props) => (props.isOpen ? 101 : 100)};
  height: 100vh;
  width: 400px;
  background: white;
  right: 0;
  top: 0;
  margin-top: ${(props) => (props.isPublic ? '0px' : '-40px')};
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};
  //box-shadow: -16px 0 16px rgba(17, 17, 26, 0.1);
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`
const Arrow = styled.div`
  position: absolute;
  z-index: -1;
  top: calc(45vh - 45px);
  left: -40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  background: white;
  cursor: pointer;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  box-shadow: -8px 4px 8px rgba(17, 17, 26, 0.1),
    -4px -4px 8px rgba(17, 17, 26, 0.1);
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  z-index: 200;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 30px 50px 10px;
  box-shadow: 0px 25px 100px -20px rgba(50, 50, 93, 0.05),
    0px 15px 60px -30px rgba(0, 0, 0, 0.1);
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  //padding: 0px 50px;
`

const Section = styled.div`
  position: relative;
  margin: 20px;
`
