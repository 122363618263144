import { Modal } from 'antd'
import styled from 'styled-components'
import React, { useState } from 'react'
import TextArea from 'antd/lib/input/TextArea'
import { sendFeedbackText } from '../../features/project/model'
import { toast } from 'react-toastify'
import { PostDetails, ProjectState } from '../../features/project/types'
import { useProjectStore } from '../../features/project/projectStore/projectStore'

/**
 * @component ProvideFeedbackModal
 *
 * A modal enabling users to submit feedback
 *
 * TODO should be more generic, (post, fragment, and proj context should be optional)
 * TODO is there a way to implement this so that every parent doesn't need to manage its state?
 *   maybe use a Context so pass around setVisible()?
 */
export const ProvideFeedbackModal: React.FC<{
  visible: boolean
  hide: () => void
  post?: PostDetails
  fragment: boolean
}> = ({ visible, hide, post, fragment }) => {
  const [feedbackText, setFeedbackText] = useState<string>()
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const onOk = () => {
    if (post) {
      sendFeedbackText(post.post_uuid, projectId, fragment, feedbackText)
        .then((res) => {
          if ((res.data as any).success) {
            hide()
            toast.info('Feedback received!')
          } else {
            toast.error('Failed to send feedback, please try again')
          }
        })
        .catch((e) => {
          toast.error(e.message)
        })
    }
  }

  return (
    <Modal
      title="Provide Feedback"
      visible={visible}
      onOk={onOk}
      onCancel={hide}
      okText="Submit"
    >
      <SubText>
        Please let us know if you see any erroneous data. We use this feedback
        to improve the Yogi AI.
      </SubText>
      <TextArea
        rows={4}
        onChange={(e) => setFeedbackText(e.target.value)}
      ></TextArea>
    </Modal>
  )
}

const SubText = styled.div`
  padding-bottom: var(--default-padding-half);
`
