import { axiosInstance } from 'utils/axios'
import {
  POST_TEXT_URL,
  POST_DETAILS_URL,
  POST_FEEDBACK_URL,
  PROJECT_DELETE_URL,
  PROJECT_DETAILS_URL,
  PROJECT_ID_URL,
  PROJECT_LIST_URL,
  PROJECT_SETTINGS,
  PROJECT_COMPASS,
} from 'utils/apiLinks'
import { AxiosResponse, CancelTokenSource } from 'axios'
import {
  Project,
  PostDetails,
  ProjectDetails,
  CreateProjectResponse,
  CompassResponse,
  Filters,
} from './types'

/**
 *
 * @param {string} proj_title - The project title
 * @returns {Promise<CreateProjectResponse>>} Project UUID
 */
export const getProjectId = (
  proj_title: string
): Promise<AxiosResponse<CreateProjectResponse>> =>
  axiosInstance.get(PROJECT_ID_URL, { params: { proj_title } })

/**
 * Fetches project details
 * @param {string} proj_uuid - The project id
 * @returns {Promise<ProjectDetails>} List of projects
 */
export const getProject = async (
  proj_uuid: string
): Promise<ProjectDetails> => {
  const { data }: AxiosResponse<ProjectDetails> = await axiosInstance.get(
    PROJECT_DETAILS_URL,
    { params: { proj_uuid } }
  )
  const { data: clientSettings } = await axiosInstance.get(PROJECT_SETTINGS, {
    params: { proj_uuid },
  })

  return { ...data, clientSettings }
}

/**
 * Fetches project list
 * @returns {Promise<Project[]>} List of projects
 */
export const fetchProjects = (): Promise<AxiosResponse<Project[]>> =>
  axiosInstance.get(PROJECT_LIST_URL)

/**
 * Removes the project
 * @param {string} proj_uuid - The project UUID
 * @returns nothing
 */
export const removeProject = (
  proj_uuid: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.delete(PROJECT_DELETE_URL, { params: { proj_uuid } })

/**
 * Fetches node details
 * @param {string} post_uuid - The node UUID
 * @param {boolean} fragment - Flag to show specific data
 * @param {CancelTokenSource} cancelToken - Axios token to cancel the request
 * @returns {Promise<PostDetails>} Node details
 */
export const getPostDetails = (
  post_uuid: string,
  fragment: boolean,
  cancelToken?: CancelTokenSource
): Promise<AxiosResponse<PostDetails>> =>
  axiosInstance.get(POST_DETAILS_URL, {
    params: { post_uuid, fragment },
    cancelToken: cancelToken?.token,
  })

/**
 * Fetches node text
 * @param {string} post_uuid - The node UUID
 * @param {CancelTokenSource} cancelToken - Axios token to cancel the request
 * @returns {Promise<string>} Node text
 */
export const getPostText = (
  post_uuid: string,
  cancelToken?: CancelTokenSource
): Promise<AxiosResponse<string>> =>
  axiosInstance.get(POST_TEXT_URL, {
    params: { post_uuid },
    cancelToken: cancelToken?.token,
  })

/**
 * Send feedback about a specific post
 * @param {string} post_uuid - The node UUID
 * @param proj_uuid
 * @param fragment
 * @param feedback
 * @returns nothing
 */
export const sendFeedbackText = (
  post_uuid: string,
  proj_uuid: string,
  fragment: boolean,
  feedback: string | undefined
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(
    POST_FEEDBACK_URL,
    { feedback: feedback },
    { params: { post_uuid, proj_uuid, fragment } }
  )

/**
 * Makes the node favorite/unfavorite
 * @param {string} post_uuid - The node UUID
 * @param {boolean} star - The favorite state
 * @returns nothing
 */
export const setPostStarred = (
  post_uuid: string,
  star: boolean = true
): Promise<AxiosResponse<void>> =>
  axiosInstance.put(POST_DETAILS_URL, { star }, { params: { post_uuid } })

/**
 * Fetches project details
 * @param {string} proj_uuid - The project UUID
 * @param {Filters} filters - The filter config
 * @returns {Promise<ProjectDetails>} List of projects
 */
export const getCompassDetails = (
  proj_uuid: string,
  filters: Filters
): Promise<CompassResponse> =>
  axiosInstance
    .post(PROJECT_COMPASS, filters, { params: { proj_uuid } })
    .then(({ data }) => data)
