import React, { useState } from 'react'
import styled from 'styled-components'
import { useHistory } from 'react-router-dom'
import {
  ArrowLeftOutlined,
  MinusCircleOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import { Button, Form, Input, Select } from 'antd'
import { requestDashboard } from '../models'
import { toast } from 'react-toastify'

export const RequestDashboardForm: React.FC<any> = () => {
  const [productsPayload, setProductsPayload] = useState<string[]>([])
  const [retailersPayload, setRetailersPayload] = useState<string[]>([])
  const [isSuccesfullySent, setSuccesfullySent] = useState<boolean>(false)
  const history = useHistory()

  const handleProductsChange = (val: string[]) => {
    setProductsPayload(val)
  }

  const handleRetailersChange = (val: string[]) => {
    setRetailersPayload(val)
  }

  const handleSubmit = async (value: any) => {
    try {
      await requestDashboard(value)
      setSuccesfullySent(true)
    } catch (e) {
      toast.error(
        'Failed to send your form. Try again later, or contact our support team if the issue persists.'
      )
    }
  }

  return (
    <>
      <FormWrapper>
        {!isSuccesfullySent ? (
          <FormStyled onFinish={handleSubmit}>
            <div style={{ width: '100%' }}>
              <GoBack onClick={() => history.goBack()}>
                <ArrowLeftOutlined />
                Return
              </GoBack>
              <FormLabelLeft>
                <h1 style={{ fontWeight: 500 }}>Requets Dashboard</h1>
              </FormLabelLeft>
              <FormLabelLeft>
                <h2 style={{ fontWeight: 450 }}>
                  Tell us what kind of products you are interested for:
                </h2>
              </FormLabelLeft>
              <Form.List
                name="products"
                rules={[
                  {
                    validator: async (_, names) => {
                      if (!names) {
                        return Promise.reject(new Error('At least 1 product'))
                      }
                    },
                  },
                ]}
              >
                {(productFields, { add, remove }, { errors }) => (
                  <>
                    {productFields.map((productField, index) => (
                      <>
                        <Form.Item
                          name={[productField.name, 'product']}
                          validateTrigger={['onChange', 'onBlur']}
                          rules={[
                            {
                              required: true,
                              whitespace: true,
                              message:
                                'Please input product name or delete this field.',
                            },
                          ]}
                          noStyle
                        >
                          <StyledInput placeholder="Product name" />
                        </Form.Item>
                        {productFields.length > 1 ? (
                          <MinusCircleOutlined
                            className="dynamic-delete-button"
                            style={{ marginLeft: '4px' }}
                            onClick={() => remove(productField.name)}
                          />
                        ) : null}
                        <div style={{ paddingLeft: '24px' }}>
                          <Form.List
                            name={[productField.name, 'retailers']}
                            rules={[
                              {
                                validator: async (_, names) => {
                                  if (!names) {
                                    return Promise.reject(
                                      new Error('At least 1 retailer')
                                    )
                                  }
                                },
                              },
                            ]}
                          >
                            {(retailersFields, { add, remove }, { errors }) => (
                              <>
                                {retailersFields.map(
                                  (retailersField, index) => (
                                    <>
                                      <Form.Item
                                        {...retailersField}
                                        name={[retailersField.name, 'retailer']}
                                        validateTrigger={['onChange', 'onBlur']}
                                        rules={[
                                          {
                                            required: true,
                                            whitespace: true,
                                            message:
                                              'Please input product name or delete this field.',
                                          },
                                        ]}
                                        noStyle
                                      >
                                        <StyledInput placeholder="Retailer name or url" />
                                      </Form.Item>
                                      {retailersFields.length > 1 ? (
                                        <MinusCircleOutlined
                                          className="dynamic-delete-button"
                                          style={{ marginLeft: '4px' }}
                                          onClick={() =>
                                            remove(retailersField.name)
                                          }
                                        />
                                      ) : null}
                                    </>
                                  )
                                )}
                                <Form.Item>
                                  <Button
                                    type="dashed"
                                    onClick={() => add()}
                                    style={{ width: '60%' }}
                                    icon={<PlusOutlined />}
                                  >
                                    Add retailer
                                  </Button>
                                  <Form.ErrorList errors={errors} />
                                </Form.Item>
                              </>
                            )}
                          </Form.List>
                        </div>
                      </>
                    ))}
                    <Form.Item>
                      <Button
                        type="dashed"
                        onClick={() => add()}
                        style={{ width: '60%' }}
                        icon={<PlusOutlined />}
                      >
                        Add product
                      </Button>
                      <Form.ErrorList errors={errors} />
                    </Form.Item>
                  </>
                )}
              </Form.List>

              {/* <FormLabelLeft>
                <h2 style={{ fontWeight: 450 }}>
                  Tell us what kind of retailers you are interested for:
                </h2>
              </FormLabelLeft> */}
              {/* <Form.Item
                name="retailers"
                rules={[
                  { required: true, message: 'Specify one or more retailers' },
                ]}
              >
                <StyledSelect
                  mode="tags"
                  placeholder="Write retailer names or urls separated by key press Enter"
                  onChange={(val: any) => handleRetailersChange(val)}
                  dropdownStyle={{ display: 'none' }}
                />
              </Form.Item> */}
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'end',
                width: '100%',
                paddingTop: '54px',
              }}
            >
              <Button htmlType="submit">Request</Button>
            </div>
          </FormStyled>
        ) : (
          <FormStyled>
            <div>
              <GoBack onClick={() => history.goBack()}>
                <ArrowLeftOutlined />
                Return
              </GoBack>
              <FormLabelLeft>
                <h1 style={{ fontWeight: 500 }}>Requets Dashboard</h1>
              </FormLabelLeft>
              <FormLabelLeft>
                <h3>
                  Your request have succesfully sent! Our team will contact with
                  you as soon as possible.
                </h3>
              </FormLabelLeft>
            </div>
          </FormStyled>
        )}
      </FormWrapper>
    </>
  )
}

const StyledInput = styled(Input)`
  width: calc(100% - 40px);
  margin-bottom: 16px;
`

const StyledSelect = styled(Select)`
  width: 100%;
`

const FormLabelLeft = styled.div`
  margin-top: 12px;
  width: 100%;
  justify-content: start;
`

const FormWrapper = styled.div`
  width: 100%;
  height: 100vh;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: lightgray;
`

const FormStyled = styled(Form)`
  display: flex;
  flex-direction: column;
  flex-wrap: no-wrap;
  justify-content: space-between;
  align-items: center;
  width: 600px;
  padding: 12px;
  padding-top: 40px;
  border-radius: 8px;
  background-color: white;
  min-height: 550px;
  max-height: 90vh;
  position: relative;
  overflow-y: auto;
  overflow-x: hidden;
`

const GoBack = styled.a`
  width: 100%;
  position: absolute;
  top: 12px;
  left: 12px;
  color: black;

  svg {
    margin-right: 10px;
  }

  @media screen and (max-width: 1024px) {
    display: none;
  }
`
