import styled from 'styled-components'
import {
  darkBlue,
  deselectedFontColor,
  selectedFontColor,
  lightBlueFontColor,
} from '../../../../../../../../assets/styles/variables'

export const Container = styled.div``

export const Tabs = styled.div`
  display: flex;
  justify-content: space-around;
`

export const Tab = styled.div<{ isSelected: boolean }>`
  text-align: center;
  padding: 10px 10px;
  cursor: pointer;
  color: ${(props) =>
    props.isSelected ? selectedFontColor : deselectedFontColor};
  font-weight: ${(props) => (props.isSelected ? 500 : 'normal')};
  &:hover {
    color: ${selectedFontColor};
  }
  min-width: 50px;
`
