import React, { useEffect } from 'react'
import { Switch, Route, useRouteMatch } from 'react-router-dom'
import styled from 'styled-components'
import { RequestDashboardForm } from '../components/request-dashboard-form'
import { requestDasboardURL } from 'utils/links'

export const TrialContainer = React.memo(() => {
  const { path } = useRouteMatch()

  useEffect(() => {}, [])

  return (
    <Wrapper>
      <Switch>
        <Route exact path={`${path}${requestDasboardURL}`}>
          <RequestDashboardForm />
        </Route>
      </Switch>
    </Wrapper>
  )
})

const Wrapper = styled.div`
  height: 100%;
  position: relative;
`
