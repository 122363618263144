import React from 'react'
import styled from 'styled-components'
import { SourceGroup } from '../types'
import { SourceCard } from './source-card'

type Props = {
  sources: SourceGroup[] | null
  syndicated: boolean
  projectId: string
  role: string
}

export const SourceList: React.FC<Props> = ({
  sources,
  syndicated,
  projectId,
  role,
}) => {
  return (
    <>
      {sources &&
        sources.map((source) => (
          <SourceBox key={source.source}>
            <SourceCard
              source={source}
              syndicated={syndicated}
              projectId={projectId}
              role={role}
            ></SourceCard>
          </SourceBox>
        ))}
    </>
  )
}

const SourceBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  position: relative;
`
