import React, { CSSProperties } from 'react'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'

type Props = { paddingTop?: boolean; small?: boolean; style?: CSSProperties }

export const Loader: React.FC<Props> = ({
  paddingTop = true,
  small = false,
  style,
}) => <LogoSpinner style={style} />
