import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { Content } from './sections/content'
import { Header } from './sections/header'
import { YogiButton } from '../../../../components/UI/YogiButton'
import { HeaderFilterPanel } from '../filters'
import NLInput from './NLInput'
import { useQuery } from 'react-query'
import { fetchGeminiAnalysis } from './model'
import { Loader } from '../../../../shared/components'
import LoadingText from '../../../../components/Loading/LoadingText'
import { ArrowRightOutlined } from '@ant-design/icons'
import { YogiTextArea } from '../../../../components/UI/YogiTextArea'
import { Button, Radio, RadioChangeEvent } from 'antd'

const questions = [
  'What are the top 3 frustrations of users?',
  'What customer concerns are trending up?',
  'How do these reviews differ by retailer? What can we do to improve our Retailer Strategy?',
  'Why are sales stagnating/declining? How can we address these issues?',
  'What are people most confused about when using the products in this set of reviews?',
  'What are opportunities for these Brands? What themes belong to the different SWOT categories for the selected reviews?',
  'What are threats to Brands mentioned in the selected reviews?',
]

const Gemini: React.FC = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const comparativeIndex = 0
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex],
  )

  const [question, setQuestion] = React.useState<string>('')
  const [userInput, setUserInput] = React.useState<string>('')
  const [model, setModel] = useState('gemini-1.5-flash-latest')

  const body = {
    tier1: 'Brand',
    chart_ids: [
      {
        chart_id: '5_5_7_rating_sentiment',
      },
    ],
    criteria: filterValues.values,
    search_terms: filterValues.searchQuery,
    search_criteria: filterValues.searchCondition,
    post_type: 'post',
    reviews_filter: 0,
  }

  const {
    data: geminiResponse,
    isLoading: geminiLoading,
    isFetching: geminiFetching,
    isError: geminiError,
    refetch,
  } = useQuery(
    ['gemini', projectId, question, filterValues, model],
    () => fetchGeminiAnalysis(projectId, question, body, model),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: false, //!!projectId && !!question,
      retry: false,
    },
  )

  // this is temporary
  useEffect(() => {
    refetch()
  }, [question])

  const handleModelChange = (e: RadioChangeEvent) => {
    setModel(e.target.value)
  }

  // const { data: chartId, isLoading: chartIdLoading } = useQuery(
  //   ['gemini', projectId, chartQuestion],
  //   () => getChartId(projectId, chartQuestion),
  //   {
  //     refetchOnWindowFocus: false,
  //     refetchOnMount: false,
  //     cacheTime: Infinity,
  //     staleTime: Infinity,
  //     enabled: !!projectId && !!chartQuestion,
  //     retry: false,
  //   }
  // )

  return (
    <Container>
      <Header />
      <Divider />
      <HeaderFilterPanel
        filterPanelHeight={`calc(100vh - 180px - ${40}px)`}
        filterValues={filterValues}
        comparativeIndex={comparativeIndex}
      />
      <Section>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h2>Suggested Questions</h2>
          <Radio.Group value={model} onChange={handleModelChange}>
            <Radio.Button value="gemini-1.5-flash-latest">Flash</Radio.Button>
            <Radio.Button value="gemini-1.5-pro-latest">Pro</Radio.Button>
          </Radio.Group>
        </div>
        <QuestionList>
          {questions.map((q) => (
            <Question>
              <YogiButton
                type={q === question ? 'primary' : 'dashed'}
                onClick={() => {
                  setQuestion(q)
                }}
              >
                {q}
              </YogiButton>
            </Question>
          ))}
        </QuestionList>

        <div style={{ marginTop: 20 }}>
          <h2>Ask Yogi</h2>
          <div
            style={{
              backgroundColor: '#E8F5E9',
              borderRadius: '10px',
              padding: '8px 12px',
              width: '100%',
              whiteSpace: 'pre-wrap',
            }}
          >
            <div style={{ display: 'flex', gap: 10 }}>
              <YogiTextArea
                placeholder={'Ask Yogi a question'}
                value={userInput}
                onChange={(e) => setUserInput(e.target.value)}
                autoSize={{ minRows: 1, maxRows: 6 }}
              />
              <YogiButton
                type={'primary'}
                onClick={() => {
                  setQuestion(userInput)
                }}
              >
                <ArrowRightOutlined />
              </YogiButton>
            </div>
          </div>
        </div>
      </Section>
      <Divider />

      {question && (
        <Section>
          <h2>{question}</h2>
          <AnswerContainer>
            {geminiLoading || geminiFetching ? (
              <div
                style={{
                  top: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                  // marginTop: 50,
                  marginBottom: 25,
                }}
              >
                <Loader style={{ height: '100%', maxHeight: 300 }} />
                <LoaderTitle>
                  <LoadingText
                    key={question}
                    strings={[
                      'Grabbing the Latest Reviews',
                      'Formulizing Prompt',
                      'Shipping off to Automated',
                      'Exercising Patience',
                      'Prettifying Response',
                    ]}
                    interval={8000}
                  />
                </LoaderTitle>
              </div>
            ) : (
              <>
                {geminiResponse && (
                  <NLInput
                    userMessage={geminiResponse.response}
                    aiResponse={'I am a Larg Language Model'}
                    isValid={geminiResponse.is_valid}
                    // chartId={chartId}
                  />
                )}
              </>
            )}
          </AnswerContainer>
        </Section>
      )}

      {geminiError && (
        <Section>
          <h2>Error</h2>
          <div>{'Error fetching data'}</div>
        </Section>
      )}
      <Button onClick={() => refetch()}>Refetch</Button>
      <Divider />
      {/*<div>Chart Carousel</div>*/}
      {/*<Divider />*/}
      <Content />
    </Container>
  )
}

export default Gemini

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  background: var(--color-background-grey);
  background: var(--card-background-white);
  overflow: auto;
`

const Divider = styled.div`
  width: 100%;
  border: 1px solid rgba(159, 183, 195, 0.15);
`

const Section = styled.div`
  margin: 20px 40px;
`

const QuestionList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

const Question = styled.div`
  flex-basis: 50%;
`
const AnswerContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`

const MessageForm = styled.div`
  //margin: 0 auto;
  //width: 100%;
  //box-sizing: border-box;
  //max-width: 850px;
  //text-align: center;
  //padding: 0px 45px 0 45px;
  //box-shadow: #2c2d30 0 0 50px;

  .message-wrapper {
    position: relative;
  }

  #message::placeholder {
    color: #9ca6b5;
  }

  #message {
    background: #383b42;
    border-radius: 13px;
    width: 100%;
    box-sizing: border-box;
    border: 1px solid #2f353d;
    resize: none;
    padding: 17px 85px 17px 15px;
    font-family: inherit;
    font-size: 1em;
    color: #fff;
    box-shadow: rgba(0, 0, 0, 0.2) 0 0 45px;
    outline: none;
  }

  .disclaimer {
    margin-top: 12px;
    color: #d0d2e1;
    font-size: 0.7em;
  }

  .send-button {
    position: absolute;
    right: 15px;
    top: 50%;
    transform: translateY(-50%);
    background: #5fc319;
    border-radius: 5px;
    display: inline-block;
    font-size: 1em;
    padding: 7px 9px 7px 7px;
    color: #fff;
    border: none;
    margin-top: -2px;
  }

  button.send-button:hover {
    border: none;
    background: #5fc319;
    color: #fff;
  }
`
