import React, { useCallback, useEffect, useState } from 'react'
import { PostDetails } from '../../types'
import { Divider, Modal, Table, Tooltip, Typography } from 'antd'
import { getPostDetails, setPostStarred } from '../../model'
import { highlightPost } from './post-utils'
import mixpanel from '../../../trackers/mixpanel'
import { Loader, SourceIcon } from 'shared/components'
import {
  detectCurrentRoute,
  SEARCH_HIGHLIGHT_COLOR,
} from 'features/project/utils'
import { PostIcons, Stars } from './'
import styled from 'styled-components'
import { postToCsv, PROMOTION_ENABLED_HIGHLIGHT_COLOR } from '../../utils'
import { toast } from 'react-toastify'
import { ProvideFeedbackModal } from 'shared/components/provide-feedback-modal'
import {
  CopyOutlined,
  FlagOutlined,
  LoadingOutlined,
  StarFilled,
  StarOutlined,
} from '@ant-design/icons'
import { format } from 'date-fns'
import Column from 'antd/lib/table/Column'
import moment from 'moment'

const DATE_FORMAT = 'MM/dd/yyyy'

const mixpanelTrack = (props: {
  event: string
  action?: string
  uuid: string
}) => {
  const { event, action, uuid } = props
  const currentRoute = detectCurrentRoute(window.location.pathname)
  const type = (currentRoute.isNetwork && 'sentence') || 'post'

  return mixpanel.track(event, {
    uuid,
    ...(action && { action }),
    ...(type && { type }),
  })
}

/**
 * @component PostDetailModal
 *
 * Displays the details of a single post in a modal, usable on any page.
 * This component handles its own data fetching.
 */
export const PostDetailModal: React.FC<{
  postUuid: string | undefined // if not defined, does not fetch or render
  fragment: boolean // fragment vs full review
  onClose: () => void
  onLoad?: () => void
}> = React.memo(({ postUuid, onClose, onLoad, fragment }) => {
  // STATE
  const [post, setPost] = useState<PostDetails>()
  const [isUpdatingFavorite, setIsUpdatingFavorite] = useState(false)
  const [isFeedbackModalVisible, setIsFeedbackModalVisible] = useState(false)

  // EFFECTS AND FUNCTIONS
  useEffect(() => {
    // TODO can this pattern be generalized into useAsyncEffect()?
    let isCancelled = false // simpler and more reliable than a cancel token

    const loadPostDetails = async () => {
      if (!postUuid) return

      // TODO 'feedback review' isn't the best name, since it's not only on the feedback page
      mixpanelTrack({ event: 'details', uuid: postUuid })

      const { data } = await getPostDetails(postUuid, fragment)
      if (isCancelled) return

      if (onLoad) {
        onLoad()
      }
      setPost(data)
    }
    loadPostDetails()

    return () => {
      isCancelled = true
    }
  }, [fragment, onLoad, postUuid])

  const _onClose = useCallback(() => {
    onClose()
    setPost(undefined)
  }, [onClose])

  const copyToCsv = useCallback(() => {
    navigator.clipboard
      .writeText(postToCsv(post))
      .then(() => toast.info('Copied to clipboard'))
  }, [post])

  const toggleFavorite = useCallback(async () => {
    if (!post) return
    if (isUpdatingFavorite) return
    setIsUpdatingFavorite(true)
    await setPostStarred(post.post_uuid, !post.star)
    setPost({ ...post, star: !post.star })
    setIsUpdatingFavorite(false)
  }, [isUpdatingFavorite, post])

  // RENDER BODY

  // we use visible instead of just returning <></> to preserve the close animation from Ant UI
  const isVisible = !!postUuid

  return (
    <>
      <Modal
        visible={isVisible}
        onCancel={_onClose}
        width={700}
        bodyStyle={{ height: '80vh', overflowY: 'scroll' }}
        centered
        footer={null}
        title={
          <ModalTitleBar
            post={post}
            fragment={fragment}
            isUpdatingFavorite={isUpdatingFavorite}
            onClickFavorite={toggleFavorite}
            onClickCopy={copyToCsv}
            onClickFlag={() => setIsFeedbackModalVisible(true)}
          />
        }
      >
        <ModalContent post={post} fragment={fragment} />
      </Modal>

      <ProvideFeedbackModal
        visible={isFeedbackModalVisible}
        hide={() => setIsFeedbackModalVisible(false)}
        post={post}
        fragment={fragment}
      />
    </>
  )
})

/**
 * @component ModalContent
 *
 * content of the modal (excluding title and footer)
 */
const ModalContent: React.FC<{
  post?: PostDetails
  fragment: boolean
}> = React.memo(({ post, fragment }) => {
  if (!post) return <Loader />

  return (
    <>
      {fragment && <PostFragmentSection post={post} />}
      {fragment && <Divider />}
      <PostOverviewSection post={post} />
      <Divider />
      <PostDetailsSection post={post} />
    </>
  )
})

const PostFragmentSection: React.FC<{ post: PostDetails }> = ({ post }) => {
  return (
    <>
      <Typography.Title level={5}>Sentence</Typography.Title>
      <Typography.Paragraph>
        <blockquote>{post.text}</blockquote>
      </Typography.Paragraph>
    </>
  )
}

const PostOverviewSection: React.FC<{
  post: PostDetails
}> = (props) => {
  const [isPromotionHovered, setPromotionHoverState] = useState(false)
  const [isViewableImage, setViewableImage] = useState(true)

  const [highlightedText, setHighlightedText] = useState<string>('')

  const customHighlight = (text: string) => {
    // console.log(highlightedText)
    // console.log(text)
    // if (!highlightedText || !isHyperCopy || !text) return text
    if (!highlightedText || !text) return text
    return text.replaceAll(
      highlightedText,
      `<Yogi-highlight>${highlightedText}</Yogi-highlight>`
    )
  }
  // useEffect(() => {
  //   if (resetView) {
  //     setHighlightedText('')
  //   }
  // }, [resetView])

  useEffect(() => {
    if (window?.getSelection()?.toString()) {
      // @ts-ignore
      setHighlightedText(window?.getSelection()?.toString().trim())
    }
  }, [window?.getSelection()?.toString()])

  const [renderHack, setRenderHack] = useState(false)

  return (
    <>
      <Typography.Title level={5}>Review</Typography.Title>
      <div style={{ display: 'flex' }}>
        {isViewableImage && props.post.product_image_url && (
          <ProductImg
            src={props.post.product_image_url}
            onError={() => setViewableImage(false)}
          />
        )}

        <div style={{ flex: 1 }}>
          {/* Stars + date */}
          <Typography.Paragraph style={{ display: 'flex' }}>
            <Stars score={props.post.score} style={{ flex: 1 }} />
            <Typography.Text type={'secondary'}>
              {moment.unix(props.post.create_time).utc().format('MM/DD/yyyy')}
            </Typography.Text>
          </Typography.Paragraph>

          {/* Title + text*/}
          {props.post.title && (
            <Typography.Text strong>{props.post.title}</Typography.Text>
          )}
          <Typography.Paragraph
            id={'review-content'}
            style={{ overflowWrap: 'anywhere' }}
          >
            <div
              onMouseDown={() => {
                setRenderHack((prevState) => !prevState)
              }}
              onMouseUp={() => {
                setRenderHack((prevState) => !prevState)
              }}
            >
              {props.post.is_promoted
                ? highlightPost({
                    postBody: customHighlight(props.post.body),
                    color: PROMOTION_ENABLED_HIGHLIGHT_COLOR,
                    showHighlights: isPromotionHovered,
                  })
                : highlightPost({
                    postBody: customHighlight(props.post.body),
                    color: SEARCH_HIGHLIGHT_COLOR,
                    showHighlights: true,
                  })}
            </div>
          </Typography.Paragraph>
          <SourceAndIcons
            post={props.post}
            onPromoHover={setPromotionHoverState}
          />
        </div>
      </div>
    </>
  )
}

const PostDetailsSection: React.FC<{
  post: PostDetails
}> = ({ post }) => {
  return (
    <>
      <Typography.Title level={5}>Details</Typography.Title>
      <Table
        dataSource={getPostDataForTable(post)}
        showHeader={false}
        pagination={false}
        size="small"
      >
        <Column title="Title" dataIndex={'title'} />
        <Column title="Value" dataIndex={'value'} />
      </Table>
    </>
  )
}

/**
 * @component SourceAndIcons
 *
 * source, author, and icons like verified, recommended, etc
 */
const SourceAndIcons: React.FC<{
  post: PostDetails
  onPromoHover: (isHover: boolean) => void
}> = ({ post, onPromoHover }) => {
  const iconSource = post.syndicated_source ? 'syndicated' : post.source_type

  return (
    <Typography.Paragraph style={{ display: 'flex' }}>
      <Typography.Text
        type={'secondary'}
        style={{ flex: 1, textAlign: 'left' }}
      >
        <StyledSourceIcon source={iconSource} />
        {post.source_type} · {post.username}
      </Typography.Text>

      <PostIcons
        is_promoted={post.is_promoted}
        is_recommended={post.is_recommended}
        is_verified={post.is_verified}
        onPromoHover={onPromoHover}
      />
    </Typography.Paragraph>
  )
}

/**
 * @component ModalTitleBar
 *
 * includes a few buttons rendered next to the modal close button
 */
const ModalTitleBar: React.FC<{
  post?: PostDetails
  fragment: boolean
  isUpdatingFavorite: boolean
  onClickFlag: () => void
  onClickFavorite: () => void
  onClickCopy: () => void
}> = (props) => {
  const { post, fragment } = props

  if (!post) return <>Loading details...</>

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ flex: 1 }}>
        Feedback for <i>{post.product_display_name}</i>
      </div>

      <div style={{ paddingRight: '32px', textAlign: 'right' }}>
        <Tooltip title={'Provide Feedback'}>
          <TitleButton
            onClick={() => {
              props.onClickFlag()
              mixpanelTrack({
                event: 'details controls',
                uuid: post.post_uuid,
                action: 'provide feedback',
              })
            }}
          >
            <FlagOutlined />
          </TitleButton>
        </Tooltip>

        {/* only sentences can be favorited, for now */}
        {fragment && (
          <Tooltip title={post.star ? 'Un-favorite' : 'Favorite'}>
            <TitleButton onClick={props.onClickFavorite}>
              {props.isUpdatingFavorite ? (
                <LoadingOutlined />
              ) : post.star ? (
                <StarFilled />
              ) : (
                <StarOutlined />
              )}
            </TitleButton>
          </Tooltip>
        )}

        <Tooltip title={'Copy as TSV'}>
          <TitleButton
            onClick={() => {
              props.onClickCopy()
              mixpanelTrack({
                event: 'details controls',
                uuid: post.post_uuid,
                action: 'copy',
              })
            }}
          >
            <CopyOutlined />
          </TitleButton>
        </Tooltip>
      </div>
    </div>
  )
}

// HELPER FNs
////////////////////////////////////////////////////////////////////////////////

/**
 * Convert PostDetails into data object for ant <Table> component
 */

const getPostDataForTable = (post: PostDetails) => {
  const data: { title: string; value: any }[] = []

  const addRow = (title: string, value: any) => {
    if (value === undefined) return

    if (typeof value === 'boolean') {
      data.push({ title, value: value.toString().toUpperCase() })
    } else if (typeof value === 'number') {
      data.push({ title, value: value.toString() })
    } else if (typeof value === 'string') {
      data.push({ title, value: value })
    } else if (value === null) {
      data.push({ title, value: 'NULL' })
    } else if (value instanceof Date) {
      data.push({ title, value: format(value, DATE_FORMAT) })
    } else {
      data.push({ title, value })
    }
  }

  // addRow('Original Title', post.original_title)
  // addRow('Original Body', post.original_body)
  // addRow('Language', post.language)

  addRow('Date', moment.unix(post.create_time).utc().format('MM/DD/yyyy'))

  addRow('Item', post.product)
  addRow('Product', post.product_hierarchy)
  addRow('Brand', post.brand)

  addRow('Source Type', post.source_type)
  addRow('Source', post.source)

  addRow(
    'Review URL',
    <a
      href={post.review_url}
      onClick={() =>
        mixpanelTrack({
          event: 'details controls',
          action: 'review url',
          uuid: post.post_uuid,
        })
      }
      rel="noopener noreferrer"
      target="_blank"
    >
      {post.review_url}
    </a>
  )

  addRow(
    'Source URL',
    <a
      href={post.source_url}
      onClick={() =>
        mixpanelTrack({
          event: 'details controls',
          action: 'source url',
          uuid: post.post_uuid,
        })
      }
      rel="noopener noreferrer"
      target="_blank"
    >
      {post.source_url}
    </a>
  )

  addRow('Country', post.country)

  addRow('Product Group', post.product_group)
  addRow('Rating', post.score)
  addRow('Sentiment', post.sentiment)
  addRow('Themes', post.themes)
  addRow('Username', post.username)
  addRow('Location', post.location)
  addRow('Promotion', post.promotion)

  // Hiding these for now, to avoid duplicating data, but we might want in the future
  // addRow('Promoted', post.is_promoted)
  // addRow('Verified', post.is_verified)
  // addRow('Recommended', post.is_recommended)

  // TODO this might be useful to do higher up or even on the backend
  const productCustom = post.product_custom ?? {}
  const sourceCustom = post.source_custom ?? {}
  const postCustom = post.custom ?? {}
  const postTextCustom = post.post_text_custom ?? {}

  const customs = [postTextCustom, postCustom, productCustom, sourceCustom]
  customs.forEach((custom) => {
    Object.keys(custom).map((key) => addRow(key, custom[key]))
  })
  return data
}

// STYLES
////////////////////////////////////////////////////////////////////////////////

const ProductImg = styled.img`
  object-fit: contain;
  max-width: 50%;
  max-height: 200px;
  padding: 20px;
`

const StyledSourceIcon = styled(SourceIcon)`
  // TODO make this not black once all SourceIcons can be colored
  //  (no hardcoded fills in the SVGs)
  color: black;
  height: 1em;
  width: 1em;
  margin-right: 5px;
  vertical-align: -0.125em;
`

const TitleButton = styled.span`
  padding: 18px 20px;
  color: rgba(0, 0, 0, 0.45);

  &:hover {
    color: rgba(0, 0, 0, 0.75);
    cursor: pointer;
  }
`
