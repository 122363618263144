import { Button, Popover } from 'antd'
import mixpanel from '../../../../trackers/mixpanel'
import React from 'react'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { TimeInterval } from '../types'
import _ from 'lodash'
import {
  buttonBlue,
  cardBorderGrey,
  mainText,
} from '../../../../../assets/styles/variables'
import {
  ArrowDownOutlined,
  CloseOutlined,
  CopyOutlined,
  DownOutlined,
} from '@ant-design/icons'

type Props = {
  updateLocalDashboardControls?: (key: string, value: string) => void
  updateDashboardControls: (index: number, key: string, value: string) => void
  comparativeIndex: number
  comparePeriod?: string
  timeInterval?: TimeInterval
  isPreviousChartLoading?: boolean
}

const periodOptions = [
  { label: '12 Months', value: 'year' },
  { label: '6 Months', value: 'half' },
  { label: '3 Months', value: 'quarter' },
  { label: 'Month', value: 'month' },
  { label: 'Week', value: 'week' },
] //, 'week', 'quarter']

const CompareInput: React.FC<Props> = ({
  updateLocalDashboardControls,
  updateDashboardControls,
  comparativeIndex,
  comparePeriod,
  timeInterval = 'month',
  isPreviousChartLoading,
}) => {
  const route = useProjectStore((state: ProjectState) => state.route)

  const validPeriods = periodOptions.slice(
    0,
    periodOptions.findIndex((el) => el.value === (timeInterval || 'month')) + 1,
  )

  const compareLabel = periodOptions.find(
    (el) => el.value === comparePeriod,
  )?.label

  return (
    <Popover
      trigger={'hover'}
      content={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '5px',
          }}
        >
          {validPeriods.map((period) => (
            <Button
              onClick={() => {
                mixpanel.track('compare previous', {
                  ...route,
                  value: period,
                })
                updateLocalDashboardControls
                  ? updateLocalDashboardControls('comparePeriod', period.value)
                  : updateDashboardControls(
                      comparativeIndex,
                      'comparePeriod',
                      period.value,
                    )
              }}
            >
              Previous {_.startCase(period.label)}
            </Button>
          ))}
        </div>
      }
      placement="bottom"
      overlayClassName="compare-popover"
    >
      <Button
        type="default"
        style={{
          border: `2px solid ${!!comparePeriod ? buttonBlue : cardBorderGrey}`,
          boxShadow: 'none',
          marginRight: '10px',
          color: !!comparePeriod ? '#fff' : mainText,
          background: !!comparePeriod ? buttonBlue : '#fff',
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderRadius: 10,
        }}
        loading={isPreviousChartLoading}
      >
        {!comparePeriod ? 'Compare' : `Comparing to previous ${compareLabel}`}
        {!comparePeriod ? (
          <DownOutlined />
        ) : (
          <Button
            type="text"
            icon={<CloseOutlined />}
            onMouseEnter={(e) => {
              e.stopPropagation()
            }}
            onMouseLeave={(e) => {
              e.stopPropagation()
            }}
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              mixpanel.track('compare previous', {
                ...route,
                value: '',
              })
              updateLocalDashboardControls
                ? updateLocalDashboardControls('comparePeriod', '')
                : updateDashboardControls(comparativeIndex, 'comparePeriod', '')
            }}
            style={{
              border: `2px solid ${cardBorderGrey}`,
              borderRadius: '4px',
              boxShadow: 'none',
              height: 18,
              padding: 0,
              marginLeft: '10px',
              background: '#fff',
            }}
          ></Button>
        )}
      </Button>
    </Popover>
  )
}

export default CompareInput
