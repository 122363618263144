import { useQuery } from 'react-query'
import { fetchFilterOptions } from '../react-query-functions'
import { useProjectStore } from '../projectStore/projectStore'
import { Filters } from '../types'
import { FilterOption } from '../features/filters/types'

export const useLocalFilterList = (
  filterValues: Filters,
  selectedOption: string,
  openedOptions: string[],
  filterList: FilterOption[],
  setFilterList: (filterList: FilterOption[]) => void,
  disabled?: boolean
) => {
  const projectId = useProjectStore((state) => state.projectId)

  // TODO request just the one selected option field and cache the prior values
  return useQuery(
    [
      'filter-list-data',
      projectId,
      selectedOption ? [selectedOption] : openedOptions,
      filterValues,
    ],
    async () => {
      const data = await fetchFilterOptions(
        projectId,
        selectedOption ? [selectedOption] : openedOptions,
        filterValues
      )
      if (data) {
        let temp: FilterOption[] = JSON.parse(JSON.stringify(filterList))
        if (temp.length > 0 && openedOptions.length) {
          temp.forEach((el, index) => {
            temp[index] = { ...el, ...data[index] }
          })
        } else {
          temp = data
        }
        setFilterList(temp)
      }
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && !disabled,
    }
  )
}
