import type { ProductTransferEntity } from './types'

export enum TransferEntities {
  HIERARCHY = 'product',
  GROUP = 'group',
}

export const TransferEntitiesAvailableParam = {
  [TransferEntities.HIERARCHY]: 'No Product',
  [TransferEntities.GROUP]: 'No Item Group',
}

export const TransferEntityNames = {
  [TransferEntities.HIERARCHY]: 'hierarchy',
  [TransferEntities.GROUP]: 'group',
}

const RIGHT_ENTITY_SUFFIX = '-right'

export function addSuffix(entities: ProductTransferEntity[]) {
  return entities.map((e) => ({
    ...e,
    uuid: e.uuid + RIGHT_ENTITY_SUFFIX,
  }))
}

export function removeSuffix(entities: string[]) {
  return entities.map((e) => e.replace(RIGHT_ENTITY_SUFFIX, ''))
}
