import { axiosInstance } from '../../../../utils/axios'

const NOTIFICATIONS_BASE_URL = '/notifications'

export { NOTIFICATIONS_BASE_URL }

export type NotificationsResponse = {
  id: number
  acknowledged_at: string
  body_html: string
  created_at: string
  info: string
  icon: string
  link: string
  model_id: number
  model_name: string
  notification_type: string
}
export const getNotifications = async (
  proj_uuid: string
): Promise<NotificationsResponse[]> => {
  const { data } = await axiosInstance.get<NotificationsResponse[]>(
    `${NOTIFICATIONS_BASE_URL}`,
    {
      params: {
        only_unacknowledged: false,
        proj_uuid: proj_uuid,
      },
    }
  )

  return data
}
export const markRead = async (
  proj_uuid: string,
  alert_uuid: number
): Promise<NotificationsResponse[]> => {
  const { data } = await axiosInstance.put<NotificationsResponse[]>(
    `${NOTIFICATIONS_BASE_URL}/${alert_uuid}/read`,
    {},
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )

  return data
}
export const markUnread = async (
  proj_uuid: string,
  alert_uuid: number
): Promise<NotificationsResponse[]> => {
  const { data } = await axiosInstance.put<NotificationsResponse[]>(
    `${NOTIFICATIONS_BASE_URL}/${alert_uuid}/unread`,
    {},
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )

  return data
}
