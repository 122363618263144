import React, { useState } from 'react'
import { Select } from 'antd'

const { Option } = Select

type Props = {
  value: string
  onChange: (s: string) => void
  className?: string
}

const intervals = ['year', 'quarter', 'month', 'week']

export const IntervalSelector: React.FC<Props> = ({
  value,
  onChange,
  className,
}) => {
  const [current, setCurrent] = useState<string>(value)

  return (
    <div style={{ marginTop: '10px', width: '100%' }}>
      <div>Time Interval</div>
      <Select
        className={className}
        value={current}
        onChange={(value) => {
          setCurrent(value)
          onChange(value)
        }}
        style={{ marginTop: '5px', width: '100%' }}
      >
        {intervals.map((interval) => (
          <Option
            value={interval}
            selected={interval === current}
            key={interval}
          >
            {interval
              .split('')
              .map((s, index) => (index === 0 ? s.toLocaleUpperCase() : s))}
          </Option>
        ))}
      </Select>
    </div>
  )
}
