import React from 'react'

const CompareModeSvgIcon: React.FC = () => (
  <svg
    width="40"
    height="27"
    viewBox="0 0 39 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="18" height="25" rx="4" fill="currentColor" />
    <rect
      x="21.5"
      y="0.5"
      width="17"
      height="24"
      rx="3.5"
      stroke="currentColor"
    />
    <path
      d="M29.3153 15.527V9.4929H30.8409V15.527H29.3153ZM27.0611 13.2727V11.7472H33.0952V13.2727H27.0611Z"
      fill="currentColor"
    />
  </svg>
)

export default CompareModeSvgIcon
