import React, { useState, useContext } from 'react'
import { Checkbox } from 'antd'
import { AuthContext } from 'features/auth'
import {
  StyledMultiSelectInput,
  Section,
  RecipientItem,
  SectionTitle,
} from '../CreateAlert.styles'
import mixpanel from '../../../../../../trackers/mixpanel'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'

const { Option } = StyledMultiSelectInput

interface Props {
  recipients: string[]
  setRecipients: (recipients: string[]) => void
  sharedUsers:
    | { admins: string[]; shared_users: string[]; yogi_employees: string[] }
    | undefined
  owners: { email: string }[]
}

const AlertRecipients: React.FC<Props> = ({
  recipients,
  setRecipients,
  sharedUsers,
  owners,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const [includeSelfAsRecipient, setIncludeSelfAsRecipient] = useState(true)

  const allUsers = sharedUsers
    ? [
        ...sharedUsers.admins,
        ...sharedUsers.shared_users,
        ...sharedUsers.yogi_employees,
      ].sort()
    : []

  return (
    <Section style={{ flexGrow: 1 }}>
      <SectionTitle>Choose Recipients</SectionTitle>
      <StyledMultiSelectInput
        mode="multiple"
        allowClear
        value={recipients}
        style={{ width: '100%' }}
        placeholder="Choose users to share view"
        // @ts-ignore - something about antd doesnt play nice with react-hook-form
        onChange={(value: string[]) => {
          setRecipients(value)
          mixpanel.track('alerts', {
            action: 'recipients',
            value: value.join(', '),
            proj_uuid: projectId,
          })
        }}
      >
        {allUsers
          .filter(
            (user) =>
              user !== currentUserEmail &&
              !owners.find((el) => el.email === user),
          )
          .map((user) => (
            <Option key={user} value={user}>
              {user}
            </Option>
          ))}
      </StyledMultiSelectInput>
      <div>
        <RecipientItem>
          <div style={{ marginRight: 'auto', fontSize: 12 }}>
            Include Self ({currentUserEmail})
          </div>
          <div>
            <Checkbox
              checked={includeSelfAsRecipient}
              onChange={(e) => setIncludeSelfAsRecipient(e.target.checked)}
            />
          </div>
        </RecipientItem>
      </div>
    </Section>
  )
}

export default AlertRecipients
