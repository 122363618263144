import { AxiosResponse } from 'axios'
import { axiosInstance } from 'utils/axios'
import {
  PROJECT_FILTER_PARAMS_URL,
  PROJECT_OPTIONS,
  PROJECT_SETTINGS_FILTER_ORDER,
  PROJECT_SETTINGS_FILTER_PRESET,
} from 'utils/apiLinks'
import {
  AppliedFilterOption,
  ProjectOptionsResponse,
  FilterPresetResponse,
} from './types'
import { FilterPreset } from 'features/project/types'

/**
 * Fetches options for the project
 * @param {string} proj_uuid - The project UUID
 * @param body
 * @returns {Promise<ProjectOptionsResponse>} Meta details
 */
export const getProjectOptions = (
  proj_uuid: string,
  body?: any,
  customParams?: any,
): Promise<AxiosResponse<ProjectOptionsResponse>> => {
  let config = { params: { proj_uuid } }
  if (customParams) config = { ...config, ...customParams }
  return axiosInstance.post(PROJECT_OPTIONS, body, config)
}
export const deleteFilterPreset = (
  proj_uuid: string,
  preset_name: string,
): Promise<AxiosResponse<ProjectOptionsResponse>> =>
  axiosInstance.delete(PROJECT_SETTINGS_FILTER_PRESET, {
    params: { proj_uuid, preset_name },
  })

export const saveFilterPreset = async (
  proj_uuid: string,
  preset_name: string,
  criteria: AppliedFilterOption[],
  search_criteria: string[],
  search_terms: string[],
  relative_date_filter_idx: number,
): Promise<ProjectOptionsResponse> => {
  const { data } = await axiosInstance.put(
    PROJECT_SETTINGS_FILTER_PRESET,
    {
      criteria,
      search_criteria,
      search_terms,
      relative_date_filter_idx,
      name: preset_name,
    },
    { params: { proj_uuid } },
  )
  return data
}

export const saveFilterOrder = (
  proj_uuid: string,
  ordered_filters_fields: string[],
): Promise<AxiosResponse<ProjectOptionsResponse>> =>
  axiosInstance.put(
    PROJECT_SETTINGS_FILTER_ORDER,
    { ordered_filters_fields },
    { params: { proj_uuid } },
  )

export const getFilterParamsByHash = (
  proj_uuid: string,
  key: string,
): Promise<AxiosResponse<string>> =>
  axiosInstance.get(PROJECT_FILTER_PARAMS_URL, { params: { proj_uuid, key } })

export const loadFilterPresets = async (
  proj_uuid: string,
): Promise<FilterPreset[]> => {
  const { filter_presets } = await axiosInstance
    .get(PROJECT_SETTINGS_FILTER_PRESET, { params: { proj_uuid } })
    .then(({ data }) => data)
  return filter_presets
}
