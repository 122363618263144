import React from 'react'
import { AnnotationType } from './types'
import { Tooltip } from 'antd'
import { AvatarWrapper } from '../../../../alerts/Components/AlertCard'
import styled from 'styled-components'
import { InnerTitle } from '../../../../../../../components/InnerTitle'
import { AnnotationContainer } from './NewAnnotation'
import {
  cardBorderGrey,
  lightText,
} from '../../../../../../../assets/styles/variables'
import { useAuthContext } from 'features/auth'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import { DeleteOutlined } from '@ant-design/icons'
import { useDashboardStore } from '../../../dashboardStore'

type Props = {
  annotation: AnnotationType
  deleteAnnotation: (id: string) => void
}

export const Annotation: React.FC<Props> = ({
  annotation,
  deleteAnnotation,
}) => {
  const authContext = useAuthContext()
  const isOwner =
    annotation.author.username === authContext?.user?.attributes?.sub
  const setHoveredAnnotation = useDashboardStore(
    (state) => state.setHoveredAnnotation,
  )

  return (
    <AnnotationContainer
      onMouseEnter={() => setHoveredAnnotation(annotation)}
      onMouseLeave={() => setHoveredAnnotation(null)}
    >
      <Header>
        <Left>
          {annotation.author.first_name && annotation.author.last_name && (
            <Tooltip title={annotation.author.email}>
              <AvatarWrapper>
                {annotation.author.first_name[0]}
                {annotation.author.last_name[0]}
              </AvatarWrapper>
            </Tooltip>
          )}
          <div>
            <InnerTitle style={{ margin: 0, fontSize: 16 }}>
              {annotation.author.first_name} {annotation.author.last_name}
            </InnerTitle>
            {annotation.create_time && (
              <p style={{ margin: 0, fontSize: 12, color: lightText }}>
                {new Date(annotation.create_time).toLocaleTimeString()}{' '}
                {new Date(annotation.create_time).toLocaleDateString()}
              </p>
            )}
          </div>
        </Left>
        <Right>
          {isOwner && (
            <>
              {/*<YogiButton>*/}
              {/*  <EditOutlined />*/}
              {/*</YogiButton>*/}
              <YogiButton onClick={() => deleteAnnotation(annotation.id)}>
                <DeleteOutlined />
              </YogiButton>
            </>
          )}
        </Right>
      </Header>
      <Content>
        {annotation.component && (
          <p
            style={{
              width: '100%',
              border: '2px solid ' + cardBorderGrey,
              borderRadius: '6px',
              padding: '4px 11px',
              background: 'white',
              margin: 0,
              cursor: 'default',
            }}
          >
            {/*@ts-ignore*/}
            Component: {annotation.component.chartTitle}
          </p>
        )}
        <p
          style={{
            width: '100%',
            height: '100px',
            border: '2px solid ' + cardBorderGrey,
            borderRadius: '6px',
            padding: '4px 11px',
            background: 'white',
            margin: 0,
            cursor: 'default',
          }}
        >
          {annotation.description}
        </p>
      </Content>
    </AnnotationContainer>
  )
}

const Header = styled.div`
  display: flex;
  justify-content: space-between;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`

const Right = styled.div`
  display: flex;
  gap: 10px;
`
const Content = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`
