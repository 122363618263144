import mixpanel from '../../../../trackers/mixpanel'
import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import {
  buttonBlue,
  cardBorderGrey,
  pageBackgroundGrey,
} from '../../../../../assets/styles/variables'
import useThemes from '../../../hooks/useThemes'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
type Props = {
  activeValues: string[]
  setActiveValues: (value: string[]) => void
  options: string[]
}
const ThemeSelector: React.FC<Props> = ({
  activeValues,
  setActiveValues,
  options,
}) => {
  const history = useHistory()

  const { getSubthemeNameFromClusterUUID, getClusterUUIDsFromThemeName } =
    useThemes()

  const clearSubtheme = () => {
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.delete('subtheme')
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
    setChildSelected(false)
  }

  const [childSelected, setChildSelected] = useState(false)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setActiveValues(
      // @ts-ignore
      !!urlParams.get('subtheme')
        ? // @ts-ignore
          getClusterUUIDsFromThemeName(
            // @ts-ignore
            urlParams.get('item'),
            urlParams.get('subtheme'),
          )
        : activeValues,
    )
    setChildSelected(!!urlParams.get('subtheme'))
    return () => {
      clearSubtheme()
    }
  }, [])

  useEffect(() => {
    if (activeValues?.length === 1) {
      const uuid = activeValues[0]
      if (uuid) {
        const element = document.querySelector('#uuid-' + uuid) ?? null
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'center',
        })
      }
    }
  }, [activeValues?.length])

  const uniqueOptions = Array.from(new Set(options))
  const themeNameToIdentifiersMap: Record<string, string> = {}
  uniqueOptions.forEach((uuid) => {
    if (!getSubthemeNameFromClusterUUID(uuid)) return
    // @ts-ignore
    themeNameToIdentifiersMap[getSubthemeNameFromClusterUUID(uuid)] = uuid
  })

  return (
    <Container>
      <ProductItemContainer>
        {Object.keys(themeNameToIdentifiersMap).map((theme: string) => {
          const subtheme = themeNameToIdentifiersMap[theme]
          return (
            <ProductItem
              key={subtheme}
              id={'uuid-' + subtheme}
              onClick={() => {
                mixpanel.track('catalog', {
                  action: 'select subtheme',
                  lens: 'theme',
                  value: subtheme,
                })
                if (childSelected && activeValues.includes(subtheme)) {
                  setActiveValues(options)
                  clearSubtheme()
                } else {
                  const urlParams = new URLSearchParams(window.location.search)
                  setActiveValues(
                    // @ts-ignore
                    getClusterUUIDsFromThemeName(
                      // @ts-ignore
                      urlParams.get('item'),
                      theme,
                    ) ?? [],
                  )
                  urlParams.append(
                    'subtheme',
                    getSubthemeNameFromClusterUUID(subtheme) ?? subtheme,
                  )
                  history.push(
                    `${window.location.pathname}?${urlParams.toString()}`,
                  )
                  setChildSelected(true)
                }
              }}
              active={childSelected && activeValues.includes(subtheme)}
            >
              <div style={{ overflow: 'hidden' }}>
                <div
                  style={{
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: '-webkit-box',
                    lineClamp: 4,
                    WebkitLineClamp: 4,
                    WebkitBoxOrient: 'vertical',
                  }}
                >
                  {theme ?? subtheme}
                </div>
              </div>
            </ProductItem>
          )
        })}
      </ProductItemContainer>
    </Container>
  )
}

export default ThemeSelector

const Container = styled.div``

const ProductItemContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  overflow: auto;
  padding: 0 0 10px;
`

const ProductItem = styled.div<{ active: boolean }>`
  flex-grow: 1;
  padding: 10px;
  cursor: pointer;
  background: ${({ active }) => (active ? pageBackgroundGrey : 'white')};
  border-radius: 10px;
  border: 1px solid ${({ active }) => (active ? buttonBlue : cardBorderGrey)};
  box-shadow: 0 4px 4px 0 #00000040;
  height: 100%;
  min-width: 300px;
  &:hover {
    background: ${pageBackgroundGrey};
  }
  overflow: hidden;
`
