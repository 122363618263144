import { axiosInstance } from 'utils/axios'

export type Recommendation = {
  title: string
  files: Array<{
    date: string
    filename: string
    size: number
    url: string
  }>
  recommendations: Array<{
    insight: string
    question: string
    recommendation: string
  }>
}

export async function fetchRecommendations(
  proj_uuid: string
): Promise<Record<string, Array<Recommendation>>> {
  return axiosInstance
    .get('/project/recommendation', {
      params: { proj_uuid },
    })
    .then(({ data }) => data)
}
