import React, { useContext } from 'react'
import styled from 'styled-components'
import {
  lightBlueFontColor,
  navSettingsShareBackground,
} from 'assets/styles/variables'
import { AuthContext } from 'features/auth'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import mixpanel from 'features/trackers/mixpanel'
import { isInitialFlow } from 'features/home/utils'
import { profileURL } from 'utils/links'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { ShareButton } from 'features/project/features/share'
import { SETTINGS_URL } from 'features/project/utils'

type Props = {}

export const Settings: React.FC<Props> = ({}) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)

  // hooks
  const authContext = useContext(AuthContext)
  const history = useHistory()
  const { url } = useRouteMatch()

  // constants
  const isAdmin =
    details?.role === 'admin' || authContext.userDetails?.yogi_user
  const isPublicViewer = details?.role === 'public_viewer'
  const showMenuItems = !isInitialFlow(details?.state)
  const is_trial = authContext.userDetails?.free_trial
  const pageSettings = details?.clientSettings?.project?.pages
  const encodedUrl = encodeURI(url)
  const search = window.location.search

  return (
    <Container>
      {showMenuItems && !is_trial && !pageSettings.share.hidden && (
        <ShareButton />
      )}
      <Item
        onClick={() => {
          mixpanel.track('help', {
            proj_uuid: projectId,
          })
          return (window as any).StonlyWidget('setYOffset', 0)
        }}
        className={'nav-help-button'}
      >
        help
      </Item>
      {!isPublicViewer && (
        <Item
          onClick={() => {
            history.push(profileURL, { proj_uuid: projectId })
            mixpanel.track('profile', {
              proj_uuid: projectId,
            })
          }}
        >
          profile
        </Item>
      )}
      {isAdmin && !isPublicViewer && !pageSettings.settings.hidden && (
        <StyledLink
          to={`${encodedUrl}/${SETTINGS_URL}`}
          onClick={(e) => {
            e.preventDefault()
            mixpanel.track('settings', {
              proj_uuid: projectId,
            })
            history.push(`${encodedUrl}/${SETTINGS_URL}` + search)
          }}
        >
          <Item>settings</Item>
        </StyledLink>
      )}
      {!isPublicViewer && <Item onClick={authContext.signOut}>signout</Item>}
    </Container>
  )
}

const Container = styled.div`
  width: 70px;
  margin: 0 35px 15px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  gap: 10px;
  font-size: var(--font-size-md);
  color: ${lightBlueFontColor};
`

const Item = styled.div`
  cursor: pointer;
  margin-bottom: 6px;
  font-size: var(--font-size-md);
`

const StyledLink = styled(Link)`
  &:hover {
    text-decoration: none;
  }
  color: ${lightBlueFontColor};
`
