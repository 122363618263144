import React from 'react'
import { OutlierType } from '../../../../model'
import {
  Container,
  Header,
  Info,
  InfoItem,
  Tags,
  Title,
} from './InsightListItem.styles'
import { getOutlierTitle } from '../../../../utils'
import FavoriteInsightButton from '../../../FavoriteInsightButton/FavoriteInsightButton'
import { Filters } from '../../../../../../types'
import useThemes from '../../../../../../hooks/useThemes'
import { TIMESERIES_TYPE_NAME } from '../../../../constants'
import moment from 'moment'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { Divider, Tag, Tooltip } from 'antd'
import YogiDivider from 'components/UI/YogiDivider'
import _ from 'lodash'
import { mainText } from 'assets/styles/variables'

interface Props {
  outlier: OutlierType
  filterValues: Filters
  isSelected: boolean
  setSelectedOutlier: (outlier: OutlierType) => void
}

const InsightListItem: React.FC<Props> = ({
  outlier,
  filterValues,
  isSelected,
  setSelectedOutlier,
}) => {
  const { getNormalizedThemeName } = useThemes()

  const handleSelect = () => {
    setSelectedOutlier(outlier)
    mixpanel.track('automated insights', {
      action: 'select insight',
      value: getOutlierTitle(outlier),
    })
  }

  return (
    <Container isSelected={isSelected} onClick={handleSelect}>
      <Header isSelected={isSelected}>
        <Title>{getOutlierTitle(outlier)}</Title>
        <FavoriteInsightButton
          key={outlier.uuid}
          outlier={outlier}
          filterValues={filterValues}
        />
      </Header>
      <Info isSelected={isSelected}>
        {outlier.type === TIMESERIES_TYPE_NAME ? (
          <>
            <InfoItem style={{ flexBasis: '100%' }}>
              {!!outlier.date && new Date(outlier.date).toDateString()} -{' '}
              {outlier.aggregation && (
                <>
                  {moment(outlier.date)
                    // @ts-ignore TODO : 'half' is an option, but moment doesn't accept it
                    .add(1, outlier.aggregation)
                    .toDate()
                    .toDateString()}
                </>
              )}
            </InfoItem>
          </>
        ) : (
          <>
            <Tag
              color={'#E0E5F2'}
              style={{ color: mainText, whiteSpace: 'wrap' }}
            >
              {_.startCase(outlier.context_with_value?.context)}
              {': '}
              {outlier.context_with_value?.value}
            </Tag>
            <Tag
              color={'#E0E5F2'}
              style={{ color: mainText, whiteSpace: 'wrap' }}
            >
              {outlier.theme ? getNormalizedThemeName(outlier.theme) : ''}
            </Tag>
          </>
        )}
      </Info>

      <YogiDivider style={{ margin: '5px 0px' }}></YogiDivider>
      <Tags isSelected={isSelected}>
        {outlier.dateRanges
          ?.sort()
          ?.reverse()
          ?.map((dateRange, index) => (
            <Tooltip
              title={`Outlier appeared in the trailing ${_.startCase(dateRange)}`}
            >
              <Tag key={index} color={'#FFE7E2'} style={{ color: mainText }}>
                {_.startCase(dateRange)}
              </Tag>
            </Tooltip>
          ))}
      </Tags>
    </Container>
  )
}

export default InsightListItem
