import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Button, Select, Tooltip } from 'antd'
import { Loader } from 'shared/components'
import { ExportData, ProjectState } from 'features/project/types'
import { Post, PostDetailModal, PostsGrid } from './'
import { getFeedback } from './model'
import { FilterTypes } from '../filters/types'
import { isInitialFlow } from 'features/home/utils'
import _ from 'lodash'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../projectStore/projectStore'
import { useQuery } from 'react-query'
import { YogiButton } from 'components/UI/YogiButton'
import { Fetcher } from 'shared/components/fetcher'
import {
  buttonBlue,
  cardBorder,
  panelHover,
  primaryOrange,
} from 'assets/styles/variables'
import Superset from 'components/Superset/Superset'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import YogiDivider from 'components/UI/YogiDivider'
import { Summary } from 'features/project/features/feedback/components/Summary/Summary'
import { CloseOutlined } from '@ant-design/icons'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import HighlightSvgIcon from 'features/project/features/keywords/components/HighlightSvgIcon/HighlightSvgIcon'
import { Header } from 'features/project/features/filters/Header/Header'
import { SaveAndExportButton } from 'components/SaveAndExportButton/SaveAndExportButton'

export const orderNames: any = {
  asc: '↑',
  desc: '↓',
}

type Props = {
  comparativeIndex: number
  isActive: boolean
  setActivePanel: (index: number) => void
}

const FeedbackContent: React.FC<Props> = ({
  comparativeIndex,
  isActive,
  setActivePanel,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex],
  )
  const feedbackControls = useProjectStore(
    (state: ProjectState) => state.feedbackControls,
  )
  const updateFeedbackControls = useProjectStore(
    (state: ProjectState) => state.updateFeedbackControls,
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )
  const setExportData = useProjectStore(
    (state: ProjectState) => state.setExportData,
  )

  const { data: feature_flags } = useFeatureFlags()

  const { order, orderField } = feedbackControls[comparativeIndex]

  const [postDetailUuid, setPostDetailUuid] = useState<string>()
  const [page, setPage] = useState<number>(1)
  const [loadMoreLoading, setLoadMoreLoading] = useState<boolean>(false)
  const [resetting, setResetting] = useState<boolean>(false)
  const [hasFormatting, setHasFormatting] = useState<boolean>(false)

  useEffect(() => {
    mixpanel.track('feedback page')
  }, [])

  const toggleOrder = () => {
    setPage(1)
    const newOrder = order === 'asc' ? 'desc' : 'asc'
    updateFeedbackControls(comparativeIndex, 'order', newOrder)
  }

  const changeOrderField = (value: string) => {
    setPage(1)
    updateFeedbackControls(comparativeIndex, 'orderField', value)
  }

  useEffect(() => {
    setPage(1)
    // Whenever a filter is applied, opened options should be reset
    clearOpenedOptions(comparativeIndex)
  }, [
    filterValues.values,
    filterValues.searchCondition,
    filterValues.searchQuery,
  ])

  // get Verbatims
  const {
    data: postData,
    isFetching: postsFetching,
    isLoading,
  } = useQuery(
    [
      `feedback-posts_` + comparativeIndex,
      projectId,
      filterValues.values,
      filterValues.searchQuery,
      filterValues.searchCondition,
      order,
      orderField,
      page,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
          page_size:
            // one project had a LOT of dupes - this was a hackaround
            projectId === 'ca805d9d-b814-47dd-bdbd-74b1737eb9d4' ? 120 : 24,
          // projectId === '5ecaef81-57d1-4d5d-935b-e7e7375d141e' ? 120 : 24,
        },
        body: {
          criteria: filterValues.values,
          search_terms: filterValues.searchQuery,
          search_criteria: filterValues.searchCondition,
        },
      }
      const { data } = await getFeedback(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    },
  )

  useEffect(() => {
    if (postData) {
      const seenReviews = new Set<string>()
      const dedupedPosts: Post[] = []

      for (const post of postData.posts) {
        const reviewKey = post.title + post.body
        if (!seenReviews.has(reviewKey)) {
          seenReviews.add(reviewKey)
          dedupedPosts.push(post)
        }
      }

      setPosts([...(page > 1 ? posts : []), ...dedupedPosts])
      setLoadMoreLoading(false)
    }
  }, [postData])

  const [posts, setPosts] = useState<Post[]>(postData ? postData.posts : [])

  const hasMore = (postData?.n_pages || 0) > page
  const numericOptions = defaultFilterList
    ? defaultFilterList.filter(
        (option) =>
          option.filter_type === FilterTypes.WINDOW ||
          option.filter_type === FilterTypes.DATE,
      )
    : []

  let orderFieldOptions = numericOptions.map((e) => ({
    value: e.field,
    label: e.alias,
  }))

  orderFieldOptions.sort(function (a, b) {
    if (a.label > b.label) {
      return 1
    }
    if (a.label < b.label) {
      return -1
    }
    return 0
  })

  const onClosePostDetail = useCallback(() => setPostDetailUuid(undefined), [])

  const showSelectDataset =
    feature_flags?.superset &&
    !filterValues.values.find((el) => el.field === 'proj_uuid')

  const exportFn = () => {
    mixpanel.track('chart download', {
      action: 'image',
      ...route,
      value: 'keywords',
    })

    const exportData: ExportData = {
      comparativeIndex: comparativeIndex,
      filterValues: filterValues,
    }
    setExportData(exportData)

    setIsExportDrawerOpen(true)
  }

  if (showSelectDataset) {
    return (
      <ContentWrapper
        isActive={isActive}
        comparativePanelsNumber={comparativePanelsNumber}
        onClick={() => setActivePanel(comparativeIndex)}
      >
        <Superset comparativeIndex={comparativeIndex} />
      </ContentWrapper>
    )
  }

  if (isInitialFlow(details.state) || !postData) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>
          {`Loading ${details.clientSettings.project.pages.feedback.alias}`}
        </LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <ContentWrapper
      isActive={isActive}
      comparativePanelsNumber={comparativePanelsNumber}
      onClick={() => setActivePanel(comparativeIndex)}
    >
      <SortHeader>
        <SettingsRight>
          <div>Sort by:</div>
          <Select
            value={orderField}
            onChange={(value) => changeOrderField(value)}
            options={orderFieldOptions}
            style={{ minWidth: '100px' }}
          />
          <Tooltip title={'Asc/Desc'}>
            <YogiButton
              id="feedback-sort-button"
              type="dashed"
              onClick={toggleOrder}
            >
              {orderNames[order]}
            </YogiButton>
          </Tooltip>
          {hasFormatting && (
            <Tooltip title={'Reset Formatting'}>
              <ResetFormattingButton
                onClick={() => {
                  setResetting(true)
                  setHasFormatting(false)
                  setTimeout(() => {
                    setResetting(false)
                  }, 50)
                }}
              >
                <div>Reset Formatting</div>
                <GenericSvgIcon SvgComponent={HighlightSvgIcon} />
              </ResetFormattingButton>
            </Tooltip>
          )}
        </SettingsRight>
        <SaveAndExportButton exportFn={exportFn}></SaveAndExportButton>
      </SortHeader>
      <ContentBody isComparative={isComparative} id="feedback-container">
        <>
          {postData && (
            <>
              <PostsContainer isComparative={isComparative} id="post-container">
                <PostsGrid
                  posts={posts}
                  onPostClick={(postId: string) => setPostDetailUuid(postId)}
                  isLoading={isLoading}
                  isFetching={postsFetching}
                  resetView={resetting}
                  setHasFormatting={setHasFormatting}
                />
                {postData?.posts.length > 0 && hasMore && (
                  <StyledButton
                    onClick={() => {
                      setLoadMoreLoading(true)
                      setPage(page + 1)
                    }}
                    disabled={loadMoreLoading}
                  >
                    {loadMoreLoading ? 'Loading' : 'Load more'}
                  </StyledButton>
                )}
              </PostsContainer>
              <PostDetailModal
                postUuid={postDetailUuid}
                onClose={onClosePostDetail}
                fragment={false}
              />
            </>
          )}
          {postsFetching && postData && (
            <LoadingLayer>
              <Fetcher paddingTop={'50px'} />
            </LoadingLayer>
          )}
        </>
      </ContentBody>
    </ContentWrapper>
  )
}

const FeedbackContainer: React.FC = () => {
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const feedbackControls = useProjectStore(
    (state: ProjectState) => state.feedbackControls,
  )
  const removePanel = useProjectStore(
    (state: ProjectState) => state.removePanel,
  )
  const filters = useProjectStore((state: ProjectState) => state.filters)

  const [activePanel, setActivePanel] = useState(0)

  const selectedIndex = comparativePanelsNumber > 1 ? activePanel : null

  useEffect(() => {
    if (activePanel >= comparativePanelsNumber) {
      setActivePanel(0)
    }
  }, [comparativePanelsNumber])

  if (
    feedbackControls.length < comparativePanelsNumber ||
    filters.length < comparativePanelsNumber
  ) {
    return <Container />
  }

  return (
    <Container>
      <Left>
        {_.times(comparativePanelsNumber, (index) => (
          <>
            {index > 0 && <YogiDivider style={{ marginBottom: 15 }} />}
            {comparativePanelsNumber > 1 && (
              <PanelLabel>Panel {index + 1}:</PanelLabel>
            )}
            <Summary filterValues={filters[index]}></Summary>
          </>
        ))}
      </Left>
      <Right>
        <HeaderContainer>
          <Header
            // this helps stop a bug from removing an active panel at the highest index
            activePanel={
              activePanel >= comparativePanelsNumber ? 0 : activePanel
            }
          ></Header>
        </HeaderContainer>
        <Wrapper>
          {_.times(comparativePanelsNumber, (index) => (
            <Panel key={index}>
              {comparativePanelsNumber > 1 && (
                <RemovePanel
                  onClick={() => {
                    removePanel(index)
                  }}
                >
                  <CloseOutlined />
                </RemovePanel>
              )}
              <FeedbackContent
                comparativeIndex={index}
                setActivePanel={setActivePanel}
                isActive={selectedIndex === index}
              />
            </Panel>
          ))}
        </Wrapper>
      </Right>
    </Container>
  )
}

export default FeedbackContainer

const Container = styled.div`
  display: flex;
  justify-content: flex-start;
  height: 100%;
  width: 100%;
  padding-right: 16px;
  gap: 10px;
`

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  margin: 0 16px 16px 8px;
`

const Panel = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  height: calc(100% - 40px);
  width: 100%;
  overflow: hidden;
  position: relative;

  padding: 8px;
`
const ContentWrapper = styled.div<{
  comparativePanelsNumber: number
  isActive: boolean
}>`
  width: 100%;
  padding: var(--default-padding-quarter) var(--default-padding-half);
  height: 100%;
  display: flex;
  flex-direction: column;

  border: 2px solid ${cardBorder};
  border-radius: 10px;
  border-color: ${({ isActive }) => (isActive ? buttonBlue : cardBorder)};
  &:hover {
    border-color: ${({ comparativePanelsNumber, isActive }) =>
      comparativePanelsNumber > 1 && !isActive
        ? panelHover
        : isActive
          ? buttonBlue
          : cardBorder};
  }

  box-shadow: 0 4px 10px 0 #00000026;
`

const PostsContainer = styled.div<{ readonly isComparative: boolean }>`
  flex: 1;
  max-width: 100%;
  margin: 0 auto;
  order: ${(props) => (props.isComparative ? 2 : 1)};
  overflow-y: ${(props) => (props.isComparative ? 'visible' : 'scroll')};
  height: ${(props) => (props.isComparative ? 'auto' : '100%')};
`

const ContentBody = styled.div<{ readonly isComparative: boolean }>`
  display: flex;
  padding-top: calc(var(--default-padding-half) - var(--filter-margin-bottom));
  flex-direction: ${(props) => (props.isComparative ? 'column' : 'row')};
  align-items: ${(props) => (props.isComparative ? 'center' : 'flex-start')};
  height: 100%;
  overflow-y: ${(props) => (props.isComparative ? 'scroll' : 'hidden')};
  position: relative;
  z-index: 1;
`

const Left = styled.div`
  height: calc(100vh - 40px);
  overflow: auto;
  padding-right: 10px;
  width: 300px;
`

const Right = styled.div`
  height: calc(100% - 40px);
  width: calc(100% - 300px);
  max-width: calc(100% - 300px);
`

const SettingsRight = styled.div`
  display: flex;
  gap: 10px;
  font-size: var(--font-size-md);
  align-items: center;
`
const StyledButton = styled(Button)`
  display: block;
  margin: 20px auto;
`
const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.4);
  z-index: 10;
`

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const RemovePanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  cursor: pointer;
  border: 2px solid ${cardBorder};
  background: white;
  border-radius: 50%;
  z-index: 2;
  //opacity: 0.5;
  font-size: var(--font-size-md);
  transition: 0.2s ease;
  &:hover {
    background: ${cardBorder};
  }
`

const HeaderContainer = styled.div`
  margin-left: 15px;
  margin-bottom: 10px;
`

const PanelLabel = styled.div`
  margin-bottom: 5px;
`

const SortHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;
  margin-bottom: 10px;
`

const ResetFormattingButton = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;

  cursor: pointer;

  padding: 8px 12px;

  background: #ffefeb;
  color: ${primaryOrange};

  border: 1px solid #ffe1dc;
  border-radius: 10px;
`
