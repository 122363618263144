import { Auth } from 'aws-amplify'
import { axiosInstance } from 'utils/axios'
import {
  USER_VISIT_URL,
  USER_DETAILS_URL,
  USER_TRIAL_SIGNUP_URL,
  USER_REQUEST_ACCESS_URL,
} from 'utils/apiLinks'
import { CognitoUser } from '@aws-amplify/auth'
import { AxiosResponse } from 'axios'

/**
 * Signs in
 * @param {string} username
 * @param {string} password
 * @returns {User} User entity
 */
export const signIn = (username: string, password: string) =>
  Auth.signIn(username, password)

/**
 * Signs in
 * @param {string} email
 * @param {string} first_name
 * @param {string} last_name
 * @param {string} org
 * @param {string} team
 * @returns {User} User entity
 */
export const freeSignUp = (
  email: string,
  first_name: string,
  last_name: string,
  org: string,
  team: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.post(USER_TRIAL_SIGNUP_URL, {
    email,
    first_name,
    last_name,
    org,
    team,
  })

/**
 * Sends the code for reseting user's password
 * @param {string} username
 * @returns nothing
 */
export const resetPasswordSendCode = (username: string) =>
  Auth.forgotPassword(username)

/**
 * Resets user's password
 * @param {string} username
 * @param {string} code
 * @param {string} password
 * @returns nothing
 */
export const resetPassword = (
  username: string,
  code: string,
  password: string
) => Auth.forgotPasswordSubmit(username, code, password)

/**
 * Sets up user password
 * @param {string} user
 * @param {string} newPassword
 * @returns nothing
 */
export const setUpPassword = (user: CognitoUser, newPassword: string) =>
  Auth.completeNewPassword(user, newPassword, null)

/**
 * Sends the code for verifying user's email
 * @returns nothing
 */
export const sendVerificationCode = () =>
  Auth.verifyCurrentUserAttribute('email')

/**
 * Verifies user's email
 * @param {string} email
 * @returns nothing
 */
export const verifyUser = (code: string) =>
  Auth.verifyCurrentUserAttributeSubmit('email', code)

/**
 * Updates last user visit
 * @returns nothing
 */
export const updateLastVisit = () => axiosInstance.put(USER_VISIT_URL)

export const fetchUser = () => axiosInstance.get(USER_DETAILS_URL)
export const requestAccess = (email: string) =>
  axiosInstance.get(USER_REQUEST_ACCESS_URL)
