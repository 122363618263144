import React, {
  useState,
  useEffect,
  useContext,
  createContext,
  useMemo,
} from 'react'
import { useQuery } from 'react-query'
import { fetchProjectsBySearch } from './model'
import { HomeContext as HomeContextType, Project } from './types'

export const HomeContext = createContext<HomeContextType>({} as HomeContextType)

export const HomeProvider: React.FC<Props> = React.memo(({ children }) => {
  const [projects, setProjects] = useState<Project[]>([])
  const [searchTerm, setSearchTerm] = useState('')
  const [searchType, setSearchType] = useState('project')
  const [searchTypeChanged, setSearchTypeChanged] = useState<boolean>(false)
  const [updateState, setUpdateState] = useState<boolean>(false)
  const [modalOpened, setModalOpened] = useState<boolean>(false)

  const { isLoading, data, refetch } = useQuery(
    ['project-search', searchTerm, searchTypeChanged],
    () => fetchProjectsBySearch(searchType, searchTerm),
    { refetchOnWindowFocus: false }
  )

  useEffect(() => {
    if (data) {
      setProjects(data.data)
    }
  }, [data])

  const value = useMemo(() => {
    return {
      refetch: refetch,
      projects: projects,
      loading: isLoading,
      searchType: searchType,
      searchTerm: searchTerm,
      searchTypeChanged: searchTypeChanged,
      updateState: updateState,
      modalOpened: modalOpened,
      setSearchTerm: setSearchTerm,
      setSearchType: setSearchType,
      setSearchTypeChanged: setSearchTypeChanged,
      setUpdateState: setUpdateState,
      setModalOpened: setModalOpened,
    }
  }, [
    data,
    projects,
    searchType,
    searchTerm,
    searchTypeChanged,
    updateState,
    modalOpened,
  ])

  return <HomeContext.Provider value={value}>{children}</HomeContext.Provider>
})

type Props = { children: React.ReactNode }

export const useHomeContext = () => {
  const context = useContext(HomeContext)
  if (!context) {
    throw new Error('useHomeContext must be used within a HomeContext.')
  }
  return context
}
