import mixpanel from 'mixpanel-browser'
import { isProd } from 'utils/axios'

export default {
  init: () => {
    mixpanel.init('87e9d4ca08ca03f48fcc9933a2722f05', {
      batch_requests: true,
      ignore_dnt: true,
    })
  },
  track: (...props) => {
    props[1] = { ...props[1], date: new Date().toISOString() }
    return isProd ? mixpanel.track(...props) : console.info('TRACK', props)
  },
  set: (...props) => {
    return isProd ? mixpanel.people.set(...props) : console.info('SET', props)
  },
  identify: (...props) => {
    return isProd
      ? mixpanel.identify(...props)
      : console.info('IDENTIFY', props)
  },
  reset: () => {
    return isProd ? mixpanel.reset() : console.info('RESET')
  },
  alias: (...props) => {
    return isProd ? mixpanel.alias(...props) : console.info('ALIAS', props)
  },
  register: (props) => {
    return isProd ? mixpanel.register(props) : console.info('REGISTER', props)
  },
}
