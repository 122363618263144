import React, { useMemo } from 'react'
import { OutlierType } from '../../../../model'
import { ChartWrapper, Container } from './InsightCharts.styles'
import { getOutlierCharts } from '../../../../utils'
import { Filters } from '../../../../../../types'
import InsightChart from '../InsightChart/InsightChart'
import useAutoInsights from '../../../../hooks/useAutoInsights'

interface Props {
  outlier: OutlierType
  filterValues: Filters
}

const InsightCharts: React.FC<Props> = ({ outlier, filterValues }) => {
  const { outlierFilters, themeFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchCondition: outlier.filter.search_criteria,
    searchQuery: outlier.filter.search_terms,
  })

  const charts = useMemo(
    () => getOutlierCharts(outlier, outlierFilters, themeFilters),
    [outlier],
  )

  return (
    <Container>
      {charts.map((chart, index) => (
        <ChartWrapper
          style={{
            flexBasis: chart.width,
            minWidth: 250,
            flexGrow: 1,
            maxHeight: 400,
            height: 400,
          }}
        >
          <InsightChart chartItem={chart.chartItem} outlier={outlier} />
        </ChartWrapper>
      ))}
    </Container>
  )
}

export default InsightCharts
