import { PostType } from 'features/project/features/Charts/types'

export const fontSizeOptions = [
  { label: 'Extra Small Font', value: 'extra_small' },
  { label: 'Small Font', value: 'small' },
  { label: 'Medium Font', value: 'medium' },
  { label: 'Large Font', value: 'large' },
  { label: 'Extra Large Font', value: 'extra_large' },
]

export const postTypeOptions = [
  { label: 'Reviews', value: 'post' as PostType },
  { label: 'Sentences', value: 'sentence' as PostType },
]
