import React, { useEffect, useMemo, useState } from 'react'
import { Button, Popconfirm, Tooltip } from 'antd'
import {
  BarChartOutlined,
  CommentOutlined,
  CopyOutlined,
  DeleteOutlined,
  DotChartOutlined,
  EditOutlined,
  EyeOutlined,
  FileSearchOutlined,
  FormatPainterOutlined,
  OrderedListOutlined,
  SortAscendingOutlined,
} from '@ant-design/icons'
import styled from 'styled-components'
import { useSavedViews } from '../utils'
import { SavedView, SavedViewPayload } from '../types'
import { trimText } from 'features/project/utils'
import {
  getSavedViews,
  saveView,
  updateLastRecentlyUsed,
  updateSavedView,
} from '../model'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useQuery } from 'react-query'
import {
  buttonBlue,
  superLightGrey,
} from '../../../../../assets/styles/variables'
import { useLocation } from 'react-router-dom'
import { YogiSearch } from '../../../../../components/UI/YogiSearch'
import { Radio } from 'antd'
import { useAuthContext } from 'features/auth'
import { Loader } from '../../../../../shared/components'
import { Fetcher } from '../../../../../shared/components/fetcher'
import { toast } from 'react-toastify'
import { Circle } from '@mui/icons-material'

const viewImages: { [key: string]: JSX.Element } = {
  dashboard: <BarChartOutlined />,
  'dashboard-new': <BarChartOutlined />,
  feedback: <CommentOutlined />,
  network: <DotChartOutlined />,
  keywords: <FileSearchOutlined />,
  default: <EyeOutlined />,
}
type Props = {
  toggleIsOpen: (isOpen: boolean) => void
}

export const ViewLoadForm: React.FC<Props> = ({ toggleIsOpen }) => {
  const location = useLocation()
  const locationParts = location.pathname.split('/')
  const viewUrl = locationParts[locationParts.length - 1]
  const { updateFilters, deleteView } = useSavedViews()
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const [views, setViews] = useState<SavedView[]>([])
  const { data: viewsData, refetch } = useQuery(
    ['saved-views', projectId],
    () => getSavedViews(projectId),
    {
      refetchOnWindowFocus: false,
    },
  )

  useEffect(() => {
    if (viewsData) {
      setViews(viewsData)
    }
  }, [viewsData])

  const [order, setOrder] = useState('Recent')
  const [loading, setLoading] = useState(false)

  const sortOptions = [
    {
      label: (
        <Tooltip title={'Recently Viewed'}>
          <OrderedListOutlined />
        </Tooltip>
      ),
      value: 'Recent',
    },
    {
      label: (
        <Tooltip title={'Alphabetical'}>
          <SortAscendingOutlined />
        </Tooltip>
      ),
      value: 'Alphabetical',
    },
  ]
  const maxTextLength = 50

  const selfViews = useMemo(() => views?.filter((view) => view.author), [views])

  const [searchedViews, setSearchedViews] = useState(selfViews)
  const [searchTerm, setSearchTerm] = useState<string>('')

  useEffect(() => {
    const filteredViews = selfViews?.filter((view) => {
      return view.name.toLowerCase().includes(searchTerm.toLowerCase())
    })
    const sortedViews = filteredViews?.sort((a, b) => {
      if (order === 'Alphabetical') {
        if (a.name.toLowerCase() > b.name.toLowerCase()) {
          return 1
        } else if (b.name.toLowerCase() > a.name.toLowerCase()) {
          return -1
        }
      } else {
        if (a.last_time_used > b.last_time_used) {
          return -1
        } else if (b.last_time_used > a.last_time_used) {
          return 1
        }
      }
      return 0
    })
    setSearchedViews(sortedViews)
  }, [searchTerm, order, selfViews])

  return (
    <div
      style={{
        maxHeight: '320px',
        overflowY: 'auto',
        minWidth: '290px',
      }}
    >
      <div
        style={{
          marginBottom: '8px',
          display: 'flex',
          gap: 5,
          alignItems: 'center',
        }}
      >
        <YogiSearch
          style={{ boxShadow: 'none', flexGrow: 0 }}
          onChange={(event: any) => setSearchTerm(event.target.value)}
        />
        <div style={{ width: '82px' }}>
          <StyledRadioGroup
            options={sortOptions}
            onChange={(e) => setOrder(e.target.value)}
            value={order}
            optionType="button"
            buttonStyle="solid"
          />
        </div>
      </div>

      {searchedViews &&
        searchedViews
          // .sort((a, b) => a.name.localeCompare(b.name))
          .map((view: SavedView) => (
            <ViewWrapper key={view.uuid}>
              <ViewButton
                type="text"
                icon={viewImages[view.page]}
                onClick={() => {
                  mixpanel.track('saved view', {
                    action: 'load',
                    value: view.name,
                    location: 'toolbar',
                  })
                  updateLastRecentlyUsed(projectId, view.uuid).then(() =>
                    refetch(),
                  )
                  updateFilters(view)
                  toggleIsOpen(false)
                }}
                // style={{
                //   color:
                //     view.search_url === window.location.search
                //       ? 'blue'
                //       : 'black',
                // }}
              >
                {view.name.length > maxTextLength ? (
                  <Tooltip title={view.name}>
                    <ViewName>
                      {trimText(view.name, maxTextLength)}
                      {view.search_url === window.location.search && (
                        <span
                          style={{
                            marginLeft: 8,
                            color: buttonBlue,
                            fontSize: 16,
                          }}
                        >
                          <EyeOutlined />
                        </span>
                      )}
                    </ViewName>
                  </Tooltip>
                ) : (
                  <ViewName>
                    {trimText(view.name, maxTextLength)}
                    {view.search_url === window.location.search && (
                      <span
                        style={{
                          marginLeft: 8,
                          color: buttonBlue,
                          fontSize: 16,
                        }}
                      >
                        <EyeOutlined />
                      </span>
                    )}
                  </ViewName>
                )}
              </ViewButton>
              {view.is_author ? (
                <Popconfirm
                  // style={{ width: '250px' }}
                  title="Are you sure you want to update this saved view with your current view?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    setLoading(true)
                    updateSavedView(projectId, {
                      ...view,
                      page: viewUrl,
                      search_url: window.location.search,
                    }).then(() =>
                      refetch().then(() => {
                        toast.success('View Updated')
                        setLoading(false)
                      }),
                    )
                  }}
                >
                  <Tooltip title={'Update With Current View'}>
                    <ViewAction>
                      <FormatPainterOutlined />
                    </ViewAction>
                  </Tooltip>
                </Popconfirm>
              ) : (
                <Popconfirm
                  title="Are you sure you want to duplicate this saved view?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    setLoading(true)
                    saveView(projectId, {
                      ...view,
                    }).then(() => refetch().then(() => setLoading(false)))
                  }}
                >
                  <Tooltip title={'Make a Copy'}>
                    <ViewAction>
                      <CopyOutlined />
                    </ViewAction>
                  </Tooltip>
                </Popconfirm>
              )}
              {((view.is_author && view.ownership_type === 'proj_shared') ||
                view.ownership_type === 'personal') && (
                <Popconfirm
                  title="Are you sure you want to delete this saved view?"
                  okText="Yes"
                  cancelText="No"
                  onConfirm={() => {
                    setLoading(true)
                    deleteView(view.uuid).then(() =>
                      refetch().then(() => setLoading(false)),
                    )
                  }}
                >
                  <ViewAction>
                    <DeleteOutlined />
                  </ViewAction>
                </Popconfirm>
              )}
            </ViewWrapper>
          ))}

      {loading && (
        <Overlay>
          <Fetcher />
        </Overlay>
      )}
    </div>
  )
}

const ViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${superLightGrey};
  margin-bottom: 8px;
  border-radius: 8px;
`

const ViewButton = styled(Button)`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  height: 40px;
`

const ViewAction = styled.div`
  width: 7%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  justify-content: center;
  &:hover {
    background: rgba(0, 0, 0, 0.018);
  }
`

const ViewName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 10px;
  display: flex;
  align-items: center;
`

const StyledRadioGroup = styled(Radio.Group)`
  .ant-radio-button-wrapper {
    height: 32px;
    padding: 0 8px;
    border: none;
  }
  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }
  //.ant-radio-button-wrapper-checked {
  //  border: 1px solid #1890ff;
  //}
`
const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
