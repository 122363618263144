import React, { useState } from 'react'
import { InfoCircleOutlined, LeftOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { YogiButton } from 'components/UI/YogiButton'
import { Summary } from '../summary'
import { initialState, useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { lightText } from 'assets/styles/variables'
import useResizeObserver from 'use-resize-observer'
import CatalogDetailContent from './CatalogDetailContent'
import { DatePanel } from '../filters/features/date-panel/date-panel'
import { FocusListOption } from 'features/project/features/catalog/Components/CatalogList/CatalogList'
import { Tooltip } from 'antd'
import { useHistory } from 'react-router'

interface FocusDetailProps {
  lens: string
  item: FocusListOption
  setValue: (value: string | null) => void
  tabIndex: number
  setTabIndex: (value: number) => void
}

const CatalogDetail: React.FC<FocusDetailProps> = ({
  lens,
  item,
  setValue,
  tabIndex,
}) => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const { ref, height = 1 } = useResizeObserver<HTMLDivElement>()
  const history = useHistory()

  const [localFilters, setLocalFilters] = useState<Filters>(
    initialState.filters[0],
  )

  const alias = defaultFilterList?.find((item) => item.field === lens)?.alias

  return (
    <Container>
      <Header ref={ref}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <YogiButton
            type="dashed"
            onClick={() => {
              setValue(null)

              const urlParams = new URLSearchParams(window.location.search)
              if (urlParams.get('subtheme')) {
                urlParams.delete('subtheme')
                history.push(
                  `${window.location.pathname}?${urlParams.toString()}`,
                )
              }
            }}
            icon={<LeftOutlined />}
            style={{
              border: 'none',
              boxShadow: 'none',
              color: lightText,
              paddingLeft: 0,
              background: 'transparent',
            }}
          >
            Back
          </YogiButton>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            marginBottom: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'start',
              gap: 10,
              alignItems: 'center',
            }}
          >
            <div style={{ marginBottom: 0, fontSize: 22, fontWeight: 600 }}>
              {item.value}
            </div>
            <Tooltip
              title={
                'Catalog Data is only filtered to selected ' +
                alias +
                ' and Date Range'
              }
              placement="right"
            >
              <InfoCircleOutlined
                style={{
                  fontSize: '16px',
                  marginBottom: 2,
                  cursor: 'pointer',
                }}
              />
            </Tooltip>
          </div>

          {tabIndex === 0 && (
            <div>
              <DatePanel
                comparativeIndex={0}
                filterValues={localFilters}
                updateLocalFilterValues={setLocalFilters}
              />
            </div>
          )}
        </div>
      </Header>
      <Content headerHeight={height}>
        <CatalogDetailContent
          item={item}
          lens={lens}
          tabIndex={tabIndex}
          filterValues={localFilters}
        />
      </Content>
      <Summary />
    </Container>
  )
}

const Container = styled.div`
  height: calc(100% + 40px);
  max-width: calc(100% - 300px);

  display: flex;
  flex-direction: column;
  flex-grow: 1;

  padding: 60px 20px 20px;
  margin-top: -60px;

  background: linear-gradient(
    90deg,
    rgba(98, 183, 212, 0.064) 0%,
    rgba(252, 250, 250, 0.064) 43.62%
  );
`

const Header = styled.div``

const Content = styled.div<{ headerHeight?: number }>`
  height: calc(100% - ${(props) => props.headerHeight}px);
  overflow: auto;
  display: flex;
`

export default CatalogDetail
