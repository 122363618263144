import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import { Button, DatePicker, Divider, Select } from 'antd'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
  getBadgeLabels,
  HardcodedFilterOptionsEnum,
} from 'features/project/features/filters/helpers'
import {
  AppliedFilterOptionRange,
  FilterOptionDate,
  FilterOptionRange,
  RelativeDateFilterIdx,
} from '../../types'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import { YogiButton } from 'components/UI/YogiButton'
import { cardBorder, cardBorderGrey } from 'assets/styles/variables'
import DashboardDateSelect from '../../../dashboard/components/Dashboard/components/DashboardDateSelect'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { dateSelectOptions } from 'features/project/features/filters/features/date-panel/constants'
dayjs.extend(utc)
const { Option } = Select

// TODO generalize isInsight to buttonSize
type Props = {
  comparativeIndex: number
  isInsight?: boolean
  filterValues: Filters
  // these are for custom dash charts to have their own filterlists
  updateLocalFilterValues?: (filterValues: Filters) => void
  isChild?: boolean
  parentMin?: number
  parentMax?: number
  isLeft?: boolean
}
export const DatePanel: React.FC<Props> = ({
  comparativeIndex,
  isInsight,
  filterValues,
  updateLocalFilterValues,
  isChild,
  parentMin,
  parentMax,
  isLeft,
}) => {
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const setFilterValues = useProjectStore(
    (state: ProjectState) => state.setFilterValues,
  )
  const updateRelativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.updateRelativeDateFilterIdx,
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const [localRelativeDateFilterIdx, setLocalRelativeDateFilterIdx] =
    useState(-1)
  const panelRef = React.createRef<HTMLDivElement>()
  const [prevValues, setPrevValues] = useState<number[]>([])
  const [currentValues, setCurrentValues] = useState<number[]>([])
  const [visible, setVisible] = useState(false)
  const option = defaultFilterList?.find(
    (filterOption) =>
      filterOption.field === HardcodedFilterOptionsEnum.CREATE_TIME,
  ) as FilterOptionDate
  const [notNullOption, setNotNullOption] = useState(option)

  const defaultTime = defaultFilterList.find(
    (el) => el.field === 'create_time',
  ) as FilterOptionRange

  const inputOption = filterValues.values.find(
    (filterValue) =>
      filterValue.field === HardcodedFilterOptionsEnum.CREATE_TIME,
  ) as AppliedFilterOptionRange

  const handleSetDateSelectValue = () => {
    if (!inputOption) {
      return 'All Time'
    }
    const matchingOption = dateSelectOptions.find(
      (el) =>
        el.value !== 'custom' &&
        el.value !== null &&
        JSON.parse(el.value).min === inputOption?.min &&
        JSON.parse(el.value).max === inputOption?.max,
    )
    if (matchingOption) {
      return matchingOption.value
    } else {
      if (inputOption) {
        return getBadgeLabels(inputOption, defaultFilterList)[0]
      } else {
        // this catches the case where a view has an empty filter from before imposing a date filter on load
        if (defaultTime) {
          return getBadgeLabels(
            {
              field: 'create_time',
              min: defaultTime?.min,
              max: defaultTime?.max,
              value_type: 0,
            },
            defaultFilterList,
          )[0]
        }
      }
    }
  }

  const dateSelectValue = useMemo(
    () => handleSetDateSelectValue(),
    [inputOption],
  )

  useEffect(() => {
    if (!option) return

    if (option.max) setNotNullOption(option)
    if (!_.isEqual(currentValues, prevValues)) {
      setPrevValues(currentValues)
      return
    }

    const existingDateValue = filterValues.values.find(
      (o) => o.field === HardcodedFilterOptionsEnum.CREATE_TIME,
    ) as AppliedFilterOptionRange
    // TODO: move to filterContext
    const minDate = moment
      .unix(
        option?.cur_min ||
          notNullOption?.cur_min ||
          inputOption?.min ||
          option?.min ||
          notNullOption?.min,
      )
      .unix()
    const maxDate = moment
      .unix(
        option?.cur_max ||
          notNullOption?.cur_max ||
          inputOption?.max ||
          option?.max ||
          notNullOption?.max,
      )
      .unix()
    if (!(option && ((minDate && maxDate) || prevValues.length))) {
      // setValidDate(false)
    } else if (option && !existingDateValue) {
      setPrevValues([minDate, maxDate])
      setCurrentValues([minDate, maxDate])
    } else if (existingDateValue && !prevValues.length) {
      setPrevValues([existingDateValue.min, existingDateValue.max])
      setCurrentValues([existingDateValue.min, existingDateValue.max])
    }
  }, [option, localRelativeDateFilterIdx])

  useEffect(() => {
    if (inputOption && prevValues.length) {
      setPrevValues([inputOption.min, inputOption.max])
      setCurrentValues([inputOption.min, inputOption.max])
    }
  }, [inputOption])

  // console.log(isValidDate, option, prevValues.length)
  // if (!isValidDate || !option || !prevValues.length) return null

  const setFilter = (min: number, max: number) => {
    setCurrentValues([min, max])
  }

  const setFilterFromDashSelector = (filters: Filters) => {
    const existingDateValue = filters.values.find(
      (o) => o.field === HardcodedFilterOptionsEnum.CREATE_TIME,
    ) as AppliedFilterOptionRange
    if (existingDateValue) {
      setFilter(existingDateValue.min, existingDateValue.max)
      // setPrevValues([existingDateValue.min, existingDateValue.max])
      // setCurrentValues([existingDateValue.min, existingDateValue.max])
    }
  }

  const updateFilters = (filters: Filters) => {
    if (updateLocalFilterValues) {
      updateLocalFilterValues(filters)
    } else {
      setFilterValues(comparativeIndex, filters.values)
    }
  }

  const submitForm = (curMin: number, curMax: number) => {
    const min = formatDateToUTCMidnight(moment.unix(curMin)).unix()
    const max = formatDateToUTCEndOfDay(moment.unix(curMax)).unix()
    mixpanel.track('filter', {
      action: 'date',
      value: { min: min, max: max },
      ...route,
    })
    const excludedFields = new Set([
      'filter_values',
      HardcodedFilterOptionsEnum.CREATE_TIME,
    ])
    updateFilters({
      values: [
        ...filterValues.values.filter(
          ({ field }) => !excludedFields.has(field),
        ),
        {
          field: HardcodedFilterOptionsEnum.CREATE_TIME,
          min: min,
          max: max,
          value_type: 0, // TODO??
        },
      ],
      searchCondition: filterValues.searchCondition,
      searchQuery: filterValues.searchQuery,
    })
  }

  const isLeftPos =
    isLeft || details.clientSettings.project.toolbar.alignment === 'left'

  return (
    <Wrapper
      style={{
        height: isInsight ? '100%' : '',
      }}
    >
      <StyledSelect
        defaultValue={'Last 12 Months'}
        onSelect={(value: any) => {
          if ((value as string) === 'custom') {
            setVisible(true)
            return
          }
        }}
        onChange={(value) => {
          mixpanel.track('date', {
            action: 'new date selector',
            value,
            ...route,
          })
          if (value === 'custom') {
            // setDateSelectValue('custom')
            setVisible(true)
            return
          }
          if (value === null) {
            // setDateSelectValue('All Time')
            const excludedFields = new Set([
              'filter_values',
              HardcodedFilterOptionsEnum.CREATE_TIME,
            ])
            updateFilters({
              values: [
                ...filterValues.values.filter(
                  ({ field }) => !excludedFields.has(field),
                ),
              ],
              searchCondition: filterValues.searchCondition,
              searchQuery: filterValues.searchQuery,
            })
          }
          const selectedValue = JSON.parse(value as string)
          if (selectedValue) {
            // setFilter(selectedValue.min, selectedValue.max)
            submitForm(selectedValue.min, selectedValue.max)
            // setLocalRelativeDateFilterIdx(RelativeDateFilterIdx.Unset)
          }
        }}
        style={{
          minWidth: 200,
        }}
        value={dateSelectValue}
      >
        {dateSelectOptions.map((date, index) => (
          <Option key={index} value={date.value}>
            {date.label}
          </Option>
        ))}
      </StyledSelect>
      {visible && (
        <PickerContainer
          ref={panelRef}
          panels={comparativePanelsNumber}
          isChild={isChild}
          isLeftPos={isLeftPos}
        >
          <ButtonsWrapper>
            <DashboardDateSelect
              filterValues={filterValues}
              updateLocalFilterValues={setFilterFromDashSelector}
              excludeCustom
            />
          </ButtonsWrapper>
          <Content>
            <DatePicker.RangePicker
              format="MM/DD/YYYY"
              minDate={dayjs
                .unix(parentMin ?? option?.min ?? defaultTime?.min)
                .utc()
                .startOf('day')}
              maxDate={dayjs
                .unix(parentMax ?? option?.max ?? defaultTime?.max)
                .utc()
                .endOf('day')}
              value={[
                dayjs
                  .unix(currentValues[0] ?? prevValues[0] ?? inputOption?.min)
                  .utc(),
                dayjs
                  .unix(currentValues[1] ?? prevValues[1] ?? inputOption?.max)
                  .utc(),
              ]}
              onChange={(dates) => {
                if (dates && dates[0] && dates[1]) {
                  // const firstDate = formatDateToUTCMidnight(dates[0])
                  // const secondDate = formatDateToUTCEndOfDay(dates[1])
                  const firstDate = dates[0].utc().startOf('day')
                  const secondDate = dates[1].utc().endOf('day')
                  setFilter(firstDate.unix(), secondDate.unix())
                  setLocalRelativeDateFilterIdx(RelativeDateFilterIdx.Unset)
                }
              }}
            />
          </Content>
          <Divider style={{ margin: 0 }} />
          <ButtonsWrapper>
            <Button
              type="text"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                handleSetDateSelectValue()
                setVisible(false)
              }}
            >
              Cancel
            </Button>
            {/*<YogiButton*/}
            {/*  type="default"*/}
            {/*  onClick={() => {*/}
            {/*    const minDate = moment*/}
            {/*      .unix(*/}
            {/*        parentMin ||*/}
            {/*          inputOption?.min ||*/}
            {/*          option.min ||*/}
            {/*          notNullOption.min*/}
            {/*      )*/}
            {/*      .unix()*/}
            {/*    const maxDate = moment*/}
            {/*      .unix(*/}
            {/*        parentMax ||*/}
            {/*          inputOption?.max ||*/}
            {/*          option.max ||*/}
            {/*          notNullOption.max*/}
            {/*      )*/}
            {/*      .unix()*/}
            {/*    setPrevValues([minDate, maxDate])*/}
            {/*    if (isChild) {*/}
            {/*      setCurrentValues([parentMin ?? minDate, parentMax ?? maxDate])*/}
            {/*    }*/}
            {/*    clearFilter(*/}
            {/*      comparativeIndex,*/}
            {/*      HardcodedFilterOptionsEnum.CREATE_TIME*/}
            {/*    )*/}
            {/*    setVisible(false)*/}
            {/*    setLocalRelativeDateFilterIdx(RelativeDateFilterIdx.Unset)*/}

            {/*    updateSelectedOptionField(comparativeIndex, '')*/}
            {/*    clearOpenedOptions(comparativeIndex)*/}
            {/*    if (updateLocalFilterValues) {*/}
            {/*      updateLocalFilterValues({*/}
            {/*        values: [*/}
            {/*          ...filterValues.values.filter(*/}
            {/*            ({ field }) => field !== 'create_time'*/}
            {/*          ),*/}
            {/*        ],*/}
            {/*        searchCondition: filterValues.searchCondition,*/}
            {/*        searchQuery: filterValues.searchQuery,*/}
            {/*      })*/}
            {/*    }*/}
            {/*    updateRelativeDateFilterIdx(*/}
            {/*      comparativeIndex,*/}
            {/*      RelativeDateFilterIdx.Unset*/}
            {/*    )*/}
            {/*  }}*/}
            {/*>*/}
            {/*  Clear*/}
            {/*</YogiButton>*/}
            <YogiButton
              type="primary"
              onClick={() => {
                submitForm(currentValues[0], currentValues[1])
                setVisible(false)
                updateSelectedOptionField(comparativeIndex, '')
                clearOpenedOptions(comparativeIndex)
                updateRelativeDateFilterIdx(
                  comparativeIndex,
                  localRelativeDateFilterIdx,
                )
              }}
            >
              Apply
            </YogiButton>
          </ButtonsWrapper>
        </PickerContainer>
      )}
      {/*<Button*/}
      {/*  icon={<CalendarOutlined />}*/}
      {/*  style={{*/}
      {/*    border: `2px solid ${cardBorderGrey}`,*/}
      {/*    borderRadius: '4px',*/}
      {/*    boxShadow: 'none',*/}
      {/*  }}*/}
      {/*  onClick={() => setVisible((prev) => !prev)}*/}
      {/*/>*/}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  display: flex;
  gap: 10px;
  position: relative;
  width: 100%;
`
const PickerContainer = styled.div<{
  panels: number
  isChild?: boolean
  isLeftPos?: boolean
}>`
  width: ${({ panels }) => 'calc(100vh/' + panels + ')'};
  max-width: ${({ isChild }) => (isChild ? '400px' : '600px')};
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: ${({ isLeftPos }) => !isLeftPos && 0};
  z-index: 100;
  box-shadow:
    0 10px 15px rgba(0, 0, 0, 0.1),
    0 4px 6px rgba(0, 0, 0, 0.05);

  border: 2px solid ${cardBorderGrey};
  border-radius: 4px;
`
const Content = styled.div`
  padding: 5px 14px 10px;
  flex: 1;

  .ant-picker-range {
    width: 100%;
  }
`
const ButtonsWrapper = styled.div`
  padding: 10px 14px;
  display: flex;
  flex-wrap: wrap;

  & > button:not(:last-child) {
    margin-right: 5px;
  }
`

const StyledSelect = styled(Select)`
  .ant-select-selector {
    border: 2px solid ${cardBorder}!important;
    border-radius: 10px !important;

    box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.15);
  }

  .ant-select-selection-item {
    font-size: var(--font-size-md);
  }
`
