import React from 'react'
import { Table } from 'antd'
import styled from 'styled-components'

type Props = {
  name: string
  image_url: string
  first_review: string
  last_review: string
  review_count: number
  rating_count: number
  generic_listing: boolean
}

const columns = [
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
    render: (text: string) => <b>{text}</b>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
]

export const ProductTableDescription: React.FC<Props> = ({
  name,
  image_url,
  first_review,
  last_review,
  review_count,
  rating_count,
  generic_listing,
}) => {
  let table_data = [
    {
      key: 'name',
      field: 'Name',
      value: name,
    },
    // {
    //   key: 'first_review',
    //   field: 'First review or rating',
    //   value: first_review,
    // },
    // {
    //   key: 'last_review',
    //   field: 'Last review or rating',
    //   value: last_review,
    // },
    {
      key: 'review_count',
      field: 'Reviews',
      value: review_count,
    },
    {
      key: 'rating_count',
      field: 'Ratings',
      value: rating_count,
    },
    {
      key: 'generic_listing',
      field: 'General Listing',
      value: '' + generic_listing,
    },
  ]
  return (
    <>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={table_data}
          size="small"
          // bordered
          showHeader={false}
          pagination={false}
          style={{ width: '100%' }}
        ></Table>
      </TableWrapper>
    </>
  )
}

const TableWrapper = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
