import {
  AppliedFilterOption,
  FilterOptionSelect,
} from 'features/project/features/filters/types'
import {
  getBadgeLabels,
  HardcodedFilterOptionsEnum,
} from 'features/project/features/filters/helpers'
import { Tag, Tooltip } from 'antd'
import React from 'react'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import styled from 'styled-components'
import pluralize from 'pluralize'

const colors = [
  'magenta',
  'red',
  'volcano',
  'orange',
  'gold',
  'lime',
  'green',
  'cyan',
  'blue',
  'geekblue',
  'purple',
]

export const useFilterBadges = (
  filterValues: Filters,
  comparativeIndex: number,
  openFilterPanel?: (field: string) => void,
  updateLocalFilterValues?: (filterValues: Filters) => void,
  infoOnly?: boolean,
) => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const clearFilter = useProjectStore(
    (state: ProjectState) => state.clearFilter,
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )

  function getTag(value: AppliedFilterOption, index: number) {
    const color = colors[index]

    let valuesCount =
      'values' in value
        ? value.field.includes('sentiment')
          ? value.values.filter((item) => item !== '1' && item !== '0').length
          : value.values.length
        : 1

    const filterOption = defaultFilterList?.find((o) => o.field === value.field)

    // catch dupes if theme & grouped dash because grouped dash can have
    // multiple entries of the same theme name, most typically
    // Critics / Detractors or Fans / Attractors
    // this is probably inefficient
    if (filterOption?.field === 'theme') {
      const dupeObj: any = {}
      'values' in value &&
        value.values.forEach((item, index) => {
          const option = (filterOption as FilterOptionSelect).values?.find(
            (el) => el.cluster_uuid === item,
          )
          if (option) {
            if (!dupeObj[option.value]) {
              dupeObj[option.value] = []
            }
            dupeObj[option.value].push(item)
          } else {
            if (!dupeObj['deleted_value_' + index]) {
              dupeObj['deleted_value_' + index] = []
            }
            dupeObj['deleted_value_' + index].push(item)
          }
        })
      valuesCount = Object.keys(dupeObj).length
    }

    valuesCount = [...new Set(getBadgeLabels(value, defaultFilterList))].length

    return (
      <Tooltip
        key={value.field}
        placement="bottom"
        title={[...new Set(getBadgeLabels(value, defaultFilterList))]
          ?.sort((a, b) => (a?.toLowerCase() > b?.toLowerCase() ? 1 : -1))
          ?.map((option, index) => {
            return (
              <span key={index}>
                {filterOption?.field === 'source_type' && option === 'Null'
                  ? 'Syndicated'
                  : option}
                <br />
              </span>
            )
          })}
      >
        <StyledTag
          key={value.field}
          id={'filter-badge-' + value.field}
          color={color}
          closable={!infoOnly}
          onClose={() => {
            if (updateLocalFilterValues) {
              updateLocalFilterValues({
                ...filterValues,
                values: filterValues.values.filter(
                  (el) => el.field !== value.field,
                ),
              })
            } else {
              clearFilter(comparativeIndex, value.field)
              updateSelectedOptionField(comparativeIndex, '')
              clearOpenedOptions(comparativeIndex)
            }
          }}
          style={{
            paddingRight: infoOnly ? '10px' : '6px',
            cursor: !!openFilterPanel ? 'pointer' : 'default',
          }}
          onClick={(e) => {
            if (openFilterPanel && defaultFilterList) {
              openFilterPanel(value.field)
            }
            e.stopPropagation()
          }}
        >
          <b>{valuesCount}</b>{' '}
          {filterOption?.alias
            ? pluralize(filterOption?.alias, valuesCount)
            : value.field}
        </StyledTag>
      </Tooltip>
    )
  }
  return {
    getTag,
  }
}
const StyledTag = styled(Tag)`
  padding: 4px 6px 4px 10px;
  font-weight: 300;

  .ant-tag-close-icon {
    margin-left: 5px;
  }
`
