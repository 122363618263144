import React, { useRef } from 'react'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import {
  exportCardBackground,
  exportCardBackgroundHover,
  lightBlueFontColor,
} from 'assets/styles/variables'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import FilterSvgIcon from 'features/project/features/Charts/components/ExportDrawer/Icons/FilterSvgIcon/FilterSvgIcon'
import ViewSvgIcon from 'features/project/features/Charts/components/ExportDrawer/Icons/ViewSvgIcon/ViewSvgIcon'
import DashboardSvgIcon from 'features/project/features/Charts/components/ExportDrawer/Icons/DashboardSvgIcon/DashboardSvgIcon'
import ReviewsSvgIcon from 'features/project/features/Charts/components/ExportDrawer/Icons/ReviewsSvgIcon/ReviewsSvgIcon'
import ImageSvgIcon from 'features/project/features/Charts/components/ExportDrawer/Icons/ImageSvgIcon/ImageSvgIcon'
import CsvSvgIcon from 'features/project/features/Charts/components/ExportDrawer/Icons/CsvSvgIcon/CsvSvgIcon'
import { Input } from 'antd'
import { SaveFilter } from 'features/project/features/Charts/components/ExportDrawer/components/SaveFilter/SaveFilter'
import { SaveView } from 'features/project/features/Charts/components/ExportDrawer/components/SaveView/SaveView'
import { SaveDashboard } from 'features/project/features/Charts/components/ExportDrawer/components/SaveDashboard/SaveDashboard'
import { ExportReviews } from 'features/project/features/Charts/components/ExportDrawer/components/ExportReviews/ExportReviews'
import { FilePptOutlined } from '@ant-design/icons'
import { DrawerLoader } from 'components/Loading/DrawerLoader/DrawerLoader'
import { ExportCsv } from 'features/project/features/Charts/components/ExportDrawer/components/ExportCsv/ExportCsv'

type Props = {
  isPublic?: boolean
}
export const ExportDrawer: React.FC<Props> = ({ isPublic }) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const isExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isExportDrawerOpen,
  )
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )
  const exportData = useProjectStore((state: ProjectState) => state.exportData)
  const isExporting = useProjectStore(
    (state: ProjectState) => state.isExporting,
  )

  const ref = useRef<HTMLDivElement>(null)

  const isOpen = isExportDrawerOpen && !!exportData

  return (
    <>
      <OverlayContainer
        isOpen={isOpen}
        onClick={() => {
          setIsExportDrawerOpen(false)
        }}
        // custom dash references export internally which messes with the positioning
        style={{ marginTop: route.isDashboard ? '-55px' : 0 }}
      ></OverlayContainer>
      <Drawer
        ref={ref}
        isOpen={isOpen}
        // custom dash references alerts internally which messes with the positioning
        style={{ marginTop: route.isDashboard ? '-55px' : 0 }}
      >
        <Container>
          {/*{!isPublic && (*/}
          <Section>
            <Title>Save</Title>
            <CardList>
              <SaveFilter
                filterValues={exportData?.filterValues}
                comparativeIndex={exportData?.comparativeIndex}
              >
                <Card>
                  <Icon>
                    <GenericSvgIcon SvgComponent={FilterSvgIcon} />
                  </Icon>
                  <Text>
                    save as <Bold>filter</Bold>
                  </Text>
                </Card>
              </SaveFilter>
              {!route?.isDashboard && (
                <>
                  <SaveView>
                    <Card>
                      <Icon>
                        <GenericSvgIcon SvgComponent={ViewSvgIcon} />
                      </Icon>
                      <Text>
                        save as <Bold>view</Bold>
                      </Text>
                    </Card>
                  </SaveView>
                  {(route.isCharts || route.isDashboard) && (
                    <SaveDashboard
                      filterValues={exportData?.filterValues}
                      comparativeIndex={exportData?.comparativeIndex}
                    >
                      <Card>
                        <Icon>
                          <GenericSvgIcon SvgComponent={DashboardSvgIcon} />
                        </Icon>
                        <Text>
                          save as <Bold>dashboard</Bold>
                        </Text>
                      </Card>
                    </SaveDashboard>
                  )}
                </>
              )}
            </CardList>
          </Section>
          {/*)}*/}

          <Section>
            <Title>Export</Title>
            <CardList>
              {/*{!isPublic && (*/}
              <ExportReviews
                comparativeIndex={exportData?.comparativeIndex}
                filterValues={exportData?.filterValues}
              >
                <Card>
                  <Icon>
                    <GenericSvgIcon SvgComponent={ReviewsSvgIcon} />
                  </Icon>
                  <Text style={{ marginBottom: 6 }}>
                    export <Bold>reviews</Bold> to <Bold>email</Bold>
                  </Text>
                </Card>
              </ExportReviews>
              {/*)}*/}
              {exportData?.downloadImage && (
                <Card onClick={exportData?.downloadImage}>
                  <Icon>
                    <GenericSvgIcon SvgComponent={ImageSvgIcon} />
                  </Icon>
                  <Text>
                    export as <Bold>png</Bold>
                  </Text>
                </Card>
              )}
              {exportData?.downloadCsv && (
                <ExportCsv
                  exportCsv={exportData.downloadCsv}
                  exportCsvWithThemeDrivers={
                    exportData.downloadCsvWithThemeDrivers
                  }
                >
                  <Card>
                    <Icon>
                      <GenericSvgIcon SvgComponent={CsvSvgIcon} />
                    </Icon>
                    <Text>
                      export chart as <Bold>csv</Bold>
                    </Text>
                  </Card>
                </ExportCsv>
              )}
              {exportData?.exportPPT && (
                <Card onClick={exportData?.exportPPT}>
                  <Icon>
                    {/*<GenericSvgIcon SvgComponent={CsvSvgIcon} />*/}
                    <FilePptOutlined style={{ fontSize: 30 }} />
                  </Icon>
                  <Text>
                    export as <Bold>pptx</Bold>
                  </Text>
                </Card>
              )}
            </CardList>
            {/*<AdditionalControls>*/}
            {/*  {isPublicViewer && (*/}
            {/*    // TODO TEST/FIX THIS -- certainly breaks hardcoded heights*/}
            {/*    <Header>*/}
            {/*      <StyledInput*/}
            {/*        placeholder="Email..."*/}
            {/*        value={publicViewerEmail}*/}
            {/*        onChange={(e: ChangeEvent<HTMLInputElement>) => {*/}
            {/*          const value = e.target.value*/}
            {/*          setPublicViewerEmail(value)*/}
            {/*          validationSchema*/}
            {/*            .validate(value)*/}
            {/*            .then(() => setPublicEmailIsValid(true))*/}
            {/*            .catch(() => setPublicEmailIsValid(false))*/}
            {/*        }}*/}
            {/*      />*/}
            {/*    </Header>*/}
            {/*  )}*/}
            {/*</AdditionalControls>*/}
          </Section>
        </Container>
        {isExporting && (
          <LoadingOverlay>
            <DrawerLoader></DrawerLoader>
          </LoadingOverlay>
        )}
      </Drawer>
    </>
  )
}

const OverlayContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${(props) => (props.isOpen ? 200 : -1)};
  height: 100vh;
  width: 100vw;
  transition: 0.2s ease-in-out;
  background: ${(props) => (props.isOpen ? 'rgba(0, 0, 0, 0.2)' : 'none')};
`

const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 201;
  height: 100vh;
  width: 50%;
  min-width: 500px;
  max-width: 750px;
  background: white;
  right: 0;
  top: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
  overflow: auto;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 47px 44px;
`

const Section = styled.div`
  padding-bottom: 40px;
`

const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
`

const CardList = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-top: 20px;
`

const Card = styled.div`
  height: 175px;
  width: 175px;

  display: flex;
  flex-direction: column;
  justify-content: end;
  align-items: center;

  background: ${exportCardBackground};
  color: ${lightBlueFontColor};

  cursor: pointer;
  border-radius: 10px;

  transition: 0.2s ease;

  &:hover {
    background: ${exportCardBackgroundHover};
  }
  &:focus {
    background: ${exportCardBackgroundHover};
  }
`

const Icon = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`

const Text = styled.div`
  font-size: 14px;
  margin-bottom: 27px;
  text-align: center;
  line-height: 1.5;
  padding: 0 10px;
`

const Bold = styled.span`
  font-weight: 600;
`

const AdditionalControls = styled.div``
const Header = styled.div`
  padding: var(--default-padding-half) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
const StyledInput = styled(Input)`
  width: 200px;
  margin-right: var(--default-padding-half);
`

const LoadingOverlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  padding-top: 40%;
  top: 0;
  left: 0;
  z-index: 100;
  background: rgba(255, 255, 255, 0.4);
`
const LoaderWrapper = styled.div``
