import create from 'zustand'
import { produce } from 'immer'
import { DateRange } from './components/Dashboard/components/DashboardDateSelect'

export type DashboardState = {
  draggedItem: any
  setDraggedItem: (item: any) => void
  isSmartDashboard: boolean
  setIsSmartDashboard: (isSmartDashboard: boolean) => void
  areAnnotationsOpen: boolean
  setAreAnnotationsOpen: (areAnnotationsOpen: boolean) => void
  hoveredAnnotation: any
  setHoveredAnnotation: (hoveredAnnotation: any) => void
  selectedChartInfo: any
  setSelectedChartInfo: (selectedChartInfo: any) => void
}

// TODO typing
export const useDashboardStore = create<DashboardState>()((set) => ({
  draggedItem: null,
  setDraggedItem: (chart: any) =>
    set(
      produce((state: any) => {
        state.draggedItem = chart
      }),
    ),
  isSmartDashboard: false,
  setIsSmartDashboard: (isSmartDashboard: boolean) =>
    set(
      produce((state: any) => {
        state.isSmartDashboard = isSmartDashboard
      }),
    ),
  areAnnotationsOpen: false,
  setAreAnnotationsOpen: (areAnnotationsOpen: boolean) =>
    set(
      produce((state: any) => {
        state.areAnnotationsOpen = areAnnotationsOpen
      }),
    ),
  hoveredAnnotation: null,
  setHoveredAnnotation: (hoveredAnnotation: boolean) =>
    set(
      produce((state: any) => {
        state.hoveredAnnotation = hoveredAnnotation
      }),
    ),
  selectedChartInfo: null,
  setSelectedChartInfo: (selectedChartInfo: any) =>
    set(
      produce((state: any) => {
        state.selectedChartInfo = selectedChartInfo
      }),
    ),
}))
