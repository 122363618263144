export enum SentimentValuesEnum {
  HIGH = 'high',
  MEDIUM = 'medium',
  LOW = 'low',
}
export type ProjectMetadataResponse = {
  stop_words: { value: null | string[]; alias: string },
  embedder?: { value: null | string; alias: string }
}
export type ProjectMetadataPayload = {
  sentiment_level: SentimentValuesEnum
  parse_sentences: boolean
  stop_words: string[]
  bypass_nlp: boolean
  embedder?: string
}

export const embedder_types = [
  'universal-sentence-encoder-1',
  'universal-sentence-encoder-1-hub',
  'universal-sentence-encoder-4',
  'universal-sentence-encoder-large-5',
  'all-mpnet-base-v2',
  'all-MiniLM-L12-v1',
  'all-distilroberta-v1'
]
