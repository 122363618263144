import styled from 'styled-components'

export const Container = styled.div`
  height: 100%;
`

export const ChartTitle = styled.div`
  font-weight: 500;
  margin-bottom: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
