import React from 'react'

const FilterSvgIcon: React.FC = () => (
  <svg
    width="35"
    height="30"
    viewBox="0 0 35 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.19048 5.16437L2.19047 5.16438L2.19325 5.16792C3.80172 7.21908 6.09131 10.1457 7.97902 12.5599C8.92283 13.7669 9.76608 14.8457 10.3734 15.6228L11.0904 16.5403L11.14 16.6037V25.7656C11.14 27.1944 12.3097 28.35 13.733 28.35H16.919C18.3424 28.35 19.5121 27.1944 19.5121 25.7656V16.603L19.5617 16.5393L20.2769 15.6218C20.8827 14.8448 21.7239 13.7661 22.6657 12.5591C24.5494 10.1449 26.8348 7.21864 28.4429 5.16792L28.4457 5.16437C29.7767 3.45123 28.5383 1 26.3975 1H4.23864C2.09789 1 0.859467 3.45123 2.19048 5.16437Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M32.7417 19.3153V15.975H33.4097C33.7771 15.975 34.0778 15.6744 34.0778 15.307C34.0778 14.9395 33.7771 14.6389 33.4097 14.6389H26.7292C26.3617 14.6389 26.0611 14.9395 26.0611 15.307C26.0611 15.6744 26.3617 15.975 26.7292 15.975H27.3972V19.3153C27.3972 20.4243 26.502 21.3195 25.3931 21.3195V22.6556H29.3814V27.3319L30.0494 28L30.7175 27.3319V22.6556H34.7458V21.3195C33.6369 21.3195 32.7417 20.4243 32.7417 19.3153Z"
      fill="currentColor"
    />
  </svg>
)

export default FilterSvgIcon
