import React from 'react'
import styled from 'styled-components'
import { FormTitle } from 'shared/components'
import { CardNew } from './card-new'

type Props = {
  onBlankClick: () => void
  onCSVClick: () => void
}

export const Selector: React.FC<Props> = ({ onBlankClick, onCSVClick }) => {
  return (
    <Wrapper>
      <FormTitle>Add Products and Sources</FormTitle>
      <Flex>
        <CardWrapper onClick={onBlankClick}>
          <CardNew text="Add products and sources to the project individually." />
        </CardWrapper>
        <CardWrapper onClick={onCSVClick}>
          <CardNew text="Upload a CSV to prefill new products and sources to add to the project." />
        </CardWrapper>
      </Flex>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 700px;
`
const Flex = styled.div`
  display: flex;
  justify-content: space-around;
`
const CardWrapper = styled.div`
  width: 300px;
`
