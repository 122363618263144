import React from 'react'
import styled from 'styled-components'
import { Form, Select, Button } from 'antd'
import { FormikProps } from 'formik'
import { FormTitle } from 'shared/components'
import { CustomFields } from 'features/project-form/components'
import { sourceTypes } from 'utils/source_types'
import { UploadType } from 'utils/enums'
import { SourceProvider } from './source-providers'
import { SourceValues } from '../source-form'
import { ProductForSource } from '../types'

export type FormikType = FormikProps<SourceValues>
type Props = {
  formik: FormikType
  products: ProductForSource[]
  onDelete?: () => void
}

const options = [UploadType.File, ...Object.keys(sourceTypes)]

export const NewSource: React.FC<Props> = ({ formik, onDelete, products }) => {
  return (
    <Wrapper>
      <FormTitle>New Source</FormTitle>
      <Form.Item
        name="sourceType"
        label="Source Type"
        wrapperCol={{ span: 6 }}
        validateStatus={formik.errors.sourceType && 'error'}
        help={formik.errors.sourceType}
      >
        <Select
          placeholder="Source Type"
          showSearch
          value={formik.values.sourceType || undefined}
          onChange={(option) => {
            formik.setFieldValue('sourceType', option)
            formik.setFieldValue('sourceUrl', '')
          }}
          allowClear
        >
          {options.map((option) => (
            <Select.Option value={option} key={option}>
              {option}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <Form.Item
        name="product_id"
        label="Product"
        wrapperCol={{ span: 6 }}
        validateStatus={formik.errors.product_id && 'error'}
        help={formik.errors.product_id}
      >
        <Select
          placeholder="Product"
          defaultValue={formik.values.product_id || undefined}
          value={formik.values.product_id}
          onChange={(option) => formik.setFieldValue('product_id', option)}
          allowClear
        >
          {products.map((product) => (
            <Select.Option value={product.id} key={product.id}>
              {product.name}
            </Select.Option>
          ))}
        </Select>
      </Form.Item>

      <SourceProvider formik={formik} sourceType={formik.values.sourceType} />
      <Error>{formik.errors.custom}</Error>

      {!!formik.values.sourceType &&
        formik.values.sourceType !== UploadType.File && (
          <CustomFields
            fields={formik.values.custom}
            onFieldAdd={(field: string, value: string) =>
              formik.setFieldValue(`custom`, {
                ...formik.values.custom,
                [field]: value,
              })
            }
            onFieldRemove={(field: string) => {
              const values = formik.values.custom
              if (values) {
                delete values[field]
              }
              formik.setFieldValue(`custom`, values)
            }}
          />
        )}

      <ButtonsWrapper>
        {onDelete && <SubmitButton onClick={onDelete}>Delete</SubmitButton>}
        <SubmitButton type="primary" htmlType="submit">
          {onDelete ? 'Save' : 'Add Source'}
        </SubmitButton>
      </ButtonsWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 800px;
  height: 700px;
  overflow: auto;
  display: flex;
  flex-direction: column;

  @media screen and (max-width: 1024px) {
    width: auto;
  }
`
const ButtonsWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`
const SubmitButton = styled(Button)`
  display: block;
  margin: 40px 20px 0;
`
const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
`
