import { mainText } from '../../../../assets/styles/variables'
import { Grid } from '@mui/material'
import React from 'react'
import styled from 'styled-components'
import { HeartOutlined, MessageOutlined, StarOutlined } from '@ant-design/icons'

export const conditionOptionsList: {
  label: string
  volume_label: string
  std_dev_label: string
  value: string
  color: string
  type: string
}[] = [
  {
    label: 'has positively or negatively changed',
    volume_label: 'has positively or negatively changed',
    std_dev_label: '(+/- .75 Std Dev)',
    value: 'Change',
    color: '',
    type: 'outlier',
  },
  // {
  //   label: 'Significantly Changed',
  //   volume_label: 'Significantly Changed',
  //   std_dev_label: '(+/- 1.25 Std Dev)',
  //   value: 'Significant Change',
  //   color: '',
  //   type: 'outlier',
  // },
  // {
  //   label: 'Significantly More Negative',
  //   volume_label: 'Significantly Lower',
  //   std_dev_label: '(-1.25 Std Dev)',
  //   value: 'Significant Negative',
  //   color: '',
  //   type: 'outlier',
  // },
  {
    label: 'has negatively changed',
    volume_label: 'has negatively changed',
    std_dev_label: '(-.75 Std Dev)',
    value: 'Negative Change',
    color: '',
    type: 'outlier',
  },
  {
    label: 'has positively changed',
    volume_label: 'Slightly Higher',
    std_dev_label: '(+.75 Std Dev)',
    value: 'Positive Change',
    color: '',
    type: 'outlier',
  },
  // {
  //   label: 'Significantly More Positive',
  //   volume_label: 'significantly higher',
  //   std_dev_label: '(+1.25 Std Dev)',
  //   value: 'Significant Positive',
  //   color: '',
  //   type: 'outlier',
  // },
  {
    label: 'is above',
    volume_label: 'is above',
    std_dev_label: '',
    value: 'Above',
    color: '',
    type: 'limit',
  },
  {
    label: 'is below',
    volume_label: 'is below',
    std_dev_label: '',
    value: 'Below',
    color: '',
    type: 'limit',
  },
  {
    label: 'increased by more than',
    volume_label: 'increased by more than',
    std_dev_label: '',
    value: 'Increased',
    color: '',
    type: 'limit',
  },
  {
    label: 'decreased by more than',
    volume_label: 'decreased by more than',
    std_dev_label: '',
    value: 'Decreased',
    color: '',
    type: 'limit',
  },
]

export const outlierAlertsList: {
  label: string
  volume_label: string
  std_dev_label: string
  value: string
  color: string
}[] = [
  {
    label: 'negatively or positively changed',
    volume_label: 'slightly changed',
    std_dev_label: '(+/- .75 Std Dev)',
    value: 'Change',
    color: '',
  },
  // {
  //   label: 'significantly changed',
  //   volume_label: 'significantly changed',
  //   std_dev_label: '(+/- 1.25 Std Dev)',
  //   value: 'Significant Change',
  //   color: '',
  // },
  // {
  //   label: 'significantly more negative',
  //   volume_label: 'significantly lower',
  //   std_dev_label: '(-1.25 Std Dev)',
  //   value: 'Significant Negative',
  //   color: '',
  // },
  {
    label: 'negatively changed',
    volume_label: 'slightly lower',
    std_dev_label: '(-.75 Std Dev)',
    value: 'Negative Change',
    color: '',
  },
  {
    label: 'positively changed',
    volume_label: 'slightly higher',
    std_dev_label: '(+.75 Std Dev)',
    value: 'Positive Change',
    color: '',
  },
  // {
  //   label: 'significantly more positive',
  //   volume_label: 'significantly higher',
  //   std_dev_label: '(+1.25 Std Dev)',
  //   value: 'Significant Positive',
  //   color: '',
  // },
]

export const customLimitsList: {
  label: string
  volume_label: string
  std_dev_label: string
  value: string
  color: string
}[] = [
  {
    label: 'above',
    volume_label: 'above',
    std_dev_label: '',
    value: 'Above',
    color: '',
  },
  {
    label: 'below',
    volume_label: 'below',
    std_dev_label: '',
    value: 'Below',
    color: '',
  },
  {
    label: 'increased by more than',
    volume_label: 'increased by more than',
    std_dev_label: '',
    value: 'Increased',
    color: '',
  },
  {
    label: 'decreased by more than',
    volume_label: 'decreased by more than',
    std_dev_label: '',
    value: 'Decreased',
    color: '',
  },
]

export const intervalOptionsList: { label: string; value: string }[] = [
  {
    label: 'Week',
    value: 'Week',
  },
  {
    label: 'Month',
    value: 'Month',
  },
  {
    label: 'Quarter',
    value: 'Quarter',
  },
]

export const alertScopeOptions: {
  label: string
  value: string
  field: string
}[] = [
  {
    label: 'Brands',
    value: 'Brand',
    field: 'brand',
  },
  {
    label: 'Products',
    value: 'Product',
    field: 'product_hierarchy',
  },
  // {
  //   label: 'Datasets',
  //   value: 'Dataset',
  //   field: 'proj_uuid',
  // },
  {
    label: 'Sources',
    value: 'Source',
    field: 'source_type',
  },
]
export const promotionalOptions: {
  label: string
  value: string | null
}[] = [
  {
    label: 'All Reviews',
    value: null,
  },
  {
    label: 'Non-Promotional',
    value: 'Not Promotional',
  },
  {
    label: 'Promotional Only',
    value: 'Promotional',
  },
]

export const ListHeader = () => {
  return (
    <Grid
      container
      spacing={1}
      style={{
        paddingLeft: 40,
        paddingRight: 16,
        fontWeight: 600,
        color: mainText,
      }}
    >
      <CenterGrid item xs={4}>
        ALERT NAME
      </CenterGrid>
      <CenterGrid item xs={1} style={{ alignItems: 'center' }}>
        STATUS
      </CenterGrid>
      <CenterGrid item xs={1} style={{ alignItems: 'center' }}>
        INTERVALS
      </CenterGrid>
      {/*<CenterGrid item xs={1}>*/}
      {/*  ITEMS*/}
      {/*</CenterGrid>*/}
      <CenterGrid item xs={1}>
        RECIPIENTS
      </CenterGrid>
      <CenterGrid item xs={1}>
        LAST SENT
      </CenterGrid>
      <CenterGrid item xs={1}>
        CREATED BY
      </CenterGrid>
      <CenterGrid item xs={2}></CenterGrid>
      <CenterGrid item xs={1}></CenterGrid>
    </Grid>
  )
}
const CenterGrid = styled(Grid)`
  text-align: center;
`

interface AlertTypeOption {
  label: string
  value: string
  icon: React.ReactNode
}

export const alertTypeOptions: AlertTypeOption[] = [
  { label: 'Star Rating', value: 'Star Rating', icon: <StarOutlined /> },
  {
    label: 'Sentiment',
    value: 'Sentiment',
    icon: <HeartOutlined style={{ fontWeight: 'bold' }} />,
  },
  { label: 'Volume', value: 'Volume', icon: <MessageOutlined /> },
]

export const defaultValues = {
  title: '',
  alert_type: 'Star Rating',
  trigger_type: 'Change',
  time_interval: 'Month',
  alert_scope: 'Brand',
  reviews_filter: 0,
  is_grouped: true,
  showCompareValue: false,
}
