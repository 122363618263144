import { axiosInstance } from 'utils/axios'
import { ProjectMetadataPayload, ProjectMetadataResponse } from './types'

export function startProcessing(
  proj_uuid: string,
  data?: any
): Promise<unknown> {
  return axiosInstance.post('/etl/network', data, { params: { proj_uuid } })
}

export function refreshPreferences(
  proj_uuid: string,
  data?: any
): Promise<unknown> {
  return axiosInstance.put('/project/settings-update', data, {
    params: { proj_uuid },
  })
}

export function rerunStopWords(
  proj_uuid: string,
  data?: any
): Promise<unknown> {
  return axiosInstance.post('/etl/ingestion/embeddings', data, {
    params: { proj_uuid },
  })
}

export function startNLP(proj_uuid: string, data?: any): Promise<unknown> {
  return axiosInstance.post('etl/ingestion/nlp', data, {
    params: { proj_uuid },
  })
}

export function runProjectUpdate(
  proj_uuid: string,
  data?: any
): Promise<unknown> {
  return axiosInstance.put('/source/project-update', data, {
    params: { proj_uuid },
  })
}

export function createReports(proj_uuid: string): Promise<unknown> {
  return axiosInstance.get('/project/reports', { params: { proj_uuid } })
}

export function switchUserRole(
  proj_uuid: string,
  data?: any
): Promise<unknown> {
  return axiosInstance.put('/project/role', data, {
    params: { proj_uuid },
  })
}

export function updateProject(
  proj_uuid: string,
  payload: { title: string; description: string; user_proj_settings: any }
): Promise<unknown> {
  return axiosInstance.put('/project', payload, { params: { proj_uuid } })
}

export function getStopwordsSuggestion(proj_uuid: string): Promise<unknown> {
  return axiosInstance.get('/project/suggest-stopwords', {
    params: { proj_uuid },
  })
}

export function getProjectMetadata(
  proj_uuid: string
): Promise<ProjectMetadataResponse> {
  return axiosInstance
    .get('/project/metadata', { params: { proj_uuid } })
    .then(({ data }) => data)
}

export function updateProjectMetadata(
  proj_uuid: string,
  metadata: ProjectMetadataPayload
): Promise<unknown> {
  return axiosInstance.put(
    '/project/metadata',
    { metadata },
    { params: { proj_uuid } }
  )
}
