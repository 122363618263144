import React from 'react'
import styled from 'styled-components'
import { mainText } from '../../../assets/styles/variables'

interface Props {
  color?: string
  style?: React.CSSProperties
  children?: React.ReactNode
}

const YogiInfo: React.FC<Props> = ({ color, style, children }) => {
  return (
    <Container style={{ ...style }}>
      <ColorBar color={color} />
      <Content color={color}>{children}</Content>
    </Container>
  )
}

export default YogiInfo

const Container = styled.div<{ color?: string }>`
  display: flex;
  justify-content: flex-start;
  overflow: hidden;
  flex-shrink: 0;
  border-radius: 10px;

  color: ${(props) => (props.color ? props.color : '#28364F')};
`

const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
`

const Content = styled.div<{ color?: string }>`
  padding: 5px 10px;
  color: ${({ color }) => (color ? color : mainText)};
`
