import React, { useState, useEffect, useRef } from 'react'
import { Rnd } from 'react-rnd'
import styled from 'styled-components'
import { Checkbox, InputNumber, Select } from 'antd'
import { YogiSelect } from '../../../../../components/UI/YogiSelect'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import YogiDivider from '../../../../../components/UI/YogiDivider'
import { Divider } from '@mui/material'
import {
  ChartTypes,
  highlightHoveredData,
  isValuableChart,
  onCopyChart,
} from '../utils'
import { NumericalChart } from '../features'
import type {
  DataForChart,
  NumericalChart as NumericalChartType,
  TableChart as TableChartType,
  UnionChart,
} from '../types'
import { NewTableChart } from '../features/new-table-chart'
import Split from 'react-split'
import { ChartLegend } from './chart-legend'
import {
  CanvasWrapper,
  ChartWrapper,
  EmptyWrapper,
  LegendContainer,
  LegendWrapper,
  TextCenter,
} from './Chart/chart'
import useResizeObserver from 'use-resize-observer'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../types'
import { AppliedFilterOptionRange } from '../../filters/types'
import ChartJS from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartAnnotations from 'chartjs-plugin-annotation'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'
import useChartUpdate from '../../../hooks/useChartUpdate'
import useChartConfig from '../../../hooks/useChartConfig'
ChartJS.register(
  ChartDataLabels,
  ChartAnnotations,
  MatrixController,
  MatrixElement,
)
ChartJS.defaults.font.family = 'Poppins, sans-serif'

interface ResizableContainerProps {
  dashboardControls: any[]
  comparativeIndex: number
  updateDashboardControls: (field: string, value: any) => void
  currentChart: UnionChart
  filters: Filters
  prevRange: AppliedFilterOptionRange | undefined
  setPrevRange: (hasPrev: AppliedFilterOptionRange) => void
  filteredExpandedCategories: string[]
  legendRef: React.RefObject<HTMLDivElement>
  currentConfiguredChart: DataForChart | null | undefined
  highlightedLegendItems: string[]
  setHighlightedLegendItems: (items: string[]) => void
  disabledCategories: string[]
  setDisabledCategories: (items: string[]) => void
}

const presets = [
  { label: 'Full Slide', width: 12.16, height: 4.93 },
  { label: 'Half Slide', width: 5.95, height: 4.93 },
  { label: 'Quarter Slide', width: 5.95, height: 2.3 },
]

const presetObj: any = {
  'Full Slide': { width: 12.16, height: 4.93 },
  'Half Slide': { width: 5.95, height: 4.93 },
  'Quarter Slide': { width: 5.95, height: 2.3 },
}

const exCharts = ['numerical_charts', 'table_charts']
const ResizableChartContainer: React.FC<ResizableContainerProps> = ({
  comparativeIndex,
  dashboardControls,
  updateDashboardControls,
  currentChart,
  filters,
  prevRange,
  setPrevRange,
  filteredExpandedCategories,
  highlightedLegendItems,
  setHighlightedLegendItems,
  disabledCategories,
  setDisabledCategories,
}) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const orderedTableData = useProjectStore(
    (state: ProjectState) => state.orderedTableData,
  )
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)
  const { ref: legendRef, width: legendWidth } =
    useResizeObserver<HTMLDivElement>()
  const { ref: splitRef, width: splitWidth } =
    useResizeObserver<HTMLDivElement>()

  const [chartAPI, setChartAPI] = useState<ChartJS | null>(null)
  // State for the aspect ratio, with a default value
  const [aspectRatio, setAspectRatio] = useState(12.16 / 4.93)
  // State for the dimensions of the container
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 })

  const [customAspectWidth, setCustomAspectWidth] = useState<number>(12.16)
  const [customAspectHeight, setCustomAspectHeight] = useState<number>(4.93)
  const [selectedPreset, setSelectedPreset] = useState<string>('Full Slide')

  const [lockAspectRatio, setLockAspectRatio] = useState<boolean>(true)

  const [timeoutId, setTimeoutId] = useState<any>(null)

  const {
    legend: displayLegend,
    fontSize,
    treeView,
    sortedByCategory,
    tier1,
    isAlignOrder,
    isAlignScales,
    reviewsCount,
    timeInterval,
    postType,
    showPercentChange,
    matrixFlipAxis,
    order: rootOrder,
    hideParentCategories,
  } = dashboardControls[0]

  const {
    tableSort,
    order,
    isDelta,
    pageSize,
    expandedCategories,
    columnsVisibility,
    tableState,
    isFullScreen,
    comparePeriod,
    showCategories,
  } = dashboardControls[comparativeIndex] ?? {}

  const isValuableChartEmpty =
    currentChart &&
    isValuableChart(currentChart) &&
    currentChart.values?.length === 0

  const isCanvasChart =
    currentChart.chart_type &&
    !isValuableChartEmpty &&
    !exCharts.includes(currentChart.chart_type)

  const disableGrouping =
    (tier1 === 'Brand' && details?.disable_brand_grouping) ||
    (tier1 === 'Product' && details?.disable_brand_grouping)

  // Calculate initial dimensions based on the aspect ratio and the window size
  const calculateDimensions = () => {
    // TODO check if height > width and reverse this calc if so
    let height = window.innerHeight - 60 // Subtract 40px for padding
    let width = height * aspectRatio
    if (width > window.innerWidth) {
      width = window.innerWidth
      height = width / aspectRatio
    }
    return { width, height }
  }

  // Handle window resizing and initial setup
  useEffect(() => {
    const handleResize = () => {
      setDimensions(calculateDimensions())
    }

    handleResize() // Set initial size based on current window size
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [aspectRatio]) // Effect dependencies

  // Function to update dimensions upon resizing the Rnd container
  const handleResize = (e: MouseEvent, dir: string, ref: HTMLElement) => {
    setDimensions({
      width: parseInt(ref.style.width, 10),
      height: parseInt(ref.style.height, 10),
    })
  }

  // Handle aspect ratio changes
  const handleAspectRatioChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const newAspectRatio = parseFloat(event.target.value)
    if (!isNaN(newAspectRatio) && newAspectRatio > 0) {
      setAspectRatio(newAspectRatio)
    }
  }

  const { currentConfiguredChart } = useChartConfig({
    currentChart,
    chartRef,
    dashboardControls,
    comparativeIndex,
    isComparative,
    comparativePanelsNumber,
    filters,
    updateDashboardControls,
    filteredExpandedCategories,
    disabledCategories,
  })

  const { updatedChart } = useChartUpdate({
    // legend,
    isFullScreen,
    currentConfiguredChart,
    chartRef,
    treeView,
  })

  function debounceChartUpdate(chart: ChartJS, delay = 100) {
    if (timeoutId !== null) {
      clearTimeout(timeoutId)
    }

    let timeout = setTimeout(() => {
      chart.update('none')
    }, delay)
    setTimeoutId(timeout)
  }

  const [prevChartType, setPrevChartType] = useState<string | undefined>('')

  useEffect(() => {
    if (currentConfiguredChart && updatedChart && chartRef.current) {
      const { type, legend: legendObject, plugins } = currentConfiguredChart

      const { data: dataObject, options } = updatedChart

      const newPlugins = plugins

      if (prevChartType !== type) {
        setPrevChartType(type)
        if (chartAPI) {
          chartAPI.destroy()
        }
        const chart = new ChartJS(chartRef.current, {
          type,
          options,
          data: dataObject,
          plugins: newPlugins as any,
        })
        setChartAPI(chart)
        // setLegend(legendObject)
      } else {
        if (chartAPI) {
          chartAPI.data = dataObject
          chartAPI.options = options
          debounceChartUpdate(chartAPI)
          // // chartAPI.destroy()
        } else {
          const chart = new ChartJS(chartRef.current, {
            type,
            options,
            data: dataObject,
            plugins: newPlugins as any,
          })
          setChartAPI(chart)
        }
      }
    }
  }, [updatedChart])

  useEffect(() => {
    if (currentChart?.chart_type === ChartTypes.BUBBLE && chartAPI) {
      debounceChartUpdate(chartAPI)
    }
  }, [chartAPI])

  useEffect(() => {
    highlightHoveredData(
      chartAPI,
      currentChart,
      highlightedLegendItems,
      treeView,
    )
  }, [highlightedLegendItems.length])

  return (
    <Container>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column-reverse',
          height: '100%',
        }}
      >
        <AspectRatio>
          {presets.map((preset) => (
            <YogiButton
              type={selectedPreset === preset.label ? 'primary' : 'default'}
              onClick={() => {
                setLockAspectRatio(true)
                setSelectedPreset(preset.label)
                setCustomAspectWidth(preset.width)
                setCustomAspectHeight(preset.height)
                setAspectRatio(preset.width / preset.height)
                setDimensions(calculateDimensions())
              }}
            >
              {preset.label}
            </YogiButton>
          ))}
          <Divider
            // variant={'middle'}
            orientation={'vertical'}
            style={{ margin: '0px 10px' }}
          />
          <label htmlFor="aspectRatio">Aspect Ratio:</label>
          <InputNumber
            // type="number"
            id="aspectRatioW"
            value={customAspectWidth}
            onChange={(e) => {
              if (e) {
                setCustomAspectWidth(e)
                if (lockAspectRatio) {
                  setCustomAspectHeight(e / aspectRatio)
                }
              }
            }}
          />
          x
          <InputNumber
            // type="number"
            id="aspectRatioHeight"
            value={customAspectHeight}
            onChange={(e) => {
              if (e) {
                setCustomAspectHeight(e)
                if (lockAspectRatio) {
                  setCustomAspectWidth(e * aspectRatio)
                }
              }
            }}
          />
          <button
            onClick={() => {
              setAspectRatio(customAspectWidth / customAspectHeight)
              setDimensions(calculateDimensions())
            }}
          >
            Apply
          </button>
          <Divider
            // variant={'middle'}
            orientation={'vertical'}
            style={{ margin: '0px 10px' }}
          />
          <label htmlFor="lockAspectRatio">Locked:</label>
          <Checkbox
            // type="number"
            id="lockAspectRatio"
            checked={lockAspectRatio}
            onChange={(e) => {
              if (e) {
                setLockAspectRatio(e.target.checked)
              }
            }}
          />
          <Divider
            // variant={'middle'}
            orientation={'vertical'}
            style={{ margin: '0px 10px' }}
          />
          <div>
            Current Dimensions: {dimensions.width.toFixed(2)} x{' '}
            {dimensions.height.toFixed(2)}
          </div>
          <Divider
            // variant={'middle'}
            orientation={'vertical'}
            style={{ margin: '0px 10px' }}
          />
          <YogiButton
            onClick={() => onCopyChart(chartWrapperRef, chartWrapperRef)}
          >
            Copy to Clipboard
          </YogiButton>
          <YogiButton
            onClick={() =>
              onCopyChart(chartWrapperRef, chartWrapperRef, undefined, true)
            }
          >
            Download
          </YogiButton>
          <Divider
            // variant={'middle'}
            orientation={'vertical'}
            style={{ margin: '0px 10px' }}
          />
          <YogiButton
            onClick={() => {
              updateDashboardControls('isFullScreen', false)
            }}
          >
            Close
          </YogiButton>
        </AspectRatio>
        <Rnd
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            border: '2px solid #ddd',
            marginTop: 40,
          }}
          maxHeight={window.innerHeight - 60}
          maxWidth={window.innerWidth}
          size={{
            width: dimensions.width + 'px',
            height: dimensions.height + 'px',
          }}
          position={{ x: 0, y: 0 }}
          // @ts-ignore
          onResize={handleResize}
          enableResizing={{
            bottomRight: true,
          }}
          minWidth="300px"
          minHeight="200px"
          lockAspectRatio={lockAspectRatio}
          bounds="window"
          disableDragging={true}
        >
          {currentChart && !isValuableChartEmpty ? (
            <ChartWrapper
              ref={chartWrapperRef}
              style={{
                // todo YEAH IK
                maxHeight: '100%',
                height: '100%',
                width: '100%',
                borderRadius: '0',
              }}
              canvasChart={!!isCanvasChart}
            >
              <span
                style={{
                  position: 'absolute',
                  height: 0,
                  width: 'calc(100% - 150px)',
                }}
                ref={splitRef}
              ></span>
              {currentChart.chart_type === ChartTypes.NUMERICAL ||
              currentChart.chart_type === ChartTypes.TABLE ? (
                <>
                  {currentChart.chart_type === ChartTypes.NUMERICAL && (
                    <NumericalChart data={currentChart as NumericalChartType} />
                  )}
                  {currentChart.chart_type === ChartTypes.TABLE &&
                    !isValuableChartEmpty && (
                      <NewTableChart
                        key={hideParentCategories ? 'hideParentCategories' : ''}
                        currentChart={currentChart as TableChartType}
                        orderedTableData={orderedTableData}
                        isAlignOrder={isAlignOrder}
                        comparativeIndex={comparativeIndex}
                        // rowContextCb={(e, row) => {
                        //   clickHandlerTableChartAdapter(e, row)
                        // }}
                        filterValues={filters}
                        tier1={tier1}
                        timeInterval={timeInterval}
                        postType={postType}
                        isDelta={isDelta}
                        showPercentChange={showPercentChange}
                        pageSize={pageSize}
                        tableSort={tableSort}
                        prevRange={prevRange}
                        setPrevRange={setPrevRange}
                        expandedCategories={filteredExpandedCategories}
                        columnsVisibility={columnsVisibility}
                        updateDashboardControls={updateDashboardControls}
                        tableState={tableState}
                        hideParentCategories={hideParentCategories}
                        showCategories={showCategories}
                      />
                    )}
                </>
              ) : (
                // Also for some reason this split init doesn't do what it's supposed to do
                <Split
                  sizes={[100, 0]}
                  // TODO leftoff - might be time to bring the loading into here instead of above
                  gutterSize={displayLegend ? 7 : 0}
                  direction="horizontal"
                  className="split"
                >
                  {currentChart?.chart_type &&
                  !isValuableChartEmpty &&
                  !exCharts.includes(currentChart.chart_type) ? (
                    <>
                      <CanvasWrapper>
                        <canvas
                          ref={chartRef}
                          style={{
                            width: '100%',
                            height: '100%',
                          }}
                        />
                      </CanvasWrapper>
                      <div
                        style={{
                          display:
                            currentConfiguredChart?.legend &&
                            displayLegend &&
                            !isValuableChartEmpty
                              ? 'block'
                              : 'none',
                          minWidth: '175px',
                        }}
                      >
                        <LegendContainer>
                          {currentConfiguredChart?.legend &&
                            displayLegend &&
                            !isValuableChartEmpty && (
                              <LegendWrapper>
                                <ChartLegend
                                  legend={currentConfiguredChart?.legend}
                                  // change={(items) => setLegend(items)}
                                  setHighlightedLegendItems={
                                    setHighlightedLegendItems
                                  }
                                  disabledLegendItems={disabledCategories}
                                  setDisabledLegendItems={setDisabledCategories}
                                  expandedCategories={
                                    filteredExpandedCategories
                                  }
                                  currentChart={currentChart}
                                  updateDashboardControls={
                                    updateDashboardControls
                                  }
                                  tier1={tier1}
                                  showCategories={showCategories}
                                  disableGrouping={disableGrouping}
                                />
                              </LegendWrapper>
                            )}
                        </LegendContainer>
                      </div>
                    </>
                  ) : (
                    <></>
                  )}
                </Split>
              )}
            </ChartWrapper>
          ) : (
            <ChartWrapper>
              <EmptyWrapper>
                <TextCenter>{currentChart.msg}</TextCenter>
              </EmptyWrapper>
            </ChartWrapper>
          )}
        </Rnd>
      </div>
    </Container>
  )
}

export default ResizableChartContainer

const Container = styled.div`
  position: fixed; // Use 'fixed' to overlay over the entire viewport
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  z-index: 9999; // High z-index to ensure it overlays on top of other content
  background: lightgray;
`

const AspectRatio = styled.div`
  background: white;
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 10px;
  overflow: visible;
`
