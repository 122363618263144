import { useQuery } from 'react-query'
import { fetchFilterOptions } from '../react-query-functions'
import { useProjectStore } from '../projectStore/projectStore'
import { Filters } from '../types'

export const useGlobalFilterList = (
  comparativeIndex: number,
  customFilterValues?: Filters
) => {
  const projectId = useProjectStore((state) => state.projectId)
  const globalFilterValues = useProjectStore(
    (state) => state.filters[comparativeIndex]
  )
  const openedOptions = useProjectStore(
    (state) => state.openedOptions[comparativeIndex]
  )
  const selectedOptionField = useProjectStore(
    (state) => state.selectedOptionField
  )
  const setFilterListByIndex = useProjectStore(
    (state) => state.setFilterListByIndex
  )

  const filterValues = customFilterValues ?? globalFilterValues

  // TODO request just the one selected option field and cache the prior values
  return useQuery(
    [
      'filter-list-data',
      projectId,
      [selectedOptionField[comparativeIndex]] || openedOptions,
      filterValues,
    ],
    async () => {
      const data = await fetchFilterOptions(
        projectId,
        selectedOptionField[comparativeIndex]
          ? [selectedOptionField[comparativeIndex]!]
          : openedOptions,
        filterValues
      )
      if (data) {
        setFilterListByIndex(comparativeIndex, data)
      }
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )
}
