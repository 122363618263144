import React, { useEffect, useState } from 'react'
import { Button, Tooltip, Popover, Checkbox } from 'antd'
import {
  CheckOutlined,
  CopyOutlined,
  DownloadOutlined,
  UndoOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import styled from 'styled-components'
import { useClickOutside } from 'shared/hooks'
import {
  HardcodedFilterOptionsEnum,
  updateFilterList,
} from '../../../../filters/helpers'
import useResizeObserver from 'use-resize-observer'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ExportData, Filters, ProjectState } from '../../../../../types'
import { cardBorderGrey, secondaryGreen } from 'assets/styles/variables'
import { TuneOutlined } from '@mui/icons-material'
import { FilterOption } from '../../../../filters/types'
import DashboardDateSelect from './DashboardDateSelect'
import { FilterButton } from 'features/project/features/filters/FilterButton/FilterButton'
import { useLocalFilterList } from '../../../../../hooks/useLocalFilterList'
import _ from 'lodash'
import { onCopyChart } from 'features/project/features/Charts/utils'
import { SearchButton } from 'features/project/features/filters/SearchButton/SearchButton'
import { SaveAndExportButton } from 'components/SaveAndExportButton/SaveAndExportButton'
import { CopyChartButton } from 'components/CopyChartButton/CopyChartButton'

type Props = {
  reset?: any
  settings?: any
  chartType?: string
  filterValues: Filters
  setFilters: (filters: Filters) => void
  comparativeIndex: number
  isEdit?: boolean
  disabled?: boolean
  isCurrent: boolean
  setIsCurrent?: (isCurrent: boolean) => void
  timeResolution: string
  setTimeResolution?: (timeResolution: string) => void
  trailingDaysCount: number | undefined
  setTrailingDaysCount?: (timeResolution: number | undefined) => void
  copyRef?: any
  dashboardId?: number
  dashboardName?: string
  resetDashboard?: () => void
  showResetDashboard: boolean
  exportDashboard?: () => void
}

export const DashboardFilterHeader = ({
  reset,
  settings,
  filterValues,
  setFilters,
  comparativeIndex,
  isEdit,
  disabled,
  isCurrent,
  setIsCurrent,
  timeResolution,
  setTimeResolution,
  trailingDaysCount,
  setTrailingDaysCount,
  copyRef,
  dashboardId,
  dashboardName,
  showResetDashboard,
  resetDashboard,
  exportDashboard,
}: Props) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const dateWindows = useProjectStore((state) => state.dateWindows)
  const setExportData = useProjectStore(
    (state: ProjectState) => state.setExportData,
  )
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )
  const setIsExporting = useProjectStore(
    (state: ProjectState) => state.setIsExporting,
  )

  const panelRef = React.createRef<HTMLDivElement>()
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const { ref, width = 0 } = useResizeObserver()
  const isSmall = !!width && width < 600

  const [localOpenedOptions, setLocalOpenedOptions] = useState<string[]>([])
  const [filterList, setFilterList] = useState<FilterOption[]>([])
  const [selectedOption, setSelectedOption] = useState<string>('')

  const [renderHackIndex, setRenderHackIndex] = useState<number>(0)
  useEffect(() => {
    setRenderHackIndex((prevState) => prevState + 1)
  }, [isEdit])

  const { data: filterListData } = useLocalFilterList(
    filterValues,
    selectedOption,
    localOpenedOptions,
    filterList,
    setFilterList,
  )

  useEffect(() => {
    setFilterList(filterListData ?? [])
    setLocalOpenedOptions([])
    setSelectedOption('')
  }, [filterValues])

  useEffect(() => {
    if (filterListData) {
      // this caches the filter on a per-field basis
      setFilterList((prevState) =>
        updateFilterList(prevState, localOpenedOptions, filterListData),
      )
    }
  }, [filterListData])

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button')
    )
      return
    setFilterPanelVisible(false)
  })

  useEffect(() => {
    // find the latest completed entry for that time resolution
    // setFilters with that datefilter applied
    if (!isCurrent || !timeResolution || !dateWindows?.[timeResolution]) return
    // Select the most recent "Completed" time resolution period
    let dateFilter = JSON.parse(dateWindows[timeResolution][1].value)
    if (timeResolution === 'YTD') {
      // in the case of YTD, the current period is desired
      dateFilter = JSON.parse(dateWindows[timeResolution][0].value)
    }
    if (timeResolution === 'Trailing') {
      const dateWindow = dateWindows[timeResolution].find(
        (el) => el.daysCount === (trailingDaysCount ?? 30),
      )
      dateFilter = dateWindow ? JSON.parse(dateWindow.value) : dateFilter
    }
    const filterCopy = _.cloneDeep(filterValues)
    filterCopy.values = filterCopy.values.filter(
      ({ field }) => field !== HardcodedFilterOptionsEnum.CREATE_TIME,
    )
    filterCopy.values.push({
      field: HardcodedFilterOptionsEnum.CREATE_TIME,
      min: dateFilter.min,
      max: dateFilter.max,
      value_type: 0, // TODO??
    })
    setFilters(filterCopy)
  }, [isCurrent, timeResolution, trailingDaysCount, dateWindows])

  const [isCopying, setIsCopying] = useState(false)
  const copyFn = () => {
    setIsCopying(true)
    mixpanel.track('dashboard download', {
      action: 'copy',
      ...route,
      value: dashboardId,
    })
    setTimeout(() => {
      setIsCopying(false)
    }, 5000)

    onCopyChart(copyRef, copyRef, dashboardName, false).then(() => {
      // setIsCopying(false)
    })
  }

  const exportFn = () => {
    mixpanel.track('dashboard download', {
      action: 'image',
      ...route,
      value: dashboardId,
    })

    const exportData: ExportData = {
      comparativeIndex: comparativeIndex,
      filterValues: filterValues,
      downloadImage: () => {
        setIsExporting(true)
        onCopyChart(copyRef, copyRef, dashboardName, true, 'Dashboard').then(
          () => {
            setIsExporting(false)
          },
        )
      },
      exportPPT: () => {
        exportDashboard && exportDashboard()
      },
      isDashboard: true,
    }
    setExportData(exportData)

    setIsExportDrawerOpen(true)
  }

  return (
    <OuterContainer className={'filter-header'}>
      <ResizeContainer ref={ref}>
        <Container style={{ width: `${width}px` }}>
          <Wrapper>
            <Left style={{ gap: isSmall ? 10 : 15 }}>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <FilterButton
                  filterValues={filterValues}
                  isFilterPanelVisible={isFilterPanelVisible}
                  setFilterPanelVisible={setFilterPanelVisible}
                  filterOptionsLoading={false}
                  comparativeIndex={0}
                  updateLocalFilterValues={setFilters}
                  localOpenedOptions={localOpenedOptions}
                  setLocalOpenedOptions={setLocalOpenedOptions}
                  localSelectedOption={selectedOption}
                  setLocalSelectedOption={setSelectedOption}
                  isDashboardEdit={isEdit}
                />
              </span>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <SearchButton
                  filterValues={filterValues}
                  comparativeIndex={0}
                  updateLocalFilterValues={setFilters}
                  isDashboardEdit
                />
              </span>
              {showResetDashboard && (
                <Tooltip title={'Reset the dashboard to its saved state'}>
                  <Button
                    onClick={() => {
                      mixpanel.track('custom dashboard', {
                        action: 'reset',
                        value: dashboardId,
                        proj_uuid: projectId,
                      })
                      resetDashboard && resetDashboard()
                    }}
                    icon={<UndoOutlined />}
                    style={{ height: 36, borderRadius: 10 }}
                  >
                    Reset
                  </Button>
                </Tooltip>
              )}
            </Left>
            <Right style={{ gap: isSmall ? 5 : 10 }}>
              {reset}
              {settings && (
                <Popover
                  content={settings}
                  title={<div style={{ padding: '5px' }}>Controls</div>}
                  trigger="click"
                  placement="bottom"
                >
                  <Tooltip placement="top" title="Controls">
                    <Button
                      type="text"
                      style={{
                        border: `2px solid ${cardBorderGrey}`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        display: 'flex',
                        gap: isSmall ? '0px' : '5px',
                      }}
                    >
                      <TuneOutlined fontSize={'small'} />{' '}
                      {isSmall ? '' : <span>Controls</span>}
                    </Button>
                  </Tooltip>
                </Popover>
              )}
              {/*todo incredibly stupid render hack - fixes bugginess when switching from view to edit*/}
              {isEdit && (
                <div
                  onClick={() => setIsCurrent && setIsCurrent(!isCurrent)}
                  style={{
                    whiteSpace: 'nowrap',
                    cursor: 'pointer',
                    fontSize: 10,
                  }}
                >
                  <Checkbox
                    checked={isCurrent}
                    style={{ marginRight: 5, fontSize: 10 }}
                    disabled={!defaultFilterList?.length || !dateWindows}
                  />
                  Most Recent
                </div>
              )}
              <DateSelectorWrapper className="date-selector">
                <DashboardDateSelect
                  key={renderHackIndex}
                  filterValues={filterValues}
                  updateLocalFilterValues={setFilters}
                  isDashboard
                  disabled={disabled}
                  isCurrent={isEdit ? isCurrent : false}
                  setTimeResolution={setTimeResolution}
                  setTrailingDaysCount={setTrailingDaysCount}
                  isEdit
                />
              </DateSelectorWrapper>
              {!isEdit && (
                <>
                  {copyRef && (
                    <Tooltip title={'Copy Dashboard as PNG to Clipboard'}>
                      {isCopying ? (
                        <CheckOutlined
                          style={{
                            color: secondaryGreen,
                            boxShadow: 'none',
                            marginRight: '10px',
                            background: '#fff',
                          }}
                        />
                      ) : (
                        <CopyChartButton copyFn={copyFn} />
                      )}
                    </Tooltip>
                  )}
                  <SaveAndExportButton exportFn={exportFn} />
                </>
              )}
            </Right>
          </Wrapper>
        </Container>
      </ResizeContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
  z-index: 101;
  padding: 7px 10px 0;
`

const ResizeContainer = styled.div`
  width: 100%;
  position: relative;
`

const Container = styled.div`
  z-index: 10;
  min-height: var(--filter-height);
  height: var(--filter-height);
  display: flex;
  align-items: center;
  padding: 0;
  width: calc(100% - 30px);
`

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  .ant-badge-count {
    top: 4px;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  height: 100%;

  .ant-badge-count {
    right: 7px;
    top: 3px;
  }
`

const DateSelectorWrapper = styled.div`
  margin-left: 4px;
`
