import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { darkBlue, primaryOrange } from 'assets/styles/variables'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { FilterOptionSelect } from 'features/project/features/filters/types'
import { saveToLS } from 'features/project/utils'
import { useQuery } from 'react-query'
import { fetchProjects } from 'features/project/model'

type Props = {}

export const HubSelector: React.FC<Props> = ({}) => {
  // global state
  const details = useProjectStore((state: ProjectState) => state.details)

  const { data: projectsData } = useQuery(
    ['project-search'],
    () => fetchProjects(),
    { refetchOnWindowFocus: false },
  )

  const projects = projectsData?.data

  // constants
  const options =
    projects?.map((el) => ({ value: el.uuid, label: el.title })) || []

  const selectedProject = {
    proj_uuid: details.proj_uuid,
    title: details.title_override ?? details.title,
  }

  if (!options?.length) {
    return <Container />
  }

  return (
    <Container>
      <Left>Hub</Left>
      <Right>
        <StyledSelect
          showSearch
          optionFilterProp={'label'}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={options}
          // defaultValue={defaultEmpty ? undefined : options[0]?.value}
          value={selectedProject.proj_uuid}
          onChange={(value) => {
            window.location.pathname = '/project/' + value
            // setDataset(value as string)
            // updateFilterValues(comparativeIndex || 0, 'proj_uuid', [value])
            // saveToLS(`defaultDataset-${projectId}`, value)
          }}
          placeholder={'Select a dataset'}
        ></StyledSelect>
      </Right>
    </Container>
  )
}

const Container = styled.div`
  width: 300px;
  display: flex;
  box-shadow: 0 4px 4px 0 #0000001a;
  border-radius: 20px;
`

const Left = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
  color: white;

  background: ${darkBlue};
  padding: 5px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`

const Right = styled.div`
  width: 100%;
  background: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
`

const StyledSelect = styled(Select)`
  .ant-select {
    background: white !important;
  }
  width: 100%;
  .ant-select-selector {
    background: transparent !important;
    border: none !important;
    color: ${darkBlue};
    font-size: 12px;
    height: 100%;
  }
  .ant-select-selection-search-input {
    font-weight: 600;
    margin-left: 10px !important;
  }
  .ant-select-selection-item {
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
  }
  .ant-select-arrow {
    color: ${darkBlue} !important;
    font-weight: 600;
  }
`
