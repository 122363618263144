import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import useResizeObserver from 'use-resize-observer'
import Split from 'react-split'
import { Empty } from 'antd'
import { AlertOutlined, CloseOutlined } from '@ant-design/icons'
import ChartJS from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import ChartAnnotations from 'chartjs-plugin-annotation'
import { MatrixController, MatrixElement } from 'chartjs-chart-matrix'
import { ChartLegend } from 'features/project/features/Charts/components/chart-legend'
import { NumericalChart } from 'features/project/features/Charts/features'
import {
  alignOrder,
  alignScales,
  ChartTypes,
  filterExpandedCategories,
  fontSizeDict,
  highlightHoveredData,
  isValuableChart,
} from '../../utils'
import type {
  NumericalChart as NumericalChartType,
  TableChart as TableChartType,
  UnionChart,
} from '../../types'
import { AppliedFilterOptionRange } from '../../../filters/types'
import { Filters, ProjectState } from 'features/project/types'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../../projectStore/projectStore'
import {
  darkBlue,
  darkBlueHover,
  legendBackground,
  lightText,
  secondaryRed,
} from 'assets/styles/variables'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import ChartTools from '../ChartTools'
import { NewTableChart } from '../../features/new-table-chart'
import ResizableChartContainer from '../ResizableChartContainer'
import useChartConfig from '../../../../hooks/useChartConfig'
import useChartUpdate from '../../../../hooks/useChartUpdate'
import { useChartData } from '../../../../hooks/useChartData'
import ChartTitle from '../ChartTitle'
import useChart from '../../../../hooks/useChart'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import SummarizeSvgIcon from 'assets/2024/Icons/SummarizeSvgIcon/SummarizeSvgIcon'
import { ErrorBoundaryContent } from 'components/ErrorBoundary/ErrorBoundaryContent/ErrorBoundaryContent'
import { useClickOutside } from 'shared/hooks'
import { DEFAULT_CHART_FONT_SIZE } from 'features/project/utils'

// Register Chart.js plugins
ChartJS.register(
  ChartDataLabels,
  ChartAnnotations,
  MatrixController,
  MatrixElement,
)
ChartJS.defaults.font.family = 'Poppins, sans-serif'

// Type definitions
type Props = {
  filters: Filters
  chart: UnionChart
  comparativeIndex: number
  isComparative: boolean
  size?: string
  isLink?: boolean
  insightChartList?: any
  headerContent?: any
  localDashboardControls?: any
  setLocalDashboardControls?: (localDashboardControls: any) => void
  updateLocalDashboardControls?: (key: string, value: any) => void
  localPaginationMin?: number
  legendRef: any
  splitRef: any
  initialLegendWidthPercent: number
  isEditing?: boolean
  hideHeader?: boolean
  priorityItem?: string
  headerHeight?: number
}

const exCharts = ['numerical_charts', 'table_charts']
export const Chart: React.FC<Props> = ({
  filters,
  chart,
  comparativeIndex,
  isComparative,
  size,
  isLink,
  insightChartList,
  headerContent,
  localDashboardControls,
  updateLocalDashboardControls,
  initialLegendWidthPercent,
  splitRef,
  legendRef,
  isEditing,
  hideHeader,
  priorityItem,
  headerHeight,
}) => {
  // State and Refs
  const [currentChart, setCurrentChart] = useState(chart)
  const [chartTitle, setChartTitle] = useState<string | undefined>(undefined)
  const [highlightedLegendItems, setHighlightedLegendItems] = useState<any>([])
  const [disabledCategories, setDisabledCategories] = useState<string[]>([])
  const [legendWidthPercent, setLegendWidthPercent] = useState<number>(0)
  const [prevRange, setPrevRange] = useState<
    AppliedFilterOptionRange | undefined
  >(undefined)
  const [prevChartType, setPrevChartType] = useState<string | undefined>('')

  const wrapperRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLCanvasElement | null>(null)
  const chartInstanceRef = useRef<ChartJS | null>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)
  const contextMenuRef = useRef<HTMLDivElement>(null)
  const clickedChart = useRef<any>()

  // Hooks
  const { ref: resizeRef, height: chartHeaderHeight } =
    useResizeObserver<HTMLDivElement>()
  const { ref: dashboardHeader, height: dashboardHeaderHeight } =
    useResizeObserver<HTMLDivElement>()
  const { data: feature_flags } = useFeatureFlags()
  const { clickHandler } = useChart()
  useClickOutside(contextMenuRef, () => {
    const menu = contextMenuRef.current
    if (menu && menu.style.display !== 'none') {
      clickedChart.current = []
      menu.style.display = 'none'
    }
  })

  // Project store values
  const details = useProjectStore((state: ProjectState) => state.details)
  const globalDashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls,
  )
  const orderedTableData = useProjectStore(
    (state: ProjectState) => state.orderedTableData,
  )
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const currentChartList = useProjectStore(
    (state: ProjectState) => state.currentChartList,
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen,
  )
  const setSummaryRequest = useProjectStore(
    (state: ProjectState) => state.setSummaryRequest,
  )
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen,
  )
  const setAlertRequest = useProjectStore(
    (state: ProjectState) => state.setAlertRequest,
  )
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls,
  )

  const dashboardControls = localDashboardControls
    ? [localDashboardControls]
    : globalDashboardControls
  const {
    legend: displayLegend,
    fontSize,
    treeView,
    sortedByCategory,
    tier1,
    isAlignOrder,
    isAlignScales,
    reviewsCount,
    timeInterval,
    postType,
    showPercentChange,
    matrixFlipAxis,
    order: rootOrder,
    hideParentCategories,
  } = dashboardControls[0]

  const {
    tableSort,
    order,
    isDelta,
    pageSize,
    expandedCategories,
    columnsVisibility,
    tableState,
    isFullScreen,
    comparePeriod,
    showCategories,
  } = dashboardControls[comparativeIndex] ?? {}

  const expandedCategoriesList = useMemo(
    () => dashboardControls.map((el) => el.expandedCategories),
    [dashboardControls],
  )

  const filteredExpandedCategories: string[] = useMemo(() => {
    return filterExpandedCategories(currentChart, expandedCategories)
  }, [expandedCategories, currentChart])

  const isAlignOrderTypeChart =
    chart.chart_type === ChartTypes.TABLE ||
    chart.chart_type === ChartTypes.BAR ||
    chart.chart_type === ChartTypes.STACK_BAR
  // ||
  // chart.chart_type === ChartTypes.MATRIX

  const isValuableChartEmpty =
    currentChart &&
    isValuableChart(currentChart) &&
    currentChart.values?.length === 0

  const isCanvasChart =
    currentChart.chart_type &&
    !isValuableChartEmpty &&
    !exCharts.includes(currentChart.chart_type)

  const disableGrouping =
    (tier1 === 'Brand' && details?.disable_brand_grouping) ||
    (tier1 === 'Product' && details?.disable_brand_grouping)

  const updateDashboardControlsGeneral = useCallback(
    (key: string, value: any) => {
      if (updateLocalDashboardControls) {
        updateLocalDashboardControls(key, value)
      } else {
        updateDashboardControls(comparativeIndex, key, value)
      }
    },
    [updateLocalDashboardControls, updateDashboardControls, comparativeIndex],
  )

  // Custom hooks
  const {
    currentConfiguredChart,
    currentPage,
    toggleOrder,
    onPageChange,
    isPreviousChartLoading,
  } = useChartConfig({
    currentChart,
    chartRef,
    dashboardControls,
    comparativeIndex,
    isComparative,
    comparativePanelsNumber,
    filters,
    updateDashboardControls: updateDashboardControlsGeneral,
    filteredExpandedCategories,
    disabledCategories,
    disableGrouping,
    priorityItem,
  })

  const { updatedChart } = useChartUpdate({
    isFullScreen,
    currentConfiguredChart,
    chartRef,
    treeView,
  })

  const { data: chartItem, isError } = useChartData(
    comparativeIndex,
    filters,
    tier1,
    currentChart.chart_id,
    postType,
    timeInterval,
    reviewsCount,
    false,
  )

  // Effects
  useEffect(() => {
    setLegendWidthPercent(initialLegendWidthPercent ?? 0)
  }, [initialLegendWidthPercent])

  useEffect(() => {
    updateDashboardControlsGeneral('expandedCategories', expandedCategories)
  }, [expandedCategories])

  useEffect(() => {
    if (
      comparativePanelsNumber > 1 &&
      chart.chart_type !== ChartTypes.TABLE &&
      chart.chart_type !== ChartTypes.PIE &&
      chart.chart_type !== ChartTypes.NUMERICAL &&
      chart.chart_type !== ChartTypes.MATRIX
    ) {
      if (isAlignOrder && isAlignScales && comparativeIndex !== undefined) {
        setCurrentChart(
          alignScales(
            isAlignOrderTypeChart
              ? alignOrder(
                  chart,
                  insightChartList ?? currentChartList,
                  comparativeIndex,
                  filteredExpandedCategories,
                  order,
                  sortedByCategory,
                  showCategories,
                  tier1,
                  !!disableGrouping,
                  dashboardControls,
                )
              : chart,
            currentChartList,
            dashboardControls,
            comparativeIndex,
            !!disableGrouping,
          ),
        )
      } else if (isAlignScales && comparativeIndex !== undefined) {
        setCurrentChart(
          alignScales(
            chart,
            currentChartList,
            dashboardControls,
            comparativeIndex,
            !!disableGrouping,
          ),
        )
      } else if (isAlignOrder && comparativeIndex !== undefined) {
        setCurrentChart(
          isAlignOrderTypeChart
            ? alignOrder(
                chart,
                insightChartList ?? currentChartList,
                comparativeIndex,
                filteredExpandedCategories,
                order,
                sortedByCategory,
                showCategories,
                tier1,
                !!disableGrouping,
                dashboardControls,
              )
            : chart,
        )
      } else {
        setCurrentChart(chart)
      }
    } else {
      setCurrentChart(chart)
    }
  }, [
    isAlignOrder,
    isAlignScales,
    currentChartList,
    chart,
    expandedCategoriesList,
    rootOrder,
  ])

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (!document.hidden && chartInstanceRef.current) {
        chartInstanceRef.current.update()
      }
    }

    document.addEventListener('visibilitychange', handleVisibilityChange)
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])

  useEffect(() => {
    if (currentConfiguredChart && prevChartType) {
      const { type } = currentConfiguredChart
      if (prevChartType === type) {
        // I don't totally understand what the root cause of this is
        // but when adding a panel when viewing a bar chart in align order mode
        // this helps the left side chart render correctly
        // really this whole component's render cycle needs to be rebuilt
        setTimeout(() => {
          updateChart()
        }, 1)
        return
      }
    }
    initializeChart()

    // this seems like the best case, but since we update sometimes, this causes a breaking bug if uncommented
    // return () => {
    //   if (chartInstanceRef.current) {
    //     chartInstanceRef.current.destroy()
    //   }
    // }
  }, [currentConfiguredChart, updatedChart])

  useEffect(() => {
    return () => {
      if (import.meta.hot) {
        // Prevent cleanup during HMR
        import.meta.hot.on('vite:beforeUpdate', () => {
          if (chartInstanceRef.current) {
            chartInstanceRef.current.destroy()
            chartInstanceRef.current = null
          }
        })
      } else if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }
    }
  }, [])

  useEffect(() => {
    highlightHoveredData(
      chartInstanceRef.current,
      currentChart,
      highlightedLegendItems,
      treeView,
    )
  }, [highlightedLegendItems, currentChart, treeView])

  // Callbacks
  const setChartRef = useCallback((node: HTMLCanvasElement | null) => {
    chartRef.current = node
  }, [])

  const handleLeftClick = useCallback(
    (e: MouseEvent) => {
      const menu = contextMenuRef.current
      if (!menu) return

      e.preventDefault()

      if (
        !clickedChart.current ||
        clickedChart.current.length === 0 ||
        currentChart.chart_id === '9_3_26_rating' ||
        currentChart.chart_id === '9_3_27_sentiment' ||
        currentChart.chart_id === '9_3_28_rating' ||
        currentChart.chart_id === '9_3_29_sentiment' ||
        currentChart.chart_id === '9_3_1_themes' ||
        (currentChart.chart_type === ChartTypes.LINE &&
          chartInstanceRef.current?.data?.datasets[
            clickedChart.current[0].datasetIndex
          ]?.label === 'My Brands')
      ) {
        setTimeout(() => {
          menu.style.display = 'none'
        }, 110)
        return
      }

      setTimeout(() => {
        try {
          menu.style.left = e.clientX - 1 + 'px'
          menu.style.top = e.clientY - 1 + 'px'
          menu.style.display = 'block'
        } catch (error) {
          // console.error('Error displaying context menu:', error)
        }
      }, 110)
    },
    [currentChart, contextMenuRef, clickedChart],
  )

  const contextMenuPlugin = useMemo(
    () => ({
      id: 'customContextMenu',
      beforeEvent(chart: ChartJS, args: any) {
        const event = args.event
        if (event.type === 'click') {
          clickedChart.current = chart.getElementsAtEventForMode(
            event,
            'nearest',
            { intersect: true },
            false,
          )
          handleLeftClick(event.native)
        }
      },
    }),
    [handleLeftClick],
  )

  // Helper functions
  const initializeChart = useCallback(() => {
    if (chartRef.current && currentConfiguredChart && updatedChart) {
      const { type, plugins } = currentConfiguredChart
      const { data: dataObject, options } = updatedChart
      setPrevChartType(type)
      const newPlugins = plugins
        ? [...plugins, contextMenuPlugin]
        : [contextMenuPlugin]

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      const ctx = chartRef.current.getContext('2d')
      if (ctx) {
        const newChartInstance = new ChartJS(ctx, {
          type,
          options,
          data: dataObject,
          plugins: newPlugins as any,
        })
        chartInstanceRef.current = newChartInstance
      }
    }
  }, [currentConfiguredChart, updatedChart, contextMenuPlugin])

  const updateChart = useCallback(() => {
    if (
      chartInstanceRef.current &&
      updatedChart &&
      // for reasons beyond my understanding, pie charts do not play well with .update,
      // mostly occurs when togglign between Tree and Pie views
      currentChart.chart_type !== ChartTypes.PIE
    ) {
      const { data: dataObject, options } = updatedChart
      // If the chart type is the same, update the existing chart
      chartInstanceRef.current.data = dataObject
      chartInstanceRef.current.options = options
      chartInstanceRef.current.update('none')
    } else {
      initializeChart()
    }
  }, [initializeChart])

  const clickHandlerTableChartAdapter = useCallback(
    (e: React.MouseEvent, row: any) => {
      if (tier1 === 'Theme' && row.getParentRow()) {
        clickedChart.current = [
          {
            ...row.original,
            category: `${row.getParentRow().original.category}: ${
              row.original.category
            }`,
          },
        ]
      } else {
        clickedChart.current = [row.original]
      }
      handleLeftClick(e.nativeEvent)
    },
    [tier1, handleLeftClick],
  )

  // Render functions
  const renderChart = () => {
    if (isValuableChartEmpty && currentChart?.msg) {
      return (
        <EmptyWrapper>
          <TextCenter>{currentChart.msg}</TextCenter>
        </EmptyWrapper>
      )
    }

    if (currentChart.chart_type === ChartTypes.NUMERICAL) {
      return <NumericalChart data={currentChart as NumericalChartType} />
    }

    if (currentChart.chart_type === ChartTypes.TABLE && !isValuableChartEmpty) {
      return (
        <TableWrapper size={size} chartHeaderHeight={chartHeaderHeight}>
          <NewTableChart
            key={hideParentCategories ? 'hideParentCategories' : ''}
            currentChart={currentChart as TableChartType}
            orderedTableData={orderedTableData}
            isAlignOrder={isAlignOrder}
            comparativeIndex={comparativeIndex}
            rowContextCb={clickHandlerTableChartAdapter}
            filterValues={filters}
            tier1={tier1}
            timeInterval={timeInterval}
            postType={postType}
            isDelta={isDelta}
            showPercentChange={showPercentChange}
            pageSize={pageSize}
            tableSort={tableSort}
            prevRange={prevRange}
            setPrevRange={setPrevRange}
            expandedCategories={filteredExpandedCategories}
            columnsVisibility={columnsVisibility}
            updateDashboardControls={updateDashboardControlsGeneral}
            tableState={tableState}
            hideParentCategories={hideParentCategories}
            disableGrouping={disableGrouping}
            showCategories={showCategories}
          />
        </TableWrapper>
      )
    }

    return (
      <Split
        sizes={[100 - (legendWidthPercent ?? 0), legendWidthPercent ?? 0]}
        gutterSize={displayLegend ? 7 : 0}
        direction="horizontal"
        className="split"
      >
        <CanvasWrapper
          size={size}
          chartHeaderHeight={chartHeaderHeight}
          headerHeight={headerHeight ?? 48}
        >
          <canvas
            ref={setChartRef}
            style={{
              width: '100%',
              height: '100%',
            }}
          />
        </CanvasWrapper>
        <LegendContainer
          ref={legendRef}
          style={{
            display:
              currentConfiguredChart?.legend &&
              displayLegend &&
              !isValuableChartEmpty
                ? 'block'
                : 'none',
            minWidth: comparativePanelsNumber > 2 ? '130px' : '175px',
          }}
        >
          {currentConfiguredChart?.legend &&
            displayLegend &&
            !isValuableChartEmpty && (
              <LegendWrapper>
                <ChartLegend
                  legend={currentConfiguredChart?.legend}
                  setHighlightedLegendItems={setHighlightedLegendItems}
                  disabledLegendItems={disabledCategories}
                  setDisabledLegendItems={setDisabledCategories}
                  expandedCategories={filteredExpandedCategories}
                  currentChart={currentChart}
                  updateDashboardControls={updateDashboardControlsGeneral}
                  tier1={tier1}
                  showCategories={showCategories}
                  disableGrouping={disableGrouping}
                />
              </LegendWrapper>
            )}
        </LegendContainer>
      </Split>
    )
  }

  const chartWrapperStyle = useMemo(() => {
    const headerHeight = route.isDashboard
      ? (dashboardHeaderHeight ?? 80)
      : route.isCharts
        ? (chartHeaderHeight ?? 54) + 22
        : 0

    return {
      maxHeight:
        currentChart.chart_type === ChartTypes.TABLE || size
          ? ''
          : `calc(100% - ${headerHeight}px)`,
      height:
        currentChart.chart_type === ChartTypes.TABLE || size ? 'auto' : '100%',
      width: 'auto',
      borderRadius: '5px',
    }
  }, [
    currentChart.chart_type,
    size,
    route.isDashboard,
    route.isCharts,
    dashboardHeaderHeight,
    chartHeaderHeight,
  ])

  const renderContextMenu = () => (
    <ContextMenu ref={contextMenuRef} style={{ display: 'none' }}>
      <ContextMenuListItem
        className="menu-item"
        onClick={() => {
          const clickObject = clickHandler(
            contextMenuRef,
            clickedChart,
            currentChart,
            chartInstanceRef.current,
            treeView,
            projectId,
            filters,
            tier1,
            !!matrixFlipAxis,
            comparePeriod,
            timeInterval,
          )

          if (clickObject) {
            mixpanel.track('summary control', {
              action: 'chart',
              value: clickObject.body,
              ...route,
            })
            setIsAlertDrawerOpen(false)
            setSummaryRequest(clickObject)
            setSummaryIsOpen(true)
          }
        }}
        style={{
          display: 'flex',
          gap: 5,
        }}
      >
        <div>Summarize</div>
        <GenericSvgIcon SvgComponent={SummarizeSvgIcon} />
      </ContextMenuListItem>
      <ContextMenuListItem
        className="menu-item"
        onClick={() => {
          const clickObject = clickHandler(
            contextMenuRef,
            clickedChart,
            currentChart,
            chartInstanceRef.current,
            treeView,
            projectId,
            filters,
            tier1,
            !!matrixFlipAxis,
            comparePeriod,
            timeInterval,
          )

          if (clickObject) {
            mixpanel.track('alerts', {
              action: 'create',
              type: 'chart',
              value: clickObject.body,
              ...route,
            })

            const parsedClickFilters: Filters = {
              values: clickObject.body.criteria,
              searchQuery: clickObject.body.search_terms,
              searchCondition: clickObject.body.search_criteria,
            }

            parsedClickFilters.values = parsedClickFilters.values.filter(
              (el) => el.field !== 'create_time',
            )

            setSummaryIsOpen(false)
            setAlertRequest(parsedClickFilters)
            setIsAlertDrawerOpen(true)
          }
        }}
        style={{
          display: 'flex',
          gap: 5,
        }}
      >
        <div>Set Alert</div>
        <AlertOutlined style={{ marginTop: -1 }} />
      </ContextMenuListItem>
    </ContextMenu>
  )

  if (isError) {
    return (
      <EmptyContainer size={size}>
        {/*<ChartWrapper style={{ width: '100%', background: 'transparent' }}>*/}
        {/*<EmptyWrapper>*/}
        <ErrorBoundaryContent />
        {/*</EmptyWrapper>*/}
        {/*</ChartWrapper>*/}
      </EmptyContainer>
    )
  }

  // Main render
  if ((!currentChart || isValuableChartEmpty) && !currentChart?.msg) {
    return (
      <EmptyContainer size={size}>
        <ChartWrapper style={{ width: '100%', background: 'transparent' }}>
          <EmptyWrapper>
            <Empty description={'No data to visualize with current filters'} />
            {!!reviewsCount && (
              <ReviewCountWrapper>
                <ReviewCount>
                  <div>
                    Minimum R&R Set to{' '}
                    <span style={{ fontWeight: 500 }}>{reviewsCount}</span>
                  </div>
                  <div
                    style={{
                      cursor: 'pointer',
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      gap: '5px',
                      color: secondaryRed,
                    }}
                    onClick={() => {
                      updateDashboardControls(0, 'reviewsCount', 0)
                    }}
                  >
                    <CloseOutlined style={{ marginTop: '-2px' }} /> Clear
                  </div>
                </ReviewCount>
              </ReviewCountWrapper>
            )}
          </EmptyWrapper>
        </ChartWrapper>
      </EmptyContainer>
    )
  }

  return (
    <Wrapper
      ref={wrapperRef}
      canvaschart={!!isCanvasChart}
      size={size}
      fontsize={fontSize}
    >
      {/* optional override for header content - used in custom dashboards*/}
      {headerContent ? (
        <div ref={dashboardHeader}>{headerContent}</div>
      ) : (
        <>
          {!hideHeader && (
            <Flex
              ref={resizeRef}
              className={'drag-handle'}
              style={{ cursor: isEditing ? 'move' : 'inherit' }}
            >
              <ChartTitle
                currentChart={currentChart}
                isEditing={!!isEditing}
                fontSize={fontSize}
                chartTitle={chartTitle}
                setChartTitle={setChartTitle}
                filters={filters}
                dashboardControls={dashboardControls[0]}
                isLink={isLink}
                comparativePanelsNumber={comparativePanelsNumber}
                comparativeIndex={comparativeIndex}
                updateDashboardControls={updateDashboardControlsGeneral}
              />
              <ChartTools
                wrapperRef={wrapperRef}
                chartWrapperRef={chartWrapperRef}
                currentChart={currentChart}
                comparativeIndex={comparativeIndex}
                isDelta={isDelta}
                isUnlinked={true}
                currentPage={currentPage}
                pageSize={pageSize}
                order={order}
                toggleOrder={toggleOrder}
                onPageChange={onPageChange}
                prevRange={prevRange}
                fontSize={fontSize}
                isFullScreen={isFullScreen}
                expandedCategories={filteredExpandedCategories}
                sortedByCategory={sortedByCategory}
                chartItem={chartItem}
                comparePeriod={comparePeriod}
                timeInterval={timeInterval}
                isPreviousChartLoading={isPreviousChartLoading}
                hideParentCategories={hideParentCategories}
                disableGrouping={disableGrouping}
                tier1={tier1}
                showCategories={showCategories}
                filterValues={filters}
              />
            </Flex>
          )}
        </>
      )}

      <ChartWrapper
        ref={chartWrapperRef}
        style={chartWrapperStyle}
        size={size}
        canvasChart={!!isCanvasChart}
      >
        <span
          style={{
            position: 'absolute',
            height: 0,
            width: 'calc(100% - 150px)',
          }}
          ref={splitRef}
        ></span>
        {renderChart()}
      </ChartWrapper>

      {renderContextMenu()}

      {!!feature_flags?.allow_fullscreen && !!isFullScreen && (
        <ResizableChartContainer
          comparativeIndex={comparativeIndex}
          dashboardControls={dashboardControls}
          updateDashboardControls={updateDashboardControlsGeneral}
          currentChart={currentChart}
          filters={filters}
          prevRange={prevRange}
          setPrevRange={setPrevRange}
          filteredExpandedCategories={filteredExpandedCategories}
          legendRef={legendRef}
          currentConfiguredChart={currentConfiguredChart}
          highlightedLegendItems={highlightedLegendItems}
          setHighlightedLegendItems={setHighlightedLegendItems}
          disabledCategories={disabledCategories}
          setDisabledCategories={setDisabledCategories}
        />
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div<{
  canvaschart?: boolean
  size?: string
  fontsize?: string
}>`
  height: 100%;
  width: 100%;
  display: block;
  align-items: stretch;
  box-sizing: border-box;
  flex-direction: column;
  justify-content: center;
  min-height: 100%;
  background: white;
  padding-bottom: 10px;

  border-radius: var(--border-radius);

  @media print {
    page-break-after: always;
    padding: var(--default-padding);
  }

  // *:not(.filters-button *):not(.search-button *):not(.tools *) {
  //     font-size: ${(props) =>
    fontSizeDict[props.fontsize || DEFAULT_CHART_FONT_SIZE]};
  // }

  *:not(.filter-header *) {
    font-size: ${(props) =>
      fontSizeDict[props.fontsize || DEFAULT_CHART_FONT_SIZE]};
  }

  .split {
    overflow: hidden;
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  .gutter-horizontal {
    cursor: ew-resize;
    background: white
      url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAUAAAAeCAYAAADkftS9AAAAIklEQVQoU2M4c+bMfxAGAgYYmwGrIIiDjrELjpo5aiZeMwF+yNnOs5KSvgAAAABJRU5ErkJggg==')
      no-repeat 50%;
  }
`

export const ChartWrapper = styled.div<{
  canvasChart?: boolean
  size?: string
}>`
  position: relative;
  display: flex;
  flex-direction: row;
  height: 100%;
  //min-height: inherit;
  background: white;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  overflow-x: auto;
`

const TableWrapper = styled.div<{
  size?: string
  chartHeaderHeight?: number
}>`
  max-height: ${({ size, chartHeaderHeight }) => {
    if (size === 'small') {
      return '200px'
    }
    if (size === 'medium') {
      return '400px'
    }
    if (size === 'large') {
      return '1000px'
    }
    return `calc(100vh - 146px - ${chartHeaderHeight ? chartHeaderHeight + 22 : 38}px)`
  }};
  width: 100%;
`

export const CanvasWrapper = styled.div<{
  size?: string
  chartHeaderHeight?: number
  headerHeight: number
}>`
  flex: 1;
  width: fit-content;
  background: white;
  //padding: var(--default-padding-half);
  padding-left: 15px;
  //padding: 10px;
  border-radius: 0 0 var(--border-radius) var(--border-radius);
  max-height: ${({ size, chartHeaderHeight, headerHeight }) => {
    if (size === 'small') {
      return '200px'
    }
    if (size === 'medium') {
      return '400px'
    }
    if (size === 'large') {
      return '1000px'
    }
    return `calc(100vh - 126px - ${chartHeaderHeight ? chartHeaderHeight + headerHeight : headerHeight}px)`
  }};
  overflow: auto;
  overflow-y: overlay;
`

const Flex = styled.div`
  display: flex;
  align-items: center;
  padding: 11px var(--default-padding-half);
  background: white;
  justify-content: space-between;
  flex-wrap: wrap;
  row-gap: 5px;

  & > div {
    margin-left: 5px;
  }

  border-radius: var(--border-radius) var(--border-radius) 0 0;
`
export const LegendContainer = styled.div`
  background: ${legendBackground};
  overflow-y: auto;
  margin: 0 10px;
  display: flex;
  overflow-x: hidden;
  padding: 18px 13px;
  border-radius: 10px;
`

export const LegendWrapper = styled.div`
  width: 100%;
`

const EmptyContainer = styled.div<{ size?: string }>`
  display: flex;
  align-items: center;
  min-height: ${(props) => {
    if (props.size === 'small') {
      return '200px'
    }
    if (props.size === 'medium') {
      return '400px'
    }
    if (props.size === 'large') {
      return '1000px'
    }
    return 'calc(100vh - 185px)'
  }};
  overflow: auto;
`

export const EmptyWrapper = styled.div`
  padding: var(--default-padding-double);
  width: 100%;
`

export const TextCenter = styled.div`
  text-align: center;
`

const ContextMenu = styled.div`
  position: fixed;
  background: ${darkBlue};
  color: white;
  list-style: none;
  border-radius: 5px;
  z-index: 100;
  overflow: hidden;

  .menu-item {
    font-size: 14px;
    line-height: 24px;
    width: 13em;
    font-family: sans-serif;
    padding: 4px 10px;
  }

  .menu-item:not(:last-child) {
    border-bottom: 1px solid white;
  }

  .menu-item:hover {
    background: ${darkBlueHover};
    cursor: pointer;
  }
`

const ReviewCountWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 30px;
`
const ReviewCount = styled.div`
  display: flex;
  gap: 20px;
  justify-content: space-between;
  align-items: center;
  background: white;
  border-radius: 8px;
  padding: 10px 20px;
  border: 2px solid ${lightText};
`

const ContextMenuListItem = styled.li`
  display: flex;
  justify-content: space-between;
  align-items: center;
`
