import { AxiosResponse } from 'axios'
import { axiosInstance } from 'utils/axios'
import { PROJECT_KEYWORDS } from 'utils/apiLinks'
import type { FilterPayload } from 'features/project/features/filters/types'
import type { KeywordsSearchPayload, KeywordsSearchResponse } from './types'

export const searchKeywords = (
  proj_uuid: string,
  keywordsPayload: KeywordsSearchPayload,
  filtersPayload: FilterPayload,
  customParams?: any
): Promise<AxiosResponse<KeywordsSearchResponse>> => {
  let config = { params: { proj_uuid, ...keywordsPayload } }
  if (customParams) config = { ...config, ...customParams }
  return axiosInstance.post(PROJECT_KEYWORDS, filtersPayload, config)
}
