import React, { useEffect, useState } from 'react'
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  CloseOutlined,
} from '@ant-design/icons'
import { Button, Input, Select } from 'antd'
import styled from 'styled-components'
import {
  buttonBlue,
  cardBorderGrey,
  lightBlueFontColor,
  lightText,
  mainText,
} from 'assets/styles/variables'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import { useQuery } from 'react-query'
import { Loader } from 'shared/components'
import _ from 'lodash'
import { emptyFilters } from '../../../../utils'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { InnerTitle } from 'components/InnerTitle'
import { useLocalFilterList } from '../../../../hooks/useLocalFilterList'
import { FilterOption, FilterOptionSelect } from '../../../filters/types'
import { Fetcher } from 'shared/components/fetcher'
import { SearchOutlined } from '@mui/icons-material'
import PDPListItem from './components/PDPListItem/PDPListItem'
import YogiDivider from 'components/UI/YogiDivider'
import { getProductHierarchiesForProject } from 'features/project/features/products/model'
import { ProductHierarchyInfo } from 'features/project/features/products/types'

type FocusListProps = {
  selectedLens: string | null
  setSelectedProduct: (title: string) => void
  focusListData: ProductHierarchyInfo[]
  setFocusListData: (value: ProductHierarchyInfo[]) => void
}

const PDPList: React.FC<FocusListProps> = ({
  selectedLens,
  setSelectedProduct,
  focusListData,
  setFocusListData,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const { Option } = Select

  const { data: hierarchyData, isLoading } = useQuery(
    ['getProductHierarchiesForProject', projectId],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        projectId,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && selectedLens === 'product_hierarchy',
    },
  )

  const [selectedDataset, setSelectedDataset] = useState<string>('')
  const [selectedBrand, setSelectedBrand] = useState<string>('')

  const [filterList, setFilterList] = useState<FilterOption[]>([])

  const [activeFilters, setActiveFilters] = useState<Filters>(
    _.cloneDeep(emptyFilters),
  )

  useEffect(() => {
    const tempFilters = _.cloneDeep(emptyFilters)
    if (selectedBrand) {
      tempFilters.values.push({ field: 'brand', values: [selectedBrand] })
    }
    if (selectedDataset) {
      tempFilters.values.push({ field: 'proj_uuid', values: [selectedDataset] })
    }
    setActiveFilters(tempFilters)
  }, [selectedBrand, selectedDataset])

  const { data: filterListData, isLoading: filterListLoading } =
    useLocalFilterList(
      activeFilters,
      'product_hierarchy',
      ['product_hierarchy'],
      filterList,
      setFilterList,
      !(selectedBrand || selectedDataset),
    )

  useEffect(() => {
    if (filterListData) {
      setFilterList(filterListData)
    }
  }, [filterListData])

  const [sortField, setSortField] = useState<'title' | 'reviews'>('reviews')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const [searchTerm, setSearchTerm] = useState('')
  const [category, setCategory] = useState('All')

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }

  useEffect(() => {
    if (!hierarchyData) return

    let sortData = _.cloneDeep(hierarchyData)

    // do filtering
    if (selectedBrand || selectedDataset) {
      sortData = sortData.filter((item) => {
        return !!(
          filterList.find(
            (el) => el.field === 'product_hierarchy',
          ) as FilterOptionSelect
        )?.values?.find((el) => el.value === item.hierarchy)?.n_posts
      })
    }

    sortData.sort((a, b) => {
      let result

      if (sortField === 'title') {
        result = a.hierarchy.localeCompare(b.hierarchy)
      } else {
        result = b.review_count - a.review_count
      }

      return sortOrder === 'desc' ? result : -result
    })

    if (searchTerm) {
      sortData = sortData.filter((item) =>
        item.hierarchy.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    }
    setFocusListData(sortData)
  }, [hierarchyData, sortField, sortOrder, searchTerm, category, filterList])

  return (
    <Container>
      <Controls>
        <FilterControls>
          {/* TODO make like chart lookup search */}
          <SortControls
            style={{ justifyContent: 'space-between', width: '100%' }}
          >
            {/*<SortControls>*/}
            {/*  <span style={{ fontSize: 10 }}>Sort by:&nbsp;</span>*/}
            {/*</SortControls>*/}
            {/*<SortControls>*/}
            <Select
              style={{ width: '100%', height: 36 }}
              value={selectedBrand}
              // defaultValue={'All Brands'}
              onChange={(value: string) => setSelectedBrand(value)}
              options={[
                { value: '', label: 'All Brands' },
                ...((
                  defaultFilterList.find(
                    (el) => el.field === 'brand',
                  ) as FilterOptionSelect
                )?.values?.map((el) => ({
                  value: el.value,
                  label: el.value,
                })) ?? []),
              ]}
            />
            {/*</SortControls>*/}

            <div style={{ height: '28px', marginTop: -1 }}>
              <YogiDivider vertical />
            </div>
            <SortControls style={{ gap: 5 }}>
              <Select
                value={sortField}
                onChange={(value) => setSortField(value)}
                style={{ width: 120, height: 36 }}
              >
                <Option value="title">Name</Option>
                <Option value="reviews">Review Count</Option>
                {/*<Option value="date">Date</Option>*/}
                {/*<Option value="active">Active</Option>*/}
              </Select>
              <StyledButton
                onClick={() =>
                  setSortOrder((prev) => (prev === 'asc' ? 'desc' : 'asc'))
                }
              >
                {sortOrder === 'asc' ? (
                  <ArrowUpOutlined />
                ) : (
                  <ArrowDownOutlined />
                )}
              </StyledButton>
            </SortControls>
          </SortControls>
        </FilterControls>
        {/*<SortControls>*/}
        {/*  {!!(*/}
        {/*    defaultFilterList?.find(*/}
        {/*      (el) => el.field === 'proj_uuid',*/}
        {/*    ) as FilterOptionSelect*/}
        {/*  )?.values && (*/}
        {/*    <Select*/}
        {/*      style={{ width: '100%' }}*/}
        {/*      value={selectedDataset}*/}
        {/*      // defaultValue={'All Brands'}*/}
        {/*      onChange={(value: string) => setSelectedDataset(value)}*/}
        {/*      options={[*/}
        {/*        { value: '', label: 'All Datasets' },*/}
        {/*        ...((*/}
        {/*          defaultFilterList.find(*/}
        {/*            (el) => el.field === 'proj_uuid',*/}
        {/*          ) as FilterOptionSelect*/}
        {/*        )?.values?.map((el) => ({*/}
        {/*          value: el.cluster_uuid,*/}
        {/*          label: el.value,*/}
        {/*        })) ?? []),*/}
        {/*      ]}*/}
        {/*    />*/}
        {/*  )}*/}
        {/*</SortControls>*/}

        <SearchContainer>
          <StyledInput
            placeholder="Search..."
            prefix={
              <SearchOutlined
                style={{ color: lightBlueFontColor, fontSize: 16 }}
              />
            }
            style={{
              border: '2px solid ' + cardBorderGrey,
              borderRadius: '6px',
            }}
            onChange={onSearch}
            value={searchTerm}
            suffix={<StyledCloseOutlined onClick={() => setSearchTerm('')} />}
          />
        </SearchContainer>
      </Controls>
      {isLoading ? (
        <LoadingWrapper>
          <LogoSpinner></LogoSpinner>
          <LoaderTitle>Loading Products</LoaderTitle>
        </LoadingWrapper>
      ) : (
        <Content>
          {selectedLens === 'product_hierarchy' && isLoading ? (
            <div
              style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <Loader style={{ height: '50%', maxHeight: 300 }} />
              <LoaderTitle>{`Loading Options`}</LoaderTitle>
            </div>
          ) : (
            <FocusListWrapper>
              {focusListData?.map((focus, index) => (
                <PDPListItem
                  title={focus.hierarchy}
                  reviewCount={focus.review_count}
                  productCount={focus.product_count}
                  imageUrl={focus.image_url}
                  onSelect={setSelectedProduct}
                  disabled={
                    focus.source_types && !focus.source_types.includes('Amazon')
                  }
                />
              ))}
              {focusListData.length === 0 && (
                <div
                  style={{ textAlign: 'center', width: '100%', marginTop: 40 }}
                >
                  <InnerTitle>No Products Found</InnerTitle>
                </div>
              )}

              {filterListLoading && (
                <Overlay>
                  <div style={{ paddingTop: 30 }}>
                    <Fetcher />
                  </div>
                </Overlay>
              )}
            </FocusListWrapper>
          )}
        </Content>
      )}
    </Container>
  )
}

export default PDPList

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  min-width: 300px;
  max-width: 300px;
  position: relative;
`

const Controls = styled.div`
  padding: 18px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  //margin-bottom: 10px;
  gap: 20px;
  width: 100%;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
  align-items: center;
  //width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  width: 100%;
`

const Content = styled.div`
  //padding: var(--default-padding-half) var(--default-padding);
  height: 100%;
  overflow: auto;
  padding-bottom: 30px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const StyledButton = styled(Button)`
  border-radius: 6px;
  border: 2px solid ${cardBorderGrey};
  color: ${mainText};
  &:hover {
    border-color: ${buttonBlue};
  }
  padding: 4px 10px;
  height: 36px;
`
const StyledInput = styled(Input)`
  height: 36px !important;
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 2px !important;
  }
  .ant-input-affix-wrapper:hover {
    border-right-width: 2px !important;
  }
`
const StyledCloseOutlined = styled(CloseOutlined)`
  cursor: pointer;

  &:hover {
    color: ${lightText};
  }
`
const Overlay = styled.div`
  //background: transparent;
  position: absolute;
  //top: calc(50% - 50px);
  //left: calc(50% - 50px);
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  //justify-content: center;
  flex-direction: column;
  background: rgba(255, 255, 255, 0.5);
`
const LoadingWrapper = styled.div`
  margin-top: 100px;
  max-height: 100px;
`

const FocusListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  //position: relative;
`
