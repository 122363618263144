import React, { useState, useEffect } from 'react'
import { useParams, useHistory } from 'react-router-dom'
import { Loader } from 'shared/components'
import { signinURL } from 'utils/links'
import { ProjectRoot } from 'features/project/containers/project-root'
import { verifyAccess } from '../model'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import useMixpanel from '../../../hooks/useMixpanel'

type Params = { id: string; token: string }

export const SharePage = () => {
  const setProjectId = useProjectStore(
    (state: ProjectState) => state.setProjectId
  )
  const history = useHistory()
  const { id, token } = useParams<Params>()
  const [hasAccess, setAccess] = useState<boolean>(false)

  useEffect(() => {
    verifyAccess(id, token).then((result) => {
      if (result) {
        setAccess(true)
        setProjectId(id)
      } else {
        history.push(signinURL)
      }
    })
  }, [])

  useMixpanel()

  if (!hasAccess) {
    return <Loader />
  }

  return <ProjectRoot projectId={id} />
}
