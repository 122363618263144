import React from 'react'

const ImageSvgIcon: React.FC = () => (
  <svg
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M28 24.8889V3.11111C28 1.4 26.6 0 24.8889 0H3.11111C1.4 0 0 1.4 0 3.11111V24.8889C0 26.6 1.4 28 3.11111 28H24.8889C26.6 28 28 26.6 28 24.8889ZM8.55556 16.3333L12.4444 21.0156L17.8889 14L24.8889 23.3333H3.11111L8.55556 16.3333Z"
      fill="currentColor"
    />
  </svg>
)

export default ImageSvgIcon
