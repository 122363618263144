import styled from 'styled-components'

export const Copy = styled.div`
  position: absolute;
  top: 4px;
  right: 4px;
  z-index: 100;
  background: transparent;
  border-radius: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
`
