import React, { useEffect } from 'react'

export const useClickOutside = (
  ref: React.RefObject<any>,
  eventHandler: (e: any) => void
) => {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event: Event) {
      if (ref.current && !ref.current.contains(event.target)) {
        setTimeout(() => {
          eventHandler(event)
        }, 100)
      }
    }

    // Bind the event listener
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref, eventHandler])
}
