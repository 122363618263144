import React from 'react'
import { Transfer } from 'antd'
import { ProductTransferEntity } from '../types'

type Props = {
  rightItems: ProductTransferEntity[]
  leftItems: ProductTransferEntity[]
  setRightItems: (s: ProductTransferEntity[]) => void
}

export const ProductTransfer: React.FC<Props> = ({
  rightItems,
  leftItems,
  setRightItems,
}) => {
  const preparedAvailableItems = transformItem(leftItems)

  return (
    <div>
      <Transfer
        listStyle={{
          width: 340,
        }}
        dataSource={preparedAvailableItems}
        targetKeys={rightItems.map((i) => i.uuid)}
        onChange={(nextTargetKeys) => {
          const newList = nextTargetKeys
            .map((key) => leftItems.find((i) => i.uuid === key))
            .filter(Boolean) as ProductTransferEntity[]

          setRightItems(newList)
        }}
        // titles={['Available Items', 'Selected Items']}
        render={(item) => item.title as string}
      />
    </div>
  )
}

function transformItem(items: ProductTransferEntity[]) {
  return items.map((i) => ({
    key: i.uuid,
    title: i.name,
  }))
}
