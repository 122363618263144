// COLORS
export const cardBorderGrey = '#EFF0F6'
export const pageBackgroundGrey = '#FCFAFA'
export const darkBlue = '#28364F'
export const darkBlueHover = '#32435b'
export const buttonBlue = '#5072A4'
export const buttonHover = 'rgba(80, 114, 164, .8)'

// BRAND COLORS
export const primary = '#28364F'
export const secondary = '#FAB49B'
export const accent = '#9FB7C3'
export const offWhite = '#FCFAFA'
export const fieldBackground = '#9FB7C3'
export const mainText = '#343A40'
export const lightText = '#8A8C8F'

// SUPPLEMENTARY COLORS
export const secondaryNavy = '#5E7CB0'
export const secondaryNavyHover = '#4c6891'
export const secondaryRed = '#CD6C5C'
export const secondaryGreen = '#1DBD91'
export const tertiaryGreen = '#2e9376'
export const secondaryOrange = '#FFC46B'
export const secondaryYellow = '#FFE135'
export const secondaryBlue = '#3582F5'
export const superLightGrey = '#9fb7c31a'

// OTHER COLORs
export const starYellow = '#EBCB13'
export const errorRed = '#ff9685'
export const newRed = '#ea3920'

// YDS 2024
export const cardBorder = '#d8d8d8'
export const cardBoxShadow = '0px 4px 10px 0px #00000026'
export const toggleBorderSelected = '#d3d3d3'
export const toggleBorderUnselected = '#F0F6FF'
export const lightBlueFontColor = '#5072a4'
export const lightBlueFontColorHalfOpacity = '#A7B8D1'
export const selectableCardBackgroundSelected = '#e2efff'
export const selectableCardBackgroundNotSelected = '#F2F6FD'
export const selectableCardBorderSelected = '#486794'
export const selectableCardBorderNotSelected = '#E7EAEF'
export const greyBackgroundAlternate = '#f5f3f3'
export const newButtonBlue = '#3F70BF'
export const deselectedFontColor: string = '#A7B6CF'
export const selectedFontColor: string = '#28364F'

export const darkBlueFontColor = '#28364F'

// OCT 2024
export const primaryOrange = '#FF7055'

// NAVIGATION
export const navDefaultIconColor = '#5072A4'
export const navDefaultTextColor = '#D6E0ED'
export const navSettingsShareBackground = '#D6E0ED'

// HOME
export const suggestedViewsUnselected = '#E2CECE'

// BUTTONS
export const secondaryButtonBlue = '#E0E5F2'
export const secondaryButtonLightBlue = '#dde7f6'
export const secondaryButtonLightOrange = '#FFE7E2'
export const disabledButtonBackground = '#d8d8d8'

// FILTERS
export const filterButtonFontSize = '10px;'

// CHART SELECTOR
export const categoryBorder = '#D6E0ED'
export const activeCategoryBackground = '#E7EDF0'
export const footerBackground = '#F6F8F9'
export const chartCardBorder = '#5072A4'
export const overlayBackground = 'rgba(80, 114, 164, 0.6)'

// EXPORT
export const exportCardBackground = '#F5F5F5'
export const exportCardBackgroundHover = '#D6E0ED'

// CHARTS
export const datasetSelectorBackground = '#FFF1EE'
export const panelHover = 'rgba(255,112,85,0.2)'
export const legendBackground = '#F8F8F8'
export const disabledLegendItemColor = '#B6B6B6'

// DASHBOARD
export const lightBlueBackground = '#EBECF1'
export const fakePanelBackground = '#f5f3f3'

// KEYWORDS
export const cancelOrange = '#E2B2A9'

// CATALOG
export const pageTypeOptionButton = '#39475F'
export const pageTypeOptionButtonSelected = '#D6E0ED'
export const pageTypeOptionButtonHovered = '#434f66'

// PDP
export const pdpCopyButton = '#D6E0ED'
export const pdpCopyButtonHover = '#c9ddeb'
export const updatedPdpBackground = '#E6EAF1'

// LAYOUT
export const fontSizeS = '8px'
export const fontSizeM = '10px'
export const fontSizeL = '12px'
export const fontSizeXL = '14px'
export const fontSizeXXL = '18px'
