import React from 'react'
import { Space, Tooltip } from 'antd'
import {
  CheckCircleTwoTone,
  DollarTwoTone,
  LikeTwoTone,
} from '@ant-design/icons'
import { green, purple } from '@ant-design/colors'

export const PostIcons: React.FC<{
  is_verified: boolean
  is_recommended: boolean
  is_promoted: boolean
  spacing?: number
  onPromoHover?: (isHover: boolean) => void
}> = (props) => {
  return (
    <Space size={props.spacing}>
      {props.is_recommended && (
        <Tooltip title="Recommended">
          <LikeTwoTone twoToneColor={purple.primary} />
        </Tooltip>
      )}
      {props.is_verified && (
        <Tooltip title="Verified">
          <CheckCircleTwoTone />
        </Tooltip>
      )}
      {props.is_promoted && (
        <Tooltip title="Promotional">
          <DollarTwoTone
            twoToneColor={green.primary}
            onMouseEnter={() => props.onPromoHover?.(true)}
            onMouseLeave={() => props.onPromoHover?.(false)}
          />
        </Tooltip>
      )}
    </Space>
  )
}
