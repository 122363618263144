import React, { useMemo } from 'react'
import styled from 'styled-components'
import { getNavigationItems } from 'features/project/partials/ProjectLayout/components/Navigation/utils'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import HomeSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/HomeSvgIcon/HomeSvgIcon'
import mixpanel from 'features/trackers/mixpanel'
import { Link, useHistory, useRouteMatch } from 'react-router-dom'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { useFeatureFlags } from 'features/project/hooks/useFeatureFlags'
import { isInitialFlow } from 'features/home/utils'
import {
  navDefaultIconColor,
  navDefaultTextColor,
  primaryOrange,
} from 'assets/styles/variables'

export const Navigation: React.FC = () => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const pageSettings = details?.clientSettings?.project?.pages

  // hooks
  const history = useHistory()
  const { url } = useRouteMatch()
  const encodedUrl = encodeURI(url)
  const { data: feature_flags } = useFeatureFlags()

  // constants
  const search = window.location.search
  const navigationItems = useMemo(
    () =>
      getNavigationItems({
        feature_flags,
        pageSettings,
      }).filter((el) => el.enabled),
    [feature_flags, pageSettings, getNavigationItems],
  )

  const selectedNavItemIndex = navigationItems.findIndex(
    (el) =>
      el.url ===
      window.location.pathname.split(encodedUrl)[1]?.split('/')?.pop(),
  )

  const showMenuItems = !isInitialFlow(details?.state)

  return (
    <Container>
      <YogiHome
        isActive={selectedNavItemIndex === -1}
        onClick={(e) => {
          e.preventDefault()
          history.push(`${encodedUrl}` + search)
          mixpanel.track('home', {
            proj_uuid: projectId,
          })
        }}
      >
        <GenericSvgIcon SvgComponent={HomeSvgIcon} />
      </YogiHome>
      {showMenuItems && (
        <NavigationList>
          {navigationItems.map((el, index) => (
            <StyledLink
              key={index}
              isActive={index === selectedNavItemIndex}
              to={`${encodedUrl}/${el.url}` + search}
              style={{
                marginTop: el.title === 'auto insights' ? -6 : 0,
              }}
              onClick={(e) => {
                e.preventDefault()
                history.push(`${encodedUrl}/${el.url}` + search)
                mixpanel.track(el.title, {
                  proj_uuid: projectId,
                })
              }}
            >
              <IconWrapper>
                <GenericSvgIcon SvgComponent={el.svgComponent} />
              </IconWrapper>
              <NavText>{el.title}</NavText>
            </StyledLink>
          ))}
        </NavigationList>
      )}
    </Container>
  )
}

const Container = styled.div`
  width: 70px;
  margin: 35px 35px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  overflow: auto;
`

const YogiHome = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  transition: color 0.2s ease;
  margin-left: -1px;

  color: ${({ isActive }) => (isActive ? primaryOrange : navDefaultIconColor)};
  &:hover {
    color: ${primaryOrange};
  }
`

const NavigationList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 30px;
  gap: 17px;
  width: 100%;
`

const IconWrapper = styled.div`
  transition: color 0.2s ease;
`

const NavText = styled.div`
  font-size: var(--font-size-md);
  font-weight: 400;
  line-height: 28px;
  letter-spacing: 0.25px;
  text-align: center;
  transition: color 0.2s ease;
  white-space: nowrap;
`

const StyledLink = styled(Link)<{ isActive: boolean }>`
  text-decoration: none !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;

  ${IconWrapper} {
    color: ${({ isActive }) =>
      isActive ? primaryOrange : navDefaultIconColor};
  }

  ${NavText} {
    color: ${({ isActive }) =>
      isActive ? primaryOrange : navDefaultIconColor};
  }

  &:hover {
    ${IconWrapper}, ${NavText} {
      color: ${primaryOrange};
    }
  }
`
