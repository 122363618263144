import { useQuery } from 'react-query'
import { getProductHierarchiesForProject } from 'features/project/features/products/model'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { getCompassDetails } from 'features/project/model'
import { emptyFilters } from 'features/project/utils'

export const useInitializeHub = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  useQuery(
    ['getProductHierarchiesForProject', projectId],
    () =>
      getProductHierarchiesForProject(
        'getProductHierarchiesForProject',
        projectId,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  useQuery(
    ['home', projectId],
    () => getCompassDetails(projectId, emptyFilters),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    },
  )
}
