import React, { useState, useEffect } from 'react'
import { InnerTitle } from '../../../../../components/InnerTitle'
import { FileSearchOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import { CHARTS_URL } from '../../../utils'
import mixpanel from '../../../../trackers/mixpanel'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { OpenInNewOutlined } from '@mui/icons-material'
import { nextStepsConstants } from '../constants'
import NextStepsButton from './NextStepsButton'
import { Filters } from '../../../types'

type Props = {
  type: 'theme' | 'brand' | 'source_type' | 'product_hierarchy'
  value: string
  filters: Filters
}

const NextSteps: React.FC<Props> = ({ type, value, filters }) => {
  return (
    <div>
      <InnerTitle>
        <FileSearchOutlined style={{ marginRight: '5px' }} />
        Next Steps:
      </InnerTitle>
      <div style={{ display: 'flex', gap: 10, flexWrap: 'wrap' }}>
        {nextStepsConstants[type].map((el) => (
          <NextStepsButton
            title={el.title}
            filters={filters}
            dashboardControls={el.dashboardControls}
            page={el.page}
          />
        ))}
      </div>
    </div>
  )
}
export default NextSteps
