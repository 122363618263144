import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { Form, Input, Upload, Button, Tooltip, Select, Table } from 'antd'
import { UploadOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { UploadFile } from 'antd/lib/upload/interface'
import { FormikType } from '../new-source'
import { getFieldOptionsFromSourceData } from '../../utils'
import { tooltip } from 'utils/tooltip-data'

type Props = {
  formik: FormikType
}

type SelectItemProps = {
  fieldOptions: Array<string>
  onChange: (p: string | undefined) => void
  isSelected: (f: string) => boolean
  value?: string
  fields: { [s: string]: string }
}

const fields: { [s: string]: string } = {
  Body: 'body',
  Title: 'title',
  Id: 'input_id',
  Username: 'username',
  'User ID': 'userid',
  Location: 'location',
  Country: 'country',
  Date: 'create_time',
  Rating: 'score',
  'Syndicated From': 'syndicated_from',
  Source: 'source',
  Item: 'product',
  Product: 'product_hierarchy',
  'Formal Product': 'formal_product',
  Brand: 'brand',
  URL: 'url',
  Promotion: 'promotion',
  Verified: 'verified',
  Recommended: 'recommended',
  'Post Custom field': 'custom',
  'Source Custom field': 'source_custom',
  'Product Custom field': 'product_custom',
}

const SelectItem = React.memo(function SelectItem({
  fieldOptions,
  value,
  fields,
  onChange,
  isSelected,
}: SelectItemProps) {
  const selectedField = Object.keys(fields).find((f) => fields[f] === value)
  return (
    <DataItem>
      <Select
        defaultValue="[Ignored]"
        value={selectedField}
        onChange={(v: string) => onChange(v !== '-1' ? v : undefined)}
      >
        <Select.Option value="-1">[Ignored]</Select.Option>
        {fieldOptions.map((option) => (
          <Select.Option
            value={option}
            key={option}
            disabled={isSelected(option)}
          >
            {option}
          </Select.Option>
        ))}
      </Select>
    </DataItem>
  )
})

export const File: React.FC<Props> = ({ formik }) => {
  const [fileList, setFileList] = useState<UploadFile[]>([])
  const [options, setOptions] = useState<string[]>()
  const [values, setValues] = useState<string[][]>()

  const [tableColumns, setTableColumns] = useState<any>()
  const [tableValues, setTableValues] = useState<any>()

  const isSelected = useCallback(
    (fieldName: string) => {
      const field = fields[fieldName]
      const meta = formik.values as any

      if (['custom', 'source_custom', 'product_custom'].includes(field))
        return false
      return ![undefined, null].includes(meta[field])
    },
    [formik.values]
  )

  useEffect(() => {
    if (fileList && fileList.length) {
      const [file] = fileList

      getFieldOptionsFromSourceData(file, (o: string[], v: string[][]) => {
        setOptions(o)
        setValues(v)
      })
    }
  }, [fileList])

  useEffect(() => {
    if (formik.values.sourceFile && !fileList.length) {
      setFileList([formik.values.sourceFile as any])
    }
  }, [formik.values.sourceFile])

  useEffect(() => {
    if (options && values) {
      const columns: any = []
      options.forEach((option, index) => {
        columns.push({
          title: option,
          dataIndex: index,
          key: index,
          render: (t: any) => {
            if (t !== null) return t
            const values: any = formik.values
            const value = Object.keys(formik.values)
              .map((key) => ({ key: key, value: values[key] }))
              .find(({ value }) => parseInt(value) === index)

            return (
              <SelectItem
                isSelected={isSelected}
                fieldOptions={Object.keys(fields)}
                fields={fields}
                value={value?.key}
                onChange={(v: string | undefined) => {
                  const metaObject = formik.values as any
                  const oldFieldName = Object.keys(metaObject).find(
                    (key) => metaObject[key] === index
                  )
                  formik.setFieldValue(`${oldFieldName}`, undefined)

                  for (const field in metaObject.custom) {
                    if (metaObject.custom[field] === index) {
                      formik.setFieldValue(`custom.${field}`, undefined)
                    }
                  }
                  for (const field in metaObject.source_custom) {
                    if (metaObject.source_custom[field] === index) {
                      formik.setFieldValue(`source_custom.${field}`, undefined)
                    }
                  }
                  for (const field in metaObject.product_custom) {
                    if (metaObject.product_custom[field] === index) {
                      formik.setFieldValue(`product_custom.${field}`, undefined)
                    }
                  }

                  if (!v) {
                    return
                  }

                  const fieldName = fields[v]
                  if (
                    ['custom', 'source_custom', 'product_custom'].includes(
                      fieldName
                    )
                  ) {
                    formik.setFieldValue(`${fieldName}.${option}`, index)
                  } else {
                    formik.setFieldValue(`${fieldName}`, index)
                  }
                }}
              />
            )
          },
        })
      })
      setTableColumns(columns)

      const resultValues: any = []
      resultValues.push(columns.map(() => null))
      values.forEach((valueList, index) => {
        const o: any = {}
        valueList.forEach((value, i) => {
          o[i] = value.length > 100 ? value.substring(0, 100) : value
        })
        resultValues.push(o)
      })
      setTableValues(resultValues)
    }
  }, [formik.values, options, values])

  const { touched, errors } = formik

  const sourceTitleError = touched.sourceTitle && errors.sourceTitle

  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['file-form']}>
            <span>
              File <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={errors.sourceFile && 'error'}
        help={formik.submitCount > 0 && errors.sourceFile}
        name="photos"
      >
        <Upload
          fileList={fileList}
          accept={'.csv'}
          beforeUpload={() => false}
          onChange={({ file }: any) => {
            if (file.status === 'removed') {
              formik.setFieldValue('sourceFile', null)
              formik.setFieldValue('sourceTitle', null)
              setFileList([])
              setTableColumns(null)
              setTableValues(null)
            } else {
              if (file.size / 1024 / 1024 >= 50) {
                toast.error('The uploaded file should be less than 50mb')
                return
              }
              formik.setFieldValue('sourceFile', file)
              formik.setFieldValue('sourceTitle', file.name.split('.')[0])
              setFileList([file])
            }
          }}
        >
          <Button>
            <UploadOutlined /> Click to Upload
          </Button>
        </Upload>
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['file-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!sourceTitleError ? 'error' : ''}
        help={sourceTitleError}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Error>{errors?.title}</Error>
      <TableWrapper>
        {tableColumns && tableValues && (
          <Table
            scroll={{ x: 400 }}
            dataSource={tableValues}
            columns={tableColumns}
            pagination={false}
          />
        )}
      </TableWrapper>
    </>
  )
}
const DataItem = styled.div`
  width: 180px;
  margin-bottom: 8px;

  .ant-select {
    width: 100%;
  }
`
const TableWrapper = styled.div`
  max-width: 100%;
  min-height: 300px;
  position: relative;

  td {
    min-width: 50px;
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 6px;
    white-space: nowrap;
  }
`
const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
`
