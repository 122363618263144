import React from 'react'
import { Button as AntdButton } from 'antd'
import styled from 'styled-components'

type ButtonProps = React.ComponentProps<typeof AntdButton>

const Button: React.FC<ButtonProps> = (props) => (
    <SubmitButton {...props} />
  )


const SubmitButton = styled(AntdButton)`
  display: block;
  width: 100%;
  height: 50px;

  border-radius: 5px;

  font-size: 16px;
  line-height: 19px;
`

export { Button }
