import { axiosInstance } from '../../utils/axios'
import { SEMANTIC_QUERY } from '../../utils/apiLinks'

export const getSemanticReferences = async (
  proj_uuid: string,
  query: string,
  brands?: string[],
  products?: string[],
  themes?: string[],
  limit?: number,
  threshold?: number
): Promise<any[]> => {
  const { data } = await axiosInstance.post(
    SEMANTIC_QUERY,
    {
      query,
      brands,
      products,
      themes,
      limit,
      threshold: (threshold ?? 25) / 100,
      pc_index: 'openai-sentence',
    },
    { params: { proj_uuid } }
  )
  return data
}
