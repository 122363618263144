export const tooltip = {
  'original-sources':
    'The non-syndicated sources that were added to the project by a project admin.',
  'syndicated-sources':
    'The sources that Yogi created while reading syndicated reviews or reviews. Syndicated reviews can appear on several websites, but Yogi deduplicates these and only stores them once under one of these syndicated sources.',
  'source-form-title':
    "Give your source a name to display in the charts. Make sure it's one you'll recognize.",
  'source-form-description':
    'Give your source a description to make it easier for you to remember what it contains.',
  'form-date': 'Select the earliest date from which data should be pulled.',
  'file-form':
    "Select the file you'd like to upload into Yogi. Ensure that the top row holds the relevant column names. Also please note that Yogi only supports files with up to 50,000 rows. Any files longer that will not be allowed.",
  'file-form-title':
    "Give your file a name to display in the charts. Make sure it's one you'll recognize.",
  'file-form-description':
    'Give your file a description to make it easier for you to remember what it contains.',
  'source-url': 'Provide the URL of the page you want pulled.',
  'product-form-title':
    "Give your product item (e.g. SKU) a name to display in the charts. Make sure it's one you'll recognize.",
  'direct-messages': 'Check this to analyze the direct messages of the page.',
  'global-reviews':
    'Check this to pull reviews that were submitted outside of the specified Amazon domain.',
  'processing-data-types': 'Choose data types to process.',
  'product-form-name': "Provide the item's formal or official name.",
  'product-form-brand':
    "Provide the name of the item's brand to display in the charts.",
  'product-form-hierarchy':
    'Provide the name of the overall umbrella product that should contain this item.',
  'product-form-generic':
    'Indicate whether this item is the "generic" catch-all item for the specified product. Syndicated reviews that cannot be explicitly tied to a particular item will be placed under this general item.',
  'theme-form-name': 'Name the new theme.',
  'download-button':
    'Select "Download All Reviews" to download all data from the project. Select "Download Filtered Reviews" to download only the data that is valid for the currently applied filter. You will receive an email with a link to download the results once complete.',
  'num-posts':
    'Number of reviews or reviews among all sources in this project.',
  'avg-sentiment': 'Average sentiment of sentences.',
  'avg-rating': 'Average rating of reviews.',
  'num-sentences':
    'Number of sentences among all reviews or reviews in this project. Sentences are synonymous with the nodes on the Network page. Sentence parsing allows for more granular and accurate metrics such as sentiment and theme associations.',
  'sentiment-breakdown': 'Breakdown of reviews by scores.',
  'copy-button': 'Copy Chart to Clipboard',
  'print-button': 'Download Chart Image',
  'csv-download': 'Download Chart Data as CSV',
  'legend-button': 'Toggle the legend display.',
  'source-title': 'The title of the source.',
  'source-last-update':
    'The date the source was last updated (UTC+00 timezone).',
  'source-item': 'The item to which this source refers.',
  'source-state':
    "The state of source. Complete means the source has completed pulling reviews, incomplete means the source is queued to pull reviews, processing means the source is actively pulling reviews, and error means the source had an issue pulling reviews during it's first pull.",
  'source-hierarchy':
    'The umbrella product of the item to which this source refers.',
  'custom-field':
    'Custom fields that add more information to this source. These fields are generally filterable.',
  'custom-value': 'The values of the custom fields for this source.',
  'filter-panel':
    'Fields that can be filtered are shown here. Click on a field to view its distinct filter options. Select the options you want to filter, and click "Apply" to apply the filter. Click "Deselect All" to remove the current field\'s options from the filter, and click "Clear" to remove all filters. Some fields have a range of values to choose from. Use the slider to add a value range to the filter.',
  'search-bar':
    'Text search can be applied as a filter. Type one or multiple words and hit the "Enter" key to commit text as a filter option. You can string multiple text options together and apply logical "AND", "OR", and "Exclude" criteria to specify whether all/some/none of the text options should be contained in the filtered reviews.',
  'total-nodes':
    'The total number of sentences among the reviews in the project for the currently applied filter.',
  'selected-nodes':
    // 'The selected number of nodes. Click the "SHIFT" key and left-mouse click drag the red select box over the network graph to select nodes. The selected count will appear here. If you are a project admin, you may right click on the graph after selecting nodes to manage the themes these nodes are assigned to.',
    'The selected number of nodes.',
  'integrity-reports':
    'Generate reports on the integrity of the data pulled from the sources in the project. The results are sent to your email. Use these reports to verify data is being pulled correctly from the sources, syndication is working correctly, and to check duplicate reviews or reviews.',
  'start-postprocessing':
    'Start building connections between reviews or reviews and generating themes after all the sources have completed pulling data.',
  'refresh-preferences':
    'Update the personalized project settings of all users who have access to this project. Click this if you are experiencing strange behavior with features such as filtering and network graph coloring.',
  'public-url':
    'Toggle public sharing of this project. If enabled, you can share this URL with people, and they can access this project without a Yogi login.',
  'source-url-page': 'This is the source url.',
  'post-sentence-metrics': 'Display with sentence metrics. Review by default.',
  'export-dashboard': 'Download all charts',
  'keyword-page-window':
    'Select the number of words to the left and right of your phrase to include in the search results.',
  'rerun-stop-words': 'Regenerate themes using the current set of stop words.',
  'run-nlp': 'Run NLP processing on the ingested reviews.',
  'suggested-stopwords':
    'Get a CSV file with suggested stop words per theme sent to your email.',
  'update-project': 'Ingest new reviews into the project.',
}
