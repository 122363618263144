import React, { useState, useCallback, useRef } from 'react'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'
import { Input, Form, Button } from 'antd'

type Props = {
  onFieldAdd: (s: string, v: string) => void
  onFieldRemove: (s: string) => void
  fields?: {
    [s: string]: string | number
  } | null
}

export const CustomFields: React.FC<Props> = ({
  onFieldAdd,
  onFieldRemove,
  fields,
}) => {
  const keyRef = useRef(null)
  const [key, setKey] = useState('')
  const [value, setValue] = useState('')

  const onAdd = useCallback(() => {
    if (key.length && value.length) {
      onFieldAdd(key, value)

      setKey('')
      setValue('')

      // @ts-ignore
      keyRef.current.focus()
    }
  }, [key, value])

  return (
    <Wrapper>
      <Form.Item label="Custom Fields">
        <Headers>
          <Row>
            <span>Keys</span>
            <span>Values</span>
          </Row>
        </Headers>
        <Values>
          {fields &&
            Object.keys(fields).map((key) => (
              <Row key={key}>
                <div>{key}</div>
                <div>
                  {fields[key]}
                  <CloseOutlined onClick={() => onFieldRemove(key)} />
                </div>
              </Row>
            ))}
          <Row>
            <Input
              ref={keyRef}
              value={key}
              onChange={(event) => setKey(event.target.value)}
              style={{ width: 200 }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  onAdd()
                }
              }}
            />
            <Input
              value={value}
              onChange={(event) => setValue(event.target.value)}
              style={{ width: 200 }}
              onKeyPress={(event) => {
                if (event.key === 'Enter') {
                  event.preventDefault()
                  onAdd()
                }
              }}
            />
          </Row>
        </Values>

        <ButtonWrapper>
          <Button onClick={onAdd}>Add Field</Button>
        </ButtonWrapper>
      </Form.Item>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  margin-top: var(--default-padding);
  max-height: 300px;
  overflow: auto;
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: var(--default-padding-half);
`
const Headers = styled.div`
  padding-bottom: var(--default-padding-half);
  border-bottom: 1px solid var(--color-grey);
`
const Values = styled.div`
  padding-top: var(--default-padding-half);
  position: relative;

  &:before {
    content: ' ';
    height: 100%;
    left: 50%;
    top: 0;
    position: absolute;
    border-right: 1px solid var(--color-grey);
  }
`
const Row = styled.div`
  display: flex;
  justify-content: space-around;

  span {
    font-weight: 500;
    font-size: 16px;
  }

  div {
    width: 200px;
    text-align: center;
    margin-bottom: 6px;

    &:nth-child(2) {
      display: flex;
      align-items: center;
      justify-content: center;

      & > span {
        cursor: pointer;
        justify-self: flex-end;
        margin-left: 10px;
      }
    }
  }
`
