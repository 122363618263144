import React, { useState } from 'react'
import styled from 'styled-components'
import { Select, Form, Button, Input } from 'antd'
import { Loader } from 'shared/components'
import { ProductTransfer } from '../components/product-transfer'
import { addSuffix, removeSuffix, TransferEntities } from '../helpers'
import { ProductTransferEntity } from '../types'
import {
  fetchProductsForGroupOrHierarchy,
  updateProductsForGroupOrHierarchy,
} from '../model'

type Props = {
  type: TransferEntities
  options: Array<string>
  projectId: string
}

export const ProductEditForm: React.FC<Props> = ({
  type,
  options,
  projectId,
}) => {
  const [localOptions, setLocalOptions] = useState<string[]>([])
  const [localOptionInput, setLocalOptionInput] = useState('')
  const [isLoading, setLoading] = useState(false)
  const [leftEntity, setLeftEntity] = useState<string | undefined>()
  const [rightEntity, setRightEntity] = useState<string | undefined>()
  const [leftItems, setLeftItems] = useState<ProductTransferEntity[]>([])
  const [rightItems, setRightItems] = useState<ProductTransferEntity[]>([])
  const [selectedItems, setSelectedItems] = useState<ProductTransferEntity[]>(
    []
  )
  const allItems = [...leftItems, ...rightItems]
  const setRightAndSelectedItems = (e: ProductTransferEntity[]) => {
    const signedEntities = addSuffix(e)
    setRightItems(signedEntities)
    setSelectedItems(signedEntities)
  }

  const fetchEntity = async (
    entity: string,
    setItems: (i: ProductTransferEntity[]) => void
  ) => {
    setLoading(true)
    const items = await fetchProductsForGroupOrHierarchy(
      projectId,
      entity,
      type
    )
    setLoading(false)
    setItems(items)
  }

  const updateEntities = async () => {
    if (!leftEntity || !rightEntity) return null
    try {
      setLoading(true)
      const leftItemIds = allItems
        .filter((i) => !selectedItems.find((item) => item.uuid === i.uuid))
        .map((i) => i.uuid)
      const rightItemIds = selectedItems.map((i) => i.uuid)
      await Promise.all(
        [
          !!leftItemIds.length &&
            updateProductsForGroupOrHierarchy(
              projectId,
              leftEntity,
              removeSuffix(leftItemIds),
              type
            ),
          !!rightItemIds.length &&
            updateProductsForGroupOrHierarchy(
              projectId,
              rightEntity,
              removeSuffix(rightItemIds),
              type
            ),
        ].filter(Boolean) as any
      )

      await Promise.all([
        fetchEntity(leftEntity, setLeftItems),
        fetchEntity(rightEntity, setRightAndSelectedItems),
      ])
    } catch {
      alert('Something went wrong')
    }
  }

  const availableOptions = [...options, ...localOptions]

  return (
    <>
      <StyledForm
        layout="inline"
        onFinish={() => {
          setLocalOptions(
            Array.from(new Set([...localOptions, localOptionInput]))
          )
          setLocalOptionInput('')
          setLeftEntity(localOptionInput)
          setLeftItems([])
        }}
      >
        <Form.Item label={`New ${type}`}>
          <Input
            name="option"
            value={localOptionInput}
            onChange={(e) => setLocalOptionInput(e.target.value)}
            style={{ width: 400 }}
          />
        </Form.Item>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Create
          </Button>
        </Form.Item>
      </StyledForm>

      <SelectWrapper>
        <Select
          showSearch
          style={{ width: '100%' }}
          loading={isLoading}
          disabled={isLoading}
          value={leftEntity}
          placeholder={`Select a ${type}...`}
          onChange={(entity) => {
            setLeftEntity(entity)
            fetchEntity(entity, setLeftItems)
          }}
        >
          {availableOptions
            .filter((i) => i !== rightEntity)
            .map((option) => (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            ))}
        </Select>
        <Select
          showSearch
          style={{ width: '100%' }}
          loading={isLoading}
          disabled={isLoading}
          placeholder={`Select a ${type}...`}
          onChange={(e) => {
            const entity = e as string
            setRightEntity(entity)
            fetchEntity(entity, setRightAndSelectedItems)
          }}
        >
          {availableOptions
            .filter((i) => i !== leftEntity)
            .map((option) => (
              <Select.Option value={option} key={option}>
                {option}
              </Select.Option>
            ))}
        </Select>
      </SelectWrapper>
      <ProductTransferWrapper>
        <ProductTransfer
          leftItems={allItems}
          rightItems={selectedItems}
          setRightItems={setSelectedItems}
        />
        {isLoading && (
          <LoadingLayer>
            <Loader />
          </LoadingLayer>
        )}
      </ProductTransferWrapper>
      <ButtonWrapper>
        <Button
          type="primary"
          size="large"
          loading={isLoading}
          disabled={!leftEntity || !rightEntity}
          onClick={updateEntities}
        >
          Save
        </Button>
      </ButtonWrapper>
    </>
  )
}

const SelectWrapper = styled.div`
  display: flex;
  justify-content: space-between;

  & > * {
    margin: 0 var(--default-padding-half);
  }
`
const ProductTransferWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: var(--default-padding) 0;
  margin-bottom: var(--default-padding-half);
`
const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
`
const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`
const StyledForm = styled(Form)`
  margin: 0 var(--default-padding-half) var(--default-padding);
`
