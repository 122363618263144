import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
} from 'features/project/features/filters/types'
import React from 'react'
import styled from 'styled-components'

type Props = {
  applyFreeInput: (values: string[]) => void
  currentAppliedFilter: AppliedFilterOption | undefined
}

const FreeInput: React.FC<Props> = ({
  applyFreeInput,
  currentAppliedFilter,
}) => {
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    const values = e.target.value
      .split(/[,\n]/)
      .map((v) => v.trim())
      .filter(Boolean)
    applyFreeInput(values)
  }

  return (
    <Container>
      <StyledTextArea
        onChange={handleInputChange}
        placeholder="Enter values separated by commas or new lines"
        rows={5}
        defaultValue={(
          (currentAppliedFilter as AppliedFilterOptionSelect)?.values ?? []
        ).join('\n')}
      />
    </Container>
  )
}

export default FreeInput

const Container = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;
`

const StyledTextArea = styled.textarea`
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
`
