import React from 'react'
import { Form as AntdForm } from 'antd'
import styled from 'styled-components'
import { cardBorderGrey } from '../../../assets/styles/variables'

type FormItemProps = React.ComponentProps<typeof AntdForm.Item>

const FormItem: React.FC<FormItemProps> = (props) => (
  <StyledFormItem {...props} />
)

const StyledFormItem = styled(AntdForm.Item)`
  margin-bottom: 15px;

  label {
    font-weight: 300;
    color: ${(props) =>
      props.validateStatus === 'error' ? '#FF0033' : '#495057'};
  }
`

export { FormItem }
