import { ChartData } from 'chart.js'
import { Context } from 'chartjs-plugin-datalabels'
import { numberFormatter } from 'utils/numberFormat'

import { PieChart, DataForChart } from '../types'
import { getGroupedChartValues2 } from 'features/project/features/Charts/utils'

const insertLineBreaks = (
  sentence: string,
  wrapLabels: boolean = false,
  maxAxesLength: number = 25,
) => {
  if (!wrapLabels) return sentence
  let words = sentence.toString()?.split(' ') ?? sentence
  let newSentence = ''
  let lineLength = 0
  words?.forEach((word) => {
    if (lineLength + word.length > maxAxesLength) {
      newSentence += '\n'
      lineLength = 0
    }
    newSentence += word + ' '
    lineLength += word.length
  })
  return newSentence
}

export const configurePieChart = (
  currentChart: PieChart,
  tier1: string,
  showCategories: boolean,
  expandedCategories: string[],
  hasLabels?: boolean,
  wrapLabels?: boolean,
  maxAxesLength?: number,
  disableGrouping?: boolean,
): DataForChart => {
  let values: {
    category: string
    color: string
    percent: number
    volume: number
    hover: any
    [s: string]: number | string
  }[] = getGroupedChartValues2(
    currentChart,
    expandedCategories,
    tier1,
    showCategories,
    disableGrouping,
  )

  // Sort rating from 5 to 1, instead of by volume which is default
  values =
    currentChart.legend_title === 'Rating'
      ? [...values].sort((a: any, b: any) => {
          return Number(b.category) - Number(a.category)
        })
      : values

  let chartValues = values.map((v) => v.volume)
  const labels = values.map((v) => v[currentChart.legend_key])
  const additionalValues: any = values.map((v) => v.hover)
  const backgroundColor = values.map((v) => v.color)
  const legend = values.map((v) => ({
    color: v.color,
    label: v.category,
    disabled: expandedCategories?.includes(v.category) ?? false,
    children:
      currentChart?.category_mapping &&
      currentChart?.category_mapping[v.category]?.map((item) => ({
        color: values.find((el) => el.category === item)?.color ?? v.color,
        label: item.split(': ')[1] ?? item,
        disabled: false,
      })),
  }))

  const options: any = {
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: false,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    plugins: {
      datalabels: hasLabels
        ? {
            formatter: (value: any, context: Context) => {
              const label = context.chart.data?.labels?.[context.dataIndex]
              const curSum: number = (
                context.chart.data.datasets[0].data as number[]
              ).reduce((s, v) => s + v, 0)
              const percentage = (value * 100) / curSum
              if (percentage < 3) return null
              return (
                percentage.toFixed(2) +
                '% ' +
                insertLineBreaks(label as string, wrapLabels, maxAxesLength)
              )
            },
            display: 'auto',
            anchor: 'end',
            align: 'end',
            color: '#000000',
          }
        : false,
      legend: false,
      tooltip: {
        position: 'nearest',
        displayColors: false,
        callbacks: {
          title: function (data: any) {
            return `${data[0].label}`
          },
          label(): string | string[] {
            return ''
          },
          afterBody: function (data: any) {
            const index = data[0].dataIndex
            if (index === undefined) return ''
            const hoverIndex = values.findIndex(
              (item) => item.volume === data[0].dataset.data[index],
            )
            return additionalValues[hoverIndex]
              ? [
                  ...Object.keys(additionalValues[hoverIndex]).map(
                    (key) =>
                      `${key}: ${numberFormatter(
                        additionalValues[hoverIndex][key],
                      )}`,
                  ),
                ]
              : []
          },
        },
      },
    },
    animation: {
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    layout: {
      padding: {
        left: 50,
        right: 50,
        top: 50,
        bottom: 50,
      },
    },

    elements: {
      center: {
        text: currentChart.avg_value || '',
        color: '#000',
      },
    },
  }

  const data: ChartData = {
    datasets: [
      {
        data: chartValues,
        backgroundColor,
      },
    ],
    labels,
  }

  const plugins: any[] = [
    {
      id: 'customPlugin',
      beforeDraw: function (chart: any) {
        try {
          if (chart.config.options.elements.center.text) {
            // Get ctx from string
            var ctx = chart.ctx
            chart.innerRadius = 50
            // Get options from the center object in options
            var centerConfig = chart.config.options.elements.center
            var fontStyle = centerConfig.fontStyle || 'Arial'
            var txt = centerConfig.text ?? ''
            var color = centerConfig.color || '#000'
            var maxFontSize = centerConfig.maxFontSize || 75
            var sidePadding = centerConfig.sidePadding || 20
            var sidePaddingCalculated =
              (sidePadding / 100) * (chart.innerRadius * 2)
            // Start with a base font of 30px
            ctx.font =
              chart.width > 600 ? '30px ' + fontStyle : '60px ' + fontStyle

            // Get the width of the string and also the width of the element minus 10 to give it 5px side padding
            var stringWidth = ctx.measureText(txt).width
            var elementWidth = chart.innerRadius * 2 - sidePaddingCalculated

            // Find out how much the font can grow in width.
            var widthRatio = elementWidth / stringWidth
            var newFontSize = Math.floor(30 * widthRatio)
            var elementHeight = chart.innerRadius * 2

            // Pick a new font size so it will not be larger than the height of label.
            var fontSizeToUse = Math.min(
              newFontSize,
              elementHeight,
              maxFontSize,
            )
            var minFontSize = centerConfig.minFontSize
            var lineHeight = centerConfig.lineHeight || 25
            var wrapText = false

            if (minFontSize === undefined) {
              minFontSize = 20
            }

            if (minFontSize && fontSizeToUse < minFontSize) {
              fontSizeToUse = minFontSize
              wrapText = true
            }

            // Set font settings to draw it correctly.
            ctx.textAlign = 'center'
            ctx.textBaseline = 'middle'
            var centerX = (chart.chartArea.left + chart.chartArea.right) / 2
            var centerY = (chart.chartArea.top + chart.chartArea.bottom) / 2
            ctx.font = fontSizeToUse + 'px ' + fontStyle
            ctx.fillStyle = color

            if (!wrapText) {
              ctx.fillText(txt, centerX, centerY)
              return
            }

            var words = txt.toString().split('')
            var line = ''
            var lines = []

            // Break words up into multiple lines if necessary
            for (var n = 0; n < words.length; n++) {
              var testLine = line + words[n] + ' '
              var metrics = ctx.measureText(testLine)
              var testWidth = metrics.width
              if (testWidth > elementWidth && n > 0) {
                lines.push(line)
                line = words[n] + ' '
              } else {
                line = testLine
              }
            }

            // Move the center up depending on line height and number of lines
            centerY -= (lines.length / 2) * lineHeight

            for (var i = 0; i < lines.length; i++) {
              ctx.fillText(lines[i], centerX, centerY)
              centerY += lineHeight
            }
            //Draw text in center
            ctx.fillText(line, centerX, centerY)
          }
        } catch (e) {}
      },
    },
  ]

  return { data, options, type: 'doughnut', height: 500, legend, plugins }
}
