import React, { Fragment } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Dropdown, Menu } from 'antd'
import mixpanel from 'features/trackers/mixpanel'
import styled from 'styled-components'
import {
  cardBorder,
  filterButtonFontSize,
  navDefaultTextColor,
  primaryOrange,
} from 'assets/styles/variables'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { DashboardChartResponse } from 'features/project/features/Charts/types'

type Props = {
  chartItem: DashboardChartResponse
  tier1: string
  onChange: (value: string) => void
}

export const Breakdown: React.FC<Props> = ({ chartItem, tier1, onChange }) => {
  const contexts = (
    <Menu
      style={{
        maxHeight: '320px',
        overflowY: 'scroll',
        overflowX: 'hidden',
        border: '1px solid rgba(0,0,0,0.16)',
        marginTop: '-2px',
      }}
      onClick={(data) => {
        mixpanel.track('chart lens', { value: data?.key.toString() })
        onChange(data?.key.toString())
      }}
      selectedKeys={tier1 ? [tier1] : []}
    >
      {chartItem?.tier1.options.slice(0, -1).map((option: any, index: any) => {
        const isBordered = chartItem.tier1.options
          .find((el: any) => el.name === 'bar')
          ?.value?.includes(index)
        return (
          <Fragment key={option.name}>
            <Menu.Item
              className={option.name === 'Product' ? 'product_item' : ''}
              key={option.name}
              style={{
                color: 'black',
                fontSize: 10,
                // marginLeft: 8,
              }}
            >
              {option.name}
            </Menu.Item>
            {isBordered && (
              <Menu.Item
                disabled
                key={'bar' + option.name}
                style={{
                  color: 'black',
                  border: '0.5px solid rgba(0,0,0,0.16)',
                  padding: 0,
                  height: '1px',
                  margin: '4px',
                  cursor: 'unset',
                }}
              ></Menu.Item>
            )}
          </Fragment>
        )
      })}
    </Menu>
  )
  return (
    <>
      <Dropdown overlay={contexts} trigger={['click']}>
        <BreakdownButton>
          <ColorBar></ColorBar>
          <BreakdownText>
            <div style={{ whiteSpace: 'nowrap' }}>Breakdown by </div>
            <SelectedLensText>{tier1 ?? <LoadingOutlined />}</SelectedLensText>
          </BreakdownText>
        </BreakdownButton>
      </Dropdown>
    </>
  )
}

const BreakdownButton = styled.div`
  width: 100%;
  font-size: ${filterButtonFontSize};
  margin: 5px;
  background: white;
  border: 2px solid ${cardBorder};
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #00000026;
  cursor: pointer;
  transition: 0.2s ease;
  min-width: 175px;
  &:hover {
    border-color: ${navDefaultTextColor};
  }
`

const ColorBar = styled.div`
  width: 8px;
  background: ${primaryOrange};
`

const BreakdownText = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  padding: 8px 14px 8px;
`

const SelectedLensText = styled.span`
  color: ${primaryOrange};
  font-weight: 500;
  margin: 0 10px;
  white-space: nowrap;
`
