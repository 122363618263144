import React from 'react'

const AdminHomeSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M2 0C0.895429 0 0 0.89543 0 2V4.66667C0 5.77124 0.89543 6.66667 2 6.66667H4.66667C5.77124 6.66667 6.66667 5.77124 6.66667 4.66667V2C6.66667 0.895429 5.77124 0 4.66667 0H2ZM5 3.33333C5 4.25381 4.25381 5 3.33333 5C2.41286 5 1.66667 4.25381 1.66667 3.33333C1.66667 2.41286 2.41286 1.66667 3.33333 1.66667C4.25381 1.66667 5 2.41286 5 3.33333ZM2 8.33333C0.895429 8.33333 0 9.22876 0 10.3333V13C0 14.1046 0.89543 15 2 15H4.66667C5.77124 15 6.66667 14.1046 6.66667 13V10.3333C6.66667 9.22876 5.77124 8.33333 4.66667 8.33333H2ZM5 11.6667C5 12.5871 4.25381 13.3333 3.33333 13.3333C2.41286 13.3333 1.66667 12.5871 1.66667 11.6667C1.66667 10.7462 2.41286 10 3.33333 10C4.25381 10 5 10.7462 5 11.6667ZM10.3333 0C9.22876 0 8.33333 0.89543 8.33333 2V4.66667C8.33333 5.77124 9.22876 6.66667 10.3333 6.66667H13C14.1046 6.66667 15 5.77124 15 4.66667V2C15 0.895429 14.1046 0 13 0H10.3333ZM13.3333 3.33333C13.3333 4.25381 12.5871 5 11.6667 5C10.7462 5 10 4.25381 10 3.33333C10 2.41286 10.7462 1.66667 11.6667 1.66667C12.5871 1.66667 13.3333 2.41286 13.3333 3.33333ZM10.3333 8.33333C9.22876 8.33333 8.33333 9.22876 8.33333 10.3333V13C8.33333 14.1046 9.22876 15 10.3333 15H13C14.1046 15 15 14.1046 15 13V10.3333C15 9.22876 14.1046 8.33333 13 8.33333H10.3333ZM13.3333 11.6667C13.3333 12.5871 12.5871 13.3333 11.6667 13.3333C10.7462 13.3333 10 12.5871 10 11.6667C10 10.7462 10.7462 10 11.6667 10C12.5871 10 13.3333 10.7462 13.3333 11.6667Z"
      fill="currentColor"
    />
  </svg>
)

export default AdminHomeSvgIcon
