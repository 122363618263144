import React, { useState } from 'react'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import {
  cardBorder,
  cardBorderGrey,
  darkBlue,
  darkBlueFontColor,
  lightBlueFontColor,
  mainText,
  pageTypeOptionButton,
  pageTypeOptionButtonHovered,
  pageTypeOptionButtonSelected,
  selectableCardBackgroundNotSelected,
  selectableCardBackgroundSelected,
} from 'assets/styles/variables'
import { CATALOG_URL, PDP_URL } from 'features/project/utils'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { BarcodeOutlined } from '@ant-design/icons'
import styled from 'styled-components'

export type PDPListItem = {
  title: string
  reviewCount: number
  productCount?: number
  imageUrl?: string
  onSelect: (value: string) => void
  disabled?: boolean
}

const FocusListItem: React.FC<PDPListItem> = ({
  title,
  reviewCount,
  imageUrl,
  productCount,
  onSelect,
  disabled,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const urlParams = new URLSearchParams(window.location.search)

  const isSelected = urlParams.get('product') === title

  return (
    <>
      <a
        style={{
          textDecoration: 'none',
          color: mainText,
          cursor: disabled ? 'not-allowed' : 'pointer',
        }}
        href={`${window.location.origin}/project/${projectId}/${PDP_URL}?product=${title}`}
        onClick={(e) => {
          e.preventDefault()
          if (disabled) return
          mixpanel.track('pdp insights', {
            action: 'select product',
            value: title,
          })
        }}
      >
        <FocusItemContainer
          onClick={() => {
            onSelect(title)
          }}
          selected={isSelected}
        >
          <Content>
            {/*<ColorBar color={secondaryNavy} />*/}
            <FocusItemHeader>
              <h3>
                {title.length > 100 ? `${title.slice(0, 100)}...` : title}
              </h3>
              <Subheader>
                <div>
                  {productCount && productCount > 1 && (
                    <>
                      Items: {productCount.toLocaleString()}
                      <br />
                    </>
                  )}
                  Reviews & Ratings: {reviewCount.toLocaleString()}
                </div>
              </Subheader>
            </FocusItemHeader>
            {imageUrl && (
              <ImageWrapper>
                {imageUrl ? (
                  <img
                    src={imageUrl}
                    alt=""
                    style={{ height: 'auto', width: 'auto', maxHeight: 80 }}
                  />
                ) : (
                  <BarcodeOutlined style={{ fontSize: '60px' }} />
                )}
              </ImageWrapper>
            )}
          </Content>
        </FocusItemContainer>
      </a>
    </>
  )
}
export default FocusListItem

const Subheader = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  color: ${lightBlueFontColor};
  font-size: var(--font-size-md);
`

const FocusItemContainer = styled.div<{ selected: boolean }>`
  padding: 8px;

  background: ${({ selected }) =>
    selected ? darkBlue : selectableCardBackgroundNotSelected};

  color: ${({ selected }) => (selected ? 'white' : darkBlueFontColor)};

  cursor: pointer;

  border: 1px solid ${cardBorderGrey};
  border-radius: var(--border-radius);

  transition: transform ease 0.2s;
  &:hover {
    background: ${(props) =>
      props.selected ? darkBlue : selectableCardBackgroundSelected};
  }

  ${Subheader} {
    color: ${({ selected }) => (selected ? cardBorder : lightBlueFontColor)};
  }
`

const Content = styled.div`
  position: relative;

  width: 100%;

  display: flex;
  justify-content: space-between;

  padding-left: 8px;
`

const Options = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 10px;
`

const OptionButton = styled.div<{ selected: boolean }>`
  padding: 10px 15px;
  border-radius: 10px;
  font-size: 14px;
  background: ${({ selected }) =>
    selected ? pageTypeOptionButtonSelected : pageTypeOptionButton};
  color: ${({ selected }) => (selected ? lightBlueFontColor : 'white')};
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  gap: 10px;
  align-items: center;

  &:hover {
    background: ${({ selected }) =>
      selected ? pageTypeOptionButtonSelected : pageTypeOptionButtonHovered};
  }
`

const FocusItemHeader = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
`

const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  //margin-right: 10px;
  max-width: 50%;
  background: white;
  border-radius: 5px;
  overflow: hidden;
`
const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  //margin-right: 10px;
  max-width: 50%;
  //background: white;
  border-radius: 5px;
  overflow: hidden;
`
