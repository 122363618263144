import { getOutlierAnalysis, OutlierType } from '../model'
import { useMemo } from 'react'
import {
  createChartItemFromOutlierChart,
  getOutlierCharts,
  getOutlierFilterValues,
  getOutlierThemeFilterValues,
  getOutlierTitle,
} from '../utils'
import { Filters, ProjectState } from '../../../types'
import useThemes from '../../../hooks/useThemes'
import { NewDashboardPayload } from '../../dashboard/types'
import { useQuery } from 'react-query'
import { useProjectStore } from '../../../projectStore/projectStore'

type AutoInsightsResult = {
  outlierFilters: ReturnType<typeof getOutlierFilterValues>
  themeFilters: ReturnType<typeof getOutlierThemeFilterValues>
  convertOutlierToCustomDashboard: (isActive: boolean) => NewDashboardPayload
  getOutlierFilters: (
    outlier: OutlierType,
    filterValues: Filters,
  ) => ReturnType<typeof getOutlierFilterValues>
}

const useAutoInsights = (
  outlier: OutlierType,
  filterValues: Filters,
): AutoInsightsResult => {
  const { getClusterUUIDsFromThemeName } = useThemes()

  const outlierFilters = useMemo(
    () =>
      getOutlierFilterValues(
        outlier,
        filterValues,
        getClusterUUIDsFromThemeName,
      ),
    [filterValues, outlier, getClusterUUIDsFromThemeName],
  )

  const getOutlierFilters = (outlier: OutlierType, filterValues: Filters) => {
    return getOutlierFilterValues(
      outlier,
      filterValues,
      getClusterUUIDsFromThemeName,
    )
  }

  const themeFilters = useMemo(
    () =>
      getOutlierThemeFilterValues(
        outlier,
        filterValues,
        getClusterUUIDsFromThemeName,
      ),
    [filterValues, outlier, getClusterUUIDsFromThemeName],
  )

  const convertOutlierToCustomDashboard = (isActive: boolean) => {
    // Implementation goes here
    const charts = getOutlierCharts(outlier, outlierFilters, themeFilters)
    const { analysis } = outlier

    const items = charts.map((chart, index) =>
      createChartItemFromOutlierChart(chart.chartItem, index),
    )

    items.push({
      type: 'text',
      title: 'Analysis',
      content: analysis,
      layout: {
        h: 5,
        w: 12,
        minH: 4,
        minW: 4,
        i: items.length.toString(),
        x: 0,
        y: items.length * 5,
      },
      dashboardControls: {},
      filterValues: outlierFilters,
    })

    const createDashboardBody: NewDashboardPayload = {
      name: getOutlierTitle(outlier),
      dashboard_settings: {
        filters: filterValues,
        items: items,
        layout: items.map((item) => item.layout),
        isCurrent: false,
        extra_settings: {
          outlier: outlier,
          is_active: isActive,
        },
      },
      is_shared: false,
    }

    return createDashboardBody
  }

  return {
    outlierFilters,
    themeFilters,
    convertOutlierToCustomDashboard,
    getOutlierFilters,
  }
}

export default useAutoInsights
