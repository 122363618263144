// ViewAlert.tsx
import React, { useContext, useState } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import {
  fontSizeM,
  pageBackgroundGrey,
  secondaryNavy,
} from '../../../../../assets/styles/variables'
import _ from 'lodash'
import useResizeObserver from 'use-resize-observer'
import { conditionOptionsList } from '../constants'
import { AuthContext } from '../../../../auth'
import { AlertResponse } from '../model'
import { Loader } from '../../../../../shared/components'
import { GroupsOutlined, PersonOutlined } from '@mui/icons-material'
import Divider from '../../../../../components/UI/YogiDivider'
import { PanelBadges } from '../../filters/features/badge-panel/panel-badges'
import { Header } from './CreateAlert/CreateAlert.styles'
import pluralize from 'pluralize'
const { Title } = Typography
// const pluralize = require('pluralize')

type Props = {
  alertData?: AlertResponse
  setIsOpen: (isOpen: boolean) => void
  refetch: () => Promise<any>
}
const ViewAlert: React.FC<Props> = ({ alertData, setIsOpen, refetch }) => {
  const { alert, owners, viewers } = alertData || {}

  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const isCagUseStdDev = !!currentUserEmail?.includes('@conagrafoods.com')

  const { ref: headerRef, height: headerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()
  const { ref: footerRef, height: footerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()

  const condition = conditionOptionsList.find(
    (el) => el.value === alert?.trigger_type,
  )

  return (
    <Container>
      <Header ref={headerRef}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
          }}
        >
          <Title level={4}>{alert?.title}</Title>
          <Title
            level={4}
            onClick={() => {
              // TODO reset form if edit?
              setIsOpen(false)
            }}
            style={{ fontWeight: 600, cursor: 'pointer', marginTop: 0 }}
          >
            X
          </Title>
        </div>
      </Header>
      <Body headerHeight={headerHeight} footerHeight={footerHeight}>
        <div>
          <Section style={{ background: pageBackgroundGrey }}>
            <Title level={5}>
              {/* TODO lmao */}
              Send an Alert when {alert?.alert_type}{' '}
              {condition?.type === 'limit' ? (
                <>
                  {conditionOptionsList.find(
                    (el) => el.value === alert?.trigger_type,
                  )?.label ?? ''}{' '}
                  {alert?.compare_value}.
                </>
              ) : (
                <>
                  {conditionOptionsList.find(
                    (el) => el.value === alert?.trigger_type,
                  )?.label ?? 'has changed'}{' '}
                  {(isCagUseStdDev &&
                    `${
                      conditionOptionsList.find(
                        (el) => el.value === alert?.trigger_type,
                      )?.std_dev_label
                    } `) ??
                    ''}
                  in the last {alert?.time_interval?.toLowerCase()}.
                </>
              )}
            </Title>
            <PanelBadges
              filterValues={{
                values: alert?.filters ?? [],
                searchQuery: [],
                searchCondition: [],
              }}
              comparativeIndex={0}
              countThreshold={10}
              infoOnly
            />

            <div
              style={{
                display: 'flex',
                gap: '5px',
                flexWrap: 'wrap',
                marginTop: 10,
                fontSize: fontSizeM,
              }}
            >
              {alert?.search_terms.map((el) => (
                <div
                  style={{
                    padding: '2px 10px',
                    background: secondaryNavy,
                    color: 'white',
                    borderRadius: '10px',
                  }}
                >
                  {el}
                </div>
              ))}
              {!!alert?.search_terms.length &&
                alert.search_terms.length > 1 && (
                  <>
                    <div
                      style={{
                        padding: '2px ',
                      }}
                    >
                      Joined by
                    </div>
                    {alert?.search_criteria.map((el) => (
                      <div
                        style={{
                          padding: '2px 10px',
                          background: secondaryNavy,
                          color: 'white',
                          borderRadius: '10px',
                        }}
                      >
                        {el}
                      </div>
                    ))}
                  </>
                )}
            </div>
          </Section>
          <Section>
            <div>
              <Title
                level={5}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <PersonOutlined style={{ marginRight: 5 }} /> {owners?.length}{' '}
                {pluralize('Owners', owners?.length ?? 0)}
              </Title>
              <div>
                {owners?.map((owner) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {owner.email}
                      {owner.email === currentUserEmail ? ' (you)' : ''}
                    </div>
                  </div>
                ))}
              </div>
            </div>
            <Divider style={{ margin: '10px 0px' }} />
            <div>
              <Title
                level={5}
                style={{ display: 'flex', alignItems: 'center' }}
              >
                <GroupsOutlined style={{ marginRight: 5 }} /> {viewers?.length}{' '}
                {pluralize('Recipients', viewers?.length ?? 0)}
              </Title>
              <div>
                {viewers?.map((viewer) => (
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>
                      {viewer.email}
                      {viewer.email === currentUserEmail ? ' (you)' : ''}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </Section>
        </div>
      </Body>

      {!alert && (
        <Overlay>
          <Loader paddingTop={false} />
          Loading...
        </Overlay>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  //gap: 8px;
`

const Body = styled.div<{ headerHeight: number; footerHeight: number }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(
    100vh - ${(props) => props.headerHeight}px -
      ${(props) => props.footerHeight}px - 50px
  );
  padding-bottom: var(--default-padding);
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--default-padding-half) var(--default-padding);
  border-bottom: 1px solid #f2f2f2;
`

const Footer = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  margin: 0 var(--default-padding-half);
`

const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default ViewAlert
