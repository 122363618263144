import { axiosInstance, setSharedToken } from 'utils/axios'
import {
  SharedUsersResponse,
  ShareProjectWithUserPayload,
  ShareProjectWithUserResponse,
} from './types'

export async function fetchSharedUsers(
  proj_uuid: string
): Promise<SharedUsersResponse> {
  try {
    const { data } = await axiosInstance.get('/project/shared', {
      params: { proj_uuid },
    })
    return data
  } catch (error) {
    throw error
  }
}

export async function checkIfUserExists(email: string): Promise<boolean> {
  try {
    const { data } = await axiosInstance.get('/user/exists', {
      params: { email },
    })
    return data.user_exists
  } catch (error) {
    throw error
  }
}

export async function shareProjectWithUncheckedUser(
  proj_uuid: string,
  shared_email: string,
  admin: boolean
): Promise<boolean> {
  try {
    const userExists = await checkIfUserExists(shared_email)
    if (userExists) {
      await shareProjectWithUser(proj_uuid, {
        admin,
        shared_email,
        create_user: false,
      })
      return true
    }
    return false
  } catch (error) {
    throw error
  }
}

export async function shareProjectWithUser(
  proj_uuid: string,
  payload: ShareProjectWithUserPayload
): Promise<ShareProjectWithUserResponse> {
  try {
    const { data } = await axiosInstance.post('/project/share', payload, {
      params: { proj_uuid },
    })
    return data
  } catch (error) {
    throw error
  }
}

export async function unshareProjectWithUser(
  proj_uuid: string,
  shared_email: string
): Promise<void> {
  const { data } = await axiosInstance.delete('/project/unshare', {
    params: { proj_uuid, shared_email },
  })

  return data
}

export async function fetchUrlForSharing(
  proj_uuid: string,
  on: boolean
): Promise<string> {
  const { data } = await axiosInstance.get('/project/token', {
    params: { proj_uuid, on },
  })
  return data
}

export async function verifyAccess(
  proj_uuid: string,
  public_token: string
): Promise<boolean> {
  try {
    const { data } = await axiosInstance.get('/project/public', {
      params: { proj_uuid, public_token },
    })

    if (data) {
      setSharedToken(public_token)
      return true
    }
  } catch {}

  return false
}
