import postsEmpty from '../assets/images/empty/PostsEmpty.png'
import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
}
export const PostsEmpty: React.FC<Props> = ({ children }) => {
  return (
    <EmptyContainer>
      <img
        src={postsEmpty}
        style={{ maxWidth: '150px', margin: '0 auto 20px' }}
      />
      <Paragraph>{children}</Paragraph>
    </EmptyContainer>
  )
}

const EmptyContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 40px;
`

const Paragraph = styled.p`
  /* Regular Paragraph */

  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 140%;
  /* identical to box height, or 20px */

  display: flex;
  align-items: center;

  /* Primary Color */

  color: #28364f;
`
