import styled from 'styled-components'
import {
  darkBlueFontColor,
  lightBlueFontColor,
  selectableCardBackgroundSelected,
} from '../../../../../../../../assets/styles/variables'

export const Container = styled.div``

export const Body = styled.div`
  display: flex;
  gap: 40px;
  margin-bottom: 10px;
`

export const Sections = styled.div`
  display: flex;
  flex-direction: column;
  width: 220px;
`

export const Section = styled.div<{ isSelected: boolean }>`
  background-color: ${({ isSelected }) =>
    isSelected ? selectableCardBackgroundSelected : 'transparent'};
  color: ${({ isSelected }) => (isSelected ? '#fff' : darkBlueFontColor)};
  padding: 10px 16px;
  border-radius: 10px;
  cursor: pointer;

  &:hover {
    color: ${({ isSelected }) => (isSelected ? '#fff' : lightBlueFontColor)};
    cursor: pointer;
  }

  transition: all 0.2s;
`

export const Content = styled.div`
  padding-top: 10px;
  flex-basis: 75%;
  flex-grow: 1;
  max-width: 1000px;
  //margin: 0 auto;
  font-size: 16px;
`

export const Analysis = styled.div`
  color: #28364f; // ${darkBlueFontColor};
  line-height: 30px;
`
