import React, { useState } from 'react'
import { OutlierType } from '../../../../model'
import {
  Analysis,
  Body,
  Container,
  Content,
  Section,
  Sections,
} from './InsightAnalysis.styles'
import { Filters, ProjectState } from '../../../../../../types'
import Markdown from 'marked-react'
import SemanticKeywords from '../../../../../../../../components/SemanticKeywords/SemanticKeywords'
import SemanticReviewCarousel from '../../../../../../../../components/SemanticReviewCarousel/SemanticReviewCarousel'
import { LogoSpinnerSmall } from 'components/Loading/LogoSpinner/LogoSpinnerSmall'
import InsightReviews from './InsightReviews/InsightReviews'
import { useFeatureFlags } from '../../../../../../hooks/useFeatureFlags'
import YogiInfo from '../../../../../../../../components/UI/YogiInfo/YogiInfo'
import { lightBlueFontColor } from '../../../../../../../../assets/styles/variables'
import useAutoInsights from '../../../../hooks/useAutoInsights'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { emptyFilters } from 'features/project/utils'
import { useQuery } from 'react-query'
import { getFeedback } from 'features/project/features/feedback'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { LoadingOutlined } from '@ant-design/icons'

interface Props {
  outlier: OutlierType
  filterValues: Filters
}

const InsightAnalysis: React.FC<Props> = ({ outlier, filterValues }) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [analysisBodyIndex, setAnalysisBodyIndex] = useState(-1)
  const [page, setPage] = useState<number>(1)

  // hooks
  const { data: feature_flags } = useFeatureFlags()

  // consts needed for api calls
  const model = 'gemini-1.5-flash'
  // const reviews = outlier.data.map((el) => el.body).filter(Boolean)
  // const prompt = constructThemeOutlierPrompt(outlier)

  // api
  const { analysis: parsedAnalysis } = outlier
  const { outlierFilters } = useAutoInsights(outlier, {
    values: outlier.filter.criteria,
    searchQuery: outlier.filter.search_terms,
    searchCondition: outlier.filter.search_criteria,
  })

  // const needed for api
  const order = 'desc'
  const orderField = 'create_time'

  // api
  const {
    data: postData,
    isFetching: postsFetching,
    isLoading: postsLoading,
  } = useQuery(
    [
      `feedback-posts_`,
      projectId,
      outlierFilters.values,
      outlierFilters.searchQuery,
      outlierFilters.searchCondition,
      order,
      orderField,
      page,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
          page,
          order,
          order_field: orderField,
          page_size:
            // one project had a LOT of dupes - this was a hackaround
            projectId === 'ca805d9d-b814-47dd-bdbd-74b1737eb9d4' ? 120 : 24,
          // projectId === '5ecaef81-57d1-4d5d-935b-e7e7375d141e' ? 120 : 24,
        },
        body: {
          criteria: outlierFilters.values,
          search_terms: outlierFilters.searchQuery,
          search_criteria: outlierFilters.searchCondition,
        },
      }
      const { data } = await getFeedback(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  return (
    <Container>
      <Body>
        <Sections>
          <Section
            isSelected={analysisBodyIndex === -1}
            onClick={() => {
              setAnalysisBodyIndex(-1)
              mixpanel.track('automated insights', {
                action: 'analysis section',
                type: 'outlier summary',
              })
            }}
          >
            Outlier Summary
          </Section>
          {parsedAnalysis?.body.map((section, index) => (
            <Section
              key={index}
              isSelected={analysisBodyIndex === index}
              onClick={() => {
                setAnalysisBodyIndex(index)
                mixpanel.track('automated insights', {
                  action: 'analysis section',
                  type: index,
                })
              }}
            >
              <div>{section.title}</div>
            </Section>
          ))}
          <Section
            isSelected={analysisBodyIndex === -2}
            onClick={() => {
              setAnalysisBodyIndex(-2)

              mixpanel.track('automated insights', {
                action: 'analysis section',
                type: 'reviews',
              })
            }}
          >
            Reviews {postData ? `(${postData?.n_posts})` : <LoadingOutlined />}
          </Section>
        </Sections>
        <Content style={{ paddingTop: analysisBodyIndex === -1 ? 0 : 10 }}>
          <>
            {parsedAnalysis ? (
              <>
                <Analysis
                  key={-1}
                  style={{
                    display: analysisBodyIndex === -1 ? 'block' : 'none',
                  }}
                >
                  {/*<h2>{parsedAnalysis.header}</h2>*/}
                  <SemanticKeywords
                    body={parsedAnalysis?.subheader}
                    keywords={parsedAnalysis?.key_phrases}
                    brands={
                      outlier.context_with_value?.context === 'brand'
                        ? [outlier.context_with_value.value]
                        : []
                    }
                    products={
                      outlier.context_with_value?.context === 'product'
                        ? [outlier.context_with_value.value]
                        : []
                    }
                    themes={outlier.theme ? [outlier.theme] : []}
                    filterValues={outlierFilters}
                  />
                </Analysis>
                {parsedAnalysis.body.map((section, index) => (
                  <Analysis
                    key={index}
                    style={{
                      display: analysisBodyIndex === index ? 'block' : 'none',
                    }}
                  >
                    <Markdown>{section.explanation}</Markdown>
                    {feature_flags?.semantic_search_proj ? (
                      <SemanticReviewCarousel
                        phrase={section.title}
                        brands={
                          outlier.context_with_value?.context === 'brand'
                            ? [outlier.context_with_value.value]
                            : []
                        }
                        products={
                          outlier.context_with_value?.context === 'product'
                            ? [outlier.context_with_value.value]
                            : []
                        }
                        themes={outlier.theme ? [outlier.theme] : []}
                        style={{ marginBottom: '20px' }}
                      />
                    ) : (
                      <YogiInfo
                        color={lightBlueFontColor}
                        style={{ marginBottom: 15 }}
                      >
                        <div>"{section.evidence}"</div>
                      </YogiInfo>
                    )}
                    <Markdown>{section.implication}</Markdown>
                  </Analysis>
                ))}
              </>
            ) : (
              <>
                {analysisBodyIndex !== -2 && (
                  <LogoSpinnerSmall>
                    <p>Loading Analysis...</p>
                  </LogoSpinnerSmall>
                )}
              </>
            )}
          </>
          <Analysis
            key={-2}
            style={{
              display: analysisBodyIndex === -2 ? 'block' : 'none',
            }}
          >
            <InsightReviews
              outlier={outlier}
              filterValues={outlierFilters}
              postData={postData}
              page={page}
              setPage={setPage}
              postsLoading={postsLoading}
              postsFetching={postsFetching}
            />
          </Analysis>
        </Content>
      </Body>
    </Container>
  )
}

export default InsightAnalysis
