import React, { useCallback, useState } from 'react'
import styled from 'styled-components'
import { Button } from 'antd'
import { PostsGrid, PostDetailModal } from 'features/project/features/feedback'
import { Loader } from 'shared/components'
import { usePhraseList } from './hooks/use-phrase-list'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { YogiButton } from 'components/UI/YogiButton'

type Props = {
  phrase: string
  useKeywordsSearch?: boolean
  showHighlights?: boolean
  comparativeIndex: number
}

export const PhraseList: React.FC<Props> = ({
  phrase,
  useKeywordsSearch = false,
  showHighlights = true,
  comparativeIndex,
}) => {
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const updateKeywordsControls = useProjectStore(
    (state: ProjectState) => state.updateKeywordsControls,
  )
  const [postId, setPostId] = useState<string>()
  const { posts, isLoading, hasMore, loadMore } = usePhraseList(
    phrase,
    useKeywordsSearch,
    comparativeIndex,
  )

  const onClosePostDetailModal = useCallback(() => {
    setPostId(undefined)
  }, [])

  const dedupedPosts = posts.filter(
    (value, index, self) =>
      index ===
      self.findIndex((t) => t.title === value.title && t.body === value.body),
  )

  return (
    <Wrapper>
      <PageGrid
        style={{
          flexDirection: isComparative ? 'column' : 'row',
          // height: isComparative ? 'calc(100vh - 287px)' : '100%',
          // overflowY: isComparative ? 'auto' : 'hidden',
        }}
      >
        <PostsWrapper
          style={{
            order: isComparative ? 1 : 0,
            paddingRight: isComparative ? 0 : 'var(--default-padding-half)',
          }}
        >
          <PostsGrid
            posts={dedupedPosts}
            onPostClick={(postId: string) => setPostId(postId)}
            showHighlights={showHighlights}
            isLoading={isLoading}
          />

          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              padding: 20,
              gap: 20,
            }}
          >
            <Button
              onClick={() =>
                updateKeywordsControls(comparativeIndex, 'currentPhrase', null)
              }
            >
              Back
            </Button>
            {hasMore && (
              <StyledButton onClick={loadMore} disabled={isLoading}>
                {isLoading ? 'Loading' : 'Load more'}
              </StyledButton>
            )}
          </div>
          {isLoading && <Loader />}
        </PostsWrapper>
      </PageGrid>
      <PostDetailModal
        postUuid={postId}
        fragment={false}
        onClose={onClosePostDetailModal}
      />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-width: 100%;
  width: 100%;
  height: 100%;
  padding: 10px;
`
const StyledButton = styled(Button)`
  display: block;
  //margin: 20px auto;
`
const PageGrid = styled.div`
  display: flex;
  width: 100%;
  overflow: auto;
  height: 100%;
`
const PostsWrapper = styled.div`
  width: 100%;
  flex: 1;
  margin: 0 auto;
  //max-height: calc(100vh - 287px);
  height: 100%;
  overflow-y: scroll;
`
