export const removeAtIndex = (arr: any[], idx: number) => [
  ...arr.slice(0, idx),
  ...arr.slice(idx + 1),
]

export const mergeObjects = (source: any, target: any) => {
  for (let key in source) {
    if (!target[key]) {
      target[key] = source[key]
    }
  }
  return target
}
