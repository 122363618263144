import React from 'react'
import styled from 'styled-components'
import { Source } from '../types'
import { SourceIcon as Icon } from 'shared/components'
import mixpanel from 'features/trackers/mixpanel'

type Props = {
  source: Source
}

export const SourceIcon: React.FC<Props> = ({ source }) => {
  const iconStyle = {
    width: '1.5rem',
    height: '1.5rem',
  }

  if (!source.source_url) {
    return <></>
  }

  return (
    <a
      href={source.source_url}
      target={'_blank'}
      rel={'noopener noreferrer'}
      onClick={(event) => {
        mixpanel.track('product data', {
          action: 'source card',
          value: source.source_url,
        })
        event.stopPropagation()
        return source.source_type !== 'File' && !source.syndicated_source
          ? openInNewTab(source.source_url)
          : null
      }}
    >
      <SourceBox>
        <SourceContent>
          <SourceLogo>
            <Icon
              source={
                source.syndicated_source ? 'syndicated' : source.source_type
              }
              style={iconStyle}
            />
          </SourceLogo>
        </SourceContent>
      </SourceBox>
    </a>
  )
}

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const SourceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 auto;
  width: 128px;
`

const SourceLogo = styled.div`
  color: black;
  height: 1.5em;
`

const SourceBox = styled.div`
  display: flex;
  width: 50px;
  height: 50px;
  margin: 10px;
  padding: 1px;
  flex: 1 0 21%;
  background: #fff;
  box-size: border-box;
  box-shadow: var(--default-box-shadow);
  color: black;
  transition: transform ease 0.1s;
  transform: scale(1);
  border-radius: var(--border-radius);
  cursor: pointer;

  &:hover {
    text-decoration: none;
    transform: scale(1.05);
    color: black;
  }
`
