// AlertList.tsx
import React, { useContext, useEffect, useState } from 'react'
import { useQuery, useQueryClient } from 'react-query'
import { Grid } from '@mui/material'
import {
  MoreOutlined,
  TableOutlined,
  UnorderedListOutlined,
} from '@ant-design/icons'
import { Button, Dropdown, Menu, Popconfirm, Radio, Select } from 'antd'
import styled from 'styled-components'
import { mainText, secondaryNavy } from 'assets/styles/variables'
import { AlertDrawer } from './Components/AlertDrawer'
import { YogiSearch } from 'components/UI/YogiSearch'
import { YogiButton } from 'components/UI/YogiButton'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import {
  AlertResponse,
  disableAlert,
  enableAlert,
  getAlerts,
  saveAlert,
  testAlert,
  unsubscribeAlert,
  updateAlert,
} from './model'
import AlertCard from './Components/AlertCard'
import Divider from '../../../../components/UI/YogiDivider'
import { ListHeader } from './constants'
import { Fetcher } from 'shared/components/fetcher'
import { AuthContext } from '../../../auth'
import { toast } from 'react-toastify'
import useResizeObserver from 'use-resize-observer'
import { PostsEmpty } from 'components/PostsEmpty'
import { NotificationsResponse } from '../notifications/model'
import { Loader } from 'shared/components'
import mixpanel from '../../../trackers/mixpanel'

const { Option } = Select

interface AlertActionMenuProps {
  onDuplicate: (id: number) => void
  onDelete: (id: number, isOwner: boolean) => void
  onArchive: (id: number) => void
  onShare: (id: number) => void
  alertId: number
  onEdit: (id: number) => void
  isOwner: boolean
}

const AlertActionMenu: React.FC<AlertActionMenuProps> = ({
  onDuplicate,
  onDelete,
  onShare,
  onArchive,
  alertId,
  onEdit,
  isOwner,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const [visible, setVisible] = useState(false)

  const handleVisibleChange = (flag: boolean) => {
    setVisible(flag)
  }

  const handleClick = ({ key }: { key: string }) => {
    switch (key) {
      case 'edit':
        onEdit(alertId)
        break
      case 'duplicate':
        onDuplicate(alertId)
        break
      case 'share':
        onShare(alertId)
        break
      case 'archive':
        onArchive(alertId)
        break
      case 'delete':
        // todo show spinner
        onDelete(alertId, isOwner)
        break
      case 'email':
        // todo show spinner
        testAlert(projectId, alertId)
          .then(() => {
            toast.success('Test email sent')
          })
          .catch(() => {
            toast.error('Failed to send test email')
          })
        break
      case 'test':
        // todo show spinner
        testAlert(projectId, alertId)
          .then(() => {
            toast.success('Test email sent')
          })
          .catch(() => {
            toast.error('Failed to send test email')
          })
        break
      default:
        break
    }
    setVisible(false)
  }

  const menu = (
    <Menu
      onClick={({ key, domEvent }) => {
        domEvent.stopPropagation()
        handleClick({ key })
      }}
    >
      {isOwner && <Menu.Item key="edit">Edit</Menu.Item>}
      <Menu.Item key="share">Share</Menu.Item>
      <Menu.Item key="duplicate">Duplicate</Menu.Item>

      <Popconfirm
        title={`Are you sure you want to ${
          isOwner ? 'delete' : 'unsubscribe from'
        } this alert?`}
        okText="Yes"
        cancelText="No"
        onConfirm={() => handleClick({ key: 'delete' })}
      >
        <Menu.Item key="delete">{isOwner ? 'Delete' : 'Unsubscribe'}</Menu.Item>
      </Popconfirm>
      {/*<Menu.Item key="email">Test Email</Menu.Item>*/}
      {/*<Menu.Item key="test">Trigger Alert</Menu.Item>*/}
    </Menu>
  )

  return (
    <Dropdown
      overlay={menu}
      trigger={['click']}
      visible={visible}
      onVisibleChange={handleVisibleChange}
      // @ts-ignore
      onClick={(e) => e.stopPropagation()}
    >
      <AlertActionIcon />
    </Dropdown>
  )
}

type AlertProps = {
  alertData: AlertResponse
  toggleActive: (id: number, active: boolean) => void
  onDuplicate: (id: number) => void
  onShare: (id: number) => void
  onDelete: (id: number, isOwner: boolean) => void
  onArchive: (id: number) => void
  onSelect: (id: number) => void
  onEdit: (id: number) => void
  listType: 'list' | 'grid'
  notifications: NotificationsResponse[] | undefined
  markAsViewed: (id: number) => void
}

const Alert: React.FC<AlertProps> = ({
  alertData,
  toggleActive,
  onDuplicate,
  onShare,
  onDelete,
  onArchive,
  onSelect,
  onEdit,
  listType,
  notifications,
  markAsViewed,
}) => {
  const { alert } = alertData
  const authContext = useContext(AuthContext)

  return (
    <AlertCard
      alertData={alertData}
      toggleActive={toggleActive}
      onSelect={onSelect}
      listType={listType}
      notifications={notifications}
      markAsViewed={markAsViewed}
      actions={
        <AlertActionMenu
          {...{ onDuplicate, onDelete, onArchive, onShare }}
          alertId={alert.id}
          onEdit={onEdit}
          isOwner={
            authContext.user &&
            alertData.owners
              .map((el) => el.email)
              .includes(authContext?.user?.attributes?.email)
          }
        />
      }
    />
  )
}

type Props = {
  useNotifications: any
}

const Alerts: React.FC<Props> = ({ useNotifications }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const isAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isAlertDrawerOpen,
  )
  const setIsAlertDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsAlertDrawerOpen,
  )
  const setAlertRequest = useProjectStore(
    (state: ProjectState) => state.setAlertRequest,
  )

  const { notifications, markAsViewed } = useNotifications()
  const queryClient = useQueryClient()

  const [sortField, setSortField] = useState<'title' | 'date' | 'active'>(
    'date',
  )
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')

  const [searchTerm, setSearchTerm] = useState('')
  const [category, setCategory] = useState('')
  const [drawerType, setDrawerType] = useState('')
  const [selectedAlert, setSelectedAlert] = useState<null | number>(null)
  const [listType, setListType] = useState<'list' | 'grid'>('list')

  const [isUpdating, setIsUpdating] = useState<boolean>(false)

  const onSearch = (e: any) => {
    setSearchTerm(e.target.value)
  }
  const { data, refetch, isLoading } = useQuery(
    ['alerts', projectId],
    () => {
      return getAlerts(projectId)
    },
    {
      refetchOnWindowFocus: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const [hasLoadedNotifications, setHasLoadedNotifications] = useState(false)

  const { isLoading: isLoadingNotfications } = useNotifications()

  useEffect(() => {
    if (!isLoadingNotfications && !hasLoadedNotifications) {
      setHasLoadedNotifications(true)
    }
  }, [isLoadingNotfications])

  const sortedData = React.useMemo(() => {
    let sortData = data ? [...data] : []

    sortData.sort(({ alert: alertA }, { alert: alertB }) => {
      let result

      if (sortField === 'title') {
        result = alertB.title.localeCompare(alertA.title)
      } else if (sortField === 'date') {
        result =
          new Date(alertA.created_dt).getTime() -
          new Date(alertB.created_dt).getTime()
      } else {
        result =
          (alertA.status === 'Live' ? 1 : 0) -
          (alertB.status === 'Live' ? 1 : 0)
      }
      return sortOrder === 'asc' ? result : -result
    })

    if (searchTerm) {
      sortData = sortData.filter(({ alert }) =>
        alert.title.toLowerCase().includes(searchTerm.toLowerCase()),
      )
    }

    if (category) {
      sortData = sortData.filter(({ alert }) => alert.status === category)
    }

    return sortData
  }, [data, sortField, sortOrder, searchTerm, category])

  useEffect(() => {
    if (!isAlertDrawerOpen) {
      setDrawerType('')
    }
  }, [isAlertDrawerOpen])

  const handleShare = (id: number) => {
    setIsAlertDrawerOpen(true)
    setSelectedAlert(id)
    setDrawerType('share')
  }

  const handleDuplicate = async (id: number) => {
    mixpanel.track('alerts', {
      action: 'duplicate',
      value: id,
      proj_uuid: projectId,
    })
    const alert = data?.find(({ alert }) => alert.id === id)?.alert
    if (alert) {
      try {
        setIsUpdating(true)
        const alertCopy = JSON.parse(JSON.stringify(alert))
        delete alertCopy.id
        alertCopy.title = `${alertCopy.title} (Copy)`
        const newAlert = await saveAlert(projectId, alertCopy)
        queryClient.setQueryData(
          ['alerts', projectId],
          (oldData: AlertResponse[] | undefined) => {
            if (!oldData) return [newAlert]
            return [...oldData, newAlert]
          },
        )
        toast.success(`Alert duplicated`)
        setIsUpdating(false)
      } catch (e) {
        toast.error(`Error duplicating alert`)
        setIsUpdating(false)
      }
    }
  }

  const handleDelete = async (id: number, isOwner: boolean) => {
    mixpanel.track('alerts', {
      action: 'delete',
      value: id,
      proj_uuid: projectId,
    })
    const alert = data?.find(({ alert }) => alert.id === id)?.alert
    if (alert) {
      setIsUpdating(true)
      if (isOwner) {
        await updateAlert(projectId, { ...alert, status: 'Deleted' })
      } else {
        await unsubscribeAlert(projectId, alert.id)
      }
      queryClient.setQueryData(
        ['alerts', projectId],
        (oldData: AlertResponse[] | undefined) => {
          if (!oldData) return []

          const filteredData = oldData.filter((alert) => {
            return alert.alert.id !== id
          })
          return [...filteredData]
        },
      )
      setIsUpdating(false)
    }
  }

  const handleArchive = async (id: number) => {
    const alert = data?.find(({ alert }) => alert.id === id)?.alert
    if (alert) {
      setIsUpdating(true)
      await updateAlert(projectId, { ...alert, status: 'Archived' })
      await refetch()
      setIsUpdating(false)
    }
  }

  const handleToggleActive = async (id: number, active: boolean) => {
    mixpanel.track('alerts', {
      action: 'toggle',
      value: active,
      id: id,
      proj_uuid: projectId,
    })
    const alertData = data?.find((alertData) => alertData.alert.id === id)
    if (alertData) {
      try {
        // setIsUpdating(true)
        if (active) {
          await disableAlert(projectId, alertData.alert.id)
        } else {
          await enableAlert(projectId, alertData.alert.id)
        }
        queryClient.setQueryData(
          ['alerts', projectId],
          (oldData: AlertResponse[] | undefined) => {
            if (!oldData) return []

            const filteredData = oldData.filter((alert) => {
              return alert.alert.id !== id
            })
            const selectedAlert = oldData.find((alert) => {
              return alert.alert.id === id
            })
            if (selectedAlert) {
              return [
                ...filteredData,
                {
                  ...selectedAlert,
                  is_enabled: !active,
                },
              ]
            }
            return [...filteredData]
          },
        )
        toast.success(`Alert ${active ? 'disabled' : 'enabled'}`)
        setIsUpdating(false)
      } catch (e) {
        toast.error(
          `Error ${alertData.is_enabled ? 'disabling' : 'enabling'} alert`,
        )
        // setIsUpdating(false)
      }
    }
  }

  const handleSelect = async (id: number) => {
    // mixpanel is handled on card
    setSelectedAlert(id)
    setIsAlertDrawerOpen(true)
    setDrawerType('view')
  }

  const handleEdit = async (id: number) => {
    mixpanel.track('alerts', {
      action: 'edit',
      type: 'open',
      value: id,
      proj_uuid: projectId,
    })
    setSelectedAlert(id)
    setIsAlertDrawerOpen(true)
    setDrawerType('')
  }

  const { ref: headerRef, height: headerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()

  if (isLoading || !hasLoadedNotifications) {
    return (
      <>
        <Loader />
        <LoaderTitle>{`Loading Alerts...`}</LoaderTitle>
      </>
    )
  }

  return (
    <Container>
      {/*<Header ref={headerRef}>*/}
      {/*  <h1 style={{ margin: 0 }}>Alerts</h1>*/}
      {/*  <YogiButton*/}
      {/*    type="primary"*/}
      {/*    onClick={() => {*/}
      {/*      setSelectedAlert(null)*/}
      {/*      setIsAlertDrawerOpen(true)*/}
      {/*      setAlertRequest(undefined)*/}
      {/*      setDrawerType('')*/}
      {/*    }}*/}
      {/*  >*/}
      {/*    Create Alert*/}
      {/*  </YogiButton>*/}
      {/*</Header>*/}
      <Body headerHeight={headerHeight}>
        <Controls>
          <FilterControls>
            <SearchContainer>
              <YogiSearch onChange={onSearch} />
            </SearchContainer>

            {/*<Radio.Group*/}
            {/*  value={listType}*/}
            {/*  onChange={(e) => {*/}
            {/*    mixpanel.track('alerts', {*/}
            {/*      action: 'list type',*/}
            {/*      value: e.target.value,*/}
            {/*      proj_uuid: projectId,*/}
            {/*    })*/}
            {/*    setListType(e.target.value)*/}
            {/*  }}*/}
            {/*  buttonStyle="solid"*/}
            {/*  // @ts-ignore // no idea why this doesnt take textWrap /shrug*/}
            {/*  style={{ textWrap: 'nowrap' }}*/}
            {/*>*/}
            {/*  <Radio.Button value={'list'}>*/}
            {/*    <UnorderedListOutlined />*/}
            {/*  </Radio.Button>*/}
            {/*  <Radio.Button value={'grid'}>*/}
            {/*    <TableOutlined />*/}
            {/*  </Radio.Button>*/}
            {/*</Radio.Group>*/}

            <YogiButton
              type="primary"
              onClick={() => {
                setSelectedAlert(null)
                setIsAlertDrawerOpen(true)
                setAlertRequest(undefined)
                setDrawerType('')
              }}
            >
              Create Alert
            </YogiButton>
          </FilterControls>
          <SortControls>
            <label>
              {/*Sort by:&nbsp;*/}
              <Select
                value={sortField}
                onChange={(value) => {
                  mixpanel.track('alerts', {
                    action: 'sort type',
                    value: value,
                    proj_uuid: projectId,
                  })
                  setSortField(value)
                }}
                style={{ borderRadius: 10 }}
              >
                <Option value="title">Title</Option>
                <Option value="date">Date</Option>
                {/*<Option value="active">Active</Option>*/}
              </Select>
            </label>
            {sortOrder === 'asc' ? (
              <StyledButton
                id="feedback-sort-button"
                type="dashed"
                onClick={() => {
                  mixpanel.track('alerts', {
                    action: 'sort direction',
                    value: 'desc',
                    proj_uuid: projectId,
                  })
                  setSortOrder('desc')
                }}
              >
                ↑
              </StyledButton>
            ) : (
              <StyledButton
                id="feedback-sort-button"
                type="dashed"
                onClick={() => {
                  mixpanel.track('alerts', {
                    action: 'sort direction',
                    value: 'asc',
                    proj_uuid: projectId,
                  })
                  setSortOrder('asc')
                }}
              >
                ↓
              </StyledButton>
            )}
          </SortControls>
        </Controls>
        <Content>
          {!data?.length ? (
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                width: '100%',
              }}
            >
              <PostsEmpty>No Alerts...yet!</PostsEmpty>

              <YogiButton
                type="primary"
                onClick={() => {
                  mixpanel.track('alerts', {
                    action: 'create',
                    type: 'open',
                    proj_uuid: projectId,
                  })
                  setSelectedAlert(null)
                  setIsAlertDrawerOpen(true)
                  setAlertRequest(undefined)
                  setDrawerType('')
                }}
              >
                Create Alert
              </YogiButton>
            </div>
          ) : (
            <Grid container spacing={3}>
              {listType === 'list' && <ListHeader />}
              {listType === 'list' && (
                <Divider style={{ marginLeft: 24, marginTop: 5 }} />
              )}
              {sortedData?.map((alertData) => (
                <Grid
                  item
                  xs={12}
                  sm={listType === 'list' ? 12 : 12}
                  md={listType === 'list' ? 12 : 6}
                  lg={listType === 'list' ? 12 : 4}
                  xl={listType === 'list' ? 12 : 3}
                  key={alertData.alert.id}
                  spacing={2}
                >
                  <Alert
                    alertData={alertData}
                    toggleActive={handleToggleActive}
                    onDuplicate={handleDuplicate}
                    onShare={handleShare}
                    onArchive={handleArchive}
                    onDelete={handleDelete}
                    onSelect={handleSelect}
                    onEdit={handleEdit}
                    listType={listType}
                    notifications={notifications}
                    markAsViewed={markAsViewed}
                  />
                </Grid>
              ))}
            </Grid>
          )}
        </Content>
      </Body>
      <AlertDrawer
        alertData={data?.find((el) => el.alert.id === selectedAlert)}
        isShare={drawerType === 'share'}
        isView={drawerType === 'view'}
      />
      {isUpdating && (
        <Overlay>
          <Fetcher paddingTop={'300px'} />
        </Overlay>
      )}
    </Container>
  )
}

export default Alerts

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-height: calc(100vh - 40px);
  //overflow: hidden;
`

const Header = styled.div`
  background: white;
  padding: var(--default-padding-half) var(--default-padding);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const Body = styled.div<{ headerHeight?: number }>`
  //background: white;
  height: calc(100% - ${(props) => props.headerHeight}px - 40px);
  overflow: auto;
`

const Controls = styled.div`
  margin: 10px 0px;
  padding: var(--default-padding-half) var(--default-padding);
`

const FilterControls = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
  gap: 40px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
`

const SortControls = styled.div`
  display: flex;
  flex-direction: row;
  gap: 5px;
  width: 100%;
`
const SearchContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-basis: 30%;
  width: 100%;
`

const PillContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  min-height: 50px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`

const Pill = styled.button<{ selected: boolean }>`
  display: inline-block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 2px 15px;
  border-radius: 20px;
  background: ${(props) => (props.selected ? secondaryNavy : 'white')};
  color: ${(props) => (props.selected ? 'white' : mainText)};
  border: ${(props) =>
    props.selected ? '1px solid ' + secondaryNavy : '1px solid #CDD3DD'};
  cursor: pointer;
  transform: translate(0%);
  transition: 0.15s ease-out;

  &:hover {
    background: ${() => secondaryNavy};
    color: white;
    border: ${() => '1px solid ' + secondaryNavy};
  }
`

const VerticalDivider = styled.div`
  height: 100%;
  border: 2px solid rgba(159, 183, 195, 0.15);
`

const Content = styled.div`
  padding: var(--default-padding-half) var(--default-padding);
`

const AlertActionIcon = styled(MoreOutlined)`
  font-size: 24px;
  cursor: pointer;
`

const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 0%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`
const StyledButton = styled(Button)`
  background: white !important;
  color: var(--main-text);
  border: 2px solid var(--card-border-grey);
  border-radius: 8px;
  height: 34px;
  &:focus {
    border: 2px solid var(--card-border-grey);
    background: white;
    color: var(--main-text);
  }
  &:hover {
    color: var(--button-blue);
    border: 2px solid var(--button-blue);
    background: white;
  }
  box-shadow: none;
`

const LoaderTitle = styled.div`
  //margin-top: var(--default-padding);
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`
