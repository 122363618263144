import { axiosInstance } from 'utils/axios'
import {
  Product,
  ProductHierarchyList,
  ProductHierarchyInfo,
  ProductGroupList,
  ProductTransferEntity,
} from './types'
import {
  PRODUCT_HIERARCHY,
  PRODUCT_HIERARCHY_INFO,
  PRODUCT_PROJECT_INFO,
  PRODUCT_PROJECT_HIERARCHIES,
  PRODUCT_LIST,
  PRODUCT_GROUPS,
  PRODUCT_GROUP_PRODUCTS,
  PRODUCT_HIERARCHY_PRODUCTS,
} from 'utils/apiLinks'
import { TransferEntities, TransferEntityNames } from './helpers'

/**
 *
 * @param key Key for the react-query
 * @param proj_uuid Project UUID
 */
export const getProductHierarchy = async (
  key: string,
  proj_uuid: string
): Promise<ProductHierarchyList> => {
  const { data } = await axiosInstance.get(PRODUCT_HIERARCHY, {
    params: {
      proj_uuid: proj_uuid,
    },
  })
  return data
}
/**
 *
 * @param key Key for react-query
 * @param proj_uuid Project Uuid
 * @param hierarchy Hierachy Name
 */
export const getProjectHierarchyInfo = async (
  key: string,
  proj_uuid: string,
  hierarchy: string
): Promise<ProductHierarchyInfo> => {
  const { data } = await axiosInstance.get(PRODUCT_HIERARCHY_INFO, {
    params: {
      proj_uuid: proj_uuid,
      hierarchy: hierarchy,
    },
  })
  return data
}

export const getProductHierarchyAll = async (
  key: string,
  proj_uuid: string
): Promise<ProductHierarchyInfo[]> => {
  const { data } = await axiosInstance.get<ProductHierarchyInfo[]>(
    PRODUCT_PROJECT_INFO,
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}

export const getProductHierarchyAllOld = async (
  key: string,
  proj_uuid: string
): Promise<ProductHierarchyInfo[]> => {
  const { data: hieararchyNames } = await axiosInstance.get<
    ProductHierarchyList
  >(PRODUCT_HIERARCHY, {
    params: {
      proj_uuid: proj_uuid,
    },
  })
  let data: ProductHierarchyInfo[] = []
  await Promise.all(
    hieararchyNames.product_hierarchies.map((obj) =>
      axiosInstance
        .get<ProductHierarchyInfo>(PRODUCT_HIERARCHY_INFO, {
          params: {
            proj_uuid: proj_uuid,
            hierarchy: obj,
          },
        })
        .then((response) => {
          data.push(response.data)
        })
    )
  )
  return data
}

export const getProductHierarchiesForProject = async (
  key: string,
  proj_uuid: string
): Promise<ProductHierarchyInfo[]> => {
  const { data } = await axiosInstance.get(PRODUCT_PROJECT_HIERARCHIES, {
    params: {
      proj_uuid: proj_uuid,
    },
  })
  return data
}

export const getProductsForHierarchy = async (
  key: string,
  proj_uuid: string,
  hierarchy: string
): Promise<Product[]> => {
  const { data } = await axiosInstance.get(PRODUCT_LIST, {
    params: {
      proj_uuid: proj_uuid,
      hierarchy: hierarchy,
    },
  })
  return data
}

export async function fetchProductGroups(
  proj_uuid: string
): Promise<ProductGroupList> {
  const { data } = await axiosInstance.get(PRODUCT_GROUPS, {
    params: { proj_uuid },
  })
  return data
}

export async function fetchDataForProductEditing(
  proj_uuid: string
): Promise<ProductHierarchyList & ProductGroupList> {
  const [hierarchies, groups] = await Promise.all([
    getProductHierarchy('', proj_uuid),
    fetchProductGroups(proj_uuid),
  ])

  return {
    ...hierarchies,
    ...groups,
  }
}

export async function fetchProductsForGroupOrHierarchy(
  proj_uuid: string,
  entity: string,
  type: TransferEntities
): Promise<ProductTransferEntity[]> {
  const isGroup = type === TransferEntities.GROUP
  const url = isGroup ? PRODUCT_GROUP_PRODUCTS : PRODUCT_HIERARCHY_PRODUCTS

  const { data } = await axiosInstance.get(url, {
    params: { proj_uuid, [TransferEntityNames[type]]: entity },
  })

  return data.items
}

export async function updateProductsForGroupOrHierarchy(
  proj_uuid: string,
  entity: string,
  product_uuids: string[],
  type: TransferEntities
): Promise<void> {
  const isGroup = type === TransferEntities.GROUP
  const url = isGroup ? PRODUCT_GROUPS : PRODUCT_HIERARCHY

  return await axiosInstance.put(
    url,
    { [TransferEntityNames[type]]: entity, product_uuids },
    { params: { proj_uuid } }
  )
}
