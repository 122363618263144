import React from 'react'
import styled from 'styled-components'

type Props = {
  icon?: string
  title?: string
  error?: boolean
  onClick: () => void
}

export const SourceCard = ({
  icon,
  title,
  onClick,
  error,
}: Props) => {
  return (
    <Wrapper onClick={onClick}>
      {icon && <Icon error={error}>{icon}</Icon>}
      <Title error={error}>{title}</Title>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  width: 100%;
  flex: 1;
  padding: var(--default-padding-half);
  box-shadow: var(--default-box-shadow);
  cursor: pointer;
  transition: transform ease 0.1s;
  transform: scale(1);
  border-radius: var(--border-radius);
  overflow: hidden;

  &:hover {
    text-decoration: none;
    transform: scale(1.05);
  }
`
const Icon = styled.div`
  width: 48px;
  height: 48px;
  font-size: var(--font-size-md);
  font-weight: 500;
  border: 2px solid black;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: black;
  background-color: var(--color-grey);
  margin: 0 auto var(--default-padding-half);

  ${(props: { error?: boolean }) => props.error && 'background-color: red;'}
`
const Title = styled.div`
  font-size: var(--font-size-md);
  text-align: center;
  color: #000;
  word-break: break-all;

  ${(props: { error?: boolean }) => props.error && 'color: red;'}
`
