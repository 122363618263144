import { GEMINI_ANALYSIS_URL, GEMINI_CHART, POST_SAMPLE } from 'utils/apiLinks'
import { axiosInstance, isProd } from 'utils/axios'

type PostSample = any
export const getPostSample = async (
  proj_uuid: string,
  dataset?: string
): Promise<PostSample> => {
  const { data } = await axiosInstance.get(POST_SAMPLE, {
    params: {
      proj_uuid: proj_uuid,
      project_uuid: dataset,
    },
  })
  return data
}

type GeminiResponse = {
  chart_question: string
  response: string
  is_valid: boolean
  sentences: number
  tokens: number
}

export const fetchGeminiAnalysis = async (
  proj_uuid: string,
  user_prompt: string,
  chart_request: any,
  model: string
): Promise<GeminiResponse> => {
  const { data } = await axiosInstance.post(
    GEMINI_ANALYSIS_URL,
    {
      user_prompt,
      filter_obj: chart_request,
      model,
    },
    { params: { proj_uuid } }
  )
  return data
}

export const getChartId = async (proj_uuid: string, question: string) => {
  const { data } = await axiosInstance.post(
    GEMINI_CHART,
    {
      question,
    },
    { params: { proj_uuid, question } }
  )
  return data
}
