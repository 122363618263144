import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import CatalogList, {
  FocusListOption,
} from 'features/project/features/catalog/Components/CatalogList/CatalogList'
import CatalogDetail from './CatalogDetail'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import mixpanel from '../../../trackers/mixpanel'
import { EmptyCatalogDetail } from 'features/project/features/catalog/Components/EmptyCatalogDetail/EmptyCatalogDetail'
import { Loader } from 'shared/components'

// fyi this component used to be called Focus
const Catalog: React.FC = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const location = useLocation()
  const history = useHistory()

  const [focusListData, setFocusListData] = useState<FocusListOption[]>([])

  const [selectedLens, setSelectedLens] = useState<string | null>(
    'product_hierarchy',
  )

  const [tabIndex, setTabIndex] = useState<number>(0)

  // TODO this should be a FocusData object so we can request information from themes with same name across grouped dash
  const [selectedFocus, setSelectedFocus] = useState<string | null>(null)

  useEffect(() => {
    mixpanel.track('catalog page')
  }, [])

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSelectedLens(urlParams.get('category') || 'product_hierarchy')
    // @ts-ignore
    setSelectedFocus(!!urlParams.get('item') ? urlParams.get('item') : null)
  }, [location.search])

  const updateLens = (lens: string) => {
    // update url params to reflect lens
    const urlParams = new URLSearchParams(window.location.search)
    urlParams.set('category', lens)
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const updateFocus = (focus: string | null) => {
    // update url params to reflect catalog value(s)
    const urlParams = new URLSearchParams(window.location.search)
    if (focus) {
      urlParams.set('item', focus)
    } else {
      urlParams.delete('item')
    }
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const focusItem = focusListData.find((item) => item.value === selectedFocus)

  if (!defaultFilterList.length) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>Loading Catalog</LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <Container>
      <CatalogList
        selectedFocus={selectedFocus}
        setSelectedFocus={updateFocus}
        selectedLens={selectedLens}
        setSelectedLens={updateLens}
        focusListData={focusListData}
        setFocusListData={setFocusListData}
        tabIndex={tabIndex}
        setTabIndex={setTabIndex}
      />
      <>
        {focusItem && selectedLens && selectedFocus ? (
          <CatalogDetail
            key={`${selectedLens}_${focusItem.value}`}
            item={focusItem}
            lens={selectedLens}
            setValue={updateFocus}
            tabIndex={tabIndex}
            setTabIndex={setTabIndex}
          />
        ) : (
          <EmptyCatalogDetail />
        )}
      </>
    </Container>
  )
}

export default Catalog

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: calc(100% - 40px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`
