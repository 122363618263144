import { axiosInstance } from 'utils/axios'
import { HASH_FILTER_URL, ORIGINAL_URL } from 'utils/apiLinks'
import { AxiosResponse } from 'axios'

/**
 * Fetches project details
 * @param {string} proj_uuid - The project UUID
 * @param {string} url_string - The URL Param String
 * @returns {Promise<AxiosResponse>} Axios Response // this probably changes too
 */
// may want to type the AxiosResponse
export const getBookmarkUrlPost = (
  proj_uuid: string,
  body: any,
): Promise<string> =>
  axiosInstance
    .post(HASH_FILTER_URL, body, {
      params: { proj_uuid },
    })
    .then(({ data }) => data)

/**
 * Fetches project details
 * @param {string} proj_uuid - The project UUID
 * @param {string} key - The URL Param String
 * @returns {Promise<AxiosResponse>} Axios Response // this probably changes too
 */
// may want to type the AxiosResponse
export const getOriginalUrl = (
  proj_uuid: string,
  key: string,
): Promise<string> =>
  axiosInstance
    .get(ORIGINAL_URL, {
      params: { proj_uuid, key },
    })
    .then(({ data }) => data)

export type FeatureFlagsResponse = {
  [key: string]: boolean
}
// may want to type the AxiosResponse
export const getFeatureFlags = (
  proj_uuid: string,
): Promise<FeatureFlagsResponse> =>
  axiosInstance
    .get('/feature_flags', {
      params: { proj_uuid },
    })
    .then(({ data }) => data)
