import { Filters } from 'features/project/types'
import { ThemeDrivers } from 'features/project/features/Charts/components/ExportDrawer/types'
import { axiosInstance } from 'utils/axios'
import { POST_THEME_DRIVERS } from 'utils/apiLinks'

export const getThemeDrivers = async (
  proj_uuid: string,
  chartId: string,
  filterValues: Filters,
  tier1: string,
  tier1_values: string[],
): Promise<ThemeDrivers> => {
  const config = { params: { proj_uuid } }
  const payload = {
    chart_id: chartId,
    criteria: filterValues.values,
    search_criteria: filterValues.searchCondition,
    search_terms: filterValues.searchQuery,
    tier1,
    tier1_values,
  }
  const { data } = await axiosInstance.post(POST_THEME_DRIVERS, payload, config)
  return data
}
