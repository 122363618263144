import mixpanel from 'features/trackers/mixpanel'
import React, { useEffect } from 'react'
import { useParams, useHistory, useLocation } from 'react-router-dom'
import { home } from 'utils/links'
import { ProjectRoot } from '../containers/project-root'
import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { useFeatureFlags } from '../hooks/useFeatureFlags'
import useMixpanel from '../hooks/useMixpanel'

type RouteParams = { id: string }
export const Project = () => {
  const { id } = useParams<RouteParams>()
  const history = useHistory()

  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const setProjectId = useProjectStore(
    (state: ProjectState) => state.setProjectId
  )

  useEffect(() => {
    if (id) {
      mixpanel.register({ proj_uuid: id })
      setProjectId(id)
    }
  }, [id])

  // TODO this will come to represent Group Id
  useEffect(() => {
    if (!id) history.push(home)
  }, [])

  useMixpanel()

  if (!projectId) return null

  return <ProjectRoot projectId={projectId} />
}
