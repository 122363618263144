import React from 'react'
import styled from 'styled-components'
import {
  cardBorder,
  filterButtonFontSize,
  lightBlueFontColor,
  navDefaultTextColor,
  secondaryButtonLightBlue,
} from 'assets/styles/variables'
import { DashboardChartResponse } from 'features/project/features/Charts/types'
import { useClickOutside } from 'shared/hooks'
import { ChartSelectorPanel } from './components/ChartSelectorPanel/ChartSelectorPanel'

type Props = {
  chartItem: DashboardChartResponse
}

export const ChartSelector: React.FC<Props> = ({ chartItem }) => {
  const [isChartsPanelVisible, setIsChartsPanelVisible] = React.useState(false)

  const cardFilter = chartItem?.tier2
  const selectedCategory = cardFilter?.options[cardFilter.default].name

  const panelRef = React.createRef<HTMLDivElement>()
  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.classList?.contains('chart-selector')) ||
      (e.path && e.path[1]?.id === 'chart-selector')
    )
      return
    setIsChartsPanelVisible(false)
  })

  return (
    <Wrapper className={'chart-selector'}>
      <Container
        className={'chart-selector'}
        // onMouseEnter={() => {
        //   setIsChartsPanelVisible(true)
        // }}
        onClick={() => {
          setIsChartsPanelVisible((prev) => !prev)
        }}
      >
        <Label className={'chart-selector'}>
          <span>Chart</span>
        </Label>
        <Count className={'chart-selector'}>
          <span
            style={{ minWidth: 10, textAlign: 'center' }}
            className={'chart-selector'}
          >
            {selectedCategory ?? 'Choose'}
          </span>
        </Count>
      </Container>
      {isChartsPanelVisible && (
        <ChartSelectorPanelWrapper ref={panelRef}>
          <ChartSelectorPanel
            chartItem={chartItem}
            setIsChartsPanelVisible={setIsChartsPanelVisible}
          ></ChartSelectorPanel>
        </ChartSelectorPanelWrapper>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`

const Container = styled.div<{ disabled?: boolean }>`
  font-size: ${filterButtonFontSize};
  margin: 5px;
  background: white;
  border: 2px solid ${cardBorder};
  border-radius: 12px;
  display: flex;
  overflow: hidden;
  box-shadow: 0 0 5px 0 #00000026;
  cursor: pointer;
  transition: 0.2s ease;
  &:hover {
    border-color: ${navDefaultTextColor};
  }
`

const Label = styled.div`
  padding: 8px 14px 8px;
  color: #28364f;
`

const Count = styled.div`
  padding: 5px 14px;
  font-weight: 600;

  display: flex;
  align-items: center;
  justify-content: center;

  background: ${secondaryButtonLightBlue};
  color: ${lightBlueFontColor};

  border-top-left-radius: 12px;
  border-bottom-left-radius: 12px;
`

const ChartSelectorPanelWrapper = styled.div`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: 5px;
  top: 50px;
  z-index: 100;

  border-radius: 10px;
  border: 1px solid #e3e3e3;

  box-shadow: 0 4px 5px 0 #00000026;

  width: calc(90vw / 2);
  max-width: 700px;
`
