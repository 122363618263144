import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useClickOutside } from 'shared/hooks'
import {
  HardcodedFilterOptionsEnum,
  updateFilterList,
} from '../../../../filters/helpers'
import { isInitialFlow } from 'features/home/utils'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { FilterOption, FilterOptionRange } from '../../../../filters/types'
import { FilterButton } from 'features/project/features/filters/FilterButton/FilterButton'
import { useLocalFilterList } from '../../../../../hooks/useLocalFilterList'
import { OldDatePanel } from '../../../../filters/features/date-panel/old-date-panel'
import { SearchButton } from 'features/project/features/filters/SearchButton/SearchButton'

type Props = {
  filterValues: Filters
  mergedFilters: Filters
  parentFilters: Filters
  updateLocalFilters: (filters: Filters) => void
  disabled?: boolean
  isEditing?: boolean
  isUnlinked: boolean
}

export const ChartHeaderFilterPanel = ({
  filterValues,
  mergedFilters,
  parentFilters,
  updateLocalFilters,
  isUnlinked,
  disabled,
  isEditing,
}: Props) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const defaultTime = defaultFilterList.find(
    (el) => el.field === 'create_time',
  ) as FilterOptionRange

  const panelRef = React.createRef<HTMLDivElement>()
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const isSmall = true

  const [localOpenedOptions, setLocalOpenedOptions] = useState<string[]>([])
  const [filterList, setFilterList] = useState<FilterOption[]>([])
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(false)
  const [filterApplied, setFilterApplied] = useState(false)
  const [selectedOption, setSelectedOption] = useState<string>('')

  const { data: filterListData, isLoading } = useLocalFilterList(
    mergedFilters,
    selectedOption,
    localOpenedOptions,
    filterList,
    setFilterList,
  )

  useEffect(() => {
    setFilterList(filterListData ?? [])
    setLocalOpenedOptions([])
    setSelectedOption('')
  }, [filterValues])

  useEffect(() => {
    if (filterListData) {
      // this caches the filter on a per-field basis
      setFilterList((prevState) =>
        updateFilterList(prevState, localOpenedOptions, filterListData),
      )
    }
  }, [filterListData])

  useEffect(() => {
    if (filterApplied && isLoading) {
      setFilterOptionsLoading(true)
    } else {
      setFilterApplied(false)
      setFilterOptionsLoading(false)
    }
  }, [filterApplied, isLoading])

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button')
    )
      return
    setFilterPanelVisible(false)
    // updateSelectedOptionField(comparativeIndex, '')
  })

  if (isInitialFlow(details.state)) {
    return <Wrapper />
  }

  return (
    <OuterContainer className={'drag-cancel'}>
      <ResizeContainer>
        <Container>
          <Wrapper>
            <Left style={{ gap: isSmall ? 10 : 15 }}>
              <span style={{ marginRight: 5 }}>
                <FilterButton
                  filterValues={filterValues}
                  isFilterPanelVisible={isFilterPanelVisible}
                  setFilterPanelVisible={setFilterPanelVisible}
                  filterOptionsLoading={filterOptionsLoading || !filterList}
                  isSmall={isSmall}
                  disabled={disabled}
                  comparativeIndex={0}
                  // cdash
                  updateLocalFilterValues={updateLocalFilters}
                  localOpenedOptions={localOpenedOptions}
                  setLocalOpenedOptions={setLocalOpenedOptions}
                  localSelectedOption={selectedOption}
                  setLocalSelectedOption={setSelectedOption}
                  isUnlinked={isUnlinked}
                  parentFilters={parentFilters}
                  isDashboardChart
                  isDashboardEdit={isEditing}
                  mergedFilters={mergedFilters}
                />
              </span>
              <span style={{ marginRight: 5 }}>
                <SearchButton
                  filterValues={filterValues}
                  comparativeIndex={0}
                  updateLocalFilterValues={updateLocalFilters}
                  isDashboardChart
                  isSmall
                  isDashboardEdit={isEditing}
                />
              </span>
            </Left>
            <Right style={{ gap: isSmall ? 5 : 10 }}>
              <div className={'drag-cancel'}>
                <OldDatePanel
                  comparativeIndex={0}
                  filterValues={filterValues}
                  updateLocalFilterValues={updateLocalFilters}
                  isChild
                  isUnlinked={isUnlinked}
                  parentMin={
                    (
                      parentFilters?.values?.find(
                        ({ field }) =>
                          field === HardcodedFilterOptionsEnum.CREATE_TIME,
                      ) as FilterOptionRange
                    )?.min ?? defaultTime?.min
                  }
                  parentMax={
                    (
                      parentFilters?.values?.find(
                        ({ field }) =>
                          field === HardcodedFilterOptionsEnum.CREATE_TIME,
                      ) as FilterOptionRange
                    )?.max ?? defaultTime?.max
                  }
                  isLeft
                  disabled={disabled}
                />
              </div>
            </Right>
          </Wrapper>
        </Container>
      </ResizeContainer>
    </OuterContainer>
  )
}

const OuterContainer = styled.div`
  cursor: default;
`

const ResizeContainer = styled.div`
  width: 100%;
  //position: relative;
`

const Container = styled.div`
  z-index: 10;
  background: white;
  display: flex;
  align-items: center;
  padding: 0;
  width: calc(100% - 30px);
`

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  //position: relative;

  .ant-badge-count {
    top: 4px;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .ant-badge-count {
    right: 7px;
    top: 3px;
  }
`
