import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Badge, Tooltip, Popover, Input } from 'antd'
import {
  SearchOutlined,
  ExportOutlined,
  PlusOutlined,
  DashboardOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import styled from 'styled-components'
import { useClickOutside } from 'shared/hooks'
import { PanelBadges } from './features/badge-panel/panel-badges'
import { FilterPanel } from 'features/project/features/filters/FilterButton/components/FilterPanel/FilterPanel'
import { DatePanel } from './features/date-panel/date-panel'
import { SearchPanel } from './SearchButton/components/SearchPanel/SearchPanel'
import { isInitialFlow } from '../../../home/utils'
import Draggable from 'react-draggable'
import useResizeObserver from 'use-resize-observer'
import { initialState, useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { FilterPresetManager } from './features/filter-preset/filter-preset'
import { YogiButton } from '../../../../components/UI/YogiButton'
import {
  buttonBlue,
  cardBorderGrey,
  fontSizeM,
  pageBackgroundGrey,
  superLightGrey,
} from '../../../../assets/styles/variables'
import { TuneOutlined } from '@mui/icons-material'
import * as Yup from 'yup'
import { downloadPosts } from '../feedback'
import { toast } from 'react-toastify'
import { AppliedFilterOptionSelect } from './types'
import { useGlobalFilterList } from '../../hooks/useGlobalFilterList'
import { FilterButton } from 'features/project/features/filters/FilterButton/FilterButton'
import { useQuery } from 'react-query'
import {
  getDashboards,
  saveDashboard,
  updateDashboard,
} from '../dashboard/model'
import {
  DashboardResponse,
  DashboardSettings,
  DashboardType,
} from '../dashboard/types'
import { DASHBOARD_URL, trimText } from '../../utils'
import { Layout } from 'react-grid-layout'
import { Fetcher } from '../../../../shared/components/fetcher'
import { useHistory } from 'react-router'
import { Loader } from '../../../../shared/components'
import AIcon from '../../../../assets/images/icons/AIcon_180x180.png'

const validationSchema = Yup.string().email('not an email').required()
type Props = {
  reset?: any
  settings?: any
  download?: any
  filterPanelHeight?: string
  filterValues: Filters
  comparativeIndex: number
  // downloadSettings?: any
}

export const HeaderFilterPanel = ({
  reset,
  settings,
  download,
  filterPanelHeight,
  filterValues,
  comparativeIndex,
}: // downloadSettings,
Props) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const filterList = useProjectStore(
    (state: ProjectState) => state.filterList[comparativeIndex],
  )
  const openedOptions = useProjectStore(
    (state: ProjectState) => state.openedOptions[comparativeIndex],
  )
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const setFilters = useProjectStore((state: ProjectState) => state.setFilters)
  const setFilterList = useProjectStore(
    (state: ProjectState) => state.setFilterList,
  )
  const setOpenedOptions = useProjectStore(
    (state: ProjectState) => state.setOpenedOptions,
  )
  const setIsAlignFilters = useProjectStore(
    (state: ProjectState) => state.setIsAlignFilters,
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )
  const updateOpenedOptions = useProjectStore(
    (state: ProjectState) => state.updateOpenedOptions,
  )
  const setFilterListByIndex = useProjectStore(
    (state: ProjectState) => state.setFilterListByIndex,
  )
  const resetFilterListByIndex = useProjectStore(
    (state: ProjectState) => state.resetFilterListByIndex,
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen,
  )
  const setSummaryRequest = useProjectStore(
    (state: ProjectState) => state.setSummaryRequest,
  )

  const panelRef = React.createRef<HTMLDivElement>()
  const [downloadLoading, setDownloadLoading] = useState(false)
  const [publicViewerEmail, setPublicViewerEmail] = useState<string>('')
  const [publicEmailIsValid, setPublicEmailIsValid] = useState<boolean>(false)
  const [isFilterPanelVisible, setFilterPanelVisible] = useState(false)
  const [isSearchPanelVisible, setSearchPanelVisible] = useState(false)
  const [filterOptionsLoading, setFilterOptionsLoading] = useState(false)
  const [dragged, setDragged] = useState(false)
  const [dragDisabled, setDragDisabled] = useState(true)
  const { ref, width = 0 } = useResizeObserver()
  const isSmall = !!width && width < 600

  const [filterApplied, setFilterApplied] = useState(false)
  const [addToDashVisible, setAddToDashVisible] = useState(false)
  const [confirmingDashboard, setConfirmingDashboard] = useState<any>(null)

  const { isCharts, isKeywords } = route
  const isPublicViewer = details.role === 'public_viewer'

  useClickOutside(panelRef, (e) => {
    if (
      (e.target && e.target?.id.includes('filter-badge')) ||
      (e.target && e.target?.id.includes('filters-button')) ||
      (e.path && e.path[1]?.id === 'filters-button') ||
      dragged ||
      isComparative
    )
      return
    setFilterPanelVisible(false)
  })

  useEffect(() => {
    resetFilterListByIndex(comparativeIndex)
  }, [filterValues])

  const { data: filterListData, isLoading } =
    useGlobalFilterList(comparativeIndex)
  useEffect(() => {
    if (filterListData) {
      // get selected filter list entry and place it into the filter list obj
      setFilterListByIndex(comparativeIndex, filterListData)
    }
  }, [filterListData])

  const {
    data: dashboardsData,
    refetch: refetchDashboards,
    isLoading: dashboardsLoading,
  } = useQuery(
    ['custom-dashboards', projectId],
    () => {
      return getDashboards(projectId)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  useEffect(() => {
    if (filterApplied && isLoading) {
      setFilterOptionsLoading(true)
    } else {
      setFilterApplied(false)
      setFilterOptionsLoading(false)
    }
  }, [filterApplied, isLoading])

  // if (!filterList?.length || isInitialFlow(details.state)) {
  //   return <Wrapper />
  // }

  if (isInitialFlow(details.state)) {
    return <Wrapper />
  }

  const isLeftPos = details.clientSettings.project.toolbar.alignment === 'left'

  const downloadReviews = async (printCurrent?: boolean) => {
    setDownloadLoading(true)
    if (isPublicViewer && !publicEmailIsValid) {
      toast.error('Invalid email')
      setDownloadLoading(false)
      return
    }
    try {
      await downloadPosts(projectId, {
        email: isPublicViewer ? publicViewerEmail : undefined,
        ...(printCurrent
          ? {
              search_terms: filterValues.searchQuery,
              search_criteria: filterValues.searchCondition,
              criteria: filterValues.values,
            }
          : {}),
      })
      toast.success('Success')
    } catch (e) {
      toast.error('Error')
    } finally {
      setDownloadLoading(false)
    }
  }

  const downloadContent = (
    <div style={{ display: 'flex', flexDirection: 'column' }}>
      <div style={{ marginBottom: '10px', width: '100%' }}>
        <YogiButton
          type="dashed"
          onClick={() => {
            mixpanel.track('posts download', { action: 'all', ...route })
            downloadReviews()
          }}
          style={{ width: '100%' }}
        >
          Export All Reviews
        </YogiButton>
      </div>
      <div style={{ width: '100%' }}>
        <YogiButton
          type="dashed"
          key="2"
          onClick={() => {
            downloadReviews(true)
            mixpanel.track('posts download', { action: 'filtered', ...route })
          }}
          style={{ width: '100%' }}
        >
          Export Filtered Reviews
        </YogiButton>
      </div>
      {/*{!isComparative && (*/}
      {/*  <Tooltip title={tooltip['download-button']} placement="right">*/}
      {/*    {' '}*/}
      {/*    <InfoCircleOutlined />*/}
      {/*  </Tooltip>*/}
      {/*)}*/}
      {isPublicViewer && (
        // TODO TEST/FIX THIS -- certainly breaks hardcoded heights
        <Header>
          <StyledInput
            placeholder="Email..."
            value={publicViewerEmail}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              const value = e.target.value
              setPublicViewerEmail(value)
              validationSchema
                .validate(value)
                .then(() => setPublicEmailIsValid(true))
                .catch(() => setPublicEmailIsValid(false))
            }}
          />
        </Header>
      )}
    </div>
  )

  const AddToDashboardContent = () => {
    const chartData = useProjectStore(
      (state: ProjectState) => state.chartData[comparativeIndex ?? 0],
    )
    const dashboardControls = useProjectStore(
      (state: ProjectState) => state.dashboardControls[0],
    )

    const history = useHistory()

    const [dashboards, setDashboards] = useState<DashboardResponse[]>([])
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    useEffect(() => {
      if (dashboardsData) {
        setDashboards(dashboardsData)
      }
    }, [dashboardsData])

    const maxTextLength = 50

    // TODO separate into own component
    const addToExistingDash = (dashboard: DashboardType) => {
      setIsSubmitting(true)
      const newDashboardItem = {
        type: 'chart',
        dashboardControls: dashboardControls,
        filterValues: filterValues,
        content: {
          chart_ids: [{ chart_id: dashboardControls.chartId }],
        },
      }
      const tempDashboard = JSON.parse(JSON.stringify(dashboard))
      const lastDashboardItem = tempDashboard.dashboard_settings.layout.sort(
        (a: Layout, b: Layout) => b.y - a.y,
      )[0]
      const lastDashboardIndex = tempDashboard.dashboard_settings.layout.sort(
        (a: Layout, b: Layout) => Number(b.i) - Number(a.i),
      )[0]
      const newDashboardLayout = {
        h: 9,
        i: lastDashboardIndex
          ? (Number(lastDashboardIndex?.i) + 1).toString()
          : '0',
        w: 12,
        x: 0,
        y: lastDashboardItem ? lastDashboardItem.h + lastDashboardItem.y : 0,
        minH: 4,
        minW: 4,
        moved: false,
        static: false,
        isDraggable: true,
      }

      const updatedDashboardSettings = JSON.parse(
        JSON.stringify(dashboard.dashboard_settings),
      )
      updatedDashboardSettings.layout.push(newDashboardLayout)
      updatedDashboardSettings.items.push(newDashboardItem)

      const updatedDash = updateDashboard(projectId, {
        dashboard_id: dashboard.id,
        name: dashboard.name,
        dashboard_settings: updatedDashboardSettings,
      })
        .then((res) => {
          toast.success('Chart added to dashboard')
        })
        .catch((err) => {
          console.log(err)
          toast.error('Error adding chart to dashboard')
        })
        .finally(() => {
          setIsSubmitting(false)
          setConfirmingDashboard(null)
          setAddToDashVisible(false)
          refetchDashboards()
        })
    }

    const dashboardFiltersWillModifyChart = (
      dashboardFilters: Filters,
      chartFilters: Filters,
    ) => {
      // if there are more filters on the dashboard than the chart, then the chart will be modified
      if (dashboardFilters.values.length > chartFilters.values.length)
        return true
      // if, in a filter shared by dash + chart, the chart has a value that is not in the dashboard, then the chart will be modified
      // what should happen though? should the global filters be applied to the chart? or should the chart be added to the dashboard without the global filters?
      const subFilterMatch = dashboardFilters.values.some((dashboardFilter) => {
        const match = chartFilters.values.find(
          (chartFilter) => chartFilter.field === dashboardFilter.field,
        )
        // TODO range filters
        if (
          !(match as AppliedFilterOptionSelect)?.values?.every((r) =>
            (dashboardFilter as AppliedFilterOptionSelect).values.includes(r),
          )
        ) {
          // perform filter modification here?
          // probably better to perform filter modification in the mergeFilters function
          return true
        }
      })
      if (subFilterMatch) return true

      return false
    }

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          maxHeight: 300,
          overflow: 'auto',
        }}
      >
        {dashboardsLoading && <Loader style={{ height: 100 }} />}
        {confirmingDashboard && (
          <div
            style={{
              position: 'absolute',
              height: 'calc(100% - 25px)',
              width: '100%',
              zIndex: 100,
              background: 'rgba(255, 255, 255, 0.9)',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              top: 12,
              left: 0,
              padding: 20,
            }}
          >
            <div
              style={{
                textAlign: 'center',
                background: pageBackgroundGrey,
                padding: 10,
                borderRadius: 8,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
                boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
              }}
            >
              <div
                style={{
                  textAlign: 'center',
                }}
              >
                <span style={{ fontWeight: 500 }}>
                  {confirmingDashboard.name}
                </span>{' '}
                has Global Filters that will be applied to this Chart. Do you
                still wish to add this Chart to this Dashboard?
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  gap: 20,
                }}
              >
                <YogiButton onClick={() => setConfirmingDashboard(null)}>
                  Cancel
                </YogiButton>
                <YogiButton
                  type={'primary'}
                  onClick={() => addToExistingDash(confirmingDashboard)}
                >
                  Add
                </YogiButton>
              </div>
            </div>
          </div>
        )}
        {dashboards &&
          dashboards
            // .sort((a, b) => a.name.localeCompare(b.name))
            .map(({ dashboard }: DashboardResponse) => (
              <ViewWrapper key={dashboard.id}>
                <ViewButton
                  type="text"
                  // icon={viewImages[view.page]}
                  onClick={() => {
                    mixpanel.track('charts', {
                      action: 'add to dashboard',
                      value: dashboard.name,
                      location: 'toolbar',
                      ...route,
                    })
                    // do new chart filters fit into dashboard filters?
                    // if dashboard has a filter that the new chart doesnt, then prompt
                    if (
                      dashboardFiltersWillModifyChart(
                        dashboard.dashboard_settings.filters,
                        filterValues,
                      )
                    ) {
                      setConfirmingDashboard(dashboard)
                      return
                    }
                    addToExistingDash(dashboard)
                    // if dashboard filter doesn't contain a filter that the new chart does, then prompt
                    // repeat for text search terms?
                  }}
                  // style={{
                  //   color:
                  //     view.search_url === window.location.search
                  //       ? 'blue'
                  //       : 'black',
                  // }}
                >
                  {dashboard.name.length > maxTextLength ? (
                    <Tooltip title={dashboard.name}>
                      <ViewName>
                        {trimText(dashboard.name, maxTextLength)}
                      </ViewName>
                    </Tooltip>
                  ) : (
                    <ViewName>
                      {trimText(dashboard.name, maxTextLength)}
                    </ViewName>
                  )}
                </ViewButton>
              </ViewWrapper>
            ))}

        <NewWrapper>
          <ViewButton
            type="text"
            icon={<PlusOutlined />}
            onClick={() => {
              mixpanel.track('charts', {
                action: 'create dashboard',
                location: 'toolbar',
                ...route,
              })
              setIsSubmitting(true)
              const newDashboardItem = {
                type: 'chart',
                dashboardControls: dashboardControls,
                filterValues: filterValues,
                content: {
                  chart_ids: [{ chart_id: dashboardControls.chartId }],
                },
              }
              const newDashboardLayout: Layout = {
                h: 9,
                i: '0',
                w: 12,
                x: 0,
                y: 0,
                minH: 4,
                minW: 4,
                moved: false,
                static: false,
                isDraggable: true,
              }

              const dashboardSettings: DashboardSettings = {
                // filters: filterValues,
                filters: initialState.filters[0],
                items: [],
                layout: [],
              }
              dashboardSettings.layout.push(newDashboardLayout)
              dashboardSettings.items.push(
                //@ts-ignore
                newDashboardItem,
              )
              saveDashboard(projectId, {
                name: chartData.charts[0].title,
                dashboard_settings: dashboardSettings,
                is_shared: false,
              })
                .then((res) => {
                  // toast.success('New Dashboard Created')
                  refetchDashboards().then(() => {
                    history.push(
                      `${DASHBOARD_URL}?dashboard_id=${res.dashboard.id}`,
                    )
                  })
                })
                .catch((err) => {
                  setIsSubmitting(false)
                  toast.error('Error creating Dashboard')
                })
            }}
            // style={{
            //   color:
            //     view.search_url === window.location.search
            //       ? 'blue'
            //       : 'black',
            // }}
          >
            <ViewName>Create Dashboard</ViewName>
          </ViewButton>
        </NewWrapper>

        {/* TODO Overlay doesn't cover add buttons*/}
        {isSubmitting && (
          <Overlay>
            <Fetcher />
          </Overlay>
        )}
      </div>
    )
  }

  return (
    <OuterContainer>
      <ResizeContainer ref={ref}>
        <Container style={{ width: `${width}px` }}>
          <Wrapper>
            <Left style={{ gap: isSmall ? 5 : 10 }}>
              <span style={{ marginRight: 5, zIndex: 2 }}>
                <FilterButton
                  filterValues={filterValues}
                  isFilterPanelVisible={isFilterPanelVisible}
                  setFilterPanelVisible={setFilterPanelVisible}
                  comparativePanelsNumber={comparativePanelsNumber}
                  filterOptionsLoading={false}
                  // filterOptionsLoading={filterOptionsLoading || !filterList}
                  isSmall={isSmall}
                  filterList={filterListData ?? []}
                />
                {/*<Badge*/}
                {/*  count={globalAppliedFiltersCount}*/}
                {/*  size="small"*/}
                {/*  style={{ zIndex: 1 }}*/}
                {/*>*/}
                {/*  <YogiButton*/}
                {/*    id="filters-button"*/}
                {/*    disabled={filterOptionsLoading}*/}
                {/*    type={isFilterPanelVisible ? 'default' : 'primary'}*/}
                {/*    onClick={() => {*/}
                {/*      setFilterPanelVisible(!isFilterPanelVisible)*/}
                {/*      mixpanel.track('filter panel open', {*/}
                {/*        isDashboard,*/}
                {/*        isNetwork,*/}
                {/*        isFeedback,*/}
                {/*        isKeywords,*/}
                {/*      })*/}
                {/*    }}*/}
                {/*    style={{ padding: isSmall ? '0 10px' : '' }}*/}
                {/*  >*/}
                {/*    {comparativePanelsNumber === 3 || isSmall ? (*/}
                {/*      <span style={{ pointerEvents: 'none' }}>*/}
                {/*        {filterOptionsLoading ? (*/}
                {/*          <LoadingOutlined style={{ pointerEvents: 'none' }} />*/}
                {/*        ) : (*/}
                {/*          <UnorderedListOutlined*/}
                {/*            style={{ pointerEvents: 'none' }}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*      </span>*/}
                {/*    ) : (*/}
                {/*      <span style={{ pointerEvents: 'none' }}>*/}
                {/*        {filterOptionsLoading ? (*/}
                {/*          <LoadingOutlined*/}
                {/*            style={{*/}
                {/*              pointerEvents: 'none',*/}
                {/*              marginLeft: -3,*/}
                {/*              marginRight: 8,*/}
                {/*            }}*/}
                {/*          />*/}
                {/*        ) : (*/}
                {/*          <UnorderedListOutlined*/}
                {/*            style={{*/}
                {/*              pointerEvents: 'none',*/}
                {/*              marginLeft: -3,*/}
                {/*              marginRight: 8,*/}
                {/*            }}*/}
                {/*          />*/}
                {/*        )}*/}
                {/*        {''}*/}
                {/*        Filters*/}
                {/*      </span>*/}
                {/*    )}*/}
                {/*  </YogiButton>*/}
                {/*</Badge>*/}
                {/*{!isComparative && (*/}
                {/*  <Tooltip title={tooltip['filter-panel']}>*/}
                {/*    {' '}*/}
                {/*    <InfoCircleOutlined />*/}
                {/*  </Tooltip>*/}
                {/*)}*/}
              </span>
              {!isKeywords && (
                <span style={{ marginRight: 5, zIndex: 2 }}>
                  <Badge count={filterValues?.searchQuery?.length} size="small">
                    <YogiButton
                      id="search-button"
                      type="dashed"
                      onClick={() =>
                        setSearchPanelVisible(!isSearchPanelVisible)
                      }
                      style={{ padding: isSmall ? '0 10px' : '' }}
                    >
                      {comparativePanelsNumber === 3 || isSmall ? (
                        <span>
                          <SearchOutlined />
                        </span>
                      ) : (
                        <span>
                          <SearchOutlined
                            style={{
                              pointerEvents: 'none',
                              marginLeft: -3,
                              marginRight: 4,
                            }}
                          />{' '}
                          Search
                        </span>
                      )}
                    </YogiButton>
                  </Badge>
                  {/*{!isComparative && (*/}
                  {/*  <Tooltip title={tooltip['search-bar']}>*/}
                  {/*    {' '}*/}
                  {/*    <InfoCircleOutlined />*/}
                  {/*  </Tooltip>*/}
                  {/*)}*/}
                </span>
              )}
              {isLeftPos && (
                <DateSelectorWrapper className="date-selector">
                  <DatePanel
                    comparativeIndex={comparativeIndex}
                    filterList={filterList}
                    filterValues={filterValues}
                  />
                </DateSelectorWrapper>
              )}
              <div>
                <PanelBadges
                  filterValues={filterValues}
                  comparativeIndex={comparativeIndex}
                  small={isSmall}
                  openFilterPanel={(field) => {
                    updateSelectedOptionField(comparativeIndex, field)
                    updateOpenedOptions(comparativeIndex, field)
                    setFilterPanelVisible(true)
                  }}
                />
              </div>
              {isComparative && comparativeIndex === 0 && (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    marginLeft: 2,
                  }}
                >
                  <a
                    style={{
                      fontSize: fontSizeM,
                      height: 24,
                      borderColor: '#d9d9d9',
                      display: 'flex',
                      alignItems: 'center',
                      color: buttonBlue,
                      textDecoration: 'none',
                    }}
                    onClick={() => {
                      const tempFilterValues = []
                      const tempFilterOptions = []
                      const tempOpenedOptions = []
                      for (let i = 0; i < comparativePanelsNumber; i++) {
                        tempFilterValues.push(filterValues)
                        tempFilterOptions.push(filterList)
                        tempOpenedOptions.push(openedOptions)
                      }
                      setFilters(tempFilterValues)
                      setFilterList(tempFilterOptions)
                      setOpenedOptions(tempOpenedOptions)
                      setIsAlignFilters(true)
                    }}
                  >
                    <Tooltip
                      placement="top"
                      title="Copy Filters to Other Panels"
                    >
                      Align Filters
                    </Tooltip>
                  </a>
                </div>
              )}

              {isSearchPanelVisible && (
                <SearchPanelWrapper>
                  <SearchPanel
                    filterValues={filterValues}
                    closePanel={() => setSearchPanelVisible(false)}
                    comparativeIndex={comparativeIndex}
                  />
                </SearchPanelWrapper>
              )}
            </Left>
            <Right style={{ gap: isSmall ? 5 : 10 }}>
              {/* TODO? */}
              {/*{downloadSettings && (*/}
              {/*  <DownloadSelector*/}
              {/*    loading={downloadSettings.loading}*/}
              {/*    onPrint={downloadSettings.onPrint}*/}
              {/*  />*/}
              {/*)}*/}
              {route.isFeedback && (
                <Button
                  onClick={() => {
                    // setIsModalOpened(true)
                    setSummaryIsOpen(true)

                    const summaryRequest = {
                      headers: {
                        proj_uuid: projectId,
                      },
                      body: {
                        chart_id: '5_5_7_rating_sentiment',
                        // api needs a tier1 value, but it doesn't matter what it is
                        tier1: 'Brand',
                        tier1_value: '',
                        tier2_value: null,
                        tier3_value: null,
                        search_terms: filterValues?.searchQuery ?? [],
                        search_criteria: filterValues?.searchCondition ?? [],
                        criteria: filterValues?.values ?? [],
                      },
                    }

                    mixpanel.track('summary control', {
                      action: 'feedback',
                      value: summaryRequest.body,
                      ...route,
                    })
                    setSummaryRequest(summaryRequest)
                  }}
                  type="text"
                  style={{
                    border: `2px solid ${cardBorderGrey}`,
                    borderRadius: '4px',
                    boxShadow: 'none',
                    display: 'flex',
                    gap: isSmall || isComparative ? '0px' : '5px',
                  }}
                >
                  <img
                    style={{
                      height: '120%',
                      marginTop: -3,
                      marginLeft: -10,
                      marginRight: 3,
                    }}
                    src={AIcon}
                  />
                  Summarize
                </Button>
              )}
              {reset}
              {settings && (
                <Popover
                  content={settings}
                  title={<div style={{ padding: '5px' }}>Controls</div>}
                  trigger="click"
                  placement="bottom"
                >
                  <Tooltip placement="top" title="Controls">
                    {/*<SettingsButton icon={<ControlOutlined />} />*/}
                    <Button
                      type="text"
                      style={{
                        border: `2px solid ${cardBorderGrey}`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                        display: 'flex',
                        gap: isSmall || isComparative ? '0px' : '5px',
                      }}
                    >
                      <TuneOutlined fontSize={'small'} />{' '}
                      {isSmall || isComparative ? '' : <span>Controls</span>}
                    </Button>
                  </Tooltip>
                </Popover>
              )}
              {isCharts && (
                <Popover
                  content={AddToDashboardContent}
                  title={<div style={{ padding: '5px' }}>Add to Dashboard</div>}
                  trigger="click"
                  placement="bottom"
                  visible={addToDashVisible}
                  onVisibleChange={(visible) => setAddToDashVisible(visible)}
                >
                  <Tooltip placement="top" title="Add to Dashboard">
                    {/*<SettingsButton icon={<ControlOutlined />} />*/}
                    <Button
                      type="text"
                      icon={<DashboardOutlined />}
                      style={{
                        border: `2px solid ${cardBorderGrey}`,
                        borderRadius: '4px',
                        boxShadow: 'none',
                      }}
                    />
                  </Tooltip>
                </Popover>
              )}
              <Popover
                content={download ? download : downloadContent}
                title={<div style={{ padding: '5px' }}>Export via Email</div>}
                trigger="click"
                placement="bottom"
              >
                <Tooltip placement="top" title="Export Reviews by Email">
                  {/*<SettingsButton icon={<ControlOutlined />} />*/}
                  <Button
                    type="text"
                    icon={<ExportOutlined />}
                    style={{
                      border: `2px solid ${cardBorderGrey}`,
                      borderRadius: '4px',
                      boxShadow: 'none',
                    }}
                  />
                </Tooltip>
              </Popover>
              <FilterPresetManagerWrapper>
                <FilterPresetManager
                  comparativeIndex={comparativeIndex}
                  filterValues={filterValues}
                  filterList={filterList}
                />
              </FilterPresetManagerWrapper>
              {!isLeftPos && (
                <DateSelectorWrapper className="date-selector">
                  <DatePanel
                    comparativeIndex={comparativeIndex}
                    filterList={filterList}
                    filterValues={filterValues}
                  />
                </DateSelectorWrapper>
              )}
            </Right>
          </Wrapper>
        </Container>
      </ResizeContainer>
      {isFilterPanelVisible && (
        <Draggable onStart={() => setDragged(true)} disabled={dragDisabled}>
          <FilterPanelWrapper
            ref={panelRef}
            params={{
              comparativePanelsNumber: comparativePanelsNumber,
              isComparative: isComparative,
              dragged: dragged,
            }}
          >
            <FilterPanel
              onClose={() => {
                setFilterApplied(true)
                setFilterPanelVisible(false)
                setDragged(false)
                updateSelectedOptionField(comparativeIndex, '')
              }}
              height={filterPanelHeight}
              dragged={dragged}
              setDragDisabled={setDragDisabled}
              comparativeIndex={comparativeIndex}
              filterListData={filterListData}
              filterList={filterList}
              filterValues={filterValues}
            />
          </FilterPanelWrapper>
        </Draggable>
      )}
    </OuterContainer>
  )
}

type FilterWrapperProps = {
  params: {
    comparativePanelsNumber: number
    isComparative: boolean
    dragged: boolean
  }
}

const OuterContainer = styled.div`
  position: relative;
  width: 100%;
`

const ResizeContainer = styled.div`
  width: 100%;
  position: relative;
`

const Container = styled.div`
  z-index: 10;
  min-height: var(--filter-height);
  height: var(--filter-height);
  background: white;
  display: flex;
  align-items: center;
  //border-top: 1px solid var(--color-grey);
  padding: 0;
  //border-bottom: 1px solid var(--color-grey);
  width: calc(100% - 30px);
  margin-bottom: var(--filter-margin-bottom);
  border-radius: var(--border-radius);
  box-shadow: var(--light-box-shadow);
`

const Wrapper = styled.div`
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: nowrap;
  padding: 0px 10px;
`

const Left = styled.div`
  display: flex;
  gap: 10px;
  margin-right: 10px;
  align-items: center;
  flex-wrap: nowrap;
  position: relative;

  .ant-badge-count {
    top: 4px;
  }
`
const Right = styled.div`
  display: flex;
  align-items: center;
  position: relative;

  .ant-badge-count {
    right: 7px;
    top: 3px;
  }
`

export const FilterPanelWrapper = styled.div`
  overflow-y: hidden;
  background: #fff;
  position: absolute;
  left: 0;
  top: 50px;
  z-index: 100;
  box-shadow: var(--header-box-shadow);

  width: ${(props: FilterWrapperProps) =>
    !props.params.isComparative
      ? 'calc(100vw - 240px)'
      : props.params.comparativePanelsNumber > 2
        ? 'calc(90vw/3)'
        : 'calc(90vw/2)'};
  max-width: 800px;

  transform: ${(props: FilterWrapperProps) =>
    !props.params.dragged ? 'none !important' : 'inherit'};
`
export const SearchPanelWrapper = styled.div`
  min-width: 420px;
  max-width: 500px;
  background: #fff;
  position: absolute;
  top: 100%;
  left: 0;
  z-index: 1000;
  box-shadow: var(--header-box-shadow);
`
const DateSelectorWrapper = styled.div`
  margin-left: 4px;
`
const FilterPresetManagerWrapper = styled.div`
  margin-left: auto;
  margin-right: 0px;
`
const SettingsButton = styled(Button)`
  border: none;
  background: inherit !important;
  box-shadow: none;
`
const AlignFilters = styled.a`
  font-size: var(--font-size-md);
  padding: 0;
  margin: 0 8px;
  color: var(--color-text-black);
  text-align: center;
  line-height: 12px;
`

const AlignTableOrder = styled.a`
  font-size: var(--font-size-md);
  padding: 0;
  margin: 0 8px;
  color: var(--color-text-black);
  text-align: center;
  line-height: 12px;
`

const Header = styled.div`
  padding: var(--default-padding-half) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
const StyledInput = styled(Input)`
  width: 200px;
  margin-right: var(--default-padding-half);
`

const ViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${superLightGrey};
  margin-bottom: 8px;
  border-radius: 8px;
`

const NewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
`

const ViewButton = styled(Button)`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  height: 40px;
`

const ViewAction = styled.div`
  width: 7%;
  text-align: center;
  display: flex;
  align-items: center;
  height: 40px;
  cursor: pointer;
  justify-content: center;
  &:hover {
    background: rgba(0, 0, 0, 0.018);
  }
`

const ViewName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 10px;
  display: flex;
  align-items: center;
`
const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
