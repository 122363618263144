import React from 'react'
import { Typography, Tooltip } from 'antd'
import styled from 'styled-components'
import { capitalize } from 'lodash'
import { Source } from '../types'
import { SourceIcon as Icon } from 'shared/components'

type Props = {
  source: Source
}

export const SourceCard: React.FC<Props> = ({ source }) => {
  const iconStyle = {
    width: '48px',
    height: '48px',
  }
  return (
    <>
      <SourceBox
        onClick={() =>
          source.source_type !== 'File' && !source.syndicated_source
            ? openInNewTab(source.source_url)
            : null
        }
      >
        <SourceContent>
          <SourceIcon>
            {' '}
            <Icon
              source={
                source.syndicated_source ? 'syndicated' : source.source_type
              }
              style={iconStyle}
            />
          </SourceIcon>

          <SourceTitle>
            {source.source_type !== 'File' ? (
              <Tooltip
                title={
                  source.syndicated_source
                    ? capitalize(source.syndicated_source) +
                      ' (Syndicated Source)'
                    : source.source_type + ' Product Link'
                }
                placement="bottom"
              >
                <Typography.Title ellipsis level={4}>
                  {source.syndicated_source
                    ? capitalize(source.syndicated_source)
                    : source.source_type}
                </Typography.Title>
              </Tooltip>
            ) : (
              <Typography.Title ellipsis level={4}>
                {source.syndicated_source
                  ? capitalize(source.syndicated_source) +
                    ' (Syndicated Source)'
                  : source.source_type}
              </Typography.Title>
            )}
          </SourceTitle>
        </SourceContent>
      </SourceBox>
    </>
  )
}

const openInNewTab = (url: string) => {
  const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
  if (newWindow) newWindow.opener = null
}

const SourceContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: auto;
  margin: 0 auto;
  width: 250px;
`
const SourceTitle = styled.div`
  margin-bottom: 0 px;
  overflow-x: auto;
  white-space: nowrap;
  display: flex;
  max-width: 128px;
  justify-content: center;
  align-items: center;
  position: relative;
  cursor: pointer;
`

const SourceIcon = styled.div`
  color: black;
  position: flex;
`

const SourceBox = styled.div`
  display: flex;
  width: 128px;
  height: 128px;
  margin: 10px;
  padding: 5px;
  flex: 1 0 21%;
  background: #fff;
  box-size: border-box;
  box-shadow: var(--default-box-shadow);
  color: black;
  transition: transform ease 0.1s;
  transform: scale(1);
  border-radius: var(--border-radius);
  &:hover {
    text-decoration: none;
    transform: scale(1.05);
    color: black;
  }
  cursor: pointer;
`
