import React from 'react'

const DashboardSvgIcon: React.FC = () => (
  <svg
    width="30"
    height="30"
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M0 0H13.3333V13.3333H0V0ZM16.6667 0H30V13.3333H16.6667V0ZM0 16.6667H13.3333V30H0V16.6667ZM25 16.6667H21.6667V21.6667H16.6667V25H21.6667V30H25V25H30V21.6667H25V16.6667Z"
      fill="currentColor"
    />
  </svg>
)

export default DashboardSvgIcon
