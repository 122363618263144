import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Filters, ProjectState } from '../../../../types'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { useQuery } from 'react-query'
import { getSummary } from 'features/project/features/Charts/model'
import { InnerTitle } from 'components/InnerTitle'
import { CloseOutlined, HighlightOutlined } from '@ant-design/icons'
import { Loader } from 'shared/components'
import { SEARCH_HIGHLIGHT_COLOR } from '../../../../utils'
import mixpanel from '../../../../../trackers/mixpanel'
import {
  buttonBlue,
  buttonHover,
  cardBorder,
  cardBorderGrey,
  darkBlueFontColor,
  disabledButtonBackground,
  lightBlueFontColor,
  lightBlueFontColorHalfOpacity,
  lightText,
  mainText,
  pageBackgroundGrey,
  secondaryNavy,
  secondaryRed,
  updatedPdpBackground,
} from 'assets/styles/variables'
import { Stats } from '../../../summary/components/Stats'
import _ from 'lodash'
import { OpenInNewOutlined } from '@mui/icons-material'
import { Divider } from '@mui/material'
import YogiDivider from 'components/UI/YogiDivider'
import { getItemPDPAnalysis } from '../../model'
import { useHistory } from 'react-router'
import CopyButton from '../CopyButton'
import HighlightedText from '../HighlightedText'
import { Switch, Tooltip } from 'antd'
import LoadingText from 'components/Loading/LoadingText'
import { Link } from 'react-router-dom'
import { YogiButton } from 'components/UI/YogiButton'
import { YogiInput } from 'components/UI/YogiInput'
import Temperature from '../Temperature'
import { Product, ProductHierarchyInfo } from '../../../products/types'
import { YogiSelect } from 'components/UI/YogiSelect'
import useThemes from '../../../../hooks/useThemes'
import ProductSelector from '../ProductSelector'
import usePDP from '../../../../hooks/usePDP'
import PDPProductSelector from 'features/project/features/PDP/Components/Analysis/components/PDPProductSelector/PDPProductSelector'
import TemperatureSelect from 'features/project/features/PDP/Components/Analysis/components/TemperatureSelect/TemperatureSelect'
import PDPCopyButton from 'features/project/features/PDP/Components/Analysis/components/PDPCopyButton/PDPCopyButton'
import { PDPLoader } from 'components/Loading/PDPLoader/PDPLoader'

type Props = {
  item: ProductHierarchyInfo
  filterValues: Filters
  tier1: string
  chartId: string
  disabled?: boolean
}

const Analysis: React.FC<Props> = ({ item, filterValues, tier1, chartId }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const history = useHistory()
  const { getNormalizedThemeName } = useThemes()

  const [enableHighlights, setEnableHighlights] = React.useState<boolean>(true)
  const [temperature, setTemperature] = React.useState<number>(1)
  const [selectedItem, setSelectedItem] = React.useState<Product | undefined>()
  const [selectedUrl, setSelectedUrl] = React.useState<string | null>(null)
  const [userInput, setUserInput] = React.useState<string>('')
  const [userKeywords, setUserKeywords] = React.useState<string[]>([])
  const [committedKeywords, setCommittedKeywords] = React.useState<string[]>([])

  // if an item has been selected, grab a URL to use for the PDP
  useEffect(() => {
    if (selectedItem) {
      setSelectedUrl(
        selectedItem.sources
          .sort((a, b) => a.source_url?.localeCompare(b.source_url))
          .sort((a, b) => {
            const containsStringA = a.source_url?.includes('www.amazon.com/')
            const containsStringB = b.source_url?.includes('www.amazon.com/')

            if (containsStringA && !containsStringB) {
              return -1 // a should come before b
            } else if (!containsStringA && containsStringB) {
              return 1 // b should come before a
            } else {
              return 0 // no change in order
            }
          })
          .find((el) => el.source_type === 'Amazon')?.source_url ?? null,
      )
    }
  }, [selectedItem])

  // update url params to reflect item
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    if (urlParams.get('item') !== selectedItem?.uuid) {
      if (selectedItem?.uuid) {
        urlParams.set('item', selectedItem.uuid)
        history.push(`${window.location.pathname}?${urlParams.toString()}`)
      }
      setCommittedKeywords([...userKeywords])

      if (selectedItem?.uuid) {
        mixpanel.track('pdp insights', {
          action: 'view',
          product: item.hierarchy,
          item: selectedItem?.uuid,
        })
      }
    }
  }, [selectedItem])

  const toggleSwitch = (checked: boolean) => {
    setEnableHighlights(checked)
  }

  const {
    data: analysisResponseObj,
    isLoading: analysisLoading,
    isFetching: analysisFetching,
    refetch,
  } = useQuery(
    ['pdpAnalysis', committedKeywords, selectedUrl],
    () =>
      getItemPDPAnalysis(
        projectId,
        selectedItem!.name,
        committedKeywords,
        selectedUrl,
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !!selectedItem && !!selectedUrl,
    },
  )

  const analysisResponse =
    analysisResponseObj?.[
      temperature === 1 ? 'temp1' : temperature === 2 ? 'temp2' : 'temp3'
    ]

  const { handleKeywordClick, handleThemeClick } = usePDP()

  return (
    <OverviewContainer>
      <ProductSelectorWrapper className={'product-selector'}>
        <PDPProductSelector
          hierarchy={item.hierarchy}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      </ProductSelectorWrapper>
      {selectedUrl && (
        <>
          {analysisLoading || !analysisResponse || analysisFetching ? (
            <LoadingWrapper
              style={{
                height: '100%',
                width: '100%',
                padding: 8,
              }}
            >
              <div
                style={{
                  top: '40%',
                  display: 'flex',
                  flexDirection: 'column',
                  marginTop: 50,
                  marginBottom: 50,
                }}
              >
                <PDPLoader />
                <LoaderTitle>We Are Turning Data to Sentences</LoaderTitle>
                {/*<LoaderTitle>*/}
                {/*  {!!selectedItem?.uuid ? (*/}
                {/*    <LoadingText*/}
                {/*      key={selectedItem?.uuid}*/}
                {/*      strings={[*/}
                {/*        'Fetching PDP from Amazon',*/}
                {/*        'Calculating Themes and Keywords',*/}
                {/*        'Modifying PDP Content',*/}
                {/*        'Making A List, Checking It Twice',*/}
                {/*        'Wrapping Things Up',*/}
                {/*      ]}*/}
                {/*      interval={8000}*/}
                {/*    />*/}
                {/*  ) : (*/}
                {/*    <LoadingText*/}
                {/*      key={selectedItem?.uuid}*/}
                {/*      strings={['Getting Product Data']}*/}
                {/*      speed={5}*/}
                {/*    />*/}
                {/*  )}*/}
                {/*</LoaderTitle>*/}
              </div>
            </LoadingWrapper>
          ) : (
            <div style={{ display: 'flex', gap: 20 }}>
              <div style={{ flexBasis: '50%' }}>
                <div style={{ flexGrow: 1 }}>
                  <SectionTitle style={{ marginBottom: 10 }}>
                    Selected Keywords and Themes
                  </SectionTitle>
                  <div
                    style={{
                      marginTop: 10,
                      marginBottom: 10,
                      display: 'flex',
                      gap: 10,
                      alignItems: 'center',
                    }}
                  >
                    <form
                      onSubmit={(e) => {
                        e.preventDefault()
                        if (userInput) {
                          setUserKeywords([...userKeywords, userInput.trim()])
                          setUserInput('')
                        }
                      }}
                      style={{ flexGrow: 1 }}
                    >
                      <YogiInput
                        placeholder={'Add Keywords'}
                        value={userInput}
                        onChange={(event) => {
                          setUserInput(event.target.value)
                        }}
                      />
                    </form>
                    <YogiButton
                      disabled={!userInput}
                      onClick={() => {
                        if (userInput) {
                          setUserKeywords([...userKeywords, userInput.trim()])
                          setUserInput('')
                        }
                      }}
                    >
                      Add
                    </YogiButton>
                    <YogiButton
                      type="dashed"
                      onClick={() => {
                        setUserKeywords([])
                      }}
                    >
                      Clear
                    </YogiButton>
                  </div>
                  <PillContainer
                    style={{
                      overflow: 'visible',
                      width: '100%',
                      flexGrow: 1,
                      flexWrap: 'wrap',
                      minHeight: 0,
                    }}
                  >
                    {analysisResponse.keywords
                      .filter((el) =>
                        analysisResponse.explanation.keywords_used.includes(
                          el.keyword,
                        ),
                      )
                      .sort((a, b) => b.sentiment - a.sentiment)
                      .map((el) => (
                        // todo make these separate components -
                        //  can precalc the hashcode so theres no so much delay
                        <Tooltip
                          title={
                            <div>
                              <div>Mentions: {el.sentences}</div>
                              <div>Sentiment: {el.sentiment}</div>
                            </div>
                          }
                        >
                          <KeywordPill
                            color={el.color}
                            onClick={() => handleKeywordClick(el.keyword)}
                          >
                            <ColorBar color={el.color} />
                            <div>{el.keyword}</div>
                            {/*<Divider style={{ borderColor: '#c2c2c2' }} />*/}
                            {/*<div*/}
                            {/*  style={{*/}
                            {/*    display: 'flex',*/}
                            {/*    margin: '5px 0px 3px',*/}
                            {/*    fontSize: 14,*/}
                            {/*    fontWeight: 400,*/}
                            {/*  }}*/}
                            {/*>*/}
                            {/*  <div style={{ minWidth: 25 }}>{el.sentences}</div>*/}
                            {/*  <div>*/}
                            {/*    <Divider*/}
                            {/*      orientation={'vertical'}*/}
                            {/*      style={{*/}
                            {/*        borderColor: '#c2c2c2',*/}
                            {/*        margin: '0px 5px',*/}
                            {/*      }}*/}
                            {/*    />*/}
                            {/*  </div>*/}
                            {/*  <div style={{ marginLeft: 2 }}>*/}
                            {/*    {el.sentiment > 0 && '+'}*/}
                            {/*    {el.sentiment}*/}
                            {/*  </div>*/}
                            {/*</div>*/}
                          </KeywordPill>
                        </Tooltip>
                      ))}

                    {!!userKeywords.length && (
                      <>
                        {userKeywords.map((el) => (
                          <KeywordPill
                            color={secondaryNavy}
                            onClick={() => handleKeywordClick(el)}
                            style={{ position: 'relative' }}
                          >
                            <ColorBar color={secondaryNavy} />
                            <div>{el}</div>
                            <CloseButton
                              className={'close-button'}
                              onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                setUserKeywords(
                                  userKeywords.filter(
                                    (keyword) => keyword !== el,
                                  ),
                                )
                              }}
                            >
                              <CloseOutlined />
                            </CloseButton>
                          </KeywordPill>
                        ))}
                      </>
                    )}
                  </PillContainer>

                  {temperature > 1 && (
                    <>
                      <Divider
                        style={{
                          borderColor: '#c2c2c2',
                          marginTop: 10,
                          marginBottom: 5,
                        }}
                      />
                      <h3 style={{ marginBottom: 0 }}>Themes</h3>
                      <PillContainer
                        style={{
                          width: '100%',
                          flexGrow: 1,
                          flexWrap: 'wrap',
                          minHeight: 0,
                        }}
                      >
                        {analysisResponse.themes
                          .filter(
                            (el) => el.sentences > 5 && el.sentiment >= 0.3,
                          )
                          .sort((a, b) => b.sentiment - a.sentiment)
                          .map((el, index) => (
                            <Tooltip
                              title={
                                <div>
                                  <div>Sentences: {el.sentences}</div>
                                  <div>Sentiment: {el.sentiment}</div>
                                </div>
                              }
                            >
                              <KeywordPill
                                color={el.color}
                                onClick={() => handleThemeClick(el.theme)}
                              >
                                <ColorBar color={el.color} />
                                {getNormalizedThemeName(el.theme)
                                  .split(': ')
                                  .map((part, index) => (
                                    <div>
                                      {part}
                                      {!index &&
                                        getNormalizedThemeName(el.theme).split(
                                          ': ',
                                        ).length > 1 &&
                                        ':'}
                                    </div>
                                  ))}
                              </KeywordPill>
                            </Tooltip>
                          ))}
                      </PillContainer>
                    </>
                  )}

                  {/*<div style={{ marginTop: 10 }}>*/}
                  {/*  <YogiDivider style={{ marginTop: 5, marginBottom: 5 }} />*/}
                  {/*  /!*<div style={{ color: lightText }}>*!/*/}
                  {/*  /!*  * Reviews from all Product listings are used to identify*!/*/}
                  {/*  /!*  Keywords and Themes*!/*/}
                  {/*  /!*</div>*!/*/}
                  {/*</div>*/}
                </div>
                <WidgetWrapper>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      alignItems: 'center',
                      marginBottom: 20,
                      marginTop: 20,
                    }}
                  >
                    <SectionTitle>Current</SectionTitle>
                    {selectedItem &&
                      selectedItem.sources.filter(
                        (el) => el.source_type === 'Amazon',
                      ).length > 1 && (
                        <YogiSelect
                          style={{ width: 300 }}
                          value={selectedUrl}
                          defaultValue={selectedUrl}
                          onChange={(value: string) => setSelectedUrl(value)}
                          options={selectedItem?.sources
                            .filter((el) => el.source_type === 'Amazon')
                            .map((el) => ({
                              // label: new URL(el.source_url).hostname,
                              label: el.source_url?.split('//')[1],
                              value: el.source_url,
                            }))
                            .sort((a, b) => a.label.localeCompare(b.label))}
                        />
                      )}
                    {selectedUrl && (
                      <a
                        href={selectedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ textDecoration: 'none' }}
                      >
                        <YogiButton
                          type="dashed"
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: 5,
                            // padding: '2px 5px',
                            marginTop: -3,
                          }}
                        >
                          Open PDP <OpenInNewOutlined fontSize={'small'} />
                        </YogiButton>
                      </a>
                    )}
                  </div>
                  {/*<YogiDivider style={{ marginBottom: 5 }} />*/}
                  <PDPTitle>{analysisResponse.before.listing_title}</PDPTitle>
                  <PDPContent>
                    <p>{analysisResponse.before.description}</p>
                    {typeof analysisResponse.before.features === 'object' ? (
                      <ul>
                        {analysisResponse.before.features.map((el) => (
                          <li>{el}</li>
                        ))}
                      </ul>
                    ) : (
                      <div>
                        <p>{analysisResponse.before.features}</p>
                      </div>
                    )}
                    {!analysisResponse.before.description && (
                      <div>
                        <YogiDivider style={{ marginBottom: 5 }} />
                        <div style={{ color: lightText }}>
                          * No description provided on current PDP
                        </div>
                      </div>
                    )}
                  </PDPContent>
                </WidgetWrapper>
              </div>

              <AnalysisWrapper>
                <TemperatureSelect
                  temperature={temperature}
                  setTemperature={setTemperature}
                />
                <UpdatedPDPContainer>
                  <RegenerateButton
                    onClick={() => {
                      if (!_.isEqual(userKeywords, committedKeywords)) {
                        setCommittedKeywords([...userKeywords])
                        refetch()
                      }
                    }}
                    disabled={_.isEqual(userKeywords, committedKeywords)}
                    // style={{
                    //   background: _.isEqual(userKeywords, committedKeywords)
                    //     ? 'grey!important'
                    //     : '',
                    // }}
                  >
                    <div>Regenerate PDP</div>
                    <svg
                      width="12"
                      height="13"
                      viewBox="0 0 12 13"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M10.8717 5.33722L11.5987 4.57167C12.1338 4.00833 12.1338 3.09111 11.5987 2.52778L10.6316 1.50944C10.0966 0.946111 9.22549 0.946111 8.69048 1.50944L7.96342 2.275L10.8717 5.33722ZM6.98943 3.29333L0.68591 9.93778V13H3.59417L9.89769 6.36278L6.98943 3.29333ZM10.9746 10.4722C10.9746 12.0539 9.23235 13 7.54501 13C7.16776 13 6.8591 12.675 6.8591 12.2778C6.8591 11.8806 7.16776 11.5556 7.54501 11.5556C8.60131 11.5556 9.60274 11.0283 9.60274 10.4722C9.60274 10.1328 9.27351 9.84389 8.75907 9.60555L9.77422 8.53667C10.5081 8.99167 10.9746 9.59833 10.9746 10.4722ZM1.08374 7.475C0.418405 7.07056 0 6.54333 0 5.77778C0 4.47778 1.29637 3.87833 2.44184 3.35111C3.14833 3.01889 4.11546 2.57111 4.11546 2.16667C4.11546 1.87056 3.58045 1.44444 2.74364 1.44444C1.87939 1.44444 1.509 1.885 1.48843 1.90667C1.24836 2.20278 0.816233 2.23889 0.528151 1.99333C0.246928 1.74778 0.192055 1.30722 0.425264 0.996667C0.500714 0.895556 1.2072 0 2.74364 0C4.28008 0 5.48728 0.953333 5.48728 2.16667C5.48728 3.51722 4.16348 4.13111 2.99057 4.67278C2.34581 4.96889 1.37182 5.41667 1.37182 5.77778C1.37182 6.00167 1.66676 6.21111 2.10574 6.39889L1.08374 7.475Z"
                        fill="white"
                      />
                    </svg>
                  </RegenerateButton>
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'end',
                      alignItems: 'center',
                      marginBottom: 30,
                      gap: 20,
                    }}
                  >
                    {/*<SectionTitle>Updated</SectionTitle>*/}

                    <PDPCopyButton
                      textToCopy={`${analysisResponse.after.listing_title}\n\n${analysisResponse.after.description}\n\n${analysisResponse.after.features
                        .map((str) => `• ${str}`)
                        .join('\n')}}`}
                    />
                    <StyledSwitch
                      defaultChecked
                      checked={enableHighlights}
                      onChange={toggleSwitch}
                      checkedChildren={<HighlightOutlined />}
                      unCheckedChildren={<HighlightOutlined />}
                    />
                  </div>

                  {/*<YogiDivider style={{ marginBottom: 5 }} />*/}
                  {/*<AfterTitle*/}
                  {/*  dangerouslySetInnerHTML={{*/}
                  {/*    __html: highlightKeywords(*/}
                  {/*      analysisResponse.before.listing_title,*/}
                  {/*      analysisResponse.after.listing_title,*/}
                  {/*      analysisResponse.keywords.map((el) => el.keyword)*/}
                  {/*    ),*/}
                  {/*  }}*/}
                  {/*></AfterTitle>*/}
                  <PDPTitle
                    className={'copy-container'}
                    style={{ position: 'relative' }}
                  >
                    {/*<CopyButton textToCopy={analysisResponse.after.listing_title} />*/}
                    {/*<HighlightedText*/}
                    {/*  text={analysisResponse.after.listing_title}*/}
                    {/*  keywordsToHighlight={*/}
                    {/*    analysisResponse.explanation.keywords_used*/}
                    {/*  }*/}
                    {/*  keywords={analysisResponse.keywords}*/}
                    {/*  disabled={!enableHighlights}*/}
                    {/*/>*/}
                    <div>{analysisResponse.before.listing_title}</div>
                  </PDPTitle>
                  <PDPContent
                    className={'copy-container'}
                    style={{ position: 'relative' }}
                  >
                    {/*<CopyButton*/}
                    {/*  textToCopy={analysisResponse.after.description}*/}
                    {/*/>*/}

                    <Description>
                      <HighlightedText
                        key={temperature}
                        text={analysisResponse.after.description}
                        keywordsToHighlight={
                          analysisResponse.explanation.keywords_used
                        }
                        userKeywords={committedKeywords}
                        keywords={analysisResponse.keywords}
                        disabled={!enableHighlights}
                        filters={filterValues}
                      />
                    </Description>
                  </PDPContent>
                  <PDPContent
                    className={'copy-container'}
                    style={{ position: 'relative', paddingLeft: 20 }}
                  >
                    {/*<CopyButton*/}
                    {/*  textToCopy={analysisResponse.after.features*/}
                    {/*    .map((str) => `• ${str}`)*/}
                    {/*    .join('\n')}*/}
                    {/*/>*/}
                    <ul>
                      {analysisResponse.after.features.map((el, index) => (
                        <StyledListItem
                          className={'copy-container'}
                          style={{ position: 'relative' }}
                        >
                          <HighlightedText
                            text={el}
                            keywordsToHighlight={
                              analysisResponse.explanation.keywords_used
                            }
                            userKeywords={committedKeywords}
                            keywords={analysisResponse.keywords}
                            disabled={!enableHighlights}
                            filters={filterValues}
                          ></HighlightedText>
                        </StyledListItem>
                      ))}
                    </ul>
                  </PDPContent>
                  <YogiDivider style={{ marginBottom: 10 }} />
                  <PDPContent style={{ color: lightText }}>
                    * Generative tools may create unexpected results - make sure
                    output conforms to your PDP guidelines
                  </PDPContent>
                  {analysisResponse.after.description.length > 1000 && (
                    <PDPContent style={{ color: secondaryRed, marginTop: 10 }}>
                      * Warning - Description is longer than 1000 characters. A
                      Description of less than 1000 characters is recommended
                    </PDPContent>
                  )}
                  {analysisResponse.after.features.join().length > 1000 && (
                    <PDPContent style={{ color: secondaryRed, marginTop: 10 }}>
                      * Warning - Feature Bullets are longer than 1000
                      characters. A Feature Bullet section of less than 1000
                      characters (200 characters each) is recommended
                    </PDPContent>
                  )}
                </UpdatedPDPContainer>
              </AnalysisWrapper>
            </div>
          )}
        </>
      )}
    </OverviewContainer>
  )
}

export default Analysis

const OverviewContainer = styled.div`
  height: 100%;
  width: 100%;
  //max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 8px;
  //justify-content: center;
  overflow: auto;
  padding: var(--default-padding-quarter);
`

const TitleWrapper = styled.div`
  background: white;
  border-top-left-radius: var(--border-radius);
  border-top-right-radius: var(--border-radius);
  margin-bottom: -5px;
`

const ChartContainer = styled.div`
  height: 250px;
`

const LoadingWrapper = styled.div`
  //height: 125px;

  height: 100%;
  width: 100%;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
`

const WidgetWrapper = styled.div`
  min-height: 100%;
  width: 100%;
  //background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
  position: relative;
`

const ProductSelectorWrapper = styled.div`
  border-radius: var(--border-radius);
  margin-bottom: 10px;
`

const KeywordWrapper = styled.div`
  width: 100%;
  background: white;
  border-radius: var(--border-radius);
  padding: var(--default-padding-half);
  display: flex;
  gap: 10px;
  //align-items: center;
  position: relative;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`

const OpenInNewOutlinedStyled = styled(OpenInNewOutlined)`
  transition:
    fill 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
    color 0.2s ease !important;

  &:hover {
    color: ${buttonBlue};
  }
`

const AnalysisWrapper = styled.div`
  flex-basis: 50%;
`

const StyledListItem = styled.li`
  .highlight {
    background-color: ${SEARCH_HIGHLIGHT_COLOR};
    //color: white;
  }
`

const AfterTitle = styled.h2`
  color: var(--color-text-dark-grey);
  .highlight {
    background-color: ${SEARCH_HIGHLIGHT_COLOR};
    //color: white;
  }
`
const Description = styled.p`
  .highlight {
    background-color: ${SEARCH_HIGHLIGHT_COLOR};
    //color: white;
  }
`

const PillContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  min-height: 50px;
  width: 100%;
  overflow: visible;
  white-space: nowrap;
`

const KeywordPill = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
  //align-items: center;
  justify-content: center;
  //flex-direction: row;
  padding: 2px 15px;
  //border: 2px solid ${(props) => props.color ?? secondaryNavy};
  border: 1px solid #eff0f6;
  border-radius: 8px;
  background: ${pageBackgroundGrey};
  font-weight: 500;
  font-size: 12px;
  //cursor: pointer;
  transform: translate(0%);
  transition: 0.15s ease-out;
  cursor: pointer;
  box-shadow: var(--default-box-shadow);

  &:hover {
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.05);
    .close-button {
      display: flex;
    }
  }
`

const ColorBar = styled.div<{ color?: string }>`
  width: 5px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
  height: 100%;
  position: absolute;
  top: 0px;
  left: 0px;
  border-top-left-radius: var(--border-radius);
  border-bottom-left-radius: var(--border-radius);
`
const StyledSwitch = styled(Switch)`
  background-color: ${(props) =>
    props.checked ? secondaryNavy : 'rgba(0, 0, 0, 0.25)'};

  .ant-switch-inner {
    font-size: 12px;
  }
`

const StyledLink = styled(Link)`
  //  display: flex;
  //  align-items: center;
  //  width: 100%;
  //  justify-content: space-between;
  color: var(--main-text);
  &:hover {
    text-decoration: none;
    color: var(--main-text);
  }
`
const CloseButton = styled.div`
  position: absolute;
  display: none;
  align-items: center;
  justify-content: center;
  right: -7px;
  top: -7px;
  font-size: 12px;
  cursor: pointer;
  color: ${mainText};
  background: ${pageBackgroundGrey};
  border: 1px solid ${mainText};
  border-radius: 50%;
  width: 20px;
  height: 20px;
  line-height: 1;
  &:hover {
    background: ${cardBorderGrey};
  }
`

const SectionTitle = styled.div`
  font-size: 14px;
  //margin-bottom: 10px;

  color: ${lightBlueFontColor};
  font-weight: 600;
`

const PDPTitle = styled.div`
  font-size: 14px;
  font-weight: 600;
  color: ${darkBlueFontColor};
  margin-bottom: 20px;
`

const PDPContent = styled.div`
  font-size: 14px;
  line-height: 28px;
`

const UpdatedPDPContainer = styled.div`
  position: relative;
  padding: 10px 15px 20px 35px;
  margin-top: 40px;
  background: ${updatedPdpBackground};
  border-radius: 20px;
`

const RegenerateButton = styled.div<{ disabled: boolean }>`
  position: absolute;
  left: 0;
  top: 0;
  min-width: 200px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: 500;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  padding: 14px 25px;
  border-radius: 10px;

  color: white;

  transition: all 0.2s ease;

  box-shadow: 0 4px 4px 0 #00000040;

  background: ${({ disabled }) =>
    disabled ? disabledButtonBackground : buttonBlue};

  &:hover {
    background: ${({ disabled }) =>
      disabled ? disabledButtonBackground : buttonHover};
  }
`
