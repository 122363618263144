import React, { useRef, forwardRef, useImperativeHandle } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { generateReadableFilters } from '../../../utils'
import html2canvas from 'html2canvas'
import { emptyFilters } from '../../../../../utils'

export interface DraggableChartCardsProps {
  item: any
  index: string
}

export interface WidgetHandle {
  onExportChart: () => Promise<
    { base64: string; aspectRatio: number } | undefined
  >
  index: string
}

export const TextWidget = forwardRef<WidgetHandle, DraggableChartCardsProps>(
  ({ item, index }, ref) => {
    const defaultFilterList = useProjectStore(
      (state: ProjectState) => state.defaultFilterList
    )

    const widgetRef = useRef<HTMLDivElement>(null)

    useImperativeHandle(
      ref,
      () => ({
        onExportChart: async () => {
          if (widgetRef.current) {
            if (widgetRef.current && item) {
              let canvas
              canvas = await html2canvas(widgetRef.current, { scale: 2 })
              const base64 = canvas.toDataURL('image/jpg')

              const returnObj = {
                base64,
                aspectRatio: canvas.width / canvas.height,
                title: 'test',
                filters: generateReadableFilters(
                  emptyFilters,
                  defaultFilterList
                ),
                text: item.content,
                type: 'text',
              }
              return returnObj
            }
          }
        },
        index: index ?? '0',
      }),
      [item]
    )

    return (
      <div
        ref={widgetRef}
        style={{
          background: 'white',
          padding: 10,
          borderRadius: 8,
          height: '100%',
          overflow: 'auto',
        }}
      >
        <Text dangerouslySetInnerHTML={{ __html: item.content }} />
      </div>
    )
  }
)

const Text = styled.div`
  p {
    font-size: 1em;
  }
`
