import React, { useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { DatePicker, Form, Input, Tooltip, Button, Checkbox } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SourceProps } from '../source-providers'
import { tooltip } from 'utils/tooltip-data'

const dateFormat = 'MM/DD/YYYY'

export const Facebook = ({ formik }: SourceProps) => {
  const connect = useCallback(async (pages_messaging: boolean | undefined) => {
    // @ts-ignore
    let FB = window.FB

    new Promise((resolve, reject) => {
      FB.login(
        function (r: any) {
          if (r.authResponse) {
            resolve(r.authResponse)
          } else {
            reject(false)
          }
        },
        {
          scope: pages_messaging
            ? 'pages_read_engagement,pages_show_list,pages_read_user_content,pages_messaging'
            : 'pages_read_engagement,pages_show_list,pages_read_user_content',
        }
      )
    })
      .then(
        (authResponse: any) =>
          new Promise((resolve, reject) => {
            FB.api(`${authResponse.userID}/accounts`, function (response: any) {
              if (response.data) {
                formik.setFieldValue(
                  'access_token',
                  response.data[0].access_token
                )
                formik.setFieldValue('page_id', response.data[0].id)
                if (pages_messaging === undefined)
                  formik.setFieldValue('process_direct_messages', false)
                resolve(true)
              } else {
                reject(false)
              }
            })
          })
      )
      .catch((e: any) => {
        console.log('Error', e)
        alert(
          'Something went wrong. Please try again from a new tab, or try again with a hard refresh (Shift+Ctrl+R)'
        )
      })
  }, [])

  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceTitle ? 'error' : ''}
        help={formik.errors.sourceTitle}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['form-date']}>
            <span>
              Date <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sourceDate ? 'error' : ''}
        help={formik.errors.sourceDate}
      >
        <DatePicker
          defaultValue={
            formik.values.sourceDate && moment(formik.values.sourceDate)
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue('sourceDate', value.toDate())
            }
          }}
          format={dateFormat}
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['direct-messages']}>
            <span>
              Direct Messages <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
      >
        <Checkbox
          name="process_direct_messages"
          checked={formik.values.process_direct_messages}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <ButtonWrapper>
        <Error>{formik.submitCount > 0 && formik.errors.access_token}</Error>
        <Button
          type="primary"
          size="large"
          onClick={() => connect(formik.values.process_direct_messages)}
        >
          Connect with Facebook
        </Button>
      </ButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  max-width: 200px;
  margin: 0 auto;
`
const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
`
