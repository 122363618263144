type ValidParams = string | number
type InvalidParams = null | undefined
type ReturnValue = string

export function numberFormatter<T extends InvalidParams>(param: T): T
export function numberFormatter<T extends ValidParams>(param: T): ReturnValue
export function numberFormatter(
  number: ValidParams | InvalidParams
): ReturnValue | InvalidParams {
  if (number === 'NaN') return 'No Value'
  if (number === null || number === undefined) {
    return number
  }
  if (isNaN(Number(number))) {
    return typeof number === 'string' ? number : number.toLocaleString()
  }
  // let result = (
  //   typeof number === 'string' ? number : number.toString()
  // ).replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  // if (result.slice(-3) === '.00') {
  //   if (+number >= 10 || +number === 0) {
  //     result = result.slice(0, -3)
  //   } else {
  //     result = result.slice(0, -1)
  //   }
  // }
  // const decIndex = result.indexOf('.')
  // if (decIndex === -1) {
  //   return result
  // }
  // result.slice(0, decIndex + 2)
  // return result
  return Number(Number(number).toFixed(2)).toLocaleString()
}

// export function newNumberFormatter(number: string | number): string {
//   return Number(Number(number).toFixed(2)).toLocaleString()
// }
