import { useQuery } from 'react-query'
import { getDashboards } from '../features/dashboard/model'
import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'

const useCustomDashboards = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const getDashboardsQuery = useQuery(
    ['custom-dashboards', projectId],
    () => {
      return getDashboards(projectId)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )
  return {
    ...getDashboardsQuery,
  }
}

export default useCustomDashboards
