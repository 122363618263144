import React, { useEffect, useMemo, useState } from 'react'
import { Table, Tooltip } from 'antd'
import { numberFormatter } from 'utils/numberFormat'
import {
  PostType,
  TableChart as TableChartType,
  TableChart as TableChartNew,
  TimeInterval,
} from '../types'
import styled from 'styled-components'
import { useProjectStore } from '../../../projectStore/projectStore'
import { Filters, ProjectState, TableChartData } from '../../../types'
import _ from 'lodash'
import { useChartData } from '../../../hooks/useChartData'
import { AppliedFilterOptionRange } from '../../filters/types'
import {
  mainText,
  secondaryRed,
  tertiaryGreen,
} from '../../../../../assets/styles/variables'
import { FallOutlined, LoadingOutlined, RiseOutlined } from '@ant-design/icons'
import { getAdjustedDateRangeString } from '../utils'
import { findDateWindow } from '../../../utils'
import { usePrevFilters } from '../../../hooks/usePrevFilters'

type Props = {
  data: TableChartType | TableChartNew
  pagination?: boolean
  orderedTableData: TableChartData[]
  isAlignOrder?: boolean
  setIsAlignOrder?: (a: any) => void
  comparativeIndex?: number
  rowContextCb?: (e: React.MouseEvent, row: any) => void
  filterValues: Filters
  tier1?: string
  timeInterval?: TimeInterval
  postType: PostType
  disabled?: boolean
  isDelta?: boolean
  showPercentChange?: boolean
  onPageChange?: (page: number, pageSize: number) => void
  pageSize?: number
  bulkUpdateDashboardControls?: (dashboardControls: any) => void
  tableSort?: { columnKey: string; order: string }
  dashboardControls?: any
  prevRange: AppliedFilterOptionRange | undefined
  setPrevRange: (hasPrev: AppliedFilterOptionRange) => void
  expandedCategories: string[]
}

export const TableChart: React.FC<Props> = React.memo(function ({
  data,
  orderedTableData,
  isAlignOrder,
  setIsAlignOrder,
  comparativeIndex,
  pagination = true,
  rowContextCb,
  filterValues,
  tier1,
  timeInterval,
  postType,
  disabled,
  isDelta,
  showPercentChange,
  onPageChange,
  pageSize,
  bulkUpdateDashboardControls,
  dashboardControls,
  tableSort,
  prevRange,
  setPrevRange,
  expandedCategories,
}) {
  // TODO integrate expanded categories
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )

  const setOrderedTableData = useProjectStore(
    (state: ProjectState) => state.setOrderedTableData
  )
  const dateWindows = useProjectStore((state) => state.dateWindows)

  const { newDelta, dateFilter, prevFilters } = usePrevFilters({
    filters: filterValues,
    dateWindows,
    defaultFilterList,
    prevIsDelta: !!isDelta,
  })

  useEffect(() => {
    updateDashboardControls(comparativeIndex || 0, 'isDelta', newDelta)

    if (!_.isEqual(prevRange, dateFilter)) {
      setPrevRange(dateFilter as AppliedFilterOptionRange)
    }
  }, [dateFilter, newDelta, prevRange])

  const {
    data: prevChartData,
    isLoading,
    isError,
  } = useChartData(
    0,
    prevFilters,
    tier1,
    data.chart_id,
    postType,
    timeInterval,
    0,
    disabled || !filterValues || !dateFilter
  )

  const prevData = prevChartData?.charts[0] as TableChartType
  let prevValues = prevData?.values || []
  let values = data.values
  if (data.category_mapping && data.parent_values) {
    const parentValues = _.cloneDeep(data.parent_values)
    parentValues.forEach((value: any, index: number) => {
      if (
        (tier1 === 'Theme' &&
          data.category_mapping[value.category]?.length > 1) ||
        (tier1 !== 'Theme' && data.category_mapping[value.category]?.length > 0)
      ) {
        value.nestedData = values.filter((el: any) =>
          data.category_mapping[value.category].includes(el.category)
        )
        if (tier1 === 'Theme') {
          value.nestedData = value.nestedData.map((el: any) => ({
            ...el,
            category: el.category.split(': ')[1],
          }))
        }
      }
      value.key = index
    })
    values = parentValues

    if (prevData) {
      const prevParentValues = _.cloneDeep(prevData.parent_values)
      prevParentValues.forEach((value: any, index: number) => {
        if (
          (tier1 === 'Theme' &&
            prevData.category_mapping[value.category]?.length > 1) ||
          (tier1 !== 'Theme' &&
            prevData.category_mapping[value.category]?.length > 0)
        ) {
          value.nestedData = prevValues.filter((el: any) =>
            prevData.category_mapping[value.category].includes(el.category)
          )
        }
        value.key = index
      })
      prevValues = prevParentValues
    }
  }

  const prevPeriodText = useMemo(() => {
    const label = findDateWindow(
      dateWindows,
      prevRange?.min,
      prevRange?.max
    )?.label

    return label
      ? `in ${label}`
      : `from ${getAdjustedDateRangeString(prevRange)}`
  }, [prevRange])

  const [activeValues, setActiveValues] = useState(values)
  const [diffValues, setDiffValues] = useState<any[]>([])

  const [orderedData, setOrderedData] = useState<any[]>(values)
  const [order, setOrder] = useState<any | undefined>()

  useEffect(() => {
    setOrder(tableSort)
    setOrderedData(values)
  }, [data])

  useEffect(() => {
    if (comparativeIndex === 0) {
      setOrderedTableData(orderedData)
    }
  }, [orderedData])

  useEffect(() => {
    if (isAlignOrder && comparativeIndex !== 0) {
      setOrder(null)
      const newOrderedData: any[] = []
      orderedTableData.forEach((item) => {
        // @ts-ignore
        const res = values.find((elem) => elem.category === item.category)
        if (res) {
          newOrderedData.push(res)
        }
      })
      values.forEach((item) => {
        if (!newOrderedData.includes(item)) {
          newOrderedData.push(item)
        }
      })
      setOrderedData(newOrderedData)
    } else if (comparativeIndex !== 0) {
      setOrderedData(values)
    }
  }, [orderedTableData, isAlignOrder, data])

  useEffect(() => {
    const valuesCopy = JSON.parse(JSON.stringify(orderedData))
    const diffCopy: any[] = []
    const prevValuesObj: any = {}
    prevValues.forEach((el: any) =>
      Object.assign(prevValuesObj, { [el.category]: el })
    )

    if (valuesCopy.length && valuesCopy.length && !isError && !isLoading) {
      valuesCopy.forEach((value: any, index: number) => {
        if (prevValuesObj[value.category]) {
          const prev = prevValuesObj[value.category]
          const diff: any = {
            category: value.category,
            npsf: (value.npsf - prev?.npsf).toFixed(2),
            rating: (value.rating - prev?.rating).toFixed(2),
            sentiment: (value.sentiment - prev?.sentiment).toFixed(2),
            volume: value.volume - prev?.volume,
            percent: (value.percent - prev?.percent).toFixed(2),
            color: value.color,
            type_: value.type_,
            nestedData: [],
          }
          diff.prev_npsf = value.prev_npsf = prev?.npsf
          diff.prev_rating = value.prev_rating = prev?.rating
          diff.prev_sentiment = value.prev_sentiment = prev?.sentiment
          diff.prev_volume = value.prev_volume = prev?.volume
          diff.prev_percent = value.prev_percent = prev?.percent
          value.nestedData?.forEach((nestedValue: any, index: number) => {
            const nestedPrev = prevValuesObj[value.category]?.nestedData?.find(
              (el: any) => el.category.includes(nestedValue.category)
            )

            const nestedDiff: any = {
              category: nestedValue.category,
              npsf: (nestedValue.npsf - nestedPrev?.npsf).toFixed(2),
              rating: (nestedValue.rating - nestedPrev?.rating).toFixed(2),
              sentiment: (
                nestedValue.sentiment - nestedPrev?.sentiment
              ).toFixed(2),
              volume: nestedValue.volume - nestedPrev?.volume,
              percent: (nestedValue.percent - nestedPrev?.percent).toFixed(2),
              color: nestedValue.color,
              type_: nestedValue.type_,
            }
            nestedDiff.prev_npsf = nestedValue.prev_npsf = nestedPrev?.npsf
            nestedDiff.prev_rating = nestedValue.prev_rating =
              nestedPrev?.rating
            nestedDiff.prev_sentiment = nestedValue.prev_sentiment =
              nestedPrev?.sentiment
            nestedDiff.prev_volume = nestedValue.prev_volume =
              nestedPrev?.volume
            nestedDiff.prev_percent = nestedValue.prev_percent =
              nestedPrev?.percent
            diff.nestedData.push(nestedDiff)
          })
          diffCopy.push(diff)
        } else {
          // todo prev should be 0, not sure if we care about these, maybe?
          diffCopy.push(value)
        }
        delete prevValuesObj[value.category]
      })
    }
    // todo not sure if we need this either - values that exist in previous but not current. ig current should be 0? show when doing delta? probably
    Object.keys(prevValuesObj).forEach((key) => {
      const prev = prevValuesObj[key]
      const diff: any = {
        category: prev.category,
        npsf: 'No Value',
        rating: 'No Value',
        sentiment: 'No Value',
        volume: 0 - prev?.volume,
        percent: (0 - prev?.percent).toFixed(2),
        color: prev.color,
        type_: prev.type_,
      }
      diff.prev_npsf = prev?.npsf
      diff.prev_rating = prev?.rating
      diff.prev_sentiment = prev?.sentiment
      diff.prev_volume = prev?.volume
      diff.prev_percent = prev?.percent
      diffCopy.push(diff)
    })

    diffCopy.sort((a: any, b: any) => b.volume - a.volume)
    setDiffValues(diffCopy)
    setActiveValues(valuesCopy)
  }, [orderedData, prevChartData])

  console.log(activeValues)

  useEffect(() => {
    if (comparativeIndex === 0) {
      setOrderedTableData(isDelta ? diffValues : activeValues)
    }
  }, [isDelta])

  const YogiTable = ({
    dataSource,
    isChild,
  }: {
    dataSource: any
    isChild?: boolean
  }) => (
    <StyledTable
      id="table-chart"
      dataSource={dataSource}
      style={{
        width: '100%',
        overflow: isChild ? '' : 'auto',
      }}
      // defaultExpandAllRows={true}
      sortDirections={['descend', 'ascend', 'descend']}
      onChange={(pagination, filters, sorter, extra) => {
        if (bulkUpdateDashboardControls) {
          const tempDashboardControls = JSON.parse(
            JSON.stringify(dashboardControls)
          )
          tempDashboardControls['tableSort'] = {
            // @ts-ignore
            columnKey: sorter?.columnKey,
            // @ts-ignore
            order: sorter?.order,
          }
          tempDashboardControls['pageSize'] = pagination.pageSize
          bulkUpdateDashboardControls(tempDashboardControls)
        }
        setOrder(sorter)
        setOrderedData(extra.currentDataSource)
      }}
      onRow={(record) => {
        return {
          onClick: (e) => (rowContextCb ? rowContextCb(e, record) : () => {}), // right button click row
        }
      }}
      pagination={
        !pagination || isChild
          ? false
          : {
              pageSizeOptions: ['5', '10', '20', '50', '100'],
              // onChange: onPageChange,
              defaultPageSize: pageSize || 10,
              showSizeChanger: true,
              size: 'small',
            }
      }
      showHeader={!isChild}
      expandable={
        !isChild
          ? {
              // expandedRowKeys: dataSource.map((o: any) => o.key),
              expandedRowRender: expandedRowRender,
              rowExpandable: (record: any) =>
                !!record.nestedData && !!record.nestedData.length,
              onExpand: (expanded, record) => {
                console.log(expanded, record)
              },
              onExpandedRowsChange: (expandedRows) => {
                console.log(expandedRows)
              },
              indentSize: 50,
            }
          : {}
      }
      // rowKey={'category'}
    >
      {data.col_keys.map((key, index) => (
        <StyledTableColumn
          title={data.col_titles[index]}
          dataIndex={key}
          key={key}
          className={isChild ? 'child' : ''}
          sorter={(a: any, b: any, d: any) => {
            const sortKey = key
            if (index !== 0) {
              return a[sortKey] - b[sortKey]
            }
            return a[sortKey].localeCompare(b[sortKey])
          }}
          //@ts-ignore
          sortOrder={order?.columnKey === key && order.order}
          render={(value, row: any) => {
            if (value === row.category && row.color) {
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                  }}
                >
                  <div
                    style={{
                      minWidth: '30px',
                      height: '30px',
                      backgroundColor: row.color,
                      marginRight: '10px',
                      borderRadius: 10,
                    }}
                  ></div>
                  <div>{value}</div>
                </div>
              )
            } else {
              const prev = row['prev_' + key]
              let percentChange =
                prev && (Math.round(((value - prev) / prev) * 100) || 0)
              if (isDelta) {
                percentChange = prev && (Math.round((value / prev) * 100) || 0)
              }
              if (key === 'sentiment') {
                percentChange = value - prev
              }
              if (key === 'sentiment' && isDelta) {
                percentChange = value
              }
              return (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    gap: 20,
                  }}
                >
                  {!(key === 'sentiment' && isDelta) && (
                    <div style={{ minWidth: 38 }}>
                      {isDelta && value >= 0 && '+'}
                      {numberFormatter(value)}
                    </div>
                  )}

                  {isLoading ? (
                    <LoadingOutlined />
                  ) : (
                    <>
                      {showPercentChange &&
                        !!prev &&
                        dateFilter &&
                        percentChange !== undefined &&
                        percentChange !== Infinity && (
                          <Tooltip
                            title={
                              <div>
                                {`Compared to ${numberFormatter(
                                  prev
                                )} ${prevPeriodText}`}
                              </div>
                            }
                          >
                            {value !== 'No Value' && value !== 'NaN' && (
                              <div
                                style={{
                                  display: 'flex',
                                  alignItems: 'center',
                                  gap: 10,
                                }}
                              >
                                <div
                                  style={{
                                    fontSize: '14px',
                                    // lineHeight: '1.1',
                                    fontWeight: '400',
                                    color:
                                      percentChange === 0
                                        ? mainText
                                        : percentChange > 0
                                        ? tertiaryGreen
                                        : secondaryRed,
                                  }}
                                >
                                  <>
                                    {percentChange >= 0 && '+'}
                                    {numberFormatter(percentChange)}
                                    {key !== 'sentiment' && '%'}
                                  </>
                                </div>
                                <div style={{ marginLeft: -5 }}>
                                  {percentChange === 0 ? (
                                    '-'
                                  ) : percentChange > 0 ? (
                                    <RiseOutlined
                                      style={{ color: tertiaryGreen }}
                                    />
                                  ) : (
                                    <FallOutlined
                                      style={{ color: secondaryRed }}
                                    />
                                  )}
                                </div>
                              </div>
                            )}
                          </Tooltip>
                        )}
                    </>
                  )}
                </div>
              )
            }
          }}
        />
      ))}
    </StyledTable>
  )

  const expandedRowRender = (record: any) => {
    console.log(record)
    return (
      // <Table
      //   columns={nestedColumns}
      //   dataSource={record.nestedData}
      //   pagination={false}
      //   showHeader={false}
      // />
      <YogiTable isChild dataSource={record.nestedData} />
    )
  }

  return <YogiTable dataSource={isDelta ? diffValues : activeValues} />
})

const StyledTable = styled(Table)`
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-column-sort {
    background: none;
  }

  .ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
  }

  .ant-table-pagination-right {
    margin-right: 16px;
  }
`

const StyledTableColumn = styled(Table.Column)`
  .child {
    width: 100px;
  }
`
