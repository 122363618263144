import React, { useEffect, useMemo, useState } from 'react'
import {
  Container,
  ErrorImage,
  InfoContainer,
  LoaderTitle,
  LoadingWrapper,
} from './AutoInsights.styles'
import InsightList from './components/InsightList/InsightList'
import { useQueries, useQuery } from 'react-query'
import { initialState, useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import {
  getOutlierAnalysis,
  getOutliers,
  getTimeSeriesOutliers,
  OutlierType,
} from './model'
import InsightDetail from './components/InsightDetail/InsightDetail'
import { Summary } from '../summary'
import { AlertDrawer } from '../alerts/Components/AlertDrawer'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import smartDashboard from '../../../../assets/images/graphics/smartDashboard.png'
import YogiInfo from '../../../../components/UI/YogiInfo/YogiInfo'
import {
  constructOutliersList,
  constructThemeOutlierPrompt,
  getOutlierFilterValues,
} from './utils'
import useCustomDashboards from '../../hooks/useCustomDashboards'
import { DEFAULT_MODEL } from './constants'
import mixpanel from '../../../trackers/mixpanel'
import useAutoInsights from 'features/project/features/auto-insights/hooks/useAutoInsights'
import useThemes from 'features/project/hooks/useThemes'
import { Loader } from 'shared/components'

const AutoInsights: React.FC = () => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [filterValues, setFilterValues] = useState({
    ...initialState.filters[0],
    // ...emptyFilters,
  })
  const [selectedOutlier, setSelectedOutlier] = useState<
    OutlierType | undefined
  >(undefined)
  const [activeTab, setActiveTab] = useState(0)
  const [page, setPage] = useState(1)
  const [hiddenPage, setHiddenPage] = useState(1)
  const [search, setSearch] = React.useState('')

  const incrementPage = () => {
    setPage(page + 1)
    setHiddenPage(hiddenPage + 1)
    mixpanel.track('automated insights', {
      action: 'load more',
      value: page + 1,
    })
  }

  const resetPageCount = () => {
    setPage(1)
    setHiddenPage(2)
  }

  // api
  const {
    data: outlierResponse,
    isLoading,
    isError,
  } = useQuery(
    ['outliers', projectId, filterValues],
    () => getOutliers(projectId, filterValues),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    },
  )

  const {
    data: timeSeriesOutlierResponse,
    isLoading: timeSeriesLoading,
    isError: timeSeriesError,
  } = useQuery(
    ['timeseries-outliers', projectId, filterValues],
    () => getTimeSeriesOutliers(projectId, filterValues),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
      retry: false,
    },
  )

  // "saved" insights are stored in the custom dashboards
  const { data: customDashboardsResponse } = useCustomDashboards()

  const minVolume = 10
  const pageSize = 5
  // derived constants
  const activeItems = useMemo(
    () =>
      constructOutliersList(
        outlierResponse,
        [], //timeSeriesOutlierResponse,
        customDashboardsResponse,
        activeTab,
        minVolume,
        pageSize,
        hiddenPage,
        search,
      ),
    [
      outlierResponse,
      timeSeriesOutlierResponse,
      activeTab,
      minVolume,
      pageSize,
      hiddenPage,
      search,
    ],
  )

  const activeModel = DEFAULT_MODEL //  model ?? DEFAULT_MODEL
  const results = useQueries(
    activeItems.map((outlier) => {
      // const prompt = constructThemeOutlierPrompt(outlier)

      // delete outlier.filter.criteria.find(
      //   (filter) => filter.field === 'create_time',
      // )?.values
      // delete outlier.filter.criteria.find((filter) => filter.field === 'score')
      //   ?.values
      return {
        queryKey: ['analysis', projectId, activeModel, outlier.uuid], //, prompt],
        queryFn: () =>
          getOutlierAnalysis(
            projectId,
            outlier,
            outlier.filter,
            activeModel,
            //prompt
          ),
        refetchOnWindowFocus: false,
        refetchOnMount: false,
        cacheTime: Infinity,
        staleTime: Infinity,
        enabled: !!projectId && !!outlier,
        retry: 3,
      }
    }),
  )

  const resultData =
    activeTab === 3
      ? activeItems
      : results
          .map((result) => result.data)
          .filter(Boolean)
          .slice(0, page * pageSize)

  const analysesAreLoading = results.some((result) => result.isLoading)
  const analysesAreError = results.some((result) => result.isError)
  const error = results.find((result) => result.error)?.error

  const isPageLoading = results
    .slice(0, page * pageSize)
    .some((result) => result.isLoading)
  const hasMoreOutliers = results.length !== resultData.length

  useEffect(() => {
    if (results.length) {
      if (!analysesAreError && !analysesAreLoading) {
        setHiddenPage(page + 1)
      }
    }
  }, [analysesAreLoading, analysesAreError])

  if (isError || timeSeriesError)
    return (
      <Container style={{ flexDirection: 'column', justifyContent: 'center' }}>
        <ErrorImage src={smartDashboard} />
        <InfoContainer>
          <YogiInfo color={'red'}>
            <div>
              Error fetching Automated Insights. Please try again later!
            </div>
          </YogiInfo>
        </InfoContainer>
      </Container>
    )

  if (
    isLoading ||
    timeSeriesLoading ||
    !outlierResponse ||
    !timeSeriesOutlierResponse ||
    !customDashboardsResponse ||
    (hiddenPage === 1 && analysesAreLoading)
  )
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>{`Loading Automated Insights`}</LoaderTitle>
      </LoadingWrapper>
    )

  return (
    <Container>
      <InsightList
        outliers={resultData as OutlierType[]}
        selectedOutlier={selectedOutlier ?? (resultData as OutlierType[])[0]}
        setSelectedOutlier={setSelectedOutlier}
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        filterValues={filterValues}
        incrementPage={incrementPage}
        isPageLoading={isPageLoading}
        hasMoreOutliers={hasMoreOutliers}
        search={search}
        setSearch={setSearch}
        resetPageCount={resetPageCount}
      />
      <InsightDetail
        key={(selectedOutlier ?? (resultData as OutlierType[])[0])?.uuid}
        outlier={selectedOutlier ?? (resultData as OutlierType[])[0]}
        filterValues={filterValues}
        activeTab={activeTab}
      />
      <Summary />
      <AlertDrawer />
    </Container>
  )
}

export default AutoInsights
