import { buttonBlue, buttonHover } from 'assets/styles/variables'
import { DownloadOutlined } from '@ant-design/icons'
import React, { CSSProperties } from 'react'
import styled from 'styled-components'

type Props = {
  exportFn: () => void
  style?: CSSProperties
}
export const SaveAndExportButton: React.FC<Props> = ({ exportFn, style }) => {
  return (
    <Container style={style} onClick={exportFn}>
      <DownloadOutlined />
      <Text>save & export</Text>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  gap: 5px;
  margin-right: 10px;
  cursor: pointer;
  color: ${buttonBlue};
  &:hover {
    color: ${buttonHover};
  }
`

const Text = styled.div`
  font-size: var(--font-size-md);
  white-space: nowrap;
`
