const clientSettingsDefault = {
  project: {
    toolbar: {
      alignment: null,
    },
    pages: {
      alerts: {
        alias: '',
        hidden: false,
        loading: {
          text: '',
        },
      },
      compass: {
        alias: '',
        hidden: false,
        loading: {
          text: '',
        },
      },
      insights: {
        alias: '',
        hidden: false,
        loading: {
          text: '',
        },
      },
      feedback: {
        alias: '',
        hidden: false,
        loading: {
          text: '',
        },
      },
      network: {
        alias: '',
        hidden: false,
        nodes_limit: 0,
      },
      dashboard: {
        alias: '',
        hidden: false,
        new_dashboard_button: false,
      },
      sources: {
        alias: '',
        hidden: false,
      },
      keywords: {
        alias: '',
        hidden: false,
      },
      saved_views: {
        alias: '',
        hidden: false,
      },
      products: {
        alias: '',
        hidden: false,
      },
      recommendations: {
        alias: '',
        hidden: false,
      },
      settings: {
        alias: '',
        hidden: false,
      },
      share: {
        alias: '',
        hidden: false,
      },
      catalog: {
        alias: '',
        hidden: false,
      },
      pdp: {
        alias: '',
        hidden: false,
      },
      custom_dashboards: {
        alias: '',
        hidden: false,
      },
      auto_insights: {
        alias: '',
        hidden: false,
      },
    },
    terms: { post: '', sentence: '' },
  },
}

export const detailsDefault = {
  state: '',
  title: '',
  description: '',
  cluster_event_uuid: '',
  proj_uuid: '',
  role: '',
  menu: {
    Dashboard: '',
    Feedback: '',
    Network: '',
    Sources: '',
    Products: '',
  },
  clientSettings: clientSettingsDefault,
}
