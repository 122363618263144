import { useQuery } from 'react-query'
import { getCountries } from 'features/project/features/Charts/model'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { useChartData } from 'features/project/hooks/useChartData'
import { emptyFilters } from 'features/project/utils'
import { useEffect } from 'react'

export const useDefaultChart = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const updateDashboardControls = useProjectStore(
    (state) => state.updateDashboardControls,
  )
  const updateChartData = useProjectStore((state) => state.updateChartData)
  const currentChartList = useProjectStore((state) => state.currentChartList)
  const updateCurrentChartList = useProjectStore(
    (state) => state.updateCurrentChartList,
  )
  const { tier1, chartId } = useProjectStore(
    (state) => state.dashboardControls[0],
  )

  const chartResponse = useChartData(
    0,
    emptyFilters,
    undefined,
    undefined,
    'post',
    'month',
    0,
  )

  const { data: defaultChart } = chartResponse

  // needed for setting global state
  useEffect(() => {
    if (defaultChart) {
      updateChartData(0, defaultChart)

      if (!chartId) {
        updateDashboardControls(0, 'chartId', defaultChart.charts[0].chart_id)
      }
      if (!tier1) {
        const value =
          defaultChart.tier1.options[defaultChart.tier1.default].name
        if (value) {
          updateDashboardControls(0, 'tier1', value)
        }
      }

      // console.log('defaultChart', defaultChart)
      // if (!currentChartList[0]) {
      updateCurrentChartList(0, defaultChart.charts[0])
      // }
    }
  }, [
    defaultChart,
    // chartId,
    // tier1,
    // updateChartData,
    // updateDashboardControls,
    // updateCurrentChartList,
  ])

  return chartResponse
}
