import {
  SourceGroup,
  SourcesInfo,
  SourceCustomFieldDelete,
  SourceDetailsVariables,
  SourceCustomFieldAdd,
} from './types'
import { ProjectDetails } from '../../types'
import {
  PROJECT_SOURCE_GROUP_URL,
  PROJECT_SOURCE_GROUP_INFO_URL,
  PROJECT_DETAILS_URL,
  SOURCE_UPDATE_URL,
  SOURCE_CUSTOMFIELD_DELETE_URL,
  POST_ADD_CUSTOM_FIELD,
} from 'utils/apiLinks'
import { axiosInstance } from 'utils/axios'

/**
 * Gets the source group info within the name (Ex. Amazon)
 * @param key
 * @param sourcename name of the source
 * @param syndicated_source whether or not syndicated sources
 * @param proj_uuid uuid of project
 */
export const getSourceGroupInfo = async (
  key: string,
  sourcename: string,
  syndicated_source: boolean,
  proj_uuid: string
): Promise<SourcesInfo> => {
  const { data } = await axiosInstance.get(PROJECT_SOURCE_GROUP_INFO_URL, {
    params: {
      proj_uuid: proj_uuid,
      source_name: sourcename,
      syndicated_sources: syndicated_source,
    },
  })
  return data
}

/**
 * Gets the Source Groups
 * @param key
 * @param syndicated_sources gets the syndicated sources
 * @param proj_uuid gets the proj_uuid
 */
export const getSourceGroups = async (
  key: string,
  syndicated_sources: boolean,
  proj_uuid: string
): Promise<SourceGroup[]> => {
  const { data } = await axiosInstance.get(PROJECT_SOURCE_GROUP_URL, {
    params: { proj_uuid: proj_uuid, syndicated_sources: syndicated_sources },
  })
  return data
}

/**
 * Fetches project details
 * @param {string} proj_uuid - The project UUID
 * @returns {Promise<ProjectDetails>} List of projects
 */
export const getProjectDetails = async (
  key: string,
  proj_uuid: string
): Promise<ProjectDetails> => {
  const { data } = await axiosInstance.get(PROJECT_DETAILS_URL, {
    params: { proj_uuid },
  })
  return data
}

/**
 * Fetches project details
 * @param {string} proj_uuid - The project UUID
 * @returns {Promise<SourceDetails>} List of projects
 */

export const updateSource = async ({
  uuid,
  title,
  description,
}: SourceDetailsVariables) => {
  const { data } = await axiosInstance.put(
    SOURCE_UPDATE_URL,
    { title, description },
    { params: { uuid } }
  )
  return data
}

/**
 * Fetches project details
 * @param {string} proj_uuid - The project UUID
 * @returns {Promise<SourceDetails>} List of projects
 */

export const deleteSource = async ({
  uuids,
  fields,
}: SourceCustomFieldDelete) => {
  const { data } = await axiosInstance.delete(SOURCE_CUSTOMFIELD_DELETE_URL, {
    data: { uuids, fields },
  })
  return data
}

/**
 * Add Custom Field
 *
 */

export const addCustomField = async ({
  resource_uuid,
  field,
  resource,
  field_value,
  field_type,
  lookup_field,
  lookup_value,
  lookup_type,
  add_field,
}: SourceCustomFieldAdd) => {
  const { data } = await axiosInstance.post(
    POST_ADD_CUSTOM_FIELD,
    {
      resource_uuid,
      field,
      resource,
      field_value,
      field_type,
      lookup_field,
      lookup_type,
      add_field,
    },
    { params: { resource_uuid } }
  )
  return data
}
