import React from 'react'

const SavedSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.3333 0H1.66667C0.75 0 0 0.75 0 1.66667V7.5C0 8.41667 0.75 9.16667 1.66667 9.16667H13.3333C14.25 9.16667 15 8.41667 15 7.5V1.66667C15 0.75 14.25 0 13.3333 0ZM13.3333 5H10C10 6.35 8.85 7.5 7.5 7.5C6.15 7.5 5 6.35 5 5H1.66667V1.66667H13.3333V5ZM10 10.8333H15V13.3333C15 14.25 14.25 15 13.3333 15H1.66667C0.75 15 0 14.25 0 13.3333V10.8333H5C5 12.2167 6.11667 13.3333 7.5 13.3333C8.88333 13.3333 10 12.2167 10 10.8333Z"
      fill="currentColor"
    />
  </svg>
)

export default SavedSvgIcon
