import { useState, useEffect } from 'react'
import { useQuery } from 'react-query'
import {
  getNotifications,
  markRead,
  NotificationsResponse,
} from '../features/notifications/model'
import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { toast } from 'react-toastify'

const useNotifications = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const notifications = useProjectStore(
    (state: ProjectState) => state.localNotifications
  )
  const setLocalNotifications = useProjectStore(
    (state: ProjectState) => state.setLocalNotifications
  )
  const {
    data: notificationsFromServer,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    ['notifications', projectId],
    () => {
      return getNotifications(projectId)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )

  // Synchronize the local state with the server state when new notifications are fetched
  useEffect(() => {
    if (notificationsFromServer) {
      setLocalNotifications(notificationsFromServer)
    }
  }, [notificationsFromServer])

  const markAsViewed = (id: number) => {
    setLocalNotifications(
      notifications.map((notification) =>
        notification.id === id
          ? { ...notification, acknowledged_at: 'temp' }
          : notification
      )
    )
    // Optionally, you might want to send this change to the server as well, depending on your requirements
    markRead(projectId, id)
      // .then(() => refetch())
      .catch((err) => {
        console.log(err)
        // toast.error('Error marking notification as read')
      })
  }

  return { notifications, isLoading, isError, markAsViewed, refetch }
}

export default useNotifications
