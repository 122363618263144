/* eslint-disable */

export default {
  init: () => {},
  login: (email, first_name, last_name) => {
    if (window.$crisp) {
      window.$crisp.push(['set', 'user:email', [email]])
      window.$crisp.push([
        'set',
        'user:nickname',
        [`${first_name} ${last_name}`],
      ])
    }
  },
}
