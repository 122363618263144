import { useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { FilterOptionSelect } from '../features/filters/types'

const useThemes = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const details = useProjectStore((state: ProjectState) => state.details)

  const isLLMDash = !!details.clientSettings['llm-dash']

  const getSubthemeNameFromClusterUUID = (uuid: string) => {
    return (
      defaultFilterList.find(
        (item) => item.field === 'theme'
      ) as FilterOptionSelect
    )?.values
      .find((el) => el.cluster_uuid === uuid)
      ?.value?.split(': ')[1]
  }

  const getClusterUUIDsFromThemeName = (theme: string, subtheme: string) => {
    return (
      (
        defaultFilterList.find(
          (item) => item.field === 'theme'
        ) as FilterOptionSelect
      )?.values
        ?.filter((el) => el.value === `${theme}: ${subtheme}`)
        ?.map((el) => el.cluster_uuid ?? el.value) ?? [`${theme}: ${subtheme}`]
    )
  }

  const getNormalizedThemeName = (theme: string) => {
    if (theme.split(': ')?.[0] === theme.split(': ')?.[1]) {
      return theme.split(': ')[0]
    }
    return theme
  }

  return {
    getSubthemeNameFromClusterUUID,
    getClusterUUIDsFromThemeName,
    getNormalizedThemeName,
  }
}

export default useThemes
