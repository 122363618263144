import React from 'react'
import { Form, Input } from 'antd'
import styled from 'styled-components'
import { FormikProps } from 'formik'

type Props = {
  formik: FormikProps<any>
  field: string
  label: string
  disabled?: boolean
}

export const FormItem: React.FC<Props> = ({
  formik,
  label,
  field,
  disabled,
}) => {
  return (
    <Form.Item label={label}>
      <StyledInput
        name={field}
        onChange={formik.handleChange}
        value={formik.values[field]}
        disabled={disabled}
      />
      <Error>{formik.errors?.[field]}</Error>
    </Form.Item>
  )
}

const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
  position: absolute;
  top: 100%;
  font-size: var(--font-size-sm);
`

const StyledInput = styled(Input)`
  .ant-input {
    background: white;
    border: 2px solid var(--card-border-grey);
    border-radius: 6px;
  }
`
