import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { Title } from '../../../../components/Title'
import { useProjectStore } from '../../projectStore/projectStore'
import { Filters, ProjectState } from '../../types'
import { PanelBadges } from '../filters/features/badge-panel/panel-badges'
import { InnerTitle } from '../../../../components/InnerTitle'
import {
  fontSizeL,
  fontSizeM,
  lightText,
  secondaryNavy,
} from '../../../../assets/styles/variables'
import { useQuery } from 'react-query'
import {
  getSummary,
  getSummaryPosts,
} from 'features/project/features/Charts/model'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { EmptyWrapper, Theme } from './components/theme'
import { AppliedFilterOptionSelect, FilterOptionSelect } from '../filters/types'
import _ from 'lodash'
import summaryEmpty from 'assets/images/Summary-Empty.png'
import { HyperDiv } from '../../../../components/HyperCopy/HyperDiv'
import { Stats } from './components/Stats'
import { Themes } from './components/Themes'
import { Keywords } from './components/Keywords'
import { Verbatims } from './components/Verbatims'
import AIcon from 'assets/images/icons/AIcon_180x180.png'
import { matrixChartIds } from 'features/project/features/Charts/utils'
import { useDrag } from 'react-dnd'
import { ItemTypes } from '../dashboard/components/Dashboard/components/ItemTypes'
import { Tooltip } from 'antd'
import { DragIndicatorOutlined } from '@mui/icons-material'
import { DrawerLoader } from 'components/Loading/DrawerLoader/DrawerLoader'

type Props = { isPublic?: boolean }
export const Summary: React.FC<Props> = ({ isPublic }) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const filterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const summaryIsOpen = useProjectStore(
    (state: ProjectState) => state.summaryIsOpen,
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen,
  )
  const summaryRequest = useProjectStore(
    (state: ProjectState) => state.summaryRequest,
  )

  const isMatrixChart = matrixChartIds.includes(summaryRequest?.body?.chart_id)

  const [selectedTheme, setSelectedTheme] = useState<string>('')

  const ref = useRef<HTMLDivElement>(null)

  const [localFilters, setLocalFilters] = useState<Filters>({
    values: summaryRequest?.body?.criteria ?? [],
    searchQuery: summaryRequest?.body?.search_query ?? [],
    searchCondition: summaryRequest?.body?.search_condition ?? [],
  })

  useEffect(() => {
    setLocalFilters({
      values: summaryRequest?.body?.criteria ?? [],
      searchQuery: summaryRequest?.body?.search_terms ?? [],
      searchCondition: summaryRequest?.body?.search_criteria ?? [],
    })
    // setSentiment('')
    // setThemeOption('overall_themes')
  }, [summaryRequest])

  const { isLoading, data, refetch, isError } = useQuery(
    ['summary', summaryRequest],
    () => getSummary(summaryRequest.headers, summaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!summaryRequest,
    },
  )

  const { isLoading: postsLoading } = useQuery(
    ['summary-posts', summaryRequest],
    () => getSummaryPosts(summaryRequest.headers, summaryRequest.body),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!summaryRequest,
    },
  )

  useEffect(() => {
    // Single theme applied
    if (
      (
        summaryRequest?.body?.criteria?.find(
          (el: AppliedFilterOptionSelect) => el.field === 'theme',
        ) as AppliedFilterOptionSelect
      )?.values.length === 1
    ) {
      let selectedTheme = (
        summaryRequest.body.criteria.find(
          (el: AppliedFilterOptionSelect) => el.field === 'theme',
        ) as AppliedFilterOptionSelect
      )?.values[0]
      selectedTheme =
        (
          filterList?.find(
            (option) => option.field === 'theme',
          ) as FilterOptionSelect
        )?.values?.find((value) => value.cluster_uuid === selectedTheme)
          ?.value ?? selectedTheme
      setSelectedTheme(selectedTheme)
      // Theme Lens selection
    } else if (summaryRequest?.body?.tier1 === 'Theme') {
      if (isMatrixChart) {
        setSelectedTheme(summaryRequest?.body?.tier1_value?.split(' - ')[0])
      } else {
        setSelectedTheme(summaryRequest?.body?.tier1_value)
      }
    } else {
      setSelectedTheme('')
    }
  }, [summaryRequest])

  useEffect(() => {
    if (summaryRequest?.body?.chart_id?.split('_')[3] === 'themes') {
      setSelectedTheme(summaryRequest.body.tier3_value)
    }
  }, [summaryRequest])

  const isThemeTier =
    localFilters?.values?.filter((el: any) => el.field === 'theme')?.length ===
      1 || summaryRequest?.body?.tier1 === 'Theme'

  const [prevPosition, setPrevPosition] = useState(window.innerHeight / 2.5)
  const [position, setPosition] = useState(window.innerHeight / 2.5)
  const isDragging = useRef(false)
  const startY = useRef(0)

  const handleMouseDown = (event: React.MouseEvent<HTMLDivElement>) => {
    isDragging.current = true
    startY.current = event.clientY - position
    document.addEventListener('mousemove', handleMouseMove)
    document.addEventListener('mouseup', handleMouseUp)
  }

  const handleMouseMove = (event: MouseEvent) => {
    if (isDragging.current) {
      const newY = event.clientY - startY.current
      if (newY < 0) return
      if (newY > window.innerHeight - 40) return
      setPosition(newY)
    }
  }

  const handleMouseUp = () => {
    isDragging.current = false
    setPrevPosition(position)
    document.removeEventListener('mousemove', handleMouseMove)
    document.removeEventListener('mouseup', handleMouseUp)
  }

  const getTitle = () => {
    const { body } = summaryRequest ?? {}
    const { tier1, tier1_value, tier1_label } = body ?? {}

    // If tier1_value is missing or matches tier1, just show tier1
    // Review Count has a special case because it never has a value
    if (tier1 === 'Review Count' || tier1 === tier1_value) {
      return tier1
    }

    const displayValue = tier1_label ?? tier1_value
    return `${tier1}: ${displayValue}`
  }

  return (
    <>
      <OverlayContainer
        isOpen={summaryIsOpen}
        onClick={() => {
          setSummaryIsOpen(false)
        }}
        // custom dash references summary internally which messes with the positioning
        style={{ marginTop: route.isDashboard ? '-55px' : 0 }}
      ></OverlayContainer>
      <Drawer
        ref={ref}
        isOpen={summaryIsOpen}
        isPublic={!!isPublic}
        style={{ marginTop: route.isDashboard ? '-55px' : 0 }}
      >
        <Wrapper
        // style={{ display: summaryIsOpen ? 'block' : 'none' }}
        >
          {/*<Arrow*/}
          {/*  onClick={() => {*/}
          {/*    if (position === prevPosition) {*/}
          {/*      setSummaryIsOpen(!summaryIsOpen)*/}
          {/*    }*/}
          {/*  }}*/}
          {/*  style={{*/}
          {/*    position: 'relative',*/}
          {/*    top: `${position}px`,*/}
          {/*    cursor: 'move',*/}
          {/*  }}*/}
          {/*  onMouseDown={handleMouseDown}*/}
          {/*>*/}
          {/*  <StyledImg*/}
          {/*    style={{*/}
          {/*      height: '80%',*/}
          {/*      width: '80%',*/}
          {/*      filter: 'grayscale(100%)',*/}
          {/*      // marginTop: -3,*/}
          {/*      // marginLeft: -10,*/}
          {/*      // marginRight: 3,*/}
          {/*    }}*/}
          {/*    src={AIcon}*/}
          {/*  />*/}
          {/*  /!*{summaryIsOpen ? <DoubleRightOutlined /> : <DoubleLeftOutlined />}*!/*/}
          {/*</Arrow>*/}
          {isLoading || postsLoading ? (
            <Content>
              <LoadingWrapper>
                <DrawerLoader></DrawerLoader>
                <InnerTitle>Loading Summary</InnerTitle>
              </LoadingWrapper>
            </Content>
          ) : (
            <Content>
              {summaryRequest ? (
                <>
                  <Header elementName={'summary-header'}>
                    {route.isCharts || route.isDashboard || route.isFocus ? (
                      <>
                        <div
                          style={{
                            display: 'flex',
                            justifyContent: 'space-between',
                          }}
                        >
                          <Title>{getTitle()}</Title>
                        </div>
                        {summaryRequest?.body?.tier3_value &&
                          !isMatrixChart && (
                            <InnerTitle
                              style={{ marginBottom: '0px', lineHeight: 1.2 }}
                            >
                              {_.startCase(
                                summaryRequest.body.chart_id
                                  .split('_')[3]
                                  .slice(-1) === 's'
                                  ? summaryRequest.body.chart_id
                                      .split('_')[3]
                                      .slice(0, -1)
                                  : summaryRequest.body.chart_id.split('_')[3],
                              )}
                              {' - '}
                              {summaryRequest?.body?.tier3_value ?? ''}
                            </InnerTitle>
                          )}
                      </>
                    ) : (
                      <Title>Summary</Title>
                    )}
                    <BadgeContainer>
                      <PanelBadges
                        filterValues={localFilters}
                        comparativeIndex={0}
                        countThreshold={100}
                        infoOnly
                        isSummary
                      />
                      {!!localFilters.searchQuery.length && (
                        <div style={{ marginTop: '10px', fontSize: fontSizeL }}>
                          {/*Term Search*/}
                          <div
                            style={{
                              display: 'flex',
                              gap: '5px',
                              flexWrap: 'wrap',
                            }}
                          >
                            {localFilters.searchQuery.map((el) => (
                              <div
                                style={{
                                  padding: '2px 10px',
                                  background: secondaryNavy,
                                  color: 'white',
                                  borderRadius: '10px',
                                }}
                              >
                                {el}
                              </div>
                            ))}
                            {localFilters.searchQuery.length > 1 && (
                              <>
                                <div
                                  style={{
                                    padding: '2px ',
                                  }}
                                >
                                  Joined by
                                </div>
                                {localFilters.searchCondition.map((el) => (
                                  <div
                                    style={{
                                      padding: '2px 10px',
                                      background: secondaryNavy,
                                      color: 'white',
                                      borderRadius: '10px',
                                    }}
                                  >
                                    {el}
                                  </div>
                                ))}
                              </>
                            )}
                          </div>
                        </div>
                      )}
                    </BadgeContainer>
                  </Header>
                  <Body>
                    <Section elementName={'summary-info-panel'}>
                      <Stats
                        data={data}
                        summaryRequest={summaryRequest}
                        filterValues={localFilters}
                        filterList={filterList ?? []}
                      />
                    </Section>
                    <Divider />
                    <Section elementName={'summary-themes'}>
                      {data && (
                        <Themes
                          data={data}
                          selectedTheme={selectedTheme}
                          summaryRequest={summaryRequest}
                          localFilters={localFilters}
                        />
                      )}
                    </Section>
                    <Divider />
                    <Section elementName={'summary-keywords'}>
                      {data && (
                        <Keywords
                          data={data}
                          summaryRequest={summaryRequest}
                          selectedTheme={selectedTheme}
                          filterListData={filterList}
                        />
                      )}
                    </Section>
                    <Divider />
                    <Section elementName={'summary-feedback'}>
                      <Verbatims
                        summaryRequest={summaryRequest}
                        isThemeTier={isThemeTier}
                      />
                    </Section>
                  </Body>
                </>
              ) : (
                <EmptyWrapper>
                  <img
                    src={summaryEmpty}
                    style={{ maxWidth: '250px', margin: '0 auto 10px' }}
                  />
                  {route.isCharts || route.isDashboard ? (
                    <InnerTitle style={{ color: lightText }}>
                      Left click on chart data to see a detailed summary
                    </InnerTitle>
                  ) : (
                    <InnerTitle style={{ color: lightText }}>
                      Click Summarize to see a detailed summary
                    </InnerTitle>
                  )}
                </EmptyWrapper>
              )}
            </Content>
          )}
        </Wrapper>
      </Drawer>
    </>
  )
}

const OverlayContainer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  right: 0;
  top: 0;
  z-index: ${(props) => (props.isOpen ? 200 : -1)};
  height: 100vh;
  width: 100vw;
  transition: 0.2s ease-in-out;
  background: ${(props) => (props.isOpen ? 'rgba(0, 0, 0, 0.2)' : 'none')};
`
const Drawer = styled.div<{ isOpen: boolean; isPublic: boolean }>`
  position: absolute;
  z-index: 201;
  height: 100vh;
  width: 50%;
  background: white;
  right: 0;
  top: 0;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  z-index: 200;
`

const Header = styled(HyperDiv)`
  display: flex;
  flex-direction: column;
  padding: 30px 30px 10px;
  box-shadow:
    0px 25px 100px -20px rgba(50, 50, 93, 0.05),
    0px 15px 60px -30px rgba(0, 0, 0, 0.1);
  font-size: 18px;
`

const BadgeContainer = styled.div`
  margin: 10px 0px;
`

const Body = styled.div`
  display: flex;
  flex-direction: column;
  //padding: 0px 50px;
`

const Section = styled(HyperDiv)`
  position: relative;
  padding: 20px 30px;
`
const Divider = styled.div`
  //width: 100%;
  border: 1px solid rgba(159, 183, 195, 0.15);
  margin: 0px 30px;
`
const Arrow = styled.div`
  position: absolute;
  z-index: -1;
  top: 45vh;
  left: -40px;
  width: 40px;
  height: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: white;
  cursor: pointer;
  border-bottom-left-radius: 6px;
  border-top-left-radius: 6px;
  box-shadow:
    -8px 4px 8px rgba(17, 17, 26, 0.1),
    -4px -4px 8px rgba(17, 17, 26, 0.1);
`
const LoadingWrapper = styled.div`
  height: 100%;
  //max-width: 300px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
`
const StyledImg = styled.img`
  user-drag: none;
  -webkit-user-drag: none;
  user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
`
