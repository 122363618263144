import React from 'react'

const KeywordsSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M0 2.28261C0 1.02196 1.02196 0 2.28261 0H6.71739C7.97804 0 9 1.02196 9 2.28261C9 3.54326 7.97804 4.56522 6.71739 4.56522H2.28261C1.02196 4.56522 0 3.54326 0 2.28261ZM0 7.4674C0 6.22476 1.00736 5.2174 2.25 5.2174C3.49264 5.2174 4.5 6.22476 4.5 7.4674V7.53261C4.5 8.77525 3.49264 9.78261 2.25 9.78261C1.00736 9.78261 0 8.77525 0 7.53261V7.4674ZM2.28261 10.4348C1.02196 10.4348 0 11.4567 0 12.7174C0 13.978 1.02196 15 2.28261 15H10.4674C11.728 15 12.75 13.978 12.75 12.7174C12.75 11.4567 11.728 10.4348 10.4674 10.4348H2.28261ZM9.75 2.28261C9.75 1.02196 10.772 0 12.0326 0H12.7174C13.978 0 15 1.02196 15 2.28261C15 3.54326 13.978 4.56522 12.7174 4.56522H12.0326C10.772 4.56522 9.75 3.54326 9.75 2.28261ZM7.28261 5.21739C6.02196 5.21739 5 6.23935 5 7.5C5 8.76065 6.02196 9.78261 7.28261 9.78261H12.4674C13.728 9.78261 14.75 8.76065 14.75 7.5C14.75 6.23935 13.728 5.21739 12.4674 5.21739H7.28261Z"
      fill="currentColor"
    />
  </svg>
)

export default KeywordsSvgIcon
