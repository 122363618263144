import { ChartData } from 'chart.js'
import Chart from 'chart.js/auto'
import { numberFormatter } from 'utils/numberFormat'
import { BubbleChart, DataForChart, UnionChart } from '../types'
import { max } from 'lodash'
import 'chartjs-plugin-annotation'
import { processLabel } from 'utils/processLabel'
import { getGroupedChartValues2 } from 'features/project/features/Charts/utils'

const computeSize = (max: number) => (x: number) =>
  (1 - Math.pow(Math.E, (-1 / max) * x)) * 100 + 5
const HEIGHT_BASE = 500

const getThreshold = () => {
  switch ((Chart.defaults.font as any).size) {
    case 18:
      return 12
    case 16:
      return 13
    case 12:
      return 16
    case 10:
      return 17
    default:
      return 15
  }
}

export const configureBubbleChart = (
  currentChart: BubbleChart,
  tier1: string,
  showCategories: boolean,
  expandedCategories: string[],
  hasLabels?: boolean,
  shadedZones?: boolean,
  trendlines?: boolean,
  origin?: boolean,
  hideXAxis?: boolean,
  hideYAxis?: boolean,
  disableGrouping?: boolean,
): DataForChart => {
  let values: {
    category: string
    color: string
    percent: number
    sentiment: number
    volume: number
    rating: number
    sentiment_stdev: number
    word_count_avg: number
    hover: { [s: string]: any }
    [s: string]: any
  }[] = getGroupedChartValues2(
    currentChart,
    expandedCategories,
    tier1,
    showCategories,
    disableGrouping,
  )

  const maxValue: number = max(
    values.map((v) => v[currentChart.z_key] as number),
  ) as number
  const additionalValues: any = values.map((v) => v.hover)
  const computer = computeSize(maxValue)
  const textLength = values.reduce((acc, v) => acc + v.category, '')

  const sizeMultiplier = textLength.length / 700
  const legend = values.map((v) => ({
    color: v.color,
    label: v.category,
    disabled: expandedCategories?.includes(v.category) ?? false,
    children:
      currentChart?.category_mapping &&
      currentChart?.category_mapping[v.category]?.map((item) => ({
        color:
          currentChart.values.find((el) => el.category === item)?.color ??
          v.color,
        label: item.split(': ')[1] ?? item,
        disabled: false,
      })),
  }))

  let xValues = values
    .map((element: any) => element[currentChart.x_key])
    .flat()
    .flat()
  let minXValue = 0
  let maxXValue = 0
  let offset = 0

  // workaround for no scale width when there is just one value
  if (values.length > 1) {
    minXValue = Math.min(...xValues)
    maxXValue = Math.max(...xValues)
    offset = (maxXValue - minXValue) / 10 || maxXValue / 5
  }

  const getAnnotations = () => {
    const annotations = []
    if (currentChart?.shaded_zones && shadedZones) {
      currentChart?.shaded_zones.forEach((item, index) => {
        return (
          // item.visible &&
          annotations.push({
            type: 'box',
            drawTime: 'beforeDatasetsDraw',
            id: 'box_' + index,
            xMin: item.threshold.min,
            xMax: item.threshold.max,
            backgroundColor: item.color ?? 'rgba(175, 197, 222 ,0.5)',
            borderWidth: 0,
          })
        )
      })
    }
    if (currentChart.lobf && trendlines) {
      currentChart.lobf.forEach((item, index) =>
        annotations.push({
          type: 'line',
          drawTime: 'beforeDatasetsDraw',
          id: 'lobf' + index,
          xMin: (item.left as any)[currentChart.x_key],
          yMin: (item.left as any)[currentChart.y_key],
          xMax: (item.right as any)[currentChart.x_key],
          yMax: (item.right as any)[currentChart.y_key],
          borderWidth: 2,
          borderColor: item.color ?? 'rgb(57, 66, 230,0.7)',
          borderDash: [5, 5],
        }),
      )
    }
    if (currentChart.origin && origin) {
      annotations.push({
        type: 'line',
        drawTime: 'beforeDatasetsDraw',
        id: 'origin-1',
        yMin: (currentChart.origin as any)[currentChart.y_key].value,
        yMax: (currentChart.origin as any)[currentChart.y_key].value,
        borderWidth: 1,
        borderColor: 'red',
        label: {
          drawTime: 'afterDatasetsDraw',
          enabled: true,
          content:
            (currentChart.origin as any)[currentChart.y_key].name +
            ': ' +
            numberFormatter(
              (currentChart.origin as any)[currentChart.y_key].value,
            ),
          backgroundColor: 'rgba(0,0,0,0.3)',
          position: 'start',
          font: {
            size:
              (Chart.defaults.font as any).size > 14
                ? 14
                : (Chart.defaults.font as any).size,
          },
        },
      })
      annotations.push({
        type: 'line',
        drawTime: 'beforeDatasetsDraw',
        id: 'origin-2',
        xMin: (currentChart.origin as any)[currentChart.x_key].value,
        xMax: (currentChart.origin as any)[currentChart.x_key].value,
        borderWidth: 1,
        borderColor: 'red',
        label: {
          drawTime: 'afterDatasetsDraw',
          enabled: true,
          content:
            (currentChart.origin as any)[currentChart.x_key].name +
            ': ' +
            numberFormatter(
              (currentChart.origin as any)[currentChart.x_key].value,
            ),
          backgroundColor: 'rgba(0,0,0,0.3)',
          position: 'start',
          font: {
            size:
              (Chart.defaults.font as any).size > 14
                ? 14
                : (Chart.defaults.font as any).size,
          },
        },
      })
    }
    return annotations
  }

  const data: ChartData = {
    datasets: values.map((v) => {
      const radius = computer(v[currentChart.z_key] as number)
      const values = [
        {
          x: v[currentChart.x_key],
          y: v[currentChart.y_key],
          r: radius > 5 ? radius : 5,
        },
      ]

      return {
        label: v.category,
        backgroundColor: v.color.replace(')', ', 0.25)').replace('rgb', 'rgba'),
        borderColor: v.color,
        borderWidth: 3,
        data: values,
        order: 2,
      }
    }),
    labels: [],
  }

  const options: any = {
    plugins: {
      datalabels: hasLabels
        ? {
            formatter: function (value: any, meta: any) {
              if (!data.datasets) return ''
              const currentItem = data.datasets.find((item) => {
                return (
                  (item.data as Chart.ChartPoint[])[0].x === value.x &&
                  (item.data as Chart.ChartPoint[])[0].y === value.y &&
                  (item.data as Chart.ChartPoint[])[0].r === value.r
                )
              })
              return processLabel(getThreshold(), currentItem?.label)
            },
            color: 'black',
            anchor: 'end',
            align: 'top',
            display: 'auto',
            textAlign: 'center',
            clamp: 'true',
            font: { lineHeight: 0.8 },
          }
        : false,
      legend: false,
      tooltip: {
        intersect: true,
        position: 'nearest',
        enabled: true,
        displayColors: false,
        callbacks: {
          title: function (t: any) {
            const currentValue = values.find(
              (item) => item.category === t[0].label,
            )
            return processLabel(40, currentValue?.category)
          },
          label: function () {
            return ''
          },
          afterBody: function (data: any) {
            const index = values.findIndex(
              (item) => item.category === data[0].label,
            )
            if (index === undefined) return ''
            const base = [
              `${currentChart.y_title}: ${numberFormatter(
                values[index][currentChart.y_key],
              )}`,
              `${currentChart.x_title}: ${numberFormatter(
                values[index][currentChart.x_key],
              )}`,
            ]
            return additionalValues[index]
              ? [
                  ...base,
                  ...Object.keys(additionalValues[index]).map(
                    (key) =>
                      `${key}: ${numberFormatter(additionalValues[index][key])}`,
                  ),
                ]
              : base
          },
        },
      },
      annotation: {
        annotations: getAnnotations(),
      },
    },
    layout: {
      padding: {
        top: 50,
        left: 60,
        right: 30,
      },
    },
    animation: {
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: false,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    scales: {
      x: {
        display: true,
        title: { display: !hideXAxis, text: currentChart.x_title },
        ticks: {
          stepSize: 0.01,
          callback: function (value: any) {
            if (typeof value === 'number') value = value.toFixed(2)
            return numberFormatter(value)
          },
        },
        // this is kinda jank but it solves the issue rendering where theres only 1 bubble
        min:
          values.length === 1 && currentChart.x_key === 'sentiment'
            ? -1
            : Math.round((currentChart.min_x || minXValue - offset) * 100) /
              100,
        max:
          values.length === 1 && currentChart.x_key === 'sentiment'
            ? 1
            : Math.round((currentChart.max_x || maxXValue + offset) * 100) /
              100,
      },
      y: {
        type: 'linear',
        display: true,
        position: 'right',
        title: { display: !hideYAxis, text: currentChart.y_title },
        ticks: {
          callback: function (value: any) {
            if (typeof value === 'number') value = value.toFixed(2)
            return numberFormatter(value)
          },
        },
        min:
          values.length === 1 && currentChart.y_key === 'rating'
            ? 0
            : currentChart.min_y,
        max:
          values.length === 1 && currentChart.y_key === 'rating'
            ? 5
            : currentChart.max_y,
      },
    },
  }

  return {
    data,
    options,
    legend,
    type: 'bubble',
    height: 1000, //|| HEIGHT_BASE * (sizeMultiplier < 1 ? 1 : sizeMultiplier),
  }
}
