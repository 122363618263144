import React from 'react'
import { useFormik } from 'formik'
import { Form, Button, Row, Checkbox, Select } from 'antd'
import { toast } from 'react-toastify'
import { FormItem } from 'shared/components'
import { ProjectDetails } from 'features/project/types'
import { updateProject, updateProjectMetadata } from './model'
import {
  ProjectMetadataPayload,
  ProjectMetadataResponse,
  SentimentValuesEnum,
} from './types'
import { CopyOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Check } from '@mui/icons-material'

const layout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 },
}

type Props = {
  project: ProjectDetails
  metadata?: ProjectMetadataResponse
  // refetchProject: () => void
  refetchMetadata: () => void
  showEmbedder?: boolean
  isGroupParent?: boolean
}

export const SettingsForm: React.FC<Props> = ({
  project,
  metadata,
  refetchMetadata,
  showEmbedder,
  isGroupParent,
}) => {
  const isAdmin = project.role === 'admin'
  const formik = useFormik({
    initialValues: {
      title: project.title,
      description: project.description,
      stop_words: metadata?.stop_words.value || [],
      embedder: metadata?.embedder?.value || null,
      title_override: project?.title_override || null,
      disable_brand_grouping: project?.disable_brand_grouping || false,
    },
    onSubmit: async ({ title, description, ...rest }) => {
      submitChanges(rest)
    },
    enableReinitialize: true,
  })

  const submitChanges = (rest: any) => {
    try {
      Promise.all([
        updateProject(project.proj_uuid, {
          title: formik.values.title,
          description: formik.values.description,
          user_proj_settings: {
            proj_title: formik.values.title_override,
            disable_brand_grouping: formik.values.disable_brand_grouping,
          },
        }),
        isAdmin
          ? updateProjectMetadata(
              project.proj_uuid,
              rest as ProjectMetadataPayload,
            )
          : Promise.resolve(),
      ]).then(() => {
        // refetchProject()
        refetchMetadata()
        toast.success(
          `Project ${formik.values.title} has been successfully updated`,
        )
      })
    } catch (e) {
      toast.error((e as any).message)
    }
  }

  const isDisabled = !isAdmin || formik.isSubmitting
  return (
    <>
      <Form onFinish={formik.handleSubmit} style={{ flex: 1 }}>
        <FormItem
          label="Title"
          field="title"
          formik={formik}
          disabled={isDisabled}
        />
        <FormItem
          label="Description"
          field="description"
          formik={formik}
          disabled={isDisabled}
        />
        {isAdmin && (
          <>
            <h2>User Overrides</h2>
            <UserOverrides>
              <FormItem
                label="User Title Override"
                field="title_override"
                formik={formik}
                disabled={isDisabled}
              />
              <Form.Item label={'Disable Brand Grouping'}>
                <Checkbox
                  name={'disable_brand_grouping'}
                  onChange={formik.handleChange}
                  checked={!!formik.values['disable_brand_grouping']}
                  disabled={isDisabled}
                />
              </Form.Item>
            </UserOverrides>
          </>
        )}

        {/*{isAdmin && !isGroupParent && (*/}
        {/*  <>*/}
        {/*    <Form.Item*/}
        {/*      label={metadata?.stop_words.alias}*/}
        {/*      style={{*/}
        {/*        flexDirection: 'column',*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      <div style={{ width: 'fit-content', position: 'relative' }}>*/}
        {/*        <Select*/}
        {/*          style={{*/}
        {/*            // width: '50vw',*/}
        {/*            // maxHeight: '300px',*/}
        {/*            overflow: 'auto',*/}
        {/*          }}*/}
        {/*          mode="tags"*/}
        {/*          value={formik.values.stop_words || []}*/}
        {/*          onChange={(s: string[]) =>*/}
        {/*            formik.setFieldValue(*/}
        {/*              'stop_words',*/}
        {/*              s.filter((word) => word.trim().length > 0),*/}
        {/*            )*/}
        {/*          }*/}
        {/*          onInputKeyDown={(event) => {*/}
        {/*            if (event.metaKey) {*/}
        {/*              const value = (formik.values.stop_words || []).join(',')*/}
        {/*              if (event.key === 'c') {*/}
        {/*                navigator.clipboard.writeText(value)*/}
        {/*              }*/}
        {/*              if (event.key === 'x') {*/}
        {/*                navigator.clipboard.writeText(value)*/}
        {/*                formik.setFieldValue('stop_words', [])*/}
        {/*              }*/}
        {/*              if (event.key === 'v') {*/}
        {/*                navigator.clipboard*/}
        {/*                  .readText()*/}
        {/*                  .then((result) =>*/}
        {/*                    (formik as any).setFieldValue(*/}
        {/*                      'stop_words',*/}
        {/*                      Array.from(*/}
        {/*                        new Set(*/}
        {/*                          formik.values.stop_words.concat(*/}
        {/*                            result.split(','),*/}
        {/*                          ),*/}
        {/*                        ),*/}
        {/*                      ),*/}
        {/*                    ),*/}
        {/*                  )*/}
        {/*              }*/}
        {/*            }*/}
        {/*          }}*/}
        {/*          tokenSeparators={[',']}*/}
        {/*          disabled={isDisabled}*/}
        {/*        />*/}
        {/*        <Button*/}
        {/*          type="primary"*/}
        {/*          shape="circle"*/}
        {/*          icon={<CopyOutlined />}*/}
        {/*          onClick={() => {*/}
        {/*            navigator.clipboard.writeText(*/}
        {/*              (formik.values.stop_words || []).join(','),*/}
        {/*            )*/}
        {/*            toast.info('Copied to clipboard')*/}
        {/*          }}*/}
        {/*          style={{ position: 'absolute', right: 20, top: 4 }}*/}
        {/*        />*/}
        {/*      </div>*/}
        {/*    </Form.Item>*/}

        {/*    {showEmbedder && metadata?.embedder?.value && (*/}
        {/*      <Form.Item label={metadata?.embedder.alias}>*/}
        {/*        <b>{metadata?.embedder.value}</b>*/}
        {/*      </Form.Item>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}
        <Form.Item>
          <Row justify="end">
            <Button
              size="large"
              type="primary"
              htmlType="submit"
              disabled={isDisabled}
            >
              Submit
            </Button>
          </Row>
        </Form.Item>
      </Form>
    </>
  )
}

const UserOverrides = styled.div`
  border: 1px solid red;
  border-radius: 8px;
  padding: 10px;
  margin-bottom: 20px;
`
