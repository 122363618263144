import React, { useMemo } from 'react'
import styled from 'styled-components'
import { initialState } from '../../projectStore/projectStore'
import { Filters } from '../../types'
import Overview from './Components/Overview'
import { FocusListOption } from 'features/project/features/catalog/Components/CatalogList/CatalogList'
import { ProductHierarchyInfo } from 'features/project/features/products/types'
import { useHistory } from 'react-router'
import PDPInsights from 'features/project/features/catalog/Components/PDPInsights/PDPInsights'

interface FocusDetailProps {
  lens: string
  tabIndex: number
  filterValues: Filters
  item: FocusListOption
}

const CatalogDetailContent: React.FC<FocusDetailProps> = ({
  item,
  lens,
  tabIndex,
  filterValues,
}) => {
  const chartId = '5_4_16_rating'
  const tier1 = lens

  const history = useHistory()

  const localFilterValues = useMemo(() => {
    return {
      ...filterValues,
      values: [
        ...filterValues.values,
        { field: lens, values: item.identifiers },
      ],
    }
  }, [filterValues, lens, item.identifiers])

  const dashboardControls = JSON.parse(
    JSON.stringify(initialState.dashboardControls[0]),
  )

  dashboardControls.legend = false

  return (
    <>
      <Content>
        <MainBody>
          {lens === 'product_hierarchy' ? (
            <>
              {tabIndex === 0 && (
                <Overview
                  item={item}
                  filterValues={localFilterValues}
                  tier1={tier1}
                  chartId={chartId}
                />
              )}
              {tabIndex === 1 && (
                <PDPInsights
                  item={item as unknown as ProductHierarchyInfo}
                  filterValues={filterValues}
                />
              )}
            </>
          ) : (
            <Overview
              item={item}
              filterValues={localFilterValues}
              tier1={tier1}
              chartId={chartId}
            />
          )}
        </MainBody>
      </Content>
    </>
  )
}

const Content = styled.div`
  width: 100%;
`

const MainBody = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;
  width: 100%;
`

export default CatalogDetailContent
