import React from 'react'

const FeedbackSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 0.647583H1.5C0.675 0.647583 0 1.32258 0 2.14758V15.6476L3 12.6476H13.5C14.325 12.6476 15 11.9726 15 11.1476V2.14758C15 1.32258 14.325 0.647583 13.5 0.647583ZM8.6775 7.82508L7.5 10.3976L6.3225 7.82508L3.75 6.64758L6.3225 5.47008L7.5 2.89758L8.6775 5.47008L11.25 6.64758L8.6775 7.82508Z"
      fill="currentColor"
    />
  </svg>
)

export default FeedbackSvgIcon
