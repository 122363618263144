import React, { useEffect, useMemo } from 'react'
import { tooltip } from 'utils/tooltip-data'
import { InfoCircleOutlined } from '@ant-design/icons'
import { Form, Input, Tooltip, DatePicker, Checkbox, Select } from 'antd'
import { SourceProps } from '../source-providers'
import { amazonDomains, amazonOptions, getAmazonDomain } from './amazon-utils'
import { extractUrl, retrievePureUrl } from '../../utils'
import moment from 'moment'

export const Amazon = ({ formik }: SourceProps) => {
  const sourceUrl = getAmazonDomain(formik.values.sourceUrl || '')
  const sourceUrlValue = useMemo(
    () => extractUrl(formik.values.sourceUrl, sourceUrl),
    [formik.values.sourceUrl]
  )

  useEffect(() => {
    if (formik.values.subtype === undefined)
      formik.setFieldValue('subtype', ['reviews'])
    if (formik.values.global_reviews === undefined)
      formik.setFieldValue('global_reviews', false)
  }, [])

  const amazonFilterOptions = (
    <Select
      value={sourceUrl}
      onChange={(option) => {
        if (formik.values.sourceUrl) {
          formik.setFieldValue(
            'sourceUrl',
            formik.values.sourceUrl.replace(sourceUrl, option)
          )
        } else {
          formik.setFieldValue('sourceUrl', option)
        }
      }}
      options={amazonOptions}
    />
  )

  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-url']}>
            <span>
              URL <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceUrl ? 'error' : ''}
        help={formik.errors.sourceUrl}
        wrapperCol={{ span: 16 }}
      >
        <Input
          name="sourceUrl"
          value={sourceUrlValue}
          addonBefore={amazonFilterOptions}
          onChange={(event) => {
            if (!event.target.value) {
              // If the input is empty, set Amazon domain as sourceUrl.
              // As we have additional validation for Amazon,
              // it will prevent passing pure domain as the source link.
              formik.setFieldValue('sourceUrl', sourceUrl)
            } else {
              const targetDomain = retrievePureUrl(event.target.value)
              const amazonDomain = amazonDomains.find(
                (country_domain) =>
                  targetDomain.indexOf(retrievePureUrl(country_domain)) !== -1
              )
              formik.setFieldValue(
                'sourceUrl',
                amazonDomain
                  ? amazonDomain +
                      targetDomain.replace(retrievePureUrl(amazonDomain), '')
                  : sourceUrl + event.target.value
              )
            }
          }}
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['processing-data-types']}>
            <span>
              Data types <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.subtype ? 'error' : ''}
        help={formik.errors.subtype}
        wrapperCol={{ span: 16 }}
      >
        <Checkbox.Group
          name="processing_data_types"
          options={[
            { label: 'Reviews', value: 'reviews' },
            { label: 'Questions', value: 'questions' },
          ]}
          value={formik.values.subtype}
          onChange={(values) => {
            formik.setFieldValue(
              'subtype',
              values.map((value) => value.toString())
            )
          }}
        />
      </Form.Item>

      <Form.Item
        label={
          <Tooltip title={tooltip['global-reviews']}>
            <span>
              Global Reviews <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
      >
        <Checkbox
          name="global_reviews"
          disabled={!formik.values.subtype?.includes('reviews')}
          checked={formik.values.global_reviews}
          onChange={formik.handleChange}
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceTitle ? 'error' : ''}
        help={formik.errors.sourceTitle}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['form-date']}>
            <span>
              Date <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sourceDate ? 'error' : ''}
        help={formik.errors.sourceDate}
      >
        <DatePicker
          defaultValue={
            formik.values.sourceDate && moment(formik.values.sourceDate)
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue('sourceDate', value.toDate())
            }
          }}
          format={'MM/DD/YYYY'}
        />
      </Form.Item>
    </>
  )
}
