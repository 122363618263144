import { getProjectOptions } from './features/filters/model'
import { HardcodedFilterOptionsEnum } from './features/filters/helpers'
import { AppliedFilterOption, FilterValues } from './features/filters/types'
import { Filters } from './types'

const defaultOpenedOptions = [HardcodedFilterOptionsEnum.CREATE_TIME]
export const fetchFilterOptions = async (
  id: string,
  optionsToFetch: Array<string>,
  filterValues: Filters
) => {
  try {
    // TODO - this is a temp fix so that Theme values are always held, for cross referencing with the Summary
    const body = {
      fields: optionsToFetch.length ? optionsToFetch : defaultOpenedOptions,
      // .filter((el) => el !== 'theme')
      // .concat('theme'),
      criteria: filterValues.values,
      search_terms: filterValues.searchQuery,
      search_criteria: filterValues.searchCondition,
      post_text_table_search: true,
    }
    // TODO simplify this
    // theme needs to live in filterList when applied, because the badge labels look at filterList to translate cluster ids to theme names - relic of old, can be fixed in a few ways
    // maybe cache the plain filterList and hold on to that all the time in case you need to reference the full list
    // https://linear.app/yo-yo-gi/issue/EFE-120/theme-filter-badges-needs-to-be-in-filterlist
    // if (
    //   filterValues.values.find((el) => el.field === 'theme') &&
    //   !body.fields.includes('theme')
    // ) {
    //   body.fields = [...body.fields, 'theme']
    // }
    const { data } = await getProjectOptions(id, body)
    return data.filter_options.map((option) => {
      return { ...option, field: option.field }
    })
  } catch (e) {
    // setLoading(false)
    throw e
  }
}
