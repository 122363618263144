import React from 'react'
import { Table } from 'antd'
import styled from 'styled-components'

type Props = {
  data: []
}

export const ProductCustomFields: React.FC<Props> = ({ data }) => {
  let table_data = []
  const columns = [
    {
      title: 'Custom Field',
      dataIndex: 'field',
      key: 'field',
    },
    {
      title: 'Value',
      dataIndex: 'value',
      key: 'value',
    },
  ]
  for (const property in data) {
    const val =
      typeof data[property] === 'object'
        ? JSON.stringify(data[property])
        : data[property]
    table_data.push({
      key: property + val,
      field: property,
      value: val,
    })
  }
  return (
    <>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={table_data}
          size="small"
          bordered
          showHeader={false}
          pagination={false}
          style={{ width: '100%' }}
        ></Table>
      </TableWrapper>
    </>
  )
}
const TableWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`
