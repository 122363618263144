import React from 'react'
import { StarFilled, StarOutlined } from '@ant-design/icons'
import { Space } from 'antd'
import { yellow } from '@ant-design/colors'

export const Stars: React.FC<{
  score: number
  maxScore?: number
  spacing?: number
  style?: React.CSSProperties
}> = ({ score, maxScore = 5, spacing = 3, style }) => {

  // just return empty and hide the stars if score or maxScore is invalid
  // TODO this should `throw`, if/when we have global error-handling
  if (score < 0) return <></>
  if (maxScore <= 0) return <></>
  if (score > maxScore) return <></>

  const stars: React.ReactElement[] = []

  for (let i = 1; i <= maxScore; i++) {
    if (i <= score) {
      stars.push(<StarFilled key={i} />)
    } else {
      stars.push(<StarOutlined key={i} />)
    }
  }

  return (
    <Space size={spacing} style={{ color: yellow.primary, ...style }}>
      {stars.map((x) => x)}
    </Space>
  )
}
