import { POST_SAMPLE } from 'utils/apiLinks'
import { axiosInstance } from 'utils/axios'

export type Sentence = {
  text: string
  theme_idx: number
  sentiment: string
}
export type Brand = {
  name: string
  image_url: string
}
export type Product = {
  brand: Brand
  name: string
  image_url: string
  hierarchy: string
  uuid: string
}
export type Source = {
  name: string
  image_url: string
  type: string
  uuid: string
}
export type Theme = {
  name: string
  color: string
  // frequency: number
  uuid: string
}
export type Keyword = {
  word: string
  // frequency: number
}

export type ReviewCardType = {
  uuid: string
  create_time: string
  username: string
  title: string
  score: number
  sentences: Sentence[]
  keywords: Keyword[]
  product: Product
  source: Source
  themes: Theme[]
}
export const getPostSample = async (
  proj_uuid: string,
  dataset?: string
): Promise<ReviewCardType[]> => {
  const { data } = await axiosInstance.get(POST_SAMPLE, {
    params: {
      proj_uuid: proj_uuid,
      project_uuid: dataset,
    },
  })
  return data
}
