import React from 'react'
import styled from 'styled-components'
import { Select } from 'antd'
import { primaryOrange } from 'assets/styles/variables'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { FilterOptionSelect } from 'features/project/features/filters/types'
import { saveToLS } from 'features/project/utils'

type Props = {
  dataset: string | undefined
  setDataset: (dataset: string) => void
  defaultEmpty?: boolean
  comparativeIndex?: number
}

export const DatasetSelector: React.FC<Props> = ({
  dataset,
  setDataset,
  defaultEmpty,
  comparativeIndex,
}) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const updateFilterValues = useProjectStore(
    (state: ProjectState) => state.updateFilterValues,
  )

  // constants
  const options =
    (
      defaultFilterList?.find((el) => el.field === 'proj_uuid') as
        | FilterOptionSelect
        | undefined
    )?.values.map((el) => ({ value: el.cluster_uuid, label: el.value })) || []

  if (!options?.length) {
    return <Container />
  }

  return (
    <Container>
      <Left>Dataset</Left>
      <Right>
        <StyledSelect
          showSearch
          optionFilterProp={'label'}
          filterSort={(optionA, optionB) =>
            (optionA?.label ?? '')
              .toLowerCase()
              .localeCompare((optionB?.label ?? '').toLowerCase())
          }
          options={options}
          // defaultValue={defaultEmpty ? undefined : options[0]?.value}
          value={dataset}
          onChange={(value) => {
            setDataset(value as string)
            updateFilterValues(comparativeIndex || 0, 'proj_uuid', [value])
            saveToLS(`defaultDataset-${projectId}`, value)
          }}
          placeholder={'Select a dataset'}
        ></StyledSelect>
      </Right>
    </Container>
  )
}

const Container = styled.div`
  width: 400px;
  max-width: 90%;
  display: flex;
  box-shadow: 0 4px 4px 0 #0000001a;
  border-radius: 20px;
`

const Left = styled.div`
  font-size: 12px;
  font-weight: 600;
  line-height: 1.6;
  color: white;

  background: #ff7055;
  padding: 5px 20px;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
`

const Right = styled.div`
  width: 100%;
  background: white;
  border-top-right-radius: 20px;
  border-bottom-right-radius: 20px;
  overflow: hidden;
`

const StyledSelect = styled(Select)`
  .ant-select {
    background: white !important;
  }
  width: 100%;
  .ant-select-selector {
    background: transparent !important;
    border: none !important;
    color: ${primaryOrange};
    font-size: 12px;
    height: 100%;
  }
  .ant-select-selection-search-input {
    font-weight: 600;
    margin-left: 10px !important;
  }
  .ant-select-selection-item {
    font-weight: 600;
    margin-left: 10px;
    cursor: pointer;
  }
  .ant-select-arrow {
    color: ${primaryOrange} !important;
    font-weight: 600;
  }
`
