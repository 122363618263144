import React from 'react'
import styled from 'styled-components'
import { Form, Input, Tooltip, DatePicker, Select, Row, Col } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { tooltip } from 'utils/tooltip-data'
import { FormikType } from '../new-source'

const { Option } = Select

type Props = {
  formik: FormikType
  sourceType: string
}

const dateFormat = 'MM/DD/YYYY'

const supportedLocales = [
  'ca_ES',
  'de_DE',
  'de_AT',
  'de_CH',
  'en_AE',
  'en_AU',
  'en_CA',
  'en_CH',
  'en_DE',
  'en_FI',
  'en_GB',
  'en_GR',
  'en_HK',
  'en_IE',
  'en_IN',
  'en_JP',
  'en_KW',
  'en_MY',
  'en_PH',
  'en_PK',
  'en_NZ',
  'en_SA',
  'en_SG',
  'en_US',
  'en_ZA',
  'fr_CA',
  'fr_FR',
  'fr_BE',
  'fr_CH',
  'fr_LU',
  'hi_IN',
  'nl_NL',
  'nl_BE',
  'pt_BR',
  'pt_PT',
  'ar_AE',
  'ar_AR',
  'ar_EG',
  'ar_SA',
  'cs_CZ',
  'da_DK',
  'el_GR',
  'es_AR',
  'es_ES',
  'es_MX',
  'es_US',
  'es_UY',
  'hu_HU',
  'it_CH',
  'it_IT',
  'jp_JP',
  'ja_JP',
  'ko_KR',
  'no_NO',
  'pl_PL',
  'ru_RU',
  'sv_SE',
  'th_TH',
  'tr_TR',
  'zh_CN',
  'zh_HK',
  'zh_TW',
  'ro_RO',
  'ii_ID',
  'fi_FI',
  'lt_LT',
]

export const PowerReviews = ({ formik }: Props) => {
  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceTitle ? 'error' : ''}
        help={formik.errors.sourceTitle}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['form-date']}>
            <span>
              Date <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sourceDate ? 'error' : ''}
        help={formik.errors.sourceDate}
      >
        <DatePicker
          defaultValue={
            formik.values.sourceDate && moment(formik.values.sourceDate)
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue('sourceDate', value.toDate())
            }
          }}
          format={dateFormat}
        />
      </Form.Item>

      <Row justify="space-around">
        <Col span={11}>
          <Form.Item
            label={
              <Tooltip title="Provide the Powerreviews Page ID.">
                <span>
                  Page <InfoCircleOutlined />
                </span>
              </Tooltip>
            }
            validateStatus={!!formik.errors.extra_product_id ? 'error' : ''}
            help={formik.errors.extra_product_id}
          >
            <Flex>
              <Input
                name="extra_product_id"
                onChange={formik.handleChange}
                value={formik.values.extra_product_id}
                placeholder="Insert Page ID here"
              />
            </Flex>
          </Form.Item>
        </Col>
        <Col span={11}>
          <Form.Item
            label={
              <Tooltip title="Provide the Powerreviews Enterprise API Secret.">
                <span>
                  Secret <InfoCircleOutlined />
                </span>
              </Tooltip>
            }
            validateStatus={!!formik.errors.access_token ? 'error' : ''}
            help={formik.errors.access_token}
          >
            <Input
              name="access_token"
              onChange={formik.handleChange}
              value={formik.values.access_token || ''}
              placeholder="Insert API Key here"
            />
          </Form.Item>
        </Col>
      </Row>
      <Row justify="space-around">
        <Col span={11}>
          <Form.Item
            label={
              <Tooltip title="Provide the Powerreviews Enterprise Client ID.">
                <span>
                  Client <InfoCircleOutlined />
                </span>
              </Tooltip>
            }
            validateStatus={!!formik.errors.client_id ? 'error' : ''}
            help={formik.errors.client_id}
          >
            <Input
              name="client_id"
              onChange={formik.handleChange}
              value={formik.values.client_id || ''}
              placeholder="Insert Client ID here"
            />
          </Form.Item>
        </Col>

        <Col span={11}>
          <Form.Item
            label={
              <Tooltip title="Reviews Locale.">
                <span>
                  Locale <InfoCircleOutlined />
                </span>
              </Tooltip>
            }
            validateStatus={!!formik.errors.locale ? 'error' : ''}
            help={formik.errors.locale}
          >
            <Select
              onChange={(option) => formik.setFieldValue('locale', option)}
              value={formik.values.locale}
              defaultValue="en_US"
            >
              {supportedLocales.map((locale, index) => (
                <Option key={index} value={locale}>
                  {locale}
                </Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>
    </>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
`
