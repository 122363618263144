import React from 'react'
import KeyPhrase from './components/KeyPhrase/KeyPhrase'
import { useFeatureFlags } from '../../features/project/hooks/useFeatureFlags'
import reactStringReplace from 'react-string-replace'
import mixpanel from '../../features/trackers/mixpanel'
import {
  initialState,
  useProjectStore,
} from '../../features/project/projectStore/projectStore'
import { getBookmarkUrlPost } from '../../features/url-params/model'
import { getCurrentRouteName } from '../../features/project/utils'
import { Filters, ProjectState } from '../../features/project/types'

const SemanticKeywords: React.FC<{
  body: string
  keywords: string[]
  filterValues: Filters
  threshold?: number
  // ideally this is just filters
  products?: string[]
  themes?: string[]
  brands?: string[]
}> = ({
  body,
  keywords,
  threshold,
  products,
  themes,
  brands,
  filterValues,
}) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // hooks
  const { data: feature_flags } = useFeatureFlags()

  // functions - maybe move to utils
  const openFeedback = async (e: React.MouseEvent, keywords: string[]) => {
    e.preventDefault()
    document.body.style.cursor = 'wait'
    const filters = {
      values: filterValues.values,
      searchQuery: keywords,
      // searchCondition: themeSummaryBody.search_criteria,
      searchCondition: ['or'],
    }
    const pageControls = initialState.feedbackControls
    const body = {
      filters: [filters],
      pageControls,
    }
    const filterHash = await getBookmarkUrlPost(projectId, {
      page: getCurrentRouteName(window.location.pathname),
      filterString: JSON.stringify(body),
    })

    const url = `${window.location.origin}/project/${projectId}/feedback?filters=${filterHash}`
    window.open(url, '')
    document.body.style.cursor = ''
  }

  const replaceKeywordsWithLinks = (paragraph: string, keywords: string[]) => {
    let formatted = paragraph
    keywords.forEach((keyword) => {
      // let regex = new RegExp(keyword + '?(\\w+)')
      // @ts-ignore
      formatted = reactStringReplace(formatted, keyword, (match, i) => (
        <a
          href={'#'}
          onClick={(e) => {
            // mixpanel.track('summary theme', {
            //   action: 'keyword',
            //   value: keyword,
            //   theme: theme.theme,
            //   data: summaryRequest,
            //   proj_uuid: projectId,
            // })
            openFeedback(e, [keyword])
          }}
        >
          {match}
        </a>
      ))
    })
    return formatted
  }

  const identifyKeyPhrases = (
    text: string,
    phrases: string[]
  ): React.ReactNode[] => {
    const result: React.ReactNode[] = []
    let remainingText = text

    while (remainingText.length > 0) {
      let matchFound = false

      for (const phrase of phrases) {
        const lowercasePhrase = phrase.toLowerCase()
        const lowercaseText = remainingText.toLowerCase()
        const index = lowercaseText.indexOf(lowercasePhrase)

        if (index === 0) {
          // Match found at the start
          const actualPhrase = remainingText.slice(0, phrase.length)
          result.push(
            <KeyPhrase
              key={result.length}
              phrase={actualPhrase}
              brands={brands ?? []}
              products={products ? products : []}
              // themes={outlier.theme ? [outlier.theme] : []}
              threshold={threshold}
            />
          )
          remainingText = remainingText.slice(phrase.length)
          matchFound = true
          break
        } else if (index > 0) {
          // Non-phrase text before the match
          const beforeMatch = remainingText.slice(0, index)
          result.push(beforeMatch)

          // The matched phrase
          const actualPhrase = remainingText.slice(index, index + phrase.length)
          result.push(
            <KeyPhrase
              key={result.length}
              phrase={actualPhrase}
              brands={brands ?? []}
              products={products ?? []}
              themes={themes ?? []}
            />
          )

          remainingText = remainingText.slice(index + phrase.length)
          matchFound = true
          break
        }
      }

      if (!matchFound) {
        // No match found, add the first character and continue
        result.push(remainingText[0])
        remainingText = remainingText.slice(1)
      }
    }
    return result
  }

  return (
    <div>
      {feature_flags?.semantic_search_proj
        ? identifyKeyPhrases(body, keywords)
        : replaceKeywordsWithLinks(body, keywords)}
    </div>
  )
}

export default SemanticKeywords
