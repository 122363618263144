import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Form, Input, Tooltip, DatePicker } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { sourceTypes } from 'utils/source_types'
import { FormikType } from '../new-source'
import { extractUrl, retrievePureUrl } from '../../utils'
import { tooltip } from 'utils/tooltip-data'

type Props = {
  formik: FormikType
  sourceType: string
}
const dateFormat = 'MM/DD/YYYY'

export const Url: React.FC<Props> = ({ formik, sourceType }) => {
  const sourceUrl = sourceTypes[sourceType]
  const sourceUrlValue = useMemo(
    () => extractUrl(formik.values.sourceUrl, sourceUrl),
    [formik.values.sourceUrl]
  )

  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-url']}>
            <span>
              URL <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceUrl ? 'error' : ''}
        help={formik.errors.sourceUrl}
        wrapperCol={{ span: 16 }}
      >
        <Flex>
          {sourceTypes[sourceType]}{' '}
          <Input
            name="sourceUrl"
            onChange={(event) => {
              const domain = retrievePureUrl(sourceTypes[sourceType])
              const value = retrievePureUrl(event.target.value).replace(
                domain,
                ''
              )

              formik.setFieldValue('sourceUrl', sourceTypes[sourceType] + value)
            }}
            value={sourceUrlValue}
          />
        </Flex>
      </Form.Item>

      <Form.Item
        label={
          <Tooltip title={tooltip['source-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceTitle ? 'error' : ''}
        help={formik.errors.sourceTitle}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['form-date']}>
            <span>
              Date <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sourceDate ? 'error' : ''}
        help={formik.errors.sourceDate}
      >
        <DatePicker
          defaultValue={
            formik.values.sourceDate && moment(formik.values.sourceDate)
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue('sourceDate', value.toDate())
            }
          }}
          format={dateFormat}
        />
      </Form.Item>
    </>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
`
