import React, { useState, useEffect, FC } from 'react'
import styled from 'styled-components'
import {
  NumberOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
  ArrowLeftOutlined,
} from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import {
  initialState,
  useProjectStore,
} from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { Tier2Option } from 'features/project/features/Charts/types'
import { useChartData } from '../../../../../hooks/useChartData'
import { DraggableChart } from './DraggableChart'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import { YogiSelect } from '../../../../../../../components/UI/YogiSelect'
import { ArrowRight } from '@mui/icons-material'
import { LogoSpinnerSmall } from 'components/Loading/LogoSpinner/LogoSpinnerSmall'
import { emptyFilters } from '../../../../../utils'
import { Breakdown } from 'features/project/features/filters/Header/components/Breakdown/Breakdown'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { Loader } from 'shared/components'

type CardProps = { selected?: boolean; localSelected?: boolean }
const icons: any = {
  NumberOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
}

export interface DraggableChartCardsProps {
  items: any[]
  addItem: (item: any) => void
  onAddItem: (item: any, layoutItem: any) => void
  // setShowReset: (showReset: boolean) => void
}

export const DraggableChartCards: FC<DraggableChartCardsProps> = ({
  items,
  addItem,
  // setShowReset,
  onAddItem,
}) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const dashboardControls = JSON.parse(
    JSON.stringify(initialState.dashboardControls[0]),
  )
  const [tier1, setTier1] = useState('Brand')

  const { data } = useChartData(
    0,
    emptyFilters,
    tier1,
    dashboardControls?.chartId || '5_5_7_rating_sentiment',
    dashboardControls?.postType,
    dashboardControls?.timeInterval,
    dashboardControls?.reviewsCount,
  )

  const chartOptions =
    data?.tier1?.options
      .filter((el) => el.name !== 'bar')
      .sort((a: any, b: any) => a.name.localeCompare(b.name))
      .map((option: any) => ({
        value: option.name,
        label: option.name,
      })) ?? []

  const cardFilter = data?.tier2

  const [selectedOption, setSelectedOption] = useState<Tier2Option | null>(null)

  // useEffect(() => {
  //   if (selectedOption) {
  //     setShowReset(false)
  //   } else {
  //     setShowReset(true)
  //   }
  // }, [selectedOption])

  const handleChange = (value: any) => {
    mixpanel.track('chart lens', { value: value, ...route })
    setTier1(value)
  }

  if (!data || !chartOptions.length)
    return (
      <div style={{ marginTop: 20 }}>
        <LogoSpinner height={100}></LogoSpinner>
      </div>
    )

  return (
    <Wrapper>
      {!selectedOption ? (
        <>
          {/*<div style={{ float: 'left', width: '100%', marginBottom: -16 }}>*/}
          {/*  Breakdown By:*/}
          {/*</div>*/}
          {/*<YogiSelect*/}
          {/*  defaultValue={tier1}*/}
          {/*  value={tier1}*/}
          {/*  onChange={handleChange}*/}
          {/*  options={chartOptions}*/}
          {/*  dropdownMatchSelectWidth*/}
          {/*  style={{ width: '100%' }}*/}
          {/*/>*/}
          <Breakdown chartItem={data} tier1={tier1} onChange={handleChange} />

          <Label>Chart Type:</Label>
          {!data ? (
            <div style={{ marginTop: 20 }}>
              <LogoSpinner height={100}></LogoSpinner>
            </div>
          ) : (
            <ChartTypeList>
              {cardFilter?.options.map((option: any, optionIndex: any) => {
                const AntdIcon = icons[option.icon] as any
                return (
                  <CardWrapper
                    key={option.name}
                    className={'charts-' + option.name}
                  >
                    <Card
                      onClick={() => {
                        if (!option.disabled) {
                          setSelectedOption(option)
                        }
                      }}
                    >
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <AntdIcon />
                        <Name>{option.name}</Name>
                      </div>
                      <ArrowRight />
                    </Card>
                  </CardWrapper>
                )
              })}
            </ChartTypeList>
          )}
        </>
      ) : (
        <>
          <YogiButton
            onClick={() => setSelectedOption(null)}
            style={{ margin: '10px 0px' }}
          >
            <ArrowLeftOutlined /> All Charts
          </YogiButton>
          <Charts>
            {selectedOption.tier3.map((chart: any, index: any) => {
              return (
                <DraggableChart
                  key={index}
                  chart={chart}
                  items={items}
                  addItem={addItem}
                  tier1={tier1}
                  onAddItem={onAddItem}
                />
              )
            })}
          </Charts>
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  flex: 0;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  //padding: 0px 20px;
  //gap: 20px;
  padding-top: 5px;
`

const CardWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Card = styled.button`
  width: 100%;
  justify-content: space-between;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s;
  background: white;
  border-radius: 6px;
  border: 2px solid rgba(159, 183, 195, 0.15);

  &:hover {
    border: 2px solid #4070c0;
  }

  :disabled {
    background: white;
    color: rgba(0, 0, 0, 0.16);
    cursor: not-allowed;

    &:hover {
      border: 2px solid rgba(159, 183, 195, 0.15);
    }
  }
`
const Name = styled.span`
  white-space: nowrap;
  //font-size: var(--font-size-md);
  margin-left: 10px;
  //line-height: 1.25;
  text-align: left;
  font-size: var(--font-size-md);
`
const Charts = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
`

const ChartTypeList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Label = styled.div`
  width: 100%;
  text-align: left;

  margin-top: 5px;
`
