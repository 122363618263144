import { sourceTypes } from './source_types'
const custom_hostnames: { [index: string]: string } = {
  'commercial.keurig.com': 'Keurig',
  'jeux-video.fnac.com': 'FNAC',
  'groceries.asda.com': 'Asda',
  'catalog.11st.co.kr': '11st',
  'articulo.mercadolibre.com.mx': 'MercadoLibre',
  // Note: Following this pattern bc these sites exist in many TLDs
  GoogleMaps: 'GoogleMaps',
  GoogleShopping: 'GoogleShopping',
}

const findSourceType = (
  sourceUrlType: string,
  leaveUrlBaseOnly: boolean = false
) => {
  const sourceType = Object.keys(sourceTypes).find(
    (key) =>
      String(
        clearUrlType(sourceTypes[key], leaveUrlBaseOnly)
      ).toLocaleLowerCase() === sourceUrlType
  )
  return sourceType
}

const processUrl = (url: string, leaveUrlBaseOnly: boolean) => {
  const sourceUrlType = clearUrlType(url, leaveUrlBaseOnly)
  if (sourceUrlType && sourceUrlType in custom_hostnames) {
    return custom_hostnames[sourceUrlType]
  }

  return (
    (sourceUrlType && findSourceType(sourceUrlType, leaveUrlBaseOnly)) ||
    undefined
  )
}

const prepareURL = (urlSrc: string) => {
  let url = urlSrc
  url =
    !url.includes('https://') && !url.includes('http://')
      ? `https://${url}`
      : url
  url = url.endsWith('/') ? url : `${url}/`
  return url
}

const doesUrlContainSubstrings = (url: string, substrings: string[]) =>
  [...substrings].every((subStr) => url.toLowerCase().includes(subStr))

const clearUrlType = (sourceUrl: string, leaveUrlBaseOnly: boolean = false) => {
  let cleanedUrl = prepareURL(sourceUrl)

  try {
    const isGoogleShoppingURL = doesUrlContainSubstrings(cleanedUrl, [
      'www.google',
      '/shopping/',
    ])
    const isGoogleMapsURL = doesUrlContainSubstrings(cleanedUrl, [
      'www.google',
      '/maps/',
    ])
    if (isGoogleMapsURL) {
      return 'GoogleMaps'
    }
    if (isGoogleShoppingURL) {
      return 'GoogleShopping'
    }

    let host = new URL(cleanedUrl).host
    host = host.replace('www.', '')

    if (leaveUrlBaseOnly) {
      host = host
        .replace('.com', '')
        .split('.')
        .reduce((a, b) => (a.length >= b.length ? a : b))
    }
    return host
  } catch {
    return undefined
  }
}

export const getSourceTypeByUrl = (url: string) => {
  let sourceType = processUrl(url, false)
  if (!sourceType) {
    sourceType = processUrl(url, true)
  }
  return sourceType
}
