export type Project = {
  description: string
  last_update: number
  n_posts: number
  n_sources: number
  n_brands: number
  n_products: number
  n_ratings: number
  state: string
  title: string
  uuid: string
  role: string
}

export type Group = {
  projects: GroupProject[]
}

export type GroupProject = {
  uuid: string
  title: string
  grouped?: boolean
}

export type ProjectMetaType = {
  reserved_keys: string[]
}

export enum ProjectStatuses {
  CREATED = 'created',
  PREPROCESSING = 'preprocessing',
  PREPROCESSING_UPDATE = 'preprocessing update',
  PREPROCESSING_COMPLETE = 'preprocessing complete',
  PREPROCESSING_UPDATE_COMPLETE = 'preprocessing update complete',
  NETWORK_PROCESSING = 'network processing',
  NETWORK_PROCESSING_UPDATE = 'network processing update',
  NETWORK_COMPLETE = 'network complete',
  NETWORK_UPDATE_COMPLETE = 'network update complete',
  CLUSTERING_UPDATE_COMPLETE = 'clustering update complete',
  COMPLETE = 'complete',
  ERROR = 'error',
}

export type HomeContext = {
  refetch: () => void
  projects: Project[]
  loading: boolean
  searchType: string
  searchTerm: string
  searchTypeChanged: boolean
  updateState: boolean
  modalOpened: boolean
  setSearchTerm: (v: string) => void
  setSearchType: (v: string) => void
  setSearchTypeChanged: (v: boolean) => void
  setUpdateState: (v: boolean) => void
  setModalOpened: (v: boolean) => void
}
