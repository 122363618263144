import type { FC } from 'react'
import React, { memo, useEffect, useRef, useState } from 'react'

import styled from 'styled-components'
import { Layout } from 'react-grid-layout'
import { Chart, ChartHandle } from './Charts/chart'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import { Header } from './header'
import { DashboardFilterHeader } from './components/DashboardFilterHeader'
import _ from 'lodash'
import { Grid } from '@mui/material'
import { saveDashboard } from '../../model'
import { exportAllImages } from '../../utils'
import { DisplayWidget } from './components/DisplayWidget'
import { DashboardResponse, DashboardSettings } from '../../types'
import { Summary } from '../../../summary'
import mixpanel from '../../../../../trackers/mixpanel'
import { TextWidget } from './components/TextWidget'
import { Annotations } from './Annotations/Annotations'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import { useDashboardStore } from '../../dashboardStore'
import { AlertDrawer } from 'features/project/features/alerts/Components/AlertDrawer'
import { ExportDrawer } from 'features/project/features/Charts/components/ExportDrawer/ExportDrawer'

type Props = {
  filterValues: Filters
  setFilters: (filters: Filters) => void
  setView: (view: 'list' | 'view' | 'edit') => void
  uneditedDashboard: DashboardResponse | undefined
  selectedDashboard: DashboardResponse | undefined
  setSelectedDashboard: (
    selectedDashboard: DashboardResponse | undefined,
  ) => void
  setSharingDashboard: (sharingDashboard: DashboardResponse | undefined) => void
  isOwner?: boolean
  isPublic?: boolean
}
export const DashboardView: FC<Props> = memo(function ContainerFn({
  filterValues,
  setFilters,
  uneditedDashboard,
  selectedDashboard,
  setView,
  setSelectedDashboard,
  setSharingDashboard,
  isOwner,
  isPublic,
}) {
  const [itemMap, setItemMap] = useState<{ [key: string]: any }>({})
  const [shadowMap, setShadowMap] = useState<{ [key: string]: any }>({})
  const [curLayout, setCurLayout] = useState<Layout[]>([])
  const { layout } = selectedDashboard?.dashboard.dashboard_settings ?? {}

  const comparativeIndex = 0
  // TODO should this be in own project store?
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const setGlobalIsExporting = useProjectStore(
    (state: ProjectState) => state.setIsExporting,
  )

  const hoveredAnnotation = useDashboardStore(
    (state) => state.hoveredAnnotation,
  )

  const element = hoveredAnnotation?.component
    ? document.querySelectorAll(
        "[data-chartid='" + hoveredAnnotation?.component?.chart_id + "']",
      )[0]
    : null

  element?.scrollIntoView({ behavior: 'smooth' })

  const { data: feature_flags } = useFeatureFlags()

  const rowHeight = 50

  const [dashboard, setDashboard] = useState<DashboardSettings | undefined>(
    selectedDashboard?.dashboard.dashboard_settings,
  )
  // const [dashboardName, setDashboardName] = useState<string>(
  //   selectedDashboard?.dashboard.name ?? 'New Dashboard',
  // )

  const dashboardName = selectedDashboard?.dashboard.name ?? ''

  const [showResetDashboard, setShowResetDashboard] = useState<boolean>(false)
  const chartRefs = useRef<ChartHandle[]>([])
  const containerRef = useRef<HTMLDivElement>(null)
  const [chartRefsMap, setChartRefsMap] = useState<{ [key: string]: any }>({})

  // TODO this should respond to filter/settings changes in items as well as on the parent level
  useEffect(() => {
    if (dashboard && !_.isEqual(dashboard.filters, filterValues)) {
      setShowResetDashboard(true)
    } else {
      setShowResetDashboard(false)
    }
  }, [filterValues])

  useEffect(() => {
    if (selectedDashboard) {
      setDashboard(selectedDashboard.dashboard.dashboard_settings)
    } else {
      setDashboard(undefined)
    }
  }, [selectedDashboard])

  // Commenting this allowed the "Keep Recent" functionality to work when opening but may introduce a bug /shrug
  // todo can prob be condensed into above
  // useEffect(() => {
  //   if (dashboard) {
  //     setFilters(dashboard?.filters)
  //   }
  // }, [dashboard])

  const [headerHeight, setHeaderHeight] = useState(40)

  const addToRefs = (el: ChartHandle) => {
    if (el && !chartRefs.current.includes(el)) {
      chartRefs.current.push(el)
      chartRefsMap[el.index] = el
      setChartRefsMap(chartRefsMap)
    }
  }

  useEffect(() => {
    setItemMap(shadowMap)
  }, [layout])

  const updateItem = (
    index: string,
    filterValues: Filters | null,
    dashboardControls: any,
    title?: string,
  ) => {
    // mixpanel.track('custom dashboard', {
    //   action: 'update item',
    //   value: selectedDashboard?.dashboard?.id,
    //   type: 'view',
    //   proj_uuid: projectId,
    // })
    const item = JSON.parse(JSON.stringify(dashboard?.items[Number(index)]))
    if (filterValues) {
      item.filterValues = filterValues
    }
    if (dashboardControls) {
      item.dashboardControls = dashboardControls
    }
    if (title) {
      item.title = title
    }
    shadowMap[`${index}`] = item
    setShadowMap(shadowMap)
  }

  const handleSaveDashboard = async () => {
    // this doesnt get used fyi
    mixpanel.track('custom dashboard', {
      action: 'save',
      value: selectedDashboard?.dashboard?.id,
      proj_uuid: projectId,
    })
    const orderedLayout = curLayout.sort((a, b) => {
      if (a.y !== b.y) {
        return a.y - b.y
      } else {
        return a.x - b.x
      }
    })
    const orderedItems = orderedLayout.map((l) => itemMap[l.i])
    const dashboardLayout = {
      layout: orderedLayout,
      items: orderedItems,
      filters: filterValues,
    }
    await saveDashboard(projectId, {
      name: dashboardName,
      dashboard_settings: dashboardLayout,
      is_shared: false,
    })
    setDashboard(dashboardLayout)
  }
  const [isExporting, setIsExporting] = useState<boolean>(false)

  useEffect(() => {
    if (isExporting) {
      setGlobalIsExporting(true)
      exportAllImages(
        // @ts-ignore
        layout,
        chartRefsMap,
        dashboard,
        projectId,
        filterValues,
        defaultFilterList,
        dashboardName,
        new Date().toLocaleDateString(),
        setIsExporting,
      )
    } else {
      setGlobalIsExporting(false)
    }
  }, [isExporting])

  return (
    <Container>
      <Header
        saveDashboard={handleSaveDashboard}
        exportDashboard={() => {
          mixpanel.track('custom dashboard', {
            action: 'export',
            value: selectedDashboard?.dashboard.id,
            proj_uuid: projectId,
          })
          setIsExporting(true)
        }}
        showResetDashboard={showResetDashboard}
        dashboardName={dashboardName}
        dashboard={selectedDashboard}
        setView={setView}
        setSharingDashboard={() => {
          setSharingDashboard(selectedDashboard)
        }}
        isExporting={isExporting}
        isOwner={isOwner}
        isPublic={isPublic}
        setHeaderHeight={setHeaderHeight}
      />
      <ViewWrapper headerHeight={headerHeight}>
        <DashboardFilterHeader
          filterValues={filterValues}
          setFilters={setFilters}
          comparativeIndex={0}
          isCurrent={
            selectedDashboard?.dashboard?.dashboard_settings.isCurrent ?? false
          }
          timeResolution={
            selectedDashboard?.dashboard?.dashboard_settings.timeResolution ??
            'Trailing'
          }
          trailingDaysCount={
            selectedDashboard?.dashboard?.dashboard_settings.trailingDaysCount
          }
          copyRef={containerRef}
          dashboardId={selectedDashboard?.dashboard?.id}
          dashboardName={dashboardName}
          showResetDashboard={showResetDashboard}
          resetDashboard={() => {
            if (uneditedDashboard) {
              setSelectedDashboard(
                JSON.parse(JSON.stringify(uneditedDashboard)),
              )
              setFilters(
                uneditedDashboard?.dashboard.dashboard_settings.filters,
              )
            }
          }}
          exportDashboard={() => {
            mixpanel.track('custom dashboard', {
              action: 'export',
              value: selectedDashboard?.dashboard.id,
              proj_uuid: projectId,
            })
            setIsExporting(true)
          }}
        />
        <div
          style={{
            overflow: 'auto',
            height: 'calc(100% - 50px)',
            paddingTop: 12,
          }}
        >
          <Grid
            ref={containerRef}
            container
            style={{ padding: 10, marginTop: -20 }}
            spacing={1}
          >
            {dashboard?.items.map((item: any, index: number) => {
              if (item.type === 'chart') {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={dashboard.layout[index].w}
                    style={{
                      // react-grid-layout adds 10px to the height of each row (except one)
                      height: (rowHeight + 10) * dashboard.layout[index].h - 10,
                      position: 'relative',
                      // border:
                      //   hoveredAnnotation &&
                      //   (item.dashboardControls.chartId ??
                      //     item.content.chart_ids[0].chart_id) ===
                      //     hoveredAnnotation.component?.chart_id
                      //     ? `2px solid ${buttonBlue}`
                      //     : '2px solid transparent',
                    }}
                    data-chartid={
                      item.dashboardControls.chartId ??
                      item.content.chart_ids[0].chart_id
                    }
                  >
                    {/*TODO should this be DisplayChart imported from /charts?*/}
                    <Chart
                      ref={addToRefs}
                      filters={filterValues}
                      chartId={
                        item.dashboardControls.chartId ??
                        item.content.chart_ids[0].chart_id
                      }
                      comparativeIndex={comparativeIndex}
                      isComparative={false}
                      dashboardControls={item.dashboardControls}
                      tier1={item.dashboardControls.tier1 ?? item.content.tier1}
                      isEditing={false}
                      item={item}
                      index={index.toString()}
                      updateItem={updateItem}
                      isLink={!isPublic}
                      focused={
                        hoveredAnnotation &&
                        (item.dashboardControls.chartId ??
                          item.content.chart_ids[0].chart_id) ===
                          hoveredAnnotation.component?.chart_id
                      }
                    />
                  </Grid>
                )
              } else if (item.type === 'widget') {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={dashboard.layout[index].w}
                    style={{
                      height: (rowHeight + 10) * dashboard.layout[index].h - 10,
                      position: 'relative',
                    }}
                  >
                    <DisplayWidget
                      ref={addToRefs}
                      name={item.name}
                      filters={filterValues}
                      item={item}
                      index={index.toString()}
                      updateItem={updateItem}
                      layout={dashboard.layout[index]}
                      dashboardControls={item.dashboardControls}
                    />
                  </Grid>
                )
              } else if (item.type === 'text') {
                return (
                  <Grid
                    key={index}
                    item
                    xs={12}
                    sm={dashboard.layout[index].w}
                    style={{
                      height: (rowHeight + 10) * dashboard.layout[index].h - 10,
                      position: 'relative',
                    }}
                  >
                    <TextWidget
                      ref={addToRefs}
                      item={item}
                      index={index.toString()}
                    />
                  </Grid>
                )
              }
            })}
          </Grid>
        </div>
      </ViewWrapper>
      {feature_flags?.annotations && selectedDashboard && (
        <Annotations dashboard={selectedDashboard} isPublic={isPublic} />
      )}
      <Summary isPublic={isPublic} />
      {!isPublic && <AlertDrawer />}
      <ExportDrawer isPublic />
    </Container>
  )
})

const Container = styled.div`
  position: relative;
  justify-content: flex-start;
  flex-direction: row;
  max-height: calc(100vh - 92px);
  //overflow-y: auto;
  //overflow-x: hidden;
  height: 100%;
  width: 100%;
`
const ViewWrapper = styled.div<{ headerHeight?: number }>`
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  height: 100%;
  width: 100%;
  max-height: ${(props) =>
    props.headerHeight ? `calc(100vh - ${props.headerHeight}px)` : '100%'};
`
