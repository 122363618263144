import React, { useContext } from 'react'
import { Profile } from '../components/profile'
import { PageWrapper } from 'shared/wrappers'
import { AuthContext } from 'features/auth'

export const ProfileContainer = () => {
  const authContext = useContext(AuthContext)
  return (
    <PageWrapper>
      <Profile
        data={authContext.userDetails}
        loading={!authContext.userDetails}
        is_trial={authContext.userDetails?.free_trial}
      />
    </PageWrapper>
  )
}
