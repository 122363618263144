import styled from 'styled-components'
import { mainText, secondaryNavy } from '../../../assets/styles/variables'

export const Pill = styled.button<{ selected: boolean }>`
  display: inline-block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;

  font-size: var(--font-size-md);

  padding: 3px 15px;
  border-radius: 20px;
  background: ${(props) => (props.selected ? secondaryNavy : 'white')};
  color: ${(props) => (props.selected ? 'white' : mainText)};
  border: ${(props) =>
    props.selected ? '1px solid ' + secondaryNavy : '1px solid #CDD3DD'};
  cursor: pointer;
  transform: translate(0%);
  transition: 0.15s ease-out;

  &:hover {
    background: ${() => secondaryNavy};
    color: white;
    border: ${() => '1px solid ' + secondaryNavy};
  }
`
