import React, { FC } from 'react'
import styled from 'styled-components'
import {
  NumberOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
  PlusOutlined,
} from '@ant-design/icons'
import {
  initialState,
  useProjectStore,
} from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { ArrowRight } from '@mui/icons-material'
import { useQuery } from 'react-query'
import { getSavedViews } from '../../../../saved-vews/model'
import { PostsEmpty } from '../../../../../../../components/PostsEmpty'
import {
  LaunchCard,
  LaunchCardList,
  LaunchCardListItem,
  LaunchTitle,
  savedIcons,
} from 'features/project/features/Home/components/Content/Content'
import { getOriginalUrl } from '../../../../../../url-params/model'

type CardProps = { selected?: boolean; localSelected?: boolean }
const icons: any = {
  NumberOutlined,
  PieChartOutlined,
  BarChartOutlined,
  ClockCircleOutlined,
  TrophyOutlined,
  PlusOutlined,
}

export interface TemplateSelectorProps {
  selectTemplate: (template: any) => void
  isSavedViews?: boolean
  items: any[]
}

export const TemplateSelector: FC<TemplateSelectorProps> = ({
  selectTemplate,
  isSavedViews,
  items,
}) => {
  const templateOptions = [
    // {
    //   id: 1,
    //   name: 'My Brands',
    //   icon: 'PlusOutlined',
    //   filters: {
    //     values: [
    //       {
    //         field: 'brand',
    //         values: ['Advil', 'Aleve', 'Basic Care'],
    //       },
    //     ],
    //     searchQuery: [],
    //     searchCondition: [],
    //   },
    //   layout: [
    //     {
    //       w: 12,
    //       h: 9,
    //       x: 0,
    //       y: 0,
    //       i: '0',
    //       minW: 4,
    //       minH: 4,
    //       moved: false,
    //       static: false,
    //       isDraggable: true,
    //     },
    //     {
    //       w: 12,
    //       h: 9,
    //       x: 0,
    //       y: 18,
    //       i: '1',
    //       minW: 4,
    //       minH: 4,
    //       moved: false,
    //       static: false,
    //       isDraggable: true,
    //     },
    //     {
    //       w: 6,
    //       h: 9,
    //       x: 6,
    //       y: 9,
    //       i: '2',
    //       minW: 4,
    //       minH: 4,
    //       moved: false,
    //       static: false,
    //       isDraggable: true,
    //     },
    //     {
    //       w: 6,
    //       h: 9,
    //       x: 0,
    //       y: 9,
    //       i: '3',
    //       minW: 4,
    //       minH: 4,
    //       moved: false,
    //       static: false,
    //       isDraggable: true,
    //     },
    //     {
    //       w: 12,
    //       h: 9,
    //       x: 0,
    //       y: 27,
    //       i: '4',
    //       minW: 4,
    //       minH: 4,
    //       moved: false,
    //       static: false,
    //       isDraggable: true,
    //     },
    //   ],
    //   items: [
    //     {
    //       type: 'chart',
    //       content: {
    //         name: 'Brand',
    //         image: '2_3.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_2_3_categories',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     {
    //       type: 'chart',
    //       content: {
    //         name: 'Themes',
    //         image: '3_14.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_3_14_themes',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     {
    //       type: 'chart',
    //       content: {
    //         name: 'Rating',
    //         image: '3_16.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_3_16_rating',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     {
    //       type: 'chart',
    //       content: {
    //         name: 'Sentiment',
    //         image: '3_17.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_3_17_sentiment',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     {
    //       type: 'chart',
    //       content: {
    //         name: 'Summary Metrics',
    //         image: '1_13.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_1_13_categories',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //   ],
    //   itemMap: {
    //     '0': {
    //       type: 'chart',
    //       content: {
    //         name: 'Brand',
    //         image: '2_3.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_2_3_categories',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     '1': {
    //       type: 'chart',
    //       content: {
    //         name: 'Themes',
    //         image: '3_14.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_3_14_themes',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     '2': {
    //       type: 'chart',
    //       content: {
    //         name: 'Rating',
    //         image: '3_16.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_3_16_rating',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: false,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: true,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     '3': {
    //       type: 'chart',
    //       content: {
    //         name: 'Sentiment',
    //         image: '3_17.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_3_17_sentiment',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: false,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: true,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //     '4': {
    //       type: 'chart',
    //       content: {
    //         name: 'Summary Metrics',
    //         image: '1_13.png',
    //         chart_ids: [
    //           {
    //             chart_id: '5_1_13_categories',
    //           },
    //         ],
    //         tier1: 'Brand',
    //       },
    //       dashboardControls: {
    //         chartId: null,
    //         tier1: null,
    //         tier2: null,
    //         tier3: null,
    //         legend: true,
    //         fullAxis: true,
    //         hasLabels: true,
    //         hasOverlay: false,
    //         treeView: true,
    //         origin: true,
    //         labels: true,
    //         trendlines: false,
    //         shadedZones: true,
    //         sortedByCategory: false,
    //         isAlignScales: true,
    //         isAlignOrder: true,
    //         multiChart: false,
    //         fontSize: 'medium',
    //         timeInterval: null,
    //         reviewsCount: 0,
    //         postType: 'post',
    //         interval: null,
    //         isExpandChart: false,
    //       },
    //       filterValues: {
    //         values: [],
    //         searchQuery: [],
    //         searchCondition: [],
    //       },
    //     },
    //   },
    // },
    {
      id: 0,
      name: 'Create New',
      icon: 'PlusOutlined',
      filters: {
        values: [],
        searchCondition: [],
        searchQuery: [],
      },
      layout: [],
      items: [],
      itemMap: {},
    },
  ]
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const { data: savedViews } = useQuery(
    ['saved-views', projectId],
    () => getSavedViews(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!isSavedViews,
    },
  )

  return (
    <Wrapper>
      {isSavedViews ? (
        <>
          <Text>Saved Views</Text>
          <LaunchCardList container spacing={2}>
            {!savedViews?.length && (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: '100%',
                }}
              >
                <PostsEmpty>No Saved Views...yet!</PostsEmpty>
              </div>
            )}
            {savedViews?.map((el, index) => (
              <LaunchCardListItem key={index} item xs={12}>
                <LaunchCard
                  key={index}
                  onClick={async () => {
                    // TODO handle compare view
                    const searchParams = new URLSearchParams(el.search_url)
                    const originalUrlData = await getOriginalUrl(
                      projectId,
                      searchParams.get('filters') || '',
                    )
                    const filterObj = JSON.parse(originalUrlData)
                    const dashboardControls = filterObj.pageControls[0]
                    let option = {
                      id: 0,
                      name: el.name,
                      layout: [
                        {
                          w: 12,
                          h: 9,
                          x: 0,
                          y: 0,
                          i: '0',
                          minW: 4,
                          minH: 4,
                          moved: false,
                          static: false,
                          isDraggable: true,
                        },
                      ],
                      icon: 'PlusOutlined',
                      filters: filterObj.filters[0],
                      items: [
                        {
                          type: 'chart',
                          content: {
                            name: 'Sentiment',
                            image: '3_17.png',
                            chart_ids: [
                              {
                                chart_id: dashboardControls.chartId,
                              },
                            ],
                            tier1: dashboardControls.tier1,
                          },
                          dashboardControls: dashboardControls,
                          filterValues: initialState.filters[0],
                        },
                      ],
                      itemMap: {
                        '0': {
                          type: 'chart',
                          content: {
                            name: 'Sentiment',
                            image: '3_17.png',
                            chart_ids: [
                              {
                                chart_id: dashboardControls.chartId,
                              },
                            ],
                            tier1: dashboardControls.tier1,
                          },
                          dashboardControls: dashboardControls,
                          filterValues: initialState.filters[0],
                        },
                      },
                    }
                    selectTemplate(option)
                    // mixpanel.track('home saved view', {
                    //   action: 'load',
                    //   type: 'user',
                    //   value: el.name,
                    // })
                    // updateLastRecentlyUsed(projectId, el.uuid)
                    // updateFilters(el)
                  }}
                >
                  <SVIcon>
                    {/*<TrendingUp style={{ color: secondaryGreen }} />*/}
                    {/*<TrendingUp style={{ color: secondaryGreen }} />*/}
                    {savedIcons[el?.page || 'default']}
                  </SVIcon>
                  <LaunchTitle>{el.name}</LaunchTitle>
                </LaunchCard>
              </LaunchCardListItem>
            ))}
          </LaunchCardList>
        </>
      ) : (
        <>
          <Text>Templates</Text>
          {templateOptions.map((option: any, optionIndex: any) => {
            const AntdIcon = icons[option.icon] as any
            return (
              <CardWrapper key={option.name}>
                <Card
                  disabled={option.disabled}
                  selected={false}
                  onClick={() => {
                    if (!option.disabled) {
                      selectTemplate(option)
                    }
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon>{<AntdIcon />}</Icon>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Name>{option.name}</Name>
                    </div>
                  </div>
                  <ArrowRight />
                </Card>
              </CardWrapper>
            )
          })}

          {!!items.length && (
            <>
              <h4>Or</h4>
              <CardWrapper>
                <Card
                  selected={false}
                  onClick={() => {
                    selectTemplate(null)
                  }}
                >
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Icon>
                      <PlusOutlined />
                    </Icon>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                      <Name>Continue Current</Name>
                    </div>
                  </div>
                  <ArrowRight />
                </Card>
              </CardWrapper>
            </>
          )}
        </>
      )}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  //position: absolute;
  flex: 0;
  height: 100%;
  flex-grow: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0px 20px;
  gap: 20px;
  //background: var(--page-background-grey);

  //border-top: 2px solid rgba(159, 183, 195, 0.15);
  //border-bottom: 2px solid rgba(159, 183, 195, 0.15);
  //@media (max-width: 1450px) {
  //  position: relative;
  //  justify-content: flex-start;
  //}
  //@media (max-width: 1400px) {
  //  gap: 10px;
  //}
  //@media (max-width: 1300px) {
  //  //position: absolute;
  //  justify-content: center;
  //  gap: 20px;
  //}
  //@media (max-width: 1100px) {
  //  position: relative;
  //  justify-content: flex-start;
  //  gap: 0px;
  //}
`

const Text = styled.div`
  margin: 0px 4px;
  //@media (max-width: 1300px) {
  //  display: none;
  //  margin: 0px;
  //}
`

const CardWrapper = styled.div`
  width: 100%;
  position: relative;
`

const Card = styled.button`
  //@media (min-width: 1400px) {
  //  min-width: 120px;
  //}
  //@media (max-width: 1400px) {
  //  min-width: 110px;
  //}
  //@media (max-width: 1300px) {
  //  min-width: 0px;
  //}
  width: 100%;
  justify-content: space-between;
  //box-shadow: var(--default-box-shadow);
  //border: none;
  padding: 5px 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  transition: background-color 0.15s;
  background: white;
  border-radius: 6px;
  border: 2px solid rgba(159, 183, 195, 0.15);

  &:hover {
    border: 2px solid #4070c0;
  }

  ${({ selected }: CardProps) =>
    selected &&
    'background: linear-gradient(#f4f5ff, #f4f5ff) padding-box, linear-gradient(180deg, #3378ea 0%, #5933ab 100%) border-box; border-radius: 6px; border: 2px solid transparent;'};

  //@media (max-width: 1300px) {
  //  margin: 0 calc(var(--default-padding-half) / 6);
  //}

  :disabled {
    background: white;
    color: rgba(0, 0, 0, 0.16);
    cursor: not-allowed;

    &:hover {
      border: 2px solid rgba(159, 183, 195, 0.15);
    }
  }
`
const Icon = styled.span`
  font-size: 24px;
`
export const SVIcon = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-basis: 10%;
`
const Name = styled.span`
  white-space: nowrap;
  font-size: var(--font-size-md);
  margin-left: 10px;
  line-height: 1.25;
  text-align: left;
  //@media (max-width: 1300px) {
  //  display: none;
  //}
`
