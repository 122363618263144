import React, { useState } from 'react'
import { Button, message } from 'antd'
import { CheckOutlined, CopyOutlined, LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { toast } from 'react-toastify'
import { YogiButton } from 'components/UI/YogiButton'
import { pdpCopyButton, pdpCopyButtonHover } from 'assets/styles/variables' // Make sure to create a CSS file for styling

interface PDPCopyButtonProps {
  textToCopy: string
}

const PDPCopyButton: React.FC<PDPCopyButtonProps> = ({ textToCopy }) => {
  const copyToClipboard = async () => {
    try {
      await navigator.clipboard.writeText(textToCopy)
      toast.success('Copied to clipboard!')
    } catch (err) {
      toast.error('Failed to copy!')
    }
  }

  const [isCopying, setIsCopying] = useState(false)
  const [showCheck, setShowCheck] = useState(false)

  return (
    <Copy data-html2canvas-ignore={'true'} onClick={copyToClipboard}>
      {isCopying ? (
        <LoadingOutlined />
      ) : showCheck ? (
        <CheckOutlined />
      ) : (
        <>
          <svg
            width="13"
            height="15"
            viewBox="0 0 13 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.1455 0H4.4582C3.64087 0 2.97214 0.675 2.97214 1.5V10.5C2.97214 11.325 3.64087 12 4.4582 12H11.1455C11.9628 12 12.6316 11.325 12.6316 10.5V1.5C12.6316 0.675 11.9628 0 11.1455 0ZM11.1455 10.5H4.4582V1.5H11.1455V10.5ZM0 9.75V8.25H1.48607V9.75H0ZM0 5.625H1.48607V7.125H0V5.625ZM5.20124 13.5H6.68731V15H5.20124V13.5ZM0 12.375V10.875H1.48607V12.375H0ZM1.48607 15C0.668731 15 0 14.325 0 13.5H1.48607V15ZM4.08669 15H2.60062V13.5H4.08669V15ZM7.80186 15V13.5H9.28793C9.28793 14.325 8.61919 15 7.80186 15ZM1.48607 3V4.5H0C0 3.675 0.668731 3 1.48607 3Z"
              fill="#28364F"
            />
          </svg>
          <div>Copy</div>
        </>
      )}
    </Copy>
  )
}

export default PDPCopyButton

export const Copy = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;

  font-size: var(--font-size-md);
  padding: 14px 25px;

  background: ${pdpCopyButton};

  cursor: pointer;
  border-radius: 10px;

  &:hover {
    background: ${pdpCopyButtonHover};
  }
`
