import { createTheme } from '@mui/material/styles'
import { buttonBlue } from 'assets/styles/variables'
import { CATALOG_URL } from '../../../../utils'

export const theme = createTheme({
  components: {
    MuiAutocomplete: {
      styleOverrides: {
        root: {
          borderRadius: 8,
          border: 'none',
        },
        input: {
          border: 'none',
        },
        inputRoot: {
          border: 'none',
        },
        listbox: {
          fontFamily: 'Poppins, sans-serif',
          fontSize: '20px',
        },
        groupLabel: {
          // fontFamily: 'Poppins, sans-serif',
          fontSize: '18px',
          background: '#f5f4f4',
        },
      },
    },
    MuiInputLabel: {
      // Targeting the InputLabel
      styleOverrides: {
        root: {
          fontFamily: 'Poppins, sans-serif',
          fontSize: '20px',
          marginTop: -3,
        },
      },
    },
    MuiOutlinedInput: {
      // Targeting the OutlinedInput
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-notchedOutline': {
            borderRadius: 8,
            borderColor: '#dde2e5',
            borderWidth: '2px',
          },
          '&:hover .MuiOutlinedInput-notchedOutline': {
            borderColor: buttonBlue,
          },
          '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
            borderColor: buttonBlue,
          },
        },
        notchedOutline: {
          '& legend': {
            fontSize: '16.5px', // Adjust as needed
          },
        },
      },
    },
  },
})

export const excludedFilterOptions = [
  'source',
  'item',
  'Verified',
  'Recommended',
  'proj_uuid',
]

export const getThemeLink = (
  theme: string,
  projectId: string,
  isLLMDash: boolean,
) => {
  if (isLLMDash) {
    return `/project/${projectId}/${CATALOG_URL}?category=theme&item=${encodeURIComponent(
      theme?.split(': ')?.[0] ?? theme,
    )}&subtheme=${encodeURIComponent(theme?.split(': ')?.[1] ?? '')}`
  } else {
    return `/project/${projectId}/${CATALOG_URL}?category=theme&item=${encodeURIComponent(
      theme,
    )}`
  }
}
