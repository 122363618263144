import { Typography } from 'antd'
import React from 'react'
import {
  HIGHLIGHT_TAG_ROOT,
  SEARCH_HIGHLIGHT_COLOR,
  TRANSPARENT_COLOR,
} from '../../utils'

type Props = {
  postBody?: string
  color?: string
  highlightTag?: string
  showHighlights?: boolean
}

/**
 * Replace text tags with text highlighting.
 *
 * @param {string|undefined} postBody - Text body of the review.
 * @param {string|undefined} highlightTag - The root of the text tag.
 * @param {string|undefined} color - The color to highlight the found text.
 * @param {boolean|undefined} showHighlights - Show or hide highlight color.
 * @returns {React.FC} - The component that contains the highlighted text.
 */
export const highlightPost = ({
  postBody,
  color = SEARCH_HIGHLIGHT_COLOR,
  highlightTag = HIGHLIGHT_TAG_ROOT,
  showHighlights = true,
}: Props) => {
  if (!postBody) return <></>
  if (!postBody.includes(highlightTag)) return postBody
  // Create a regular expression to find the desired text tag.
  const highlightDelimiter = new RegExp('</*' + highlightTag + '>', 'g')
  return (
    <>
      {postBody.split(highlightDelimiter).map((text_part, i) =>
        // Odd numbers of pieces need to be highlighted (numbering starts from 0).
        i % 2 === 1 ? (
          <Typography.Text>
            {text_part.split(' ').map((word, i) => (
              <span
                style={{
                  backgroundColor: showHighlights ? color : TRANSPARENT_COLOR,
                }}
              >
                {word}
                {i + 1 < text_part.split(' ')?.length ? ' ' : ''}
              </span>
            ))}
          </Typography.Text>
        ) : (
          <Typography.Text>{text_part}</Typography.Text>
        )
      )}
    </>
  )
}
