import React from 'react'
import styled from 'styled-components'
import SupersetSelector from 'components/Superset/components/SupersetSelector/SupersetSelector'
import datasetGraphic from 'assets/images/empty/datasetGraphic.png'

type Props = {
  comparativeIndex: number
}

const Superset: React.FC<Props> = ({ comparativeIndex }) => {
  return (
    <Container>
      <SelectorWrapper>
        <h2 style={{ color: 'white' }}>Choose your Datasets</h2>
        <SupersetSelector comparativeIndex={comparativeIndex} />
      </SelectorWrapper>

      <img
        src={datasetGraphic}
        style={{
          width: '80%',
          maxHeight: '50%',
          objectFit: 'contain',
          marginTop: '50px',
        }}
      />
    </Container>
  )
}

export default Superset

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: 90%;
  margin: 0 auto;
  padding-top: 60px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  //min-height: inherit;
  background: white;
  //background: #c5d7e0;
  overflow-x: auto;
`

const SelectorWrapper = styled.div`
  width: 100%;
  margin: 0 auto 20px;
  max-width: 800px;
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: calc(var(--border-radius) * 2);
  background-color: rgba(243, 134, 105, 0.85);
`
