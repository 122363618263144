import React from 'react'

const AlertsSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="18"
    viewBox="0 0 15 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5.84167 16.0033C5.84167 16.9293 6.58333 17.6786 7.5 17.6786C8.41667 17.6786 9.15833 16.9293 9.15833 16.0033H5.84167ZM13.2333 12.476V7.57653C13.2333 4.84056 11.3583 2.55077 8.825 1.94464V1.33852C8.825 0.597704 8.23333 0 7.5 0C6.76667 0 6.175 0.597704 6.175 1.33852V1.94464C3.64167 2.55077 1.76667 4.84056 1.76667 7.57653V12.476L0 14.2607V15.1531H15V14.2607L13.2333 12.476Z"
      fill="currentColor"
    />
  </svg>
)

export default AlertsSvgIcon
