import React from 'react'
import styled from 'styled-components'

type ServerValidationErrorProps = {
  message: string
}

const ServerValidationError: React.FC<ServerValidationErrorProps> = ({
  message,
}) => {
  return <Wrapper>{message}</Wrapper>
}

const Wrapper = styled.div`
  padding: 8px 16px;

  background: #fff1f0;
  border: 1px solid #ffccc7;
  border-radius: 2px;
  color: #ff0033;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
`

export { ServerValidationError }
