import { initialState, useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import mixpanel from '../../trackers/mixpanel'
import { getBookmarkUrlPost } from '../../url-params/model'
import { getCurrentRouteName } from '../utils'
import { Keyword, Theme } from 'features/project/features/Home/model'

const useReviewCard = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const highlightKeywords = (text: string, keywords: Keyword[]) => {
    // TODO clean the text cause technically someone could leave a review with HTML tags
    let highlightedText = text
    keywords
      .slice(0, 3)
      .map((el) => el.word)
      .forEach((keyword) => {
        const regex = new RegExp(`(\\b${keyword}\\w*)(\\b)`, 'gi')
        highlightedText = highlightedText.replace(
          regex,
          '<span class="keyword"><u>$&</u></span>',
        )
      })
    return highlightedText
  }
  const handleKeywordClick = async (
    keyword: string,
    setShowFetcher: (show: boolean) => void,
  ) => {
    mixpanel.track('expanded reviews', {
      action: 'click',
      type: 'keyword',
      value: keyword,
      proj_uuid: projectId,
    })
    setShowFetcher(true)
    const pageControls = JSON.parse(
      JSON.stringify(initialState.keywordsControls),
    )
    pageControls[0].searchTerm = keyword
    const body = {
      filters: initialState.filters,
      pageControls,
    }
    try {
      const filterHash = await getBookmarkUrlPost(projectId, {
        page: getCurrentRouteName(window.location.pathname),
        filterString: JSON.stringify(body),
      })
      const url = `${window.location.origin}/project/${projectId}/keywords?filters=${filterHash}`
      window.open(url, '')
      setShowFetcher(false)
    } catch (error) {
      setShowFetcher(false)
      console.log(error)
    }
  }

  const handleSectionClick = (type: string, value: string) => {
    mixpanel.track('expanded reviews', {
      action: 'click',
      type,
      value,
      proj_uuid: projectId,
    })
  }

  const handleSentenceClick = (theme: Theme, sentence: string) => {
    mixpanel.track('expanded reviews', {
      action: 'click',
      type: 'sentence',
      value: theme.name,
      text: sentence,
      proj_uuid: projectId,
    })
  }

  return {
    highlightKeywords,
    handleKeywordClick,
    handleSectionClick,
    handleSentenceClick,
  }
}

export default useReviewCard
