import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import mixpanel from 'features/trackers/mixpanel'
import { Loader } from 'shared/components'
import { SavedViewCard } from '../components/saved-view-card'
import {
  CopyOutlined,
  DeleteFilled,
  EditOutlined,
  SearchOutlined,
  ShareAltOutlined,
  UserOutlined,
} from '@ant-design/icons'
import {
  Input,
  Modal,
  Radio,
  RadioChangeEvent,
  Table,
  Form,
  Tooltip,
} from 'antd'
import { SavedView, SavedViewDisplay } from '../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { useQuery } from 'react-query'
import {
  deleteSavedView,
  getSavedViews,
  saveView,
  updateLastRecentlyUsed,
} from '../model'
import { useSavedViews } from '../utils'
import _ from 'lodash'
import { toast } from 'react-toastify'
import { ShareSavedViewModal } from '../components/share-modal'
import { useAuthContext } from 'features/auth'
import { Fetcher } from '../../../../../shared/components/fetcher'
import { EditSavedViewModal } from '../components/edit-modal'
import { PeopleOutline } from '@mui/icons-material'

const options = [
  { label: 'List', value: 'List' },
  { label: 'Grid', value: 'Grid' },
]

const eyeIconStyle = {
  fontSize: '100px',
}
const controlIconStyle = {
  fontSize: '24px',
}
const SavedViews: React.FC = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const [search, setSearch] = useState('')
  const [isList, setIsList] = useState(true)

  const authContext = useAuthContext()
  const { updateFilters } = useSavedViews()

  const onSearch = (e: any) => {
    mixpanel.track('saved view', { action: 'search' })
    setSearch(e.target.value)
  }

  const {
    isLoading,
    data: savedViews,
    refetch,
  } = useQuery(['saved-views', projectId], () => getSavedViews(projectId), {
    refetchOnWindowFocus: false,
  })
  const views = savedViews?.filter((view) => !!view.author)

  const [loading, setLoading] = useState(false)

  // TODO memo
  const transformedViews: SavedViewDisplay[] | undefined = views?.map(
    (view) => ({
      ...view,
      page_display:
        view.page === 'dashboard' ? 'Charts' : _.startCase(view.page),
      create_time_display: new Date(
        view.create_time * 1000,
      ).toLocaleDateString(),
      last_used_display: new Date(
        view.last_time_used * 1000,
      ).toLocaleDateString(),
    }),
  )

  useEffect(() => {
    mixpanel.track('saved views page')
  }, [])

  const [viewType, setViewType] = useState('List')
  const [visible, setVisible] = useState<string | null>(null)
  const [view, setView] = useState<SavedView | undefined>()

  const onViewChange = ({ target: { value } }: RadioChangeEvent) => {
    setViewType(value)
  }

  useEffect(() => {
    refetch()
  }, [])

  if (isLoading || !views) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>{`Loading ${
          details.clientSettings.project.pages?.saved_views?.alias ||
          'Saved Views'
        } Page`}</LoaderTitle>
      </LoadingWrapper>
    )
  }

  if (!views.length) {
    return (
      <LoadingWrapper>
        <LoaderTitle>{`You don't have any saved views`}</LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <Wrapper id="product-container">
      <Header>
        {views.length && (
          <SearchInput
            prefix={<SearchOutlined />}
            size="large"
            allowClear
            placeholder="Search for Saved View"
            value={search}
            onChange={onSearch}
          />
        )}
        {/*<div>*/}
        {/*  <YogiButton onClick={() => setIsList(!isList)}>View Type</YogiButton>*/}
        {/*</div>*/}
        {/*<Radio.Group*/}
        {/*  options={options}*/}
        {/*  onChange={onViewChange}*/}
        {/*  value={viewType}*/}
        {/*  optionType="button"*/}
        {/*  buttonStyle="solid"*/}
        {/*/>*/}
      </Header>
      <Body>
        {/*{viewType === 'List' ? (*/}
        <TableWrapper>
          <Table
            style={{ cursor: 'pointer' }}
            dataSource={
              transformedViews &&
              transformedViews.filter((view: any) => {
                return (
                  view.name.toLowerCase().indexOf(search.toLowerCase()) !== -1
                )
              })
              // .sort((a, b) => a.name.localeCompare(b.name))
            }
            columns={[
              {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                sorter: (a, b) => a.name.localeCompare(b.name),
                render: (_, view) => (
                  <div>
                    {view.name}{' '}
                    {view.is_author && (
                      <Tooltip title={'You own this view'}>
                        <UserOutlined />
                      </Tooltip>
                    )}
                  </div>
                ),
              },
              {
                title: 'Page',
                dataIndex: 'page_display',
                key: 'page_display',
                sorter: (a, b) => a.page_display.localeCompare(b.page_display),
                filters: [
                  {
                    text: 'Charts',
                    value: 'dashboard',
                  },
                  {
                    text: 'Feedback',
                    value: 'feedback',
                  },
                  {
                    text: 'Keywords',
                    value: 'keywords',
                  },
                  {
                    text: 'Network',
                    value: 'network',
                  },
                ],
                // @ts-ignore
                onFilter: (value: string, record) =>
                  record.page.indexOf(value) === 0,
              },
              // {
              //   title: 'Description',
              //   dataIndex: 'view_description',
              //   key: 'view_description',
              // },
              {
                title: 'Last Used',
                dataIndex: 'last_used_display',
                key: 'last_used_display',
                sorter: (a, b) => a.last_time_used - b.last_time_used,
              },
              {
                title: 'Created On',
                dataIndex: 'create_time_display',
                key: 'create_time_display',
                sorter: (a, b) => a.create_time - b.create_time,
              },
              {
                title: 'Actions',
                key: 'action',
                render: (_, view) => (
                  <div
                    style={{
                      display: 'flex',
                      gap: 10,
                    }}
                  >
                    <ActionIcon
                      onClick={(e: React.SyntheticEvent) => {
                        setLoading(true)
                        e.preventDefault()
                        e.stopPropagation()
                        saveView(projectId, {
                          ...view,
                        })
                          .then(() => refetch())
                          .catch(() => {
                            toast.error('Error copying Saved View')
                          })
                          .finally(() => setLoading(false))
                      }}
                    >
                      <CopyOutlined style={controlIconStyle} />
                    </ActionIcon>
                    {view.ownership_type === 'personal' && (
                      <ActionIcon
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setVisible('share')
                          setView(view)
                        }}
                      >
                        <ShareAltOutlined style={controlIconStyle} />
                      </ActionIcon>
                    )}
                    {view.is_author && (
                      <ActionIcon
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault()
                          e.stopPropagation()
                          setVisible('edit')
                          setView(view)
                        }}
                      >
                        <EditOutlined style={controlIconStyle} />
                      </ActionIcon>
                    )}
                    {((view.is_author &&
                      view.ownership_type === 'proj_shared') ||
                      view.ownership_type === 'personal') && (
                      <SavedViewsDeleteIcon
                        onClick={(e: React.SyntheticEvent) => {
                          e.preventDefault()
                          e.stopPropagation()
                          mixpanel.track('saved view', {
                            action: 'delete',
                            value: view.name,
                          })
                          setLoading(true)
                          deleteSavedView(projectId, view.uuid).then(() => {
                            refetch()
                              .then(() => {
                                toast.success('Saved View deleted!')
                              })
                              .catch(() => {
                                toast.error('Error deleting Saved View')
                              })
                              .finally(() => setLoading(false))
                          })
                        }}
                      >
                        <DeleteFilled style={controlIconStyle} />
                      </SavedViewsDeleteIcon>
                    )}
                  </div>
                ),
              },
            ]}
            onRow={(record) => {
              return {
                onClick: (e) => {
                  updateLastRecentlyUsed(projectId, record.uuid)
                  // TODO theres a way to not cause a reload - not sure
                  // TODO also we can probably just link via the saved view name probably probably
                  updateFilters(record as SavedView)
                }, // right button click row
              }
            }}
          />
        </TableWrapper>
        {/*) : (*/}
        {/*  <SavedViewsWrapper>*/}
        {/*    {views*/}
        {/*      .filter((view: SavedView) => {*/}
        {/*        return (*/}
        {/*          view.name.toLowerCase().indexOf(search.toLowerCase()) !== -1*/}
        {/*        )*/}
        {/*      })*/}
        {/*      .sort((a, b) => a.name.localeCompare(b.name))*/}
        {/*      .map((view) => (*/}
        {/*        <SavedViewCard refetchViews={refetch} view={view} />*/}
        {/*      ))}*/}
        {/*  </SavedViewsWrapper>*/}
        {/*)}*/}
        <Modal
          className="share-modal"
          footer={null}
          visible={!!visible}
          title={
            visible === 'share'
              ? 'Share view with dashboard members'
              : 'Edit Saved View'
          }
          onCancel={() => setVisible(null)}
        >
          {view && visible === 'share' && (
            <ShareSavedViewModal projectId={projectId} view={view} />
          )}
          {view && visible === 'edit' && (
            <EditSavedViewModal
              projectId={projectId}
              view={view}
              refetch={refetch}
              setVisible={setVisible}
            />
          )}
        </Modal>
        {loading && (
          <Overlay>
            <Fetcher />
          </Overlay>
        )}
      </Body>
    </Wrapper>
  )
}

export default SavedViews

// export const SavedViewsRoot = () => {
//   return (
//     <FilterPresetProvider>
//       <SavedViews />
//     </FilterPresetProvider>
//   )
// }

const Wrapper = styled.div`
  position: relative;
`

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  margin: 32px 64px;
`

const SearchInput = styled(Input)`
  width: 40%;
`

const Body = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 var(--default-padding);
`
const TableWrapper = styled.div`
  padding: 20px;
  width: 100%;
`

const SavedViewsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
const SavedViewsDeleteIcon = styled.div`
  display: block;
  color: var(--color-grey);

  &:hover {
    ${(props: { custom?: { loading: boolean } }) =>
      !props.custom?.loading && 'color: var(--color-red);'}
  }

  ${(props: { custom?: { loading: boolean } }) =>
    props.custom?.loading && 'animation: spin 1s linear infinite;'}
`

const ActionIcon = styled.div`
  display: block;
  color: var(--color-grey);

  &:hover {
    ${(props: { custom?: { loading: boolean } }) =>
      !props.custom?.loading && 'color: var(--color-blue);'}
  }

  ${(props: { custom?: { loading: boolean } }) =>
    props.custom?.loading && 'animation: spin 1s linear infinite;'}
`
