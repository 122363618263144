import { Filters } from '../../types'
import {
  OUTLIER_TYPE_NAME,
  TIER_NAME_BRAND,
  TIER_NAME_PRODUCT,
  TIER_NAME_SENTIMENT,
  TIER_NAME_THEME,
  TIMESERIES_TYPE_NAME,
} from './constants'
import { OutlierResponse, OutlierType } from './model'
import _ from 'lodash'
import { DashboardItem, DashboardResponse } from '../dashboard/types'

export const createChartItem = (
  chartId: string,
  tier1: string,
  title: string,
  filters: Filters,
  zscore: number,
) => ({
  title: title,
  type: 'chart',
  content: {
    name: 'Rating',
    image: '4_16.png',
    chart_ids: [{ chart_id: chartId, model: null, field: null }],
    tier1,
  },
  dashboardControls: {
    chartId,
    tier1,
    tier2: null,
    tier3: null,
    legend: false,
    fullAxis: true,
    hasLabels: true,
    hasOverlay: false,
    treeView: false,
    origin: true,
    labels: true,
    trendlines: false,
    shadedZones: true,
    sortedByCategory: false,
    isAlignScales: true,
    isAlignOrder: true,
    multiChart: true,
    fontSize: 'small',
    timeInterval: null,
    reviewsCount: 10,
    postType: 'post',
    interval: null,
    isExpandChart: false,
    order: zscore <= 0 ? 'asc' : 'desc',
    pageSize: 10,
    isDelta: false,
    showPercentChange: true,
    wrapLabels: true,
    maxAxesLength: 30,
    hideXAxis: true,
    hideYAxis: true,
    expandedCategories: [
      'My Products',
      'Competitor Products',
      'My Brands',
      'Competitor Brands',
    ],
  },
  filterValues: filters,
})

function getTimePeriodLabel(start: number, end: number): string {
  const diffInSeconds = end - start
  const diffInDays = diffInSeconds / (60 * 60 * 24)

  if (diffInDays >= 32) {
    return 'Quarter'
  } else if (diffInDays >= 8) {
    return 'Month'
  } else {
    return 'Week'
  }
}

export const getOutlierTitle = (outlier: OutlierType) => {
  if (!outlier) return ''
  if (outlier.analysis?.stinger) {
    return outlier.analysis?.stinger
  }
  if (outlier.type === 'outlier') {
    return `${outlier.context_with_value?.value} is ${
      outlier.weighted_zscore <= 0 ? 'underperforming' : 'overperforming'
    } other ${_.startCase(
      outlier.context_with_value?.context,
    )}s in the theme of ${outlier.theme}`
  } else if (outlier.type === 'timeseries') {
    return `${
      outlier.context_with_value?.value
    } has had a significant change in sentiment in the ${getTimePeriodLabel(
      outlier.date_range?.start_date,
      outlier.date_range.end_date,
    )} following ${new Date(
      outlier.window_start_date ?? '',
    ).toLocaleDateString()}`
  }
  return ''
}

// combines the list of outliers and timeseries and sorts them
export const constructOutliersList = (
  outlierResponse: OutlierResponse[] | undefined,
  timeSeriesOutlierResponse: OutlierResponse[] | undefined,
  customDashboardsResponse: DashboardResponse[] | undefined,
  activeTab: number,
  minVolume: number,
  pageSize: number,
  page: number,
  search: string,
): OutlierType[] => {
  if (
    !outlierResponse ||
    !timeSeriesOutlierResponse ||
    !customDashboardsResponse
  )
    return []
  const outliers = (outlierResponse ?? []).map((item) => ({
    ...item,
    type: OUTLIER_TYPE_NAME,
  }))
  const timeseriesOutliers = (timeSeriesOutlierResponse ?? []).map((item) => ({
    ...item,
    type: TIMESERIES_TYPE_NAME,
  }))

  const allOutliers: OutlierResponse[] = [...outliers, ...timeseriesOutliers]

  const activeOutliers = () => {
    if (activeTab === 0) {
      return allOutliers.sort(
        (a, b) => Math.abs(b.weighted_zscore) - Math.abs(a.weighted_zscore),
      )
    } else if (activeTab === 1) {
      // return outliers.filter(item => item.isRecent)
      return allOutliers.sort((a, b) => {
        if (a.date && b.date) {
          return new Date(b.date).getTime() - new Date(a.date).getTime()
        } else {
          return 0
        }
      })
    } else if (activeTab === 2) {
      // return outliers.filter(item => item.hasMostReviews)
      return allOutliers.sort((a, b) => b.volume - a.volume)
    } else {
      return (
        customDashboardsResponse
          ?.filter(
            (item) =>
              item.dashboard.dashboard_settings.extra_settings?.outlier &&
              item.dashboard.dashboard_settings.extra_settings?.is_active,
          )
          .map(
            (outlier) =>
              outlier.dashboard.dashboard_settings.extra_settings.outlier,
          ) ?? []
      )
    }
  }
  // we pull the next page into the list to allow for preloading
  if (search) {
    return activeOutliers()
      .filter((outlier) => {
        return (
          outlier.analysis?.stinger
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          outlier.context_with_value?.value
            .toLowerCase()
            .includes(search.toLowerCase()) ||
          outlier.theme?.toLowerCase().includes(search.toLowerCase())
        )
      })
      .filter((outlier) => outlier.metric === 'sentiment')
      .slice(0, page * pageSize)
  }
  return activeOutliers()
    .filter((outlier) => outlier.metric === 'sentiment')
    .slice(0, page * pageSize)
}

// configures the charts to be displayed for a given outlier
export const getOutlierCharts = (
  outlier: OutlierType,
  activeFilters: Filters,
  themeFilters: Filters,
) => {
  if (outlier.type === OUTLIER_TYPE_NAME) {
    if (outlier.context_with_value?.context === 'product') {
      return [
        {
          chartItem: createChartItem(
            '5_5_17_sentiment',
            TIER_NAME_PRODUCT,
            'Product Sentiment Ranking',
            themeFilters,
            outlier.weighted_zscore,
          ),
          width: '33%',
        },
        {
          chartItem: createChartItem(
            '3_4_21_sentiment',
            TIER_NAME_SENTIMENT,
            `${outlier.context_with_value.value} - ${outlier.theme} Sentiment and Volume over Time`,
            activeFilters,
            outlier.weighted_zscore,
          ),
          width: '50%',
        },
      ]
    } else if (outlier.context_with_value?.context === 'brand') {
      return [
        {
          chartItem: createChartItem(
            '5_5_17_sentiment',
            TIER_NAME_BRAND,
            'Brand Sentiment Ranking',
            themeFilters,
            outlier.weighted_zscore,
          ),
          width: '33%',
        },
        {
          chartItem: createChartItem(
            '5_4_17_sentiment',
            TIER_NAME_BRAND,
            `${outlier.context_with_value.value} - ${outlier.theme} Sentiment and Volume over Time`,
            activeFilters,
            outlier.weighted_zscore,
          ),
          width: '50%',
        },
      ]
    } else {
      return []
    }
  } else if (outlier.type === TIMESERIES_TYPE_NAME) {
    return [
      {
        chartItem: createChartItem(
          '5_5_17_sentiment',
          TIER_NAME_THEME,
          'Theme Ranking',
          themeFilters,
          outlier.weighted_zscore,
        ),
        width: '40%',
      },

      {
        chartItem: createChartItem(
          '5_5_17_sentiment',
          TIER_NAME_PRODUCT,
          'Product Ranking',
          themeFilters,
          outlier.weighted_zscore,
        ),
        width: '40%',
      },
      {
        chartItem: createChartItem(
          '3_4_21_sentiment',
          TIER_NAME_SENTIMENT,
          'Sentiment and Volume over Time',
          activeFilters,
          outlier.weighted_zscore,
        ),
        width: '60%',
      },
    ]
  } else {
    return []
  }
}

// applies outlier filters to the filter values
export const getOutlierFilterValues = (
  outlier: OutlierType,
  filterValues: Filters,
  getClusterUUIDsFromThemeName: (theme: string, subtheme: string) => string[],
): Filters => {
  const tempFilters = _.cloneDeep(filterValues)
  // tempFilters.values = filterValues.values.filter(
  //   (filter) => !['product_hierarchy', 'theme'].includes(filter.field),
  // )

  // tempFilters.values.push({ field: 'score', min: 0, max: 5, value_type: 1 })

  // if (outlier.context_with_value?.context === 'product') {
  //   tempFilters.values.push({
  //     field: 'product_hierarchy',
  //     values: [outlier.context_with_value.value],
  //   })
  // } else if (outlier.context_with_value?.context === 'brand') {
  //   tempFilters.values.push({
  //     field: 'brand',
  //     values: [outlier.context_with_value.value],
  //   })
  // }

  // if(outlier.brand){
  //   tempFilters.values.push(
  //     { field: 'brand', values: [outlier.brand] }
  //   )
  // }

  // if (outlier.theme) {
  //   tempFilters.values.push({
  //     field: 'theme',
  //     values: getClusterUUIDsFromThemeName(
  //       outlier.theme.split(': ')[0],
  //       outlier.theme.split(': ')[1],
  //     ),
  //   })
  // }

  return tempFilters
}

// Applies just the theme filter from an outlier to the filter values
// used for rendering the product comparison chart
export const getOutlierThemeFilterValues = (
  outlier: OutlierType,
  filterValues: Filters,
  getClusterUUIDsFromThemeName: (theme: string, subtheme: string) => string[],
): Filters => {
  const tempFilters = _.cloneDeep(filterValues)
  if (outlier.context_with_value?.context === 'product') {
    tempFilters.values = filterValues.values.filter(
      (filter) => !['product_hierarchy'].includes(filter.field),
    )
  } else if (outlier.context_with_value?.context === 'brand') {
    tempFilters.values = filterValues.values.filter(
      (filter) => !['brand'].includes(filter.field),
    )
  }

  // if (outlier.theme) {
  //   tempFilters.values.push(
  //     {
  //       field: 'theme',
  //       values: getClusterUUIDsFromThemeName(
  //         outlier.theme.split(': ')[0],
  //         outlier.theme.split(': ')[1],
  //       ),
  //     },
  //     // { field: 'score', min: 0, max: 5, value_type: 1 },
  //   )
  // }

  return tempFilters
}

export const createChartItemFromOutlierChart = (
  chartItem: any,
  index: number,
): DashboardItem => {
  return {
    type: 'chart',
    title: chartItem.title,
    layout: {
      h: 9,
      w: 12,
      minH: 4,
      minW: 4,
      i: index.toString(),
      x: 0,
      y: index * 5,
    },
    content: chartItem.content,
    dashboardControls: {
      ...chartItem.dashboardControls,
    },
    filterValues: {
      ...chartItem.filterValues,
    },
  }
}
export function constructThemeOutlierPrompt(outlier: OutlierType) {
  const satisfaction =
    outlier.weighted_zscore <= 0 ? 'dissatisfaction' : 'satisfaction'
  const sentimentType = outlier.weighted_zscore <= 0 ? 'negative' : 'positive'

  return `What are the main causes of user ${satisfaction} in the given reviews for the theme of "${
    outlier.theme
  }"? Think step by step, be specific, and give examples to support your claims.

Note that the selection of reviews has been identified as a set of outliers for a specific product, and is not exhaustive of all reviews for the product.

The specific "${outlier.context_with_value?.context}" being analyzed is "${
    outlier.context_with_value?.value
  }"
ONLY DISCUSS your analysis on the specific theme of "${outlier.theme}"
ONLY FOCUS ON user ${satisfaction} in the reviews

Emphasize specific explanations for why this set of reviews may be a ${sentimentType} outlier, and what the implications are for the product. Broad analyses are not helpful.

Present these specific explanations in a clear and concise manner, and separate them into their own sections.

Format your findings as JSON with the following fields:
{
  "stinger": str (a catchy and informative news headline in the form of a statement that cleverly or playfully signals the main theme(s) of the analysis. Not a question or exclamation.)
  "header": str (a concise attention capturing title of the main causes of user ${satisfaction} in the reviews)
  "subheader": str (a short attention capturing introduction to the analysis and setting the context for the findings)
  "key_phrases": List[str] (key phrases or themes that emerged from the reviews that would be useful for a vector search on the set of reviews. These terms must be words or phrases mentioned in the "subheading" section, so they can be identified and modified in the UI, and must be specific to the outlier in question.)
  "body": List[Finding] (a list of findings, each representing a specific cause of user ${satisfaction})
  "footer": str (optional: additional insights or recommendations)
}
Where the Finding object has the following structure:
{
  "title": str (a concise title for the finding)
  "explanation": str (a clear explanation of the issue)
  "evidence": str (this must be a verbatim quote from the supplied set of reviews)
  "implication": str (the potential impact of the issue on the product)
}

Here is an example of a full response:
        
{ 
  "stinger": "Size Concerns: Le Creuset Signature Square Skillet Grill Not Measuring Up to Competitors", 
  "header": "Main Causes of User Dissatisfaction in 'Le Creuset Signature Square Skillet Grill' Reviews Regarding Size",
  "subheader": "Reviewers weigh in on the Le Creuset Signature Square Skillet Grill's size and performance. Key points include size limitations for larger meals, comparisons to competing brands, and practical considerations for everyday cooking.",
  "key_phrases": ["size", "larger meals", "practical considerations", "everyday cooking"],
  "body": [
    {
      "title": "Size Inconsistency and Unrealistic Expectations",
      "explanation": "Several reviewers express dissatisfaction with the size of the pan, stating it is too small for their needs. They mention it can only hold a limited number of items, such as two burgers or chicken breasts.",
      "evidence": "Plus, this pan is pretty small so barely holds 2 burgers or 2 chicken breasts which is ok since there are only two of us.",
      "implication": "This highlights a potential mismatch between the product's size and the expectations of some users, especially those who need to cook for larger families or groups.",
    },
    {
      "title": "Comparison with Other Products and Brands,
      "explanation": "Reviewers compare the Le Creuset Signature Square Skillet Grill to other brands and products, finding the Le Creuset to be disappointing in terms of size and performance.",
      "evidence": "I also have an off-brand non-stick grill pan that can't be used with higher than medium heat which is why I wanted this one for searing and good grill marks. The off-brand cleans up far nicer than this one. If you like to spend time cleaning pans, buy this one.",
      "implication": "This indicates that the product might not be meeting the same performance expectations as its competitors, potentially leading to dissatisfaction among users who are already familiar with alternative products.",
    },
    {
      "title": "Limited Use Cases and Practical Considerations",
      "explanation": "Some users note that the size limits the practicality of the pan. They mention that the small size makes it difficult to cook larger portions or multiple items at once.",
      "evidence": "This pan is small and will only be able to put one patty at a time in the pan'",
      "implication": "This implies that the Le Creuset Signature Square Skillet Grill, while suitable for smaller meals, may not be a practical choice for larger families or those who frequently cook for groups.",
    }
  ],
  "footer": "Overall, the user dissatisfaction centered around the size of the Le Creuset Signature Square Skillet Grill can be attributed to its small size, which doesn't meet the expectations of some users. This could potentially be addressed by introducing a larger size option or providing clearer size specifications and guidelines to manage user expectations."}

This JSON MUST be immediately consumable by a python api endpoint.

The JSON must not be wrapped in ${'```json'} or any other code block. It must not have a new line character preceding the first key. It must be a plain JSON object, immediately parseable by the javascript JSON.parse() function.
  `.trim()
}
