import { useQuery } from 'react-query'
import { fetchProjectsByGroup } from '../../home/model'

export const useGroupProjectList = (projectId: string) => {
  return useQuery(
    ['group-project-list', projectId],
    () => fetchProjectsByGroup(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      enabled: !!projectId,
    }
  )
}
