import { isEqual, cloneDeep } from 'lodash'
import {
  FilterOption,
  AppliedFilterOption,
  FilterOptionSelect,
  AppliedFilterOptionSelect,
} from 'features/project/features/filters/types'
import {
  HardcodedFilterOptionsEnum,
  selectedFiltersCountByComparativeIndex,
} from 'features/project/features/filters/helpers'
import { THEME_DOMAIN } from 'features/project/features/network/features/cluster/utils'

export function transformFilterOptions(
  options: Array<FilterOption>
): Array<FilterOption> {
  return filterUnnesessaryOptions(options)
}

export function mergeThemeAndThemeGroupOptions(
  options: Array<FilterOptionSelect>
) {
  const clonedOptions = cloneDeep(options)
  const theme = clonedOptions.find((o) => o.field === THEME_DOMAIN)
  const themeGroup = clonedOptions.find(
    (o) => o.field === HardcodedFilterOptionsEnum.THEME_GROUP
  )

  if (!(themeGroup && theme) || !themeGroup.values || !theme.values) {
    return clonedOptions
  }
  theme.values = [...theme.values, ...themeGroup.values]

  return clonedOptions.filter(
    (o) => o.field !== HardcodedFilterOptionsEnum.THEME_GROUP
  )
}

export function filterUnnesessaryOptions<
  T extends FilterOption | AppliedFilterOption
>(options: Array<T>): Array<T> {
  const fieldsToSkip: string[] = Object.values(HardcodedFilterOptionsEnum)
  return options.filter((option) => !fieldsToSkip.includes(option.field))
}

export function getAppliedFilterOptionsInfo(
  firstList: Array<AppliedFilterOption>,
  secondList: Array<AppliedFilterOption>
) {
  const firstListCount = selectedFiltersCountByComparativeIndex(firstList)
  const secondListCount = selectedFiltersCountByComparativeIndex(secondList)
  return {
    isEqual: isEqual(firstList, secondList),
    count: firstListCount + secondListCount,
  }
}

export function isFilterOptionApplied(
  filterValues: Array<AppliedFilterOption>,
  option: FilterOption
) {
  return !!filterValues.find((value) => value.field === option.field)
}

export const applyEveryFilterValue = (
  option: FilterOption,
  appliedFilters: Array<AppliedFilterOption>,
  search: string
): Array<AppliedFilterOption> | undefined => {
  if (!('values' in option)) return

  const alreadyAppliedFilters = appliedFilters.filter(
    ({ field }) => field !== option.field
  )

  return [
    ...alreadyAppliedFilters,
    {
      field: option.field,
      values: option.values
        .filter((item) =>
          item.value.toLowerCase().includes(search.toLowerCase())
        )
        .map(({ cluster_uuid, value }) => cluster_uuid || value),
    },
  ]
}
