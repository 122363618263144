import React, { useEffect, useMemo, useState } from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { ProjectState } from '../../../../types'
import { getPostSample, ReviewCardType } from '../../model'
import { Loader } from 'shared/components'
import { FilterOptionSelect } from '../../../filters/types'
import { YogiButton } from 'components/UI/YogiButton'
import mixpanel from '../../../../../trackers/mixpanel'
import ReviewCard from './components/ReviewCard'
import {
  lightBlueFontColor,
  secondaryButtonBlue,
} from 'assets/styles/variables'

type Props = {
  dataset: string | undefined
}

const ReviewComponent: React.FC<Props> = ({ dataset }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const activeDataset =
    dataset ??
    (
      defaultFilterList?.find(
        (el) => el.field === 'proj_uuid',
      ) as FilterOptionSelect
    )?.values?.[0]?.cluster_uuid

  const [reviewIndex, setReviewIndex] = useState<number>(0)

  useEffect(() => {
    setReviewIndex(0)
  }, [activeDataset])

  const { data, isLoading } = useQuery(
    ['post-sample', projectId, activeDataset],
    () => {
      return getPostSample(projectId, activeDataset)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const dedupedData = useMemo(() => {
    if (!data) return []
    const seenSentences = new Set<string>()
    const dedupedObjects: ReviewCardType[] = []

    for (const obj of data) {
      // Create a sorted array of sentence texts and convert it to a unique key
      const sortedSentencesKey = obj.sentences
        .map((el) => el.text)
        .sort()
        .join('')

      if (!seenSentences.has(sortedSentencesKey)) {
        seenSentences.add(sortedSentencesKey)
        dedupedObjects.push(obj)
      }
    }

    return dedupedObjects
  }, [data])

  return (
    <Container>
      <Header>
        <Title>Featured Reviews</Title>
        <Buttons>
          <YogiButton
            disabled={reviewIndex === 0}
            onClick={() => {
              mixpanel.track('expanded reviews', {
                action: 'previous',
                proj_uuid: projectId,
              })
              setReviewIndex(reviewIndex - 1)
            }}
            style={{
              // background: secondaryButtonBlue,
              // color: lightBlueFontColor,
              // borderColor: secondaryButtonBlue,
              padding: '5px 20px',
              height: 48,
            }}
          >
            Previous Review
          </YogiButton>

          <YogiButton
            type={'primary'}
            disabled={reviewIndex === dedupedData.length - 1}
            onClick={() => {
              mixpanel.track('expanded reviews', {
                action: 'next',
                proj_uuid: projectId,
              })
              setReviewIndex(reviewIndex + 1)
            }}
            style={{
              background: lightBlueFontColor,
              color: 'white',
              padding: '5px 20px',
              height: 48,
            }}
          >
            Next Review
          </YogiButton>
        </Buttons>
      </Header>
      {isLoading ? (
        <LoaderWrapper>
          <Loader />
        </LoaderWrapper>
      ) : (
        <div>
          {dedupedData && dedupedData.length > 0 ? (
            <ReviewCardWrapper>
              {dedupedData[reviewIndex] && (
                <ReviewCard key={reviewIndex} {...dedupedData[reviewIndex]} />
              )}
            </ReviewCardWrapper>
          ) : (
            <div>No data</div>
          )}
        </div>
      )}
    </Container>
  )
}

export default ReviewComponent

const Container = styled.div`
  padding: 10px 20px;
  width: 100%;
  max-width: 1400px;
  background: var(--card-background-white);
  margin-top: 40px;
`

const Header = styled.div`
  font-size: 20px;

  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  width: 100%;
  margin: 0 auto 5px;
`

const LoaderWrapper = styled.div`
  padding-top: 0;
  padding-bottom: 30px;
  display: flex;
  justify-content: center;
`

const ReviewCardWrapper = styled.div`
  display: flex;
  justify-content: center;
  gap: 20px;
  margin: 0 auto;
`
const Buttons = styled.div`
  display: flex;
  gap: 13px;
`

const Title = styled.div`
  color: ${lightBlueFontColor};
  font-weight: 300;
`
