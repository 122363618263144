import { Select } from 'antd'
import mixpanel from 'features/trackers/mixpanel'
import datasetGraphic from 'assets/images/empty/datasetGraphic.png'
import React, { useMemo } from 'react'
import {
  AppliedFilterOptionSelect,
  FilterOptionSelect,
} from 'features/project/features/filters/types'
import styled from 'styled-components'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import { datasetSelectorBackground } from 'assets/styles/variables'
import { DatasetSelector } from 'features/project/features/Home/components/DatasetSelector/DatasetSelector'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import ChartsSvgIcon from 'features/project/features/Charts/components/DatasetSelector/ChartsSvgIcon/ChartsSvgIcon'

type Props = {
  comparativeIndex: number
  filterValues: Filters
}

export const DatasetSelectorView: React.FC<Props> = ({
  comparativeIndex,
  filterValues,
}) => {
  const updateFilterValues = useProjectStore(
    (state: ProjectState) => state.updateFilterValues,
  )

  const selectedDataset = useMemo(
    () =>
      (
        filterValues?.values.find(
          (el) => el.field === 'proj_uuid',
        ) as AppliedFilterOptionSelect
      )?.values[0],
    [filterValues?.values],
  )

  const setDataset = (value: string) => {
    updateFilterValues(comparativeIndex, 'proj_uuid', [value])
    mixpanel.track('select dataset', {
      dataset: value,
    })
  }

  return (
    <Container>
      <DatasetWrapper>
        <GenericSvgIcon SvgComponent={ChartsSvgIcon} />
        <DatasetSelector
          dataset={undefined}
          setDataset={setDataset}
          defaultEmpty
          comparativeIndex={comparativeIndex}
        />
      </DatasetWrapper>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  width: 100%;
  padding: 10px;
`

const DatasetWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-top: 10%;
  //margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  box-sizing: border-box;
  border-radius: var(--border-radius);
  gap: 40px;
  //min-height: inherit;
  //background: white;
  background: ${datasetSelectorBackground};
  overflow-x: auto;
`

const SelectorWrapper = styled.div`
  padding: 10px 20px;
  display: flex;
  flex-direction: column;
  //gap: 20px;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  border-radius: calc(var(--border-radius) * 2);
  background-color: rgba(243, 134, 105, 0.85);
`
