import React, { useEffect, useMemo, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { Badge, Button, Select, Spin, Table, Tooltip } from 'antd'
import { FilterOptionSelect } from '../../../../filters/types'
import { YogiButton } from '../../../../../../../components/UI/YogiButton'
import mixpanel from '../../../../../../trackers/mixpanel'
import { generateDashboard, getImpactfulThemes } from '../../../model'
import { toast } from 'react-toastify'
import {
  mainText,
  primary,
  secondaryRed,
  tertiaryGreen,
} from '../../../../../../../assets/styles/variables'
import { TableChart } from 'features/project/features/Charts/types'
import smartDashboard from 'assets/images/graphics/smartDashboard.png'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { DashboardResponse } from '../../../types'
import { numberFormatter } from '../../../../../../../utils/numberFormat'
import { FallOutlined, RiseOutlined } from '@ant-design/icons'

type Props = {
  selectedDashboard: DashboardResponse | undefined
  setSelectedDashboard: (
    selectedDashboard: DashboardResponse | undefined,
  ) => void
  category: string
  setCategory: (category: string) => void
  value: string
  setValue: (value: string) => void
  themes: string[]
  setThemes: (themes: string[]) => void
  selectedTheme: string
  setSelectedTheme: (themes: string) => void
}
const DashboardBuilderHeader: React.FC<Props> = ({
  selectedDashboard,
  setSelectedDashboard,
  category,
  setCategory,
  value,
  setValue,
  themes,
  setThemes,
  selectedTheme,
  setSelectedTheme,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const [viewTable, setViewTable] = useState(true)

  const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([])

  const [localCategory, setLocalCategory] = useState<string>('brand')

  const values = useMemo(
    () =>
      (
        defaultFilterList.find(
          (el) => el.field === localCategory,
        ) as FilterOptionSelect
      )?.values?.map((el) => ({ label: el.value, value: el.value })),
    [localCategory, defaultFilterList],
  )
  const [localValue, setLocalValue] = useState<string | undefined>()

  useEffect(() => {
    setLocalValue(undefined)
    setSelectedRowKeys([])
    if (localCategory === 'brand') {
      setViewTable(true)
    }
  }, [localCategory])

  useEffect(() => {
    setSelectedRowKeys([])
  }, [value])

  useEffect(() => {
    if (selectedDashboard) {
      setViewTable(false)
    }
  }, [selectedDashboard])

  const { data: importantThemes, isLoading } = useQuery(
    ['important-themes', projectId, localValue],
    () => {
      if (!projectId || !localValue) return Promise.resolve(undefined)
      return getImpactfulThemes(projectId, localValue)
    },
    {
      refetchOnWindowFocus: false,
      enabled: !!projectId && category === 'brand',
    },
  )

  const tableData = importantThemes?.charts[0] as TableChart
  const columns: any[] = []
  tableData?.col_titles.forEach((title, index) => {
    const key = tableData.col_keys[index]
    columns.push({
      title: title,
      dataIndex: key,
      key: key,
      sorter:
        index === 0
          ? (a: any, b: any) => a[key].localeCompare(b[key])
          : (a: any, b: any) => a[key] - b[key],
      sortDirections: ['descend', 'ascend', 'descend'],
      minWidth: index === 0 ? 400 : 100,
      render: (value: any, row: any) =>
        index === 0 ? (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <div
              style={{
                minWidth: '30px',
                height: '30px',
                backgroundColor: row.color,
                marginRight: '10px',
                borderRadius: 10,
              }}
            ></div>
            <div>{value}</div>
          </div>
        ) : (
          numberFormatter(value)
        ),
    })
  })
  columns.splice(1, 0, {
    title: 'Impact',
    dataIndex: 'impact_score',
    key: 'impact_score',
    sorter: (a: any, b: any) => a['impact_score'] - b['impact_score'],
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value: any) => numberFormatter(value),
  })
  columns.splice(3, 0, {
    title: 'Volume vs Year Prior',
    dataIndex: 'year_prior_volume_diff',
    key: 'year_prior_volume_diff',
    sorter: (a: any, b: any) =>
      a['year_prior_volume_diff'] - b['year_prior_volume_diff'],
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value: any) => (
      <Tooltip title={<div>{`Compared to previous year`}</div>}>
        {value !== 'No Value' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <div
              style={{
                fontSize: '14px',
                // lineHeight: '1.1',
                fontWeight: '400',
                color:
                  value === 0
                    ? mainText
                    : value > 0
                      ? tertiaryGreen
                      : secondaryRed,
              }}
            >
              <>
                {value >= 0 && '+'}
                {numberFormatter(value)}%{/*{key !== 'sentiment' && '%'}*/}
              </>
            </div>
            <div style={{ marginLeft: -5 }}>
              {value === 0 ? (
                '-'
              ) : value > 0 ? (
                <RiseOutlined style={{ color: tertiaryGreen }} />
              ) : (
                <FallOutlined style={{ color: secondaryRed }} />
              )}
            </div>
          </div>
        )}
      </Tooltip>
    ),
  })
  columns.splice(3, 0, {
    title: 'Volume vs Market',
    dataIndex: 'market_volume_diff',
    key: 'market_volume_diff',
    sorter: (a: any, b: any) =>
      a['market_volume_diff'] - b['market_volume_diff'],
    sortDirections: ['descend', 'ascend', 'descend'],
    render: (value: any) => (
      <Tooltip title={<div>{`Compared to market average`}</div>}>
        {value !== 'No Value' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 10,
            }}
          >
            <div
              style={{
                fontSize: '14px',
                // lineHeight: '1.1',
                fontWeight: '400',
                color:
                  value === 0
                    ? mainText
                    : value > 0
                      ? tertiaryGreen
                      : secondaryRed,
              }}
            >
              <>
                {value >= 0 && '+'}
                {numberFormatter(value)}%
              </>
            </div>
            <div style={{ marginLeft: -5 }}>
              {value === 0 ? (
                '-'
              ) : value > 0 ? (
                <RiseOutlined style={{ color: tertiaryGreen }} />
              ) : (
                <FallOutlined style={{ color: secondaryRed }} />
              )}
            </div>
          </div>
        )}
      </Tooltip>
    ),
  })

  const onSelectChange = (selectedRowKeys: React.Key[]) => {
    setSelectedRowKeys(selectedRowKeys as string[])
  }

  const rowSelection = {
    type: 'checkbox',
    selectedRowKeys: selectedRowKeys,
    onChange: onSelectChange,
    // @ts-ignore
    renderCell: (checked, record, index, originNode) => {
      // Disable the checkbox in the header (index === -1)
      if (index === -1) {
        return null
      }
      // For other rows, render the original checkbox
      return originNode
    },
  }

  return (
    <Wrapper style={{ width: '100%' }}>
      {/*<Badge count={'Beta'}>*/}
      <Container>
        <Row>
          <h3>Automated Dashboard</h3>
        </Row>
        <Row>
          <Select
            value={localCategory}
            onChange={(v) => {
              mixpanel.track('automated dashboard', {
                action: 'category',
                value: v,
              })
              setLocalCategory(v)
            }}
          >
            <Select.Option value={'brand'}>Brand</Select.Option>
            <Select.Option value={'product_hierarchy'}>Product</Select.Option>
          </Select>
          <Select
            showSearch
            value={localValue ?? undefined}
            style={{ width: '100%' }}
            onChange={(v) => {
              mixpanel.track('automated dashboard', {
                action: 'item',
                value: v,
              })
              setLocalValue(v)
            }}
            placeholder={`Search to Select ${
              localCategory === 'brand' ? 'Brand' : 'Product'
            }`}
            optionFilterProp="children"
            filterOption={(input, option) =>
              ((option?.value as string) ?? '')
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            filterSort={(optionA, optionB) =>
              ((optionA?.value as string) ?? '')
                .toLowerCase()
                .localeCompare(((optionB?.value as string) ?? '').toLowerCase())
            }
          >
            {values?.map((option) => (
              <Select.Option
                value={option.value}
                key={option.value}
                selected={option.value === value}
              >
                {option.label}
              </Select.Option>
            ))}
          </Select>
          {/*{selectedDashboard && (*/}
          {/*<Select*/}
          {/*  showSearch*/}
          {/*  style={{ width: '100%' }}*/}
          {/*  value={selectedRowKey as string}*/}
          {/*  onChange={(v) => {*/}
          {/*    mixpanel.track('automated dashboard', {*/}
          {/*      action: 'theme',*/}
          {/*      value: v,*/}
          {/*    })*/}
          {/*    if()*/}
          {/*    setSelectedRowKeys()*/}
          {/*  }}*/}
          {/*  placeholder={*/}
          {/*    !value*/}
          {/*      ? 'Select Brand/Product First'*/}
          {/*      : isLoading*/}
          {/*      ? 'Fetching Most Important Themes'*/}
          {/*      : 'Search to Select Theme'*/}
          {/*  }*/}
          {/*  optionFilterProp="children"*/}
          {/*  filterOption={(input, option) =>*/}
          {/*    ((option?.label as string) ?? '')*/}
          {/*      .toLowerCase()*/}
          {/*      .includes(input.toLowerCase())*/}
          {/*  }*/}
          {/*  filterSort={(optionA, optionB) =>*/}
          {/*    ((optionA?.label as string) ?? '')*/}
          {/*      .toLowerCase()*/}
          {/*      .localeCompare(((optionB?.label as string) ?? '').toLowerCase())*/}
          {/*  }*/}
          {/*  disabled={!value}*/}
          {/*  notFoundContent={isLoading ? <Spin size="small" /> : null}*/}
          {/*  loading={isLoading}*/}
          {/*>*/}
          {/*  {selectedThemes?.map((option: any) => (*/}
          {/*    <Select.Option*/}
          {/*      value={option.value}*/}
          {/*      key={option.value}*/}
          {/*      selected={option.value === value}*/}
          {/*    >*/}
          {/*      {option.label}*/}
          {/*    </Select.Option>*/}
          {/*  ))}*/}
          {/*</Select>*/}
          {/*)}*/}
          <YogiButton
            onClick={() => {
              setViewTable(!viewTable)
              mixpanel.track('automated dashboard', {
                action: 'view table',
                value: !viewTable,
              })
            }}
          >
            {viewTable ? 'Hide' : 'View'} Table
          </YogiButton>
          <YogiButton
            type={'primary'}
            // disabled={!value || !selectedRowKey || generatingDashboard}
            disabled={
              !localValue ||
              (localCategory === 'brand' && !selectedRowKeys.length) ||
              (localCategory === 'product_hierarchy' && value === localValue)
            }
            // loading={generatingDashboard}
            onClick={async () => {
              mixpanel.track('automated dashboard', {
                action: 'generate',
                category: localCategory,
                value: localValue,
                themes: selectedRowKeys,
              })
              // setGenerating(true)
              setViewTable(false)
              setCategory(localCategory)
              setValue(localValue!)
              setThemes(selectedRowKeys)
            }}
          >
            {/*{generatingDashboard ? 'Generating' : 'Generate'}*/}
            Generate
          </YogiButton>
        </Row>
        {localCategory === 'brand' && viewTable && (
          <Row>
            {!importantThemes && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  height: 600,
                }}
              >
                {isLoading ? (
                  <LogoSpinner
                    style={{ paddingBottom: 60 }}
                  >{`Calculating Theme Impact...`}</LogoSpinner>
                ) : (
                  <img
                    src={smartDashboard}
                    style={{
                      objectFit: 'contain',
                      width: '100%',
                      maxWidth: 500,
                      maxHeight: '100%',
                      margin: '0 auto',
                      marginTop: -50,
                    }}
                  />
                )}
              </div>
            )}
            {importantThemes && (
              <StyledTable
                id="table-chart"
                dataSource={tableData?.values ?? []}
                style={{
                  width: '100%',
                  overflow: 'auto',
                }}
                sortDirections={['descend', 'ascend', 'descend']}
                // @ts-ignore
                rowSelection={rowSelection}
                columns={columns}
                //@ts-ignore
                rowKey={(row) => row.category}
                onRow={(record: any) => {
                  return {
                    onClick: (e) => {
                      if (selectedRowKeys.includes(record.category)) {
                        setSelectedRowKeys(
                          selectedRowKeys.filter(
                            (el) => el !== record.category,
                          ),
                        )
                      } else {
                        setSelectedRowKeys([
                          ...selectedRowKeys,
                          record.category,
                        ])
                      }
                    }, // right button click row
                  }
                }}
                // scroll={{ y: 800 }}
              />
            )}
          </Row>
        )}
      </Container>

      {!!themes.length && (
        <ButtonWrapper>
          <YogiButton
            type={'primary'}
            disabled={themes.indexOf(selectedTheme) === 0}
            onClick={() => {
              setSelectedTheme(themes[themes.indexOf(selectedTheme) - 1])
            }}
          >
            ^
          </YogiButton>
          <YogiButton
            type={'primary'}
            disabled={themes.indexOf(selectedTheme) === themes.length - 1}
            onClick={() => {
              setSelectedTheme(themes[themes.indexOf(selectedTheme) + 1])
            }}
          >
            v
          </YogiButton>
        </ButtonWrapper>
      )}
      {/*</Badge>*/}
    </Wrapper>
  )
}

export default DashboardBuilderHeader

const Wrapper = styled.div`
  width: 100%;
  padding: 12px 20px 10px 10px;
  background: #f0f2f5;
  display: flex;
  align-items: start;
  gap: 10px;
  span.ant-badge {
    width: 100%;
    //height: 100%;
    display: flex;
    justify-content: center;
  }
`

const Container = styled.div`
  width: 100%;
  //max-width: 1000px;
  display: flex;
  flex-direction: column;
  gap: 5px;
  background: white;
  padding: 10px;
  border-radius: 8px;
  background:
    linear-gradient(#f4f5ff, #f4f5ff) padding-box,
    linear-gradient(180deg, #3378ea 0%, #5933ab 100%) border-box;
  border: 2px solid transparent;
`

const Row = styled.div`
  display: flex;
  gap: 10px;
`

const Pill = styled.div<{ color?: string }>`
  display: inline-block;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  padding: 0px 10px;
  border-radius: 20px;
  background: ${(props) => props.color ?? primary};
  color: ${(props) =>
    props.color === 'rgb(240, 228, 66)' ? mainText : 'white'};
  cursor: pointer;
  transform: translate(0%);
  transition: 0.15s ease-out;
  border: none;
  font-size: 12px;
  margin-left: 5px;
`

const StyledTable = styled(Table)`
  max-height: 90%;
  .ant-table-row {
    cursor: pointer;
  }

  .ant-table-column-sort {
    background: none;
  }

  .ant-table-thead th.ant-table-column-sort {
    background: #fafafa;
  }

  .ant-table-pagination-right {
    margin-right: 16px;
  }
`

const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-top: 10px;
`
