import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Form } from 'antd'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { format } from 'date-fns'
import { Loader } from 'shared/components'
import { UploadType } from 'utils/enums'
import {
  ProductForSource,
  ProjectSourceFile,
  ProjectSourceURL,
  ProjectSourceFileIndexes,
  ProjectSourceExtraFields,
} from './types'
import { getUploadPath } from './model'
import { NewSource, customSources } from './components'
import { SourceSchema } from './validators'
import { transformSourceType } from './utils'

type Props = {
  handleOpenProject: () => void
  addSource: (p: ProjectSourceFile | ProjectSourceURL) => void
  sourceNames: string[]
  reservedKeywords: string[]
  sourceToEdit: ProjectSourceFile | ProjectSourceURL | null
  sourceToEditIndex: number | null
  setSourceToEditIndex: (n: number | null) => void
  deleteSource: () => void
  products: Array<ProductForSource>
}
export type SourceValues = {
  sourceTitle: string
  sourceType: string
  product_id: number | string
  sourceUrl?: string
  sourceFile?: File
  sourceDate?: Date
} & Partial<ProjectSourceFileIndexes> &
  Partial<ProjectSourceExtraFields>

const layout = { labelCol: { span: 5 } }
const initialValues: SourceValues = {
  sourceTitle: '',
  sourceType: '',
  product_id: 0,
}

export const SourceForm = ({
  products,
  addSource,
  sourceNames,
  deleteSource,
  sourceToEdit,
  sourceToEditIndex,
  reservedKeywords,
  handleOpenProject,
  setSourceToEditIndex,
}: Props) => {
  const [loading, setLoading] = useState<boolean>(false)

  const formik = useFormik({
    initialValues,
    validateOnBlur: false,
    validateOnChange: false,
    // validationSchema: SourceSchema,
    onSubmit: async ({ sourceTitle, ...rest }: SourceValues, { setErrors }) => {
      if (
        sourceNames
          .filter((_, i) => i !== sourceToEditIndex)
          .includes(sourceTitle)
      ) {
        formik.setFieldError(
          'sourceTitle',
          `Source with name ${sourceTitle} already exists`,
        )
        return
      }

      if (!rest.product_id) {
        formik.setFieldError('product_id', `You need to select a product`)
        return
      }

      const restrictedPostKey = findIntersectedKey(
        reservedKeywords,
        rest.custom,
      )
      const restrictedSourceKey = findIntersectedKey(
        reservedKeywords,
        rest.source_custom,
      )
      const restrictedProductKey = findIntersectedKey(
        reservedKeywords,
        rest.product_custom,
      )
      if (restrictedPostKey || restrictedSourceKey || restrictedProductKey) {
        setErrors({
          custom: `Key ${
            restrictedPostKey || restrictedSourceKey || restrictedProductKey
          } conflicts with reserved Yogi keywords. Please rename it and create the project.`,
        })
        return
      }

      try {
        if (
          rest.sourceType &&
          rest.sourceType === UploadType.File &&
          rest.sourceFile
        ) {
          setLoading(true)

          const {
            data: { bucket, key },
          }: any = await getUploadPath()

          const brand = rest.brand

          const resultObject: ProjectSourceFile = {
            file: rest.sourceFile,
            key,
            bucket,
            source: 'File',
            title: sourceTitle,
            orig_name: rest.sourceFile.name,
            product_id: rest.product_id,
            indexes: {
              brand: brand === undefined ? null : brand,
              syndicated_from:
                rest.syndicated_from !== undefined
                  ? rest.syndicated_from
                  : null,
              title: rest.title !== undefined ? rest.title : null,
              body: rest.body !== undefined ? rest.body : null,
              input_id: rest.input_id !== undefined ? rest.input_id : null,
              username: rest.username !== undefined ? rest.username : null,
              userid: rest.userid !== undefined ? rest.userid : null,
              location: rest.location !== undefined ? rest.location : null,
              country: rest.country !== undefined ? rest.country : null,
              create_time:
                rest.create_time !== undefined ? rest.create_time : null,
              score: rest.score !== undefined ? rest.score : null,
              url: rest.url !== undefined ? rest.url : null,
              source: rest.source !== undefined ? rest.source : null,
              product: rest.product !== undefined ? rest.product : null,
              product_hierarchy:
                rest.product_hierarchy !== undefined
                  ? rest.product_hierarchy
                  : null,
              promotion: rest.promotion !== undefined ? rest.promotion : null,
              verified: rest.verified !== undefined ? rest.verified : null,
              recommended:
                rest.recommended !== undefined ? rest.recommended : null,
              custom: rest.custom !== undefined ? rest.custom : null,
              source_custom:
                rest.source_custom !== undefined ? rest.source_custom : null,
              product_custom:
                rest.product_custom !== undefined ? rest.product_custom : null,
            },
          }

          setLoading(false)
          addSource(resultObject)
          handleOpenProject()
        }
        if (
          rest.sourceType &&
          rest.sourceType !== UploadType.File &&
          rest.sourceDate
        ) {
          let resultObject: ProjectSourceURL = {
            url: rest.sourceUrl,
            source: rest.sourceType,
            title: sourceTitle,
            product_id: rest.product_id,
            from_date: format(rest.sourceDate, 'MM/dd/yyyy'),
            custom: rest.custom,
          }

          if (rest.sourceType === 'Amazon') {
            // possibly need to implement amazon as custom type if more complexity will appear
            resultObject = {
              ...resultObject,
              extra: {
                subtype: rest.subtype,
                global_reviews: rest.subtype?.includes('reviews')
                  ? rest.global_reviews
                  : undefined,
              },
            }
          }

          if (customSources.includes(transformSourceType(rest.sourceType))) {
            resultObject = {
              ...resultObject,
              extra: {
                page_id: rest.page_id,
                access_token: rest.access_token,
                extra_product_id: rest.extra_product_id,
                brand: rest.brand,
                email: rest.email,
                client_id: rest.client_id,
                locale: rest.locale,
              },
            }
          }

          addSource(resultObject)
          handleOpenProject()
        }
      } catch (e: any) {
        setLoading(false)
        toast.error(e?.response?.data || e.message)
        return
      }
    },
  })

  useEffect(() => {
    if (sourceToEdit && !formik.touched.title) {
      formik.setTouched({ sourceTitle: true })

      if (sourceToEdit.source !== 'File') {
        const localSource = sourceToEdit as ProjectSourceURL

        const values: SourceValues = {
          sourceTitle: localSource.title,
          sourceType: localSource.source,
          sourceUrl: localSource.url,
          product_id: localSource.product_id,
          sourceDate: new Date(localSource.from_date),
          custom: localSource.custom,
        }

        if (localSource.extra) {
          values.page_id = localSource.extra.page_id
          values.access_token = localSource.extra.access_token
          values.brand = localSource.extra.brand as any
          values.email = localSource.extra.email
          values.subtype = localSource.extra.subtype
          values.global_reviews = localSource.extra.global_reviews
          values.extra_product_id = localSource.extra.extra_product_id
          values.locale = localSource.extra.locale
          values.client_id = localSource.extra.client_id
        }

        formik.setValues(values)
      } else {
        const localSource = sourceToEdit as ProjectSourceFile

        const values: SourceValues = {
          sourceTitle: localSource.title,
          sourceType: UploadType.File,
          sourceFile: localSource.file,
          title: localSource.indexes.title,
          body: localSource.indexes.body,
          input_id: localSource.indexes.input_id,
          username: localSource.indexes.username,
          userid: localSource.indexes.userid,
          location: localSource.indexes.location,
          create_time: localSource.indexes.create_time,
          product_id: localSource.product_id,
          score: localSource.indexes.score,
          url: localSource.indexes.url,
          source: localSource.indexes.source,
          product: localSource.indexes.product,
          custom: localSource.indexes.custom,
          source_custom: localSource.indexes.source_custom,
          product_custom: localSource.indexes.product_custom,
        }

        formik.setValues(values)
      }
    }

    return () => {
      setSourceToEditIndex(null)
    }
  }, [sourceToEdit])

  if (sourceToEdit && !formik.values.sourceTitle.length) {
    return null
  }

  return (
    <Wrapper>
      <Form
        {...layout}
        onFinish={() => formik.submitForm()}
        initialValues={{
          sourceType: formik.values.sourceType,
          title: formik.values.title,
          body: formik.values.body,
          input_id: formik.values.input_id,
          username: formik.values.username,
          userid: formik.values.userid,
          location: formik.values.location,
          create_time: formik.values.create_time,
          score: formik.values.score,
          url: formik.values.url,
          custom: formik.values.custom,
        }}
      >
        <NewSource
          formik={formik}
          products={products}
          onDelete={sourceToEditIndex !== null ? deleteSource : undefined}
        />
      </Form>
      {loading && (
        <Overlay>
          <Loader />
        </Overlay>
      )}
    </Wrapper>
  )
}

const findIntersectedKey = (
  reservedKeywords: string[],
  customKeysObject?: { [p: string]: string | number } | null,
) => {
  if (customKeysObject && Object.keys(customKeysObject).length > 0) {
    const customKeys = Object.keys(customKeysObject)
    return customKeys.find((key) =>
      reservedKeywords.some(
        (reservedKey) => reservedKey.toLowerCase() === key.toLocaleLowerCase(),
      ),
    )
  }

  return null
}

const Wrapper = styled.div`
  position: relative;
`
const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
