import React, { useEffect, useRef, useState } from 'react'
import {
  ChartTypes,
  matrixChartIds,
  updateBarChartConfig,
  updateChartConfig,
  updateMatrixChartConfig,
  updatePieChartConfig,
} from 'features/project/features/Charts/utils'
import {
  ChartLegend as ChartLegendType,
  DataForChart,
  type UnionChart,
} from 'features/project/features/Charts/types'
import _ from 'lodash'
import { current } from 'immer'

type Props = {
  // legend: Array<ChartLegendType> | null
  isFullScreen: boolean
  chartRef: React.RefObject<HTMLCanvasElement>
  currentConfiguredChart: DataForChart | null | undefined
  treeView: boolean
}
const useChartConfig = ({
  // legend,
  isFullScreen,
  chartRef,
  currentConfiguredChart,
}: Props) => {
  // const props = {
  //   // legend,
  //   isFullScreen,
  //   chartRef,
  //   currentConfiguredChart,
  // }
  // const prev = useRef(props)
  // useEffect(() => {
  //   const changedProps = Object.entries(props).reduce((ps, [k, v]) => {
  //     // @ts-ignore
  //     if (prev.current[k] !== v) {
  //       // @ts-ignore
  //       ps[k] = [prev.current[k], v]
  //     }
  //     return ps
  //   }, {})
  //   if (Object.keys(changedProps).length > 0) {
  //     console.log('Changed props:', changedProps)
  //   }
  //   prev.current = props
  // }, [isFullScreen, chartRef, currentConfiguredChart])

  const [updatedChart, setUpdatedChart] = useState<
    DataForChart | null | undefined
  >(currentConfiguredChart)

  useEffect(() => {
    if (currentConfiguredChart && chartRef.current) {
      const legend = currentConfiguredChart.legend
      const chartType = currentConfiguredChart.type_label
      const { data: dataObject, options: optionsObject } =
        currentConfiguredChart
      let tempChart: any = _.cloneDeep(currentConfiguredChart)
      if (chartType === ChartTypes.MATRIX) {
        try {
          tempChart = updateMatrixChartConfig(dataObject, legend, optionsObject)
        } catch (e) {
          tempChart = currentConfiguredChart
        }
      } else if (chartType === ChartTypes.PIE && dataObject?.datasets) {
        tempChart = updatePieChartConfig(
          dataObject,
          legend,
          optionsObject,
          currentConfiguredChart.type !== 'doughnut',
        )
      } else if (chartType === ChartTypes.BAR) {
        tempChart = updateBarChartConfig(dataObject, legend, optionsObject)
      } else {
        tempChart = updateChartConfig(dataObject, legend, optionsObject)
      }

      setUpdatedChart(tempChart)
    }
  }, [isFullScreen, chartRef, currentConfiguredChart])

  return {
    updatedChart,
  }
}

export default useChartConfig
