import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import { useLocation } from 'react-router-dom'
import { useHistory } from 'react-router'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { InnerTitle } from '../../../../components/InnerTitle'
import PDPDetail from './PDPDetail'
import PDPList from './Components/PDPList/PDPList'
import mixpanel from '../../../trackers/mixpanel'
import { ProductHierarchyInfo } from '../products/types'
import { EmptyPDPDetail } from 'features/project/features/PDP/Components/EmptyPDPDetail/EmptyPDPDetail'
import { Loader } from 'shared/components'

const PDP: React.FC = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const location = useLocation()
  const history = useHistory()

  const [focusListData, setFocusListData] = useState<ProductHierarchyInfo[]>([])

  const selectedLens = 'product_hierarchy'

  const [selectedProduct, setSelectedProduct] = useState<string | null>(null)

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search)
    setSelectedProduct(
      !!urlParams.get('product') ? urlParams.get('product') : null,
    )
  }, [location.search])

  const updateProduct = (product: string | null) => {
    // update url params to reflect lens
    const urlParams = new URLSearchParams(window.location.search)
    if (!product) {
      urlParams.delete('product')
      urlParams.delete('item')
    } else {
      urlParams.set('product', product)
    }
    history.push(`${window.location.pathname}?${urlParams.toString()}`)
  }

  const focusItem = focusListData.find(
    (item) => item.hierarchy === selectedProduct,
  )

  if (!defaultFilterList.length) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>Loading PDP Insights</LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <Container>
      <PDPList
        selectedLens={selectedLens}
        setSelectedProduct={updateProduct}
        focusListData={focusListData}
        setFocusListData={setFocusListData}
      />
      <>
        {focusItem ? (
          <PDPDetail
            item={focusItem}
            lens={selectedLens}
            setValue={updateProduct}
          />
        ) : (
          <EmptyPDPDetail />
        )}
      </>
    </Container>
  )
}

export default PDP

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;
  max-width: calc(100% - 40px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  gap: 20px;
`
