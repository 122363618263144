import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { flatten } from 'lodash'
import { Button, Input } from 'antd'
import { toast } from 'react-toastify'
import {
  FilterOption,
  ApplyFilterPayload,
  AppliedFilterOption,
  SortableFilterOption,
  AppliedFilterOptionSelect,
  ApplyGroupFilterPayload,
} from 'features/project/features/filters/types'
import mixpanel from 'features/trackers/mixpanel'
import {
  applyFilterValue,
  applyFreeInputFilterValue,
  applyGroupFilterValue,
  HardcodedFilterOptionsEnum,
} from 'features/project/features/filters/helpers'
import { FilterPanelSelector } from './components/FilterPanelSelector/FilterPanelSelector'
import { FilterPanelListItem } from './components/FilterPanelListItem/FilterPanelListItem'
import {
  applyEveryFilterValue,
  isFilterOptionApplied,
  transformFilterOptions,
  getAppliedFilterOptionsInfo,
} from './helpers'

import {
  DndContext,
  closestCenter,
  DragOverlay,
  useSensor,
  useSensors,
  MouseSensor,
} from '@dnd-kit/core'
import {
  arrayMove,
  SortableContext,
  verticalListSortingStrategy,
} from '@dnd-kit/sortable'
import {
  restrictToVerticalAxis,
  restrictToFirstScrollableAncestor,
} from '@dnd-kit/modifiers'
import { DragEndEvent } from '@dnd-kit/core/dist/types'
import { saveFilterOrder } from 'features/project/features/filters/model'
import {
  HolderOutlined,
  RightOutlined,
  SearchOutlined,
} from '@ant-design/icons'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { secondaryNavy, superLightGrey } from 'assets/styles/variables'
import { YogiButton } from 'components/UI/YogiButton'
import { SavedFilterSelector } from './components/SavedFilterSelector/SavedFilterSelector'
import Divider from 'components/UI/YogiDivider'
import { useFilterList } from 'features/project/hooks/useFilterList'

type Props = {
  onClose?: () => void
  filterValues: Filters
  comparativeIndex: number
  height?: number
  // these are for custom dash charts to have their own filterlists
  localOpenedOptions?: string[]
  setLocalOpenedOptions?: (openedOptions: string[]) => void
  updateLocalFilterValues?: (filterValues: Filters) => void
  localSelectedOption?: string
  setLocalSelectedOption?: (selectedOption: string) => void
  isUnlinked?: boolean
  parentFilters?: Filters
  mergedFilters?: Filters
}

// This component probably should be broken up further
export const FilterPanel: React.FC<Props> = ({
  onClose,
  filterValues,
  comparativeIndex,
  height,
  //cdash things
  updateLocalFilterValues,
  localOpenedOptions,
  setLocalOpenedOptions,
  localSelectedOption,
  setLocalSelectedOption,
  isUnlinked,
  parentFilters,
  mergedFilters,
}) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const setFilterValues = useProjectStore(
    (state: ProjectState) => state.setFilterValues,
  )
  const clearFilter = useProjectStore(
    (state: ProjectState) => state.clearFilter,
  )

  const countries = useProjectStore((state: ProjectState) => state.countries)

  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const globalSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.selectedOptionField[comparativeIndex],
  )
  const updateGlobalSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )

  const [searchTerm, setSearchTerm] = useState('')
  const [localAppliedFilters, setLocalAppliedFilters] = useState<
    Array<AppliedFilterOption>
  >([])

  const [selectedOptionField, setSelectedOptionField] = useState<
    string | undefined
  >(globalSelectedOptionField ? globalSelectedOptionField : localSelectedOption)

  const { data: filterListData, isLoading } = useFilterList(
    mergedFilters ?? filterValues,
    selectedOptionField,
  )

  useEffect(() => {
    setLocalAppliedFilters(filterValues.values)
  }, [filterValues.values])

  useEffect(() => {
    updateGlobalSelectedOptionField(comparativeIndex || 0, '')
  }, [])

  const updateFilterValues = (filterOptions: Array<AppliedFilterOption>) => {
    if (updateLocalFilterValues) {
      updateLocalFilterValues({
        values: filterOptions,
        searchCondition: filterValues.searchCondition,
        searchQuery: filterValues.searchQuery,
      })
    } else {
      setFilterValues(comparativeIndex, [...filterOptions])
    }
    getDeletedOptions(filterOptions).forEach(({ field }) =>
      clearFilter(comparativeIndex, field),
    )
  }

  const getDeletedOptions = (filterOptions: Array<AppliedFilterOption>) => {
    return filterValues.values.filter((option) => {
      const alreadyAppliedFilter = filterOptions.find(
        ({ field }) => field === option.field,
      )
      return alreadyAppliedFilter === undefined
    })
  }

  // needs refactor
  const applyFilter = useCallback(
    (payload: ApplyFilterPayload, immediatelySubmit?: boolean) => {
      const preparedFilters = applyFilterValue(payload, localAppliedFilters)

      setLocalAppliedFilters(preparedFilters)
      if (immediatelySubmit) {
        updateFilterValues(preparedFilters)
      }
    },
    [localAppliedFilters],
  )

  const applyGroupFilter = useCallback(
    (payload: ApplyGroupFilterPayload, immediatelySubmit?: boolean) => {
      const preparedFilters = applyGroupFilterValue(
        payload,
        localAppliedFilters,
      )

      setLocalAppliedFilters(preparedFilters)
      if (immediatelySubmit) {
        updateFilterValues(preparedFilters)
      }
    },
    [localAppliedFilters],
  )

  const applyFreeInputFilter = useCallback(
    (payload: ApplyGroupFilterPayload) => {
      const preparedFilters = applyFreeInputFilterValue(
        payload,
        localAppliedFilters,
      )

      setLocalAppliedFilters(preparedFilters)
    },
    [localAppliedFilters],
  )

  const hasAppliedDateValue = filterValues.values.find(
    ({ field }) => field === HardcodedFilterOptionsEnum.CREATE_TIME,
  )

  const applyAll = (
    options: Array<FilterOption | undefined>,
    search: string,
    isDeselect: boolean,
  ) => {
    if (!options.length) return
    // I am assuming that anything that has ApplyAll is an AppliedFilterOptionSelect, because there are no AppliedFilterOptionRange filter types that provide a "Select All" options
    let preparedFilters = flatten(
      options
        .filter(Boolean)
        .map((option) => option as FilterOption)
        .map((option) =>
          applyEveryFilterValue(option, localAppliedFilters, search),
        )
        .filter(Boolean)
        .map(
          (option) => option as Array<AppliedFilterOption>,
        ) as AppliedFilterOptionSelect[][],
    )
    if (preparedFilters) {
      // the reason this exists is that, if there are already selected values for the same property, they are getting deselected when select/deselect all is called on another selection
      // I guarantee there is a better way to do this and im probably doing some bad typescript stuff
      // upon revisitation, i have no idea why this works the way it does
      const currentFilter = preparedFilters.find(
        // cant get here without a length of options as per the first statement in this function
        (el) => el.field === options[0]!.field,
      )
      let values = (currentFilter as AppliedFilterOptionSelect).values
      // this should always hit because we only run this on select type filters
      if (currentFilter && values) {
        let match = localAppliedFilters.find(
          (el) => el.field === currentFilter.field,
        ) as AppliedFilterOptionSelect
        if (match) {
          let matchValues = (match as AppliedFilterOptionSelect).values
          if (isDeselect) {
            // this is n^2 but finds the unshared values of the selections and deselection array
            values = matchValues.filter((obj) => {
              return values.indexOf(obj) === -1
            })
          } else {
            // if selecting, dedupe the values
            values = values.concat(matchValues)
            values = Array.from(new Set(values))
          }
          // update prepared filters entry with merged values
          preparedFilters.find(
            (el) => el.field === currentFilter.field,
          )!.values = values
        }
      }
      preparedFilters = preparedFilters.filter(
        (el) => !el.hasOwnProperty('values') || el.values.length > 0,
      )
      setLocalAppliedFilters(preparedFilters)
    }
  }

  const filterListToShow = transformFilterOptions(
    filterListData ?? defaultFilterList,
  )

  const { isEqual } = getAppliedFilterOptionsInfo(
    filterValues.values,
    localAppliedFilters,
  )

  const selectedFilterOptionObject = transformFilterOptions(
    defaultFilterList,
  ).find((el) => el.field === selectedOptionField)

  const items = filterListToShow.map((e) => ({ ...e, id: e.field }))

  const itemsSearched = !!searchTerm
    ? items.filter((el) =>
        el.alias?.toLowerCase().startsWith(searchTerm?.toLowerCase()),
      )
    : items

  const handleListItemClick = (option: SortableFilterOption) => {
    setSelectedOptionField(option.field)
    setLocalSelectedOption && setLocalSelectedOption(option.field)
    if (localOpenedOptions && setLocalOpenedOptions) {
      if (!localOpenedOptions.includes(option.field)) {
        setLocalOpenedOptions([...localOpenedOptions, option.field])
      }
    }
  }

  const handleClearAll = () => {
    if (onClose) {
      onClose()
      setSelectedOptionField('')
    }
    clearOpenedOptions(comparativeIndex)
    setLocalOpenedOptions && setLocalOpenedOptions([])
    setLocalAppliedFilters(
      localAppliedFilters.filter(
        (el) =>
          (countries?.enabled && el.field.includes('country')) ||
          el.field === 'create_time',
      ),
    )
    updateFilterValues(
      localAppliedFilters.filter(
        (el) =>
          (countries?.enabled && el.field.includes('country')) ||
          el.field === 'create_time',
      ),
    )
  }

  const handleApply = () => {
    mixpanel.track('filter', {
      action: 'panel',
      ...route,
      value: localAppliedFilters,
    })
    clearOpenedOptions(comparativeIndex)
    updateFilterValues(localAppliedFilters)
    onClose && onClose()
  }

  return (
    <Container>
      <Top>
        <Left
          style={{
            // height: isComparative ? '350px' : '400px',
            // maxHeight: height,
            flex: isComparative ? 3 : 2,
          }}
        >
          <FilterSearch>
            <SearchInput
              prefix={
                <SearchOutlined style={{ color: '#93ABB5', fontSize: 12 }} />
              }
              placeholder={'Search...'}
              onChange={(e) => {
                setSearchTerm(e.target.value)
              }}
            />
          </FilterSearch>
          {!route.isDashboard && (
            <FilterPanelListItem
              id={''}
              key={'saved'}
              isSelected={!selectedOptionField}
              isApplied={false}
              onClick={() => {
                // TODO handle
                setSelectedOptionField('')
              }}
            >
              <div>Saved Filters</div>
              <div>
                <RightOutlined style={{ color: '#9FB7C3' }} />
              </div>
            </FilterPanelListItem>
          )}
          {items
            .filter((el) => el.field === 'proj_uuid')
            .map((option) => (
              <FilterPanelListItem
                id={option.id}
                key={option.field}
                disabled={option.disabled}
                isSelected={option.field === selectedOptionField}
                isApplied={isFilterOptionApplied(filterValues.values, option)}
                onClick={() => {
                  setSelectedOptionField(option.field)
                  setLocalSelectedOption && setLocalSelectedOption(option.field)
                  // updateOpenedOptions(comparativeIndex, option.field)
                  // TODO refactor this
                  if (localOpenedOptions && setLocalOpenedOptions) {
                    if (!localOpenedOptions.includes(option.field)) {
                      setLocalOpenedOptions([
                        ...localOpenedOptions,
                        option.field,
                      ])
                    }
                  }
                }}
              >
                <div>{option.alias}</div>
                <div>
                  <RightOutlined style={{ color: '#9FB7C3' }} />
                </div>
              </FilterPanelListItem>
            ))}
          <Divider style={{ borderColor: secondaryNavy, opacity: '.5' }} />
          {/*<Divider style={{ borderColor: secondaryNavy }} />*/}
          {itemsSearched
            .filter((el) => el.field !== 'proj_uuid' && el.field !== 'star')
            .map((option) => (
              <FilterPanelListItem
                id={option.id}
                key={option.field}
                disabled={option.disabled}
                isSelected={option.field === selectedOptionField}
                isApplied={isFilterOptionApplied(filterValues.values, option)}
                onClick={() => handleListItemClick(option)}
              >
                <div>{option.alias}</div>
                <div>
                  <RightOutlined style={{ color: '#9FB7C3' }} />
                </div>
              </FilterPanelListItem>
            ))}
        </Left>
        <Right
          style={
            {
              // height: isComparative ? '350px' : '400px',
              // maxHeight: height,
            }
          }
        >
          {!selectedOptionField && !route.isDashboard ? (
            <SavedFilterSelector
              updateLocalFilterValues={updateLocalFilterValues}
              comparativeIndex={comparativeIndex}
              onClose={onClose}
            />
          ) : (
            <FilterPanelSelector
              key={selectedOptionField}
              option={selectedFilterOptionObject}
              applyFilter={applyFilter}
              applyGroupFilter={applyGroupFilter}
              applyFreeInputFilter={applyFreeInputFilter}
              appliedFilters={localAppliedFilters}
              applyEveryFilter={(search) => {
                applyAll([selectedFilterOptionObject], search, false)
              }}
              clearFilters={(search) => {
                applyAll([selectedFilterOptionObject], search, true)
              }}
              filterListData={filterListData}
              filterList={filterListToShow}
              parentFilters={parentFilters}
              isUnlinked={isUnlinked}
              filterValues={filterValues}
              isLoading={isLoading}
            />
          )}
        </Right>
      </Top>
      <Bottom>
        {/*{setDragDisabled && (*/}
        {/*  <HolderOutlined*/}
        {/*    style={{*/}
        {/*      marginRight: 'auto',*/}
        {/*      cursor: grabbing ? 'grabbing' : 'grab',*/}
        {/*    }}*/}
        {/*    onMouseEnter={() => setDragDisabled(false)}*/}
        {/*    onMouseLeave={() => setDragDisabled(true)}*/}
        {/*    onMouseDown={() => setGrabbing(true)}*/}
        {/*    onMouseUp={() => setGrabbing(false)}*/}
        {/*  />*/}
        {/*)}*/}

        {onClose && (
          <Button type="text" onClick={onClose}>
            Close
          </Button>
        )}
        <Button
          type="primary"
          disabled={
            !filterValues.values.length &&
            !localAppliedFilters.length &&
            !hasAppliedDateValue
          }
          onClick={handleClearAll}
          danger
          style={{
            borderRadius: '8px',
            borderWidth: '2px',
          }}
        >
          Clear All
        </Button>
        <YogiButton type="primary" disabled={isEqual} onClick={handleApply}>
          Apply
        </YogiButton>
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-md);
`

const Top = styled.div`
  display: flex;
  flex-wrap: wrap;
  height: 100%;
  max-height: calc(100% - 65px);
  flex-grow: 1;
`
const Bottom = styled.div`
  border-top: 1px solid var(--color-grey);
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: var(--default-padding-half);
  background: ${superLightGrey};

  & > * {
    margin-left: var(--default-padding-half);
  }
`
const Left = styled.div`
  overflow-y: auto;
  border-right: 1px solid var(--color-grey);
  max-height: 100%;
`
const Right = styled.div`
  flex: 5;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  max-height: 100%;
`

const Overlay = styled.div`
  cursor: pointer;
  user-select: none;
  padding: calc(var(--default-padding-half) / 2) var(--default-padding-half);
  background: var(--color-grey);
  font-weight: 400;
`

const FilterSearch = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  user-select: none;
  background: #f5f8f9;
  font-weight: 400;
  font-size: var(--font-size-md);
  border-bottom: 1px solid #e7edf0;

  outline: 0 !important;
  box-shadow: none !important;
`

const SearchInput = styled(Input)`
  background: #f5f8f9;
  font-weight: 400;
  font-size: var(--font-size-md);
  padding: 12px 12px;
  border: none;

  outline: 0 !important;
  box-shadow: none !important;

  .ant-input-prefix {
    margin-right: 8px;
  }

  .ant-input {
    background: transparent;
    margin-top: 2px;
  }
`
