import React, { useState, useCallback } from 'react'
import styled from 'styled-components'
import * as Yup from 'yup'
import { Form } from 'antd'
import { useFormik } from 'formik'
import { toast } from 'react-toastify'
import { ModalWrapper, ModalHeader } from 'shared/wrappers'
import { Loader } from 'shared/components'
import { createProject, setProjectSources, uploadFile } from './model'
import { NewProject, Selector } from './components'
import { CsvForm } from './features/csv/csv-form'
import { ProductForm } from './features/product/product-form'
import { SourceForm } from './features/source'
import { ProjectSourceFile, ProjectSourceURL } from './features/source/types'
import { Product } from './features/product/types'

const layout = { labelCol: { span: 5 } }

type Props = {
  handleClose: () => void
  reservedKeywords: string[]
}

export type FormValues = {
  title: string
  description: string
  products: Array<Product>
  sources: Array<ProjectSourceFile | ProjectSourceURL>
}

const initialValues: FormValues = {
  title: '',
  description: '',
  products: [],
  sources: [],
}

const NewProjectSchema = Yup.object().shape({
  title: Yup.string()
    .required('A project title is required')
    .min(2, '2 Character Minimum for Title')
    .max(100, '100 Character Max for Title'),
  description: Yup.string()
    .min(2, '2 Character Minimum for Description')
    .max(100, '100 Character Max for Description'),
})

export const ProjectNewForm = (props: Props) => {
  const [page, setPage] = useState<string>('selector')
  const [loading, setLoading] = useState<boolean>(false)
  const [stopWords, setStopWords] = useState<string[]>([])
  const [sourceToEditIndex, setSourceToEditIndex] = useState<null | number>(
    null
  )
  const [productToEditIndex, setProductToEditIndex] = useState<null | number>(
    null
  )

  const formik = useFormik({
    initialValues,
    validationSchema: NewProjectSchema,
    validateOnChange: false,
    validateOnBlur: false,
    onSubmit: async (
      { title, description, sources, products },
      { setFieldError }
    ) => {
      if (sources.length) {
        if (sources.some((source) => !source.product_id)) {
          setFieldError(
            'sources',
            'Every source must be assigned to a product.'
          )
          return
        }

        try {
          setLoading(true)
          const { data } = await createProject(title, description)
          const fileSources = sources.filter(
            ({ file }: any) => !!file
          ) as ProjectSourceFile[]

          await Promise.all(
            fileSources.map(({ key, bucket, file }) =>
              uploadFile(key, file, bucket)
            )
          )

          await setProjectSources({
            sources,
            products,
            stop_words: stopWords,
            proj_uuid: data.proj_uuid,
          })

          toast.success(`Project ${title} created!`)
          setLoading(false)
          props.handleClose()
        } catch (e: any) {
          setLoading(false)
          toast.error(e?.response?.data?.detail?.msg || e.message)
        }
      }
    },
  })

  const openNewProject = useCallback(() => setPage('project'), [])
  const openNewSource = useCallback(async () => {
    formik.setTouched({ title: true, description: true })
    const response = await formik.validateForm()

    if (!Object.values(response).length) {
      setPage('source')
    }
  }, [])

  const addProduct = useCallback(
    (product: Product) => {
      if (productToEditIndex !== null) {
        formik.values.products[productToEditIndex] = product
        formik.setFieldValue('products', formik.values.products)
        setProductToEditIndex(null)
      } else {
        formik.setFieldValue('products', [...formik.values.products, product])
      }
    },
    [formik.values.products, productToEditIndex]
  )
  const deleteProduct = useCallback(() => {
    if (productToEditIndex !== null) {
      openNewProject()
      formik.setFieldValue(
        'products',
        formik.values.products.filter(
          (_, index) => index !== productToEditIndex
        )
      )
      setProductToEditIndex(null)
    }
  }, [formik.values.sources, productToEditIndex])
  const addSource = useCallback(
    (source: ProjectSourceFile | ProjectSourceURL) => {
      if (sourceToEditIndex !== null) {
        formik.values.sources[sourceToEditIndex] = source
        formik.setFieldValue('sources', formik.values.sources)
        setSourceToEditIndex(null)
      } else {
        formik.setFieldValue('sources', [...formik.values.sources, source])
      }
    },
    [formik.values.sources, sourceToEditIndex]
  )
  const deleteSource = useCallback(() => {
    if (sourceToEditIndex !== null) {
      openNewProject()
      formik.setFieldValue(
        'sources',
        formik.values.sources.filter((_, index) => index !== sourceToEditIndex)
      )
      setSourceToEditIndex(null)
    }
  }, [formik.values.sources, sourceToEditIndex])

  const isSelector = page === 'selector'
  const isCsvUpload = page === 'csv'
  const isProject = page === 'project'
  const isProduct = page === 'product'
  const isSource = page === 'source'

  return (
    <ModalWrapper>
      <ModalHeader
        handleClose={props.handleClose}
        handleBack={
          isSource || isProduct
            ? openNewProject
            : isCsvUpload
            ? () => setPage('selector')
            : undefined
        }
      />
      <Wrapper>
        {isSelector && (
          <Selector
            onBlankClick={() => setPage('project')}
            onCSVClick={() => setPage('csv')}
          />
        )}
        {isProject && (
          <Form onFinish={formik.submitForm} {...layout}>
            <NewProject
              sources={formik.values.sources}
              products={formik.values.products}
              handleOpenSource={openNewSource}
              handleOpenProduct={() => setPage('product')}
              formik={formik}
              isSubmitDisabled={!formik.values.sources.length}
              stopWords={stopWords}
              setStopWords={setStopWords}
              setSourceToEditIndex={setSourceToEditIndex}
              setProductToEditIndex={setProductToEditIndex}
            />
          </Form>
        )}
        {isProduct && (
          <ProductForm
            addProduct={addProduct}
            productToEdit={
              productToEditIndex !== null
                ? formik.values.products[productToEditIndex]
                : null
            }
            handleOpenProject={openNewProject}
            deleteProduct={deleteProduct}
            setProductToEditIndex={setProductToEditIndex}
          />
        )}
        {isSource && (
          <SourceForm
            products={formik.values.products}
            reservedKeywords={props.reservedKeywords}
            sourceNames={formik.values.sources.map((source) => source.title)}
            handleOpenProject={openNewProject}
            addSource={addSource}
            sourceToEdit={
              sourceToEditIndex !== null
                ? formik.values.sources[sourceToEditIndex]
                : null
            }
            sourceToEditIndex={sourceToEditIndex}
            setSourceToEditIndex={setSourceToEditIndex}
            deleteSource={deleteSource}
          />
        )}
        {isCsvUpload && (
          <CsvForm
            reservedKeywords={props.reservedKeywords}
            onSuccess={({ sources: sourceList, products: productList }) => {
              setPage('project')
              formik.setFieldValue('sources', [
                ...formik.values.sources,
                ...sourceList,
              ])
              formik.setFieldValue('products', [
                ...formik.values.products,
                ...productList,
              ])
            }}
          />
        )}

        {loading && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
      </Wrapper>
    </ModalWrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
`
const Overlay = styled.div`
  background: rgba(255, 255, 255, 0.7);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`
