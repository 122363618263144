import React from 'react'
import { YogiButton } from '../../../components/UI/YogiButton'
import { requestAccess } from '../model'
import { toast } from 'react-toastify'
import { useAuthContext } from 'features/auth'

const RequestAccessPage: React.FC = () => {
  const authContext = useAuthContext()

  const request = async () => {
    requestAccess(authContext?.user?.attributes?.email)
      .then((res) => {
        toast.success('Request for Access Sent')
      })
      .catch((err) => {
        toast.error('Request for Access Failed')
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        height: '100vh',
        flexDirection: 'column',
      }}
    >
      <h1>You have no dashboards!</h1>
      <p>
        Please request access, and your Yogi Customer Success Manager will be
        notified.
      </p>
      <p>They will reach out to you and add you to a dashboard.</p>
      <p></p>
      <YogiButton type={'primary'} onClick={request}>
        Request Access
      </YogiButton>

      <div style={{ padding: 10 }}>
        <YogiButton key="2" onClick={authContext.signOut}>
          Logout
        </YogiButton>
      </div>
    </div>
  )
}

export default RequestAccessPage
