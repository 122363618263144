import React from 'react'

type Props = { label?: string; symbol: String }
export const Emoji: React.FC<Props> = ({ label = '', symbol }) => (
  <span
    className="emoji"
    role="img"
    aria-label={label}
    aria-hidden={label === '' ? 'true' : 'false'}
  >
    {symbol}
  </span>
)
export default Emoji
