import React from 'react'
import { Input, InputProps, SelectProps } from 'antd'
import { SearchOutlined } from '@mui/icons-material'
import {
  cardBorder,
  cardBorderGrey,
  errorRed,
} from '../../assets/styles/variables'
import styled from 'styled-components'

type Props = InputProps & { isError?: boolean }
export const YogiInput: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  style,
  isError,
  ...props
}) => {
  return (
    <CustomInput
      placeholder={placeholder}
      value={value}
      style={{
        ...style,
      }}
      onChange={onChange}
      isError={!!isError}
      {...props}
    />
  )
}

const CustomInput = styled(Input)<{ isError: boolean }>`
  :placeholder-shown {
    font-style: italic;
  }
  border-radius: 10px;
  border: 2px solid
    ${({ isError }) => (isError ? errorRed : cardBorder)}!important;
  box-shadow: 0 0 5px 0 #00000026;
`
