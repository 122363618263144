import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { ProjectState } from '../../../../../types'
import { FilterOptionSelect } from '../../../../filters/types'
import { Autocomplete, TextField } from '@mui/material'

import { ThemeProvider } from '@mui/material/styles'
import { excludedFilterOptions, getThemeLink, theme } from '../utils'
import { result } from 'lodash'
import { focusFields } from '../../../../catalog/constants'
import { CATALOG_URL } from '../../../../../utils'
import mixpanel from '../../../../../../trackers/mixpanel'

type Props = {
  size?: 'small'
}

const Omnibar: React.FC<Props> = ({ size }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const isLLMDash = !!details.clientSettings['llm-dash']

  const options = useMemo(() => {
    const resMap: any = {}
    const result: any[] = []
    defaultFilterList
      .filter(
        (el) =>
          !!(el as FilterOptionSelect).values && focusFields.includes(el.field),
      )
      .flatMap((obj) =>
        (obj as FilterOptionSelect).values.map((value) => ({
          field: obj.field,
          alias: obj.alias,
          ...value,
        })),
      )
      .forEach((el) => {
        if (!resMap[el.value]) {
          resMap[el.value] = el
          // @ts-ignore
          result.push(el)
        }
      })
    return result
  }, [defaultFilterList])

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <StyledAutocomplete
          id="grouped-demo"
          options={options.sort(
            (a, b) =>
              a.alias.localeCompare(b.alias) || a.value.localeCompare(b.value),
          )}
          groupBy={(option: any) => option.alias}
          getOptionLabel={(option: any) => option.value}
          renderInput={(params: any) => (
            <TextField
              {...params}
              label="Search for Brands, Products, Sources, and Themes"
            />
          )}
          style={{ width: '100%', background: 'white', borderRadius: 8 }}
          onChange={(event: any, value: any) => {
            mixpanel.track('omnibar', {
              action: 'select',
              value: value.value,
              proj_uuid: projectId,
            })
            let url = `${
              window.location.origin
            }/project/${projectId}/${CATALOG_URL}?category=${
              value.field
            }&item=${encodeURIComponent(value.value)}`
            if (value.field === 'theme') {
              url = getThemeLink(value.value, projectId, isLLMDash)
            }
            window.open(url, '')
          }}
          value={null}
          // size={size}
          size={'small'}
        />
      </ThemeProvider>
    </Container>
  )
}

export default Omnibar

const Container = styled.div`
  //margin-bottom: 40px;
  width: 100%;
  //max-width: 850px;
`

const StyledAutocomplete = styled(Autocomplete)`
  * {
    font-family: 'Poppins', sans-serif;
  }

  label {
    font-size: 16px;
    margin-top: 0;
  }
`
