import React, { useCallback } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import { DatePicker, Form, Input, Tooltip, Button } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import { SourceProps } from '../source-providers'
import { tooltip } from 'utils/tooltip-data'

const dateFormat = 'MM/DD/YYYY'
// @ts-ignore
let FB = window.FB

export const Instagram = ({ formik, sourceType }: SourceProps) => {
  const connect = useCallback(async () => {
    new Promise((resolve, reject) => {
      FB.login(
        function (r: any) {
          if (r.authResponse) {
            resolve(r.authResponse)
          } else {
            reject(false)
          }
        },
        { scope: 'instagram_basic,pages_read_engagement,pages_show_list' }
      )
    })
      .then(
        (authResponse: any) =>
          new Promise((resolve, reject) => {
            FB.api(`${authResponse.userID}/accounts`, function (response: any) {
              if (response.data) {
                resolve(response.data)
              } else {
                reject(false)
              }
            })
          })
      )
      .then((pageObject: any) => {
        FB.api(
          `${pageObject[0].id}?fields=instagram_business_account`,
          function (response: any) {
            formik.setFieldValue('access_token', pageObject[0].access_token)
            formik.setFieldValue('page_id', pageObject[0].id)
          }
        )
      })
      .catch((e: any) => {
        console.log('Error', e)
        alert(
          'Something went wrong. Please try again from a new tab, or try again with a hard refresh (Shift-Ctrl-R)'
        )
      })
  }, [])

  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceTitle ? 'error' : ''}
        help={formik.errors.sourceTitle}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['form-date']}>
            <span>
              Date <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sourceDate ? 'error' : ''}
        help={formik.errors.sourceDate}
      >
        <DatePicker
          defaultValue={
            formik.values.sourceDate && moment(formik.values.sourceDate)
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue('sourceDate', value.toDate())
            }
          }}
          format={dateFormat}
        />
      </Form.Item>
      <ButtonWrapper>
        <Error>{formik.submitCount > 0 && formik.errors.access_token}</Error>
        <Button type="primary" size="large" onClick={connect}>
          Connect with Facebook
        </Button>
      </ButtonWrapper>
    </>
  )
}

const ButtonWrapper = styled.div`
  max-width: 200px;
  margin: 0 auto;
`
const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
`
