import { useQuery } from 'react-query'
import { fetchChartsForSearching } from 'features/project/features/Charts/model'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'

export const useChartList = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  return useQuery(
    ['dashboard-new-charts-search', projectId],
    () => fetchChartsForSearching(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
    },
  )
}
