import { useQuery } from 'react-query'
import { getProject } from '../model'

export const useProject = (projectId: string) => {
  return useQuery(['project', projectId], () => getProject(projectId), {
    refetchOnMount: false,
    staleTime: 1000 * 60 * 5,
    enabled: !!projectId,
  })
}
