import { useQuery } from 'react-query'
import { fetchFilterOptions } from '../react-query-functions'
import { useProjectStore } from '../projectStore/projectStore'
import { Filters } from '../types'
import { FilterOption } from '../features/filters/types'

export const useFilterList = (
  filterValues: Filters,
  selectedOption: string | undefined,
) => {
  const projectId = useProjectStore((state) => state.projectId)

  // TODO request just the one selected option field and cache the prior values
  return useQuery(
    ['filter-list-data', projectId, selectedOption, filterValues],
    async () => {
      return await fetchFilterOptions(
        projectId,
        selectedOption ? [selectedOption] : [],
        filterValues,
      )
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && !!selectedOption,
    },
  )
}
