import { toast } from 'react-toastify'
import { downloadPosts } from 'features/project/features/feedback'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import { useState } from 'react'
import {
  loadFilterPresets,
  saveFilterPreset,
} from 'features/project/features/filters/model'
import {
  ProjectOptionsResponse,
  RelativeDateFilterIdx,
} from 'features/project/features/filters/types'
import { AlertResponse } from 'features/project/features/alerts/model'
import { useQuery, useQueryClient } from 'react-query'
import { axiosInstance } from 'utils/axios'
import { POST_THEME_DRIVERS, THEME_SUMMARY } from 'utils/apiLinks'
import { ThemeDrivers } from 'features/project/features/Charts/components/ExportDrawer/types'

type Props = {
  comparativeIndex?: number
}
const useExport = ({ comparativeIndex }: Props) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const isPublicViewer = details.role === 'public_viewer'
  const relativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.relativeDateFilterIdx,
  )

  const [publicEmailIsValid, setPublicEmailIsValid] = useState<boolean>(false)

  const queryClient = useQueryClient()

  const downloadReviews = async (
    filterValues: Filters | undefined,
    publicViewerEmail: string | undefined,
    publicEmailIsValid: boolean,
  ) => {
    if (isPublicViewer && !publicEmailIsValid) {
      toast.error('Invalid email')
      return
    }
    try {
      await downloadPosts(projectId, {
        email: publicViewerEmail,
        ...(filterValues
          ? {
              search_terms: filterValues.searchQuery,
              search_criteria: filterValues.searchCondition,
              criteria: filterValues.values,
            }
          : {}),
      })
      toast.success('Success')
    } catch (e) {
      toast.error('Error')
    }
  }
  const { data, refetch } = useQuery(
    ['filter-preset', projectId],
    () => loadFilterPresets(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const savePreset = async (
    filterValues: Filters,
    presetName: string,
    saveRelativeDate: boolean,
  ) => {
    const newFilter = await saveFilterPreset(
      projectId,
      presetName,
      filterValues.values,
      filterValues.searchCondition,
      filterValues.searchQuery,
      saveRelativeDate
        ? relativeDateFilterIdx[comparativeIndex || 0]
        : RelativeDateFilterIdx.Unset,
    )

    await queryClient.refetchQueries(['filter-presets', projectId])

    // .then(() => {
    //   toast.success('Filter saved')
    //   // TODO mutate saved filters query instead
    //   //refetch()
    // })
    // .catch((error) => {
    //   toast.error(
    //     error.response.data.detail.msg || 'Error saving filter preset.',
    //   )
    // })
    // .finally(() => {
    //   queryClient.resetQueries(['filter-presets', projectId])
    //   setIsSavingPreset(false)
    // })
    return newFilter
  }

  return {
    downloadReviews,
    savePreset,
  }
}

export default useExport
