import {
  ChartTypes,
  downloadChartAsCSV,
  fontSizeDict,
  getAdjustedDateRangeString,
  getGroupedChartValues,
  matrixChartIds,
  onCopyChart,
} from '../utils'
import { Checkbox, Pagination, Select, Tooltip } from 'antd'
import { YogiButton } from 'components/UI/YogiButton'
import { DEFAULT_CHART_FONT_SIZE, findDateWindow } from '../../../utils'
import {
  CheckOutlined,
  ColumnWidthOutlined,
  LinkOutlined,
} from '@ant-design/icons'
import mixpanel from '../../../../trackers/mixpanel'
import {
  buttonBlue,
  navDefaultIconColor,
  secondaryGreen,
} from 'assets/styles/variables'
import { tooltip } from 'utils/tooltip-data'
import { ChartSettings } from 'features/project/features/Charts/components/ChartSettings/ChartSettings'
import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import {
  DashboardChartResponse,
  type TableChart as TableChartType,
  TimeInterval,
  UnionChart,
  ValuesChart,
} from '../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ExportData, Filters, ProjectState } from '../../../types'
import { FullscreenRounded } from '@mui/icons-material'
import { useFeatureFlags } from '../../../hooks/useFeatureFlags'
import CompareInput from './CompareInput'
import { SaveAndExportButton } from 'components/SaveAndExportButton/SaveAndExportButton'
import { CopyChartButton } from 'components/CopyChartButton/CopyChartButton'
import { orderNames } from '../../feedback/feedback-container'
import useExport from 'features/project/features/Charts/components/ExportDrawer/useExport'
import { getThemeDrivers } from 'features/project/features/Charts/components/ExportDrawer/model'

type Props = {
  wrapperRef: React.RefObject<HTMLDivElement>
  chartWrapperRef: React.RefObject<HTMLDivElement>
  currentChart: UnionChart
  comparativeIndex: number
  isDelta: boolean
  isUnlinked: boolean
  currentPage: number
  pageSize: number
  order: string
  toggleOrder: any
  onPageChange: any
  prevRange: any
  fontSize: any
  chartItem?: DashboardChartResponse
  localDashboardControls?: any
  updateLocalDashboardControls?: (key: string, value: any) => void
  isEditing?: boolean
  setChartId?: any
  isFullScreen?: boolean
  expandedCategories?: string[]
  sortedByCategory?: boolean
  comparePeriod?: string
  timeInterval?: TimeInterval
  isPreviousChartLoading?: boolean
  hideParentCategories?: boolean
  disableGrouping?: boolean
  tier1: string
  showCategories: boolean | undefined
  filterValues: Filters
}
const ChartTools: React.FC<Props> = ({
  wrapperRef,
  chartWrapperRef,
  currentChart,
  chartItem,
  localDashboardControls,
  updateLocalDashboardControls,
  isEditing,
  isDelta,
  isUnlinked,
  setChartId,
  currentPage,
  pageSize,
  order,
  toggleOrder,
  onPageChange,
  prevRange,
  fontSize,
  comparativeIndex,
  isFullScreen,
  expandedCategories,
  sortedByCategory,
  comparePeriod,
  timeInterval,
  isPreviousChartLoading,
  hideParentCategories,
  disableGrouping,
  tier1,
  showCategories,
  filterValues,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const dateWindows = useProjectStore((state) => state.dateWindows)
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )
  const setExportData = useProjectStore(
    (state: ProjectState) => state.setExportData,
  )
  const setIsExporting = useProjectStore(
    (state: ProjectState) => state.setIsExporting,
  )

  const { data: feature_flags } = useFeatureFlags()

  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls,
  )

  const chartValues = useMemo(() => {
    return getGroupedChartValues(
      currentChart,
      expandedCategories ?? [],
      !!sortedByCategory,
      tier1,
      order ?? 'desc',
      showCategories,
      disableGrouping,
    )
  }, [
    currentChart,
    expandedCategories,
    hideParentCategories,
    sortedByCategory,
    tier1,
    order,
    showCategories,
    disableGrouping,
    getGroupedChartValues,
  ])

  const [isCopying, setIsCopying] = useState(false)

  const copyFn = async () => {
    setIsCopying(true)
    mixpanel.track('chart download', {
      action: 'copy',
      ...route,
      value: currentChart.chart_id,
    })
    setTimeout(() => {
      setIsCopying(false)
    }, 5000)
    onCopyChart(wrapperRef, chartWrapperRef, currentChart.title).then(() => {
      // setIsCopying(false)
    })
  }

  const exportFn = () => {
    mixpanel.track('chart download', {
      action: 'image',
      ...route,
      value: currentChart.chart_id,
    })

    const exportData: ExportData = {
      comparativeIndex: comparativeIndex,
      filterValues: filterValues,
      downloadImage: () => {
        setIsExporting(true)
        onCopyChart(wrapperRef, chartWrapperRef, currentChart.title, true).then(
          () => {
            setIsExporting(false)
          },
        )
      },
      downloadCsv: !!(currentChart as ValuesChart).values
        ? () => downloadChartAsCSV(currentChart, chartValues)
        : undefined,
      downloadCsvWithThemeDrivers: !!(currentChart as ValuesChart).values
        ? () =>
            downloadChartAsCSV(
              currentChart,
              chartValues,
              getThemeDriversForCsvExport,
            )
        : undefined,
    }
    setExportData(exportData)
    setIsExportDrawerOpen(true)
  }

  const getThemeDriversForCsvExport = async () => {
    return await getThemeDrivers(
      projectId,
      currentChart.chart_id,
      filterValues,
      tier1,
      chartValues.map((el: { category: string }) => el.category),
    )
  }

  return (
    <Tools className={'tools'} data-html2canvas-ignore={'true'}>
      {(currentChart.chart_type === ChartTypes.BAR ||
        currentChart.chart_type === ChartTypes.STACK_BAR) &&
        !matrixChartIds.includes(currentChart.chart_id) && (
          <PageControls>
            <Pagination
              size={'small'}
              showSizeChanger
              responsive
              onChange={onPageChange}
              defaultCurrent={1}
              current={currentPage}
              total={chartValues?.length}
              pageSize={pageSize ?? chartValues?.length}
              pageSizeOptions={[5, 10, 20, 50, 100, chartValues?.length].filter(
                (el) => el <= chartValues?.length,
              )}
              // style={{ height: '100%' }}
            />
            <Tooltip title={'Asc/Desc'}>
              <YogiButton
                id="feedback-sort-button"
                type="default"
                onClick={toggleOrder}
                style={{ padding: '0 10px' }}
              >
                {orderNames[order] ?? orderNames['desc']}
              </YogiButton>
            </Tooltip>
          </PageControls>
        )}

      {feature_flags?.yoy_charts &&
        (currentChart.chart_type === ChartTypes.LINE ||
          currentChart.chart_type === ChartTypes.BAR) && (
          <CompareInput
            updateLocalDashboardControls={updateLocalDashboardControls}
            updateDashboardControls={updateDashboardControls}
            comparativeIndex={comparativeIndex}
            comparePeriod={comparePeriod}
            timeInterval={timeInterval}
            isPreviousChartLoading={isPreviousChartLoading}
          />
        )}

      {currentChart.chart_type === ChartTypes.TABLE && !!prevRange && (
        <>
          {currentChart.category_mapping && (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginRight: 5,
              }}
            >
              <Checkbox
                checked={
                  showCategories === undefined
                    ? tier1 === 'Theme'
                    : showCategories
                }
                onChange={(event) => {
                  mixpanel.track('chart config', {
                    action: 'show competitors/my brands',
                    value: event.target.checked,
                    ...route,
                  })

                  updateLocalDashboardControls
                    ? updateLocalDashboardControls(
                        'showCategories',
                        event.target.checked,
                      )
                    : updateDashboardControls(
                        comparativeIndex,
                        'showCategories',
                        event.target.checked,
                      )
                }}
                style={{ fontSize: 10 }}
              >
                <div style={{ fontSize: 10 }}>Group {tier1}s</div>
              </Checkbox>
            </div>
          )}
          <Tooltip
            title={
              <>
                <div>
                  {isDelta ? 'Show Difference: On' : 'Show Difference: Off'}
                </div>
                <div>
                  Comparison Period:{' '}
                  {findDateWindow(dateWindows, prevRange?.min, prevRange?.max)
                    ?.label ?? getAdjustedDateRangeString(prevRange)}
                </div>
              </>
            }
          >
            <div>
              <YogiButton
                type={isDelta ? 'primary' : 'dashed'}
                icon={<ColumnWidthOutlined />}
                onClick={() => {
                  mixpanel.track('toggle table chart', {
                    ...route,
                    value: currentChart.chart_id,
                  })
                  updateLocalDashboardControls
                    ? updateLocalDashboardControls('isDelta', !isDelta)
                    : updateDashboardControls(
                        comparativeIndex,
                        'isDelta',
                        !isDelta,
                      )
                }}
                style={{
                  marginRight: '10px',
                }}
              />
            </div>
          </Tooltip>
        </>
      )}
      {route.isDashboard && updateLocalDashboardControls && (
        <Tooltip
          title={`Unlinked charts can include data outside of the Dashboard Filter\n\nCurrent Status:${
            isUnlinked ? ' Unlinked' : ' Linked'
          }`}
          placement={'top'}
        >
          <div>
            <YogiButton
              type={isUnlinked ? 'dashed' : 'primary'}
              icon={<LinkOutlined />}
              onClick={() => {
                mixpanel.track('toggle component link', {
                  ...route,
                  value: !isUnlinked,
                })
                updateLocalDashboardControls('isUnlinked', !isUnlinked)
              }}
              style={{
                marginRight: '10px',
              }}
            />
          </div>
        </Tooltip>
      )}
      {!isEditing && (
        <>
          <Tooltip title={tooltip['copy-button']}>
            {isCopying ? (
              <CheckOutlined
                style={{
                  color: secondaryGreen,
                  boxShadow: 'none',
                  marginRight: '10px',
                  background: '#fff',
                }}
              />
            ) : (
              <CopyChartButton copyFn={copyFn} />
            )}
          </Tooltip>

          <SaveAndExportButton exportFn={exportFn} />

          {currentChart.other_charts?.length > 0 && (
            <Select
              style={{
                maxWidth: '200px',
                fontSize: fontSizeDict[fontSize || DEFAULT_CHART_FONT_SIZE],
                marginRight: 10,
              }}
              value={currentChart.title}
              onChange={(value) => {
                const foundChart = currentChart?.other_charts.find(
                  (c) => c.chart_id === value,
                )
                if (foundChart) {
                  setChartId
                    ? setChartId(foundChart.chart_id as string)
                    : updateDashboardControls(
                        0,
                        'chartId',
                        foundChart.chart_id as string,
                      )
                }
              }}
            >
              {currentChart.other_charts.map((chart) => (
                <Select.Option
                  id="chart-option"
                  value={chart.chart_id}
                  selected={chart.chart_id === currentChart?.chart_id}
                  key={chart.chart_id}
                  style={{
                    fontSize: fontSizeDict[fontSize || DEFAULT_CHART_FONT_SIZE],
                  }}
                >
                  {chart.title}
                </Select.Option>
              ))}
            </Select>
          )}
        </>
      )}
      {!!feature_flags?.allow_fullscreen && (
        <FullscreenRounded
          onClick={() => {
            updateLocalDashboardControls
              ? updateLocalDashboardControls('isFullScreen', !isFullScreen)
              : updateDashboardControls(
                  comparativeIndex,
                  'isFullScreen',
                  !isFullScreen,
                )
          }}
          style={{
            color: navDefaultIconColor,
            boxShadow: 'none',
            marginRight: route.isDashboard ? 10 : 0,
            cursor: 'pointer',
          }}
        />
      )}

      {chartItem && localDashboardControls && updateLocalDashboardControls && (
        <ChartSettings
          chartItem={chartItem}
          dashboardControls={localDashboardControls}
          updateDashboardControls={updateLocalDashboardControls}
        />
      )}
    </Tools>
  )
}

export default ChartTools

const Tools = styled.div`
  display: flex;
  align-items: center;
  * {
    font-size: var(--font-size-md) !important;
  }
`

const PageControls = styled.div`
  margin-right: 10px;
  padding-right: 10px;
  border-right: 1px solid lightgrey;
  display: flex;
  gap: 10px;
  align-items: center;

  .ant-pagination {
    align-items: center;
  }

  .ant-select {
    height: 32px;
  }

  .ant-select-selector {
    border-radius: 10px !important;
  }

  .ant-pagination-item-active {
    border-color: ${buttonBlue};

    a {
      color: ${buttonBlue};
    }
  }
`
