import React, { useState, useContext } from 'react'
import styled from 'styled-components'
import { Menu, Modal } from 'antd'
import { AuthContext } from 'features/auth'
import { ShareForm } from './share-form'
import { ShareDashboard } from './share-dashboard'
import { unshareProjectWithUser } from '../model'
import { UserAddOutlined } from '@ant-design/icons'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'

export const ShareButton = () => {
  const authContext = useContext(AuthContext)
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const details = useProjectStore((state: ProjectState) => state.details)
  const isUserAdmin = details.role === 'admin'
  const [visible, setVisible] = useState<string | null>(null)
  const isCharts = visible && visible === 'dashboard'
  const settings = details.clientSettings.project.pages

  if (!authContext.isAuthenticated || !settings) {
    return null
  }

  return (
    <Wrapper>
      <Menu id="share-tab" theme="dark" mode="inline" selectedKeys={[]}>
        <Menu.Item
          key="0"
          icon={<UserAddOutlined />}
          onClick={() => setVisible('dashboard')}
        >
          <StyledButton>{settings.share.alias}</StyledButton>
        </Menu.Item>
      </Menu>

      <Modal
        className="share-modal"
        footer={null}
        open={!!visible}
        title={isCharts ? 'Share Yogi Hub' : 'Create new user'}
        onCancel={() => setVisible(null)}
      >
        {isCharts && (
          <ShareDashboard
            projectId={projectId}
            onNext={(email: string) => setVisible(email)}
            isUserAdmin={isUserAdmin}
            onRemoveEmail={(email: string) =>
              unshareProjectWithUser(projectId, email)
            }
          />
        )}
        {!isCharts && (
          <ShareForm
            email={visible}
            projectId={projectId}
            isUserAdmin={isUserAdmin}
            onSuccess={() => setVisible('dashboard')}
          />
        )}
      </Modal>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  .ant-menu-item-active {
    color: inherit !important;
  }

  .ant-menu-item-active:hover {
    color: #fff !important;
  }
`

const StyledButton = styled.span`
  cursor: pointer;
  border: none;
`
