import React, { useState } from 'react'
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons'
import styled from 'styled-components'
import SelectItem from '../FilterPanelSelector/components/SelectItem/SelectItem'
import {
  AppliedFilterOption,
  ApplyGroupFilterPayload,
  FilterOptionValue,
} from '../../../../../types'
import NestedThemeInput from './components/NestedThemeInput'

type Props = {
  themes: FilterOptionValue[]
  floatValues: boolean
  currentAppliedFilter: AppliedFilterOption | undefined
  applySelect: (value: ApplyGroupFilterPayload['value']) => void
  isLoading: boolean
}

const NestedThemes: React.FC<Props> = ({
  themes,
  floatValues,
  applySelect,
  currentAppliedFilter,
  isLoading,
}) => {
  const topLevelThemes = new Set()
  const subThemeMap: Record<string, FilterOptionValue[]> = {}

  themes.forEach((el) => {
    const splitName = el.value.split(': ')
    if (splitName.length === 1) {
      topLevelThemes.add(splitName[0])
      subThemeMap[splitName[0]] = [el]
    } else if (splitName.length === 2) {
      topLevelThemes.add(splitName[0])
      if (!subThemeMap[splitName[0]]) {
        if (
          splitName[0] === 'Fans / Attractors' ||
          splitName[0] === 'Critics / Detractors'
        ) {
          subThemeMap[splitName[0]] = [{ ...el, value: splitName[0] }]
        } else {
          subThemeMap[splitName[0]] = [{ ...el, value: splitName[1] }]
        }
      } else {
        if (
          splitName[0] === 'Fans / Attractors' ||
          splitName[0] === 'Critics / Detractors'
        ) {
          subThemeMap[splitName[0]][0].n_posts =
            subThemeMap[splitName[0]][0].n_posts + el.n_posts
          subThemeMap[splitName[0]][0].identifiers = [
            ...subThemeMap[splitName[0]][0].identifiers,
            ...el.identifiers,
          ]
        } else {
          subThemeMap[splitName[0]].push({ ...el, value: splitName[1] })
        }
      }
    } else {
      // bad theme name do nothing
    }
  })

  // TODO maybe split this into two sets - with reviews / without reviews to mimic original behavior
  const orderedKeys = floatValues
    ? Object.keys(subThemeMap).sort((a, b) => {
        return (
          subThemeMap[b].reduce(
            (acc: number, el: FilterOptionValue) => acc + el.n_posts,
            0,
          ) -
          subThemeMap[a].reduce(
            (acc: number, el: FilterOptionValue) => acc + el.n_posts,
            0,
          )
        )
      })
    : Object.keys(subThemeMap)

  return (
    <div>
      {orderedKeys.map((theme) => (
        <NestedThemeInput
          key={theme}
          theme={theme}
          subthemes={subThemeMap[theme]}
          currentAppliedFilter={currentAppliedFilter}
          applySelect={applySelect}
          isLoading={isLoading}
        />
      ))}
    </div>
  )
}

export default NestedThemes
