import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import '../../animate-logo.css'
import widgetLoader from 'assets/2024/Loaders/pdpLoader_small.gif'

type Props = {
  children?: React.ReactNode
  style?: CSSProperties
  height?: number
}

export const WidgetLoader: React.FC<Props> = ({
  children,
  style,
  height = 300,
}) => {
  return (
    <LoaderWrapper style={{ ...style, height: height }}>
      <Gif src={widgetLoader} style={{ height: height + 5 }} />
    </LoaderWrapper>
  )
}
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  height: 100%;
  max-height: 300px;
  overflow: hidden;
  background: transparent;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`

const Gif = styled.img`
  height: calc(100% + 5px);
  max-height: 305px;
`
