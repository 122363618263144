import React from 'react'
import { VirtuosoGrid } from 'react-virtuoso'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { SourceCard } from '../../features/source/components'

type Props = {
  items: any[]
  handleOpen: () => void
  setEditIndex: (n: number) => void
  formik: any
}

const ProjectSourceVirtualizedList: React.FC<Props> = ({
  items,
  handleOpen,
  setEditIndex,
  formik,
}: any) => {
  const ProductSourceCard = ({ item, index }: any) => {
    return (
      <>
        {item.source ? (
          <SourceCard
            error={!!formik.errors.sources && !item.product_id}
            icon={item.source === 'File' ? 'CSV' : 'URL'}
            title={item.title}
            onClick={() => {
              handleOpen()
              setEditIndex(index)
            }}
          />
        ) : (
          <SourceCard
            icon={item.name[0]}
            title={item.name}
            onClick={() => {
              handleOpen()
              setEditIndex(index)
            }}
          />
        )}
      </>
    )
  }

  const GridItem = ({ children, ...props }: any) => {
    const { item, index } = children
    if (!item) return <div>Loading</div>
    return (
      <SourceCardWrapper key={item.id} {...props}>
        <ProductSourceCard item={item} index={index} />
      </SourceCardWrapper>
    )
  }

  // if (items.length < 5000) {
  //   return (
  //     <Grid container spacing={2} style={{ padding: 20, marginTop: -30 }}>
  //       {items?.map((product: any, index: number) => (
  //         <Grid item xs={12} sm={6} md={4} key={index} spacing={2}>
  //           <SourceCardWrapper key={product.id}>
  //             <ProductSourceCard item={product} index={index} />
  //           </SourceCardWrapper>
  //         </Grid>
  //       ))}
  //     </Grid>
  //   )
  // }

  return (
    <VirtuosoGrid
      totalCount={items.length}
      overscan={200}
      components={{
        Item: GridItem,
        List: React.forwardRef(({ style, children }, listRef) => (
          <div
            ref={listRef}
            style={{
              ...style,
              display: 'flex',
              flexWrap: 'wrap',
            }}
          >
            {children}
          </div>
        )),
      }}
      itemContent={(index) => ({
        item: items[index],
        index,
      })}
      style={{ height: '300px', width: '100%' }}
    />
  )
}

export default ProjectSourceVirtualizedList

const SourceCardWrapper = styled.div`
  flex-basis: 40%;
  padding: 10px;
  display: flex;

  height: 150px;
  flex-grow: 1;

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
`
