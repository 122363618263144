import React, { useState } from 'react'
import { useQuery } from 'react-query'
import { getSemanticReferences } from '../SemanticKeywords/model'
import { useProjectStore } from '../../features/project/projectStore/projectStore'
import { ProjectState } from '../../features/project/types'
import { Button } from 'antd'
import { YogiButton } from 'components/UI/YogiButton'
import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons'
import {
  Container,
  Footer,
  CarouselIndex,
} from './SemanticReviewCarousel.styles'

interface Props {
  phrase: string
  threshold?: number
  products?: string[]
  themes?: string[]
  brands?: string[]
  style?: React.CSSProperties
  // Override references
  references?: any
}

const SemanticReviewCarousel: React.FC<Props> = ({
  phrase,
  threshold,
  products,
  themes,
  brands,
  style,
  references,
}) => {
  // global state
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  // local state
  const [index, setIndex] = useState(0)

  // const needed for api
  const limit = undefined

  // api
  const {
    data: referencesData,
    isLoading,
    isError,
    refetch,
  } = useQuery(
    [
      'semanticReferences',
      projectId,
      phrase,
      brands,
      products,
      themes,
      limit,
      threshold,
    ],
    () =>
      getSemanticReferences(
        projectId,
        phrase,
        brands,
        products,
        themes,
        limit,
        threshold
      ),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId && !references,
      retry: false,
      onSuccess: (data) => {
        // if (data.length === 0 && dynamicThreshold > 0.1) {
        //   setDynamicThreshold((prev) => prev - 0.025)
        // }
      },
    }
  )

  // derived constants
  const activeReferences = references || referencesData

  // errors/early returns
  if (isError)
    return (
      <Container style={{ ...style }}>
        <div>Error fetching data</div>
        <Button onClick={() => refetch()}>Retry</Button>
      </Container>
    )

  if (!activeReferences || (!references && isLoading))
    return (
      <Container style={{ ...style }}>
        <div>Loading...</div>
      </Container>
    )

  // derived pt 2
  const reference = activeReferences[index]

  return (
    <Container style={{ ...style }}>
      <div
        style={{
          maxHeight: 200,
          minHeight: 75,
          overflow: 'auto',
        }}
      >
        {reference && (
          <>
            <p>"{reference?.text}"</p>
            {/*<p*/}
            {/*  dangerouslySetInnerHTML={{*/}
            {/*    __html: highlightSentence(reference?.text, phrase),*/}
            {/*  }}*/}
            {/*></p>*/}
            {reference?.sentiment && <p>Sentiment: {reference.sentiment}</p>}
          </>
        )}
      </div>
      <Footer>
        <YogiButton
          type={'primary'}
          onClick={() =>
            setIndex(
              (index - 1 + activeReferences.length) % activeReferences.length
            )
          }
        >
          <ArrowLeftOutlined />
        </YogiButton>
        <CarouselIndex>
          {index + 1} of {activeReferences.length}
        </CarouselIndex>
        <YogiButton
          type={'primary'}
          onClick={() => setIndex((index + 1) % activeReferences.length)}
        >
          <ArrowRightOutlined />
        </YogiButton>
      </Footer>
    </Container>
  )
}

export default SemanticReviewCarousel
