import React from 'react'

const HighlightSvgIcon: React.FC = () => (
  <svg
    width="18"
    height="15"
    viewBox="0 0 18 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.4523 1.57895H5.72727C5.49545 1.57895 5.30114 1.50329 5.14432 1.35197C4.9875 1.20066 4.90909 1.01316 4.90909 0.789474C4.90909 0.565789 4.9875 0.378289 5.14432 0.226974C5.30114 0.0756579 5.49545 0 5.72727 0H12.0886L10.4523 1.57895ZM7.17954 4.73684H3.27273C3.04091 4.73684 2.84659 4.66118 2.68977 4.50987C2.53295 4.35855 2.45455 4.17105 2.45455 3.94737C2.45455 3.72368 2.53295 3.53618 2.68977 3.38487C2.84659 3.23355 3.04091 3.15789 3.27273 3.15789H8.81591L7.17954 4.73684ZM3.90682 7.89474H0.818182C0.586364 7.89474 0.392045 7.81908 0.235227 7.66776C0.0784091 7.51645 0 7.32895 0 7.10526C0 6.88158 0.0784091 6.69408 0.235227 6.54276C0.392045 6.39145 0.586364 6.31579 0.818182 6.31579H5.54318L3.90682 7.89474ZM7.85455 6.31579L12.2727 10.5789L8.18182 14.5263C7.85455 14.8421 7.47273 15 7.03636 15C6.6 15 6.21818 14.8421 5.89091 14.5263L5.85 14.4868L5.56364 14.7632C5.48182 14.8421 5.38977 14.9013 5.2875 14.9408C5.18523 14.9803 5.07955 15 4.97045 15H2.20909C2.01818 15 1.88864 14.9211 1.82045 14.7632C1.75227 14.6053 1.78636 14.4605 1.92273 14.3289L3.80455 12.5132L3.76364 12.4737C3.43636 12.1579 3.27273 11.7895 3.27273 11.3684C3.27273 10.9474 3.43636 10.5789 3.76364 10.2632L7.85455 6.31579ZM9.02045 5.19079L13.0909 1.26316C13.4182 0.947368 13.8 0.789474 14.2364 0.789474C14.6727 0.789474 15.0545 0.947368 15.3818 1.26316L17.5091 3.31579C17.8364 3.63158 18 4 18 4.42105C18 4.8421 17.8364 5.21053 17.5091 5.52632L13.4386 9.45395L9.02045 5.19079Z"
      fill="currentColor"
    />
  </svg>
)

export default HighlightSvgIcon
