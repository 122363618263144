import React from 'react'
import { Table, Tooltip, Typography } from 'antd'
import styled from 'styled-components'
import { SourceTableAdmin } from './admin/source-table-admin'
import { InfoCircleOutlined } from '@ant-design/icons'
import { tooltip } from '../../../../../utils/tooltip-data'

type Props = {
  title: string
  description: string
  last_update_time: string
  item: string
  role: string
  source_uuid: string
  hierarchy: string
  state: string
  url: string
  refetch_data: any
}

const columns = [
  {
    title: 'Field',
    dataIndex: 'field',
    key: 'field',
    render: (text: string) => <b>{text}</b>,
  },
  {
    title: 'Value',
    dataIndex: 'value',
    key: 'value',
  },
]

export const SourceTableNonAdmin: React.FC<Props> = ({
  title,
  last_update_time,
  item,
  hierarchy,
  url,
  role,
}) => {
  let table_data = [
    {
      key: 'title',
      field: (
        <div>
          {' '}
          Title {''}{' '}
          <Tooltip title={tooltip['source-title']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      value: title,
    },
    {
      key: 'last_update_time',
      field: (
        <div>
          {' '}
          Last Update Time {''}{' '}
          <Tooltip title={tooltip['source-last-update']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      value: last_update_time,
    },
    {
      key: 'item',
      field: (
        <div>
          {' '}
          Item {''}{' '}
          <Tooltip title={tooltip['source-item']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      value: item,
    },
    {
      key: 'hierarchy',
      field: (
        <div>
          {' '}
          Hierarchy {''}{' '}
          <Tooltip title={tooltip['source-hierarchy']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      value: hierarchy,
    },
    {
      key: 'url',
      field: (
        <div>
          {' '}
          Url {''}{' '}
          <Tooltip title={tooltip['source-url-page']}>
            <InfoCircleOutlined />
          </Tooltip>{' '}
        </div>
      ),
      value: (
        <Tooltip title={url}>
          <Typography.Link
            style={{ width: 350 }}
            ellipsis
            target="_blank"
            rel="noopener noreferrer"
            href={url}
          >
            {url}
          </Typography.Link>
        </Tooltip>
      ),
    },
  ]
  return (
    <>
      <TableWrapper>
        <Table
          columns={columns}
          dataSource={table_data}
          size="small"
          bordered
          showHeader={false}
          pagination={false}
        />
      </TableWrapper>
    </>
  )
}

export const SourceTableDescription: React.FC<Props> = ({
  title,
  description,
  last_update_time,
  item,
  role,
  source_uuid,
  state,
  hierarchy,
  url,
  refetch_data,
}) => {
  if (role !== 'admin') {
    return (
      <SourceTableNonAdmin
        title={title}
        description={description}
        last_update_time={last_update_time}
        item={item}
        role={role}
        state={state}
        hierarchy={hierarchy}
        source_uuid={source_uuid}
        url={url}
        refetch_data={null}
      />
    )
  } else {
    return (
      <SourceTableAdmin
        title={title}
        description={description}
        last_update_time={last_update_time}
        item={item}
        role={role}
        state={state}
        hierarchy={hierarchy}
        source_uuid={source_uuid}
        url={url}
        refetch_data={refetch_data}
      />
    )
  }
}

const TableWrapper = styled.div`
  padding-top: 25px;
  display: flex;
  align-items: center;
  justify-content: center;
`
