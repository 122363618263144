import React from 'react'
import styled from 'styled-components'
import { CloseOutlined, ArrowLeftOutlined } from '@ant-design/icons'

type Props = {
  handleClose: () => void
  handleBack?: () => void
}

export const ModalHeader: React.FC<Props> = ({ handleClose, handleBack }) => {
  return (
    <>
      {handleBack && (
        <BackButton onClick={handleBack}>
          <ArrowLeftOutlined /> <span>Back</span>
        </BackButton>
      )}

      <CloseButton onClick={handleClose}>
        <CloseOutlined />
      </CloseButton>
    </>
  )
}

const BackButton = styled.div`
  position: absolute;
  top: 10px;
  left: 10px;
  font-size: var(--font-size-md);
  line-height: var(--font-size-md);
  cursor: pointer;
  display: flex;
  align-items: center;
  font-weight: 400;

  span {
    margin-left: 10px;
    font-size: var(--font-size-md);
    text-transform: capitalize;
  }
`
const CloseButton = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  font-size: var(--font-size-md);
  line-height: var(--font-size-md);
  cursor: pointer;
`
