import React from 'react'
import styled from 'styled-components'
import { YogiButton } from 'components/UI/YogiButton'
import mixpanel from 'features/trackers/mixpanel'
import { ArrowLeftOutlined, LoadingOutlined } from '@ant-design/icons'
import {
  buttonBlue,
  buttonHover,
  exportCardBackgroundHover,
  lightBlueFontColor,
  secondaryButtonBlue,
} from 'assets/styles/variables'
import { lightBlue } from '@mui/material/colors'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import { Modal } from 'antd'
import { useHistory } from 'react-router-dom'
import _ from 'lodash'

type Props = {
  isEdit?: boolean
  isSmart?: boolean
  dashboard?: any
  setView: (view: 'list' | 'view' | 'edit') => void
  setSharingDashboard?: () => void
  isExporting: boolean
  isPublic?: boolean
  isOwner?: boolean
  exportDashboard: () => void
  saveDashboard: () => void
  filterValues: Filters
}

export const DashboardEditorActions: React.FC<Props> = ({
  isEdit,
  isSmart,
  dashboard,
  setView,
  setSharingDashboard,
  isExporting,
  isPublic,
  isOwner,
  exportDashboard,
  saveDashboard,
  filterValues,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const history = useHistory()

  const dashboardId = dashboard?.dashboard?.id

  const showResetDashboard =
    dashboard &&
    !_.isEqual(dashboard?.dashboard.dashboard_settings.filters, filterValues)

  return (
    <Container>
      <Top>
        {/*<ExportButton onClick={exportDashboard}>*/}
        {/*  {isExporting && <LoadingOutlined />}*/}
        {/*  {isExporting ? 'Exporting Dashboard' : 'Export Dashboard'}*/}
        {/*</ExportButton>*/}
        {/*{(!dashboardId || dashboard?.dashboard?.author) && (*/}
        {/*  <>*/}
        {/*    {!isEdit && !isSmart ? (*/}
        {/*      <>*/}
        {/*        {!isPublic && (*/}
        {/*          <YogiButton*/}
        {/*            onClick={() => {*/}
        {/*              setSharingDashboard && setSharingDashboard()*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Share Dashboard*/}
        {/*          </YogiButton>*/}
        {/*        )}*/}
        {/*        {isOwner && (*/}
        {/*          <YogiButton*/}
        {/*            onClick={() => {*/}
        {/*              mixpanel.track('custom dashboard', {*/}
        {/*                action: 'edit',*/}
        {/*                value: dashboardId,*/}
        {/*              })*/}
        {/*              setView('edit')*/}
        {/*            }}*/}
        {/*          >*/}
        {/*            Edit Dashboard*/}
        {/*          </YogiButton>*/}
        {/*        )}*/}
        {/*      </>*/}
        {/*    ) : (*/}
        {/*      <>*/}
        {/*        /!*<YogiButton onClick={viewDashboard}>View Dashboard</YogiButton>*!/*/}
        {/*        <SaveButton onClick={saveDashboard} id={'save-dashboard'}>*/}
        {/*          Save Dashboard*/}
        {/*        </SaveButton>*/}
        {/*      </>*/}
        {/*    )}*/}
        {/*  </>*/}
        {/*)}*/}
        {/*<StartOverButton*/}
        {/*  onClick={() => {*/}
        {/*    mixpanel.track('custom dashboard', {*/}
        {/*      action: 'reset',*/}
        {/*      value: dashboardId,*/}
        {/*      proj_uuid: projectId,*/}
        {/*    })*/}
        {/*    resetDashboard()*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Reset*/}
        {/*</StartOverButton>*/}
      </Top>
      <Bottom>
        <SaveButton onClick={saveDashboard} id={'save-dashboard'}>
          Save Dashboard
        </SaveButton>
        {/*<ExportButton*/}
        {/*  onClick={() => {*/}
        {/*    mixpanel.track('custom dashboard', {*/}
        {/*      action: 'close',*/}
        {/*      value: dashboardId,*/}
        {/*      proj_uuid: projectId,*/}
        {/*    })*/}
        {/*    if (isEdit && (showResetDashboard || !dashboardId)) {*/}
        {/*      Modal.confirm({*/}
        {/*        title: 'Make sure to save your dashboard before closing',*/}
        {/*        content: 'Any unsaved changes will be lost',*/}
        {/*        onOk() {*/}
        {/*          setView('list')*/}
        {/*          history.push(`${window.location.pathname}`)*/}
        {/*        },*/}
        {/*      })*/}
        {/*    } else {*/}
        {/*      setView('list')*/}
        {/*      history.push(`${window.location.pathname}`)*/}
        {/*    }*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <ArrowLeftOutlined /> Back*/}
        {/*</ExportButton>*/}
      </Bottom>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  //height: 100%;
  flex-direction: column;
  justify-content: space-between;
  gap: 10px;
  width: 100%;
  padding: 0 10px;
  flex-grow: 1;
  margin-bottom: 20px;
`

const Top = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
`
const Bottom = styled.div`
  padding-bottom: 20px;
`

const ExportButton = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  font-size: 14px;

  border-radius: 10px;
  background: ${secondaryButtonBlue};
  color: ${lightBlueFontColor};

  cursor: pointer;

  &:hover {
    background: ${exportCardBackgroundHover};
  }
`
const SaveButton = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  font-size: 14px;

  border-radius: 10px;
  background: ${buttonBlue};
  color: white;

  cursor: pointer;

  &:hover {
    background: ${buttonHover};
  }
`

const StartOverButton = styled.div`
  width: 100%;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  padding: 15px 0;

  font-size: var(--font-size-md);

  border-radius: 10px;
  background: transparent;
  color: ${lightBlueFontColor};

  cursor: pointer;

  &:hover {
    color: ${buttonHover};
  }
`
