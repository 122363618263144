import { AxiosResponse } from 'axios'
import { axiosInstance } from 'utils/axios'
import { SavedView, SavedViewPayload } from './types'

const SAVED_VIEWS_BASE_URL = '/project/saved_views'
const SHARE_VIEW_URL = `${SAVED_VIEWS_BASE_URL}/share`

export const getSavedViews = async (
  proj_uuid: string
): Promise<SavedView[]> => {
  const { data } = await axiosInstance.get<SavedView[]>(SAVED_VIEWS_BASE_URL, {
    params: {
      proj_uuid: proj_uuid,
    },
  })

  return data
}

export const shareViewWithUsers = async (
  proj_uuid: string,
  view_id: string,
  users: string[]
): Promise<AxiosResponse> => {
  const data = await axiosInstance.post(
    SHARE_VIEW_URL,
    {
      view_id: view_id,
      user_list: users,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}

export const deleteSavedView = async (
  proj_uuid: string,
  view_uuid: string
): Promise<AxiosResponse> => {
  const data = await axiosInstance.delete(
    `${SAVED_VIEWS_BASE_URL}/${view_uuid}`,
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}

export const saveView = async (
  proj_uuid: string,
  view: SavedViewPayload
): Promise<AxiosResponse> => {
  const data = await axiosInstance.put(
    SAVED_VIEWS_BASE_URL,
    {
      ...view,
      search_string: view.search_url,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}

export const updateLastRecentlyUsed = async (
  proj_uuid: string,
  view_uuid: string
): Promise<AxiosResponse> => {
  const data = await axiosInstance.patch(
    `${SAVED_VIEWS_BASE_URL}/${view_uuid}`,
    {},
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}

export const updateSavedView = async (
  proj_uuid: string,
  view: any
): Promise<AxiosResponse> => {
  const data = await axiosInstance.patch(
    `${SAVED_VIEWS_BASE_URL}/update/${view.uuid}`,
    {
      ...view,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
