import React from 'react'

const CatalogSvgIcon: React.FC = () => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 15 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.5 0H1.5C0.75 0 0 0.675 0 1.5V3.7575C0 4.2975 0.3225 4.7625 0.75 5.025V13.5C0.75 14.325 1.575 15 2.25 15H12.75C13.425 15 14.25 14.325 14.25 13.5V5.025C14.6775 4.7625 15 4.2975 15 3.7575V1.5C15 0.675 14.25 0 13.5 0ZM9.75 9H5.25V7.5H9.75V9ZM13.5 3.75H1.5V1.5L13.5 1.485V3.75Z"
      fill="currentColor"
    />
  </svg>
)

export default CatalogSvgIcon
