import React from 'react'
import { cardBorderGrey } from '../../assets/styles/variables'
import styled from 'styled-components'
import { TextAreaProps } from 'antd/es/input'
import TextArea from 'antd/lib/input/TextArea'

type Props = TextAreaProps
export const YogiTextArea: React.FC<Props> = ({
  placeholder,
  value,
  onChange,
  style,
  ...rest
}) => {
  return (
    <CustomTextArea
      placeholder={placeholder}
      value={value}
      style={{
        border: '2px solid ' + cardBorderGrey,
        borderRadius: '6px',
        ...style,
      }}
      onChange={onChange}
      {...rest}
    />
  )
}

const CustomTextArea = styled(TextArea)`
  :placeholder-shown {
    font-style: italic;
  }
`
