import { AxiosResponse } from 'axios'
import { axiosInstance } from 'utils/axios'
import {
  DashboardResponse,
  NewDashboardPayload,
  UpdateDashboardPayload,
} from './types'
import {
  DashboardChartResponse,
  TableChart,
} from 'features/project/features/Charts/types'
const DASHBOARDS_BASE_URL = '/custom-dashboards'

export const getDashboards = async (
  proj_uuid: string,
): Promise<DashboardResponse[]> => {
  const { data } = await axiosInstance.get<DashboardResponse[]>(
    `${DASHBOARDS_BASE_URL}`,
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )

  return data
}

export const getDashboard = async (
  proj_uuid: string,
  id: number,
): Promise<DashboardResponse> => {
  const { data } = await axiosInstance.get<DashboardResponse>(
    `${DASHBOARDS_BASE_URL}/${id}`,
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )

  return data
}

export const getPublicDashboard = async (
  proj_uuid: string,
  dashboard_id: string,
): Promise<DashboardResponse> => {
  const { data } = await axiosInstance.get<DashboardResponse>(
    `${DASHBOARDS_BASE_URL}/${dashboard_id}`,
    {
      params: {
        proj_uuid: proj_uuid,
        is_public: true,
      },
    },
  )

  return data
}

export const saveDashboard = async (
  proj_uuid: string,
  body: NewDashboardPayload,
): Promise<DashboardResponse> => {
  const { data } = await axiosInstance.post(
    DASHBOARDS_BASE_URL,
    {
      ...body,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )
  return data
}

export const updateDashboard = async (
  proj_uuid: string,
  body: UpdateDashboardPayload,
): Promise<DashboardResponse> => {
  const { data } = await axiosInstance.patch(
    `${DASHBOARDS_BASE_URL}/${body.dashboard_id}`,
    {
      ...body,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )
  return data
}

export const exportDashboardToPPTX = (
  proj_uuid: string,
  payload: any,
  customParams?: any,
  //TODO add type
): any => {
  return axiosInstance.post(`${DASHBOARDS_BASE_URL}/export`, payload, {
    params: {
      proj_uuid: proj_uuid,
    },
    responseType: 'arraybuffer',
  })
}

export const deleteDashboard = async (
  proj_uuid: string,
  dashboard_id: number,
): Promise<AxiosResponse> => {
  const data = await axiosInstance.delete(
    `${DASHBOARDS_BASE_URL}/${dashboard_id}`,
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )
  return data
}
export const shareDashboard = async (
  proj_uuid: string,
  body: {
    id: number
    as_owners: boolean
    email_addresses: string[]
    message: string
  },
): Promise<AxiosResponse> => {
  const data = await axiosInstance.post(
    `${DASHBOARDS_BASE_URL}/${body.id}/share`,
    {
      shared_users_emails: body.email_addresses,
      as_owners: body.as_owners,
      is_shared: true,
      message: body.message,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )
  return data
}
export const unshareDashboard = async (
  proj_uuid: string,
  body: { dashboard_id: number; email_addresses: string[] },
): Promise<AxiosResponse> => {
  const data = await axiosInstance.post(
    `${DASHBOARDS_BASE_URL}/${body.dashboard_id}/unshare`,
    { shared_users_emails: body.email_addresses, is_shared: false },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    },
  )
  return data
}

export async function getPublicUrl(
  proj_uuid: string,
  dashboard_id: number,
): Promise<string> {
  const { data } = await axiosInstance.post(
    `${DASHBOARDS_BASE_URL}/${dashboard_id}/create_public_link`,
    {},
    {
      params: { proj_uuid },
    },
  )
  return data
}

export async function getImpactfulThemes(
  proj_uuid: string,
  item: string,
): Promise<DashboardChartResponse | undefined> {
  const { data } = await axiosInstance.post(
    `${DASHBOARDS_BASE_URL}/theme-browser`,
    { brand: item },
    {
      params: { proj_uuid },
    },
  )
  return data
}

export async function generateDashboard(
  proj_uuid: string,
  category: string,
  value: string,
  theme: string,
): Promise<any> {
  const body =
    category === 'brand'
      ? {
          focus: category,
          brand: value,
          theme: theme,
        }
      : {
          product: value,
          analysis: 'pdp',
        }

  const { data } = await axiosInstance.post(
    `${DASHBOARDS_BASE_URL}/automated-analysis`,
    body,
    {
      params: {
        proj_uuid,
      },
    },
  )
  return data
}
