import React from 'react'
import styled from 'styled-components'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'

type Props = { paddingTop?: string }
export const Fetcher: React.FC<Props> = ({ paddingTop = '' }) => (
  <LoaderWrapper paddingTop={paddingTop}>
    <LogoSpinner height={200} />
  </LoaderWrapper>
)

const LoaderWrapper = styled.div<{ paddingTop?: string }>`
  padding-top: ${({ paddingTop }) => (paddingTop ? paddingTop : '0')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 99;
`
const Spheres = styled.img`
  animation: spin 1s linear infinite;
  animation-direction: normal;
  @-webkit-keyframes spin {
    100% {
      -webkit-transform: rotate(360deg);
    }
  }
`
const Face = styled.img<{ paddingTop?: string }>`
  width: 50px;
  position: absolute;
  left: calc(50% - 25px);
  ${({ paddingTop }) => (paddingTop ? 'top: 50%;' : '')};
`
