import { customAlphabet } from 'nanoid'
import { FormProduct } from './types'
import { ProductForSource } from './features/source/types'

export const generateProductId = () =>
  parseInt(customAlphabet('1234567890', 16)())

export const transformProducts = (
  products: FormProduct[]
): ProductForSource[] => products.map((p) => ({ id: p.uuid, name: p.name }))
