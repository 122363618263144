import React, { useContext } from 'react'
import styled from 'styled-components'
import { AuthContext } from 'features/auth'
import {
  CampaignOutlined,
  ChatOutlined,
  FavoriteBorderOutlined,
  StarOutlineOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material'
import { CompassResponse } from '../../../../types'
import {
  primary,
  secondaryBlue,
  secondaryGreen,
  secondaryOrange,
  secondaryRed,
  secondaryYellow,
  starYellow,
} from 'assets/styles/variables'
import Divider from 'components/UI/YogiDivider'
import { useFeatureFlags } from '../../../../hooks/useFeatureFlags'
import Omnibar from 'features/project/features/Home/components/ReviewComponent/components/Omnibar'
import pluralize from 'pluralize'

const infoPanelObj = {
  '# Reviews & Ratings': {
    title: '# Reviews & Ratings',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  '# Sentences': {
    title: '# Sentences',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  '# Reviews': {
    title: '# Reviews',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  '# Ratings Only': {
    title: '# Ratings Only',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  'Average Rating': {
    title: 'Average Rating',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  'Average Sentiment': {
    title: 'Average Sentiment',
    icon: <FavoriteBorderOutlined style={{ padding: '1px' }} />,
    color: secondaryGreen,
  },
  'Promotional Reviews': {
    title: 'Promotional Reviews',
    icon: <CampaignOutlined />,
    color: secondaryOrange,
  },
  'Product Recommendations': {
    title: 'Product Recommendations',
    icon: <ThumbUpOutlined />,
    color: secondaryBlue,
  },
}

type Props = {
  data: CompassResponse
  greeting: string
  startDate: string
  endDate: string
  resizeRef: (instance: HTMLDivElement | null) => void
}
export const Header = ({
  data,
  greeting,
  startDate,
  endDate,
  resizeRef,
}: Props) => {
  const { data: feature_flags } = useFeatureFlags()
  const authContext = useContext(AuthContext)
  const username = authContext.user?.attributes.given_name

  const { summary_metrics: summaryMetrics, summary_panels: summaryPanels } =
    data

  return (
    <Container ref={resizeRef}>
      <Title>
        {greeting}, {username}
        {'  👋'}
      </Title>
      <SubTitle>
        Yogi analyzed{' '}
        <Bold>{summaryMetrics?.ratings_review_count?.toLocaleString()}</Bold>{' '}
        {summaryMetrics?.ratings_review_count === 1
          ? 'Review & Rating'
          : 'Reviews & Ratings'}{' '}
        across{' '}
        <Bold>
          {summaryMetrics?.products_count?.toLocaleString()}{' '}
          {/*Laundry & Fabric Care Products from{' '}*/}
          {pluralize('Product', summaryMetrics?.products_count)}
        </Bold>{' '}
        from{' '}
        <Bold>
          {summaryMetrics?.brands_count?.toLocaleString()}{' '}
          {pluralize('Brand', summaryMetrics?.brands_count)}
        </Bold>{' '}
        on{' '}
        <Bold>
          {summaryMetrics?.source_count?.toLocaleString()}{' '}
          {pluralize('Retailer', summaryMetrics?.source_count)}
        </Bold>{' '}
        between <Bold>{startDate}</Bold> and <Bold>{endDate}</Bold>
      </SubTitle>
      <InfoSection>
        <div
          style={{
            display: 'flex',
            gap: '10px',
            flexShrink: 0,
          }}
        >
          <InfoCardWrapper>
            {summaryPanels.map((el, index) => {
              if (!el.value && el.value !== 0) return
              // @ts-ignore
              const style = infoPanelObj[el.field]
              let color = style.color
              if (el.field === 'n_sentiment_avg') {
                if (el.value < -0.3) {
                  color = secondaryRed
                } else if (el.value < 0.3) {
                  color = secondaryYellow
                }
              }
              return (
                <InfoCard key={index}>
                  <ColorBar color={color} />
                  <Info>
                    <Data>
                      <Icon style={{ color: color }}>{style.icon}</Icon>{' '}
                      <span
                        style={{
                          fontSize: '16px',
                          lineHeight: '1.1',
                          fontWeight: '500',
                        }}
                      >
                        {el.field === '# Reviews & Ratings'
                          ? el?.value?.toLocaleString()
                          : el?.value?.toFixed(2)}
                      </span>
                    </Data>
                    <Label>{el?.field?.toUpperCase()}</Label>
                  </Info>
                  {/*<div style={{ padding: '10px 5px' }}>*/}
                  {/*  <Divider vertical />*/}
                  {/*</div>*/}
                  {/*<Tooltip title={'Change this week'}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      padding: '5px 10px 5px 5px',*/}
                  {/*      color: changeColor,*/}
                  {/*      display: 'flex',*/}
                  {/*      flexDirection: 'column',*/}
                  {/*      justifyContent: 'center',*/}
                  {/*      textAlign: 'center',*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    <div>*/}
                  {/*      {changeIcon}*/}
                  {/*      <div style={{ display: 'flex' }}>*/}
                  {/*        <div style={{ marginRight: '5px' }}>*/}
                  {/*          {el.value_change}*/}
                  {/*        </div>*/}
                  {/*        <div>({el.week_change_percent.toFixed(2) + '%'})</div>*/}
                  {/*      </div>*/}
                  {/*    </div>*/}
                  {/*  </div>*/}
                  {/*</Tooltip>*/}
                  {/*<div style={{ padding: '10px 5px' }}>*/}
                  {/*  <Divider vertical />*/}
                  {/*</div>*/}
                  {/*<Tooltip title={'Percent change this week'}>*/}
                  {/*  <div*/}
                  {/*    style={{*/}
                  {/*      padding: '5px 10px 5px 5px',*/}
                  {/*      color: percentChangeColor,*/}
                  {/*      display: 'flex',*/}
                  {/*      flexDirection: 'column',*/}
                  {/*      justifyContent: 'center',*/}
                  {/*      textAlign: 'center',*/}
                  {/*    }}*/}
                  {/*  >*/}
                  {/*    {percentChangeIcon}*/}
                  {/*    {}%*/}
                  {/*  </div>*/}
                  {/*</Tooltip>*/}
                </InfoCard>
              )
            })}
          </InfoCardWrapper>
        </div>
        {feature_flags?.verbatim_homepage && (
          <>
            <Divider vertical />
            <div
              style={{
                width: '100%',
              }}
            >
              <Omnibar />
            </div>
          </>
        )}
      </InfoSection>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  width: 100%;
  padding: 0 10px;
  margin-top: 50px;
  max-width: 1400px;
`

const Bold = styled.span`
  font-weight: 600;
`

const InfoCardWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  width: 100%;
  overflow: auto;
  gap: 15px;
`
const InfoCard = styled.div<{ color?: string }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;

  background: #fcfafa;
  border: 1px solid #eff0f6;
  box-shadow:
    0 1px 0 rgba(27, 31, 35, 0.04),
    inset 0 1px 0 rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  color: ${(props) => (props.color ? props.color : '#28364F')};
`
const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
`
const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  gap: 5px;
`
const Data = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow: visible;
  width: 100%;
  gap: 10px;
`
const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  overflow: visible;
  width: 100%;
  color: #8a8c8f;
  font-size: var(--font-size-md);
  font-weight: 500;
  line-height: 1;
`
const Icon = styled.div`
  display: flex;
  font-size: 14px;
`
const Title = styled.div`
  font-size: 22px;
  font-weight: 600;
  line-height: 26.63px;
  letter-spacing: 0.3199999928474426px;
  text-align: left;
  color: ${primary};
  margin-bottom: 32px;
`
const SubTitle = styled.div`
  color: #5072a4;
  font-size: 18px;
  font-weight: 300;
  line-height: 32px;
  letter-spacing: 0.25px;
  text-align: left;
  margin-bottom: 10px;
`
const InfoSection = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  margin-top: 5px;
  gap: 20px;
`
