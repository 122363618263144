import { assign, createMachine } from 'xstate'

export enum ProjectStates {
  SELECTOR = 'selector',
  UPLOAD = 'upload',
  FORM = 'form',
  SOURCE = 'source',
  PRODUCT = 'product',
}

export enum ProjectActions {
  BACK = 'back',
  SUBMIT = 'submit',
}

const EnableBackButtonAction = (target: string) => ({
  target,
  actions: assign({ backButton: true }),
})

const DisableBackButtonAction = (target: string) => ({
  [ProjectActions.BACK]: {
    target,
    actions: assign({ backButton: false }),
  },
})

export const projectMachine = createMachine<any, any>({
  id: 'project',
  initial: ProjectStates.SELECTOR,
  context: { backButton: false },
  states: {
    [ProjectStates.SELECTOR]: {
      on: {
        [ProjectStates.FORM]: EnableBackButtonAction(ProjectStates.FORM),
        [ProjectStates.UPLOAD]: EnableBackButtonAction(ProjectStates.UPLOAD),
      },
    },
    [ProjectStates.FORM]: {
      on: {
        [ProjectStates.SOURCE]: EnableBackButtonAction(ProjectStates.SOURCE),
        [ProjectStates.PRODUCT]: EnableBackButtonAction(ProjectStates.PRODUCT),
        ...DisableBackButtonAction(ProjectStates.SELECTOR),
      },
    },
    [ProjectStates.SOURCE]: {
      on: {
        [ProjectActions.SUBMIT]: ProjectStates.FORM,
        ...DisableBackButtonAction(ProjectStates.FORM),
      },
    },
    [ProjectStates.PRODUCT]: {
      on: {
        [ProjectActions.SUBMIT]: ProjectStates.FORM,
        ...DisableBackButtonAction(ProjectStates.FORM),
      },
    },
    [ProjectStates.UPLOAD]: {
      on: {
        [ProjectStates.FORM]: ProjectStates.FORM,
        ...DisableBackButtonAction(ProjectStates.SELECTOR),
      },
    },
  },
})
