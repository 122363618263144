import {
  initialState,
  useProjectStore,
} from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import { useHistory } from 'react-router'
import React, { useEffect, useState } from 'react'
import {
  DashboardResponse,
  DashboardSettings,
  DashboardType,
} from 'features/project/features/dashboard/types'
import { Layout } from 'react-grid-layout'
import {
  getDashboards,
  saveDashboard,
  updateDashboard,
} from 'features/project/features/dashboard/model'
import { toast } from 'react-toastify'
import { AppliedFilterOptionSelect } from 'features/project/features/filters/types'
import { Loader } from 'shared/components'
import { pageBackgroundGrey, superLightGrey } from 'assets/styles/variables'
import { YogiButton } from 'components/UI/YogiButton'
import mixpanel from 'features/trackers/mixpanel'
import { Button, Tooltip } from 'antd'
import { DASHBOARD_URL, trimText } from 'features/project/utils'
import { PlusOutlined } from '@ant-design/icons'
import { Fetcher } from 'shared/components/fetcher'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { WidgetLoader } from 'components/Loading/WidgetLoader/WidgetLoader'

type Props = {
  comparativeIndex: number | undefined
  filterValues: Filters
  setIsVisible: (isVisible: boolean) => void
}

export const AddToDashboardContent: React.FC<Props> = ({
  comparativeIndex,
  filterValues,
  setIsVisible,
}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const chartData = useProjectStore(
    (state: ProjectState) => state.chartData[comparativeIndex ?? 0],
  )
  const dashboardControls = useProjectStore(
    (state: ProjectState) => state.dashboardControls[0],
  )
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )

  const history = useHistory()

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
  const [confirmingDashboard, setConfirmingDashboard] = useState<any>(null)

  const {
    data: dashboards,
    refetch: refetchDashboards,
    isLoading: dashboardsLoading,
  } = useQuery(
    ['custom-dashboards', projectId],
    () => {
      return getDashboards(projectId)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    },
  )

  const maxTextLength = 50

  // TODO separate into own component
  const addToExistingDash = (dashboard: DashboardType) => {
    setIsSubmitting(true)
    const newDashboardItem = {
      type: 'chart',
      dashboardControls: dashboardControls,
      filterValues: filterValues,
      content: {
        chart_ids: [{ chart_id: dashboardControls.chartId }],
      },
    }
    const tempDashboard = JSON.parse(JSON.stringify(dashboard))
    const lastDashboardItem = tempDashboard.dashboard_settings.layout.sort(
      (a: Layout, b: Layout) => b.y - a.y,
    )[0]
    const lastDashboardIndex = tempDashboard.dashboard_settings.layout.sort(
      (a: Layout, b: Layout) => Number(b.i) - Number(a.i),
    )[0]
    const newDashboardLayout = {
      h: 9,
      i: lastDashboardIndex
        ? (Number(lastDashboardIndex?.i) + 1).toString()
        : '0',
      w: 12,
      x: 0,
      y: lastDashboardItem ? lastDashboardItem.h + lastDashboardItem.y : 0,
      minH: 4,
      minW: 4,
      moved: false,
      static: false,
      isDraggable: true,
    }

    const updatedDashboardSettings = JSON.parse(
      JSON.stringify(dashboard.dashboard_settings),
    )
    updatedDashboardSettings.layout.push(newDashboardLayout)
    updatedDashboardSettings.items.push(newDashboardItem)

    const updatedDash = updateDashboard(projectId, {
      dashboard_id: dashboard.id,
      name: dashboard.name,
      dashboard_settings: updatedDashboardSettings,
    })
      .then((res) => {
        toast.success('Chart added to dashboard')
      })
      .catch((err) => {
        console.log(err)
        toast.error('Error adding chart to dashboard')
      })
      .finally(() => {
        setIsSubmitting(false)
        setConfirmingDashboard(null)
        setIsVisible(false)
        refetchDashboards()
      })
  }

  const dashboardFiltersWillModifyChart = (
    dashboardFilters: Filters,
    chartFilters: Filters,
  ) => {
    // if there are more filters on the dashboard than the chart, then the chart will be modified
    if (dashboardFilters.values.length > chartFilters.values.length) return true
    // if, in a filter shared by dash + chart, the chart has a value that is not in the dashboard, then the chart will be modified
    // what should happen though? should the global filters be applied to the chart? or should the chart be added to the dashboard without the global filters?
    const subFilterMatch = dashboardFilters.values.some((dashboardFilter) => {
      const match = chartFilters.values.find(
        (chartFilter) => chartFilter.field === dashboardFilter.field,
      )
      // TODO range filters
      if (
        !(match as AppliedFilterOptionSelect)?.values?.every((r) =>
          (dashboardFilter as AppliedFilterOptionSelect).values.includes(r),
        )
      ) {
        // perform filter modification here?
        // probably better to perform filter modification in the mergeFilters function
        return true
      }
    })
    if (subFilterMatch) return true

    return false
  }

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        maxHeight: 300,
        overflow: 'auto',
      }}
    >
      {dashboardsLoading && <LogoSpinner height={100} />}
      {confirmingDashboard && (
        <div
          style={{
            position: 'absolute',
            height: 'calc(100% - 25px)',
            width: '100%',
            zIndex: 100,
            background: 'rgba(255, 255, 255, 0.9)',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            top: 12,
            left: 0,
            padding: 20,
          }}
        >
          <div
            style={{
              textAlign: 'center',
              background: pageBackgroundGrey,
              padding: 10,
              borderRadius: 8,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              gap: 20,
              boxShadow: '1px 1px 4px 0 rgba(0, 0, 0, 0.15)',
            }}
          >
            <div
              style={{
                textAlign: 'center',
              }}
            >
              <span style={{ fontWeight: 500 }}>
                {confirmingDashboard.name}
              </span>{' '}
              has Global Filters that will be applied to this Chart. Do you
              still wish to add this Chart to this Dashboard?
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                gap: 20,
              }}
            >
              <YogiButton onClick={() => setConfirmingDashboard(null)}>
                Cancel
              </YogiButton>
              <YogiButton
                type={'primary'}
                onClick={() => addToExistingDash(confirmingDashboard)}
              >
                Add
              </YogiButton>
            </div>
          </div>
        </div>
      )}
      {dashboards &&
        dashboards
          // .sort((a, b) => a.name.localeCompare(b.name))
          .map(({ dashboard }: DashboardResponse) => (
            <ViewWrapper key={dashboard.id}>
              <ViewButton
                type="text"
                // icon={viewImages[view.page]}
                onClick={() => {
                  mixpanel.track('charts', {
                    action: 'add to dashboard',
                    value: dashboard.name,
                    location: 'toolbar',
                    ...route,
                  })
                  // do new chart filters fit into dashboard filters?
                  // if dashboard has a filter that the new chart doesnt, then prompt
                  if (
                    dashboardFiltersWillModifyChart(
                      dashboard.dashboard_settings.filters,
                      filterValues,
                    )
                  ) {
                    setConfirmingDashboard(dashboard)
                    return
                  }
                  addToExistingDash(dashboard)
                  // if dashboard filter doesn't contain a filter that the new chart does, then prompt
                  // repeat for text search terms?
                }}
                // style={{
                //   color:
                //     view.search_url === window.location.search
                //       ? 'blue'
                //       : 'black',
                // }}
              >
                {dashboard.name.length > maxTextLength ? (
                  <Tooltip title={dashboard.name}>
                    <ViewName>
                      {trimText(dashboard.name, maxTextLength)}
                    </ViewName>
                  </Tooltip>
                ) : (
                  <ViewName>{trimText(dashboard.name, maxTextLength)}</ViewName>
                )}
              </ViewButton>
            </ViewWrapper>
          ))}

      <NewWrapper>
        <ViewButton
          type="text"
          icon={<PlusOutlined />}
          onClick={() => {
            mixpanel.track('charts', {
              action: 'create dashboard',
              location: 'toolbar',
              ...route,
            })
            setIsSubmitting(true)
            const newDashboardItem = {
              type: 'chart',
              dashboardControls: dashboardControls,
              filterValues: filterValues,
              content: {
                chart_ids: [{ chart_id: dashboardControls.chartId }],
              },
            }
            const newDashboardLayout: Layout = {
              h: 9,
              i: '0',
              w: 12,
              x: 0,
              y: 0,
              minH: 4,
              minW: 4,
              moved: false,
              static: false,
              isDraggable: true,
            }

            const dashboardSettings: DashboardSettings = {
              // filters: filterValues,
              filters: initialState.filters[0],
              items: [],
              layout: [],
            }
            dashboardSettings.layout.push(newDashboardLayout)
            dashboardSettings.items.push(
              //@ts-ignore
              newDashboardItem,
            )
            saveDashboard(projectId, {
              name: chartData.charts[0].title,
              dashboard_settings: dashboardSettings,
              is_shared: false,
            })
              .then((res) => {
                // toast.success('New Dashboard Created')
                refetchDashboards().then(() => {
                  history.push(
                    `${DASHBOARD_URL}?dashboard_id=${res.dashboard.id}`,
                  )
                  setIsSubmitting(false)
                  setIsExportDrawerOpen(false)
                })
              })
              .catch((err) => {
                setIsSubmitting(false)
                toast.error('Error creating Dashboard')
              })
          }}
          // style={{
          //   color:
          //     view.search_url === window.location.search
          //       ? 'blue'
          //       : 'black',
          // }}
        >
          <ViewName>Create New Dashboard</ViewName>
        </ViewButton>
      </NewWrapper>

      {/* TODO Overlay doesn't cover add buttons*/}
      {isSubmitting && (
        <Overlay>
          <WidgetLoader height={100} />
        </Overlay>
      )}
    </div>
  )
}

const ViewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  background: ${superLightGrey};
  margin-bottom: 8px;
  border-radius: 8px;
`

const NewWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  border-radius: 8px;
`

const ViewButton = styled(Button)`
  width: 100%;
  text-align: left;
  display: flex;
  align-items: center;
  height: 40px;
`
const ViewName = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  margin-left: 10px;
  display: flex;
  align-items: center;
`
const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 30%;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
