import React, { FC } from 'react'
import styled from 'styled-components'
import { HyperGrid } from './HyperCopy/HyperGrid'
import {
  CampaignOutlined,
  ChatOutlined,
  FavoriteBorderOutlined,
  StarOutlineOutlined,
  ThumbUpOutlined,
} from '@mui/icons-material'
import {
  secondaryBlue,
  secondaryGreen,
  secondaryOrange,
  starYellow,
} from '../assets/styles/variables'
import { PartitionOutlined } from '@ant-design/icons'

const infoPanelObj = {
  n_reviews_and_rating: {
    title: '# Reviews & Ratings',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  n_fragments: {
    title: '# Sentences',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  n_posts: {
    title: '# Reviews',
    icon: <ChatOutlined style={{ padding: '1px' }} />,
  },
  n_rating_only: {
    title: '# Ratings Only',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  n_rating_avg: {
    title: 'Average Rating',
    icon: <StarOutlineOutlined />,
    color: starYellow,
  },
  n_sentiment_avg: {
    title: 'Average Sentiment',
    icon: <FavoriteBorderOutlined style={{ padding: '1px' }} />,
    color: secondaryGreen,
  },
  n_promo_posts: {
    title: 'Promotional Reviews',
    icon: <CampaignOutlined />,
    color: secondaryOrange,
  },
  n_recommended_posts: {
    title: 'Product Recommendations',
    icon: <ThumbUpOutlined />,
    color: secondaryBlue,
  },
  n_themes_total: {
    title: '# Related Themes',
    icon: <PartitionOutlined style={{ fontSize: '20px' }} />,
  },
}
export interface InfoCardProps {
  item: any
}

export const InfoCard: FC<InfoCardProps> = ({ item }) => {
  // @ts-ignore
  const style = infoPanelObj[item.field]
  if (!style) return <></>
  let color = style.color
  return (
    // <InfoCardWrapper elementName={'summary-info-card'}>
    <InfoCardWrapper>
      <ColorBar color={color} />
      <Info>
        <Data style={{ height: '24px' }}>
          <Icon style={{ color: color }}>{style.icon}</Icon>{' '}
          <span
            style={{
              fontSize: '18px',
              lineHeight: '1.1',
              fontWeight: '500',
            }}
          >
            {item.value?.toLocaleString()}
          </span>
        </Data>
        <Label>{style.title}</Label>
      </Info>
    </InfoCardWrapper>
  )
}

export const InfoCardWrapper = styled.div<{ color?: string }>`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  overflow: hidden;
  flex-shrink: 0;

  background: #fcfafa;
  border: 1px solid #eff0f6;
  box-shadow: 0px 1px 0px rgba(27, 31, 35, 0.04),
    inset 0px 1px 0px rgba(255, 255, 255, 0.25);
  border-radius: 12px;

  color: ${(props) => (props.color ? props.color : '#28364F')};
`
const ColorBar = styled.div<{ color?: string }>`
  width: 8px;
  background: ${(props) => (props.color ? props.color : '#28364F')};
`
const Info = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
  padding: 10px;
  gap: 5px;
`
const Data = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: row;
  overflow: visible;
  width: 100%;
  gap: 10px;
`
const Icon = styled.div`
  display: flex;
  font-size: 14px;
`
const Label = styled.div`
  display: flex;
  justify-content: flex-start;
  flex-direction: row;
  overflow: visible;
  width: 100%;
  color: #8a8c8f;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
`
