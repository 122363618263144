import React, { CSSProperties } from 'react'
import styled from 'styled-components'
import '../../animate-logo.css'
import defaultLoader from 'assets/2024/Loaders/ChartLoader_2.gif'

type Props = {
  children?: React.ReactNode
  style?: CSSProperties
  height?: number
}

export const LogoSpinner: React.FC<Props> = ({
  children,
  style,
  height = 300,
}) => {
  return (
    <LoaderWrapper style={{ ...style, height: height }}>
      <Gif src={defaultLoader} style={{ height: height + 5 }} />
      {/*<svg*/}
      {/*  id="loading-spinner"*/}
      {/*  width="328"*/}
      {/*  height="328"*/}
      {/*  viewBox="0 0 328 328"*/}
      {/*  fill="none"*/}
      {/*  xmlns="http://www.w3.org/2000/svg"*/}
      {/*>*/}
      {/*  <g id="left">*/}
      {/*    <path*/}
      {/*      d="M149.454 274.58V175.755C149.454 173.631 148.384 171.649 146.607 170.484L62.4832 115.323C56.965 111.705 50.301 117.924 53.5302 123.679L81.6779 173.84C82.7778 175.8 82.7508 178.197 81.6071 180.132L62.7489 212.032C60.3304 216.123 63.137 221.317 67.8843 221.536L105.046 223.25C107.265 223.353 109.266 224.615 110.313 226.573L137.594 277.554C140.687 283.335 149.454 281.137 149.454 274.58Z"*/}
      {/*      fill="url(#paint0_linear_220_1342)"*/}
      {/*    />*/}
      {/*  </g>*/}
      {/*  <g id="right">*/}
      {/*    <path*/}
      {/*      d="M185.752 275.336V176.812C185.752 174.749 186.761 172.817 188.453 171.639L269.884 114.951C275.317 111.169 282.161 117.246 279.048 123.088L252.158 173.55C251.101 175.533 251.181 177.929 252.366 179.839L271.914 211.321C274.421 215.358 271.728 220.612 266.987 220.934L229.812 223.461C227.628 223.609 225.678 224.88 224.66 226.818L197.635 278.267C194.573 284.095 185.752 281.919 185.752 275.336Z"*/}
      {/*      fill="url(#paint1_linear_220_1342)"*/}
      {/*    />*/}
      {/*  </g>*/}
      {/*  <g id="top">*/}
      {/*    <path*/}
      {/*      d="M87.0317 85.9692L164.581 138.737C166.698 140.178 169.477 140.194 171.611 138.778L250.905 86.1718C256.631 82.3733 253.01 73.4565 246.257 74.7247L204.145 82.6337C201.676 83.0975 199.167 82.0503 197.76 79.9685L173.533 44.1244C170.974 40.3377 165.361 40.4486 162.954 44.3334L141.017 79.7256C139.646 81.9387 137.055 83.0806 134.496 82.5996L91.742 74.5635C85.0289 73.3017 81.3845 82.1266 87.0317 85.9692Z"*/}
      {/*      fill="url(#paint2_linear_220_1342)"*/}
      {/*    />*/}
      {/*  </g>*/}
      {/*  <defs>*/}
      {/*    <linearGradient*/}
      {/*      id="paint0_linear_220_1342"*/}
      {/*      x1="279.842"*/}
      {/*      y1="66.8304"*/}
      {/*      x2="-8.47517"*/}
      {/*      y2="173.595"*/}
      {/*      gradientUnits="userSpaceOnUse"*/}
      {/*    >*/}
      {/*      <stop stopColor="#CD6C5C" />*/}
      {/*      <stop offset="1" stopColor="#FAB49B" />*/}
      {/*    </linearGradient>*/}
      {/*    <linearGradient*/}
      {/*      id="paint1_linear_220_1342"*/}
      {/*      x1="279.841"*/}
      {/*      y1="66.8434"*/}
      {/*      x2="-6.02359"*/}
      {/*      y2="171.447"*/}
      {/*      gradientUnits="userSpaceOnUse"*/}
      {/*    >*/}
      {/*      <stop stopColor="#FAB49B" />*/}
      {/*      <stop offset="1" stopColor="#CD6C5C" />*/}
      {/*    </linearGradient>*/}
      {/*    <linearGradient*/}
      {/*      id="paint2_linear_220_1342"*/}
      {/*      x1="276.808"*/}
      {/*      y1="74.4769"*/}
      {/*      x2="145.095"*/}
      {/*      y2="179.047"*/}
      {/*      gradientUnits="userSpaceOnUse"*/}
      {/*    >*/}
      {/*      <stop stopColor="#CD6C5C" />*/}
      {/*      <stop offset="1" stopColor="#FAB49B" />*/}
      {/*    </linearGradient>*/}
      {/*  </defs>*/}
      {/*</svg>*/}
      {/*<LoaderTitle>{children}</LoaderTitle>*/}
    </LoaderWrapper>
  )
}
const LoaderWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  z-index: 100;
  height: 100%;
  max-height: 300px;
  overflow: hidden;
  background: transparent;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
`

const Gif = styled.img`
  height: calc(100% + 5px);
  max-height: 305px;
`
