import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Form, Input, Tooltip, DatePicker } from 'antd'
import { InfoCircleOutlined } from '@ant-design/icons'
import moment from 'moment'
import { sourceTypes } from 'utils/source_types'
import { FormikType } from '../new-source'
import { extractUrl } from '../../utils'
import { tooltip } from 'utils/tooltip-data'

type Props = {
  formik: FormikType
  sourceType: string
}
const dateFormat = 'MM/DD/YYYY'

export const Reamaze = ({ formik, sourceType }: Props) => {
  const sourceUrlValue = useMemo(
    () => extractUrl(formik.values.sourceUrl, sourceTypes[sourceType]),
    [formik.values.sourceUrl]
  )
  return (
    <>
      <Form.Item
        label={
          <Tooltip title={tooltip['source-form-title']}>
            <span>
              Source Title <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceTitle ? 'error' : ''}
        help={formik.errors.sourceTitle}
        wrapperCol={{ span: 10 }}
      >
        <Input
          name="sourceTitle"
          onChange={formik.handleChange}
          value={formik.values.sourceTitle}
          placeholder="Insert title here"
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title={tooltip['form-date']}>
            <span>
              Date <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        wrapperCol={{ span: 16 }}
        validateStatus={!!formik.errors.sourceDate ? 'error' : ''}
        help={formik.errors.sourceDate}
      >
        <DatePicker
          defaultValue={
            formik.values.sourceDate && moment(formik.values.sourceDate)
          }
          onChange={(value) => {
            if (value) {
              formik.setFieldValue('sourceDate', value.toDate())
            }
          }}
          format={dateFormat}
        />
      </Form.Item>
      <Form.Item
        label={
          <Tooltip title="Provide the URL of the account.">
            <span>
              URL <InfoCircleOutlined />
            </span>
          </Tooltip>
        }
        validateStatus={!!formik.errors.sourceUrl ? 'error' : ''}
        help={formik.errors.sourceUrl}
        wrapperCol={{ span: 16 }}
      >
        <Flex>
          {sourceTypes[sourceType]}
          <Input
            name="sourceUrl"
            onChange={formik.handleChange}
            value={sourceUrlValue}
          />
        </Flex>
      </Form.Item>
      <Form.Item label="Brand" wrapperCol={{ span: 16 }}>
        <Input
          name="brand"
          onChange={formik.handleChange}
          value={formik.values.brand || ''}
          placeholder="Insert brand here"
        />
      </Form.Item>
      <Form.Item label="Email" wrapperCol={{ span: 16 }}>
        <Input
          name="email"
          onChange={formik.handleChange}
          value={formik.values.email || ''}
          placeholder="Insert email here"
        />
      </Form.Item>
      <Form.Item
        label="API Key"
        validateStatus={!!formik.errors.access_token ? 'error' : ''}
        help={formik.errors.access_token}
        wrapperCol={{ span: 16 }}
      >
        <Input
          name="access_token"
          onChange={formik.handleChange}
          value={formik.values.access_token || ''}
          placeholder="Insert API Key here"
        />
      </Form.Item>
    </>
  )
}

const Flex = styled.div`
  display: flex;
  align-items: center;
`
