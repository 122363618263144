import React from 'react'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import CompareModeSvgIcon from 'features/project/features/filters/Header/components/Compare/components/CompareModeSvgIcon/CompareModeSvgIcon'
import { buttonBlue, buttonHover } from 'assets/styles/variables'
import styled from 'styled-components'
import { useComparativeNavigation } from 'features/project/hooks/useComparativeNavigation'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { Tooltip } from 'antd'

export const CompareMode: React.FC = () => {
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const { addPanel } = useComparativeNavigation()
  return (
    <Tooltip title={'Add Panel'}>
      <Container
        comparativePanelsNumber={comparativePanelsNumber}
        onClick={() => {
          if (comparativePanelsNumber < 3) {
            addPanel()
          }
        }}
      >
        <GenericSvgIcon SvgComponent={CompareModeSvgIcon} />
      </Container>
    </Tooltip>
  )
}

const Container = styled.div<{ comparativePanelsNumber: number }>`
  display: flex;
  align-items: center;
  color: ${({ comparativePanelsNumber }) =>
    comparativePanelsNumber > 2 ? 'grey' : buttonBlue};
  cursor: ${({ comparativePanelsNumber }) =>
    comparativePanelsNumber > 2 ? 'not-allowed' : 'pointer'};

  &:hover {
    color: ${({ comparativePanelsNumber }) =>
      comparativePanelsNumber > 2 ? 'grey' : buttonHover};
  }
`
