import { Group, Project, ProjectMetaType } from './types'
import { axiosInstance } from 'utils/axios'
import {
  GROUP_PROJECT_LIST_URL,
  PROJECT_DELETE_URL,
  PROJECT_LIST_URL,
  PROJECT_META,
} from 'utils/apiLinks'
import { AxiosResponse } from 'axios'

/**
 * Fetches project list
 * @returns {Promise<Project[]>} List of projects
 */
export const fetchProjects = (): Promise<AxiosResponse<Project[]>> =>
  axiosInstance.get(PROJECT_LIST_URL)

export const fetchProjectsBySearch = async (
  searchType: string,
  searchTerm: string
): Promise<AxiosResponse<Project[]>> =>
  axiosInstance.get(PROJECT_LIST_URL, {
    params: {
      search_type: searchType,
      search_term: searchTerm,
    },
  })

export const fetchGroupProjectsBySearch = async (): Promise<
  AxiosResponse<Group>
> => axiosInstance.get(GROUP_PROJECT_LIST_URL)

export const fetchProjectsByGroup = async (
  proj_uuid: string
): Promise<AxiosResponse<Group>> =>
  axiosInstance.get(GROUP_PROJECT_LIST_URL, {
    params: {
      proj_uuid,
    },
  })

/**
 * Removes the project
 * @param {string} proj_uuid - The project UUID
 * @returns nothing
 */
export const removeProject = (
  proj_uuid: string
): Promise<AxiosResponse<void>> =>
  axiosInstance.delete(PROJECT_DELETE_URL, { params: { proj_uuid } })

/**
 * Fetches meta data for the projects
 * @returns {Promise<ProjectMetaType>} Meta details
 */
export const getProjectMeta = (): Promise<AxiosResponse<ProjectMetaType>> =>
  axiosInstance.get(PROJECT_META)
