import styled from 'styled-components'

export const Container = styled.div`
  padding: 10px 15px 0;
  display: flex;
  gap: 15px;
  //background: white;
  height: calc(100vh - 40px);
  width: 100%;

  @media (max-width: 1300px) {
    padding: 0 5px 0;
    gap: 7px;
  }
`

export const ErrorImage = styled.img`
  object-fit: contain;
  width: 100%;
  max-width: 500px;
  max-height: 100%;
  margin: -50px auto 0;
`

export const InfoContainer = styled.div`
  margin: 0 auto;
  font-size: 18px;
`

export const LoadingWrapper = styled.div`
  margin-top: 100px;
`

export const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`
