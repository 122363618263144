import { BarcodeOutlined } from '@ant-design/icons'
import React, { useCallback, useEffect, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { Typography } from 'antd'
import { InnerTitle } from 'components/InnerTitle'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { getProductsForHierarchy } from 'features/project/features/products/model'
import { Product } from 'features/project/features/products/types'
import { Emoji, Loader } from 'shared/components'
import mixpanel from 'features/trackers/mixpanel'
import { YogiButton } from 'components/UI/YogiButton'
import { SourceIcon } from 'features/project/features/products/components/product-source-icon'
import { ProductModal } from 'features/project/features/products/components/product-modal'
import {
  buttonBlue,
  cardBorderGrey,
  lightBlueFontColor,
  pageBackgroundGrey,
} from 'assets/styles/variables'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'

const sellerPartyMap: Record<string, string> = {
  '1P Only': '1st Party Seller',
  '3P Only': '3rd Party Seller',
  '1P & 3P': '1st & 3rd Party Sellers',
}

type Props = {
  hierarchy: string
  selectedItem?: Product
  setSelectedItem: (item: Product | undefined) => void
}
const PDPProductSelector: React.FC<Props> = ({
  hierarchy,
  selectedItem,
  setSelectedItem,
}) => {
  const validUrl = 'www.amazon.com/'
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const urlParams = new URLSearchParams(window.location.search)

  const [modalOpened, setModalOpened] = useState<boolean>(false)

  const handleOk = useCallback((e: any) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])

  const handleCancel = useCallback((e: any) => {
    e.stopPropagation()
    setModalOpened(false)
  }, [])

  const { data: hierachyProductsData, isLoading } = useQuery(
    ['getProductsForHierarchy', projectId, hierarchy],
    () =>
      getProductsForHierarchy('getProductsForHierarchy', projectId, hierarchy),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      cacheTime: Infinity,
      staleTime: Infinity,
      enabled: !!projectId,
    },
  )

  // if we click into a view with item already selected, we want to scroll to it if necessary
  useEffect(() => {
    if (selectedItem?.uuid && hierachyProductsData?.length) {
      if (selectedItem?.uuid) {
        const element =
          document.querySelector('#uuid-' + selectedItem.uuid) ?? null
        element?.scrollIntoView({
          behavior: 'smooth',
          block: 'nearest',
          inline: 'start',
        })
      }
    }
  }, [hierachyProductsData])

  // todo maybe apply disabled in the mapping below so all products are shown
  const validProducts =
    hierachyProductsData
      ?.filter((el) => !!el.sources.find((src) => src.source_type === 'Amazon'))
      .sort((a, b) => b.review_count - a.review_count) ?? []

  const [hasValidProducts, setHasValidProducts] = useState(false)

  useEffect(() => {
    if (!selectedItem) {
      if (validProducts?.[0]) {
        setSelectedItem(validProducts?.[0])
        setHasValidProducts(true)
      }
      // if (
      //   urlParams.get('item') &&
      //   urlParams.get('item') !== (selectedItem as Product | undefined)?.uuid
      // ) {
      //   const item = validProducts.find(
      //     (el) => el.uuid === urlParams.get('item'),
      //   )
      //   setSelectedItem(item)
      // } else if (validProducts?.[0]) {
      //   setSelectedItem(validProducts?.[0])
      //   setHasValidProducts(true)
      // } else {
      //   // TODO if validproducts empty, show a message
      //   setHasValidProducts(false)
      // }
    } else {
      const item = validProducts.find((el) => el.uuid === selectedItem?.uuid)
      setSelectedItem(item)
    }
  }, [validProducts])

  return (
    <Container>
      {!hierachyProductsData ? (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            height: 150,
          }}
        >
          <LogoSpinner height={100} />
          <InnerTitle
            style={{ textAlign: 'center' }}
          >{`Loading Product Listings`}</InnerTitle>
        </div>
      ) : (
        <>
          <ProductItemContainer>
            {!hasValidProducts && (
              <div style={{ marginTop: 10, width: '100%' }}>
                <InnerTitle style={{ textAlign: 'center' }}>
                  No Amazon Listings for this Product
                </InnerTitle>
              </div>
            )}
            {hierachyProductsData &&
              validProducts.map((product) => (
                <ProductItem
                  key={product.uuid}
                  id={'uuid-' + product.uuid}
                  onClick={() => {
                    // TODO this should be moved out to the respective component
                    if (!route.isPDP) {
                      mixpanel.track('catalog', {
                        action: 'product selector',
                        value: product.name,
                      })
                    }
                    if (selectedItem?.uuid === product.uuid) {
                      setSelectedItem(undefined)
                    } else {
                      setSelectedItem(product)
                    }
                  }}
                  active={selectedItem?.uuid === product.uuid}
                >
                  <ImageWrapper>
                    {product.image_url ? (
                      <img
                        src={product.image_url}
                        width="auto"
                        height="80px"
                        alt=""
                      />
                    ) : (
                      <BarcodeOutlined style={{ fontSize: '60px' }} />
                    )}
                  </ImageWrapper>
                  <div
                    style={{
                      overflow: 'hidden',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'space-between',
                      height: '100%',
                    }}
                  >
                    <div
                      style={{
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: '-webkit-box',
                        lineClamp: product.generic_listing ? 3 : 4,
                        WebkitLineClamp: product.generic_listing ? 3 : 4,
                        WebkitBoxOrient: 'vertical',
                        fontSize: 10,
                      }}
                    >
                      {product.name}
                    </div>
                    <div>
                      {product.generic_listing ? (
                        <>
                          <Emoji symbol="⭐" />
                          {' General Item Listing'}
                        </>
                      ) : null}
                    </div>
                    <div
                      style={{
                        marginTop: 5,
                        fontSize: 10,
                        color: lightBlueFontColor,
                      }}
                    >
                      {product.review_count} Reviews
                      {product?.source_custom?.['Seller Party'] && (
                        <>
                          <span style={{ marginLeft: 10 }}>-</span>
                          <span style={{ marginLeft: 10 }}>
                            {
                              sellerPartyMap[
                                product?.source_custom?.['Seller Party']
                              ]
                            }
                          </span>
                        </>
                      )}
                    </div>
                    {/*  TODO maybe url link button here*/}
                  </div>
                </ProductItem>
              ))}
          </ProductItemContainer>
        </>
      )}

      {modalOpened && selectedItem && (
        <ProductModal
          product={selectedItem}
          handleCancel={handleCancel}
          handleOk={handleOk}
        ></ProductModal>
      )}
    </Container>
  )
}

export default PDPProductSelector

const Container = styled.div`
  //height: 100%;
`

const ProductItemContainer = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  height: 120px;
  overflow: auto;
  padding-bottom: 10px;
`

const ProductItem = styled.div<{ active: boolean }>`
  flex-grow: 1;
  padding: 10px;
  cursor: pointer;
  background: ${({ active }) => (active ? pageBackgroundGrey : 'transparent')};
  border-radius: 5px;
  border: 1px solid ${({ active }) => (active ? buttonBlue : cardBorderGrey)};
  box-shadow: var(--default-box-shadow);
  height: 100%;
  min-width: 300px;
  &:hover {
    background: ${pageBackgroundGrey};
  }
  overflow: hidden;
`

const ImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  vertical-align: top;
  float: left;
  margin-right: 5%;
  margin-left: 2%;
`

const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  font-size: 14px;
  //font-weight: bold;
`
const SourceWrapper = styled.div`
  float: left;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: 10%;
`
const CardContentWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 5px 10px;
`
