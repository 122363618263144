import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Chart as ChartJS, ChartData, ChartOptions } from 'chart.js'
import { numberFormatter } from 'utils/numberFormat'
import type { KeywordsBarChart } from '../../types'
import { PostsEmpty } from 'components/PostsEmpty'
import mixpanel from 'features/trackers/mixpanel'
import { onCopyChart } from 'features/project/features/Charts/utils'
import { CheckOutlined, SwapOutlined } from '@ant-design/icons'
import { buttonBlue, secondaryGreen } from 'assets/styles/variables'
import { Tooltip } from 'antd'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ExportData, Filters, ProjectState } from 'features/project/types'
import { tooltip } from 'utils/tooltip-data'
import { SaveAndExportButton } from 'components/SaveAndExportButton/SaveAndExportButton'
import { CopyChartButton } from 'components/CopyChartButton/CopyChartButton'

type Props = {
  data: KeywordsBarChart
  filterValues: Filters
  comparativeIndex: number
  onPhraseSelect: (c: string) => void
  animationDisabled?: boolean
}

export const Chart: React.FC<Props> = ({
  data,
  filterValues,
  comparativeIndex,
  onPhraseSelect,
  animationDisabled,
}) => {
  const route = useProjectStore((state: ProjectState) => state.route)
  const setIsExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsExportDrawerOpen,
  )
  const setExportData = useProjectStore(
    (state: ProjectState) => state.setExportData,
  )
  const setIsExporting = useProjectStore(
    (state: ProjectState) => state.setIsExporting,
  )

  const wrapperRef = useRef<HTMLDivElement>(null)
  const chartWrapperRef = useRef<HTMLDivElement>(null)
  const chartRef = useRef<HTMLCanvasElement>(null)
  const chartInstanceRef = useRef<ChartJS | null>(null)

  const [sortBySentiment, setSortBySentiment] = useState<boolean>(false)
  const [isCopying, setIsCopying] = useState(false)

  const prepareChartData = () => {
    const sortedValues = [...data.values]
    if (sortBySentiment) {
      sortedValues.sort(
        (a, b) =>
          (b.hover['Avg Sentiment'] as number) -
          (a.hover['Avg Sentiment'] as number),
      )
    }

    const labels = sortedValues.map((value) =>
      value.category.length > 30
        ? value.category.substring(0, 30) + '...'
        : value.category,
    )
    const colors = sortedValues.map((value) => value.color)
    const values = sortedValues.map((value) => value.volume)

    return { labels, colors, values, sortedValues }
  }

  const createOrUpdateChart = () => {
    if (!chartRef.current) return

    const { labels, colors, values, sortedValues } = prepareChartData()

    const chartData: ChartData = {
      labels: labels,
      datasets: [
        {
          label: data.x_key,
          data: values,
          backgroundColor: colors,
          borderRadius: 10,
          maxBarThickness: 40,
        },
      ],
    }

    const chartOptions: ChartOptions = {
      indexAxis: 'y',
      scales: {
        x: {
          position: 'top',
          title: {
            display: true,
            text: 'Frequency',
          },
        },
      },
      plugins: {
        legend: { display: false },
        datalabels: {
          formatter: (value: number) => {
            return numberFormatter(value)
          },
          color: 'black',
          anchor: 'end',
          align: 'right',
        },
        tooltip: {
          callbacks: {
            label: () => '',
            afterBody: function (t: any) {
              const index = t[0].dataIndex
              if (index === undefined) return ''
              const currentItem = sortedValues[index]

              return Object.keys(currentItem.hover)
                .map(
                  (key) => `${key}: ${numberFormatter(currentItem.hover[key])}`,
                )
                .join('\n')
            },
          },
        },
      },
      animation: {
        duration: animationDisabled ? 0 : 1000,
      },
      layout: {
        padding: {
          right: 60,
        },
      },
      maintainAspectRatio: false,
      responsive: true,
      onClick: (event, elements) => {
        if (elements && elements[0]) {
          const index = elements[0].index
          const currentElement = labels[index]
          onPhraseSelect(currentElement || '')
        }
      },
      onHover: (event, elements) => {
        const target = event.native?.target as HTMLElement
        target.style.cursor =
          elements && elements.length > 0 ? 'pointer' : 'default'
      },
    }

    if (chartInstanceRef.current) {
      chartInstanceRef.current.data = chartData
      chartInstanceRef.current.options = chartOptions
      chartInstanceRef.current.update()
    } else {
      chartInstanceRef.current = new ChartJS(chartRef.current, {
        type: 'bar',
        options: chartOptions,
        data: chartData,
      })
    }
  }

  const exportFn = () => {
    mixpanel.track('chart download', {
      action: 'image',
      ...route,
      value: 'keywords',
    })

    const exportData: ExportData = {
      comparativeIndex: comparativeIndex,
      filterValues: filterValues,
      downloadImage: () => {
        setIsExporting(true)
        onCopyChart(chartRef, chartWrapperRef, 'keywords', true).then(() => {
          setIsExporting(false)
        })
      },
      // downloadCsv: () =>
      //   downloadChartAsCSV(currentChart, chartValues),
    }
    setExportData(exportData)

    setIsExportDrawerOpen(true)
  }

  const copyFn = async () => {
    setIsCopying(true)
    mixpanel.track('chart download', {
      action: 'copy',
      ...route,
      value: 'keywords',
    })
    setTimeout(() => {
      setIsCopying(false)
    }, 5000)
    onCopyChart(chartRef, chartWrapperRef, 'keywords').then(() => {
      // setIsCopying(false)
    })
  }

  useEffect(() => {
    createOrUpdateChart()

    return () => {
      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
        chartInstanceRef.current = null
      }
    }
  }, [sortBySentiment, data])

  if (!data.values.length) {
    return <PostsEmpty>No matches found.</PostsEmpty>
  }

  return (
    <Container ref={wrapperRef}>
      <Header>
        <div
          style={{
            display: 'flex',
            gap: 5,
            marginRight: 10,
            cursor: 'pointer',
            color: buttonBlue,
          }}
          onClick={() => setSortBySentiment(!sortBySentiment)}
        >
          <SwapOutlined rotate={90} />
          <div
            style={{
              fontSize: 10,
              // color: navDefaultTextColor,
            }}
          >
            sort by {sortBySentiment ? 'frequency' : 'sentiment'}
          </div>
        </div>
        <Tooltip title={tooltip['copy-button']}>
          {isCopying ? (
            <CheckOutlined
              style={{
                color: secondaryGreen,
                boxShadow: 'none',
                marginRight: '10px',
                background: '#fff',
              }}
            />
          ) : (
            <CopyChartButton copyFn={copyFn} />
          )}
        </Tooltip>
        {/*<Tooltip title={'Download chart data in your preferred format'}>*/}
        <SaveAndExportButton exportFn={exportFn} />
        {/*</Tooltip>*/}
      </Header>
      <Wrapper ref={chartWrapperRef}>
        <canvas
          id="keywords-chart"
          ref={chartRef}
          style={{
            paddingLeft: 10,
            width: '100%',
            minHeight: data.values.length * 50 + 50,
          }}
        />
      </Wrapper>
    </Container>
  )
}

const Container = styled.div`
  //background: white;
  box-shadow: var(--default-box-shadow);
  border-radius: var(--border-radius);
  padding: 15px 0;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  justify-content: end;
  gap: 10px;
  margin-bottom: 10px;
`

const Wrapper = styled.div``
