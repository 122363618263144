import React from 'react'

const ReviewsSvgIcon: React.FC = () => (
  <svg
    width="31"
    height="31"
    viewBox="0 0 31 31"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.15149 23.606H5.73728L5.44438 23.8988L1 28.3432V3.07574C1 1.93637 1.93637 1 3.07574 1H27.6817C28.8211 1 29.7574 1.93637 29.7574 3.07574V21.5302C29.7574 22.6696 28.8211 23.606 27.6817 23.606H6.15149Z"
      stroke="currentColor"
      stroke-width="2"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M16.7865 6.49329C16.2343 6.49329 15.7865 6.941 15.7865 7.49329C15.7865 8.04557 16.2343 8.49329 16.7865 8.49329H20.901L6.47695 16.8813C5.99952 17.1589 5.83756 17.771 6.1152 18.2485C6.39284 18.7259 7.00494 18.8879 7.48236 18.6102L23.1415 9.50395V15.1826C23.1415 15.7349 23.5893 16.1826 24.1415 16.1826C24.6938 16.1826 25.1415 15.7349 25.1415 15.1826V7.49329V6.49329H24.1415H16.7865Z"
      fill="currentColor"
    />
  </svg>
)

export default ReviewsSvgIcon
