import React from 'react'
import { getSummary, PostsSummary } from 'features/project/features/feedback'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { Filters, ProjectState } from 'features/project/types'
import mixpanel from 'features/trackers/mixpanel'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import AutoInsightsSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/AutoInsightsSvgIcon/AutoInsightsSvgIcon'
import { darkBlue } from 'assets/styles/variables'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'
import { WidgetLoader } from 'components/Loading/WidgetLoader/WidgetLoader'

type Props = {
  filterValues: Filters
}

export const Summary: React.FC<Props> = ({ filterValues }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters,
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen,
  )
  const setSummaryRequest = useProjectStore(
    (state: ProjectState) => state.setSummaryRequest,
  )

  // get Feedback Summary
  const {
    data: summaryData,
    isFetching: summaryFetching,
    isLoading: summaryLoading,
  } = useQuery(
    [
      'feedback-summary',
      projectId,
      filterValues?.values,
      filterValues?.searchCondition,
      filterValues?.searchQuery,
    ],
    async () => {
      const payload = {
        headers: {
          proj_uuid: projectId,
        },
        body: {
          criteria: filterValues.values,
          search_terms: filterValues.searchQuery,
          search_criteria: filterValues.searchCondition,
        },
      }

      const { data } = await getSummary(payload.headers, payload.body)
      return data
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      keepPreviousData: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    },
  )
  return (
    <SummaryContainer id="summary-container">
      <Summarize
        style={{ marginBottom: 20 }}
        onClick={() => {
          setSummaryIsOpen(true)

          const summaryRequest = {
            headers: {
              proj_uuid: projectId,
            },
            body: {
              chart_id: '5_5_7_rating_sentiment',
              // api needs a tier1 value, but it doesn't matter what it is
              tier1: 'Brand',
              tier1_value: '',
              tier2_value: null,
              tier3_value: null,
              search_terms: filterValues.searchQuery,
              search_criteria: filterValues.searchCondition,
              criteria: filterValues.values,
            },
          }

          mixpanel.track('summary control', {
            action: 'keywords',
            value: summaryRequest.body,
            ...route,
          })
          setSummaryRequest(summaryRequest)
        }}
      >
        <div>Summarize</div>
        <div style={{ marginTop: -5 }}>
          <GenericSvgIcon SvgComponent={AutoInsightsSvgIcon} />
        </div>
      </Summarize>
      {!summaryData ? (
        <WidgetLoader height={150}></WidgetLoader>
      ) : (
        <PostsSummary
          data={summaryData}
          isComparative={false}
          loading={summaryLoading || summaryFetching}
        />
      )}
    </SummaryContainer>
  )
}

const SummaryContainer = styled.div`
  width: 100%;
  border-radius: var(--border-radius);
  margin-bottom: 0;
  box-shadow: none;
  position: relative;
`

const Summarize = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${darkBlue};
  color: white;
  cursor: pointer;

  padding: 18px;

  border-radius: 10px;

  box-shadow: 0 4px 4px 0 #00000040;

  &:hover {
    background: #28364ff3;
  }
`
