import React, { useEffect } from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
}

export const ModalWrapper: React.FC<Props> = ({ children }) => {
  useEffect(() => {
    document.body.style.overflow = 'hidden'

    return () => {
      document.body.style.overflow = 'auto'
    }
  }, [])

  return (
    <Wrapper>
      <Container>
        <Body>{children}</Body>
      </Container>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: fixed;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10;
`
const Container = styled.div`
  position: relative;
`
const Body = styled.div`
  background: #fff;
  padding: var(--default-padding);
`
