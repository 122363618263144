import React from 'react'
import { Button, ButtonProps } from 'antd'
import styled from 'styled-components'
import {
  buttonBlue,
  buttonHover,
  exportCardBackgroundHover,
  lightBlueFontColor,
  secondaryButtonBlue,
  secondaryNavy,
  secondaryRed,
} from 'assets/styles/variables'

export const YogiButton: React.FC<ButtonProps> = ({
  children,
  onClick,
  type,
  icon,
  id,
  disabled,
  style,
  htmlType,
  shape,
  danger,
  loading,
}) => {
  return (
    <StyledButton
      id={id}
      type={type}
      icon={icon}
      onClick={onClick}
      disabled={disabled}
      style={style}
      htmlType={htmlType}
      shape={shape}
      loading={loading}
    >
      {children}
    </StyledButton>
  )
}

interface AdditionalStyles {
  background: string
  hoverBackground: string
  hoverColor: string
  color: string
  border: string
}

interface StyledButtonProps extends ButtonProps {
  additionalstyles?: AdditionalStyles
}

const getAdditionalStyles = (props: StyledButtonProps): AdditionalStyles => {
  if (props.danger) {
    return {
      background: secondaryRed,
      hoverBackground: secondaryRed,
      hoverColor: 'white',
      color: 'white',
      border: secondaryRed,
    }
  }
  if (props.type === 'primary') {
    return {
      background: buttonBlue,
      hoverBackground: buttonHover,
      hoverColor: 'white',
      color: 'white',
      border: buttonBlue,
    }
  }
  if (props.type === 'dashed') {
    return {
      background: 'white',
      color: lightBlueFontColor,
      border: secondaryButtonBlue,
      hoverBackground: secondaryButtonBlue,
      hoverColor: lightBlueFontColor,
    }
  }
  return {
    background: secondaryButtonBlue,
    color: lightBlueFontColor,
    border: secondaryButtonBlue,
    hoverBackground: exportCardBackgroundHover,
    hoverColor: lightBlueFontColor,
  }
}

const StyledButton = styled(Button).attrs<StyledButtonProps>((props) => ({
  additionalstyles: getAdditionalStyles(props),
}))<StyledButtonProps>`
  border-radius: 10px;
  border-width: 0;
  background: ${(props) => props.additionalstyles?.background};
  color: ${(props) => props.additionalstyles?.color};
  border-color: ${(props) => props.additionalstyles?.border};
  border-style: solid;

  &:hover {
    border-color: ${(props) =>
      props.disabled
        ? 'auto'
        : props.type === 'dashed'
          ? secondaryButtonBlue
          : props.additionalstyles?.hoverBackground}!important;
    color: ${(props) =>
      props.disabled
        ? 'auto'
        : props.type === 'dashed'
          ? props.additionalstyles?.hoverColor
          : props.additionalstyles?.hoverColor}!important;
    background: ${(props) =>
      props.disabled
        ? 'auto'
        : props.additionalstyles?.hoverBackground}!important;
    //box-shadow: 0px 12px 24px 0px #1d4ed852 !important;
    //box-shadow: 0px 2px 4px 0px #3b82f61f !important;
  }

  &:focus {
    border-color: ${(props) =>
      props.additionalstyles?.hoverBackground}!important;
    color: ${(props) => props.additionalstyles?.hoverColor}!important;
    background: ${(props) => props.additionalstyles?.hoverBackground}!important;
  }
`
