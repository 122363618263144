import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { Loader } from 'shared/components'
import { signinURL } from 'utils/links'
import { verifyAccess } from '../model'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { emptyFilters } from '../../../utils'
const Dashboards = React.lazy(() => import('../../dashboard/dashboards'))
import useNotifications from '../../../hooks/useNotifications'
import { useQuery } from 'react-query'
import { fetchFilterOptions } from '../../../react-query-functions'
import { generateDates } from '../../dashboard/utils'
import useMixpanel from '../../../hooks/useMixpanel'
import mixpanel from '../../../../trackers/mixpanel'

type Params = { id: string; token: string }

export const DashboardSharePage = () => {
  const setProjectId = useProjectStore(
    (state: ProjectState) => state.setProjectId,
  )
  const setHasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.setHasLoadedFilters,
  )
  const history = useHistory()
  const { id, token } = useParams<Params>()

  const [hasAccess, setAccess] = useState<boolean>(false)

  useEffect(() => {
    verifyAccess(id, token).then((result) => {
      if (result) {
        setAccess(true)
        // maybe this should go into useMixpanel()
        mixpanel.register({ proj_uuid: id })
        setProjectId(id)
        setHasLoadedFilters(true)
      } else {
        history.push(signinURL)
      }
    })
  }, [])

  const setDefaultFilterList = useProjectStore(
    (state: ProjectState) => state.setDefaultFilterList,
  )
  const setDateWindows = useProjectStore((state) => state.setDateWindows)

  // TODO put at project level and store in project store
  const { data: filterList } = useQuery(
    ['filter-list-data', id, [], emptyFilters],
    async () => {
      return await fetchFilterOptions(id, [], emptyFilters)
    },
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!id && hasAccess,
    },
  )
  useEffect(() => {
    if (filterList) {
      setDefaultFilterList(filterList)
      setDateWindows(generateDates(filterList))
    }
  }, [filterList])

  useMixpanel()

  if (!hasAccess) {
    return <Loader />
  }

  return (
    // <Route exact path={`${path}/${DASHBOARD_URL}`}>
    <Dashboards useNotifications={useNotifications} isPublic />
    // </Route>
  )
}
