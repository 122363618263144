import { Button, Checkbox, Form, Input } from 'antd'
import React from 'react'
import { FormItem } from 'shared/components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { ProjectOptionsResponse, RelativeDateFilterIdx } from '../../types'
import mixpanel from 'features/trackers/mixpanel'
import styled from 'styled-components'
import { YogiButton } from 'components/UI/YogiButton'
import { Filters } from 'features/project/types'
import { toast } from 'react-toastify'

type Props = {
  saveFunction: (
    filterValues: Filters,
    presetName: string,
    saveRelativeDate: boolean,
  ) => Promise<ProjectOptionsResponse>
  relativeDateFilterIdx: number
  filterValues: Filters | undefined
  setIsVisible: (isVisible: boolean) => void
}

const validationSchema = Yup.object().shape({
  presetName: Yup.string().required('Field is required'),
})

export const SavePresetForm: React.FC<Props> = ({
  saveFunction,
  relativeDateFilterIdx,
  filterValues,
  setIsVisible,
}) => {
  const [isSaving, setIsSaving] = React.useState<boolean>(false)
  const formik = useFormik({
    validationSchema,
    initialValues: {
      presetName: '',
      includeView: false,
      saveRelativeDate: false,
    },
    onSubmit: async ({ presetName, saveRelativeDate }, { resetForm }) => {
      if (!filterValues) return
      setIsSaving(true)
      mixpanel.track('saved filter', { action: 'save', value: presetName })
      saveFunction(filterValues, presetName, saveRelativeDate)
        .then(() => {
          setIsSaving(false)
          setIsVisible(false)
          resetForm()
          toast.success('Filter Saved')
        })
        .catch((e) => {
          toast.error(e.message)
        })
    },
  })

  const relativeDateIsSet =
    relativeDateFilterIdx != null &&
    relativeDateFilterIdx !== RelativeDateFilterIdx.Unset

  return (
    <Form onFinish={formik.handleSubmit}>
      {/*<FormItem label="Filter Name:" field="presetName" formik={formik} />*/}
      <Form.Item style={{ marginBottom: '5px' }}>
        <StyledInput
          name={'presetName'}
          onChange={formik.handleChange}
          value={formik.values['presetName']}
          placeholder={'Filter Name'}
        />
        <Error>{formik.errors?.['presetName']}</Error>
      </Form.Item>
      {relativeDateIsSet && (
        <Form.Item
          label="Save relative date"
          wrapperCol={{ span: 16 }}
          style={{ marginBottom: '5px' }}
        >
          <Checkbox
            disabled={!relativeDateIsSet}
            value={relativeDateIsSet && formik.values.saveRelativeDate}
            name="saveRelativeDate"
            checked={relativeDateIsSet && formik.values.saveRelativeDate}
            onChange={formik.handleChange}
          />
        </Form.Item>
      )}

      <div
        style={{ width: '100%', display: 'flex', justifyContent: 'flex-end' }}
      >
        <YogiButton type="primary" htmlType="submit" loading={isSaving}>
          Save
        </YogiButton>
      </div>
    </Form>
  )
}

const StyledInput = styled(Input)`
  border-radius: 6px;

  &:placeholder-shown {
    font-style: italic;
  }
`
const Error = styled.div`
  text-align: center;
  color: #ff4d4f;
  position: absolute;
  top: 100%;
  font-size: var(--font-size-sm);
`
