import React from 'react'
import styled from 'styled-components'
import { lightBlueFontColor, lightText } from 'assets/styles/variables'

type Props = {}

export const EmptyCatalogDetail: React.FC<Props> = ({}) => {
  return (
    <Container>
      <Header>Catalog</Header>
      <Body>
        Select from Brands, Products, Sources, and Themes to view an item in
        detail.
      </Body>
    </Container>
  )
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-top: -110px;
  height: calc(100% + 110px);
  width: 100%;

  padding: 53px 48px;

  background: linear-gradient(
    90deg,
    rgba(98, 183, 212, 0.064) 0%,
    rgba(252, 250, 250, 0.064) 43.62%
  );
`

const Header = styled.div`
  font-size: 44px;
  color: ${lightBlueFontColor};
  margin-top: 60px;
`

const Body = styled.div`
  font-size: 14px;
  line-height: 25px;

  color: ${lightText};
`
