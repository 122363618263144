import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Button, DatePicker, Upload } from 'antd'
import { FormTitle } from 'shared/components'
import { UploadOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import { ProjectSourceURL } from '../source/types'
import { Product } from '../product/types'
import { getSourcesFromFile } from './utils'
import moment from 'moment'

type Props = {
  reservedKeywords?: string[]
  onSuccess: (s: { sources: ProjectSourceURL[]; products: Product[] }) => void
}

export const CsvForm: React.FC<Props> = ({
  reservedKeywords = [],
  onSuccess,
}) => {
  const [file, setFile] = useState<any>(null)
  const [sourceDate, setSourceDate] = useState<Date>(new Date(2018, 0, 1))
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    if (file) {
      getSourcesFromFile(file, reservedKeywords, sourceDate, (response) => {
        if (typeof response === 'string') {
          setError(response)
        } else {
          onSuccess(response)
        }
      })
    }
  }, [file])

  return (
    <div>
      <FormTitle>Auto-Fill Products and Sources</FormTitle>
      <Center>
        <p>
          Please select a CSV file to auto-fill the project creation
          information.
          <br /> Reach out to Yogi Support for an example file. Certain rules
          must be followed to load the data correctly.
          <br /> You can review the pulled information after you upload the
          file.
        </p>
        <p>Select default sources start date.</p>
        <DatePicker
          defaultValue={moment(sourceDate)}
          onChange={(value) => {
            if (value) {
              setSourceDate(value.toDate())
            }
          }}
          format={'MM/DD/YYYY'}
        />
        <br />
        <br />
        <Flex>
          <Upload
            accept={'.csv'}
            beforeUpload={() => false}
            onChange={({ fileList }: any) => {
              if (fileList.length > 0) {
                if (fileList[fileList.length - 1].size / 1024 / 1024 >= 50) {
                  toast.error('The uploaded file should be less than 50mb')
                  return
                }
                setFile(fileList[fileList.length - 1].originFileObj)
              } else {
                setFile(null)
              }
            }}
          >
            <Button type="default">
              <UploadOutlined /> Click to Upload
            </Button>
          </Upload>
        </Flex>
      </Center>

      {file && error && <Error>{error}</Error>}
    </div>
  )
}

const Flex = styled.div`
  display: flex;
  justify-content: center;
`

const Center = styled.div`
  text-align: center;
`
const Error = styled.div`
  background-color: #f9f9f9;
  min-height: 10px;
  max-height: 150px;
  overflow: auto;
  margin: 20px;
  text-align: center;
  color: #ff4d4f;
  white-space: pre-line;
`
