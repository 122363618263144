type DynamicURL = (param?: string, param2?: string) => string

export const home = '/'
export const signinURL = '/sign-in'
export const trialSignupURL = '/trial-signup'
export const signinCallbackURL = '/sign-in/callback'
export const resetPasswordURL = '/reset-password'
export const newPasswordURL = '/new-password'
export const setupPasswordURL = '/setup-password'
export const serviceDashboardURL = '/service-charts'
export const serviceFeedbackURL = '/service-feedback'
export const serviceKeywordsURL = '/service-keywords'
export const serviceNetworkURL = '/service-network'
export const verifyURL = '/verify-user'
export const profileURL = '/profile'
export const projectPage = '/project/:id'
export const shareProjectURL = '/project/:id/shared/:token'
export const shareCustomDashboardURL = '/project/:id/shared-dashboard/:token'
export const trialURL = '/trial'
export const requestDasboardURL = '/request-charts'
export const requestAccessURL = '/request-access'

export const comparativePart = 'comparative'

export const comparativeDashboardURL = (id?: string) =>
  id
    ? `/${comparativePart}/dashboard/${id}`
    : `/${comparativePart}/dashboard/:id`
export const comparativeNetworkURL = (id?: string) =>
  id ? `/${comparativePart}/network/${id}` : `/${comparativePart}/network/:id`
export const comparativeKeywordsURL = (id?: string) =>
  id ? `/${comparativePart}/keywords/${id}` : `/${comparativePart}/keywords/:id`

export const projectURL: DynamicURL = (name?: string) =>
  name ? `/project/${name}` : '/project/:id'

export const projectShareURL: DynamicURL = (id, token) =>
  id && token ? `/project/${id}/shared/${token}` : '/project/:id/shared/:token'
