import moment from 'moment/moment'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
} from 'features/project/features/filters/helpers'
import React from 'react'

const today = moment()
export const dateSelectOptions = [
  {
    label: 'Last Month',
    value: JSON.stringify({
      max: formatDateToUTCEndOfDay(today).unix(),
      min: formatDateToUTCMidnight(moment().subtract(1, 'month')).unix(),
    }),
  },
  {
    label: 'Last 3 Months',
    value: JSON.stringify({
      max: formatDateToUTCEndOfDay(today).unix(),
      min: formatDateToUTCMidnight(moment().subtract(3, 'months')).unix(),
    }),
  },
  {
    label: 'Last 6 Months',
    value: JSON.stringify({
      max: formatDateToUTCEndOfDay(today).unix(),
      min: formatDateToUTCMidnight(moment().subtract(6, 'months')).unix(),
    }),
  },
  {
    label: 'Last 12 Months',
    value: JSON.stringify({
      max: formatDateToUTCEndOfDay(today).unix(),
      min: formatDateToUTCMidnight(moment().subtract(1, 'year')).unix(),
    }),
  },
  {
    label: 'Last 2 Years',
    value: JSON.stringify({
      max: formatDateToUTCEndOfDay(today).unix(),
      min: formatDateToUTCMidnight(moment().subtract(2, 'year')).unix(),
    }),
  },
  {
    label: 'Year To Date',
    value: JSON.stringify({
      max: formatDateToUTCEndOfDay(today).unix(),
      min: formatDateToUTCMidnight(moment().startOf('year')).unix(),
    }),
  },
  {
    label: 'All Time',
    value: null,
  },
  // {
  //   label: 'Last 2 Weeks',
  //   value: JSON.stringify({
  //     max: formatDateToUTCEndOfDay(today).unix(),
  //     min: formatDateToUTCMidnight(moment().subtract(2, 'weeks')).unix(),
  //   }),
  // },
  // {
  //   label: 'Last Week',
  //   value: JSON.stringify({
  //     max: formatDateToUTCEndOfDay(today).unix(),
  //     min: formatDateToUTCMidnight(moment().subtract(1, 'week')).unix(),
  //   }),
  // },
  {
    label: <span>Custom Range</span>,
    value: 'custom',
  },
]
