import React from 'react'
import { Input, InputProps } from 'antd'
import { SearchOutlined } from '@mui/icons-material'
import { cardBorderGrey } from '../../assets/styles/variables'
import styled from 'styled-components'
import { CloseOutlined } from '@ant-design/icons'

type Props = InputProps & {
  onChange: any
}
export const YogiSearch: React.FC<Props> = ({ onChange, style }) => {
  return (
    <StyledInput
      placeholder="Search..."
      prefix={<SearchOutlined style={{ color: '#9FB8C3' }} />}
      style={{
        border: '2px solid ' + cardBorderGrey,
        borderRadius: '6px',
        ...style,
      }}
      onChange={onChange}
      suffix={<CloseOutlined />}
    />
  )
}

const StyledInput = styled(Input)`
  .ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
    border-right-width: 2px !important;
  }
  .ant-input-affix-wrapper:hover {
    border-right-width: 2px !important;
  }
`
