import React, { useEffect } from 'react'
import styled from 'styled-components'
import { Form, Input, Tooltip, Button, Checkbox } from 'antd'
import { useFormik } from 'formik'
import { InfoCircleOutlined } from '@ant-design/icons'
import { FormTitle } from 'shared/components'
import { CustomFields } from 'features/project-form/components'
import { generateProductId } from 'features/project-form/utils'
import { tooltip } from 'utils/tooltip-data'
import { ProductSchema } from './validators'
import { Product } from './types'

type Props = {
  onDelete?: () => {}
  addProduct: (product: Product) => void
  productToEdit: Product | null
  handleOpenProject: () => void
  setProductToEditIndex: (n: number | null) => void
  deleteProduct: () => void
}

const initialValues: Product = {
  id: 0,
  name: '',
  brand: null,
  general: false,
  hierarchy: null,
  custom: {},
}

const layout = { labelCol: { span: 5 } }
export const ProductForm: React.FC<Props> = ({
  onDelete,
  addProduct,
  handleOpenProject,
  productToEdit,
  setProductToEditIndex,
}) => {
  const formik = useFormik({
    initialValues: initialValues,
    validateOnChange: false,
    validateOnBlur: false,
    validationSchema: ProductSchema,
    onSubmit: (values) => {
      addProduct({ ...values, id: values.id ? values.id : generateProductId() })
      handleOpenProject()
    },
  })

  useEffect(() => {
    if (productToEdit && !formik.touched.name) {
      formik.setTouched({ name: true })

      formik.setFieldValue('name', productToEdit.name)
      formik.setFieldValue('brand', productToEdit.brand || '')
      formik.setFieldValue('hierarchy', productToEdit.hierarchy || '')
      formik.setFieldValue('general', productToEdit.general || false)
      formik.setFieldValue('id', productToEdit.id)
      formik.setFieldValue('custom', productToEdit.custom)
    }

    return () => {
      setProductToEditIndex(null)
    }
  }, [productToEdit])

  return (
    <Wrapper>
      <Form
        {...layout}
        onFinish={() => formik.submitForm()}
        initialValues={formik.values}
      >
        <FormTitle>New Product</FormTitle>
        <Form.Item
          label={
            <Tooltip title={tooltip['product-form-title']}>
              <span>
                Item Name <InfoCircleOutlined />
              </span>
            </Tooltip>
          }
          validateStatus={!!formik.errors.name ? 'error' : ''}
          help={formik.errors.name}
          wrapperCol={{ span: 10 }}
        >
          <Input
            name="name"
            onChange={formik.handleChange}
            value={formik.values.name}
            placeholder="Insert product name here"
          />
        </Form.Item>
        <Form.Item
          label={
            <Tooltip title={tooltip['product-form-brand']}>
              <span>
                Brand <InfoCircleOutlined />
              </span>
            </Tooltip>
          }
          validateStatus={!!formik.errors.brand ? 'error' : ''}
          help={formik.errors.brand}
          wrapperCol={{ span: 10 }}
        >
          <Input
            name="brand"
            onChange={formik.handleChange}
            value={formik.values.brand || ''}
            placeholder="Insert product formal name here"
          />
        </Form.Item>
        <Form.Item
          label={
            <Tooltip title={tooltip['product-form-hierarchy']}>
              <span>
                Product <InfoCircleOutlined />
              </span>
            </Tooltip>
          }
          validateStatus={!!formik.errors.hierarchy ? 'error' : ''}
          help={formik.errors.hierarchy}
          wrapperCol={{ span: 10 }}
        >
          <Input
            name="hierarchy"
            onChange={formik.handleChange}
            value={formik.values.hierarchy || ''}
            placeholder="Insert product here"
          />
        </Form.Item>
        <Form.Item
          label={
            <Tooltip title={tooltip['product-form-generic']}>
              <span>
                General Item <InfoCircleOutlined />
              </span>
            </Tooltip>
          }
          wrapperCol={{ span: 10 }}
        >
          <Checkbox
            name="general"
            checked={formik.values.general}
            onChange={formik.handleChange}
          />
        </Form.Item>
        <CustomFields
          fields={formik.values.custom}
          onFieldAdd={(field: string, value: string) =>
            formik.setFieldValue(`custom`, {
              ...formik.values.custom,
              [field]: value,
            })
          }
          onFieldRemove={(field: string) => {
            const values: any = formik.values.custom
            if (values) {
              delete values[field]
            }
            formik.setFieldValue(`custom`, values)
          }}
        />
        <ButtonsWrapper>
          {onDelete && <SubmitButton onClick={onDelete}>Delete</SubmitButton>}
          <SubmitButton type="primary" htmlType="submit">
            {onDelete ? 'Save' : 'Add Product'}
          </SubmitButton>
        </ButtonsWrapper>
      </Form>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  max-height: 800px;
  width: 800px;
  overflow: auto;
`
const ButtonsWrapper = styled.div`
  margin-top: auto;
  margin-bottom: 0;
  display: flex;
  justify-content: center;
`
const SubmitButton = styled(Button)`
  display: block;
  margin: 40px 20px 0;
`
