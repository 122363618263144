import React, { useMemo } from 'react'
import { DashboardChartResponse } from 'features/project/features/Charts/types'
import styled from 'styled-components'
import {
  activeCategoryBackground,
  categoryBorder,
  chartCardBorder,
  overlayBackground,
} from 'assets/styles/variables'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { CheckOutlined } from '@ant-design/icons'

type Props = {
  chartItem: DashboardChartResponse
  setIsChartsPanelVisible: (value: boolean) => void
}

export const ChartSelectorPanel: React.FC<Props> = ({
  chartItem,
  setIsChartsPanelVisible,
}) => {
  const cardFilter = chartItem?.tier2

  // global state
  const chartId = useProjectStore(
    (state: ProjectState) => state.dashboardControls[0]?.chartId,
  )
  const updateDashboardControls = useProjectStore(
    (state: ProjectState) => state.updateDashboardControls,
  )

  // local state
  const [selectedCategory, setSelectedCategory] = React.useState(
    cardFilter?.options[cardFilter.default].name,
  )
  const [selectedChart, setSelectedChart] = React.useState(
    cardFilter?.options[cardFilter.default].tier3?.find(
      (el) => el.chart_ids[0].chart_id === chartId,
    ),
  )

  // constants
  const categories = [...chartItem.tier2.options].reverse()
  const options = categories.find(
    (category) => category.name === selectedCategory,
  )?.tier3

  // some Lenses only have chart types available in some categories
  // tier3 holds the actual list of chart ids, so if its empty, we should skip it
  const activeCategories = useMemo(() => {
    return categories.filter((category) => {
      return category.tier3.length > 0
    })
  }, [categories])

  return (
    <Container>
      <Body>
        <Left>
          {activeCategories.map((category) => (
            <Category
              key={category.name}
              selected={category.name === selectedCategory}
              onMouseEnter={() => {
                setSelectedCategory(category.name)
              }}
            >
              {category.name}
            </Category>
          ))}
        </Left>
        <Right>
          <ChartList>
            {options?.map((chart: any, index: any) => {
              const chartSelected = selectedChart
                ? selectedChart.chart_ids?.[0]?.chart_id ===
                  chart.chart_ids?.[0]?.chart_id
                : chartId === chart.chart_ids?.[0]?.chart_id
              return (
                <Chart
                  key={`${chart.name} ${index}`}
                  onClick={() => {
                    if (!chart) return
                    updateDashboardControls(
                      0,
                      'chartId',
                      chart.chart_ids[0].chart_id,
                    )
                    mixpanel.track('chart type', {
                      value: chart.chart_ids?.[0]?.chart_id,
                      name: chart.name,
                    })
                    setIsChartsPanelVisible(false)
                  }}
                  selected={chartSelected}
                >
                  <ChartImage>
                    <img
                      src={`${window.location.origin}/images/${chart.image}`}
                      alt={chart.name}
                    />
                  </ChartImage>
                  <Overlay selected={chartSelected}>
                    <div>{chart.name}</div>
                  </Overlay>
                  <FullOverlay selected={chartSelected}>
                    {chartSelected && <CheckOutlined />}
                  </FullOverlay>
                </Chart>
              )
            })}
          </ChartList>
        </Right>
      </Body>
    </Container>
  )
}

const Container = styled.div``

const Body = styled.div`
  display: flex;
  height: 304px;
`

const Left = styled.div`
  min-width: 109px;
  border-right: 1px solid ${categoryBorder};
`

const Category = styled.div<{ selected: boolean }>`
  font-size: var(--font-size-md);
  font-weight: ${(props) => (props.selected ? 600 : 400)};

  width: 100%;
  padding: 22px 5px 22px 24px;
  border-bottom: 1px solid ${categoryBorder};

  background: ${(props) =>
    props.selected ? activeCategoryBackground : 'transparent'};

  cursor: pointer;
  &:hover {
    background: ${activeCategoryBackground};
  }
`

const Right = styled.div`
  overflow: auto;
`

const ChartList = styled.div`
  width: 100%;
  margin: 15px 13px;

  display: flex;
  flex-wrap: wrap;
  gap: 16px;
`

const ChartTitle = styled.span<{ selected: boolean }>`
  font-size: var(--font-size-md);
  text-align: center;
  margin-top: calc(var(--default-padding-half) / 2);
  color: black;
  ${({ selected }) => selected && 'color: white;'};
`

const Overlay = styled.div<{ selected: boolean }>`
  position: absolute;

  font-size: var(--font-size-md);
  font-weight: ${(props) => (props.selected ? 600 : 400)};

  width: 100%;
  height: 30%;
  bottom: 0;
  padding: 2px 5px;
  display: flex;
  line-height: 1.4;
  align-items: center;

  background: ${overlayBackground};
  color: white;

  z-index: 1;
`
const FullOverlay = styled.div<{ selected: boolean }>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 70%;
  bottom: 30%;
  align-items: start;
  padding: 10px;
  background: ${(props) =>
    props.selected ? overlayBackground : 'transparent'};
  transition: 0.2s ease;

  color: white;

  z-index: 3;
`

const Chart = styled.div<{ selected: boolean }>`
  position: relative;
  min-width: 160px;
  max-width: 160px;
  overflow: hidden;
  min-height: 115px;

  border: 1px solid ${chartCardBorder};
  border-radius: 10px;
  cursor: pointer;

  display: flex;
  align-items: center;
  justify-content: center;

  box-shadow: 0 0 4px 0 #00000040;

  &:hover ${ChartTitle} {
    color: white;
  }

  &:hover ${Overlay} {
    background: ${overlayBackground};
  }

  &:hover ${FullOverlay} {
    background: ${overlayBackground};
  }
`

const ChartImage = styled.span`
  & > img {
    max-width: 100%;
  }
`
