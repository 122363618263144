import { useState, useEffect, useCallback } from 'react'
import { AxiosResponse } from 'axios'

type Props = {
  [s: string]: string
}

export const useApiCall = <T,>(
  fn: (p: any) => Promise<AxiosResponse<T>>,
  props?: Props | string | undefined | null,
  dependencies?: any[]
) => {
  const [data, setData] = useState<T | null>(null)
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  const fetch = useCallback(async () => {
    try {
      const response = await fn(props)
      setData(response.data)
    } catch (e: any) {
      setError(e)
    } finally {
      setLoading(false)
    }
  }, dependencies || [])

  useEffect(() => {
    fetch()
  }, dependencies || [])

  return { data, loading, error, fetch }
}
