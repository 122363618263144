import { ChartData } from 'chart.js'
import { numberFormatter } from 'utils/numberFormat'
import { BarChart, DataForChart } from '../types'
import 'chartjs-plugin-annotation'
import {
  getAdjustedDateRangeString,
  getGroupedChartValues,
  splitLabelForChart,
} from '../utils'
import { DateWindows, Filters } from '../../../types'
import { AppliedFilterOptionRange } from '../../filters/types'
import { findDateWindow } from '../../../utils'

function createDiagonalPattern(color = 'black') {
  // create a 10x10 px canvas for the pattern's base shape
  let shape = document.createElement('canvas')
  shape.width = 10
  shape.height = 10
  // get the context for drawing
  let c = shape.getContext('2d')
  // draw 1st line of the shape
  // @ts-ignore
  c.strokeStyle = color
  c?.beginPath()
  c?.moveTo(2, 0)
  c?.lineTo(10, 8)
  c?.stroke()
  // draw 2nd line of the shape
  c?.beginPath()
  c?.moveTo(0, 8)
  c?.lineTo(2, 10)
  c?.stroke()
  // create the pattern from the shape
  return c?.createPattern(shape, 'repeat')
}

export const configureBarChart = (
  currentChart: BarChart,
  filters: Filters,
  dateWindows: DateWindows,
  tier1: string,
  showCategories: boolean,
  hasLabels?: boolean,
  fullAxes?: boolean,
  isComparative?: boolean,
  startIndex?: number,
  limit?: number,
  wrapLabels?: boolean,
  maxAxesLength?: number,
  expandedCategories?: string[],
  order?: 'asc' | 'desc',
  sortedByCategory?: boolean,
  alignOrder?: boolean,
  comparativePanelsNumber?: number,
  hideXAxis?: boolean,
  hideYAxis?: boolean,
  prevChartData?: BarChart,
  prevFilters?: Filters,
  disableGrouping?: boolean,
  priorityItem?: string,
): DataForChart => {
  const endIndex =
    startIndex !== undefined && limit !== undefined
      ? startIndex + limit
      : undefined

  let values = currentChart.values
  let prevValues = prevChartData?.values

  if (comparativePanelsNumber === 1 || !alignOrder) {
    values = getGroupedChartValues(
      currentChart,
      expandedCategories ?? [],
      !!sortedByCategory,
      tier1,
      order ?? 'desc',
      showCategories,
      disableGrouping,
    )
    if (prevValues) {
      prevValues = getGroupedChartValues(
        prevChartData,
        expandedCategories ?? [],
        !!sortedByCategory,
        tier1,
        order ?? 'desc',
        showCategories,
        disableGrouping,
      )
    }
  }

  if (priorityItem) {
    const priorityIndex = values.findIndex(
      (item) => item.category === priorityItem,
    )
    const item = values.splice(priorityIndex, 1)[0]
    values.unshift(item)
    if (prevValues) {
      const prevPriorityIndex = prevValues.findIndex(
        (item) => item.category === priorityItem,
      )
      const prevItem = prevValues.splice(prevPriorityIndex, 1)[0]
      prevValues.unshift(prevItem)
    }
  }

  // if ((tier1 === 'Brand' || tier1 === 'Product') && !showCategories) {
  //   values = currentChart.values
  // }

  // getGroupedChartValues returns an ordered array of values
  // but we need it to match the order of the original values
  // this will also make sure the dataset is the same length and categories
  prevValues = values.reduce((acc: any[], item) => {
    const matchingPrevItem = prevValues?.find(
      (prevItem) => prevItem.category === item.category,
    )
    if (matchingPrevItem) {
      acc.push(matchingPrevItem)
    }
    return acc
  }, [])

  const emptyColors = values
    .slice(startIndex, endIndex)
    .map((v) => 'rgba(0,0,0,0)')
  const colors = values.slice(startIndex, endIndex).map((v) => v.color)
  const labels = values.slice(startIndex, endIndex).map((v) => v['category'])
  let yAxisValues = values
    .slice(startIndex, endIndex)
    .map((v) => (v.hidden ? null : v[currentChart.y_key]))
  let prevYAxisValues = prevValues
    ?.slice(startIndex, endIndex)
    .map((v) => (v.hidden ? null : v[currentChart.y_key]))
  const overlayValues = values
    .slice(startIndex, endIndex)
    .map((v) => v.overlay_value)
  const prevOverlayValues = prevValues
    ?.slice(startIndex, endIndex)
    .map((v) => v.overlay_value)
  const zAxisValues = values
    .slice(startIndex, endIndex)
    .map((v) => v[currentChart.z_key])
  const prevZAxisValues = prevValues
    ?.slice(startIndex, endIndex)
    .map((v) => v[currentChart.z_key])
  const additionalValues = values.slice(startIndex, endIndex).map((v) => ({
    ...v.hover,
    hidden: v.hidden,
  }))
  const prevAdditionalValues = prevValues
    ?.slice(startIndex, endIndex)
    .map((v) => ({
      ...v.hover,
      hidden: v.hidden,
    }))
  const legend = [
    ...currentChart.legend.map((legendItem) => ({
      color: legendItem.color,
      label: legendItem.category,
      disabled: legendItem.disabled,
    })),
  ]

  let minValue = 0
  values.forEach((item) => {
    minValue =
      item[currentChart.y_key] < minValue ? item[currentChart.y_key] : minValue
  })

  function areAllNegative(arr: number[]) {
    // Check if the array is empty
    if (arr.length === 0) {
      return false
    }

    // Check if all elements are negative
    return arr.every((num) => num < 0)
  }

  const maxAxesLabelLength = isComparative ? 15 : 30
  const hasZAxis = !!currentChart.z_key
  const getAxes = () => {
    const xAxes = {
      stacked: true,
      ticks: {
        callback: function (value: any) {
          value = (this as any).getLabelForValue(value)
          if (wrapLabels) {
            return splitLabelForChart(value, maxAxesLength || 30)
          }
          return fullAxes
            ? value
            : value?.length > maxAxesLabelLength
              ? value.substring(0, maxAxesLabelLength) + '...'
              : value
        },
        // TODO could do something where this is active across all panels if one has a negative value, but is 0 if there are no negative values /thinking
        // padding: 30,
        // autoSkip: false,
        // maxTicksLimit: limit,
      },
      // y axis label cause its rotated
      title: { display: !hideYAxis, text: currentChart.x_title },
    }

    const yAxes = {
      type: 'linear',
      display: true,
      position: 'left',
      gridLines: {
        display: true,
      },
      ticks: {
        callback: function (value: any) {
          if (typeof value === 'number') value = value.toFixed(2)
          return numberFormatter(value)
        },
        // autoSkip: false,
      },
      // todo this could be useful
      // min: yAxisValues.every((num) => num > 0) ? 0 : currentChart.min_value,
      // max: yAxisValues.every((num) => num < 0) ? 0 : currentChart.max_value,
      min: prevChartData
        ? Math.min(currentChart.min_value, prevChartData?.min_value)
        : currentChart.min_value,
      max: prevChartData
        ? Math.max(currentChart.max_value, prevChartData?.max_value)
        : currentChart.max_value,
      title: { display: !hideXAxis, text: currentChart.y_title },
    }
    const zAxes = {
      type: 'linear',
      display: hasZAxis,
      position: 'right',
      grid: { display: false },
      ticks: {
        stepSize: 0.2,
      },
      min: currentChart.z_title?.includes('Rating') ? 0 : -1,
      max: currentChart.z_title?.includes('Rating') ? 5 : 1,
      gridLines: {
        display: false,
      },
      title: { display: true, text: currentChart.z_title },
    }
    return (currentChart as BarChart).orientation === 'horizontal'
      ? [yAxes, xAxes, zAxes]
      : [xAxes, yAxes, zAxes]
  }

  const getAnnotations = () => {
    const annotations: any[] = []
    if (currentChart.shaded_zones) {
      currentChart.shaded_zones.forEach((item, index) =>
        annotations.push({
          type: 'box',
          drawTime: 'beforeDatasetsDraw',
          id: 'box_' + index,
          xMin:
            currentChart.orientation === 'horizontal'
              ? item.threshold.min
              : undefined,
          xMax:
            currentChart.orientation === 'horizontal'
              ? item.threshold.max
              : undefined,
          yMin:
            currentChart.orientation !== 'horizontal'
              ? item.threshold.min
              : undefined,
          yMax:
            currentChart.orientation !== 'horizontal'
              ? item.threshold.max
              : undefined,
          backgroundColor: item.color ?? 'rgba(175, 197, 222 ,0.5)',
          borderWidth: 0,
        }),
      )
    }
    if (currentChart.avg_value) {
      annotations.push({
        type: 'line',
        mode: 'vertical',
        drawTime: 'beforeDatasetsDraw',
        scaleId: 'test',
        value: currentChart.avg_value,
        endValue: currentChart.avg_value,
        xMin: currentChart.avg_value,
        xMax: currentChart.avg_value,
        borderColor: 'grey',
        borderWidth: 1,
        // label: {
        //   content: 'Average: ' + currentChart.avg_value,
        //   enabled: true,
        //   z: 100,
        //   position: 'end',
        // },
      })
    }
    return annotations
  }

  const data: ChartData = {
    labels: labels,
    datasets: [
      hasZAxis
        ? {
            label: currentChart.z_title,
            type: 'line',
            data: zAxisValues,
            borderColor: 'black',
            backgroundColor: 'black',
            borderWidth: 2,
            pointBackgroundColor: 'black',
            fill: false,
            order: 1,
            yAxisID: 'y1',
          }
        : { data: [] },
      {
        label: currentChart.y_title,
        backgroundColor: colors,
        data: yAxisValues,
        borderRadius: 10,
        order: 2,
        minBarLength: yAxisValues.includes(0) ? 5 : undefined,
        categoryPercentage: prevValues ? 0.7 : 0.8,
      },
      prevValues && prevYAxisValues
        ? {
            label: 'Previous ' + currentChart.y_title,
            backgroundColor: prevValues?.map(() => 'rgb(230, 230, 230)'),
            data: prevYAxisValues,
            borderRadius: 10,
            categoryPercentage: 1,
            order: 3,
            minBarLength: prevYAxisValues.includes(0) ? 5 : undefined,
            // @ts-ignore
            grouped: true,
            datalabels: {
              display: false,
            },
            // @ts-ignore
            custom: {
              min: (
                prevFilters?.values.find(
                  (el) => el.field === 'create_time',
                ) as AppliedFilterOptionRange
              )?.min,
              max: (
                prevFilters?.values.find(
                  (el) => el.field === 'create_time',
                ) as AppliedFilterOptionRange
              )?.max,
            },
          }
        : { data: [] },
      // { data: [] },
      // ...currentChart.legend.map((legendItem) => ({
      //   label: legendItem.category,
      //   backgroundColor: legendItem.color,
      //   data: [],
      // })),
    ],
  }

  const zeroCompensation = {
    renderZeroCompensation: function (chartInstance: any, d: any) {
      // get postion info from _view
      const view = d._view
      const context = chartInstance.chart.ctx

      // the view.x is the centeral point of the bar, so we need minus half width of the bar.
      const startX = view.x - view.width / 2
      // common canvas API, Check it out on MDN
      context.beginPath()
      // set line color, you can do more custom settings here.
      context.strokeStyle = '#aaaaaa'
      context.moveTo(startX, view.y)
      // draw the line!
      context.lineTo(startX + view.width, view.y)
      // bam！ you will see the lines.
      context.stroke()
    },

    afterDatasetsDraw: function (chart: any, easing: any) {
      // get data meta, we need the location info in _view property.
      const meta = chart.getDatasetMeta(0)
      // also you need get datasets to find which item is 0.
      const dataSet = chart.config.data.datasets[0].data
      meta.data.forEach((d: any, index: number) => {
        // for the item which value is 0, reander a line.
        if (dataSet[index] === 0) {
          this.renderZeroCompensation(chart, d)
        }
      })
    },
  }

  const options: any = {
    interaction: {
      mode: 'point',
    },
    plugins: {
      zeroCompensation,
      datalabels: {
        formatter: (value: number, data: any) => {
          return hasLabels ? numberFormatter(value) : null
        },
        anchor: (data: any) => {
          return areAllNegative(data.dataset.data) ? 'start' : 'end'
        },
        align: (data: any) => {
          return areAllNegative(data.dataset.data) ? 'left' : 'right'
        },
        offset: (data: any) => {
          return data.dataset.label === 'Overlay' ? -10 : 4
        },
        color: 'black',
        display: 'auto',
      },
      legend: false,
      tooltip: {
        // mode: 'y',
        // intersect: true,
        // position: 'nearest',
        enabled: true,
        displayColors: false,
        order: 1,
        callbacks: {
          title: function (data: any) {
            if (data[0]?.dataset?.label.includes('Previous')) {
              return `${data[0].label} - Previous`
            }
            return `${data[0].label}`
          },
          label: function (data: any) {
            return ''
          },
          afterBody: function (data: any) {
            let hoverValues: any = additionalValues
            let yValues: any = yAxisValues
            let overlayVals: any = overlayValues
            let zValues: any = zAxisValues
            if (data[0].dataset.label.includes('Previous')) {
              hoverValues = prevAdditionalValues
              yValues = prevYAxisValues
              overlayVals = prevOverlayValues
              zValues = prevZAxisValues
            }
            const index = data[0].dataIndex
            if (!index && index !== 0) return ''
            let base: any = []
            if (hoverValues[index].hidden) {
              return null
            }
            base = []
            if (prevChartData) {
              if (data[0]?.dataset?.label.includes('Previous')) {
                const dateFilter = prevFilters?.values.find(
                  (el) => el.field === 'create_time',
                )
                if (dateFilter) {
                  const prevPeriodText = () => {
                    const label = findDateWindow(
                      dateWindows,
                      (dateFilter as AppliedFilterOptionRange)?.min,
                      (dateFilter as AppliedFilterOptionRange)?.max,
                    )?.label

                    return label
                      ? `${label}`
                      : `${getAdjustedDateRangeString(
                          dateFilter as AppliedFilterOptionRange,
                          true,
                        )}`
                  }
                  base.push(prevPeriodText())
                }
              } else {
                const dateFilter = filters?.values.find(
                  (el) => el.field === 'create_time',
                )
                if (dateFilter) {
                  const prevPeriodText = () => {
                    const label = findDateWindow(
                      dateWindows,
                      (dateFilter as AppliedFilterOptionRange)?.min,
                      (dateFilter as AppliedFilterOptionRange)?.max,
                    )?.label

                    return label
                      ? `${label}`
                      : `${getAdjustedDateRangeString(
                          dateFilter as AppliedFilterOptionRange,
                          true,
                        )}`
                  }
                  base.push(prevPeriodText())
                }
              }
            }
            if (data[0].dataset.label === 'Overlay') {
              base.push([
                `${currentChart.y_title}: ${numberFormatter(
                  overlayVals[index],
                )}`,
              ])
            } else {
              base.push(
                `${currentChart.y_title}: ${numberFormatter(yValues[index])}`,
              )
            }
            if (currentChart.z_title) {
              base.push(
                `${currentChart.z_title}: ${numberFormatter(zValues[index])}`,
              )
            }

            return hoverValues[index] && !hoverValues[index].hidden
              ? [
                  ...base,
                  ...Object.keys(hoverValues[index]).map((key) => {
                    if (key !== 'hidden') {
                      return `${key}: ${numberFormatter(
                        // @ts-ignore
                        hoverValues[index][key],
                      )}`
                    }
                  }),
                ].filter((el) => !!el)
              : base
          },
        },
      },
      annotation: {
        annotations: getAnnotations(),
      },
    },
    animation: {
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    indexAxis:
      (currentChart as BarChart).orientation === 'horizontal' ? 'y' : 'x',
    layout: { padding: { right: 40 } },

    maintainAspectRatio: false,
    responsive: true,
    resizeDelay: 0,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    scales: {
      x: getAxes()[0],
      y: getAxes()[1],
      y1: getAxes()[2],
    },
  }

  return {
    data,
    options,
    type: 'bar',
    legend,
    height: 600,
  }
}
