import { initialState, useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { FilterOptionSelect } from '../features/filters/types'
import mixpanel from '../../trackers/mixpanel'
import _ from 'lodash'
import { emptyFilters, getCurrentRouteName } from '../utils'
import { getBookmarkUrlPost } from '../../url-params/model'
import useThemes from './useThemes'

const usePDP = () => {
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList
  )
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const { getClusterUUIDsFromThemeName } = useThemes()

  const handleKeywordClick = async (keyword: string) => {
    mixpanel.track('pdp insights', {
      action: 'open keyword',
      value: keyword,
      proj_uuid: projectId,
    })
    // setShowFetcher(true)
    document.body.style.cursor = 'wait'
    const pageControls = JSON.parse(
      JSON.stringify(initialState.keywordsControls)
    )
    pageControls[0].searchTerm = keyword

    const filters = _.cloneDeep(emptyFilters)

    // TODO if we want more restrictive filters, this be them
    // filters.values.push({ field: 'source_type', values: ['Amazon'] })

    // if (defaultFilterList.find((el) => el.field === 'product')) {
    //   const product_item = new URLSearchParams(window.location.search).get(
    //     'item'
    //   )
    //   if (product_item) {
    //     filters.values.push({
    //       field: 'product',
    //       values: [product_item],
    //     })
    //   }
    // } else {
    const product = new URLSearchParams(window.location.search).get('product')
    if (product) {
      filters.values.push({
        field: 'product_hierarchy',
        values: [product],
      })
    }
    // }

    const body = {
      filters: [filters],
      pageControls,
    }
    try {
      const filterHash = await getBookmarkUrlPost(projectId, {
        page: getCurrentRouteName(window.location.pathname),
        filterString: JSON.stringify(body),
      })
      const url = `${window.location.origin}/project/${projectId}/keywords?filters=${filterHash}`
      window.open(url, '')
      // setShowFetcher(false)
      document.body.style.cursor = ''
    } catch (error) {
      // setShowFetcher(false)
      document.body.style.cursor = ''
      console.log(error)
    }
  }

  const handleThemeClick = async (theme: string) => {
    mixpanel.track('pdp insights', {
      action: 'open theme',
      value: theme,
      proj_uuid: projectId,
    })
    // setShowFetcher(true)
    document.body.style.cursor = 'wait'
    const pageControls = JSON.parse(
      JSON.stringify(initialState.dashboardControls)
    )
    pageControls[0].tier1 = 'Theme'
    pageControls[0].chartId = '9_4_17_sentiment'
    pageControls[0].multiChart = true

    const filters = _.cloneDeep(emptyFilters)

    // filters.values.push({ field: 'source_type', values: ['Amazon'] })

    const matchingTheme = (
      defaultFilterList.find((el) => el.field === 'theme') as FilterOptionSelect
    )?.values.find((el) => el.value === theme)
    if (matchingTheme) {
      filters.values.push({
        field: 'theme',
        values: getClusterUUIDsFromThemeName(
          theme.split(': ')[0],
          theme.split(': ')[1]
        ),
        // values: [matchingTheme.cluster_uuid ?? matchingTheme.value],
      })
    }

    // if (defaultFilterList.find((el) => el.field === 'product')) {
    //   const product_item = new URLSearchParams(window.location.search).get(
    //     'item'
    //   )
    //   if (product_item) {
    //     filters.values.push({
    //       field: 'product',
    //       values: [product_item],
    //     })
    //   }
    // } else {
    const product = new URLSearchParams(window.location.search).get('product')
    if (product) {
      filters.values.push({
        field: 'product_hierarchy',
        values: [product],
      })
    }
    // }

    const body = {
      filters: [filters],
      pageControls,
    }

    try {
      const filterHash = await getBookmarkUrlPost(projectId, {
        page: getCurrentRouteName(window.location.pathname),
        filterString: JSON.stringify(body),
      })
      const url = `${window.location.origin}/project/${projectId}/dashboard?filters=${filterHash}`
      window.open(url, '')
      // setShowFetcher(false)
      document.body.style.cursor = ''
    } catch (error) {
      // setShowFetcher(false)
      document.body.style.cursor = ''
      console.log(error)
    }
  }

  return {
    handleKeywordClick,
    handleThemeClick,
  }
}

export default usePDP
