import React from 'react'

const SettingsSvgIcon: React.FC = () => (
  <svg
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.1169 9.33229C14.1512 9.06667 14.1683 8.79219 14.1683 8.5C14.1683 8.21667 14.1512 7.93333 14.1084 7.66771L15.8475 6.26875C16.0017 6.14479 16.0445 5.90573 15.9503 5.72865L14.3054 2.78906C14.2026 2.59427 13.9884 2.53229 13.8 2.59427L11.7524 3.44427C11.3241 3.10781 10.87 2.82448 10.3645 2.61198L10.0561 0.363021C10.0218 0.150521 9.8505 0 9.64489 0H6.35511C6.1495 0 5.98672 0.150521 5.95245 0.363021L5.64404 2.61198C5.13857 2.82448 4.67595 3.11667 4.25616 3.44427L2.20861 2.59427C2.02013 2.52344 1.80595 2.59427 1.70315 2.78906L0.066824 5.72865C-0.0359817 5.91458 -0.00171323 6.14479 0.16963 6.26875L1.90876 7.66771C1.86592 7.93333 1.83166 8.22552 1.83166 8.5C1.83166 8.77448 1.84879 9.06667 1.89163 9.33229L0.152495 10.7312C-0.00171322 10.8552 -0.0445488 11.0943 0.0496898 11.2714L1.69458 14.2109C1.79739 14.4057 2.01157 14.4677 2.20004 14.4057L4.24759 13.5557C4.67595 13.8922 5.13001 14.1755 5.63547 14.388L5.94389 16.637C5.98672 16.8495 6.1495 17 6.35511 17H9.64489C9.8505 17 10.0218 16.8495 10.0475 16.637L10.356 14.388C10.8614 14.1755 11.3241 13.8922 11.7438 13.5557L13.7914 14.4057C13.9799 14.4766 14.194 14.4057 14.2969 14.2109L15.9417 11.2714C16.0445 11.0766 16.0017 10.8552 15.8389 10.7312L14.1169 9.33229ZM8 11.6875C6.30371 11.6875 4.91583 10.2531 4.91583 8.5C4.91583 6.74687 6.30371 5.3125 8 5.3125C9.69629 5.3125 11.0842 6.74687 11.0842 8.5C11.0842 10.2531 9.69629 11.6875 8 11.6875Z"
      fill="currentColor"
    />
  </svg>
)

export default SettingsSvgIcon
