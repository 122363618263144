import { ProjectStatuses } from './types'

export const isInitialFlow = (status: string | undefined) => {
  /*
    When entering project, the Sources tab should be default entered when initial flow.
    Hide filter, search, date components if initial flow.
    Don't request filter and filter options APIs if initial flow.
    Hide certain menu tabs if initial flow.
  */
  return [
    String(ProjectStatuses.CREATED),
    String(ProjectStatuses.PREPROCESSING),
    String(ProjectStatuses.PREPROCESSING_COMPLETE),
    String(ProjectStatuses.NETWORK_PROCESSING),
    String(ProjectStatuses.NETWORK_COMPLETE),
    String(ProjectStatuses.ERROR),
    undefined,
  ].includes(status)
}
