import React from 'react'
import styled from 'styled-components'
import HomeSvgIcon from 'features/project/partials/ProjectLayout/components/Navigation/components/HomeSvgIcon/HomeSvgIcon'

type Props = {
  SvgComponent: React.FC<React.SVGProps<SVGSVGElement>>
  width?: number
  height?: number
  defaultColor?: string
  hoverColor?: string
}

const GenericSvgIcon: React.FC<Props> = ({ SvgComponent }) => {
  if (!SvgComponent) {
    console.warn('No SVG component provided to GenericSvgIcon')
    SvgComponent = HomeSvgIcon
  }

  return (
    <Container className="svg-icon-wrapper">
      <SvgComponent width="100%" height="100%" />
    </Container>
  )
}

export default GenericSvgIcon

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
