import { SummaryResponse } from 'features/project/features/Charts/types'
import { axiosInstance } from '../../../../utils/axios'
import { PDP_ANALYSIS_URL } from '../../../../utils/apiLinks'

type PDPObject = {
  features: string[]
  description: string
  listing_title: string
}

export type Keyword = {
  keyword: string
  sentiment: number
  sentences: number
  color: string
}

type Theme = {
  theme: string
  sentiment: number
  sentences: number
  volume_pct: number
  color: string
}

type ItemPDPAnalysisResponseObj = {
  temp1: ItemPDPAnalysisResponse
  temp2: ItemPDPAnalysisResponse
  temp3: ItemPDPAnalysisResponse
}

export type ItemPDPAnalysisResponse = {
  before: PDPObject
  after: PDPObject
  explanation: {
    title_optimization_explanation: string
    features_optimization_explanation: string
    description_optimization_explanation: string
    keywords_used: string[]
  }
  keywords: Keyword[]
  themes: Theme[]
}
export const getItemPDPAnalysis = (
  proj_uuid: string,
  item_name: string,
  user_keywords: string[],
  url: string | null,
): Promise<ItemPDPAnalysisResponseObj> => {
  return axiosInstance
    .post(
      PDP_ANALYSIS_URL,
      {
        item_name,
        temperature: [1, 2, 3],
        user_keywords,
        url,
      },
      { params: { proj_uuid } },
    )
    .then(({ data }) => data)
}
