import React, { CSSProperties } from 'react'
import { buttonBlue, buttonHover } from 'assets/styles/variables'
import { CopyOutlined } from '@ant-design/icons'
import styled from 'styled-components'

type Props = {
  copyFn: () => void
  style?: CSSProperties
}

export const CopyChartButton: React.FC<Props> = ({ copyFn, style }) => {
  return (
    <Container style={style} onClick={copyFn}>
      <CopyOutlined />
      <Text>copy</Text>
    </Container>
  )
}

export const Container = styled.div`
    display: flex;
    gap: 5px;
    margin-right: 10px;
    cursor: pointer;
    color: ${buttonBlue};
    &:hover {
        color: ${buttonHover};
    }
}
`

const Text = styled.div`
  font-size: var(--font-size-md);
`
