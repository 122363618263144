import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import useResizeObserver from 'use-resize-observer'
import { Tooltip, Typography } from 'antd'
import {
  BarChartOutlined,
  ChatOutlined,
  StarOutlineOutlined,
} from '@mui/icons-material'
import { YogiButton } from '../../../../components/UI/YogiButton'
import {
  secondaryGreen,
  secondaryRed,
  starYellow,
} from '../../../../assets/styles/variables'
import { HeartOutlined } from '@ant-design/icons'
import { Link } from 'react-router-dom'
import _ from 'lodash'
import { markUnread, NotificationsResponse } from './model'
import { toast } from 'react-toastify'
import { CHARTS_URL, DASHBOARD_URL } from '../../utils'
import mixpanel from '../../../trackers/mixpanel'
import { useSavedViews } from '../saved-vews/utils'
import { SavedView } from '../saved-vews/types'
import notificationsEmpty from '../../../../assets/images/Notifications-Empty.png'
import { EmptyWrapper } from '../summary/components/theme'
import { useClickOutside } from '../../../../shared/hooks'
import pluralize from 'pluralize'

const { Title } = Typography

const iconMap: any = {
  'Star Rating': <StarOutlineOutlined style={{ color: starYellow }} />,
  Sentiment: <HeartOutlined style={{ color: secondaryGreen, fontSize: 18 }} />,
  Volume: <ChatOutlined fontSize={'medium'} style={{ padding: '1px' }} />,
}
// const pluralize = require('pluralize')

type Props = {
  useNotifications: any
}
export const Notifications: React.FC<Props> = ({ useNotifications }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const notificationsIsOpen = useProjectStore(
    (state: ProjectState) => state.notificationsIsOpen,
  )
  const setNotificationsIsOpen = useProjectStore(
    (state: ProjectState) => state.setNotificationsIsOpen,
  )

  const { updateFilters } = useSavedViews()

  const { notifications, refetch, markAsViewed } = useNotifications()

  const [sortedNotifications, setSortedNotifications] = useState<
    NotificationsResponse[]
  >([])

  useEffect(() => {
    if (notifications) {
      const sorted = _.orderBy(notifications, ['created_at'], ['desc'])
      setSortedNotifications(sorted)
    }
  }, [notifications])

  const ref = useRef<HTMLDivElement>(null)

  const { ref: headerRef, height: headerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()

  function daysInPast(dateString: string) {
    const date1 = new Date(dateString)
    const date2 = new Date()
    const diffTime = Math.abs(date2.getTime() - date1.getTime())
    return Math.floor(diffTime / (1000 * 60 * 60 * 24))
  }

  useClickOutside(ref, () => {
    if (!notificationsIsOpen) return
    setNotificationsIsOpen(false)
  })

  return (
    <Drawer ref={ref} isOpen={notificationsIsOpen}>
      <Wrapper>
        <Header ref={headerRef}>
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'flex-start',
            }}
          >
            <Title level={4}>Notifications</Title>
            <Title
              level={4}
              onClick={() => setNotificationsIsOpen(false)}
              style={{ fontWeight: 600, cursor: 'pointer', marginTop: -20 }}
            >
              X
            </Title>
          </div>
        </Header>
        <Body headerHeight={headerHeight}>
          <NotificationList>
            {!sortedNotifications?.length && (
              <EmptyWrapper
                style={{ width: '100%', marginBottom: '20px', paddingTop: 100 }}
              >
                <EmptyImage src={notificationsEmpty} />
                <EmptyMessage>No notifications...yet!</EmptyMessage>
              </EmptyWrapper>
            )}
            {sortedNotifications?.map((notification) => (
              <Notification
                key={notification.id}
                to={
                  notification.model_name === 'CUSTOM_DASHBOARD'
                    ? `/project/${projectId}/${DASHBOARD_URL}?dashboard_id=${notification.model_id}`
                    : `/project/${projectId}/${notification.notification_type
                        .split('_')?.[0]
                        .toLowerCase()}s`
                }
                isActive={!notification.acknowledged_at}
                onClick={() => {
                  mixpanel.track('notifications', {
                    action: 'open',
                    value: notification.id,
                    proj_uuid: projectId,
                  })
                  if (
                    !notification.acknowledged_at &&
                    (notification.notification_type === 'ALERT_INVITED' ||
                      notification.model_name === 'CUSTOM_DASHBOARD')
                  ) {
                    markAsViewed(notification.id)
                  } else {
                    markUnread(projectId, notification.id)
                  }
                  setNotificationsIsOpen(false)
                }}
              >
                <Left>
                  <StarOutlineOutlined />
                  <NotificationContent>
                    <NotificationTitle
                      dangerouslySetInnerHTML={{
                        __html: notification.body_html,
                      }}
                    ></NotificationTitle>
                    <NotificationTime>{notification.info}</NotificationTime>
                    <NotificationTime>
                      {new Date(notification.created_at).toLocaleDateString()}{' '}
                      <i>
                        {daysInPast(notification.created_at)
                          ? `${daysInPast(notification.created_at)} ${pluralize(
                              'day',
                              daysInPast(notification.created_at),
                            )} ago`
                          : 'Today'}
                      </i>
                    </NotificationTime>
                  </NotificationContent>
                </Left>

                {notification.notification_type === 'ALERT_TRIGGERED' && (
                  <Tooltip title={'View in Charts'} placement={'topLeft'}>
                    <StyledLink
                      to={`/project/${projectId}/${CHARTS_URL}${notification.link}`}
                      target={'_blank'}
                      onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        mixpanel.track('notifications', {
                          action: 'open link',
                          value: notification.id,
                          proj_uuid: projectId,
                        })
                        setNotificationsIsOpen(false)
                        if (!notification.acknowledged_at) {
                          markAsViewed(notification.id)
                        } else {
                          markUnread(projectId, notification.id)
                            .then(() => refetch())
                            .catch((err) => {
                              console.log(err)
                              toast.error('Error marking notification as read')
                            })
                        }
                        updateFilters({
                          page: 'dashboard',
                          search_url: notification.link,
                        } as SavedView)
                        // history.push(
                        //   `/project/${projectId}/${DASHBOARD_URL}${notification.link}`
                        // )
                      }}
                    >
                      <YogiButton
                        icon={<BarChartOutlined fontSize={'small'} />}
                        style={{
                          display: 'flex',
                          alignItems: 'center',
                          justifyContent: 'center',
                          flexShrink: 0,
                        }}
                      />
                    </StyledLink>
                  </Tooltip>
                )}
              </Notification>
            ))}
          </NotificationList>
        </Body>
      </Wrapper>
    </Drawer>
  )
}

const Drawer = styled.div<{ isOpen: boolean }>`
  position: absolute;
  z-index: 100;
  height: 100vh;
  width: 50%;
  background: white;
  right: 0px;
  top: 0px;
  //margin-top: -70px;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
  box-shadow: ${(props) =>
    props.isOpen
      ? '0px 0px 100px -20px rgba(50, 50, 93, 0.25), -10px 0px 60px -30px rgba(0, 0, 0, 0.3)'
      : 'none'};
  transform: ${(props) =>
    props.isOpen ? 'translateX(0%)' : 'translateX(100%)'};
  transition: 0.25s ease-in-out;
`

const Wrapper = styled.div`
  position: relative;
  background: white;
  border-top-left-radius: 16px;
  border-bottom-left-radius: 16px;
`
const Header = styled.div`
  padding: var(--default-padding) var(--default-padding) 5px;
`

const Body = styled.div<{ headerHeight: number }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(100vh - ${(props) => props.headerHeight + 50}px);
`

const NotificationList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding: 10px 20px 0px;
`

const Notification = styled(Link)<{ isActive?: boolean }>`
  color: var(--main-text);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  padding: 10px;
  cursor: pointer;
  background: ${(props) => (props.isActive ? '#FFF2F2' : '#f4f4f4')};
  border-style: solid;
  border-width: 1px;
  border-color: ${(props) => (props.isActive ? secondaryRed : 'transparent')};
  border-radius: 8px;
  :hover {
    color: var(--main-text);
    text-decoration: none;
  }
`

const Left = styled.div`
  color: var(--main-text);
  display: flex;
  align-items: center;
  gap: 10px;
`

const NotificationContent = styled.div``
const NotificationTitle = styled.div``
const NotificationTime = styled.div`
  font-size: 12px;
  color: #8a8c8f;
`

const StyledLink = styled(Link)`
  display: flex;
  align-items: center;
  //width: 100%;
  justify-content: space-between;
  &:hover {
    //text-decoration: none;
  }
`
const EmptyImage = styled.img`
  max-width: 40%;
  margin-bottom: 20px;
`
const EmptyMessage = styled.div`
  text-align: center;
  font-style: italic;
  font-weight: 500;
  font-size: 14px;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #9fb7c3;
`
