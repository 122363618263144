import { useEffect } from 'react'
import mixpanel from '../../trackers/mixpanel'
import { useLocation } from 'react-router-dom'
import { useFeatureFlags } from './useFeatureFlags'

const useMixpanel = () => {
  const location = useLocation()
  const { data } = useFeatureFlags()
  const searchParams = new URLSearchParams(location.search)

  // the idea with this is that - when coming from a share link - the info will be registered to the user for the length of the session, until the project is reaccessed (refresh or new session)
  // if the project is entered without the share data, the info will be unregistered so the next events won't have share data associated
  useEffect(() => {
    if (searchParams.get('share_type')) {
      mixpanel.register({ shared_session: true })
      mixpanel.track('share_link', {
        url: window.location.href,
        share_type: searchParams.get('share_type'),
        share_user: searchParams.get('share_user'),
      })
    } else if (searchParams.get('utm_source')) {
      mixpanel.register({ shared_session: true })
      mixpanel.track('share_link', {
        url: window.location.href,
        share_type: searchParams.get('utm_source'),
        share_user: searchParams.get('utm_source'),
      })
    } else {
      mixpanel.register({ shared_session: false })
    }
  }, [])

  useEffect(() => {
    if (data) {
      mixpanel.register({ is_training: !!data?.is_training })
    }
  }, [data])

  return {}
}

export default useMixpanel
