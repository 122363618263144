import React from 'react'
import styled from 'styled-components'

type Props = {
  children: React.ReactNode
  onClick: () => void
  isSelected: boolean
  isApplied: boolean
  id: string
  disabled?: boolean
}

export const FilterPanelListItem: React.FC<Props> = React.memo(
  ({ children, onClick, isSelected, isApplied, id, disabled }) => {
    //   const { attributes, listeners, setNodeRef, transform, transition } =
    //     useSortable({ id: id })

    return (
      <Item
        onClick={onClick}
        // ref={setNodeRef}
        id={id}
        selected={isSelected}
        applied={isApplied}
        // sort_transform={transform}
        // transition={transition}
        // {...attributes}
        // {...listeners}
        disabled={!!disabled}
      >
        {children}
      </Item>
    )
  },
)

const Item = styled.div<{
  selected: boolean
  applied: boolean
  disabled: boolean
}>`
  cursor: pointer;
  user-select: none;
  padding: 12px 20px 12px 24px;
  background: ${({ selected }) => (selected ? '#e7edf0' : '#fff')};
  font-weight: ${({ applied }) => (applied ? '600' : '400')};
  font-size: var(--font-size-md);
  border-bottom: 1px solid #e7edf0;
  display: flex;
  justify-content: space-between;
  opacity: ${({ disabled }) => (disabled ? '.5' : '')};

  &:hover {
    background: #e7edf0;
  }
`
