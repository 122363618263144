import { useState, useEffect } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import styled from 'styled-components'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import queryString from 'query-string'
import { Form as AntdForm } from 'antd'
import { AuthWrapper } from 'shared/wrappers'
import { Loader } from 'shared/components'
import { toast } from 'react-toastify'
import { signinURL } from 'utils/links'
import { resetPassword, signIn } from '../model'
import { InputPassword } from '../components/input'
import { Button } from '../components/button'
import { FormItem } from '../components/form_item'
import { ServerValidationError } from '../components/server_validation_error'
import { useAuthContext } from 'features/auth'

const layout = {
  layout: 'vertical',
}

const NewPasswordSchema = Yup.object().shape({
  password: Yup.string()
    .required('Required')
    .min(8, 'Password must be at least 8 characters in length')
    .test(
      'isValidPass',
      'Password does not meet complexity requirements',
      (value) => {
        const hasUpperCase = /[A-Z]/.test(value)
        const hasLowerCase = /[a-z]/.test(value)
        const hasNumber = /[0-9]/.test(value)
        const hasSymbol = /[^a-zA-Z\d\s:]/.test(value)
        let validConditions = 0
        const numberOfMustBeValidConditions = 4
        const conditions = [hasLowerCase, hasUpperCase, hasNumber, hasSymbol]
        conditions.forEach((condition) =>
          condition ? validConditions++ : null,
        )
        if (validConditions >= numberOfMustBeValidConditions) {
          return true
        }
        return false
      },
    ),
  confirm_password: Yup.string()
    .required('Required')
    .oneOf([Yup.ref('password'), null], 'Passwords do not match'),
  code: Yup.string().required('Required'),
  email: Yup.string().email().required('Required'),
})

export const NewPasswordForm = () => {
  const authContext = useAuthContext()
  const [serverValidationError, setServerValidationError] = useState('')
  const history = useHistory()
  const location = useLocation()
  const formik = useFormik({
    initialValues: { code: '', email: '', password: '', confirm_password: '' },
    validationSchema: NewPasswordSchema,
    onSubmit: async ({ code, password, email }) => {
      try {
        await resetPassword(email, code, password)
        const user = await signIn(email, password)

        authContext.signIn(user)
        toast.info('Success')
        history.push(signinURL)
      } catch (e) {
        setServerValidationError(e.message)
      }
    },
  })
  const passwordError = formik.touched.password && formik.errors.password
  const confirmPasswordError =
    formik.touched.confirm_password && formik.errors.confirm_password
  const queryParams = queryString.parse(location.search)

  useEffect(() => {
    if (queryParams.data && queryParams.code) {
      const data = JSON.parse(atob(queryParams.data))

      formik.setFieldValue('code', queryParams.code)
      formik.setFieldValue('email', data.email)
    } else {
      history.push(signinURL)
    }
  }, [])

  const onChange = (e) => {
    setServerValidationError('')
    formik.handleChange(e)
  }

  return (
    <AuthWrapper>
      <Wrapper>
        <Form onFinish={formik.submitForm} {...layout}>
          <FormTitle>Enter New Password</FormTitle>
          <StyledFormItem
            label="New password"
            validateStatus={passwordError && 'error'}
            help={passwordError}
          >
            <InputPassword
              name="password"
              placeholder="*******"
              onChange={onChange}
              value={formik.values.password}
            />
          </StyledFormItem>
          <StyledFormItem
            label="Repeat Password"
            validateStatus={confirmPasswordError && 'error'}
            help={confirmPasswordError}
            style={{ marginBottom: '9px' }}
          >
            <InputPassword
              name="confirm_password"
              placeholder="*******"
              onChange={onChange}
              value={formik.values.confirm_password}
            />
          </StyledFormItem>
          <HelpMessage isError={passwordError}>
            Password must:
            <ul>
              <li>
                Be at least <b>8 characters</b> in length
              </li>
              <li>
                Include at least <b>one of each</b> of the following:
                <ul>
                  <li>Capital Letter</li>
                  <li>Lowercase Letter</li>
                  <li>Number</li>
                  <li>Special Character</li>
                </ul>
              </li>
            </ul>
          </HelpMessage>
          {serverValidationError && (
            <ServerValidationError message={serverValidationError} />
          )}

          <StyledButton type="primary" htmlType="submit">
            Reset & Login
          </StyledButton>
        </Form>
        {formik.isSubmitting && (
          <Overlay>
            <Loader />
          </Overlay>
        )}
      </Wrapper>
    </AuthWrapper>
  )
}

export default NewPasswordForm

const Wrapper = styled.div`
  display: flex;
  flex-flow: column nowrap;
  width: 100%;
  color: var(--color-auth-grey);
  background: #fff;
`

const Form = styled(AntdForm)`
  position: relative;
  max-width: 410px;
  width: 100%;
  margin: 0 auto;
  padding: 35px 45px;

  color: var(--color-auth-grey);
  border: 0.5px solid rgba(156, 168, 187, 0.5);
  border-radius: 6px;

  @media (max-width: 768px) {
    padding: 35px;
    border: none;
  }
`

const StyledFormItem = styled(FormItem)`
  margin-bottom: 20px;
`

const FormTitle = styled.div`
  margin-bottom: 38px;

  text-align: center;
  font-weight: 500;
  font-size: 20px;
  line-height: 27px;
`

const HelpMessage = styled.div`
  margin-bottom: 10px;

  color: ${(props) => (props.isError ? '#ff0033;' : 'inherit')};
  font-weight: 300;
  font-size: 12px;
  line-height: 16px;
`

const StyledButton = styled(Button)`
  margin-top: 30px;
`

const Overlay = styled.div`
  background: #fff;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
`
