import { ChartData, ChartDataset } from 'chart.js'
import { LineChart, DataForChart, BarChart } from '../types'
import { uniq } from 'lodash'
import 'chartjs-plugin-annotation'
import { numberFormatter } from 'utils/numberFormat'
import {
  getComparedTimePeriod,
  getGroupedChartValues2,
  sortLegend,
} from '../utils'
import 'chartjs-adapter-moment'

type ConfigType = {
  currentChart: LineChart
  tier1: string
  showCategories: boolean
  hasLabels?: boolean
  shadedZones?: boolean
  trendlines?: boolean
  fullAxes?: boolean
  isMultiChart?: boolean
  expandedCategories?: string[]
  hideXAxis?: boolean
  hideYAxis?: boolean
  prevChart?: LineChart
  comparePeriod?: string
  timeInterval?: string
  prevDateIsBeforeFirstReview?: boolean
  disableGrouping?: boolean
}

export const configureLineChart = ({
  currentChart,
  tier1,
  showCategories,
  hasLabels = true,
  shadedZones = true,
  trendlines = true,
  fullAxes = true,
  isMultiChart = false,
  expandedCategories = [],
  hideXAxis = false,
  hideYAxis = false,
  prevChart,
  comparePeriod,
  timeInterval,
  prevDateIsBeforeFirstReview,
  disableGrouping,
}: ConfigType): DataForChart => {
  const datasetObject = new Map()
  const prevDatasetObject = new Map()
  const datasets: ChartDataset[] = []
  const prevDatasets: ChartDataset[] = []
  const legend: DataForChart['legend'] = []
  const hasZAxis = !!currentChart.z_key

  let values = getGroupedChartValues2(
    currentChart,
    expandedCategories,
    tier1,
    showCategories,
    disableGrouping,
  )

  values.forEach((v) => {
    const name = v[currentChart.legend_key]
    if (name && !datasetObject.has(name)) {
      datasetObject.set(name, {
        data: [],
        dataZ: [],
        label: name,
        color: v.color,
        z_value_color: v.z_value_color,
      })
    }
  })

  const labels = uniq(currentChart.values.map((v) => v[currentChart.x_key]))

  labels.forEach((label) => {
    datasetObject.forEach((value, key) => {
      const foundElement = values.find(
        (v) =>
          v[currentChart.legend_key] === key && label === v[currentChart.x_key],
      )
      value.dataZ.push(foundElement && foundElement[currentChart.z_key])
      value.data.push(foundElement && foundElement[currentChart.y_key])
    })
  })
  datasetObject.forEach((value, key) => {
    datasets.push({
      label: key,
      data: value.data,
      borderColor: value.color,
      backgroundColor: value.color
        .replace(')', ', 0.25)')
        .replace('rgb', 'rgba'),
      fill: currentChart.area,
      tension: 0.4,
    })
    // if (currentChart.category_mapping && currentChart.category_mapping[key]) {
    legend.push({
      color: value.color,
      label: key,
      disabled: expandedCategories?.includes(key) ?? false,
      children:
        currentChart?.category_mapping &&
        currentChart?.category_mapping[key]?.map((item) => ({
          color:
            currentChart.values.find((el) => el.category === item)?.color ??
            value.color,
          label: item.split(': ')[1] ?? item,
          disabled: false,
        })),
    })
    if (hasZAxis && isMultiChart) {
      let vol_label = key === 'NPS' ? 'Review Volume' : key + ' Volume'
      datasets.push({
        label: key,
        type: 'line',
        data: value.dataZ,
        borderColor: value.z_value_color.value || value.color,
        backgroundColor:
          value.z_value_color?.value
            ?.replace(')', ', 0.25)')
            .replace('rgb', 'rgba') ||
          value.color?.replace(')', ', 0.25)').replace('rgb', 'rgba'),
        borderWidth: 2,
        pointBackgroundColor: value?.z_value_color.value || value.color,
        fill: true,
        order: 1,
        yAxisID: 'y1',
        tension: 0.4,
      })
      // legend.push({
      //   color: value.z_value_color.value || value.color,
      //   label: vol_label,
      //   disabled: false,
      // })
    }
  })

  if (prevChart) {
    const prevValues = getGroupedChartValues2(
      prevChart,
      expandedCategories,
      tier1,
      showCategories,
      disableGrouping,
    )
    prevValues.forEach((v) => {
      const name = v[currentChart.legend_key]
      if (name && !prevDatasetObject.has(name)) {
        prevDatasetObject.set(name, {
          data: [],
          dataZ: [],
          label: name,
          color: v.color,
          z_value_color: v.z_value_color,
        })
      }
    })
    const prevLabels = uniq(prevChart?.values.map((v) => v[prevChart.x_key]))
    prevLabels.forEach((label) => {
      prevDatasetObject.forEach((value, key) => {
        const foundElement = prevValues.find(
          (v) =>
            v[prevChart.legend_key] === key && label === v[prevChart.x_key],
        )
        value.dataZ.push(foundElement && foundElement[prevChart.z_key])
        value.data.push(foundElement && foundElement[prevChart.y_key])
      })
    })
    prevDatasetObject.forEach((value, key) => {
      prevDatasets.push({
        // label: (Number(key.split("'")[1]) - 1)?.toString() ?? key,
        label: key,
        data: value.data,
        borderColor: value.color,
        backgroundColor: value.color
          .replace(')', ', 0.25)')
          .replace('rgb', 'rgba'),
        fill: currentChart.area,
        borderDash: [5, 5],
        tension: 0.4,
      })
      // if (currentChart.category_mapping && currentChart.category_mapping[key]) {
      // legend.push({
      //   color: value.color,
      //   label: key,
      //   disabled: expandedCategories?.includes(key) ?? false,
      //   children:
      //     currentChart?.category_mapping &&
      //     currentChart?.category_mapping[key]?.map((item) => ({
      //       color:
      //         currentChart.values.find((el) => el.category === item)?.color ??
      //         value.color,
      //       label: item.split(': ')[1] ?? item,
      //       disabled: false,
      //     })),
      // })
      if (hasZAxis && isMultiChart) {
        let vol_label = key === 'NPS' ? 'Review Volume' : key + ' Volume'
        prevDatasets.push({
          label: key,
          type: 'line',
          data: value.dataZ,
          borderColor: value.z_value_color.value || value.color,
          backgroundColor:
            value.z_value_color?.value
              ?.replace(')', ', 0.25)')
              .replace('rgb', 'rgba') ||
            value.color?.replace(')', ', 0.25)').replace('rgb', 'rgba'),
          borderWidth: 2,
          pointBackgroundColor: value?.z_value_color.value || value.color,
          fill: true,
          order: 1,
          yAxisID: 'y1',
          borderDash: [5, 5],
          tension: 0.4,
        })
        // legend.push({
        //   color: value.z_value_color.value || value.color,
        //   label: vol_label,
        //   disabled: false,
        // })
      }
    })
  }

  if (prevDateIsBeforeFirstReview) {
    const maxCurDatasetLength = Math.max(
      ...datasets.map((el) => el.data.length),
    )
    const maxPrevDatasetLength = Math.max(
      ...prevDatasets.map((el) => el.data.length),
    )
    prevDatasets.forEach((dataset) => {
      dataset.data = [
        ...Array(maxCurDatasetLength - maxPrevDatasetLength).fill(undefined),
        ...dataset.data,
      ]
    })
  }

  const getAnnotations = () => {
    const annotations: any[] = []
    if (currentChart.shaded_zones && shadedZones) {
      currentChart.shaded_zones.forEach((item, index) =>
        annotations.push({
          type: 'box',
          drawTime: 'beforeDatasetsDraw',
          id: 'box_' + index,
          yMax: item.threshold.min ?? -1,
          yMin: item.threshold.max ?? 1,
          backgroundColor: item.color ?? 'rgba(175, 197, 222 ,0.5)',
          borderWidth: 0,
        }),
      )
    }
    if (currentChart.lobf && trendlines) {
      currentChart.lobf.forEach((item, index) => {
        annotations.push({
          type: 'line',
          drawTime: 'beforeDatasetsDraw',
          id: 'lobf' + index,
          xMin: item.left.x_value,
          yMin: item.left.y_value,
          xMax: item.right.x_value,
          yMax: item.right.y_value,
          borderWidth: 2,
          borderColor: item.color ?? 'rgb(57, 66, 230,0.7)',
          borderDash: [5, 5],
          category: item.category,
        })
      })
    }
    return annotations
  }

  const data: ChartData = {
    labels,
    // datasets,
    datasets: [...datasets, ...prevDatasets],
    // datasets: prevDatasets,
  }
  const isAlignScalesActive =
    !!(isMultiChart && hasZAxis) || !!currentChart.isAlignScales
  const leftYMax = isAlignScalesActive
    ? currentChart.y_max
      ? currentChart.y_max
      : undefined
    : undefined
  const leftYMin = isAlignScalesActive
    ? currentChart.y_min
      ? currentChart.y_min
      : undefined
    : undefined
  const rightYMax = isAlignScalesActive ? currentChart.max_z : undefined
  const rightYMin = isAlignScalesActive ? currentChart.min_z : undefined
  const getAxes = () => {
    const xAxes = {
      ticks: {
        callback: function (value: any) {
          value = (this as any).getLabelForValue(value)
          return fullAxes
            ? value
            : value.length > 30
              ? value.substr(0, 30) + '...'
              : value
        },
      },
      title: {
        display: !hideXAxis,
        text: currentChart.x_title,
      },
      min: currentChart.min_x,
      max: currentChart.max_x,
    }
    const yAxes = {
      type: 'linear',
      display: true,
      position: 'left',
      gridLines: {
        display: true,
      },
      ticks: {
        count: 9,
        callback: function (value: any) {
          if (typeof value === 'number') value = value.toFixed(2)
          return numberFormatter(value)
        },
      },
      min: currentChart.min_y,
      max: currentChart.max_y,
      title: { display: !hideYAxis, text: currentChart.y_title },
    }
    const zAxes = {
      type: 'linear',
      display: isAlignScalesActive,
      position: 'right',
      ticks: {
        count: 9,
        callback: function (value: any) {
          if (typeof value === 'number') value = value.toFixed()
          return numberFormatter(value)
        },
      },
      min: rightYMin,
      max: rightYMax,
      grid: {
        display: false,
      },
      title: { display: true, text: currentChart.z_title },
    }
    return [xAxes, yAxes, zAxes]
  }
  const options: any = {
    plugins: {
      datalabels: hasLabels
        ? {
            formatter: (value: number) => {
              return numberFormatter(value)
            },
            color: 'black',
            anchor: 'end',
            align: 'bottom',
            display: 'auto',
          }
        : false,
      legend: false,
      tooltip: {
        callbacks: {
          title(context: any) {
            if (!!context[0]?.dataset?.borderDash) {
              return getComparedTimePeriod(
                context[0]?.label,
                comparePeriod,
                timeInterval,
              )
            } else if (!!context[0]?.dataset?.fill) {
              return `${context[0]?.label} Volume`
            }
            return context[0]?.label
          },
        },
        intersect: true,
        position: 'nearest',
      },
      annotation: {
        annotations: getAnnotations(),
      },
      filler: {
        propagate: true, // Helps with smooth filling
      },
    },
    animation: {
      duration: 0,
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: false,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    scales: {
      x: getAxes()[0],
      y: getAxes()[1],
      y1: getAxes()[2],
    },
    cubicInterpolationMode: currentChart.area ? 'monotone' : 'default',
  }
  return {
    data,
    options,
    type: 'line',
    legend: sortLegend(legend, (currentChart as LineChart).legend_order),
    height: 1000,
  }
}
