import React, { useCallback } from 'react'
import { Container, Tab, Tabs } from './InsightListOptions.styles'
import mixpanel from '../../../../../../../trackers/mixpanel'
import { YogiSearch } from 'components/UI/YogiSearch'
import { debounce } from 'lodash'

const tabs = ['All', 'Recent', 'Most Reviews', 'Bookmarks']

interface Props {
  activeTab: number
  setActiveTab: (index: number) => void
  search: string
  setSearch: (search: string) => void
  resetPageCount: () => void
}

const InsightListOptions: React.FC<Props> = ({
  activeTab,
  setActiveTab,
  search,
  setSearch,
  resetPageCount,
}) => {
  const handleTabClick = (index: number) => {
    mixpanel.track('automated insights', {
      action: 'change list tab',
      value: tabs[index],
    })
    setActiveTab(index)
  }

  const request = debounce((value) => {
    setSearch(value) // Remove this line will lead to normal denounce
    resetPageCount()
    mixpanel.track('automated insights', {
      action: 'search',
      value: value,
    })
  }, 600)

  const debounceRequest = useCallback((value: string) => request(value), [])

  const onChange = (e: any) => {
    debounceRequest(e.target.value)
  }

  return (
    <Container>
      <Tabs>
        {tabs.map((tab, index) => (
          <Tab
            key={index}
            onClick={() => handleTabClick(index)}
            isSelected={activeTab === index}
          >
            {tab}
          </Tab>
        ))}
      </Tabs>
      <div style={{ margin: '0 10px' }}>
        <YogiSearch onChange={onChange} />
      </div>
    </Container>
  )
}

export default InsightListOptions
