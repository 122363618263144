import { get } from 'lodash'
import { UploadFile } from 'antd/lib/upload/interface'
import Papa from 'papaparse'
import { Analyzer, DATA_TYPES as AnalyzerDATA_TYPES } from 'type-analyzer'
const ACCEPTED_ANALYZER_TYPES = [
  AnalyzerDATA_TYPES.DATE,
  AnalyzerDATA_TYPES.TIME,
  AnalyzerDATA_TYPES.DATETIME,
  AnalyzerDATA_TYPES.NUMBER,
  AnalyzerDATA_TYPES.INT,
  AnalyzerDATA_TYPES.FLOAT,
  AnalyzerDATA_TYPES.BOOLEAN,
  AnalyzerDATA_TYPES.STRING,
  AnalyzerDATA_TYPES.GEOMETRY,
  AnalyzerDATA_TYPES.GEOMETRY_FROM_STRING,
  AnalyzerDATA_TYPES.PAIR_GEOMETRY_FROM_STRING,
  AnalyzerDATA_TYPES.ZIPCODE,
  AnalyzerDATA_TYPES.ARRAY,
  AnalyzerDATA_TYPES.OBJECT,
]
const IGNORE_DATA_TYPES = Object.keys(AnalyzerDATA_TYPES).filter(
  (type) => !ACCEPTED_ANALYZER_TYPES.includes(type)
)

function renameDuplicateFields(fieldOrder: any) {
  return fieldOrder.reduce(
    (accu: any, field: any, i: any) => {
      const { allNames } = accu
      let fieldName = field

      if (allNames.includes(field)) {
        let counter = 0
        while (allNames.includes(`${field}-${counter}`)) {
          counter++
        }
        fieldName = `${field}-${counter}`
      }

      accu.fieldByIndex[i] = fieldName
      accu.allNames.push(fieldName)

      return accu
    },
    { allNames: [], fieldByIndex: {} }
  )
}

export const getFieldOptionsFromSourceData = (
  file: UploadFile,
  cb: (opts: string[], values: string[][]) => void
) => {
  const reader = new FileReader()

  reader.readAsText(file as any, 'UTF-8')
  reader.onload = function (evt) {
    const result: any = evt.target?.result

    if (result) {
      const [fields, ...values]: any = get(
        Papa.parse(result, { preview: 7, skipEmptyLines: true }),
        'data'
      )

      const sample: any = {}
      values[0].forEach((val: string, idx: number) => {
        sample[fields[idx]] = val
      })
      const options: string[] = getFieldsFromData([sample], fields).map(
        // [${column.type}]
        (column: any) => `${column.name}`
      )
      cb(options, values)
    }
  }
}

function getFieldsFromData(data: any, fieldOrder: any) {
  const metadata = Analyzer.computeColMeta(
    data,
    [{ regex: /.*geojson|all_points/g, dataType: 'GEOMETRY' }],
    { ignoredDataTypes: IGNORE_DATA_TYPES }
  )

  const { fieldByIndex } = renameDuplicateFields(fieldOrder)

  return fieldOrder.reduce(
    (orderedArray: any, field: string, index: number) => {
      const name = fieldByIndex[index]

      const fieldMeta = metadata.find((m: any) => m.key === field)
      const { type, format } = fieldMeta || {}

      orderedArray[index] = {
        name,
        format,
        tableFieldIndex: index + 1,
        type: type,
        analyzerType: type,
      }
      return orderedArray
    },
    []
  )
}

export const transformSourceType = (sourceType: string) =>
  sourceType.replace(':', '')

export const retrievePureUrl = (rawUrl: string) =>
  rawUrl.replace('https://www.', '').replace('https://', '')
export const extractUrl = (rawUrl: string | undefined, sourceUrl: string) => {
  const pattern = retrievePureUrl(sourceUrl)
  return rawUrl && new RegExp(pattern).test(rawUrl)
    ? rawUrl.split(pattern)[1]
    : rawUrl
}
