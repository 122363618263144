import React, { useCallback, useEffect } from 'react'
import styled from 'styled-components'
import {
  cancelOrange,
  cardBorder,
  darkBlue,
  exportCardBackground,
  primaryOrange,
} from 'assets/styles/variables'
import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import AutoInsightsSvgIcon from 'src/features/project/partials/ProjectLayout/components/Navigation/components/AutoInsightsSvgIcon/AutoInsightsSvgIcon'
import { YogiInput } from 'components/UI/YogiInput'
import { Form, InputNumber } from 'antd'
import YogiDivider from 'components/UI/YogiDivider'
import { YogiButton } from 'components/UI/YogiButton'
import type { WindowSize } from 'features/project/features/keywords/types'
import { CloseOutlined } from '@ant-design/icons'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { useQuery } from 'react-query'
import { searchKeywords } from 'features/project/features/keywords/model'
import { FormikValues, useFormik } from 'formik'
import * as Yup from 'yup'

const MAX_RESULTS = 50
const validationSchema = Yup.object().shape({
  windowSize: Yup.number().min(0).max(4),
  searchPhrase: Yup.string(),
})

type Props = {
  comparativeIndex: number
}

export const Controls: React.FC<Props> = ({ comparativeIndex }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const route = useProjectStore((state: ProjectState) => state.route)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex],
  )
  const keywordsControls = useProjectStore(
    (state: ProjectState) => state.keywordsControls,
  )
  const updateKeywordsControls = useProjectStore(
    (state: ProjectState) => state.updateKeywordsControls,
  )
  const setSummaryIsOpen = useProjectStore(
    (state: ProjectState) => state.setSummaryIsOpen,
  )
  const setSummaryRequest = useProjectStore(
    (state: ProjectState) => state.setSummaryRequest,
  )
  const hasLoadedFilters = useProjectStore(
    (state: ProjectState) => state.hasLoadedFilters,
  )

  const [stopWordValue, setStopWordValue] = React.useState('')

  const { searchTerm, windowSize, stopWords, currentPhrase } =
    keywordsControls[comparativeIndex]

  const initialValues: FormikValues = {
    windowSize: windowSize ?? 1,
    searchPhrase: searchTerm ?? '',
    stopWords: stopWords ?? [],
    currentPhrase: currentPhrase ?? '',
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    validateOnChange: false,
    onSubmit: async ({ windowSize, searchPhrase, stopWords }) => {
      updateKeywordsControls(comparativeIndex, 'windowSize', windowSize)
      updateKeywordsControls(comparativeIndex, 'searchTerm', searchPhrase)
      updateKeywordsControls(comparativeIndex, 'stopWords', stopWords)
      updateKeywordsControls(comparativeIndex, 'currentPhrase', null)
      return
    },
  })

  useEffect(() => {
    formik.submitForm()
  }, [filterValues.values])

  const loadSearchParametersFromLocalViewFilters = useCallback(() => {
    formik.setFieldValue(
      'searchPhrase',
      initialValues.searchPhrase,
      // filterValues.searchQuery || initialValues.searchPhrase
    )
    formik.setFieldValue(
      'currentPhrase',
      //filterValues.searchQuery ||
      initialValues.currentPhrase,
    )
    formik.setFieldValue(
      'windowSize',
      keywordsControls[comparativeIndex].windowSize || initialValues.windowSize,
    )
    const stopWords =
      keywordsControls[comparativeIndex].stopWords || initialValues.stopWords
    formik.setFieldValue(
      'stopWords',
      (stopWords?.value?.length && stopWords) || initialValues.stopWords,
    )
  }, [filterValues, keywordsControls[comparativeIndex]])

  useEffect(() => {
    loadSearchParametersFromLocalViewFilters()
  }, [keywordsControls[comparativeIndex]])

  const { data: searchKeywordsResults } = useQuery(
    [
      `keywords-${comparativeIndex || 0}`,
      projectId,
      filterValues.values,
      filterValues.searchQuery,
      filterValues.searchCondition,
      searchTerm,
      windowSize,
      stopWords,
    ],
    async () => {
      const { data } = await searchKeywords(
        projectId,
        {
          search_term: searchTerm || '',
          window_size: windowSize,
          max_results: MAX_RESULTS,
        },
        {
          criteria: filterValues.values,
          search_terms: [],
          search_criteria: [],
          stop_words: stopWords as string[],
        },
      )
      return data
    },
    {
      keepPreviousData: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId && hasLoadedFilters,
    },
  )

  return (
    <ControlForm
      onSubmit={(event) => {
        console.log('submitting')
        event.preventDefault()
        let phrase = formik.values.searchPhrase
        formik.setFieldValue('currentPhrase', phrase)
        formik.submitForm()
      }}
    >
      <Container>
        <Summarize
          onClick={() => {
            setSummaryIsOpen(true)

            const summaryRequest = {
              headers: {
                proj_uuid: projectId,
              },
              body: {
                chart_id: '5_5_7_rating_sentiment',
                // api needs a tier1 value, but it doesn't matter what it is
                tier1: 'Brand',
                tier1_value: '',
                tier2_value: null,
                tier3_value: null,
                search_terms: currentPhrase
                  ? [currentPhrase]
                  : formik.values.searchPhrase
                    ? [formik.values.searchPhrase]
                    : [],
                search_criteria: filterValues.searchCondition,
                criteria: filterValues.values,
              },
            }

            mixpanel.track('summary control', {
              action: 'keywords',
              value: summaryRequest.body,
              ...route,
            })
            setSummaryRequest(summaryRequest)
          }}
        >
          <div>Summarize</div>
          <div style={{ marginTop: -5 }}>
            <GenericSvgIcon SvgComponent={AutoInsightsSvgIcon} />
          </div>
        </Summarize>

        <Inline>
          <Form.Item
            validateStatus={formik.errors.searchPhrase && 'error'}
            // help={formik.errors.searchPhrase}
            style={{ margin: 0, width: '100%' }}
          >
            <YogiInput
              name="searchPhrase"
              value={formik.values.searchPhrase}
              onChange={formik.handleChange}
              placeholder="Phrase Search:"
            ></YogiInput>
          </Form.Item>
          <Window>
            <Label>Window Size:&nbsp;</Label>
            <StyledInputNumber
              min={1}
              max={4}
              disabled={!formik.values.searchPhrase}
              value={formik.values.windowSize}
              onChange={(n) =>
                formik.setFieldValue('windowSize', n as WindowSize)
              }
            />
          </Window>
        </Inline>
        <YogiDivider />
        <ExcludedWords>
          {/*<Label style={{ marginBottom: 10 }}>Excluded Words:</Label>*/}
          <YogiInput
            placeholder="Excluded Words:"
            value={stopWordValue}
            onChange={(e) => setStopWordValue(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && stopWordValue) {
                e.preventDefault()
                formik.setFieldValue('stopWords', [
                  ...formik.values.stopWords,
                  stopWordValue.trim(),
                ])
                setStopWordValue('')
              }
            }}
          ></YogiInput>
          <StopWords>
            {formik.values.stopWords.map((word: string, index: number) => (
              <StopWordItem key={index}>
                <div>{word}</div>
                <CloseOutlined
                  style={{
                    fontSize: 10,
                    cursor: 'pointer',
                    color: cancelOrange,
                  }}
                  onClick={() => {
                    formik.setFieldValue(
                      'stopWords',
                      formik.values.stopWords.filter((w: string) => w !== word),
                    )
                  }}
                />
              </StopWordItem>
            ))}
          </StopWords>
        </ExcludedWords>

        <ButtonWrapper>
          {currentPhrase && (
            <YogiButton
              type="dashed"
              style={{ margin: 0, height: 48 }}
              onClick={() =>
                updateKeywordsControls(comparativeIndex, 'currentPhrase', null)
              }
            >
              Back
            </YogiButton>
          )}

          {searchKeywordsResults && (
            <YogiButton
              onClick={() => {
                formik.setFieldValue('windowSize', 1)
                updateKeywordsControls(comparativeIndex, 'windowSize', 1)
                formik.setFieldValue('searchPhrase', '')
                updateKeywordsControls(comparativeIndex, 'searchTerm', '')
                formik.setFieldValue('currentPhrase', '')
                updateKeywordsControls(comparativeIndex, 'currentPhrase', '')
                updateKeywordsControls(comparativeIndex, 'stopWords', [])
                formik.submitForm()
              }}
              style={{ margin: 0, width: '50%', height: 48 }}
            >
              Clear
            </YogiButton>
          )}
          <YogiButton
            htmlType="submit"
            type="primary"
            // onClick={submitSearchForm}
            style={{ margin: 0, width: '50%', height: 48 }}
          >
            Search
          </YogiButton>
        </ButtonWrapper>
      </Container>
    </ControlForm>
  )
}

const ControlForm = styled.form`
  //height: 100%;
  margin-bottom: 15px;
`

const Container = styled.div`
  height: 100%;
  width: 100%;

  display: flex;
  flex-direction: column;
  gap: 20px;
`

const Summarize = styled.div`
  width: 100%;

  display: flex;
  justify-content: space-between;
  align-items: center;

  background: ${darkBlue};
  color: white;
  cursor: pointer;

  padding: 18px;

  border-radius: 10px;

  box-shadow: 0 4px 4px 0 #00000040;

  &:hover {
    background: #28364ff3;
  }
`

const StyledInputNumber = styled(InputNumber)`
  border-radius: 10px;
  border: 2px solid ${cardBorder};
  box-shadow: 0 0 5px 0 #00000026;
  max-width: 50px;
`

const Window = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const Label = styled.div`
  white-space: nowrap;
  line-height: 1;
  font-size: var(--font-size-md);
  margin-right: 5px;
`

const ExcludedWords = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const StopWordItem = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 10px;

  font-size: 14px;
  font-style: italic;

  padding: 10px 20px;

  background: ${exportCardBackground};

  color: ${primaryOrange};

  border-radius: 10px;
`

const ButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 20px;

  & > button {
    margin: 0 var(--default-padding-half);
  }
`

const Inline = styled.div`
  display: flex;
  gap: 10px;
`

const StopWords = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`
