import GenericSvgIcon from 'components/GenericSvgIcon/GenericSvgIcon'
import SuggestedViewsSvgIcon from 'features/project/features/SuggestedViews/SuggestedViewsButton/components/SuggestedViewsSvgIcon/SuggestedViewsSvgIcon'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import styled from 'styled-components'
import {
  primaryOrange,
  suggestedViewsUnselected,
} from 'assets/styles/variables'
import { Tooltip } from 'antd'

export const SuggestedViewsButton = () => {
  const setIsSuggestedViewsDrawerOpen = useProjectStore(
    (state: ProjectState) => state.setIsSuggestedViewsDrawerOpen,
  )
  return (
    <Tooltip title={'Suggested Views'}>
      <SuggestedViewsWrapper
        onClick={() => {
          setIsSuggestedViewsDrawerOpen(true)
        }}
      >
        <GenericSvgIcon SvgComponent={SuggestedViewsSvgIcon}></GenericSvgIcon>
      </SuggestedViewsWrapper>
    </Tooltip>
  )
}

const SuggestedViewsWrapper = styled.div`
  margin-left: 2px;
  color: ${suggestedViewsUnselected};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    color: ${primaryOrange};
  }
`
