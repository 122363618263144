import React, { useEffect, useMemo } from 'react'
import styled from 'styled-components'
import { Loader } from 'shared/components'
import { Header } from 'features/project/features/filters/Header/Header'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'
import _ from 'lodash'
import { isInitialFlow } from '../../../home/utils'
import { Panel } from 'features/project/features/Charts/components/Panel/Panel'
import { useDefaultChart } from 'features/project/features/Charts/hooks/useDefaultChart'
import { useChartList } from 'features/project/features/Charts/hooks/useChartList'
import { CloseOutlined } from '@ant-design/icons'
import { cardBorder } from 'assets/styles/variables'
import useResizeObserver from 'use-resize-observer'

const Charts: React.FC = () => {
  // global state
  const details = useProjectStore((state: ProjectState) => state.details)
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const chartData = useProjectStore((state: ProjectState) => state.chartData)
  const filterValues = useProjectStore((state: ProjectState) => state.filters)
  const removePanel = useProjectStore(
    (state: ProjectState) => state.removePanel,
  )

  // local state
  const [activePanel, setActivePanel] = React.useState<number>(0)

  // api hooks
  const { data: defaultChart } = useDefaultChart()
  const { data: chartList } = useChartList()
  const { ref: resizeRef, height: headerHeight } =
    useResizeObserver<HTMLDivElement>()

  // effects
  useEffect(() => {
    mixpanel.track('charts page')
  }, [])

  // constants
  const loading = useMemo(
    () => !(chartList && defaultChart),
    [chartList, defaultChart],
  )

  const selectedIndex = comparativePanelsNumber > 1 ? activePanel : null

  useEffect(() => {
    if (activePanel >= comparativePanelsNumber) {
      setActivePanel(0)
    }
  }, [comparativePanelsNumber])

  if (isInitialFlow(details.state) || loading) {
    return (
      <LoadingWrapper>
        <Loader />
        <LoaderTitle>{`Loading ${details?.clientSettings.project.pages.dashboard.alias}`}</LoaderTitle>
      </LoadingWrapper>
    )
  }

  return (
    <Container comparativePanelsNumber={comparativePanelsNumber}>
      <div
        ref={resizeRef}
        style={{ paddingRight: 10, width: '100%', marginBottom: 5 }}
      >
        <Header
          // this helps stop a bug from removing an active panel at the highest index
          activePanel={activePanel >= comparativePanelsNumber ? 0 : activePanel}
        />
      </div>
      <Content>
        {_.times(comparativePanelsNumber, (index) => (
          <PanelWrapper key={index}>
            {comparativePanelsNumber > 1 && (
              <RemovePanel
                onClick={() => {
                  removePanel(index)
                }}
              >
                <CloseOutlined />
              </RemovePanel>
            )}
            {filterValues[index] && (
              <Panel
                comparativeIndex={index}
                data={chartData[index]}
                isMultiFetching={false}
                isActive={selectedIndex === index}
                setActivePanel={setActivePanel}
                headerHeight={headerHeight ?? 46}
              />
            )}
          </PanelWrapper>
        ))}
      </Content>
    </Container>
  )
}

export default Charts

const Container = styled.div<{ comparativePanelsNumber: number }>`
  height: 100%;
  width: 100%;
  max-height: calc(100% - 30px);
  max-width: calc(100% - 10px);

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
`

const PanelWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  max-height: 100vh;
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: 8px;
`

const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  height: 100%;
  width: 100%;
  gap: 0;
`

const RemovePanel = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  padding: 2px 5px;
  cursor: pointer;
  border: 2px solid ${cardBorder};
  background: white;
  border-radius: 50%;
  z-index: 2;
  //opacity: 0.5;
  font-size: var(--font-size-md);
  transition: 0.2s ease;
  &:hover {
    background: ${cardBorder};
  }
`

const LoadingWrapper = styled.div`
  margin-top: 100px;
`

const LoaderTitle = styled.div`
  text-align: center;
  font-size: var(--font-size-xxl);
  font-weight: 500;
  margin-top: 8px;
`
