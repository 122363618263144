import React from 'react'
import { useHistory } from 'react-router-dom'
import { Loader } from 'shared/components'
import { Auth, Hub } from 'aws-amplify'
import { home, signinURL } from 'utils/links'
import { useAuthContext } from 'features/auth'

export const SignInCallback: React.FC = () => {
  const authContext = useAuthContext()
  const history = useHistory()

  Hub.listen('auth', ({ payload: { event, data } }) => {
    if (event === 'signIn') {
      Auth.currentAuthenticatedUser()
        .then((user) => {
          authContext.signIn(user)
          history.push(home)
        })
        .catch((e) => {
          history.push(signinURL)
        })
    }
  })

  return <Loader />
}
