// CreateAlert.tsx
import React, { useContext, useEffect, useMemo, useState } from 'react'
import { Select, Typography } from 'antd'
import styled from 'styled-components'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { pageBackgroundGrey } from '../../../../../assets/styles/variables'
import { ProjectState } from '../../../types'
import { useProjectStore } from '../../../projectStore/projectStore'
import useResizeObserver from 'use-resize-observer'
import { useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { fetchSharedUsers } from '../../share/model'
import { AuthContext } from '../../../../auth'
import { AlertResponse, AlertType, shareAlert, unshareAlert } from '../model'
import { toast } from 'react-toastify'
import { GroupsOutlined } from '@mui/icons-material'
import { Fetcher } from '../../../../../shared/components/fetcher'
import mixpanel from '../../../../trackers/mixpanel'
const { Option } = Select
const { Title } = Typography
import pluralize from 'pluralize'

const defaultValues = {
  title: '',
  alert_type: 'Star Rating',
  trigger_type: 'Significant Negative',
  time_interval: 'Quarter',
  alert_scope: 'Brand',
  reviews_filter: 0,
  shared_users: [],
  search_terms: [],
  search_criteria: [],
  filters: [],
}

type Props = {
  alertData: AlertResponse
  setIsOpen: (isOpen: boolean) => void
  refetch: () => Promise<any>
}
const ShareAlert: React.FC<Props> = ({ alertData, setIsOpen, refetch }) => {
  const { alert, owners, viewers } = alertData || {}
  // TODO: recipients not returned on get all - should be returned for owned alerts
  // TODO: what happens when you click on an alert you don't own?
  // TODO: disable controls when alert is not owned
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  const authContext = useContext(AuthContext)
  const currentUserEmail = authContext?.user?.attributes?.email
  const isOwner = alertData?.owners.find(
    (el) => el.email?.toLowerCase() === currentUserEmail?.toLowerCase(),
  )

  const { data } = useQuery(
    ['shared-users', projectId],
    () => fetchSharedUsers(projectId),
    {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      staleTime: Infinity,
      cacheTime: Infinity,
    },
  )

  const allUsers = useMemo(() => {
    if (!data) return []
    return [...data.admins, ...data.shared_users, ...data.yogi_employees].sort()
  }, [data?.admins, data?.shared_users])

  const [currentPage, setCurrentPage] = useState(1)
  const [recipients, setRecipients] = useState<string[]>([])
  const [newRecipients, setNewRecipients] = useState<string[]>([])
  const [unsaved, setUnsaved] = useState(false)

  const [initializing, setInitializing] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const { handleSubmit, reset } = useForm<AlertType>({
    defaultValues: useMemo(() => {
      return alert ? alert : defaultValues
    }, [alert]),
  })

  useEffect(() => {
    setInitializing(true)
    if (alert) {
      reset(alert)
      setRecipients(viewers ? viewers.map((el) => el.email) : [])
    } else {
      reset(defaultValues)
      setRecipients([])
    }
  }, [alert])

  useEffect(() => {
    setUnsaved(true)
  }, [])

  const manualSubmit = async () => {
    mixpanel.track('alerts', {
      action: 'share',
      value: alert?.id,
    })
    setIsSubmitting(true)
    const body = {
      alert_id: alert?.id,
      as_owners: false,
      email_addresses: newRecipients,
    }
    try {
      await shareAlert(projectId, body)
      refetch().then(() => {
        setIsOpen(false)
        setCurrentPage(1)
        reset()
        setIsSubmitting(false)
        toast.success('Invite sent successfully')
      })
    } catch (e) {
      setIsSubmitting(false)
      toast.error('Error sending invite')
    }
  }

  const handleUnshare = async (email: string) => {
    mixpanel.track('alerts', {
      action: 'unshare',
      value: alert?.id,
    })
    setIsSubmitting(true)
    const body = {
      alert_id: alert?.id,
      email_addresses: [email],
    }
    try {
      await unshareAlert(projectId, body)
      refetch().then(() => {
        setIsSubmitting(false)
        toast.success('User removed successfully')
      })
    } catch (e) {
      setIsSubmitting(false)
      toast.error('Error removing user')
    }
  }

  const { ref: headerRef, height: headerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()
  const { ref: footerRef, height: footerHeight = 1 } =
    useResizeObserver<HTMLDivElement>()

  return (
    <Container>
      <Header ref={headerRef}>
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            paddingBottom: 10,
          }}
        >
          <Title level={4} style={{ margin: 0 }}>
            {alert?.title}
          </Title>
          <Title
            level={4}
            onClick={() => setIsOpen(false)}
            style={{ fontWeight: 600, cursor: 'pointer', marginTop: -20 }}
          >
            X
          </Title>
        </div>
        {/*<div*/}
        {/*  style={{*/}
        {/*    display: 'flex',*/}
        {/*    justifyContent: 'space-between',*/}
        {/*    margin: '20px 0px 10px',*/}
        {/*    gap: '10px',*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <div style={{ width: '100%' }}>*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        width: '100%',*/}
        {/*        border: `3px solid ${buttonBlue}`,*/}
        {/*        borderRadius: '4px',*/}
        {/*      }}*/}
        {/*    ></div>*/}
        {/*    <div*/}
        {/*      style={{*/}
        {/*        marginTop: 5,*/}
        {/*        fontWeight: 'bold',*/}
        {/*        color: buttonBlue,*/}
        {/*      }}*/}
        {/*    >*/}
        {/*      Invite Users*/}
        {/*    </div>*/}
        {/*  </div>*/}
        {/*</div>*/}
      </Header>
      {/*<Divider style={{ margin: 0 }} />*/}
      <Body headerHeight={headerHeight} footerHeight={footerHeight}>
        <Section style={{ background: pageBackgroundGrey }}>
          <div>
            <Title level={5} style={{ display: 'flex', alignItems: 'center' }}>
              <GroupsOutlined style={{ marginRight: 5 }} />{' '}
              {alertData?.viewers.length}{' '}
              {pluralize('Recipients', alertData?.viewers.length ?? 0)}
            </Title>
            <div>
              {alertData?.viewers.map((viewer) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-start',
                    gap: 15,
                    alignItems: 'center',
                    marginBottom: 5,
                  }}
                >
                  {/*<Avatar size={40} src={null} style={{ marginRight: 5 }} />*/}
                  <div>
                    {viewer.email}
                    {viewer.email === currentUserEmail ? ' (you)' : ''}
                  </div>
                  {isOwner && viewer.email !== currentUserEmail && (
                    <YogiButton
                      type="dashed"
                      onClick={() => {
                        handleUnshare(viewer.email)
                      }}
                      style={{ height: 24, padding: '1px 6px' }}
                    >
                      X
                    </YogiButton>
                  )}
                </div>
              ))}
            </div>
          </div>
        </Section>
        <Section style={{ border: 'none' }}>
          <div style={{ marginTop: -3, width: '100%' }}>
            <div>Invite Users</div>
            <Select
              mode="multiple"
              allowClear
              value={newRecipients}
              style={{ width: '100%', marginTop: 5 }}
              placeholder="Choose users to share view"
              onChange={(value) => {
                setNewRecipients(value)
              }}
              // onChange={(value) => formik.setFieldValue('selectedUsers', value)}
              // onFocus={() => setError(null)}
            >
              {allUsers
                .filter(
                  (user) =>
                    user !== currentUserEmail && !recipients.includes(user),
                )
                .map((user) => (
                  <Option key={user} value={user}>
                    {user}
                  </Option>
                ))}
            </Select>
          </div>
        </Section>
      </Body>
      <Footer ref={footerRef}>
        <YogiButton
          type="dashed"
          // disabled={currentPage === 1}
          onClick={() => setIsOpen(false)}
        >
          Cancel
        </YogiButton>

        <YogiButton type="primary" onClick={() => manualSubmit()}>
          Send
        </YogiButton>
      </Footer>

      {isSubmitting && (
        <Overlay>
          <Fetcher />
        </Overlay>
      )}
    </Container>
  )
}

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  //gap: 8px;
`

const Header = styled.div`
  padding: var(--default-padding) var(--default-padding) 5px;
  border-bottom: 1px solid #f2f2f2;
`

const Body = styled.div<{ headerHeight: number; footerHeight: number }>`
  display: flex;
  flex-direction: column;
  overflow: auto;
  height: calc(
    100vh - ${(props) => props.headerHeight}px -
      ${(props) => props.footerHeight}px - 50px
  );
  padding-bottom: var(--default-padding);
`

const Section = styled.div`
  display: flex;
  flex-direction: column;
  padding: var(--default-padding-half) var(--default-padding);
  border-bottom: 1px solid #f2f2f2;
`

const ButtonGroup = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 12px;
  margin-bottom: 8px;
`

const RecipientOptionsGroup = styled.div`
  display: flex;
  flex-direction: column;
  gap: 12px;
  margin-bottom: 8px;
`

const Footer = styled.div`
  background: white;
  display: flex;
  justify-content: space-between;
  margin: 0 var(--default-padding-half);
  //position: fixed;
  //bottom: 16px;
  //left: 16px;
  //right: 16px;
`

const MultiSelectWrapper = styled.div`
  padding: 20px;
  background: var(--super-light-grey);
  border-radius: 12px;
`

const StyledSelect = styled(Select)<{ color: string }>`
  min-width: 200px;
  margin-bottom: 8px;
  .ant-select-selector {
    border: 2px solid #dde2e5 !important;
    background: ${({ color }) => color} !important;
    :hover {
      border: 2px solid var(--button-blue) !important;
    }
  }
`
const Overlay = styled.div`
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`

export default ShareAlert
