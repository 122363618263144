import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useFormik } from 'formik'
import { Button, Divider, Form, Input, Select, Typography } from 'antd'
import { Loader } from 'shared/components'
import { fetchSharedUsers } from '../../share/model'
import { shareViewWithUsers } from '../model'
import { SavedView } from '../types'
import { AuthContext } from 'features/auth'
import { CopyOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import mixpanel from 'features/trackers/mixpanel'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { LogoSpinnerSmall } from 'components/Loading/LogoSpinner/LogoSpinnerSmall'
import { LogoSpinner } from 'components/Loading/LogoSpinner/LogoSpinner'

const { Option, OptGroup } = Select

type Props = {
  projectId: string
  view: SavedView
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

export const ShareSavedViewModal: React.FC<Props> = ({ projectId, view }) => {
  const authContext = useContext(AuthContext)
  const [error, setError] = useState<string | null>(null)
  const { data, isLoading, refetch } = useQuery(
    `shared-users-${projectId}`,
    () => fetchSharedUsers(projectId),
  )

  const currentUserEmail = authContext?.user?.attributes?.email

  const formik = useFormik({
    initialValues: {
      selectedUsers: [],
    },
    onSubmit: async ({ selectedUsers }, { resetForm }) => {
      try {
        await shareViewWithUsers(projectId, view.uuid, selectedUsers)
        resetForm()
        refetch()
        toast.success('Success')
      } catch (e) {
        setError((e as any)?.response?.data?.detail?.msg || (e as any).message)
        toast.error(error)
        return Promise.resolve(false)
      }
    },
  })
  // const shareUrlExpanded = shareUrl ? shareUrl + history.location.search : ''

  if (isLoading || !data) {
    return <Loader paddingTop={false} />
  }

  const shareUrl = `${window.location.origin}/project/${projectId}/${view.page}${view.search_url}`

  const copyToClipboard = () => {
    mixpanel.track('saved view', { action: 'url' })
    const el = document.createElement('textarea')
    el.value = shareUrl
    document.body.appendChild(el)
    el.select()
    document.execCommand('copy')
    document.body.removeChild(el)
    toast.info('Share URL is copied')
  }

  return (
    <Wrapper>
      <Form
        onFinish={() => {
          mixpanel.track('saved view', {
            action: 'share',
            emails: formik?.values?.selectedUsers,
          })
          formik.submitForm()
        }}
        {...layout}
      >
        <Select
          mode="multiple"
          allowClear
          value={formik.values.selectedUsers}
          style={{ width: '100%' }}
          placeholder="Choose users to share view"
          onChange={(value) => formik.setFieldValue('selectedUsers', value)}
          onFocus={() => setError(null)}
        >
          {data.admins.length && (
            <OptGroup label={'Admins'}>
              {data.admins
                .filter((admin) => admin !== currentUserEmail)
                .map((admin) => (
                  <Option key={admin} value={admin}>
                    {admin}
                  </Option>
                ))}
            </OptGroup>
          )}
          {data.shared_users.length && (
            <OptGroup label={'Viewers'}>
              {data.shared_users
                .filter((user) => user !== currentUserEmail)
                .map((user) => (
                  <Option key={user} value={user}>
                    {user}
                  </Option>
                ))}
            </OptGroup>
          )}
          {data.yogi_employees.length && (
            <OptGroup label={'Yogi'}>
              {data.yogi_employees
                .filter((user) => user !== currentUserEmail)
                .map((user) => (
                  <Option key={user} value={user}>
                    {user}
                  </Option>
                ))}
            </OptGroup>
          )}
        </Select>
        {error && (
          <Typography.Text copyable style={{ color: 'red' }}>
            {error}
          </Typography.Text>
        )}
        <YogiButton
          style={{ margin: '24px auto 0', display: 'block' }}
          type="primary"
          htmlType="submit"
          loading={formik.isSubmitting}
        >
          Share
        </YogiButton>
        <Divider plain>OR</Divider>
        Share View via URL
        <div style={{ display: 'flex' }}>
          <Input value={shareUrl} style={{ marginRight: 20 }} disabled />
          <YogiButton
            type="primary"
            shape="circle"
            icon={<CopyOutlined />}
            onClick={copyToClipboard}
            style={{ marginRight: 20 }}
          />
        </div>
      </Form>
      {isLoading && (
        <LoadingLayer>
          <div style={{ marginTop: 20 }}>
            <LogoSpinner height={100}></LogoSpinner>
          </div>
        </LoadingLayer>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  position: relative;

  .ant-btn > .ant-btn-loading-icon {
    padding-right: 4px;
  }

  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0px;
  }
`

const LoadingLayer = styled.div`
  display: flex;
  align-items: flex-start;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`
