import { defaultDashboardControls } from '../../projectStore/projectStore'
import { CHARTS_URL, FEEDBACK_URL, KEYWORDS_URL } from '../../utils'

export const focusFields = [
  'brand',
  'theme',
  'product_hierarchy',
  'source_type',
]

export const nextStepsConstants = {
  theme: [
    {
      page: CHARTS_URL,
      title: 'View the worst performing Products for this Theme',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {
        ...defaultDashboardControls,
        chartId: '6_5_17_sentiment',
        tier1: 'Product',
        expandedCategories: ['My Products', 'Competitor Products'],
        pageSize: 10,
        order: 'asc',
      },
    },
    {
      page: KEYWORDS_URL,
      title: 'View Full Keyword Breakdown',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
    // {
    //   page: CHARTS_URL,
    //   title: 'View All Themes',
    //   filters: {
    //     values: [],
    //     searchQuery: [],
    //     searchCondition: [],
    //   },
    //   dashboardControls: {
    //     ...defaultDashboardControls,
    //     chartId: '6_5_17_sentiment',
    //     tier1: 'Brand',
    //     expandedCategories: ['My Products', 'Competitor Products'],
    //     pageSize: 10,
    //   },
    // },
    {
      page: FEEDBACK_URL,
      title: 'Read Reviews that mention this Theme',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
  ],
  brand: [
    {
      page: CHARTS_URL,
      title: 'View the worst performing Products for this Brand',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {
        ...defaultDashboardControls,
        chartId: '6_5_17_sentiment',
        tier1: 'Product',
        expandedCategories: ['My Products', 'Competitor Products'],
        pageSize: 10,
        order: 'asc',
      },
    },
    {
      page: KEYWORDS_URL,
      title: 'View Full Brand Keyword Breakdown',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
    // {
    //   page: CHARTS_URL,
    //   title: 'View All Themes',
    //   filters: {
    //     values: [],
    //     searchQuery: [],
    //     searchCondition: [],
    //   },
    //   dashboardControls: {
    //     ...defaultDashboardControls,
    //     chartId: '6_5_17_sentiment',
    //     tier1: 'Brand',
    //     expandedCategories: ['My Products', 'Competitor Products'],
    //     pageSize: 10,
    //   },
    // },
    {
      page: FEEDBACK_URL,
      title: 'Read Reviews for this Brand',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
  ],
  product_hierarchy: [
    {
      page: CHARTS_URL,
      title: 'View the worst performing Themes for this Product',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {
        ...defaultDashboardControls,
        chartId: '6_5_17_sentiment',
        tier1: 'Theme',
        expandedCategories: [],
        pageSize: 10,
        order: 'asc',
      },
    },
    {
      page: KEYWORDS_URL,
      title: 'View Product Keyword Breakdown',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
    // {
    //   page: CHARTS_URL,
    //   title: 'View All Themes',
    //   filters: {
    //     values: [],
    //     searchQuery: [],
    //     searchCondition: [],
    //   },
    //   dashboardControls: {
    //     ...defaultDashboardControls,
    //     chartId: '6_5_17_sentiment',
    //     tier1: 'Brand',
    //     expandedCategories: ['My Products', 'Competitor Products'],
    //     pageSize: 10,
    //   },
    // },
    {
      page: FEEDBACK_URL,
      title: 'Read Reviews that mention this Product',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
  ],
  product: [
    {
      page: CHARTS_URL,
      title: 'View the worst performing Themes for this Product',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {
        ...defaultDashboardControls,
        chartId: '6_5_17_sentiment',
        tier1: 'Theme',
        expandedCategories: [],
        pageSize: 10,
        order: 'asc',
      },
    },
    {
      page: KEYWORDS_URL,
      title: 'View Product Keyword Breakdown',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
    // {
    //   page: CHARTS_URL,
    //   title: 'View All Themes',
    //   filters: {
    //     values: [],
    //     searchQuery: [],
    //     searchCondition: [],
    //   },
    //   dashboardControls: {
    //     ...defaultDashboardControls,
    //     chartId: '6_5_17_sentiment',
    //     tier1: 'Brand',
    //     expandedCategories: ['My Products', 'Competitor Products'],
    //     pageSize: 10,
    //   },
    // },
    {
      page: FEEDBACK_URL,
      title: 'Read Reviews that mention this Product',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
  ],
  source_type: [
    {
      page: CHARTS_URL,
      title: 'View the worst performing Products for this Source',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {
        ...defaultDashboardControls,
        chartId: '6_5_17_sentiment',
        tier1: 'Product',
        expandedCategories: ['My Products', 'Competitor Products'],
        pageSize: 10,
        order: 'asc',
      },
    },
    {
      page: KEYWORDS_URL,
      title: 'View Full Keyword Breakdown',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
    // {
    //   page: CHARTS_URL,
    //   title: 'View All Themes',
    //   filters: {
    //     values: [],
    //     searchQuery: [],
    //     searchCondition: [],
    //   },
    //   dashboardControls: {
    //     ...defaultDashboardControls,
    //     chartId: '6_5_17_sentiment',
    //     tier1: 'Brand',
    //     expandedCategories: ['My Products', 'Competitor Products'],
    //     pageSize: 10,
    //   },
    // },
    {
      page: FEEDBACK_URL,
      title: 'Read Reviews from this Source',
      filters: {
        values: [],
        searchQuery: [],
        searchCondition: [],
      },
      dashboardControls: {},
    },
  ],
}
