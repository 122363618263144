import React from 'react'
import { useQuery } from 'react-query'
import { Tabs } from 'antd'
import { Loader } from 'shared/components'
import { ProductEditForm } from './product-edit-form'
import { fetchDataForProductEditing } from '../model'
import { TransferEntities } from '../helpers'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'

const { TabPane } = Tabs

export const ProductEdit = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const { data, isLoading } = useQuery(
    [`edit-product-${projectId}`],
    () => fetchDataForProductEditing(projectId),
    { refetchOnWindowFocus: false }
  )

  if (isLoading) {
    return <Loader />
  }

  if (!data) {
    return <span>Something went wrong</span>
  }

  return (
    <div>
      <Tabs>
        {/* TODO: put back when backend ready
        <TabPane tab="Groups" key="1">
          <ProductEditForm
            options={data.groups}
            type={TransferEntities.GROUP}
            projectId={projectId}
          />
        </TabPane>
        */}
        <TabPane tab="Products" key="1">
          <ProductEditForm
            options={data.product_hierarchies}
            type={TransferEntities.HIERARCHY}
            projectId={projectId}
          />
        </TabPane>
      </Tabs>
    </div>
  )
}
