import React, { useState } from 'react'

type Props = { source: string; style?: React.CSSProperties; className?: string }

export const SourceIcon: React.FC<Props> = ({ source, ...cssProps }: Props) => {
  const [hasError, setHasError] = useState(false)

  // Syndication
  source = source === 'syndicated' ? 'Shop' : source

  // Foreigner source groups
  const foreignerSourceGroups = [
    'HarveyNorman',
    'MercadoLibre',
    'ElGiganten',
    'MediaMarkt',
    'OfficeDepot',
    'Komplett',
    'Petsmart',
    'Euronics',
    'Sephora',
    'Walmart',
    'Watsons',
    'BestBuy',
    'Staples',
    'Keurig',
    'Amazon',
    'Lazada',
    'Costco',
    'Shopee',
    'PBTech',
    'LLBean',
    'Naver',
    'Power',
    'Kmart',
    'Alza',
    'FNAC',
    'JD',
  ]

  for (let fsg of foreignerSourceGroups) {
    if (source?.includes(fsg) && source !== 'PowerReviews') {
      source = fsg
      break
    }
  }

  if (hasError) {
    return <div></div>
  }

  return (
    <img
      {...cssProps}
      src={`https://yogi-source-logos.s3.us-east-1.amazonaws.com/${source}-Logo.svg`}
      alt={`${source} logo`}
      onError={() => setHasError(true)}
    />
  )
}
