import React, { useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'

type Props = {}

export const Content: React.FC<Props> = ({}) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  return <Container></Container>
}

const Container = styled.div``
