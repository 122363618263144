import React, { useContext, useState } from 'react'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useFormik } from 'formik'
import { Button, Divider, Form, Input, Select, Typography } from 'antd'
import { FormItem, Loader } from 'shared/components'
import { fetchSharedUsers } from '../../share/model'
import { shareViewWithUsers, updateSavedView } from '../model'
import { SavedView } from '../types'
import { AuthContext } from 'features/auth'
import { CopyOutlined } from '@ant-design/icons'
import { toast } from 'react-toastify'
import mixpanel from 'features/trackers/mixpanel'
import { YogiButton } from '../../../../../components/UI/YogiButton'
import { LogoSpinnerSmall } from 'components/Loading/LogoSpinner/LogoSpinnerSmall'
import { Fetcher } from '../../../../../shared/components/fetcher'

const { Option, OptGroup } = Select

type Props = {
  projectId: string
  view: SavedView
  refetch: () => Promise<any>
  setVisible: any
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

export const EditSavedViewModal: React.FC<Props> = ({
  projectId,
  view,
  refetch,
  setVisible,
}) => {
  const authContext = useContext(AuthContext)
  const [error, setError] = useState<string | null>(null)
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const formik = useFormik({
    initialValues: {
      name: view.name,
    },
    onSubmit: async ({ name }, { resetForm }) => {
      setIsLoading(true)
      try {
        await updateSavedView(projectId, {
          ...view,
          name: formik.values.name,
        }).then(() =>
          refetch().then(() => {
            setIsLoading(false)
            toast.success('Success')
            setVisible(false)
          }),
        )
      } catch (e) {
        setError((e as any)?.response?.data?.detail?.msg || (e as any).message)
        toast.error(error)
        setIsLoading(false)
        return Promise.resolve(false)
      }
    },
    enableReinitialize: true,
  })

  return (
    <Wrapper>
      <Form
        onFinish={() => {
          mixpanel.track('saved view', {
            action: 'update',
            value: formik?.values?.name,
          })
          formik.submitForm()
        }}
        {...layout}
      >
        <FormItem formik={formik} field="name" label="Name" />
        {error && (
          <Typography.Text copyable style={{ color: 'red' }}>
            {error}
          </Typography.Text>
        )}
        <YogiButton
          style={{ margin: '24px auto 0', display: 'block' }}
          type="primary"
          htmlType="submit"
          loading={formik.isSubmitting}
        >
          Update
        </YogiButton>
      </Form>
      {/*{isLoading && (*/}
      {/*  <LoadingLayer>*/}
      {/*    <Fetcher />*/}
      {/*  </LoadingLayer>*/}
      {/*)}*/}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  position: relative;

  .ant-btn > .ant-btn-loading-icon {
    padding-right: 4px;
  }

  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0px;
  }
`

const LoadingLayer = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  position: absolute;
  top: -75px;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
`
