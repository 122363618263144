import { useQuery } from 'react-query'
import { getFeatureFlags } from '../../url-params/model'
import { ProjectState } from '../types'
import { useProjectStore } from '../projectStore/projectStore'

export const useFeatureFlags = () => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)

  return useQuery(
    ['feature-flags', projectId],
    () => {
      return getFeatureFlags(projectId)
    },
    {
      refetchOnWindowFocus: true,
      refetchOnMount: true,
      staleTime: Infinity,
      cacheTime: Infinity,
      enabled: !!projectId,
    }
  )
}
