import { YogiButton } from 'components/UI/YogiButton'
import React from 'react'
import styled from 'styled-components'

export const ErrorBoundaryContent = () => {
  return (
    <Container>
      <Content>
        <Title>Something went wrong.</Title>
        <p>
          Our team has been notified of this error and will get to work on
          fixing it.
        </p>
        <YogiButton
          onClick={() => location.reload()}
          type={'primary'}
          style={{ height: 48 }}
        >
          Refresh Page
        </YogiButton>
      </Content>
    </Container>
  )
}
const Container = styled.div`
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
`
const Content = styled.div`
  position: absolute;
  top: 35%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Title = styled.h1``
