import React from 'react'
import styled from 'styled-components'
import { Header } from 'shared/components'

type Props = {
  children: React.ReactNode
  fullWidth?: boolean
}

export const PageWrapper = ({ children, fullWidth }: Props) => {
  return (
    <div style={{ fontWeight: 300 }}>
      <Wrapper>
        <Header />
      </Wrapper>

      {fullWidth ? children : <Container>{children}</Container>}
    </div>
  )
}

const Container = styled.div`
  height: 100vh;
  overflow: auto;
  width: var(--container-width);
  margin: 0 auto;
  color: var(--color-text-black);

  @media screen and (max-width: 1280px) {
    width: auto;
    margin: var(--mobile-margin);
  }
`
const Wrapper = styled.div`
  background: white;
  margin-bottom: 36px;
  box-shadow: var(--header-box-shadow);
  top: 0;
  left: 0;
  width: 100%;
  z-index: 2;
  position: fixed;
`
