import React from 'react'
import App from './app'
import * as serviceWorker from './serviceWorker'

// Import CSS
// import './styles.ts' // We'll create this file to handle all CSS imports

import './config'
import { createRoot } from 'react-dom/client'

// ReactDOM.render(<App />, document.getElementById('root'))
const container = document.getElementById('root')
const root = createRoot(container!)

root.render(
  <React.StrictMode>
    <App />,
  </React.StrictMode>,
)

// Vite-specific hot module replacement
if (import.meta.hot) {
  import.meta.hot.accept('./app', () => {
    root.render(
      <React.StrictMode>
        <App />
      </React.StrictMode>,
    )
  })
}

serviceWorker.unregister()
