import React, { useState, useEffect, useMemo } from 'react'
import styled from 'styled-components'
import moment from 'moment'
import _ from 'lodash'
import { DatePicker, Button, Divider, Badge } from 'antd'
import {
  HardcodedFilterOptionsEnum,
  formatDateToMidnight,
  formatDateToEndOfDay,
  formatDateToUTCMidnight,
  formatDateToUTCEndOfDay,
} from 'features/project/features/filters/helpers'
import {
  AppliedFilterOptionRange,
  FilterOption,
  FilterOptionDate,
  FilterOptionRange,
  RelativeDateFilterIdx,
} from '../../types'
import mixpanel from 'features/trackers/mixpanel'
import { useProjectStore } from '../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../types'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import { cardBorderGrey } from '../../../../../../assets/styles/variables'
import { Grid } from '@mui/material'
import DashboardDateSelect from '../../../dashboard/components/Dashboard/components/DashboardDateSelect'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

dayjs.extend(utc)

type CustomProps = {
  custom: any
  panels: number
}

export const periods = [
  {
    name: 'Last 30 days',
    getValues: (lastDate: number) => {
      return [moment.unix(lastDate).add(-30, 'days').unix(), lastDate]
    },
  },
  {
    name: 'Last 3 months',
    getValues: (lastDate: number) => {
      return [moment.unix(lastDate).add(-90, 'days').unix(), lastDate]
    },
  },
  {
    name: 'Last 6 months',
    getValues: (lastDate: number) => {
      return [moment.unix(lastDate).add(-180, 'days').unix(), lastDate]
    },
  },
  {
    name: 'Year to Date',
    getValues: (lastDate: number) => {
      return [moment.unix(lastDate).startOf('year').unix(), lastDate]
    },
  },
]
// TODO generalize isInsight to buttonSize
type Props = {
  comparativeIndex: number
  isInsight?: boolean
  filterValues: Filters
  // these are for custom dash charts to have their own filterlists
  localOpenedOptions?: string[]
  setLocalOpenedOptions?: (openedOptions: string[]) => void
  updateLocalFilterValues?: (filterValues: Filters) => void
  isChild?: boolean
  parentMin: number
  parentMax: number
  isLeft?: boolean
  isUnlinked?: boolean
  disabled?: boolean
}
export const OldDatePanel: React.FC<Props> = ({
  comparativeIndex,
  isInsight,
  filterValues,
  localOpenedOptions,
  setLocalOpenedOptions,
  updateLocalFilterValues,
  isChild,
  parentMin,
  parentMax,
  isLeft,
  isUnlinked,
  disabled,
}) => {
  const isComparative = useProjectStore(
    (state: ProjectState) => state.isComparative,
  )
  const comparativePanelsNumber = useProjectStore(
    (state: ProjectState) => state.comparativePanelsNumber,
  )
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const setFilterValues = useProjectStore(
    (state: ProjectState) => state.setFilterValues,
  )
  const clearFilter = useProjectStore(
    (state: ProjectState) => state.clearFilter,
  )
  const updateRelativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.updateRelativeDateFilterIdx,
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )

  const [localRelativeDateFilterIdx, setLocalRelativeDateFilterIdx] =
    useState(-1)
  const panelRef = React.createRef<HTMLDivElement>()
  const [prevValues, setPrevValues] = useState<number[]>([])
  const [currentValues, setCurrentValues] = useState<number[]>([])
  const [visible, setVisible] = useState(false)
  const [isValidDate, setValidDate] = useState(true)
  const option = defaultFilterList?.find(
    (filterOption) =>
      filterOption.field === HardcodedFilterOptionsEnum.CREATE_TIME,
  ) as FilterOptionDate
  const [notNullOption, setNotNullOption] = useState(option)

  const defaultTime = defaultFilterList.find(
    (el) => el.field === 'create_time',
  ) as FilterOptionRange

  const inputOption = filterValues.values.find(
    (filterValue) =>
      filterValue.field === HardcodedFilterOptionsEnum.CREATE_TIME,
  ) as AppliedFilterOptionRange

  // this function is a disaster sorry
  const handleClear = () => {
    let minDate = moment
      .unix(
        isUnlinked
          ? inputOption?.min
          : parentMin || isUnlinked
            ? parentMin
            : inputOption?.min ||
              option?.min ||
              notNullOption?.min ||
              defaultTime?.min,
      )
      .unix()
    let maxDate = moment
      .unix(
        isUnlinked
          ? inputOption?.max
          : parentMax || isUnlinked
            ? parentMax
            : inputOption?.max ||
              option?.max ||
              notNullOption?.max ||
              defaultTime?.max,
      )
      .unix()
    // when a parent changes or unlink is toggled, it should check to see if the existing value is within the new range before clearing it out
    if (inputOption?.min >= minDate && inputOption?.max <= maxDate) {
      minDate = inputOption.min
      maxDate = inputOption.max
    }
    setPrevValues([minDate, maxDate])
    if (isChild) {
      // if(isUnlinked) {
      setCurrentValues([minDate, maxDate])
      // }  else{
      //   setCurrentValues([parentMin ?? minDate, parentMax ?? maxDate])
      // }
    }
    clearFilter(comparativeIndex, HardcodedFilterOptionsEnum.CREATE_TIME)
    setVisible(false)
    setLocalRelativeDateFilterIdx(RelativeDateFilterIdx.Unset)

    updateSelectedOptionField(comparativeIndex, '')
    clearOpenedOptions(comparativeIndex)
    // TODO this should set to the min and max of the filterValues
    if (updateLocalFilterValues) {
      if (!isChild) {
        updateLocalFilterValues({
          values: [
            ...filterValues.values.filter(
              ({ field }) => field !== 'create_time',
            ),
            {
              field: 'create_time',
              min: minDate,
              max: maxDate,
              value_type: 0,
            },
          ],
          searchCondition: filterValues.searchCondition,
          searchQuery: filterValues.searchQuery,
        })
      } else {
        updateLocalFilterValues({
          values: [
            ...filterValues.values.filter(
              ({ field }) => field !== 'create_time',
            ),
            // {
            //   field: 'create_time',
            //   min: minDate,
            //   max: maxDate,
            //   value_type: 0,
            // },
          ],
          searchCondition: filterValues.searchCondition,
          searchQuery: filterValues.searchQuery,
        })
      }
    }
    updateRelativeDateFilterIdx(comparativeIndex, RelativeDateFilterIdx.Unset)
  }

  const [rangeMin, setRangeMin] = useState(
    isUnlinked
      ? defaultTime?.min
      : parentMin || option?.min || defaultTime?.min,
  )
  const [rangeMax, setRangeMax] = useState(
    isUnlinked
      ? defaultTime?.min
      : parentMax || option?.max || defaultTime?.max,
  )

  // not happy with this
  useEffect(() => {
    if (isChild && rangeMin !== parentMin && rangeMax !== parentMax) {
      if (!isUnlinked) {
        handleClear()
        setRangeMin(parentMin ?? option?.min ?? defaultTime?.min)
        setRangeMax(parentMax ?? option?.max ?? defaultTime?.max)
      }
    }
  }, [parentMin, parentMax, isUnlinked])

  useEffect(() => {
    if (isUnlinked) {
      setRangeMin(defaultTime?.min)
      setRangeMax(defaultTime?.max)
    }
  }, [isUnlinked])

  useEffect(() => {
    if (!option) return

    if (option.max) setNotNullOption(option)
    if (!_.isEqual(currentValues, prevValues)) {
      setPrevValues(currentValues)
      return
    }

    const existingDateValue = filterValues.values.find(
      (o) => o.field === HardcodedFilterOptionsEnum.CREATE_TIME,
    ) as AppliedFilterOptionRange
    // TODO: move to filterContext
    const minDate = moment
      .unix(
        // option?.cur_min ||
        //   notNullOption?.cur_min ||
        inputOption?.min || parentMin,
        // option?.min ||
        // notNullOption?.min
      )
      .unix()
    const maxDate = moment
      .unix(
        // option?.cur_max ||
        //   notNullOption?.cur_max ||
        inputOption?.max || parentMax,
        // option?.max ||
        // notNullOption?.max
      )
      .unix()
    if (!(option && ((minDate && maxDate) || prevValues.length))) {
      setValidDate(false)
    } else if (option && !existingDateValue) {
      setPrevValues([minDate, maxDate])
      setCurrentValues([minDate, maxDate])
    } else if (existingDateValue && !prevValues.length) {
      setPrevValues([existingDateValue.min, existingDateValue.max])
      setCurrentValues([existingDateValue.min, existingDateValue.max])
    }
  }, [option, parentMin, parentMax, localRelativeDateFilterIdx])

  useEffect(() => {
    if (inputOption && prevValues.length) {
      setPrevValues([inputOption.min, inputOption.max])
      setCurrentValues([inputOption.min, inputOption.max])
    }
  }, [inputOption])

  const hasAppliedValue = useMemo(() => {
    return (
      (
        filterValues.values.find(
          ({ field }) => field === HardcodedFilterOptionsEnum.CREATE_TIME,
        ) as AppliedFilterOptionRange
      )?.min !== parentMin ||
      (
        filterValues.values.find(
          ({ field }) => field === HardcodedFilterOptionsEnum.CREATE_TIME,
        ) as AppliedFilterOptionRange
      )?.max !== parentMax
    )
  }, [filterValues])

  const setFilter = (min: number, max: number) => {
    setCurrentValues([min, max])
  }

  const setFilterFromDashSelector = (filters: Filters) => {
    const existingDateValue = filters.values.find(
      (o) => o.field === HardcodedFilterOptionsEnum.CREATE_TIME,
    ) as AppliedFilterOptionRange
    if (existingDateValue) {
      setFilter(existingDateValue.min, existingDateValue.max)
      // setPrevValues([existingDateValue.min, existingDateValue.max])
      // setCurrentValues([existingDateValue.min, existingDateValue.max])
    }
  }

  const submitForm = () => {
    const min = formatDateToUTCMidnight(moment.unix(currentValues[0])).unix()
    const max = formatDateToUTCEndOfDay(moment.unix(currentValues[1])).unix()
    mixpanel.track('filter', {
      action: 'date',
      value: { min: min, max: max },
      ...route,
    })
    const excludedFields = new Set([
      'filter_values',
      HardcodedFilterOptionsEnum.CREATE_TIME,
    ])

    if (updateLocalFilterValues) {
      updateLocalFilterValues({
        values: [
          ...filterValues.values.filter(
            ({ field }) => !excludedFields.has(field),
          ),
          {
            field: HardcodedFilterOptionsEnum.CREATE_TIME,
            min: min,
            max: max,
            value_type: 0, // TODO??
          },
        ],
        searchCondition: filterValues.searchCondition,
        searchQuery: filterValues.searchQuery,
      })
    } else {
      setFilterValues(comparativeIndex, [
        ...filterValues.values.filter(
          ({ field }) => !excludedFields.has(field),
        ),
        {
          field: option.field,
          min: min,
          max: max,
          value_type: 0, // TODO??
        },
      ])
    }
  }

  const isLeftPos =
    isLeft || details.clientSettings.project.toolbar.alignment === 'left'

  const getValidDate = (values: any[]) => {
    // console.log(values.map((el) => new Date(el * 1000).toLocaleDateString()))
    return values.find((el) => !!el && !isNaN(el))
  }

  return (
    <Wrapper
      style={{
        height: isInsight ? '100%' : '',
      }}
    >
      <Badge count={!!inputOption ? '·' : 0} size="small">
        <Button
          onClick={() => setVisible(!visible)}
          style={{
            padding: isComparative && !isInsight ? '0 6px' : '',
            fontSize: 10, //isComparative && !isInsight ? '10px' : '14px',
            height: 34,
            border: `2px solid ${cardBorderGrey}`,
            borderRadius: '6px',
            boxShadow: 'none',
          }}
          disabled={disabled}
        >
          {moment
            .unix(
              getValidDate([
                currentValues[0],
                prevValues[0],
                inputOption?.min,
                defaultTime?.min,
              ]),
            )
            .utc()
            .format('MM/DD/yy')}{' '}
          -{' '}
          {moment
            .unix(
              getValidDate([
                currentValues[1],
                prevValues[1],
                inputOption?.max,
                defaultTime?.max,
              ]),
            )
            .utc()
            .format('MM/DD/yy')}
        </Button>
      </Badge>
      {visible && (
        <PickerContainer
          ref={panelRef}
          panels={comparativePanelsNumber}
          isChild={isChild}
          isLeftPos={isLeftPos}
        >
          {!isChild && (
            <ButtonsWrapper>
              {periods.map((period, idx) => {
                const values = period.getValues(defaultTime?.max)
                const selectedTime = [
                  formatDateToUTCMidnight(
                    moment.unix(
                      getValidDate([
                        currentValues[0],
                        prevValues[0],
                        inputOption?.min,
                        defaultTime?.min,
                      ]),
                    ),
                  ).unix(),
                  formatDateToUTCEndOfDay(
                    moment.unix(
                      getValidDate([
                        currentValues[1],
                        prevValues[1],
                        inputOption?.max,
                        defaultTime?.max,
                      ]),
                    ),
                  ).unix(),
                ]
                return (
                  <YogiButton
                    type={
                      idx === localRelativeDateFilterIdx ||
                      (selectedTime[0] ===
                        formatDateToUTCMidnight(
                          moment.unix(values[0]),
                        ).unix() &&
                        selectedTime[1] ===
                          formatDateToUTCEndOfDay(
                            moment.unix(values[1]),
                          ).unix())
                        ? 'primary'
                        : 'default'
                    }
                    key={period.name}
                    onClick={() => {
                      setFilter(values[0], values[1])
                      setLocalRelativeDateFilterIdx(idx)
                    }}
                    style={{ marginBottom: '5px' }}
                  >
                    {period.name}
                  </YogiButton>
                )
              })}
            </ButtonsWrapper>
          )}
          {isChild && isUnlinked && (
            <ButtonsWrapper>
              <DashboardDateSelect
                filterValues={filterValues}
                updateLocalFilterValues={setFilterFromDashSelector}
                excludeCustom
              />
            </ButtonsWrapper>
          )}
          <Content>
            <DatePicker.RangePicker
              format="MM/DD/YYYY"
              // disabledDate={(date: dayjs.Dayjs) =>
              //   disabledDate(date, rangeMin, rangeMax)
              // }
              minDate={dayjs.unix(rangeMin).utc().startOf('day')}
              maxDate={dayjs.unix(rangeMax).utc().endOf('day')}
              value={[
                dayjs.utc(
                  getValidDate([
                    currentValues[0],
                    prevValues[0],
                    inputOption?.min,
                    defaultTime?.min,
                  ]) * 1000, // Convert Unix timestamp to milliseconds
                ),
                dayjs.utc(
                  getValidDate([
                    currentValues[1],
                    prevValues[1],
                    inputOption?.max,
                    defaultTime?.max,
                  ]) * 1000, // Convert Unix timestamp to milliseconds
                ),
              ]}
              onChange={(dates) => {
                if (dates && dates[0] && dates[1]) {
                  // const firstDate = formatDateToUTCMidnight(dates[0])
                  // const secondDate = formatDateToUTCEndOfDay(dates[1])
                  const firstDate = dates[0].utc().startOf('day')
                  const secondDate = dates[1].utc().endOf('day')
                  setFilter(firstDate.unix(), secondDate.unix())
                  setLocalRelativeDateFilterIdx(RelativeDateFilterIdx.Unset)
                }
              }}
            />
          </Content>
          <Divider style={{ margin: 0 }} />
          <ButtonsWrapper>
            <Button
              type="text"
              style={{ marginLeft: 'auto' }}
              onClick={() => setVisible(false)}
            >
              Cancel
            </Button>
            <YogiButton type="default" onClick={handleClear}>
              Clear
            </YogiButton>
            <YogiButton
              type="primary"
              onClick={() => {
                submitForm()
                setVisible(false)
                updateSelectedOptionField(comparativeIndex, '')
                clearOpenedOptions(comparativeIndex)
                updateRelativeDateFilterIdx(
                  comparativeIndex,
                  localRelativeDateFilterIdx,
                )
              }}
            >
              Apply
            </YogiButton>
          </ButtonsWrapper>
        </PickerContainer>
      )}
    </Wrapper>
  )
}

const disabledDate = (current: moment.Moment, min: number, max: number) => {
  const minDate = formatDateToMidnight(moment.unix(min))
  const maxDate = formatDateToEndOfDay(moment.unix(max))
  return current.isBefore(minDate) || current.isAfter(maxDate)
}

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`
const PickerContainer = styled.div<{
  panels: number
  isChild?: boolean
  isLeftPos?: boolean
}>`
  width: ${({ panels }) => 'calc(100vh/' + panels + ')'};
  max-width: ${({ isChild }) => (isChild ? '400px' : '600px')};
  background: #fff;
  position: absolute;
  display: flex;
  flex-direction: column;
  top: 100%;
  right: ${({ isLeftPos }) => !isLeftPos && 0};
  z-index: 101;
  box-shadow:
    0px 10px 15px rgba(0, 0, 0, 0.1),
    0px 4px 6px rgba(0, 0, 0, 0.05);

  border: 2px solid ${cardBorderGrey};
  border-radius: 4px;
`
const Content = styled.div`
  padding: 5px 14px 10px;
  flex: 1;

  .ant-picker-range {
    width: 100%;
  }
`
const ButtonsWrapper = styled.div`
  padding: 10px 14px;
  display: flex;
  flex-wrap: wrap;

  & > button:not(:last-child) {
    margin-right: 5px;
  }
`
