import { ChartType } from 'chart.js'
import { PieChart, DataForChart } from '../types'
import { numberFormatter } from 'utils/numberFormat'
import { getGroupedChartValues2 } from 'features/project/features/Charts/utils'

export const configureTreemapChart = (
  currentChart: PieChart,
  tier1: string,
  showCategories: boolean,
  expandedCategories: string[],
  disableGrouping?: boolean,
): DataForChart => {
  let values: {
    category: string
    color: string
    percent: number
    volume: number
    hover: any
    [s: string]: number | string
  }[] = getGroupedChartValues2(
    currentChart,
    expandedCategories,
    tier1,
    showCategories,
    disableGrouping,
  )

  // Sort rating from 5 to 1, instead of by volume which is default
  values =
    currentChart.legend_title === 'Rating'
      ? [...values].sort((a: any, b: any) => {
          return Number(b.category) - Number(a.category)
        })
      : values

  const legend = values.map((v) => ({
    color: v.color,
    label: v.category,
    disabled: expandedCategories?.includes(v.category) ?? false,
    children:
      currentChart?.category_mapping &&
      currentChart?.category_mapping[v.category]?.map((item) => ({
        color: values.find((el) => el.category === item)?.color ?? v.color,
        label: item.split(': ')[1] ?? item,
        disabled: false,
      })),
  }))

  const labels = values.map((v) => v[currentChart.legend_key])

  const chartValues = values.map((v) => ({
    value: v.volume,
    title: v[currentChart.legend_key],
    color: v.color,
  })) as any

  const options: any = {
    animation: {
      colors: {
        type: 'color',
        duration: 300,
      },
    },
    responsive: true,
    resizeDelay: 0,
    maintainAspectRatio: false,
    onHover: (event: any, chartElement: any) => {
      event.native.target.style.cursor = chartElement[0] ? 'pointer' : 'default'
    },
    plugins: {
      datalabels: false,
      legend: false,
      tooltip: {
        callbacks: {
          title: function (data: any) {
            return `${data[0].dataset.data[data[0].dataIndex].g}`
          },
          label: function (data: any) {
            return `${numberFormatter(data.dataset.data[data.dataIndex].v)}`
          },
        },
      },
    },
  }

  const getColor = (ctx: any) => {
    const foundItem = chartValues.find(
      (item: any) => ctx.raw?.g.toString() === item.title.toString(),
    )
    return foundItem?.color
  }

  const data: any = {
    datasets: [
      {
        data: chartValues,
        key: 'value',
        tree: chartValues,
        groups: ['title'],
        backgroundColor: getColor,
        color: '#fff',
        font: { family: 'Helvetica' },
        labels: {
          align: 'center',
          display: true,
          formatter(ctx: any) {
            const hover = Object.entries(
              // @ts-ignore
              values.find(
                (item) => item.category.toString() === ctx.raw.g.toString(),
              )?.hover,
            ).map(([key, value]) => {
              return key + ': ' + numberFormatter(value as any)
            })
            return ctx.raw.g + '\n' + hover.join('\n')
          },
          color: '#ffffff',
          position: 'center',
        },
      },
    ],
    labels: labels,
  }

  return { data, options, type: 'treemap' as ChartType, height: 500, legend }
}
