import { useQuery } from 'react-query'
import { initialState, useProjectStore } from '../projectStore/projectStore'
import { ProjectState } from '../types'
import { getProject } from '../model'
import mixpanel from '../../trackers/mixpanel'

export const useComparativeNavigation = () => {
  const addGlobalPanel = useProjectStore(
    (state: ProjectState) => state.addPanel,
  )
  const removeGlobalPanel = useProjectStore(
    (state: ProjectState) => state.removePanel,
  )

  const addPanel = () => {
    mixpanel.track('compare mode', { action: 'add panel' })
    addGlobalPanel()
  }

  const removePanel = (index: number) => {
    mixpanel.track('compare mode', { action: 'remove panel' })
    removeGlobalPanel(index)
  }
  return { addPanel, removePanel }
}
