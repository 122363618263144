import { useState, useEffect } from 'react'
import { Post } from 'features/project/features/feedback/types'
import { getFeedback, SEARCH_MODE } from '../../feedback'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'

export const usePhraseList = (
  phrase: string,
  useKeywordsSearch: boolean,
  comparativeIndex: number
) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const filterValues = useProjectStore(
    (state: ProjectState) => state.filters[comparativeIndex]
  )

  const [posts, setPosts] = useState<Post[]>([])
  const [page, setPage] = useState(1)
  const [lastPage, setLastPage] = useState(0)
  const [isLoading, setLoading] = useState(false)

  useEffect(() => {
    fetch()
  }, [
    filterValues.values,
    filterValues.searchQuery,
    filterValues.searchCondition,
    useKeywordsSearch,
  ])
  const fetch = (page?: number) => {
    if (isLoading) return
    setLoading(true)
    getFeedback(
      {
        proj_uuid: projectId,
        page: page || 1,
        //@ts-ignore
        page_size: 50,
      },
      {
        criteria: filterValues.values,
        search_terms: [phrase],
        search_mode: useKeywordsSearch
          ? SEARCH_MODE.POST_TEXT_KEYWORDS
          : SEARCH_MODE.POST_TEXT_NORMAL,
      }
    )
      .then((response) => {
        const { data } = response
        setPosts([...(page ? posts : []), ...data.posts])
        setLastPage(data.n_pages)
      })
      .finally(() => setLoading(false))
  }

  return {
    posts,
    isLoading,
    hasMore: page < lastPage,
    loadMore: () => {
      const newPage = page + 1
      setPage(newPage)
      fetch(newPage)
    },
  }
}
