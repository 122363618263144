import React, { ChangeEvent, useEffect, useState } from 'react'
import { Button, Input, Popover } from 'antd'
import { Filters, ProjectState } from 'features/project/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import styled from 'styled-components'
import mixpanel from 'features/trackers/mixpanel'
import useExport from 'features/project/features/Charts/components/ExportDrawer/useExport'
import * as Yup from 'yup'

type Props = {
  children: React.ReactNode
  comparativeIndex: number | undefined
  filterValues: Filters
}
const validationSchema = Yup.string().email('invalid email').required()

export const ExportReviews: React.FC<Props> = ({
  children,
  comparativeIndex,
  filterValues,
}) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const route = useProjectStore((state: ProjectState) => state.route)
  const isExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isExportDrawerOpen,
  )

  const [publicViewerEmail, setPublicViewerEmail] = useState<
    string | undefined
  >(undefined)
  const [publicEmailIsValid, setPublicEmailIsValid] = useState<boolean>(false)
  const [isVisible, setIsVisible] = React.useState(false)
  const [isLoading, setIsLoading] = React.useState(false)
  const [isFilteredLoading, setIsFilteredLoading] = React.useState(false)

  const { downloadReviews } = useExport({
    comparativeIndex: comparativeIndex || 0,
  })

  useEffect(() => {
    setIsVisible(false)
  }, [isExportDrawerOpen])

  const isPublicViewer = details.role === 'public_viewer'

  return (
    <Popover
      open={isVisible}
      onOpenChange={(v: boolean) => {
        setIsVisible(v)
      }}
      trigger="click"
      placement={'bottom'}
      content={
        <div style={{ display: 'flex', flexDirection: 'column', gap: 5 }}>
          <Button
            onClick={() => {
              mixpanel.track('posts download', {
                action: 'all',
                ...route,
              })
              setIsLoading(true)
              downloadReviews(
                undefined,
                publicViewerEmail,
                publicEmailIsValid,
              ).then(() => {
                setIsVisible(false)
                setIsLoading(false)
              })
            }}
            loading={isLoading}
          >
            Export All Reviews
          </Button>
          <Button
            onClick={() => {
              mixpanel.track('posts download', {
                action: 'all',
                ...route,
              })
              setIsFilteredLoading(true)
              downloadReviews(
                filterValues,
                publicViewerEmail,
                publicEmailIsValid,
              ).then(() => {
                setIsVisible(false)
                setIsFilteredLoading(false)
              })
            }}
            loading={isFilteredLoading}
          >
            Export Filtered Reviews
          </Button>
          {isPublicViewer && (
            // TODO TEST/FIX THIS -- certainly breaks hardcoded heights
            <Header>
              <StyledInput
                placeholder="Email..."
                value={publicViewerEmail}
                onChange={(e: ChangeEvent<HTMLInputElement>) => {
                  const value = e.target.value
                  setPublicViewerEmail(value)
                  validationSchema
                    .validate(value)
                    .then(() => setPublicEmailIsValid(true))
                    .catch(() => setPublicEmailIsValid(false))
                }}
              />
            </Header>
          )}
        </div>
      }
    >
      <div onClick={() => setIsVisible(!isVisible)}>{children}</div>
    </Popover>
  )
}

const Header = styled.div`
  padding: var(--default-padding-half) 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
`
const StyledInput = styled(Input)`
  width: 200px;
  margin-right: var(--default-padding-half);
`
