import React, { useEffect, useState } from 'react'
import { Typography } from 'antd'
import styled from 'styled-components'
import { Post, PostIcons, Stars } from './'
import { SourceIcon } from 'shared/components'
import { highlightPost } from './post-utils'
import { SEARCH_HIGHLIGHT_COLOR } from '../../utils'
import moment from 'moment'
import postsEmpty from '../../../../assets/images/empty/PostsEmpty.png'
import { PostsEmpty } from '../../../../components/PostsEmpty'
import html2canvas from 'html2canvas'
import logo from '../../../../assets/images/logos/Peach_Horizontal.png'
import { toast } from 'react-toastify'
import { useHyperCopy } from '../../hooks/useHyperCopy'
import { HyperDiv } from '../../../../components/HyperCopy/HyperDiv'
import { Resizable } from 're-resizable'
import { useProjectStore } from '../../projectStore/projectStore'
import { ProjectState } from '../../types'

type Props = {
  posts: Post[]
  // TODO this should be postUuid
  onPostClick: (postId: string) => void
  showHighlights?: boolean
  isThemeTier?: boolean
  isLoading?: boolean
  isFetching?: boolean
  isInsights?: boolean
  resetView?: boolean
  setHasFormatting?: (hasFormatting: boolean) => void
  hasBorder?: boolean
}

const formatText = (text: string, isThemeTier?: boolean) => {
  return isThemeTier ? `...${text}...` : text
}

export const PostsGrid: React.FC<Props> = ({
  posts,
  onPostClick,
  showHighlights = true,
  isThemeTier,
  isLoading,
  isFetching,
  isInsights,
  resetView,
  setHasFormatting,
  hasBorder,
}) => {
  const details = useProjectStore((state: ProjectState) => state.details)
  const isHyperCopy = useProjectStore(
    (state: ProjectState) => state.isHyperCopy,
  )
  const [highlightedText, setHighlightedText] = useState<string>('')

  useEffect(() => {
    if (resetView) {
      setHighlightedText('')
    }
  }, [resetView])

  useEffect(() => {
    if (setHasFormatting && highlightedText) {
      setHasFormatting(true)
    }
  }, [highlightedText])

  if (!posts.length && !isLoading && !isFetching) {
    return (
      <PostsEmpty>No reviews with text for the selected filters.</PostsEmpty>
    )
  }
  const isAdmin = details.role === 'admin'

  const customHighlight = (text: string) => {
    // console.log(highlightedText)
    // console.log(text)
    // if (!highlightedText || !isHyperCopy || !text) return text
    if (!highlightedText || !text) return text
    return text.replaceAll(
      highlightedText,
      `<Yogi-highlight>${highlightedText}</Yogi-highlight>`,
    )
  }
  // console.log(posts)

  const postComponent = (post: Post) => {
    return (
      <PostCard
        elementName={'post-card'}
        className={'post-card'}
        key={post.uuid}
        onClick={(e) => {
          if (!window?.getSelection()?.toString()) {
            onPostClick(post.uuid)
          }
          // setSelectedElement(e.currentTarget)
          // onPostClick(post.uuid)
        }}
        getHighlightedText={setHighlightedText}
        isInsights
        hasBorder={hasBorder}
      >
        <Header post={post} />
        <Typography.Paragraph ellipsis={{ rows: isInsights ? 4 : 7 }}>
          {post.title && (
            <Typography.Text strong={true}>
              {highlightPost({
                postBody: formatText(customHighlight(post.title)),
                color: SEARCH_HIGHLIGHT_COLOR,
                showHighlights: showHighlights,
              })}
              <br />
            </Typography.Text>
          )}
          {highlightPost({
            postBody: formatText(customHighlight(post.body), isThemeTier),
            color: SEARCH_HIGHLIGHT_COLOR,
            showHighlights: showHighlights,
          })}
        </Typography.Paragraph>
        <Footer post={post} />
      </PostCard>
    )
  }

  return (
    <PostsGridWrapper id="post-wrapper">
      {posts.map(
        (post) =>
          post && (
            <>
              {resetView ? (
                postComponent(post)
              ) : (
                <StyledResizable
                  postsLength={posts.length}
                  onResize={() => {
                    setHasFormatting && setHasFormatting(true)
                  }}
                >
                  {postComponent(post)}
                </StyledResizable>
              )}
            </>
          ),
      )}
    </PostsGridWrapper>
  )
}

const StyledResizable = styled(Resizable)<{ postsLength: number }>`
  display: flex;
  flex-wrap: wrap;
  flex-grow: ${(props) => (props.postsLength > 1 ? '1' : '0')};
  flex-shrink: 0;
  min-width: 250px;
  flex-basis: 250px;
  max-width: 100%;
  max-height: 400px;
  //height: 100%;
  //gap: var(--default-padding-half);
`

const PostsGridWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  //height: 100%;
  gap: var(--default-padding-half);
`

const PostCard = styled(HyperDiv)<{
  isInsights?: boolean
  hasBorder?: boolean
}>`
  padding: var(--default-padding-half);
  background: #fff;
  box-shadow: var(--default-box-shadow);
  transition: transform ease 0.1s;
  transform: scale(1);
  position: relative;
  cursor: pointer;
  border-radius: var(--border-radius);
  flex-grow: 1;
  flex-shrink: 0;
  flex-basis: 250px;
  overflow: hidden;
  display: flex;
  flex-flow: column nowrap;
  min-height: ${(props) => (props.isInsights ? '200px' : '100%')};
  border: ${(props) => (props.hasBorder ? '1px solid #f4f4f4' : '')};

  &:hover {
    background: #f4f4f4;
  }
`

const Header: React.FC<{ post: Post }> = ({ post }) => {
  const dateString = post.create_time
    ? moment.unix(post.create_time).utc().format('MM/DD/yyyy')
    : ''
  return (
    <Typography.Paragraph style={{ display: 'flex', alignItems: 'center' }}>
      <Stars score={post.score} style={{ flex: 1 }} />
      <Typography.Text
        type={'secondary'}
        style={{ fontSize: 'var(--font-size-sm)' }}
      >
        {dateString}
      </Typography.Text>
    </Typography.Paragraph>
  )
}

const Footer: React.FC<{ post: Post }> = ({ post }) => {
  const iconSource = post.syndicated_source ? 'syndicated' : post.source_type

  return (
    <FooterWrapper>
      {/* Source and username */}
      <Typography.Paragraph
        type={'secondary'}
        style={{ fontSize: 'var(--font-size-sm)', marginBottom: 0, flex: 1 }}
      >
        <b>{post.product_display_name}</b>
        <br />
        <StyledSourceIcon source={iconSource} />
        {post.source_type} · {post.username}
      </Typography.Paragraph>

      <PostIcons
        is_verified={post.is_verified}
        is_promoted={post.is_promoted}
        is_recommended={post.is_recommended}
      />
    </FooterWrapper>
  )
}

const FooterWrapper = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
`

const StyledSourceIcon = styled(SourceIcon)`
  // TODO make this not black once all SourceIcons can be colored
  //  (no hardcoded fills in the SVGs)
  color: black;
  height: 1em;
  width: 1em;
  margin-right: 5px;
  vertical-align: -0.125em;
`
