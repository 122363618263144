export const processLabel = (threshold: number, label?: string): string => {
  if (!label) return ''
  if (label.length > threshold) {
    let index =
      label.slice(0, threshold).lastIndexOf(' ') === -1
        ? label.indexOf(' ')
        : label.slice(0, threshold).lastIndexOf(' ')

    if (index < 0) return label
    return (
      label.slice(0, index) +
      '\n' +
      processLabel(threshold, label.slice(index + 1))
    )
  } else {
    return label
  }
}
