import { axiosInstance } from '../../../../utils/axios'
import { AxiosResponse } from 'axios'
import { AppliedFilterOption } from '../filters/types'
import React from 'react'
import { NotificationsResponse } from '../notifications/model'
import { UserInfo } from '../../types'

const ALERTS_BASE_URL = '/alerts'

export { ALERTS_BASE_URL }

export type AlertResponse = {
  alert: AlertType
  owners: UserInfo[]
  viewers: UserInfo[]
  is_enabled: boolean
}

export type AlertUser = {
  invited_at: string
  status: string
  user: UserInfo
}

export interface AlertType {
  alert_scope: string
  alert_type: string
  calc_info: string
  compare_value: number
  created_by: UserInfo
  created_dt: string
  error_message: string
  filters: Array<AppliedFilterOption>
  is_grouped: boolean
  id: number
  last_sent_dt: string
  project_id: number
  proj_uuid: string
  reviews_filter: number
  search_criteria: string[]
  search_terms: string[]
  status: string
  time_interval: string
  title: string
  trigger_type: string
  updated_dt: string
  url: string
}

export type AlertBody = {
  title: string
  alert_type: string
  trigger_type: string
  time_interval: string
  reviews_filter: number
  shared_users: string[]
  alert_scope: string
  search_terms: string[]
  search_criteria: string[]
  filters: Array<AppliedFilterOption>
  status: string
}

export type AlertCardProps = {
  alertData: AlertResponse
  toggleActive: (id: number, active: boolean) => void
  onSelect: (id: number) => void
  actions: React.ReactNode
  listType: string
  notifications: NotificationsResponse[] | undefined
  markAsViewed: (id: number) => void
}

export const getAlerts = async (
  proj_uuid: string
): Promise<AlertResponse[]> => {
  const { data } = await axiosInstance.get<AlertResponse[]>(ALERTS_BASE_URL, {
    params: {
      proj_uuid: proj_uuid,
    },
  })

  return data
}

export const saveAlert = async (
  proj_uuid: string,
  alert: AlertBody
): Promise<AlertResponse> => {
  const { data } = await axiosInstance.post(
    ALERTS_BASE_URL,
    {
      ...alert,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const updateAlert = async (
  proj_uuid: string,
  alert: AlertType
): Promise<AlertResponse> => {
  const { data } = await axiosInstance.put(
    `${ALERTS_BASE_URL}/${alert.id}`,
    {
      ...alert,
    },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const enableAlert = async (
  proj_uuid: string,
  alertId: number
): Promise<AxiosResponse> => {
  const data = await axiosInstance.put(
    `${ALERTS_BASE_URL}/${alertId}/enable`,
    {},
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const disableAlert = async (
  proj_uuid: string,
  alertId: number
): Promise<AxiosResponse> => {
  const data = await axiosInstance.put(
    `${ALERTS_BASE_URL}/${alertId}/disable`,
    {},
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const shareAlert = async (
  proj_uuid: string,
  body: { alert_id: number; as_owners: boolean; email_addresses: string[] }
): Promise<AxiosResponse> => {
  const data = await axiosInstance.post(
    `${ALERTS_BASE_URL}/${body.alert_id}/share`,
    body.email_addresses,
    {
      params: {
        as_owners: body.as_owners,
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const unshareAlert = async (
  proj_uuid: string,
  body: { alert_id: number; email_addresses: string[] }
): Promise<AxiosResponse> => {
  const data = await axiosInstance.post(
    `${ALERTS_BASE_URL}/${body.alert_id}/unshare`,
    body.email_addresses,
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const unsubscribeAlert = async (
  proj_uuid: string,
  alert_id: number
): Promise<AxiosResponse> => {
  const data = await axiosInstance.post(
    `${ALERTS_BASE_URL}/${alert_id}/unsubscribe`,
    {},
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const testEmail = async (
  proj_uuid: string,
  alert_id: number
): Promise<AxiosResponse> => {
  const data = await axiosInstance.put(
    `${ALERTS_BASE_URL}/process-alerts`,
    { alerts: [alert_id], is_test: false },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
export const testAlert = async (
  proj_uuid: string,
  alert_id: number
): Promise<AxiosResponse> => {
  const data = await axiosInstance.put(
    `${ALERTS_BASE_URL}/process-alerts`,
    { alerts: [alert_id], is_test: true },
    {
      params: {
        proj_uuid: proj_uuid,
      },
    }
  )
  return data
}
