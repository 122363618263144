import React from 'react'
import { Divider, Modal, Table } from 'antd'
import { BarcodeOutlined } from '@ant-design/icons'
import styled from 'styled-components'
import { Product } from '../types'
import { ProductTableDescription } from './product-description'
import { ProductCustomFields } from './product-custom-fields'
import { SourceCard } from './product-source'

type Props = {
  product: Product
  handleOk: (e: any) => void
  handleCancel: (e: any) => void
}

export const ProductModal: React.FC<Props> = ({
  product,
  handleOk,
  handleCancel,
}) => {
  return (
    <Modal
      title={product.name}
      visible={true}
      onOk={handleOk}
      onCancel={handleCancel}
      width={700}
      bodyStyle={{ height: '80vh', overflowY: 'scroll' }}
      centered
      footer={null}
    >
      <ImageWrapper>
        {product.image_url ? (
          <img src={product.image_url} width="128" height="auto" alt="" />
        ) : (
          <BarcodeOutlined style={{ fontSize: '60px' }} />
        )}
      </ImageWrapper>
      <ProductTableDescription
        name={product.name}
        image_url={product.image_url}
        first_review={product.first_review}
        last_review={product.last_review}
        review_count={product.review_count}
        rating_count={product.rating_count}
        generic_listing={product.generic_listing}
      ></ProductTableDescription>
      <br></br>
      <Divider> Sources: </Divider>
      <SourceWrapper>
        {product.sources &&
          product.sources.map((product_source) => (
            <SourceCard source={product_source}></SourceCard>
          ))}
      </SourceWrapper>
      <Divider> Product Details </Divider>
      <ProductCustomFields data={product.custom_fields}></ProductCustomFields>
    </Modal>
  )
}

const ImageWrapper = styled.div`
  display: flex;
  vertical-align: center;
  justify-content: center;
`
const SourceWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-left: 1%;
`
