import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router'
import styled from 'styled-components'
import { useQuery } from 'react-query'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { Button, Form, Tag, Select, Input, Tooltip } from 'antd'
import { toast } from 'react-toastify'
import { CopyOutlined, InfoCircleOutlined } from '@ant-design/icons'
import { AuthContext } from 'features/auth'
import mixpanel from 'features/trackers/mixpanel'
import { FormItem, Loader } from 'shared/components'
import {
  fetchSharedUsers,
  shareProjectWithUncheckedUser,
  fetchUrlForSharing,
} from '../model'
import { tooltip } from 'utils/tooltip-data'
import { useProjectStore } from '../../../projectStore/projectStore'
import { ProjectState } from '../../../types'
import { YogiButton } from '../../../../../components/UI/YogiButton'

type Props = {
  projectId: string
  onNext: (e: string) => void
  isUserAdmin: boolean
  onRemoveEmail: (tag: string) => Promise<void>
}

const layout = {
  labelCol: { span: 4 },
  wrapperCol: { span: 20 },
}

const validationSchema = Yup.object().shape({
  email: Yup.string().email('Invalid email').required('Field is required'),
})

export const ShareDashboard: React.FC<Props> = ({
  onNext,
  projectId,
  isUserAdmin,
  onRemoveEmail,
}) => {
  const history = useHistory()
  const [isLocalLoading, setLocalLoading] = useState(false)
  const [isSharingEnabled, setSharingEnabled] = useState(false)
  const { userDetails }: any = useContext(AuthContext)
  const details = useProjectStore((state: ProjectState) => state.details)
  const isAdmin = details.role === 'admin'
  const { data, isLoading, refetch } = useQuery(
    `shared-users-${projectId}`,
    () => fetchSharedUsers(projectId)
  )
  const {
    data: shareUrl,
    isLoading: isSharingUrlLoading,
    refetch: fetchUrl,
  } = useQuery(
    `token-${projectId}`,
    () => fetchUrlForSharing(projectId, isSharingEnabled),
    { enabled: false }
  )

  const [errorMessage, setErrorMessage] = useState('')

  const formik = useFormik({
    validationSchema,
    initialValues: {
      role: 'viewer',
      email: '',
    },
    onSubmit: async ({ email, role }, { setFieldError, resetForm }) => {
      setErrorMessage('')
      const normalizedEmail = email.toLowerCase()
      const userNormalizedEmailDomain = userDetails.email
        .split('@')[1]
        .toLowerCase()
      if (normalizedEmail.split('@')[1] !== userNormalizedEmailDomain) {
        if (userNormalizedEmailDomain !== 'meetyogi.com') {
          setFieldError('email', 'You are not allowed to invite this user')
          return Promise.resolve(false)
        }
      }

      try {
        const success = await shareProjectWithUncheckedUser(
          projectId,
          normalizedEmail,
          role === 'admin'
        )

        if (success) {
          resetForm()
          refetch()
        } else {
          onNext(email)
        }
      } catch (e: any) {
        setErrorMessage('Error: ' + e?.response?.data?.detail?.msg)
      }
    },
  })
  const shareUrlExpanded = shareUrl ? shareUrl + history.location.search : ''

  if (isLoading || !data) {
    return <Loader paddingTop={false} />
  }

  const copyToClipboard = () => {
    if (shareUrlExpanded) {
      const el = document.createElement('textarea')
      el.value = shareUrlExpanded
      document.body.appendChild(el)
      el.select()
      document.execCommand('copy')
      document.body.removeChild(el)
      toast.info('Share URL is copied')
    }
  }

  return (
    <Wrapper>
      <Form
        className="share-form"
        onFinish={() => {
          mixpanel.track('share', {
            action: 'share',
            role: formik.values.role,
            recipient: formik.values.email,
          })
          formik.handleSubmit()
        }}
        {...layout}
      >
        <FormItem label="Email" field="email" formik={formik} />
        <Form.Item label="Role">
          <Select
            value={formik.values.role}
            style={{ width: '100%' }}
            onChange={(v: string) => formik.setFieldValue('role', v)}
          >
            <Select.Option value="viewer">Viewer</Select.Option>
            {isUserAdmin && <Select.Option value="admin">Admin</Select.Option>}
          </Select>
        </Form.Item>
        <YogiButton
          style={{ margin: '0 auto 40px', display: 'block' }}
          type="primary"
          htmlType="submit"
          loading={formik.isSubmitting}
        >
          Share
        </YogiButton>
        {errorMessage && (
          <p style={{ color: 'red', textAlign: 'center' }}>{errorMessage}</p>
        )}
        <Form.Item label="Admins">
          <Users>
            {data.admins.concat(data.yogi_employees).map((item) => (
              <Tag key={item}>{item}</Tag>
            ))}
          </Users>
        </Form.Item>
        <Form.Item label="Viewers">
          <Users>
            {data.shared_users.length
              ? data.shared_users.map((item) => (
                  <Tag
                    key={item}
                    closable={isUserAdmin && item !== userDetails.email}
                    onClose={() => onRemoveEmail(item).then(() => refetch())}
                  >
                    {item}
                  </Tag>
                ))
              : 'No users...'}
          </Users>
        </Form.Item>
        {isAdmin && (
          <Form.Item label="Public URL">
            <Input
              value={shareUrlExpanded}
              style={{ width: 200, marginRight: 20 }}
              disabled
            />
            <YogiButton
              type="primary"
              shape="circle"
              icon={<CopyOutlined />}
              onClick={copyToClipboard}
              style={{ marginRight: 20 }}
              disabled={isSharingUrlLoading}
            />
            <Button
              onClick={() => {
                setSharingEnabled(true)
                setTimeout(() => {
                  setLocalLoading(true)
                  fetchUrl().then(() => setLocalLoading(false))
                }, 0)
              }}
            >
              On
            </Button>
            <Button
              onClick={() => {
                setSharingEnabled(false)
                setTimeout(() => {
                  setLocalLoading(true)
                  fetchUrl().then(() => setLocalLoading(false))
                }, 0)
              }}
            >
              Off
            </Button>
            <Tooltip title={tooltip['public-url']}>
              {' '}
              <InfoCircleOutlined />
            </Tooltip>
          </Form.Item>
        )}
      </Form>
      {isLocalLoading && (
        <LoadingLayer>
          <Loader />
        </LoadingLayer>
      )}
    </Wrapper>
  )
}
const Wrapper = styled.div`
  position: relative;

  .ant-btn > .ant-btn-loading-icon {
    padding-right: 4px;
  }

  .ant-btn > .ant-btn-loading-icon .anticon {
    padding-right: 0px;
  }
`
const Users = styled.div`
  display: flex;
  flex-wrap: wrap;

  & > * {
    margin-bottom: 6px;
  }
`
const LoadingLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.6);
`

const StyledFormItem = styled(FormItem)`
  .ant-input {
    background: white;
    border: 2px solid var(--card-border-grey);
    border-radius: 6px;
  }
`
