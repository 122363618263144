import { comparativePart } from 'utils/links'
import { format } from 'date-fns'
import { DateWindows, Filters, PostDetails } from './types'
import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
} from './features/filters/types'
import { axiosInstance } from 'utils/axios'
import { toast } from 'react-toastify'
import _ from 'lodash'
import queryString from 'query-string'
import {
  formatDateToUTCEndOfDay,
  formatDateToUTCMidnight,
  parseUriPayload,
} from './features/filters/helpers'
import { initialState } from './projectStore/projectStore'
import { DateRange } from './features/dashboard/components/Dashboard/components/DashboardDateSelect'
import moment from 'moment'
import { mainText } from 'assets/styles/variables'

export const DASHBOARD_URL = 'custom-dashboards'
export const CHARTS_URL = 'dashboard'
export const NETWORK_URL = 'network'
export const FEEDBACK_URL = 'feedback'
export const CATALOG_URL = 'catalog'
export const PDP_URL = 'PDP'
export const SOURCES_URL = 'sources'
export const GEMINI_URL = 'gemini'
export const AUTOMATED_URL = 'automated'
export const AUTO_INSIGHTS_URL = 'auto-insights'
export const PRODUCTS_URL = 'products'
export const SETTINGS_URL = 'settings'
export const INSIGHTS_URL = 'insights'
export const FINDINGS_URL = 'findings'
export const RECOMMENDATIONS_URL = 'recommendations'
export const KEYWORDS_URL = 'keywords'
export const SAVED_VIEWS_URL = 'saved-views'
export const ALERTS_URL = 'alerts'
export const HIGHLIGHT_TAG_ROOT = 'Yogi-highlight'
export const SEARCH_HIGHLIGHT_COLOR = 'rgba(241, 197, 95, 100)'
export const PROMOTION_ENABLED_HIGHLIGHT_COLOR = 'rgba(187,241,176,2)'
export const TRANSPARENT_COLOR = 'rgba(0,0,0,0)'
export const DEFAULT_CHART_FONT_SIZE = 'medium'

export const emptyFilters: Filters = {
  values: [],
  searchCondition: [],
  searchQuery: [],
}

export const getCurrentRouteName = (pathname: string) => {
  const isComparative = pathname.indexOf(comparativePart) !== -1
  const parts = pathname.split('/').filter((l) => l.length)
  const lastPart = isComparative
    ? parts[parts.length - 2]
    : parts[parts.length - 1]
  return lastPart
}
export const detectCurrentRoute = (pathname: string) => {
  const isComparative = pathname.indexOf(comparativePart) !== -1
  const parts = pathname.split('/').filter((l) => l.length)
  const lastPart = isComparative
    ? parts[parts.length - 2]
    : parts[parts.length - 1]

  const isNetwork = lastPart?.indexOf(NETWORK_URL) !== -1
  const isFeedback = lastPart?.indexOf(FEEDBACK_URL) !== -1
  const isSource = lastPart?.indexOf(SOURCES_URL) !== -1
  const isSettings = lastPart?.indexOf(SETTINGS_URL) !== -1
  const isRecommendations = lastPart?.indexOf(RECOMMENDATIONS_URL) !== -1
  const isProduct = lastPart?.indexOf(PRODUCTS_URL) !== -1
  const isKeywords = lastPart?.indexOf(KEYWORDS_URL) !== -1
  // dashboard and charts share the same substring so we need to direct compare. not sure why indexof was used in the first place really
  const isCharts = lastPart === CHARTS_URL
  const isSavedViews = lastPart?.indexOf(SAVED_VIEWS_URL) !== -1
  const isInsights = lastPart?.indexOf(INSIGHTS_URL) !== -1
  const isAlerts = lastPart?.indexOf(ALERTS_URL) !== -1
  const isCompass = parts.length == 2
  const isDashboard = lastPart === DASHBOARD_URL
  const isFocus = lastPart?.indexOf(CATALOG_URL) !== -1
  const isPDP = lastPart?.indexOf(PDP_URL) !== -1
  const isGemini = lastPart?.indexOf(GEMINI_URL) !== -1
  const isAutomated = lastPart?.indexOf(AUTOMATED_URL) !== -1
  const isFindings = lastPart?.indexOf(FINDINGS_URL) !== -1
  const isAutoInsights = lastPart?.indexOf(AUTO_INSIGHTS_URL) !== -1

  return {
    isSource,
    isNetwork,
    isProduct,
    isKeywords,
    isSettings,
    isFeedback,
    isCharts,
    isComparative,
    isRecommendations,
    isSavedViews,
    isInsights,
    isCompass,
    isAlerts,
    isDashboard,
    isFocus,
    isPDP,
    isGemini,
    isAutomated,
    isFindings,
    isAutoInsights,
  }
}

export const postToCsv = (post?: PostDetails) => {
  if (!post) return ''
  let headerRow: string | null = null
  let contentRow: string | null = null
  for (const [key, value] of Object.entries(post as any)) {
    if (value && key !== 'post_uuid') {
      const formattedKey = key
        .split('_')
        .map((item) => item[0].toUpperCase() + item.slice(1))
        .join(' ')
      let resKey = ''
      switch (formattedKey) {
        case 'Create Time':
          resKey = 'Date'
          break
        case 'Product Hierarchy':
          resKey = 'Product'
          break
        case 'Product':
          resKey = 'Item'
          break
        case 'Product Group':
          resKey = 'Item Groups'
          break
        case 'Score':
          resKey = 'Rating'
          break
        default:
          resKey = formattedKey
      }
      const resValue =
        resKey === 'Date'
          ? format(new Date(+(value as string) * 1000), 'MM/dd/yyyy')
          : value

      if (typeof value == 'object') {
        Object.entries(value as object).forEach(([key, value]) => {
          headerRow = headerRow ? headerRow + '\t' + key : '' + key
          contentRow = contentRow ? contentRow + '\t' + value : '' + value
        })
      } else {
        headerRow = headerRow ? headerRow + '\t' + resKey : '' + resKey
        contentRow = contentRow ? contentRow + '\t' + resValue : '' + resValue
      }
    }
  }
  return headerRow + '\n' + contentRow
}

const getQueryParamByKey = (key: string): string => {
  // Get the value of "some_key" in eg "https://example.com/?some_key=some_value"
  // let value = params.some_key // "some_value")
  const params: any = new Proxy(new URLSearchParams(window.location.search), {
    get: (searchParams, prop: string) => searchParams.get(prop),
  })

  return params[key]
}

export const getReport = async (body: any, proj_uuid: string): Promise<any> => {
  const currentRoute = detectCurrentRoute(window.location.pathname)

  // for some reasons unkown for me there is not always chart_id is pased to
  if (currentRoute.isCharts) {
    const globalFilters = body.filter_context.filterValues.globalViewFilters
    if (globalFilters.find(({ field }: any) => field === 'chart_id')?.values) {
      globalFilters.find(({ field }: any) => field === 'chart_id').values = [
        getQueryParamByKey('chart_id'),
      ]
    } else {
      globalFilters.push({
        field: 'chart_id',
        values: [getQueryParamByKey('chart_id')],
      })
    }

    if (globalFilters.find(({ field }: any) => field === 'post_data')?.values) {
      globalFilters.find(({ field }: any) => field === 'post_data').values = [
        getQueryParamByKey('post_data'),
      ]
    } else {
      globalFilters.push({
        field: 'post_data',
        values: [getQueryParamByKey('post_data')],
      })
    }
  }

  toast.success('Your export will be sent to your email!')
  return axiosInstance
    .post(
      '/dashboard/generate-report',
      { body },
      { params: { proj_uuid }, responseType: 'blob', timeout: 180000 },
    )
    .catch(() => {
      toast.error(
        'Something wrong happened on our side. Mail will not be sent :(',
      )
    })
}

export const generateAppliedOptionSelect = (field: string, values: string[]) =>
  ({ field, values }) as AppliedFilterOptionSelect

export const trimText = (text: string, maxLength: number) =>
  text.length > maxLength ? `${text.slice(0, maxLength).trim()}...` : text

export const getComparativeIndex = (value: string) => {
  if (value) {
    const valueParts = value.split('_')
    const lastPart = Number(valueParts[valueParts.length - 1])
    if (Number.isInteger(lastPart)) {
      return lastPart // not to break everything
    }
  }
  return -1
}

export const getFilterValueName = (value: any) => {
  return (value as string)?.slice(0, -2)
}

export function parseUriParams(url: string): Array<AppliedFilterOption> {
  const parsedUrl = queryString.parse(url)
  return parseUriPayload(parsedUrl)
}

function stringToBoolean(string: string) {
  if (string && typeof string === 'string') {
    if (string.toLowerCase() === 'true') {
      return true
    }
    if (string.toLowerCase() === 'false') {
      return false
    }
  }
  return string
}

export const hydrateSavedViewURL = (
  queryString: string,
  updateFilters: any,
  setDashboardControls: any,
  setFeedbackControls: any,
  setKeywordsControls: any,
  setNetworkControls: any,
) => {
  const urlParams = new URLSearchParams(queryString)

  let filter_values: any = parseUriParams(queryString).find(
    (el) => el.field === 'filter_values',
  )
  let newStyleFilters: any[] = []
  if (filter_values) {
    // this is promising for parsing the payload - odd format for what i'm doing tho
    const parsedPayload = parseUriPayload(filter_values?.values)
    // todo transform filter_values into filterValues format

    parsedPayload.sort(
      (a, b) => getComparativeIndex(a.field) - getComparativeIndex(b.field),
    )

    parsedPayload.forEach((el: any) => {
      let cI = getComparativeIndex(el.field)

      if (!newStyleFilters[cI]) {
        newStyleFilters.push({
          values: [],
          searchQuery: [],
          searchCondition: [],
        })
      }
      newStyleFilters[cI].values.push({
        ...el,
        field: getFilterValueName(el.field),
      })
    })
  }
  //
  // urlParams.delete('filter_values')
  //
  // populate search query and search condition

  let searchQueries: any = parseUriParams(queryString).filter((el) =>
    el.field.match(/search_query_\d/),
  )
  searchQueries.forEach((el: AppliedFilterOptionSelect) => {
    let cI = getComparativeIndex(el.field)

    if (!newStyleFilters[cI]) {
      newStyleFilters.push({
        values: [],
        searchQuery: [],
        searchCondition: [],
      })
    }
    newStyleFilters[cI].searchQuery = el.values
    // urlParams.delete(el.field)
  })

  let searchConditions: any = parseUriParams(queryString).filter((el) =>
    el.field.match(/search_condition_\d/),
  )
  searchConditions.forEach((el: AppliedFilterOptionSelect) => {
    let cI = getComparativeIndex(el.field)

    if (!newStyleFilters[cI]) {
      newStyleFilters.push({
        values: [],
        searchQuery: [],
        searchCondition: [],
      })
    }
    newStyleFilters[cI].searchCondition = el.values
    // urlParams.delete(el.field)
  })

  //so, if comparative but no values on one side
  newStyleFilters.forEach((el: any, index: number) => {
    updateFilters(index, el)
  })

  // DASHBOARD CONTROLS
  const dashboardObj: any = {}
  // @ts-ignore
  for (const [key, value] of urlParams.entries()) {
    dashboardObj[_.camelCase(key)] = value
  }

  const initialDash: any = initialState.dashboardControls[0]

  const dashboardObjWithInit: any = {}
  for (const [key, value] of Object.entries(initialDash)) {
    dashboardObjWithInit[_.camelCase(key)] =
      stringToBoolean(dashboardObj[_.camelCase(key)]) ||
      initialDash[_.camelCase(key)]
  }
  setDashboardControls([dashboardObjWithInit])

  // FEEDBACK PAGE
  let feedbackFilters: any = parseUriParams(queryString).filter((el) =>
    el.field.match(/feedback_filter_\d/),
  )
  if (feedbackFilters?.length) {
    const newFeedbackFilters: any = []

    feedbackFilters.forEach((el: AppliedFilterOptionSelect) => {
      let cI = getComparativeIndex(el.field)
      newFeedbackFilters[cI] = el.values
    })

    const formattedNewFeedbackFilters = newFeedbackFilters.map((el: any) => {
      return {
        order: el.order.values[0].split(/_\d/)[0],
        orderField: el.order_field.values[0].split(/_\d/)[0],
      }
    })
    setFeedbackControls(formattedNewFeedbackFilters)
  }

  // KEYWORDS PAGE
  let keywordsFilters: any = parseUriParams(queryString).filter((el) =>
    el.field.match(/keywords_filter_\d/),
  )
  if (keywordsFilters?.length) {
    const newKeywordsFilters: any = []
    keywordsFilters.forEach((el: AppliedFilterOptionSelect) => {
      let cI = getComparativeIndex(el.field)
      newKeywordsFilters[cI] = el.values
    })

    const formattedNewKeywordsFilters = newKeywordsFilters.map((el: any) => {
      return {
        searchTerm: el.search_term.values[0],
        stopWords: el.stop_words.values,
        windowSize: Number(el.window_size.values[0]),
      }
    })
    setKeywordsControls(formattedNewKeywordsFilters)
  }

  // Network PAGE
  let networkFilters: any = parseUriParams(queryString).filter((el) =>
    el.field.match(/network_filter_\d/),
  )
  if (networkFilters?.length) {
    const newNetworkFilters: any = []
    networkFilters.forEach((el: AppliedFilterOptionSelect) => {
      let cI = getComparativeIndex(el.field)
      newNetworkFilters[cI] = el.values
    })

    const formattedNetworkFilters = newNetworkFilters.map((el: any) => {
      return {
        clusterField: el.cluster_field.values[0],
      }
    })

    setNetworkControls(formattedNetworkFilters)
  }
}

export const findDateWindow = (
  dateWindows: DateWindows,
  min: number | undefined,
  max: number | undefined,
) => {
  if (!dateWindows?.allDates || !min || !max) return null
  return dateWindows.allDates.find(
    (date: DateRange) =>
      formatDateToUTCMidnight(
        moment.unix(JSON.parse(date.value).min),
      ).unix() === formatDateToUTCMidnight(moment.unix(min)).unix() &&
      formatDateToUTCEndOfDay(
        moment.unix(JSON.parse(date.value).max),
      ).unix() === formatDateToUTCEndOfDay(moment.unix(max)).unix(),
  )
}
export function pickTextColorBasedOnBgColorAdvanced(bgColor: string) {
  const lightColor = '#ffffff'
  const darkColor = mainText
  // console.log(bgColor)
  try {
    const rgb = bgColor
      .substring(4, bgColor.length - 1)
      .replace(/ /g, '')
      .split(',')
    const isHex = bgColor.charAt(0) === '#'
    var color = isHex ? bgColor.substring(1, 7) : bgColor
    var r = isHex ? parseInt(color.substring(0, 2), 16) : Number(rgb[0]) // hexToR
    var g = isHex ? parseInt(color.substring(2, 4), 16) : Number(rgb[1]) // hexToG
    var b = isHex ? parseInt(color.substring(4, 6), 16) : Number(rgb[2]) // hexToB
    var uicolors = [r / 255, g / 255, b / 255]
    var c = uicolors.map((col) => {
      if (col <= 0.03928) {
        return col / 12.92
      }
      return Math.pow((col + 0.055) / 1.055, 2.4)
    })
    var L = 0.2126 * c[0] + 0.7152 * c[1] + 0.0722 * c[2]
    return L > 0.25 ? darkColor : lightColor
  } catch (e) {
    return lightColor
  }
}
export function pickTextColorBasedOnBgColorSimple(bgColor: string) {
  const lightColor = '#ffffff'
  const darkColor = mainText
  var color = bgColor.charAt(0) === '#' ? bgColor.substring(1, 7) : bgColor
  var r = parseInt(color.substring(0, 2), 16) // hexToR
  var g = parseInt(color.substring(2, 4), 16) // hexToG
  var b = parseInt(color.substring(4, 6), 16) // hexToB
  console.log(r, g, b)
  return r * 0.299 + g * 0.587 + b * 0.114 > 186 ? darkColor : lightColor
}

export function getFromLS(key: string) {
  let ls = {}
  if (global.localStorage) {
    try {
      //@ts-ignore
      ls = JSON.parse(global.localStorage.getItem('rgl-8')) || {}
    } catch (e) {
      /*Ignore*/
    }
  }
  //@ts-ignore
  return ls[key]
}

export function saveToLS(key: string, value: any) {
  if (global.localStorage) {
    // Get existing data
    const existingData = global.localStorage.getItem('rgl-8')
    let data = existingData ? JSON.parse(existingData) : {}

    // Update data with new key-value pair
    data[key] = value

    // Save updated data back to localStorage
    global.localStorage.setItem('rgl-8', JSON.stringify(data))
  }
}
