import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import React, { useState } from 'react'
import styled from 'styled-components'
import mixpanel from '../../../../trackers/mixpanel'
import { YogiButton } from '../../../../../components/UI/YogiButton'

type Props = {
  data: any
  type: string
  action: string
}

export const Helpful: React.FC<Props> = ({ data, type, action }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const [clicked, setClicked] = useState(false)

  const click = (value: string) => {
    mixpanel.track('helpful', {
      type: type,
      proj_uuid: projectId,
      value: value,
      data: data,
    })
    setClicked(true)
  }

  return (
    <Container data-html2canvas-ignore={'true'}>
      {clicked ? (
        <h3 style={{ fontSize: '15px', marginBottom: '0px', marginTop: '3px' }}>
          Thank you for your feedback 😊
        </h3>
      ) : (
        <Wrapper>
          <h3
            style={{ fontSize: '15px', marginBottom: '0px', marginTop: '3px' }}
          >
            Was this helpful?
          </h3>
          <YogiButton
            style={{ height: 'auto', padding: '2px 12px' }}
            onClick={() => click('Yes')}
          >
            👍 Yes
          </YogiButton>
          <YogiButton
            style={{ height: 'auto', padding: '2px 12px' }}
            onClick={() => click('No')}
          >
            👎 No
          </YogiButton>
        </Wrapper>
      )}
    </Container>
  )
}

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  width: 100%;
  margin: 10px 0px;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  gap: 8px;
  width: 100%;
`
