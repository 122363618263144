import React from 'react'
import { Input, Select } from 'antd'
import { retrievePureUrl } from '../../utils'
import { FormikType } from '../new-source'

type EmojiProps = {
  label?: string
  symbol: string
  url?: string
}
const Emoji: React.FC<EmojiProps> = ({ label, symbol, url }) => {
  return (
    <span
      className="emoji"
      role="img"
      aria-label={label ? label : ''}
      aria-hidden={label ? 'false' : 'true'}
    >
      {symbol}
      {url && ' ' + url}
    </span>
  )
}

export const amazonOptions = [
  {
    value: 'https://www.amazon.com/',
    label: (
      <Emoji symbol="🇺🇸" label="United States" url="https://www.amazon.com/" />
    ),
  },
  {
    value: 'https://www.amazon.com.br/',
    label: (
      <Emoji symbol="🇧🇷" label="Brazil" url="https://www.amazon.com.br/" />
    ),
  },
  {
    value: 'https://www.amazon.ca/',
    label: <Emoji symbol="🇨🇦" label="Canada" url="https://www.amazon.ca/" />,
  },
  {
    value: 'https://www.amazon.com.mx/',
    label: (
      <Emoji symbol="🇲🇽" label="Mexico" url="https://www.amazon.com.mx/" />
    ),
  },
  {
    value: 'https://www.amazon.in/',
    label: <Emoji symbol="🇮🇳" label="India" url="https://www.amazon.in/" />,
  },
  {
    value: 'https://www.amazon.co.jp/',
    label: <Emoji symbol="🇯🇵" label="Japan" url="https://www.amazon.co.jp/" />,
  },
  {
    value: 'https://www.amazon.sg/',
    label: <Emoji symbol="🇸🇬" label="Singapore" url="https://www.amazon.sg/" />,
  },
  {
    value: 'https://www.amazon.sa/',
    label: <Emoji symbol="🇸🇦" label="Saudi Arabia" url="https://www.amazon.sa/" />,
  },
  {
    value: 'https://www.amazon.ae/',
    label: (
      <Emoji
        symbol="🇦🇪"
        label="United Arab Emirates"
        url="https://www.amazon.ae/"
      />
    ),
  },
  {
    value: 'https://www.amazon.fr/',
    label: <Emoji symbol="🇫🇷" label="France" url="https://www.amazon.fr/" />,
  },
  {
    value: 'https://www.amazon.de/',
    label: <Emoji symbol="🇩🇪" label="Germany" url="https://www.amazon.de/" />,
  },
  {
    value: 'https://www.amazon.it/',
    label: <Emoji symbol="🇮🇹" label="Italy" url="https://www.amazon.it/" />,
  },
  {
    value: 'https://www.amazon.nl/',
    label: (
      <Emoji symbol="🇳🇱" label="Netherlands" url="https://www.amazon.nl/" />
    ),
  },
  {
    value: 'https://www.amazon.es/',
    label: <Emoji symbol="🇪🇸" label="Spain" url="https://www.amazon.es/" />,
  },
  {
    value: 'https://www.amazon.se/',
    label: <Emoji symbol="🇸🇪" label="Sweden" url="https://www.amazon.se/" />,
  },
  {
    value: 'https://www.amazon.co.uk/',
    label: (
      <Emoji
        symbol="🇬🇧"
        label="United Kingdom"
        url="https://www.amazon.co.uk/"
      />
    ),
  },
  {
    value: 'https://www.amazon.com.au/',
    label: (
      <Emoji symbol="🇦🇺" label="Australia" url="https://www.amazon.com.au/" />
    ),
  },
]
export const amazonDomains = Array.from(
  amazonOptions,
  (element) => element.value
)

// Extract domain amazon from source link.
export const getAmazonDomain = (sourceUrl: string) => {
  const amazonDomain = amazonDomains.find(
    (domain) => sourceUrl.indexOf(domain) !== -1
  )
  return amazonDomain ? amazonDomain : 'https://www.amazon.com/'
}

type Props = {
  formik: FormikType
  sourceAmazon: string
  sourceUrlValue?: string
}

export const AmazonUrlInput: React.FC<Props> = ({
  formik,
  sourceAmazon,
  sourceUrlValue,
}) => {
  const amazonFilterOptions = (
    <Select
      value={sourceAmazon}
      onChange={(option) => {
        if (formik.values.sourceUrl) {
          formik.setFieldValue(
            'sourceUrl',
            formik.values.sourceUrl.replace(sourceAmazon, option)
          )
        } else {
          formik.setFieldValue('sourceUrl', option)
        }
      }}
      options={amazonOptions}
    />
  )

  return (
    <Input
      name="sourceUrl"
      value={sourceUrlValue}
      addonBefore={amazonFilterOptions}
      onChange={(event) => {
        if (!event.target.value) {
          // If the input is empty, set Amazon domain as sourceUrl.
          // As we have additional validation for Amazon,
          // it will prevent passing pure domain as the source link.
          formik.setFieldValue('sourceUrl', sourceAmazon)
        } else {
          const targetDomain = retrievePureUrl(event.target.value)
          const amazonDomain = amazonDomains.find(
            (country_domain) =>
              targetDomain.indexOf(retrievePureUrl(country_domain)) !== -1
          )
          formik.setFieldValue(
            'sourceUrl',
            amazonDomain
              ? amazonDomain +
                  targetDomain.replace(retrievePureUrl(amazonDomain), '')
              : sourceAmazon + event.target.value
          )
        }
      }}
    />
  )
}
