import styled from 'styled-components'

export const PillContainer = styled.div`
  flex-basis: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  gap: 10px;
  min-height: 50px;
  width: 100%;
  overflow: auto;
  white-space: nowrap;
`
