import React, { useMemo, useState } from 'react'
import styled from 'styled-components'
import { Autocomplete, TextField } from '@mui/material'

import { ThemeProvider } from '@mui/material/styles'
import { theme } from 'features/project/features/Home/components/ReviewComponent/utils'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { ProjectState } from 'features/project/types'
import { FilterOptionSelect } from 'features/project/features/filters/types'
import mixpanel from 'features/trackers/mixpanel'
import { YogiButton } from 'components/UI/YogiButton'

const MAX_DATASETS = 5

type Props = {
  comparativeIndex: number
}

const SupersetSelector: React.FC<Props> = ({ comparativeIndex }) => {
  const projectId = useProjectStore((state: ProjectState) => state.projectId)
  const defaultFilterList = useProjectStore(
    (state: ProjectState) => state.defaultFilterList,
  )
  const updateFilterValues = useProjectStore(
    (state: ProjectState) => state.updateFilterValues,
  )

  const [selectedValues, setSelectedValues] = useState<string[]>([])

  const options = useMemo(() => {
    const resMap: any = {}
    const result: any[] = []
    defaultFilterList
      .filter((el) => el.field === 'proj_uuid')
      .flatMap((obj) =>
        (obj as FilterOptionSelect).values.map((value) => ({
          field: obj.field,
          alias: obj.alias,
          label: obj.alias,
          ...value,
        })),
      )
      .forEach((el) => {
        if (!resMap[el.value]) {
          resMap[el.value] = el
          // @ts-ignore
          result.push(el)
        }
      })
    return result
  }, [defaultFilterList])

  return (
    <Container>
      <ThemeProvider theme={theme}>
        <StyledAutocomplete
          id="grouped-demo"
          options={options.sort(
            (a, b) =>
              a.alias.localeCompare(b.alias) || a.value.localeCompare(b.value),
          )}
          groupBy={(option: any) => option.alias}
          getOptionLabel={(option: any) => option.value}
          renderInput={(params: any) => (
            <TextField {...params} label="Select up to 5 Datasets" />
          )}
          multiple
          style={{ width: '100%', background: 'white', borderRadius: 8 }}
          onChange={(event: any, value: any) => {
            mixpanel.track('superset', {
              action: 'select',
              value: value.value,
              proj_uuid: projectId,
            })
            if (value.length > MAX_DATASETS) {
              setSelectedValues([
                ...selectedValues.slice(0, MAX_DATASETS - 1),
                value[value.length - 1],
              ])
              return
            }
            setSelectedValues(value)
          }}
          value={selectedValues}
          // size={size}
          size={'small'}
          disableCloseOnSelect
          // disabled={selectedValues.length >= MAX_DATASETS}
        />
      </ThemeProvider>
      <YogiButton
        style={{ height: 42, marginTop: -2 }}
        onClick={() => {
          const values = selectedValues.map((el: any) => el.cluster_uuid)
          updateFilterValues(comparativeIndex, 'proj_uuid', values)
          mixpanel.track('superset', {
            action: 'apply',
            value: values,
            proj_uuid: projectId,
          })
        }}
      >
        Apply
      </YogiButton>
    </Container>
  )
}

export default SupersetSelector

const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 20px;
`

const StyledAutocomplete = styled(Autocomplete)`
  label {
    font-size: 18px;
  }
`
