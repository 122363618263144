import type { FC } from 'react'
import React from 'react'
import styled from 'styled-components'
import { secondaryNavy } from '../../../../../../../assets/styles/variables'
import { useDashboardStore } from '../../../dashboardStore'
import { InfoCardWrapper } from '../../../../../../../components/InfoCard'
import { Button } from 'antd'
import { AddBoxOutlined, VerticalSplitOutlined } from '@mui/icons-material'

export interface DraggableWidgetProps {
  item: any
  onAddItem: (item: any, layoutItem: any) => void
}

interface CardProps {
  selected?: boolean
  localSelected?: boolean
}

export const DraggableWidget: FC<DraggableWidgetProps> = ({
  item,
  onAddItem,
}) => {
  const setDraggedItem = useDashboardStore((state) => state.setDraggedItem)

  const handleDragStart = (e: any) => {
    e.dataTransfer.setData('text/plain', '')
    e.dataTransfer.effectAllowed = 'move'
    const draggableItem = {
      type: 'widget',
      name: item.name,
      layout: item.layout,
    }
    setDraggedItem(draggableItem)
  }
  const [isHovering, setIsHovering] = React.useState(false)

  return (
    <div
      className="droppable-element drag-handle"
      draggable={true}
      unselectable="on"
      style={{ cursor: 'move', position: 'relative' }}
      onMouseEnter={() => {
        setIsHovering(true)
      }}
      onMouseLeave={() => {
        setIsHovering(false)
      }}
      // this is a hack for firefox
      // Firefox requires some kind of initialization
      // which we can do by adding this attribute
      // @see https://bugzilla.mozilla.org/show_bug.cgi?id=568313
      // onDragStart={(e) => e.dataTransfer.setData('text/plain', '')}
      onDragStart={handleDragStart}
    >
      {isHovering && (
        <div
          style={{
            position: 'absolute',
            height: '100%',
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            background: 'rgba(180,207,255,0.4)',
          }}
        >
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: 5,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type={'default'}
              onClick={() => {
                onAddItem(
                  {
                    type: 'widget',
                    name: item.name,
                    layout: item.layout,
                  },
                  {
                    ...item.layout,
                    w: 12,
                  },
                )
              }}
              style={{ display: 'flex', gap: 5 }}
            >
              <AddBoxOutlined style={{ fontSize: 16, marginTop: -1 }} />
              <div>Add Full Width</div>
            </Button>
          </div>
          <div
            style={{
              height: '100%',
              width: '100%',
              padding: 5,
              textAlign: 'center',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <Button
              type={'default'}
              onClick={() => {
                onAddItem(
                  {
                    type: 'widget',
                    name: item.name,
                    layout: item.layout,
                  },
                  {
                    ...item.layout,
                    w: 6,
                  },
                )
              }}
              style={{ display: 'flex', gap: 5 }}
            >
              <VerticalSplitOutlined style={{ fontSize: 16, marginTop: -1 }} />
              <div>Add Half Width</div>
            </Button>
          </div>
        </div>
      )}
      {item.showComponent ? (
        item.component
      ) : (
        <InfoCardWrapper
          style={{ padding: 10, flexDirection: 'column', alignItems: 'center' }}
        >
          <Image src={item.image} />
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 8 }}
          >
            <div style={{ fontWeight: 500, textAlign: 'center', fontSize: 12 }}>
              {item.name}
            </div>
          </div>
        </InfoCardWrapper>
      )}
    </div>
  )
}

const Image = styled.img`
  height: 44px;
  //margin-right: 10px;
`
