import styled from 'styled-components'
import { cardBorder } from '../../../../../../../../assets/styles/variables'

export const Container = styled.div`
  padding: 20px;
  border: 2px solid ${cardBorder};
  box-shadow: 0 4px 10px 0 #00000026;
  border-radius: 10px;
  margin-bottom: 20px;
  overflow: hidden;

  display: flex;
  flex-wrap: wrap;
  gap: 10px;
`

export const ChartWrapper = styled.div`
  //max-height: 460px;
`
