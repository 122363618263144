import {
  AppliedFilterOption,
  AppliedFilterOptionSelect,
  ApplyGroupFilterPayload,
  FilterOptionValue,
} from '../../../../../../../types'
import React from 'react'
import { checkIfGroupFilterSelected } from '../../utils'
import styled from 'styled-components'
import { Checkbox } from 'antd'

const numberFormatter = new Intl.NumberFormat()

type Props = {
  value: FilterOptionValue
  currentAppliedFilter: AppliedFilterOption | undefined
  applySelect: (value: ApplyGroupFilterPayload['value']) => void
  isParent?: boolean
  disabled?: boolean
  isLoading: boolean
}

const SelectItem: React.FC<Props> = ({
  value,
  currentAppliedFilter,
  applySelect,
  isParent,
  disabled,
  isLoading,
}) => {
  // this checks to see if we have already calculated the values for this filter
  const numberOfPosts = numberFormatter.format(value.n_posts)
  let displayValue = value.value
  const checked = checkIfGroupFilterSelected.checkbox(
    currentAppliedFilter as AppliedFilterOptionSelect,
    value.identifiers,
  )

  let indeterminate = false
  if (isParent) {
    const intersection = value.identifiers.filter((value) =>
      (currentAppliedFilter as AppliedFilterOptionSelect)?.values.includes(
        value,
      ),
    )
    indeterminate = !!(
      intersection.length && intersection.length !== value.identifiers.length
    )
  }

  return (
    <StyledCheckbox
      key={value.cluster_uuid || value.value}
      checked={checked}
      indeterminate={indeterminate}
      onChange={() => {
        applySelect(value.identifiers)
      }}
      disabled={disabled}
    >
      {displayValue}{' '}
      {!isLoading && <>{numberOfPosts !== '0' && <b>({numberOfPosts})</b>}</>}
    </StyledCheckbox>
  )
}

export default SelectItem

const StyledCheckbox = styled(Checkbox)`
  margin: 0 !important;
  display: flex;
  padding: 3px 20px;
  --antd-wave-shadow-color: var(--secondary-navy);
`
