import React from 'react'

const ViewSvgIcon: React.FC = () => (
  <svg
    width="35"
    height="35"
    viewBox="0 0 35 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M3.5 35C2.5375 35 1.71354 34.6573 1.02812 33.9719C0.342708 33.2865 0 32.4625 0 31.5V15.75C0 14.7875 0.342708 13.9635 1.02812 13.2781C1.71354 12.5927 2.5375 12.25 3.5 12.25H7V3.5C7 2.5375 7.34271 1.71354 8.02812 1.02812C8.71354 0.342708 9.5375 0 10.5 0H31.5C32.4625 0 33.2865 0.342708 33.9719 1.02812C34.6573 1.71354 35 2.5375 35 3.5V19.25C35 20.2125 34.6573 21.0365 33.9719 21.7219C33.2865 22.4073 32.4625 22.75 31.5 22.75H28V31.5C28 32.4625 27.6573 33.2865 26.9719 33.9719C26.2865 34.6573 25.4625 35 24.5 35H3.5ZM3.5 31.5H24.5V19.25H3.5V31.5ZM28 19.25H31.5V7H10.5V12.25H24.5C25.4625 12.25 26.2865 12.5927 26.9719 13.2781C27.6573 13.9635 28 14.7875 28 15.75V19.25Z"
      fill="currentColor"
    />
  </svg>
)

export default ViewSvgIcon
