import React, { useEffect } from 'react'
import { SavePresetForm } from 'features/project/features/filters/features/filter-preset/filter-preset-save-form'
import { Popover } from 'antd'
import { Filters, ProjectState } from 'features/project/types'
import useExport from 'features/project/features/Charts/components/ExportDrawer/useExport'
import { useProjectStore } from 'features/project/projectStore/projectStore'

type Props = {
  children: React.ReactNode
  filterValues: Filters
  comparativeIndex: number | undefined
}

export const SaveFilter: React.FC<Props> = ({
  children,
  filterValues,
  comparativeIndex,
}) => {
  const relativeDateFilterIdx = useProjectStore(
    (state: ProjectState) => state.relativeDateFilterIdx,
  )
  const isExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isExportDrawerOpen,
  )

  const { savePreset } = useExport({ comparativeIndex: comparativeIndex || 0 })

  const [isVisible, setIsVisible] = React.useState(false)

  useEffect(() => {
    setIsVisible(false)
  }, [isExportDrawerOpen])

  return (
    <Popover
      open={isVisible}
      onOpenChange={(v: boolean) => {
        setIsVisible(v)
      }}
      title={<div style={{ padding: '5px' }}>Save Current Filter</div>}
      trigger="click"
      placement={'bottom'}
      content={
        <div style={{ width: '300px' }}>
          <SavePresetForm
            saveFunction={savePreset}
            relativeDateFilterIdx={relativeDateFilterIdx[comparativeIndex || 0]}
            filterValues={filterValues}
            setIsVisible={setIsVisible}
          />
        </div>
      }
    >
      <div onClick={() => setIsVisible(!isVisible)}>{children}</div>
    </Popover>
  )
}
