import { InnerTitle } from '../../../../../components/InnerTitle'
import { Theme } from './theme'
import {
  SummaryResponse,
  ThemeSummary,
} from 'features/project/features/Charts/types'
import { PartitionOutlined } from '@ant-design/icons'
import { PillContainer } from '../../../../../components/UI/Pills/PillContainer'
import { Pill } from '../../../../../components/UI/Pills/Pill'
import React, { useEffect, useState } from 'react'
import { Select } from 'antd'
import styled from 'styled-components'

const sentimentOptions = [
  { label: 'All Mentions', value: '' },
  { label: 'Positive Mentions', value: 'Positive' },
  // { label: 'Neutral Mentions', value: 'Neutral' },
  { label: 'Negative Mentions', value: 'Negative' },
]

const themeOptions = [
  { label: 'Overall Drivers', value: 'overall_themes' },
  { label: 'Upward Drivers', value: 'most_positive_themes' },
  { label: 'Downward Drivers', value: 'most_negative_themes' },
]

type Props = {
  data: SummaryResponse | undefined
  summaryRequest: any
  localFilters: any
  selectedTheme?: string
  defaultOpened?: boolean
  isWidget?: boolean
}
export const Themes: React.FC<Props> = ({
  data,
  summaryRequest,
  localFilters,
  selectedTheme,
  defaultOpened = true,
  isWidget,
}) => {
  const [sentiment, setSentiment] = useState<string>('')
  const [themeOption, setThemeOption] = useState<
    'overall_themes' | 'most_positive_themes' | 'most_negative_themes'
  >('overall_themes')

  useEffect(() => {
    setSentiment('')
    setThemeOption('overall_themes')
  }, [summaryRequest])

  const SentimentSelector = () => {
    if (
      (summaryRequest.body.chart_id.split('_')[3] === 'sentiment' &&
        summaryRequest.body.tier3_value) ||
      localFilters?.values?.filter((el: any) => el.field === 'sentiment')
        ?.length
    )
      return null

    return (
      // <Tooltip
      //   title={
      //     'Select a target sentiment for the AI Summaries. This will update the set of reviews that are summarized.'
      //   }
      // >
      <Select
        style={{ minWidth: 200 }}
        value={sentiment}
        onChange={(v) => {
          // mixpanel.track('chart config', { action: 'font size', value: v })
          setSentiment(v)
        }}
        placeholder="Sentiment"
      >
        {sentimentOptions.map((option: any) => (
          <Select.Option
            value={option.value}
            key={option.value}
            selected={option.value === sentiment}
          >
            {option.label}
          </Select.Option>
        ))}
      </Select>
      // </Tooltip>
    )
  }

  if (!data) return <div></div>
  return (
    <div>
      {selectedTheme ? (
        <>
          <div
            style={{
              width: '100%',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <InnerTitle
              style={{
                display: 'flex',
                alignItems: 'center',
                gap: '10px',
              }}
            >
              {/*<FavoriteBorderOutlined*/}
              {/*  style={{*/}
              {/*    color: secondaryGreen,*/}
              {/*    marginTop: '-1px',*/}
              {/*  }}*/}
              {/*/>{' '}*/}
              Theme Analysis
            </InnerTitle>
            <div>
              {/*<h4>Target Sentiment</h4>*/}
              {/*<label>Target Sentiment</label>*/}
              <SentimentSelector />
            </div>
          </div>
          <Theme
            sentiment={sentiment}
            theme={data?.theme_summary?.overall_themes[0] as ThemeSummary}
            index={0}
            summaryRequest={summaryRequest}
            isAlwaysOpen
            isWidget={isWidget}
          />
        </>
      ) : (
        <>
          {(!!data?.theme_summary[themeOption] ||
            !!data?.theme_summary[themeOption].length) && (
            <>
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  // gap: '40px',
                }}
              >
                <InnerTitle
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    gap: '10px',
                    marginBottom: '0px',
                  }}
                >
                  {/*<FavoriteBorderOutlined*/}
                  {/*  style={{*/}
                  {/*    color: secondaryGreen,*/}
                  {/*    marginTop: '-1px',*/}
                  {/*  }}*/}
                  {/*/>{' '}*/}
                  <PartitionOutlined />
                  Yogi Key Themes
                </InnerTitle>
                <div
                  style={{
                    display: 'flex',
                    gap: '10px',
                    justifyContent: 'center',
                    alignItems: 'flex-start',
                  }}
                >
                  {/*<Radio.Group*/}
                  {/*  value={themeOption}*/}
                  {/*  onChange={(event) => {*/}
                  {/*    setThemeOption(event.target.value)*/}
                  {/*  }}*/}
                  {/*  style={{ marginBottom: '8px' }}*/}
                  {/*>*/}
                  {/*  {themeOptions.map((option: any) => (*/}
                  {/*    <Radio value={option.value}>*/}
                  {/*      {option.label}*/}
                  {/*    </Radio>*/}
                  {/*  ))}*/}
                  {/*</Radio.Group>*/}
                  {/*<Tooltip*/}
                  {/*  title={*/}
                  {/*    'Select a type of Driver. This will change what themes are identified.'*/}
                  {/*  }*/}
                  {/*>*/}
                  {/*  <Select*/}
                  {/*    // style={{ minWidth: 200 }}*/}
                  {/*    value={themeOption}*/}
                  {/*    onChange={(v) => {*/}
                  {/*      // mixpanel.track('chart config', { action: 'font size', value: v })*/}
                  {/*      setThemeOption(v)*/}
                  {/*    }}*/}
                  {/*    placeholder="Sentiment"*/}
                  {/*  >*/}
                  {/*    {themeOptions.map((option: any) => (*/}
                  {/*      <Select.Option*/}
                  {/*        value={option.value}*/}
                  {/*        key={option.value}*/}
                  {/*        selected={option.value === themeOption}*/}
                  {/*      >*/}
                  {/*        {option.label}*/}
                  {/*      </Select.Option>*/}
                  {/*    ))}*/}
                  {/*  </Select>*/}
                  {/*</Tooltip>*/}
                  <div style={{ paddingRight: 10 }}>
                    <SentimentSelector />
                  </div>
                </div>
              </div>
              <PillContainer>
                {/*<Pill*/}
                {/*  value={''}*/}
                {/*  selected={themeOption === 'overall_themes'}*/}
                {/*  onClick={() => {*/}
                {/*    setThemeOption('overall_themes')*/}
                {/*  }}*/}
                {/*>*/}
                {/*  All Suggested*/}
                {/*</Pill>*/}
                {themeOptions.map((el, index) => (
                  <>
                    <Pill
                      key={index}
                      value={el.value}
                      selected={el.value === themeOption}
                      onClick={() => {
                        setThemeOption(
                          el.value as
                            | 'most_positive_themes'
                            | 'most_negative_themes'
                            | 'overall_themes',
                        )
                      }}
                    >
                      {el.label}
                    </Pill>
                    {/*{index === 0 && <VerticalDivider />}*/}
                  </>
                ))}
              </PillContainer>
              <Info data-html2canvas-ignore={'true'}>
                Summaries represent {sentiment?.toLowerCase() || 'all'}{' '}
                sentences mentioned in{' '}
                {themeOption === 'most_positive_themes' ? 'an' : 'a'}{' '}
                {themeOption !== 'overall_themes' &&
                  themeOptions
                    .find((el) => el.value === themeOption)
                    ?.label.split(' ')[0]
                    ?.toLowerCase()}{' '}
                driving theme.
              </Info>
              <ThemeList>
                {data?.theme_summary[themeOption] &&
                  data.theme_summary[themeOption].map(
                    (theme: ThemeSummary, index) => (
                      <Theme
                        key={index}
                        sentiment={sentiment}
                        theme={theme}
                        index={index + 1}
                        summaryRequest={summaryRequest}
                        defaultOpened={
                          defaultOpened ||
                          (isWidget &&
                            data.theme_summary[themeOption].length === 1)
                        }
                        isWidget={isWidget}
                      />
                    ),
                  )}
              </ThemeList>
            </>
          )}

          {/*{!!data?.theme_summary?.most_negative_themes?.length && (*/}
          {/*  <>*/}
          {/*    <Divider />*/}
          {/*    <Section>*/}
          {/*      <InnerTitle*/}
          {/*        style={{*/}
          {/*          display: 'flex',*/}
          {/*          alignItems: 'center',*/}
          {/*          gap: '10px',*/}
          {/*        }}*/}
          {/*      >*/}
          {/*        <HeartBrokenOutlined*/}
          {/*          style={{*/}
          {/*            color: secondaryRed,*/}
          {/*            marginTop: '-1px',*/}
          {/*          }}*/}
          {/*        />{' '}*/}
          {/*        Lowest Average Sentiment Themes*/}
          {/*      </InnerTitle>*/}
          {/*      <ThemeList>*/}
          {/*        {data?.theme_summary?.most_negative_themes.map(*/}
          {/*          (theme, index) => (*/}
          {/*            <Theme*/}
          {/*              theme={theme}*/}
          {/*              index={index}*/}
          {/*              summaryRequest={summaryRequest}*/}
          {/*              localFilters={localFilters}*/}
          {/*            />*/}
          {/*          )*/}
          {/*        )}*/}
          {/*      </ThemeList>*/}
          {/*    </Section>*/}
          {/*  </>*/}
          {/*)}*/}
        </>
      )}
    </div>
  )
}

const ThemeList = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`

const Info = styled.div`
  padding: 5px 10px;
  background: #fff9d7;
  border-left: 4px solid #ffe134;
  margin-bottom: 10px;
  font-size: 12px;
`
