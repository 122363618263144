import React from 'react'
import { Container, InsightsListItemsWrapper } from './InsightList.styles'
import InsightListOptions from './components/InsightListOptions/InsightListOptions'
import { OutlierType } from '../../model'
import InsightListItem from './components/InsightListItem/InsightListItem'
import YogiInfo from '../../../../../../components/UI/YogiInfo/YogiInfo'
import { lightBlueFontColor } from '../../../../../../assets/styles/variables'
import { Filters } from '../../../../types'
import { BookmarkBorder } from '@mui/icons-material'
import { YogiButton } from '../../../../../../components/UI/YogiButton'
import mixpanel from '../../../../../trackers/mixpanel'

interface Props {
  outliers: OutlierType[]
  selectedOutlier: OutlierType | undefined
  setSelectedOutlier: (outlier: OutlierType | undefined) => void
  activeTab: number
  setActiveTab: (index: number) => void
  filterValues: Filters
  incrementPage: () => void
  resetPageCount: () => void
  isPageLoading: boolean
  hasMoreOutliers: boolean
  search: string
  setSearch: (search: string) => void
}

const InsightList: React.FC<Props> = ({
  outliers,
  selectedOutlier,
  setSelectedOutlier,
  activeTab,
  setActiveTab,
  filterValues,
  incrementPage,
  resetPageCount,
  isPageLoading,
  hasMoreOutliers,
  search,
  setSearch,
}) => {
  const searchedOutliers = outliers.filter((outlier) => {
    if (!search) return true
    return (
      outlier.analysis?.stinger.toLowerCase().includes(search.toLowerCase()) ||
      outlier.context_with_value?.value
        .toLowerCase()
        .includes(search.toLowerCase()) ||
      outlier.theme?.toLowerCase().includes(search.toLowerCase())
    )
  })

  return (
    <Container>
      <InsightListOptions
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        search={search}
        setSearch={setSearch}
        resetPageCount={resetPageCount}
      ></InsightListOptions>
      <InsightsListItemsWrapper>
        {outliers.length === 0 && (
          <YogiInfo color={lightBlueFontColor}>
            <>
              {activeTab < 3 ? (
                <div>
                  No Insights found in the last 12 months. This Hub may need to
                  have its reviews updated!
                </div>
              ) : (
                <div>
                  No Bookmarked Insights found. Click on the{' '}
                  <BookmarkBorder
                    fontSize={'small'}
                    style={{ marginBottom: -5 }}
                  />{' '}
                  icon to save an Insight!
                </div>
              )}
            </>
          </YogiInfo>
        )}
        {searchedOutliers.map((item, index) => (
          <InsightListItem
            key={item.uuid}
            outlier={item}
            isSelected={
              selectedOutlier ? selectedOutlier.uuid === item.uuid : index === 0
            }
            setSelectedOutlier={setSelectedOutlier}
            filterValues={filterValues}
          ></InsightListItem>
        ))}
        <YogiButton
          loading={isPageLoading}
          disabled={!hasMoreOutliers}
          onClick={incrementPage}
        >
          {isPageLoading ? 'Loading More' : 'Load More'}
        </YogiButton>
      </InsightsListItemsWrapper>
    </Container>
  )
}

export default InsightList
