import React, { useEffect } from 'react'
import { Popover } from 'antd'
import { Filters, ProjectState } from 'features/project/types'
import { useProjectStore } from 'features/project/projectStore/projectStore'
import { SaveViewForm } from 'features/project/features/saved-vews/components/view-save-form'
import { SavedViewPayload } from 'features/project/features/saved-vews/types'
import styled from 'styled-components'
import { useSavedViews } from 'features/project/features/saved-vews/utils'
import { AddToDashboardContent } from 'features/project/features/Charts/components/ExportDrawer/components/SaveDashboard/components/AddToDashboardContent/AddToDashboardContent'

type Props = {
  children: React.ReactNode
  comparativeIndex: number
  filterValues: Filters
}

export const SaveDashboard: React.FC<Props> = ({
  children,
  comparativeIndex,
  filterValues,
}) => {
  const isExportDrawerOpen = useProjectStore(
    (state: ProjectState) => state.isExportDrawerOpen,
  )

  const [isVisible, setIsVisible] = React.useState(false)

  useEffect(() => {
    setIsVisible(false)
  }, [isExportDrawerOpen])

  return (
    <Popover
      open={isVisible}
      onOpenChange={(v: boolean) => {
        console.log(v)
        setIsVisible(v)
      }}
      content={
        <AddToDashboardContent
          comparativeIndex={comparativeIndex}
          filterValues={filterValues}
          setIsVisible={setIsVisible}
        />
      }
      title={<div style={{ padding: '5px' }}>Add to Dashboard</div>}
      trigger="click"
      placement="bottomRight"
    >
      <div onClick={() => setIsVisible(!isVisible)}>{children}</div>
    </Popover>
  )
}

const SavedViewsPopoverContent = styled.div`
  width: 500px;
`
