import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Checkbox, Input, Tag, Button, Radio } from 'antd'
import mixpanel from 'features/trackers/mixpanel'
import { toast } from 'react-toastify'
import { useClickOutside } from 'shared/hooks'
import { CopyOutlined } from '@ant-design/icons'
import { useProjectStore } from '../../../../../projectStore/projectStore'
import { Filters, ProjectState } from '../../../../../types'
import { YogiButton } from 'components/UI/YogiButton'
import { AppliedFilterOptionSelect } from '../../../types'
import _ from 'lodash'

type Props = {
  closePanel: () => void
  comparativeIndex: number
  filterValues: Filters
  updateLocalFilterValues?: (filterValues: Filters) => void
  setIsFilterUnapplied?: (isFilterUnapplied: boolean) => void
}

export const SearchPanel: React.FC<Props> = ({
  closePanel,
  comparativeIndex,
  filterValues,
  updateLocalFilterValues,
  setIsFilterUnapplied,
}) => {
  const panelRef = React.createRef<HTMLDivElement>()

  const route = useProjectStore((state: ProjectState) => state.route)
  const setSearchQuery = useProjectStore(
    (state: ProjectState) => state.setSearchQuery,
  )
  const setSearchCondition = useProjectStore(
    (state: ProjectState) => state.setSearchCondition,
  )
  const updateSelectedOptionField = useProjectStore(
    (state: ProjectState) => state.updateSelectedOptionField,
  )
  const clearOpenedOptions = useProjectStore(
    (state: ProjectState) => state.clearOpenedOptions,
  )

  const [value, setValue] = useState<string>('')
  // const [words, setWords] = useState<string[]>(
  //   processPanelRelatedSearch(filterValues.searchQuery, comparativeIndex)
  // )
  let [words, setWords] = useState<string[]>(filterValues.searchQuery)
  useEffect(() => {
    setWords(filterValues.searchQuery)
  }, [filterValues.searchQuery])

  const [andOrValue, setAndOrValue] = useState(
    filterValues.searchCondition.includes('and') ? 'and' : 'or',
  )

  const [and, setAnd] = useState(filterValues.searchCondition.includes('and'))
  const [or, setOr] = useState(filterValues.searchCondition.includes('or'))
  const [not, setNot] = useState(filterValues.searchCondition.includes('not'))

  useEffect(() => {
    setAndOrValue(filterValues.searchCondition.includes('and') ? 'and' : 'or')
    setNot(filterValues.searchCondition.includes('not'))
  }, [filterValues.searchCondition])

  const isEqual = _.isEqual(filterValues.searchQuery, words) && !value.length

  useEffect(() => {
    setIsFilterUnapplied && setIsFilterUnapplied(!isEqual)
  }, [isEqual])

  // const appendSearchQuery = (newFilters: AppliedFilterOption[]) => {
  //   const fieldNameSet = new Set(newFilters.map(({ field }) => field))
  //
  //   setSearchQuery((prevValues) => [
  //     ...prevValues.filter(({ field }) => !fieldNameSet.has(field)),
  //     ...newFilters,
  //   ])
  // }
  //
  // const appendSearchCondition = (newFilters: AppliedFilterOption[]) => {
  //   const fieldNameSet = new Set(newFilters.map(({ field }) => field))
  //
  //   setSearchCondition((prevValues) => [
  //     ...prevValues.filter(({ field }) => !fieldNameSet.has(field)),
  //     ...newFilters,
  //   ])
  // }

  const setSearchQueryAndConditionWithURI = (
    newQuery: string[],
    newCondition: string[],
    comparativeIndex: number,
    isComparativeModeChanged?: boolean,
    isAlignFilters?: boolean,
  ) => {
    // TODO ?? does this ever exist?
    if (!isComparativeModeChanged) {
      const queryArray = []
      const conditionArray = []
      if (isAlignFilters) {
        for (let i = 1; i <= comparativeIndex!; i++) {
          queryArray.push({
            field: `search_query_${i}`,
            values: newQuery,
          })
          conditionArray.push({
            field: `search_condition_${i}`,
            values: newCondition,
          })
        }
        // TODO maybe
      } else {
        queryArray.push({
          field: `search_query`,
          values: newQuery,
        })
        conditionArray.push({
          field: `search_condition`,
          values: newCondition,
        })
      }
      if (updateLocalFilterValues) {
        updateLocalFilterValues({
          ...filterValues,
          searchQuery: newQuery,
          searchCondition: newCondition,
        })
      } else {
        setSearchQuery(comparativeIndex, newQuery)
        setSearchCondition(comparativeIndex, newCondition)
      }
    }
    closePanel()
  }

  useClickOutside(panelRef, () => closePanel())

  return (
    <Wrapper ref={panelRef}>
      <SearchWrapper>
        <SearchContent>
          {words.map((word) => (
            <Tag
              key={word}
              closable
              onClose={() => setWords(words.filter((w) => w !== word))}
            >
              {word}
            </Tag>
          ))}
          <StyledInput
            type="text"
            value={value}
            onChange={(e) => setValue(e.target.value)}
            onPressEnter={(e) => {
              if (value.length) {
                setWords(Array.from(new Set([...words, value])))
                setValue('')
              }
              e.stopPropagation()
              e.preventDefault()
            }}
            onKeyDown={(event) => {
              if (event.metaKey) {
                const value = (words || []).join(',')
                if (event.key === 'c') {
                  navigator.clipboard.writeText(value)
                }
                if (event.key === 'x') {
                  navigator.clipboard.writeText(value)
                  setWords([])
                }
                if (event.key === 'v') {
                  navigator.clipboard.readText().then((result) => {
                    setWords(
                      Array.from(new Set(words.concat(result.split(',')))),
                    )
                    setValue('')
                  })
                }
              }
            }}
          />
        </SearchContent>
        <YogiButton
          type="primary"
          shape="circle"
          icon={<CopyOutlined />}
          onClick={() => {
            navigator.clipboard.writeText((words || []).join(','))
            toast.info('Copied to clipboard')
          }}
        />
      </SearchWrapper>
      <CheckboxWrapper>
        <Radio.Group
          value={andOrValue}
          style={{ width: '100%', display: 'flex' }}
          onChange={(event) => {
            setAndOrValue(event.target.value)
          }}
        >
          <Radio style={{ fontSize: 10 }} value="or">
            OR
          </Radio>
          <Radio style={{ fontSize: 10 }} value="and">
            AND
          </Radio>
        </Radio.Group>
        <Checkbox checked={not} onChange={(e) => setNot(e.target.checked)}>
          EXCLUDE
        </Checkbox>
        <ButtonsWrapper>
          <Button
            type="primary"
            danger
            style={{
              borderRadius: '8px',
              borderWidth: '2px',
            }}
            disabled={!words.length && !value.length}
            onClick={() => {
              setSearchQueryAndConditionWithURI([], [], comparativeIndex)

              if (!updateLocalFilterValues) {
                updateSelectedOptionField(comparativeIndex, '')
                clearOpenedOptions(comparativeIndex)
                closePanel()
              }
            }}
          >
            Clear
          </Button>
          <YogiButton
            type="primary"
            onClick={() => {
              if (value.length) {
                words = Array.from(new Set([...words, value]))
                setValue('')
              }
              const searchCondition = [andOrValue, not && 'not'].filter(
                Boolean,
              ) as string[]
              setSearchQueryAndConditionWithURI(
                words,
                searchCondition,
                comparativeIndex,
              )

              mixpanel.track('filter', {
                action: 'search',
                search_terms: words,
                search_criteria: searchCondition,
                n_terms: words.length,
                ...route,
              })

              if (!updateLocalFilterValues) {
                updateSelectedOptionField(comparativeIndex, '')
                clearOpenedOptions(comparativeIndex)
                closePanel()
              }
            }}
          >
            Apply
          </YogiButton>
        </ButtonsWrapper>
      </CheckboxWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  padding: var(--default-padding-half);
`
const StyledInput = styled(Input)`
  height: 100%;
  border: none;
  min-width: 100px;
  width: auto;
  flex: 1;

  &:focus {
    box-shadow: none;
  }
`
const SearchWrapper = styled.div`
  display: flex;
  min-height: 46px;
  align-items: center;
  padding: 8px;
  box-sizing: border-box;
  background: #fff;
  overflow: hidden;
  border: 2px solid var(--card-border-grey);
  border-radius: 6px;
`

const SearchContent = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;

  .ant-tag {
    display: flex;
    align-items: center;
  }
`
const CheckboxWrapper = styled.div`
  padding: 12px 8px 0px;
  display: flex;
  align-items: center;
`
const ButtonsWrapper = styled.div`
  margin-left: auto;
  display: flex;

  & > * {
    margin-left: var(--default-padding-half);
  }
`
