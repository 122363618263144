import { createContext, useContext } from 'react'
import { User, AuthContextInterface } from '../types'

const initialValue: AuthContextInterface = {
  user: null,
  username: null,
  updateUsername: (p: string) => {},
  isAuthenticated: false,
  signIn: (p: User) => {},
  signOut: () => {},
  setUser: (p: User) => {},
  userDetails: null,
}

export const AuthContext = createContext<AuthContextInterface>(initialValue)

export const useAuthContext = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuthContext must be used within a AuthContext.')
  }
  return context
}
