import { AxiosResponse } from 'axios'
import { FeedbackResponse, SummaryResponse } from './types'
import {
  FILTER_URL,
  POST_DOWNLOAD_URL,
  PROJECT_SUMMARY_URL,
  PROJECT_KEYWORDS_DOWNLOAD,
} from 'utils/apiLinks'
import { axiosInstance } from 'utils/axios'
import { AppliedFilterOption } from '../filters/types'

export enum SEARCH_MODE {
  POST_NORMAL = 0,
  POST_TEXT_NORMAL = 1,
  POST_TEXT_KEYWORDS = 2,
}

export const getFeedback = (
  params: {
    proj_uuid: string
    page: number
    order?: string
    order_field?: string
  },
  body: {
    criteria?: Array<AppliedFilterOption>
    search_criteria?: Array<string>
    search_terms?: Array<string>
    search_mode?: SEARCH_MODE
  },
  customParams?: any
): Promise<AxiosResponse<FeedbackResponse>> => {
  let config = { params }
  if (customParams) config = { ...config, ...customParams }

  return axiosInstance.post(FILTER_URL, body, config)
}

export const getSummary = (
  params: any,
  body: any,
  customParams?: any
): Promise<AxiosResponse<SummaryResponse>> => {
  let config = { params }
  if (customParams) config = { ...config, ...customParams }
  return axiosInstance.post(PROJECT_SUMMARY_URL, body, config)
}

export const downloadPosts = (proj_uuid: string, body?: any) =>
  axiosInstance.post(POST_DOWNLOAD_URL, body, { params: { proj_uuid } })

export const downloadKeywords = (
  proj_uuid: string,
  search_term: string,
  window_size: number,
  max_results: number,
  body?: any
) =>
  axiosInstance.post(PROJECT_KEYWORDS_DOWNLOAD, body, {
    params: { proj_uuid, search_term, window_size, max_results },
  })

export const mergeFeedbackResponse = (
  data: FeedbackResponse,
  toMerge: FeedbackResponse
): FeedbackResponse => {
  data.posts = [...data.posts, ...toMerge.posts]
  return data
}
